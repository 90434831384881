function APIError(message, code = 0) {
    this.name = "APIError";
    this.message = message || "The API call returned an error.";
    this.code = code;
    this.stack = new Error().stack;
}

APIError.prototype = Object.create(Error.prototype);
APIError.prototype.constructor = APIError;

module.exports = APIError;
