const React = require("react");
const Language = require("../lib/Language");

// eslint-disable-next-line no-useless-escape
const isAllowed = (val) => /^[\-.,0-9]*$/.test(val);

class NumberInput extends React.Component {
    componentDidUpdate(prevProps) {
        if (
            prevProps.defaultValue !== this.props.defaultValue &&
            this.props.defaultValue !== this.state.value
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: this.getFormattedNumber(this.props.defaultValue) });
        }
    }

    getFormattedNumber = (value) => {
        const USE_COMMA =
            Language.get("zz_dec_decimal_format") &&
            Language.get("zz_dec_decimal_format").indexOf(",") > -1;
        const vl = String(value);

        if (USE_COMMA) {
            return vl.replace(".", ",");
        }
        return vl.replace(",", ".");
    };

    onReturn = () => {
        let val = String(this.state.value);
        val = val.replace(",", ".");
        val = parseFloat(val);
        if (isNaN(val)) {
            val = this.props.defaultValue;
        }

        if (this.props.decimals !== undefined && this.props.decimals > 0) {
            // eslint-disable-next-line no-magic-numbers
            const base = Math.pow(10, this.props.decimals);
            val = Math.round(val * base) / base;
        }

        this.setState({ value: val });
        this.props.onUpdate(val);
    };

    onChange = (evt) => {
        const val = evt.target.value;
        if (isAllowed(val)) {
            this.setState({ value: val });
        }
    };

    onKeyDown = (evt) => {
        if (evt.key !== "Enter") {
            return;
        }

        this.onReturn();
        evt.preventDefault();
        evt.stopPropagation();
    };

    state = {
        value: this.getFormattedNumber(this.props.defaultValue),
    };

    render() {
        const disabled = this.props.disabled || false;
        const className = this.props.classNames || "";
        return (
            <input
                className={className}
                disabled={disabled}
                type="text"
                pattern="[.,\-0-9]*"
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.onReturn}
                onKeyDown={this.onKeyDown}
            />
        );
    }
}

module.exports = NumberInput;
