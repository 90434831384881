const stringMap = {
    sv: {
        login_loading: "Laddar …",
        login_incorrect_user_or_password: "Felaktigt användarnamn eller lösenord.",
        login_new_password_required: "Ange nytt lösenord",
        login_user: "Användarnamn",
        login_email: "E-post",
        login_password: "Lösenord",
        login_new_password: "Nytt lösenord",
        login_confirm_password: "Upprepa lösenord",
        login_login: "Logga in",
        login_database_not_available: "Databasen är inte tillgänglig.",
        login_cookies_disabled: "Du måste aktivera cookies för att kunna använda TimeEdit.",
        login_insecure_browser:
            "Denna webbläsare stödjer inte den säkerhet TimeEdit kräver. Vi rekommenderar de senaste versionerma av Edge, Chrome, Firefox eller Safari.",
        nc_login_no_database_selected: "Ingen databas vald.",
        nc_login_enter_username: "Ange användarnamn.",
        nc_login_enter_password: "Ange lösenord.",
        enable_javascript: "Du måste aktivera Javascript för att kunna använda TimeEdit.",
        read_more: "Läs mer",
        supported_browsers: "Webbläsare som stöds",
        supported_browsers_text:
            "TimeEdit Core stödjer de två senaste versionerna av Edge, Chrome, Firefox och Safari. Äldre versioner kan fungera, men ingen support ges och ingen funktionalitet utlovas.",
        nc_login_new_user: "Skapa ny användare",
        nc_login_forgot_password: "Glömt lösenord",
        nc_login_reset_password: "Återställ lösenord",
        nc_login_reset_success_message:
            "Lösenordet har återställts och vidare instruktioner har skickats till den angivna e-postadressen.",
    },
    en: {
        login_loading: "Loading …",
        login_incorrect_user_or_password: "Incorrect username or password.",
        login_new_password_required: "Enter new password",
        login_user: "Username",
        login_email: "E-mail",
        login_password: "Password",
        login_new_password: "New password",
        login_confirm_password: "Repeat password",
        login_login: "Log in",
        login_database_not_available: "Database is not available.",
        login_cookies_disabled: "Cookies must be enabled in order to use TimeEdit.",
        login_insecure_browser:
            "This browser does not support the security required by TimeEdit. We recommend using the latest version of Edge, Chrome, Firefox or Safari.",
        nc_login_no_database_selected: "No database selected.",
        nc_login_enter_username: "Enter username.",
        nc_login_enter_password: "Enter password.",
        enable_javascript: "Javascript must be enabled in order to use TimeEdit.",
        read_more: "Read more",
        supported_browsers: "Supported browsers",
        supported_browsers_text:
            "TimeEdit Core supports the two latest versions of Edge, Chrome, Firefox and Safari. No support or functionality is guaranteed in older browsers.",
        nc_login_new_user: "Create new user",
        nc_login_forgot_password: "Forgot password",
        nc_login_reset_password: "Reset password",
        nc_login_reset_success_message:
            "The password has been reset and further instructions have been sent to the specified e-mail address.",
    },
};

export const getUserLanguage = () => window?.navigator?.language?.split("-")?.[0] ?? "en";

export const getLoginStringsForLanguage = (lang) =>
    stringMap[lang] ? stringMap[lang] : stringMap.en;
