const Model = require("./Model");
const Selection = require("./Selection");

const App = function (menu, view, user) {
    Model.call(this, "App");
    this.menu = menu || null;
    this.view = view || null;
    this.user = user || null;
    this.selection = new Selection();
    this._transient = ["menu"];
};

App.prototype = Object.create(Model.prototype);

App.prototype.setView = function (view) {
    return this.immutableSet({ view });
};

App.prototype.asProps = function () {
    // eslint-disable-next-line no-unused-vars
    const { _transient, _typeName, _modelId, ...props } = this; // jshint ignore:line
    return props;
};

module.exports = App;
