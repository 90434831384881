const Language = require("./Language");

const Tutorials = {
    // Use functions so that elements only need to exist when the function is actually invoked.
    getWelcomeSteps: (isMenuOnTheRight) => [
        {
            intro: Language.get("nc_tutorial_intro_welcome"),
        },
        {
            intro: Language.get("nc_tutorial_intro_reservation_mode"),
            element: document.querySelector("#selectionListTop"),
            position: "top",
        },
        {
            intro: Language.get("nc_tutorial_intro_selection_list"),
            element: document.querySelector("#selectionList"),
            position: "top",
        },
        {
            intro: Language.get("nc_tutorial_intro_object_list"),
            element: document.querySelector(".objectSelectList"),
            position: "top",
        },
        {
            intro: Language.get("nc_tutorial_object_filter_toggle_buttons"),
            element: document.querySelector(".objectSelectList .toggleButtons"),
            position: "top",
        },
        {
            intro: Language.get("nc_tutorial_intro_calendar"),
            element: document.querySelector(".calendar"),
        },
        {
            intro: Language.get("nc_tutorial_intro_menu"),
            element: document.querySelector("#menu"),
            position: isMenuOnTheRight ? "left" : "right",
        },
        {
            intro: Language.get("nc_tutorial_intro_reslist_1"),
        },
        {
            intro: Language.get("nc_tutorial_intro_reslist_2"),
            element: document.querySelectorAll(".topLeft")[0],
            position: "bottom",
        },
        {
            intro: Language.get("nc_tutorial_intro_reslist_3"),
        },
        {
            intro: Language.get("nc_tutorial_intro_reslist_4"),
        },
        {
            intro: Language.get("nc_tutorial_intro_reslist_5"),
            element: document.querySelector("#selectionList"),
            position: "top",
        },
    ],
};

module.exports = Tutorials;
