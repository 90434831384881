const React = require("react");
const Language = require("../../lib/Language");

// eslint-disable-next-line react/prefer-stateless-function
class TopLeft extends React.Component {
    render() {
        return (
            <div className="listTopLine">
                {this.props.topLeft}{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "10px" }} className="summaryLabel">
                    {Language.get("nc_exam")}
                </span>
                {this.props.contents}
            </div>
        );
    }
}

module.exports = TopLeft;
