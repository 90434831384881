const React = require("react");
const Language = require("../lib/Language");
const CancellationList = require("./CancellationList");
const ListCalendar = require("./ListCalendar");
const Macros = require("../models/Macros");
const Tutorial = require("../lib/Tutorial");

class ReservationListCalendar extends React.Component {
    componentDidMount() {
        this.showTutorial();
    }

    showTutorial = () => {
        Tutorial.show("cancellationList", [
            {
                intro: Language.get("nc_tutorial_cancellation_list_intro"),
                element: this._wrapper,
            },
            {
                intro: Language.get("nc_tutorial_cancellation_list_search_settings"),
                element: this._settingsButton,
            },
            {
                intro: Language.get("nc_tutorial_cancellation_list_restore_cancellations"),
                element: this._wrapper,
            },
        ]);
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
    ];

    onSelection = (selection) => {
        this.props.onInfoOpen([selection.id], false);
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"cancellationList"}
                refreshEvents={this.getRefreshEvents()}
                settingsRef={(node) => {
                    this._settingsButton = node;
                }}
                listRef={(node) => {
                    this._wrapper = node;
                }}
            >
                <CancellationList onSelection={this.onSelection} />
            </ListCalendar>
        );
    }
}

module.exports = ReservationListCalendar;
