const ObjectSettingsConstants = require("../lib/ObjectSettingsConstants");
const API = require("../lib/TimeEditAPI");
const Model = require("./Model");
const _ = require("underscore");
const FieldInput = require("../components/FieldInput");

const MAX_SEARCH_FIELDS = 20;
const MAX_RETURN_FIELDS = 10;

const caseInsensitiveSort = (a, b) => {
    const aLower = a.toLowerCase();
    const bLower = b.toLowerCase();
    if (aLower < bLower) {
        return -1;
    }
    if (aLower > bLower) {
        return 1;
    }
    return 0;
};

const getFieldValue = (obj, fieldId) => {
    const field = _.find(obj.fields, (fld) => fld.id === fieldId);
    if (field) {
        return field.values.join(", ");
    }
    return "";
};

const sortBy = (sortFieldId, sortDirection, a, b) => {
    if (sortFieldId > -1) {
        if (sortDirection === 0) {
            return caseInsensitiveSort(
                getFieldValue(b, sortFieldId),
                getFieldValue(a, sortFieldId)
            );
        }
        return caseInsensitiveSort(getFieldValue(a, sortFieldId), getFieldValue(b, sortFieldId));
    }
    if (sortDirection === 0) {
        return a.id - b.id;
    }
    return b.id - a.id;
};

const ObjectSearch = function (settings = {}, showExtraInfo = false) {
    Model.call(this, "ObjectSearch");
    this.showExtraInfo = showExtraInfo;
    this.searchFields = settings.searchFields || [];
    this.userSearchFields = settings.userSearchFields || [];
    this.allFields = settings.allFields || [];
    this.type = settings.type || null;
    this.subtypes = settings.subtypes || [];
    this.searchString = settings.searchString || "";
    this.defaultColumns = (settings.defaultColumns || []).filter((col) => col !== null);
    this.columns = settings.columns || [];
    this.cacheSortColumn = settings.cacheSortColumn || null;
    this.cacheSortOrder = settings.cacheSortOrder || 0;
    this.advancedSettings = settings.advancedSettings || {
        virtual: ObjectSettingsConstants.VIRTUAL.ALL,
        abstract: ObjectSettingsConstants.ABSTRACT.ALL,
        reserve: ObjectSettingsConstants.RESERVE.RESERVE,
        active: ObjectSettingsConstants.ACTIVE.ACTIVE,
        selectedCategories: {},
        matchAll: [], // Array of ids of the fields for which matchAll has been selected
    };
    this.reserveMode = settings.reserveMode !== undefined ? settings.reserveMode : true;
    this.otherObjectsMode = ObjectSettingsConstants.OTHER_OBJECTS.EXCLUDE;
    this.excludeReservations = settings.excludeReservations || [];

    this.helpObjects = settings.helpObjects || [];
    this.searchObjects = settings.searchObjects || [];

    this.setSearchProperty = function (propertyName, value) {
        if (_.isEqual(this[propertyName], value)) {
            return this;
        }

        return this.immutableSet({
            [propertyName]: value,
        });
    };
};

ObjectSearch.prototype = Object.create(Model.prototype);

ObjectSearch.prototype.applySettings = function (newSettings) {
    if (typeof newSettings !== "object") {
        return this;
    }

    const changeSet = Object.keys(newSettings)
        .filter((key) => !_.isEqual(this[key], newSettings[key]))
        .reduce((changes, key) => _.extend({}, changes, _.object([key], [newSettings[key]])), {});

    if (Object.keys(changeSet).length === 0) {
        return this;
    }

    const os = this.immutableSet(changeSet);
    return os;
};

ObjectSearch.prototype.setDefaultColumns = function (defaultColumns) {
    if (this.columns && this.columns.length > 0) {
        // eslint-disable-next-line no-param-reassign
        defaultColumns = defaultColumns
            .filter((col) => col !== null)
            .map((col) => _.find(this.columns, (cl) => cl.id === col.id) || col);
    }
    return this.setSearchProperty("defaultColumns", defaultColumns);
};

ObjectSearch.prototype.setType = function (type, callback) {
    if (this.type === type) {
        this.loadSearchFields(callback);
        return;
    }

    const os = this.immutableSet({
        type,
        subtypes: [],
        columns: [],
    });
    os.loadSearchFields(callback);
};

ObjectSearch.prototype.getColumnFields = function (columnTitles) {
    if (!columnTitles) {
        return [];
    }

    const results = _.compact(columnTitles.map((column) => this.getFieldById(column.id)));

    // Server always sorts by the first column in the list, so make sure our sort column is first
    results.sort((a, b) => {
        if (a.name === this.cacheSortColumn) {
            return -1;
        }
        if (b.name === this.cacheSortColumn) {
            return 1;
        }
        return 0;
    });

    return results;
};

ObjectSearch.prototype.getFieldById = function (id) {
    if (!this.allFields) {
        return null;
    }

    return _.find(this.allFields, (field) => field.id === id) || null;
};

ObjectSearch.prototype.getAllPossibleUserSearchFields = function () {
    const isValidSearchField = (fieldDef) =>
        fieldDef.kind !== FieldInput.fieldKind.CATEGORY &&
        fieldDef.kind !== FieldInput.fieldKind.BOOLEAN;
    return this.searchFields.filter((field) => isValidSearchField(field));
};

ObjectSearch.prototype.getUserSearchFields = function () {
    if (this.userSearchFields && this.userSearchFields.length > 0) {
        return this.userSearchFields;
    }

    return this.getDefaultUserSearchFields();
};

ObjectSearch.prototype.getDefaultUserSearchFields = function () {
    return this.getAllPossibleUserSearchFields()
        .map((field) => field.id)
        .slice(0, MAX_SEARCH_FIELDS);
};

ObjectSearch.prototype.setUserSearchFields = function (newFields) {
    const os = this.immutableSet({ userSearchFields: newFields });
    API.setUserSearchFields(newFields, this.type);
    return os;
};

ObjectSearch.prototype.loadSearchFields = function (callback = _.noop) {
    const columns = this.columns || [];
    if (columns.length > 0) {
        if (!_.every(columns, (column) => column.forType === this.type)) {
            // eslint-disable-next-line no-console
            console.log(
                "Showing type",
                this.type,
                "but found columns for types",
                columns.map((col) => col.forType)
            );
        } else {
            //console.log("Already have columns for", this.type, columns);
            callback(this);
            return;
        }
    }

    const type = this.type;
    if (!type) {
        const state = {
            allFields: [],
            searchFields: [],
            columns: [],
            categories: [],
        };
        callback(this.applySettings(state));
        return;
    }

    API.getFieldDefsForType(type, true, (allDefs) => {
        const defs = allDefs.map((def) =>
            _.extend({}, def, {
                forType: type,
                name: this.showExtraInfo ? `${def.name} (${def.extid})` : def.name,
            })
        );
        const categoryFields = defs.filter(
            (fieldDef) => fieldDef.kind === FieldInput.fieldKind.CATEGORY
        );
        const checkboxFields = defs.filter(
            (fieldDef) => fieldDef.kind === FieldInput.fieldKind.BOOLEAN
        );
        const columnFields = defs
            .filter((fieldDef) => fieldDef.listable)
            .filter((field, index, arr) => {
                if (field.kind !== FieldInput.fieldKind.REFERENCE) {
                    return true;
                }

                if (!field.reference_fields) {
                    return true;
                }

                // Filter out the referered fields present in the total list
                const references = field.reference_fields
                    .filter((ref) => arr.some((arrField) => arrField.id === ref.id))
                    .map((ref) => _.find(arr, (fld) => fld.id === ref.id));
                // Include if there is no reference, if there are more references (i.e. the reference field is built out of several values) or if the field is primary and the one reference isn't
                return references.length !== 1 || (field.primary && !references[0].primary);
            });

        const state = {
            allFields: defs,
            searchFields: defs.filter((fieldDef) => fieldDef.searchable === true),
            columns: columnFields.map((field) => ({
                name: field.name,
                sortable: field.sortable === true,
                primary: field.primary === true,
                id: field.id,
                forType: type,
            })),
            categories: [].concat(categoryFields).concat(checkboxFields),
        };
        let defaults = this.defaultColumns || [];
        defaults = defaults
            .filter((dc) => dc !== null)
            .filter((dc) => _.some(state.columns, (ac) => ac.id === dc.id));

        if (defaults.length === 0) {
            let defaultColumns = [];
            const firstPrimary = _.find(state.columns, (col) => col.primary);
            if (firstPrimary) {
                defaultColumns = [firstPrimary];
            } else if (state.columns.length > 0) {
                defaultColumns = [state.columns[0]];
            }
            state.defaultColumns = defaultColumns;
        }
        if (!state.defaultColumns && this.defaultColumns) {
            // Filter any default columns loaded from preferences which are no longer selectable, i.e. because a field has been removed from the type
            state.defaultColumns = _.filter(
                this.defaultColumns.filter((col) => col !== null),
                (col) => _.some(state.columns, (sc) => sc.id === col.id)
            );
        }
        state.defaultColumns = state.defaultColumns.map(
            (col) => _.find(this.columns, (cl) => cl.id === col.id) || col
        );
        // Load userSearchFields, if any, from preferences store
        API.getUserSearchFields(type, (result) => {
            if (result) {
                const allIds = state.searchFields.map((field) => field.id);
                state.userSearchFields = result.filter((fieldId) => allIds.indexOf(fieldId) !== -1); // Filter out fields not present on the type
            }
            callback(this.applySettings(state));
        });
    });
};

ObjectSearch.prototype.sort = (
    visibleColumns,
    isStaticList,
    sortColumn,
    sortDirection,
    objects
) => {
    if (!isStaticList) {
        return objects;
    }
    const sortField = _.find(visibleColumns, (col) => col.name === sortColumn);
    return objects.sort(sortBy.bind(this, sortField ? sortField.id : -1, sortDirection));
};

const GET_ALL_BATCH_SIZE = 1000;

ObjectSearch.prototype.getAll = function (callback) {
    let fullResult = [];
    let startIndex = 0;
    let total = 0;
    const processResult = (result, totalNumber) => {
        if (totalNumber !== -1) {
            total = totalNumber;
        }
        fullResult = fullResult.concat(result);
        if (fullResult.length < total) {
            startIndex = startIndex + GET_ALL_BATCH_SIZE;
            this.search(startIndex, processResult, false, GET_ALL_BATCH_SIZE);
        } else {
            callback(fullResult);
        }
    };
    this.search(startIndex, processResult, false, GET_ALL_BATCH_SIZE);
};

ObjectSearch.MAX_SEARCH_FIELDS = MAX_SEARCH_FIELDS;

ObjectSearch.prototype.search = function (
    firstIndex,
    callback,
    staticList = false,
    numberOfRows = 0,
    reservationLayer = null
) {
    if (!_.isFunction(callback)) {
        return;
    }

    let visibleColumns = this.getColumnFields(this.defaultColumns.filter((col) => col !== null));
    if (!this.type || this.searchFields.length === 0 || visibleColumns.length === 0) {
        callback([], 0);
        return;
    }

    if (visibleColumns.length > 0 && !visibleColumns[0].sortable) {
        const firstPrimary = _.find(
            this.columns.length > 0 ? this.columns : visibleColumns,
            (column) => column.sortable
        );
        const namedField = firstPrimary ? this.getFieldById(firstPrimary.id) : null;
        if (firstPrimary && namedField) {
            visibleColumns = visibleColumns.filter((column) => column.id !== firstPrimary.id);
            visibleColumns.unshift(namedField);
        }
        if (!firstPrimary) {
            // We found no sortable fields, searching will just return an error.
            callback([], 0);
            return;
        }
    }

    const startRow = firstIndex > 0 ? firstIndex : 0;
    const visibleSortableColumns = visibleColumns.filter((column) => column.sortable === true);
    const visibleIds = visibleSortableColumns.map((col) => col.id);
    const filteredFields = this.searchFields
        .filter((searchField) => visibleIds.indexOf(searchField.id) === -1)
        .filter(
            (searchField) =>
                searchField.kind !== FieldInput.fieldKind.CATEGORY &&
                searchField.kind !== FieldInput.fieldKind.BOOLEAN
        );
    let generalSearchFields = visibleSortableColumns.concat(filteredFields);
    if (this.userSearchFields && this.userSearchFields.length > 0) {
        generalSearchFields = this.searchFields.filter(
            (field) => this.userSearchFields.indexOf(field.id) !== -1
        );
    }

    const hasSearchString = this.searchString !== null && this.searchString !== "";

    let firstPrimaryField = _.find(this.defaultColumns, (column) => column && column.primary);
    if (!firstPrimaryField) {
        const fallbackPrimary = _.find(this.columns, (column) => column.primary);
        if (fallbackPrimary) {
            //console.log("No first primary field found, using fallback", fallbackPrimary);
            firstPrimaryField = fallbackPrimary;
        } else {
            callback([], 0);
            return;
        }
    }

    const getExactSearchFields = () => {
        if (!this.advancedSettings || !this.advancedSettings.selectedCategories) {
            return [];
        }

        // If the field type allows multiple values, and matchAll is set for the field type
        // Return multiple fields with a single value instead of one with multiple values
        const results = _.flatten(
            Object.keys(this.advancedSettings.selectedCategories)
                .filter((key) => this.advancedSettings.selectedCategories[key].length > 0)
                .map((key) => {
                    const values = this.advancedSettings.selectedCategories[key];
                    const keyInt = parseInt(key, 10);
                    if (values.length > 1 && values.some((value) => Array.isArray(value))) {
                        return values.map((value) => ({ id: keyInt, values: value }));
                    }
                    if (
                        this.advancedSettings.matchAll &&
                        this.advancedSettings.matchAll.indexOf(keyInt) !== -1
                    ) {
                        // Extra check if the field actually allows matchAll,
                        // Settings from AM are unaware of which fields support multiple values
                        let allowsMatchAll = false;
                        const def = _.find(this.categories, (cat) => cat.id === keyInt);
                        if (def && def.multiple) {
                            allowsMatchAll = true;
                        }
                        if (allowsMatchAll) {
                            return values.flat().map((val) => ({
                                id: keyInt,
                                values: Array.isArray(val) ? val : [val],
                            }));
                        }
                    }
                    return { id: keyInt, values: Array.isArray(values) ? values.flat() : values };
                })
        );
        return results;
    };

    const query = {
        type: this.type,
        subtypes: this.subtypes,
        generalSearchString: this.searchString,
        generalSearchFields: hasSearchString ? generalSearchFields.slice(0, MAX_SEARCH_FIELDS) : [],
        exactSearchFields: getExactSearchFields(),
        startRow,
        numberOfRows,
        beginTime: this.beginTime,
        endTime: this.endTime,
        sortOrder: this.cacheSortOrder,
        active: this.advancedSettings.active,
        reserve: this.advancedSettings.reserve,
        virtual: this.advancedSettings.virtual,
        abstract: this.advancedSettings.abstract,
        reserveMode: this.reserveMode,
        relatedObjects: this.otherObjectsMode,
        searchObjects: this.searchObjects,
        helpObjects: this.helpObjects,
        excludeObjects: this.excludeObjects,
        excludeReservations: this.excludeReservations,
        returnFields: visibleColumns.slice(0, MAX_RETURN_FIELDS),
        primaryField: firstPrimaryField.id,
        reservationLayer,
    };

    const onResult = (objects, totalNumber = objects.length) => {
        if (!this.cacheSortColumn || firstPrimaryField.name === this.cacheSortColumn) {
            if (objects.length > 0 && !objects[0].name) {
                callback(
                    objects.map((obj) => {
                        let nameField =
                            _.find(obj.fields, (field) => field.id === firstPrimaryField.id) ||
                            obj.fields[0];
                        const name = nameField.values[0];
                        return _.extend({}, obj, { name });
                    }),
                    totalNumber
                );
                return;
            }
            callback(objects, totalNumber);
            return;
        }

        callback(
            this.sort(
                visibleColumns,
                staticList,
                this.cacheSortColumn,
                query.sortOrder,
                objects.map((obj) => {
                    const name = _.find(obj.fields, (field) => field.id === firstPrimaryField.id)
                        .values[0];
                    return _.extend({}, obj, { name });
                })
            ),
            totalNumber
        );
    };

    if (staticList === true) {
        API.getObjects(this.searchObjects, onResult);
    } else {
        API.findObjects(query, onResult);
    }
};

module.exports = ObjectSearch;
