const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
import { withLDConsumer } from "launchdarkly-react-client-sdk";
const ReactDOM = require("react-dom");
const Log = require("../lib/Log");
const Sound = require("../lib/Sound");
const Reservation = require("../models/Reservation");
const McFluffy = require("../models/McFluffy");
const CalendarModel = require("../models/Calendar");

const Selection = require("../models/Selection");
const DateHeader = require("../models/DateHeader");
const DatePeriodHeader = require("../models/DatePeriodHeader");
const TimeHeader = require("../models/TimeHeader");
const TimePeriodHeader = require("../models/TimePeriodHeader");
const ObjectHeader = require("../models/ObjectHeader");
const WeekHeader = require("../models/WeekHeader");
const WeekdayHeader = require("../models/WeekdayHeader");
const WeekPeriodHeader = require("../models/WeekPeriodHeader");
const WeekdayPeriodHeader = require("../models/WeekdayPeriodHeader");
const IndexHeader = require("../models/IndexHeader");
const TC = require("../lib/TimeConstants");

const Entry = require("../models/Entry");
const { EntryKind, ClusterKind } = require("../lib/EntryConstants");
const Header = require("./Header");
import {
    MillenniumDateTime,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import { MS_PER_SECOND } from "../lib/TimeConstants";
const Mousetrap = require("@timeedit/mousetrap");
const Language = require("../lib/Language");
const _ = require("underscore");
const FieldDialog = require("./FieldDialog");
const EmailDialog = require("./EmailDialog");
const Popover = require("./Popover");
const EntryLayer = require("./EntryLayer");
const CalendarMenu = require("./CalendarMenu");
const FunctionMode = require("../models/FunctionMode");
const Macros = require("../models/Macros");
const TemplateKind = require("../models/TemplateKind");
const Grid = require("../lib/Grid");
const ToolButtons = require("./ToolButtons");
const LayerComponent = require("../lib/LayerComponent");
const ScrollWheelHandler = require("../lib/ScrollWheelHandler");
const ContextMenu = require("../lib/ContextMenu");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const APIError = require("../lib/APIError");
const ReservationStatus = require("../lib/ReservationStatus");
const DragListener = require("../lib/DragListener");
const UnsupportedCalendar = require("./UnsupportedCalendar");
const SummaryBar = require("./SummaryBar");
const SummaryWindow = require("./SummaryWindow");

const MIN_DRAG_LIMIT = 3;

const SECOND_BUTTON = 2;

const TOUCH_DRAG_TIMEOUT = 500;
const DEFAULT_DRAG_TIMEOUT = 100;
const DRAG_YELLOW_TIMEOUT = 500;
const SCROLL_LOCK_TIMEOUT = 500;
const EDIT_MODE_TIMEOUT = 500;
const ENTRY_CREATE_DRAG_TIMEOUT = 1000;
const CANCEL_DRAG_TIMEOUT = 500;

const MOUSETRAP_ESCAPE = "esc";

const NO_OFFSET = "NO_OFFSET";

const NUM_ENTRIES_IN_LARGE_RESULT = 500; // If findEntries returns more than this number, log it as a large result

const AUTO_OBJECT_TYPE_ERROR = "AUTO_OBJECT_TYPE_MISMATCH";

const Direction = { UP: 1, DOWN: 2, LEFT: 3, RIGHT: 4 };

const isSame = (entry1, entry2) =>
    _.isEqual(entry1.reservationids, entry2.reservationids) &&
    _.isEqual(entry1.headerObjects, entry2.headerObjects) &&
    entry1.isPadding === entry2.isPadding;

const hasSameTimes = (entry, entries) => {
    const startTime = entry.startTimes[0].getMillenniumTime().timeNumber;
    const endTime = entry.endTimes[0].getMillenniumTime().timeNumber;
    return _.every(
        entries,
        (etr) =>
            startTime === etr.startTimes[0].getMillenniumTime().timeNumber &&
            endTime === etr.endTimes[0].getMillenniumTime().timeNumber
    );
};

const getSummaryText = (summaries, activeFilter, filters, sumUnit) => {
    const activeSum = _.find(filters, (filter) => filter.id === activeFilter);
    if (!activeSum || summaries.length > 1) {
        return "";
    }
    return summaries.map((summary) => SummaryWindow.formatSum(summary.length, sumUnit)).join(", ");
};

const limitSlotsToDayof = (slots, entry) => {
    const weekday = entry.startTimes[0].getDay();
    const limitedSlots = slots.map((slot) => {
        const newSlot = _.clone(slot);
        newSlot.week_days = [weekday];
        newSlot.date = entry.startTimes[0].getMillenniumDate();
        return newSlot;
    });
    return limitedSlots;
};

let Calendar = createReactClass({
    displayName: "Calendar",

    propTypes: {
        data: PropTypes.instanceOf(CalendarModel).isRequired,
        selection: PropTypes.instanceOf(Selection).isRequired,
        size: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        onActiveCalendarChange: PropTypes.func.isRequired,
        onCalendarSwap: PropTypes.func,
        onClose: PropTypes.func,
        onSplit: PropTypes.func,
        showId: PropTypes.bool.isRequired,
    },

    contextTypes: {
        update: PropTypes.func,
        user: PropTypes.object,
        registerMacro: PropTypes.func,
        fireEvent: PropTypes.func,
        deregisterMacro: PropTypes.func,
        presentModal: PropTypes.func,
        prefsComponentActive: PropTypes.bool,
        examComponentActive: PropTypes.bool,
        memberCreatorActive: PropTypes.bool,
        customWeekNames: PropTypes.array,
        useNewReservationGroups: PropTypes.bool,
        isLocked: PropTypes.func,
    },

    getInitialState() {
        return {
            dragElement: null,
            groupedDragElements: null,
            dragStart: null,
            overlapView: false,
            displayFieldDialog: false,
            showSpotlight: false,
            availableTime: null,
            isAbsoluteAvailability: false,
            showEmailField: false,
            emailReservationIds: [],
            useSpotlight: false,
            spotlightCoordinates: null,
            calendarInfo: "",
            weekAndWeekdayHeaders: false,
            ghostEntry: false,
            showDateSelector: false,
            isConflictMode: false,
            displaySumWindow: false,
            selectedSummary: 0,
            selectedSumUnit: 0,
            summaries: [],
            filters: [],
            tooltip: null,
            currentDateTime: null,
            currentSlots: [],
        };
    },

    _scrollTimeout: null,
    _scrollLock: false,
    _shouldScrollDateFire: true,
    _currentTimeUpdateTimeout: null,

    componentDidMount() {
        this._isMounted = true;

        window.addEventListener(
            "contextmenu",
            (event) => {
                if (event.target.className === "entryLayer") {
                    event.preventDefault();
                    event.stopPropagation();
                }
            },
            false
        );

        if (this.props.isActive) {
            this.registerKeyboardShortcuts(this.props);
        }
        this.debounceLoadEntries(this.props);
        this.updateHeaderObjects(this.props);
        this.updateTypeFilterInfo(this.props);
        this.props.setLayerContentProvider(this.getLayerContent);
        this.props.setLayerCloseHandler(this.onLayerClose);

        const targetContains = (target, className) => {
            if (target.className && target.className.indexOf(className) !== -1) {
                return true;
            }
            if (target.parentNode) {
                return targetContains(target.parentNode, className);
            }
            return false;
        };

        const shouldScroll = (event) => {
            if (this.state.displaySumWindow && targetContains(event.target, "summaryWindow")) {
                return false;
            }
            return (!this.state ? true : this.state.dragElement === null) && !this._preventScroll;
        };
        this._handleScroll = ScrollWheelHandler.create({
            isActive: () =>
                this.isHeaderScrollable(this.props.data.getActiveHeader(true)) ||
                this.isHeaderScrollable(this.props.data.getActiveHeader(false)),
            shouldScroll,
            step: (isXAxis, stepIncreased) => {
                let scrollAxis = isXAxis ? "x" : "y";
                if (this._scrollLock && this._scrollLock !== scrollAxis) {
                    // eslint-disable-next-line no-param-reassign
                    isXAxis = !isXAxis; // Continue scrolling along locked axis
                    scrollAxis = isXAxis ? "x" : "y";
                }
                this._scrollLock = scrollAxis;
                clearTimeout(this._scrollTimeout);
                this._scrollTimeout = setTimeout(() => {
                    this._scrollLock = false;
                }, SCROLL_LOCK_TIMEOUT);
                const header = this.props.data.getActiveHeader(isXAxis);
                if (!this.isHeaderScrollable(header)) {
                    return;
                }
                if (stepIncreased) {
                    this.context.update(header, header.increaseFirstVisibleValue());
                    return;
                }
                this.context.update(header, header.decreaseFirstVisibleValue());
            },
        });
        this._entryLoadCallback = _.noop;

        this.registerMacros();
        const self = this;
        const EXTRA_WAIT_SECONDS = 30;
        API.getCurrentDateTime((result) => {
            this.setState({ currentDateTime: result, filters: this.props.filters });
            this._currentTimeUpdateTimeout = setTimeout(() => {
                API.getCurrentDateTime((res) => {
                    if (this._isMounted) {
                        this.setState({
                            currentDateTime: res,
                            filters: this.props.filters,
                        });
                        this.scheduleTimeUpdate(TC.MILLISECONDS_PER_HOUR, self);
                    }
                });
            }, (TC.SECONDS_PER_HOUR - (result.mts % TC.SECONDS_PER_HOUR) + EXTRA_WAIT_SECONDS) * MS_PER_SECOND);
        });
    },

    scheduleTimeUpdate(interval, self) {
        clearTimeout(self._currentTimeUpdateTimeout);
        // eslint-disable-next-line no-param-reassign
        self._currentTimeUpdateTimeout = setTimeout(() => {
            API.getCurrentDateTime((result) => {
                if (self._isMounted) {
                    self.setState({ currentDateTime: result }, () => {
                        self.scheduleTimeUpdate(interval, self);
                    });
                }
            });
        }, interval);
    },

    componentDidUpdate(prevProps) {
        // Previous componentWillReceiveProps
        if (!prevProps.isActive && this.props.isActive) {
            this.registerKeyboardShortcuts(this.props);
        }

        if (
            this.getSelection(this.props) !== this.getSelection(prevProps) ||
            CalendarModel.shouldLoadEntries(prevProps.data, this.props.data)
        ) {
            this.onOverlapViewClose();
            this.debounceLoadEntries(this.props);
        }
        if (prevProps.activeLayer !== this.props.activeLayer) {
            this.debounceLoadEntries(this.props);
        }

        if (!_.isEqual(this.props.data.typeFilter, prevProps.data.typeFilter)) {
            this.updateTypeFilterInfo(this.props);
        }

        if (
            this.props.id !== prevProps.id ||
            this.haveMacrosChanged(prevProps.data, this.props.data)
        ) {
            this.context.deregisterMacro(`calendar${this.props.id}`);
            this.registerMacros(this.props.id, this.props);
        }

        if (!_.isEqual(this.props.filters, this.state.filters)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ filters: this.props.filters });
        }

        const summaryWeek = this.props.data.sumWeek || SummaryWindow.ALL_WEEKS;
        const headers = this.props.data.getHeaderTypeMap();
        const clusterWeeks = headers.dateperiod
            ? headers.dateperiod.weeks.length > 0
                ? headers.dateperiod.weeks
                : headers.dateperiod.getWeeks(this.context.customWeekNames).map((dp) => dp.value)
            : this.props.data.getClusterValues();

        if (
            summaryWeek !== SummaryWindow.ALL_WEEKS &&
            !_.contains(
                clusterWeeks.map((cv) => (cv.getWeek ? cv.getWeek(true) : null)),
                summaryWeek
            )
        ) {
            this.context.update(
                this.props.data,
                this.props.data.immutableSet({
                    sumWeek: SummaryWindow.ALL_WEEKS,
                })
            );
        }

        this.updateHeaderObjects(this.props);

        // Previous componentDidUpdate
        const prevSelection = this.getSelection(prevProps);
        const selection = this.getSelection(this.props);
        if (prevSelection.isEditMode() && !selection.isEditMode()) {
            this.disableEditMode(selection.isGroupMode);
        }

        if (prevSelection.isGroupMode && !selection.isGroupMode) {
            this.endSpotlight();
        }
        if (
            !this.context.useNewReservationGroups &&
            selection.isGroupMode &&
            !prevSelection.isGroupMode
        ) {
            this.setSpotlightOnDate(
                this.props.data.spotlightDate,
                this.props.data.spotlightTime,
                this.props.data,
                this.NO_SPOTLIGHT_TIMEOUT
            );
        }

        const getDate = (headerValue) => {
            if (headerValue instanceof MillenniumWeek) {
                return headerValue.date;
            }
            return headerValue;
        };

        const prevHeaders = prevProps.data.getHeaderTypeMap();
        const headerProps = ["date", "week"];
        let fired = false;
        if (this._shouldScrollDateFire) {
            headerProps.forEach((prop) => {
                if (
                    !fired &&
                    prevHeaders[prop] &&
                    headers[prop] &&
                    prevHeaders[prop].firstVisibleValue !== headers[prop].firstVisibleValue
                ) {
                    fired = true;
                    this.context.fireEvent(
                        `calendar${this.props.id}`,
                        Macros.Event.SCROLL_DATE,
                        getDate(headers[prop].valueAt(0)),
                        getDate(prevHeaders[prop].valueAt(0))
                    );
                }
            });
        }
        if (
            prevHeaders.date &&
            headers.date &&
            prevHeaders.date.firstVisibleValue !== headers.date.firstVisibleValue &&
            this._shouldScrollDateFire &&
            !fired
        ) {
            this.context.fireEvent(
                `calendar${this.props.id}`,
                Macros.Event.SCROLL_DATE,
                headers.date.valueAt(0),
                prevHeaders.date.valueAt(0)
            );
        }

        if (
            !prevProps.data.spotlightDate &&
            this.props.data.spotlightDate &&
            this._shouldScrollDateFire
        ) {
            this.fireSetDateEvent(this.props.data.spotlightDate);
        }
        if (
            prevHeaders.time &&
            headers.time &&
            prevHeaders.time.firstVisibleValue !== headers.time.firstVisibleValue
        ) {
            this.context.fireEvent(
                `calendar${this.props.id}`,
                Macros.Event.SCROLL_TIME,
                headers.time.valueAt(0),
                prevHeaders.time.valueAt(0)
            );
        }

        if (this.props.data.useSpotlight && !prevProps.data.useSpotlight) {
            this.setSpotlightOnDate();
        }
    },

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._currentTimeUpdateTimeout);
        Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE);
        if (this._escBinding) {
            Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, this._escBinding);
        }

        this.context.deregisterMacro(`calendar${this.props.id}`);

        clearTimeout(this._entryTimeout);
        clearTimeout(this._entryStartTimeout);
        clearTimeout(this._dragTimeout);
        clearTimeout(this._spotlightTimeout);
    },

    haveMacrosChanged(data, nextData) {
        return data.scrollDayMacro !== nextData.scrollDayMacro;
    },

    addListener(eventType, callback) {
        window.addEventListener(eventType, callback);
    },

    removeListener(eventType, callback) {
        window.removeEventListener(eventType, callback);
    },

    onEntryDelete(reservationId, shouldSendEmail, cb) {
        this.onOverlapViewClose();
        const cancelReservation = () => {
            Reservation.cancel(
                reservationId,
                (wasSuccessful) => {
                    if (!wasSuccessful) {
                        return;
                    }

                    if (_.isFunction(cb)) {
                        cb();
                    }

                    if (
                        this.props.infoEntryReservationIds &&
                        _.isEqual(reservationId, this.props.infoEntryReservationIds)
                    ) {
                        this.props.onEntryInfoOpen(null);
                    }
                    this.fireReservationEvent([]);
                    this.loadEntries(this.props);
                    if (shouldSendEmail) {
                        this.emailReservation(reservationId, false);
                    }
                    if (this.props.data.selectionGroup.length > 0) {
                        this.context.update(
                            this.props.data,
                            this.props.data.immutableSet({ selectionGroup: [] })
                        );
                    }
                    const selection = this.getSelection();
                    if (selection.groups.length > 0) {
                        this.context.update(
                            selection,
                            selection.immutableSet({ groups: [], isGroupMode: false })
                        );
                    }
                },
                this.context.useNewReservationGroups
            );
        };

        const onRemember = () => {
            const user = this.context.user.immutableSet({ showConfirmEntryRemovalWarning: false });
            this.context.update(this.context.user, user);
        };

        Reservation.get(reservationId, (reservation) => {
            if (reservation[0] === null) {
                this.loadEntries(this.props);
            }
            if (_.isEqual(reservation[0].createdby, this.context.user.userId)) {
                cancelReservation();
            } else {
                if (this.context.user.showConfirmEntryRemovalWarning) {
                    const yesButton = {
                        title: Language.get("dialog_yes"),
                        cb: () => {
                            cancelReservation();
                        },
                        remember: true,
                        value: false,
                    };
                    const noButton = { title: Language.get("dialog_no"), remember: false };
                    const buttons = [yesButton, noButton];
                    this.context.presentModal(
                        <div>{<p>{Language.get("nc_confirm_entry_removal_message")}</p>}</div>,
                        "confirm_entry_removal",
                        Language.get("nc_confirm_entry_removal_title"),
                        buttons,
                        onRemember
                    );
                } else {
                    cancelReservation();
                }
            }
        });
    },

    showTooltip(newTooltip, id) {
        this.setState({ tooltip: newTooltip, tooltipId: id });
    },

    hideTooltip(id) {
        if (this.state.tooltipId !== null && id !== this.state.tooltipId) {
            return;
        }
        this.setState({ tooltip: null, tooltipId: null });
    },

    updateHeaderObjects(props) {
        const headers = props.data
            .getHeaders()
            .filter((header) => header instanceof ObjectHeader && !header.isUpdated);

        headers.forEach((header) => {
            header.updateSearchCriteria(header.searchCriteria, (updatedHeader) =>
                this.context.update(header, updatedHeader)
            );
        });
    },

    getSelection(props = this.props) {
        if (props.data.privateSelected) {
            return props.data.selection;
        }
        return props.selection;
    },

    getActiveFluffy(props = this.props) {
        const selection = this.getSelection(props);
        if (selection.fluffy) {
            return selection.fluffy;
        }

        if (props.data.privateSelected) {
            selection.createFluffy(
                null,
                props.activeLayer,
                props.data.templateKind,
                this.context.update.bind(this, selection)
            );
        }
        return null;
    },

    getEntry(
        entries = this.props.data.entries,
        reservationId = this.getSelection().getCurrentReservationId(),
        headerObjects = []
    ) {
        if (!reservationId) {
            return null;
        }

        let foundEntries = _.filter(
            entries,
            (entry) => entry.reservationids.indexOf(reservationId) !== -1
        );
        if (foundEntries.length === 1) {
            return foundEntries[0];
        }
        if (headerObjects.length > 0) {
            foundEntries = _.filter(foundEntries, (entry) =>
                _.every(headerObjects, (headerObject) =>
                    _.contains(entry.headerObjects, headerObject)
                )
            );
            if (foundEntries.length === 1) {
                return foundEntries[0];
            }
        }
        return _.sortBy(foundEntries, (entry) => entry.entrypropertyid)[0]; // If multiple entries, we want the "most current" one, right? Anything more to go by?
    },

    handleAvailability(newAvailability, isAbsoluteAvailability) {
        if (!this._isMounted) {
            return;
        }
        this.setState({
            availableTime: newAvailability,
            isAbsoluteAvailability,
        });
    },

    loadEntries(props, cb) {
        let selectedHeaderObjects = [];
        const clickedEntry = this.getEntry(
            this.props.data.entries,
            this.getSelection().getCurrentReservationId(),
            this.props.data.currentEntryHeaderObjects
        );
        if (clickedEntry) {
            selectedHeaderObjects = clickedEntry.headerObjects;
        }
        const callback = cb || _.noop;
        const searchData = {
            currentReservationId: this.getSelection().getCurrentReservationId(),
            fluffy: this.getActiveFluffy(props),
            groupObstacles: true,
            overlapCount: true,
            obstacleTextTypes: this.getSelection(props).obstacleTextTypes,
            selectedHeaderObjects,
        };

        if (!searchData.fluffy) {
            callback(this.props.data.entries);
            return;
        }

        // Don't load entries before all object headers have finished loading
        const headers = props.data
            .getHeaders()
            .filter((header) => header instanceof ObjectHeader && !header.isUpdated);

        if (headers.length > 0) {
            callback(this.props.data.entries);
            return;
        }

        props.data.findEntries(
            searchData,
            (newEntries, newAvailability, isAbsoluteAvailability, summaries) => {
                if (newEntries.length > NUM_ENTRIES_IN_LARGE_RESULT) {
                    // eslint-disable-next-line no-undef
                    mixpanel.track("Large findEntries result", {
                        "Number of entries": newEntries.length,
                        Calendar: {
                            "Has periods": this.props.data.hasPeriodHeaders(),
                            Providers: this.props.data.getProviderMap(),
                            Headers: _.mapObject(this.props.data.getHeaderTypeMap(), (val) =>
                                Boolean(val)
                            ),
                        },
                    });
                }
                const updateEntries = (entries) => {
                    if (!this._isMounted) {
                        return;
                    }

                    let overlapEntries = this.state.overlapEntries;
                    if (this.state.overlapEntries) {
                        const entryIds = _.flatten(
                            overlapEntries.map((entry) => entry.reservationids)
                        );
                        overlapEntries = _.filter(entries, (entry) =>
                            _.contains(entryIds, entry.reservationids[0])
                        );
                        if (overlapEntries.length === 0) {
                            overlapEntries = undefined;
                        }
                    }
                    if (summaries < 0) {
                        // We have not needed to handle error codes for summaries.
                        // If we ever do, this is where it should go.
                        // eslint-disable-next-line no-param-reassign
                        summaries = null;
                    }
                    this.setState({
                        overlapEntries,
                        summaries: summaries || [],
                    });
                    if (
                        this.props.data.selectionGroup.length > 0 &&
                        this.getSelection().mode !== Selection.EDIT
                    ) {
                        const selectionGroup = _.filter(this.props.data.selectionGroup, (entry) =>
                            _.some(entries, (etr) =>
                                _.isEqual(etr.reservationids, entry.reservationids)
                            )
                        );

                        if (!_.isEqual(this.props.data.selectionGroup, selectionGroup)) {
                            this.context.update(
                                this.props.data,
                                this.props.data.immutableSet({ selectionGroup, entries })
                            );
                        } else {
                            this.context.update(
                                this.props.data,
                                this.props.data.immutableSet({ entries })
                            );
                        }
                    } else {
                        this.context.update(
                            this.props.data,
                            this.props.data.immutableSet({ entries })
                        );
                    }

                    this._entryLoadCallback(entries);
                    callback(entries);
                };

                this.handleAvailability(newAvailability, isAbsoluteAvailability);

                updateEntries(newEntries);
            }
        );
    },

    _entryLoadTimeout: null,

    debounceLoadEntries(props) {
        const timeout = 50;
        clearTimeout(this._entryLoadTimeout);
        this._entryLoadTimeout = setTimeout(() => {
            this.loadEntries(props);
        }, timeout);
    },

    registerMacros(id = this.props.id, props = this.props) {
        if (props.data.scrollDayMacro) {
            this.context.registerMacro(`calendar${id}`, {
                events: [Macros.Event.SCROLL_DATE],
                actions: [
                    {
                        key: Macros.Action.SET_DATE,
                        action: (newDate, oldDate = this.props.data.getFirstVisibleDate()) => {
                            if (oldDate === null || _.isString(oldDate)) {
                                // Not always invoked with two dates, which can make a calendar ID the second parameter
                                // eslint-disable-next-line no-param-reassign
                                oldDate = this.props.data.getFirstVisibleDate();
                            }
                            const diff = newDate.getDayNumber() - oldDate.getDayNumber();
                            const date = this.props.data.getFirstVisibleDate();

                            this._shouldScrollDateFire = false;
                            const PREVENT_LOOP_TIMEOUT = 500;
                            setTimeout(() => {
                                this._shouldScrollDateFire = true;
                            }, PREVENT_LOOP_TIMEOUT);
                            this.setState({ tooltip: null, tooltipId: null });
                            this.context.update(
                                this.props.data,
                                this.props.data.gotoDateTime(
                                    date.addDays(diff),
                                    false,
                                    undefined,
                                    false
                                )
                            );
                        },
                    },
                ],
            });
            this.context.registerMacro(`calendar${id}`, {
                events: [Macros.Event.SET_DATE],
                actions: [
                    {
                        key: Macros.Action.SET_DATE,
                        action: (newDate, oldDate = this.props.data.getFirstVisibleDate()) => {
                            if (oldDate === null || _.isString(oldDate)) {
                                // Not always invoked with two dates, which can make a calendar ID the second parameter
                                // eslint-disable-next-line no-param-reassign
                                oldDate = this.props.data.getFirstVisibleDate();
                            }
                            const diff = newDate.getDayNumber() - oldDate.getDayNumber();
                            const date = this.props.data.getFirstVisibleDate();

                            this._shouldScrollDateFire = false;
                            const PREVENT_LOOP_TIMEOUT = 500;
                            setTimeout(() => {
                                this._shouldScrollDateFire = true;
                            }, PREVENT_LOOP_TIMEOUT);
                            this.setState({ tooltip: null, tooltipId: null });
                            this.context.update(
                                this.props.data,
                                this.props.data.gotoDateTime(
                                    date.addDays(diff),
                                    false,
                                    undefined,
                                    true
                                )
                            );
                        },
                    },
                ],
            });
        }
        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.PROVIDER_UPDATE],
            actions: [
                {
                    key: Macros.Action.UPDATE_PROVIDER,
                    action: (data, senderId) => {
                        this.setState({ tooltip: null, tooltipId: null });
                        this.handleProviderUpdate(data, senderId);
                    },
                },
            ],
        });
        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.SET_EXTERNAL_DATE],
            actions: [
                {
                    key: Macros.Action.SET_EXTERNAL_DATE,
                    action: (
                        newDate,
                        oldDate = this.props.data.getFirstVisibleDate(),
                        useSpotlight = true
                    ) => {
                        if (oldDate === null || _.isString(oldDate)) {
                            // Not always invoked with two dates, which can make a calendar ID the second parameter
                            // eslint-disable-next-line no-param-reassign
                            oldDate = this.props.data.getFirstVisibleDate();
                        }
                        const diff = newDate.getDayNumber() - oldDate.getDayNumber();
                        const date = this.props.data.getFirstVisibleDate();

                        if (this.props.data.setDayMacro || this.props.data.scrollDayMacro) {
                            this._shouldScrollDateFire = false;
                            const PREVENT_LOOP_TIMEOUT = 500;
                            setTimeout(() => {
                                this._shouldScrollDateFire = true;
                            }, PREVENT_LOOP_TIMEOUT);
                        }
                        this.props.setWeeks(
                            [
                                newDate.getMillenniumWeek(
                                    Language.firstDayOfWeek,
                                    Language.daysInFirstWeek
                                ),
                            ],
                            () => {
                                this.setState({ tooltip: null, tooltipId: null });
                                this.context.update(
                                    this.props.data,
                                    this.props.data.gotoDateTime(
                                        date.addDays(diff),
                                        useSpotlight,
                                        undefined,
                                        true
                                    )
                                );
                            }
                        );
                    },
                },
            ],
        });
        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.SELECT_ORDER, Macros.Event.SELECT_RESERVATION],
            actions: [
                {
                    key: Macros.Action.SET_RESERVATION,
                    action: (reservation, senderId) => {
                        if (
                            this.props.data.privateSelected &&
                            TemplateKind.equals(
                                this.props.data.templateKind,
                                TemplateKind.RESERVATION
                            ) // DEV-4091: Info and availability calendars have a private selection, but we still want to be able to go to clicked reservations
                        ) {
                            return;
                        }
                        if (senderId.indexOf("calendar") === 0) {
                            return;
                        }
                        const hasStatus = (status) =>
                            _.some(
                                reservation.status,
                                (statusObject) => statusObject.status === status.id
                            );
                        const isWaitingList = hasStatus(ReservationStatus.E_WAITING_LIST);
                        this.setState({ tooltip: null, tooltipId: null });
                        this.setCurrentReservation(
                            reservation.id,
                            (selection, newSelection) => {
                                const reservationGroups = reservation.group
                                    ? [reservation.group.id]
                                    : [];
                                if (!_.isEqual(newSelection.groups, reservationGroups)) {
                                    const groupSelection = newSelection
                                        .setGroups(reservationGroups)
                                        .immutableSet({ isGroupMode: false });
                                    this.context.update(selection, groupSelection);
                                } else {
                                    this.context.update(selection, newSelection);
                                }
                                if (!isWaitingList) {
                                    if (
                                        this.props.data.setDayMacro ||
                                        this.props.data.scrollDayMacro
                                    ) {
                                        this._shouldScrollDateFire = false;
                                        const PREVENT_LOOP_TIMEOUT = 500;
                                        setTimeout(() => {
                                            this._shouldScrollDateFire = true;
                                        }, PREVENT_LOOP_TIMEOUT);
                                    }
                                    this.context.update(
                                        this.props.data,
                                        this.props.data.gotoReservation(reservation)
                                    );
                                }
                            },
                            false,
                            isWaitingList,
                            isWaitingList,
                            reservation.length
                        );
                    },
                },
                {
                    key: Macros.Action.SET_ORDER,
                    action: (orderDef) => {
                        if (this.props.data.privateSelected) {
                            return;
                        }
                        this.setState({ tooltip: null, tooltipId: null });
                        this.context.update(this.props.data, this.props.data.gotoOrder(orderDef));
                    },
                },
            ],
        });

        // Only invoked by macro events
        const setAndEditReservation = (reservations) => {
            // eslint-disable-next-line no-param-reassign
            reservations = _.asArray(reservations);
            this.setCurrentReservation(
                reservations.map((res) => res.id),
                (selection, newSelection) => {
                    this.context.update(selection, newSelection);
                    if (this.props.data.setDayMacro || this.props.data.scrollDayMacro) {
                        this._shouldScrollDateFire = false;
                        const PREVENT_LOOP_TIMEOUT = 500;
                        setTimeout(() => {
                            this._shouldScrollDateFire = true;
                        }, PREVENT_LOOP_TIMEOUT);
                    }
                    const updatedData = this.props.data.gotoReservation(reservations[0], false);
                    this._entryLoadCallback = (entries) => {
                        const currentEntry = this.getEntry(entries, reservations[0].id);
                        if (!currentEntry) {
                            return;
                        }
                        this.enableEditMode(
                            currentEntry,
                            {
                                selection,
                                data: updatedData,
                            },
                            (sel, finalSelection) => {
                                this.context.update(selection, finalSelection);
                            }
                        );
                        this._entryLoadCallback = _.noop;
                    };
                    this.context.update(this.props.data, updatedData);
                },
                false,
                true
            );
        };

        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.CHANGE_RESERVATION_OBJECTS], // Only fired by SelectionPane for editing group reservations.
            // If that changes, we may not want to work on just the active calendar
            // I.e. If some persistent element were to fire this, the active calendar could be a reservation list or something
            // But since the group pane is only visible for calendars, we know we have one as the active view section
            actions: [
                {
                    key: Macros.Action.CHANGE_RESERVATION_OBJECTS,
                    action: (reservations) => {
                        if (this.props.isActive) {
                            setAndEditReservation(reservations);
                        }
                    },
                },
            ],
        });

        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.SELECT_REQUEST],
            actions: [
                {
                    key: Macros.Action.SET_REQUEST,
                    action: setAndEditReservation,
                },
            ],
        });

        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.RESERVATION_MADE_OR_MODIFIED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    action: () => {
                        this.onOverlapViewClose();
                        this.debounceLoadEntries(this.props);
                    },
                },
            ],
        });

        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.PRIMARY_FIELD_CHANGED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    action: (_typeId, fromFindObjects) => {
                        this.onOverlapViewClose();
                        if (fromFindObjects !== true) {
                            this.debounceLoadEntries(this.props);
                        }
                    },
                },
            ],
        });

        this.context.registerMacro(`calendar${id}`, {
            events: [Macros.Event.RESERVATION_RESTORED],
            actions: [
                {
                    key: Macros.Action.SET_RESERVATION,
                    action: (reservationId) => {
                        if (this.props.data.privateSelected) {
                            return;
                        }
                        this.setCurrentReservation(reservationId, (selection, newSelection) => {
                            this._shouldScrollDateFire = false;
                            const PREVENT_LOOP_TIMEOUT = 500;
                            setTimeout(() => {
                                this._shouldScrollDateFire = true;
                            }, PREVENT_LOOP_TIMEOUT);
                            this.context.update(selection, newSelection);
                            Reservation.get(reservationId, (reservations) => {
                                this.context.update(
                                    this.props.data,
                                    this.props.data.gotoReservation(reservations[0])
                                );
                            });
                        });
                    },
                },
            ],
        });
    },

    fireSetDateEvent(date) {
        this.context.fireEvent(`calendar${this.props.id}`, Macros.Event.SET_DATE, date);
    },

    handleProviderUpdate(data, sender) {
        if (sender === this.props.data._modelId || !this.props.data.scrollDayMacro) {
            return null;
        }
        const providers = this.props.data.getProviderMap();
        const headers = this.props.data.getHeaderTypeMap();

        if (data.length === 0) {
            return null;
        }

        if (providers.week && !providers.weekday) {
            const provider = headers.week || headers.weekperiod;
            if (data[0] instanceof MillenniumWeek) {
                return null;
            }
            if (provider) {
                return this.context.update(provider, provider.immutableSet({ weekdays: data }));
            }
        }

        if (providers.weekday && !providers.week) {
            const provider = headers.weekday || headers.weekdayperiod;
            if (data[0] instanceof MillenniumWeekday) {
                return null;
            }
            if (provider) {
                return this.context.update(provider, provider.immutableSet({ weeks: data }));
            }
        }

        if (providers.date) {
            const provider = headers.dateperiod;
            if (provider) {
                return this.context.update(provider, provider.setWeeks(data));
            }
        }
        return null;
    },

    registerKeyboardShortcuts() {
        Mousetrap.unbindWithHelp(".");
        Mousetrap.bindWithHelp(
            ".",
            this.gotoToday,
            undefined,
            Language.get("nc_calendar_go_to_today.")
        );
        Mousetrap.bindWithHelp(
            "right",
            this.moveEntry.bind(this, 1, 0, true),
            undefined,
            Language.get("nc_calendar_move_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "left",
            this.moveEntry.bind(this, -1, 0, true),
            undefined,
            Language.get("nc_calendar_move_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "up",
            this.moveEntry.bind(this, 0, -1, true),
            undefined,
            Language.get("nc_calendar_move_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "down",
            this.moveEntry.bind(this, 0, 1, true),
            undefined,
            Language.get("nc_calendar_move_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "shift+right",
            this.moveEntry.bind(this, 1, 0, false),
            undefined,
            Language.get("nc_calendar_move_selected_reservation_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+left",
            this.moveEntry.bind(this, -1, 0, false),
            undefined,
            Language.get("nc_calendar_move_selected_reservation_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+up",
            this.moveEntry.bind(this, 0, -1, false),
            undefined,
            Language.get("nc_calendar_move_selected_reservation_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+down",
            this.moveEntry.bind(this, 0, 1, false),
            undefined,
            Language.get("nc_calendar_move_selected_reservation_fine.")
        );
        Mousetrap.bindWithHelp(
            "mod+backspace",
            this.deleteSelectedEntries,
            undefined,
            Language.get("nc_calendar_cancel_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "mod+del",
            this.deleteSelectedEntries,
            undefined,
            Language.get("nc_calendar_cancel_selected_reservation.")
        );
        Mousetrap.bindWithHelp(
            "mod+up",
            this.resizeCurrentEntry.bind(this, Direction.UP, true),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time.")
        );
        Mousetrap.bindWithHelp(
            "mod+down",
            this.resizeCurrentEntry.bind(this, Direction.DOWN, true),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time.")
        );
        Mousetrap.bindWithHelp(
            "mod+left",
            this.resizeCurrentEntry.bind(this, Direction.LEFT, true),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time.")
        );
        Mousetrap.bindWithHelp(
            "mod+right",
            this.resizeCurrentEntry.bind(this, Direction.RIGHT, true),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time.")
        );
        Mousetrap.bindWithHelp(
            "shift+mod+up",
            this.resizeCurrentEntry.bind(this, Direction.UP, false),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+mod+down",
            this.resizeCurrentEntry.bind(this, Direction.DOWN, false),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+mod+left",
            this.resizeCurrentEntry.bind(this, Direction.LEFT, false),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time_fine.")
        );
        Mousetrap.bindWithHelp(
            "shift+mod+right",
            this.resizeCurrentEntry.bind(this, Direction.RIGHT, false),
            undefined,
            Language.get("nc_calendar_adjust_selected_reservation_time_fine.")
        );
        Mousetrap.bindWithHelp(
            "mod+v",
            this.onEntryPaste,
            undefined,
            Language.get("nc_paste_reservation.")
        );
    },

    enableTemporaryEscapeBinding(cb = _.noop) {
        const self = this;
        const prevEscBinding = Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE, true);
        Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, () => {
            self.handleCancelEntryDrag();
            cb();
            Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE);
            if (prevEscBinding[0]) {
                Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, prevEscBinding[0]);
            }
        });

        const upListener = function () {
            Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE);
            if (prevEscBinding[0]) {
                Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, prevEscBinding[0]);
            }
            self.removeListener("mouseup", upListener);
        };
        self.addListener("mouseup", upListener);
    },

    deleteSelectedEntries() {
        const entry = this.getEntry();
        if (!entry && this.props.data.selectionGroup.length === 0) {
            return;
        }
        if (this.props.data.selectionGroup.length > 0) {
            this.onEntryDelete(
                [..._.flatten(this.props.data.selectionGroup.map((etr) => etr.reservationids))],
                false,
                (success) => {
                    if (success) {
                        this.context.update(
                            this.props.data,
                            this.props.data.immutableSet({ selectionGroup: [] })
                        );
                    }
                }
            );
        } else {
            this.onEntryDelete(entry.reservationids);
        }
    },

    resizeCurrentEntry(direction, coarseInterval, evt) {
        if (this._moveInProgress === true) {
            return;
        }
        this._moveInProgress = true;
        evt.preventDefault();

        if (this.props.data.hideObstacles || this.props.data.readOnly) {
            this._moveInProgress = false;
            return;
        }

        const entry =
            this.state.transientEntry ||
            this.getEntry(
                this.props.data.entries,
                this.getSelection().getCurrentReservationId(),
                this.props.data.currentEntryHeaderObjects
            );
        if (!entry) {
            this._moveInProgress = false;
            return;
        }

        if (!this.props.data.xHeader.hasTime() && !this.props.data.yHeader.hasTime()) {
            this._moveInProgress = false;
            return;
        }

        const activeFluffy = this.getActiveFluffy();

        let timeStep = activeFluffy.rules.minor_step;
        if (coarseInterval) {
            timeStep = activeFluffy.rules.major_step;
        }
        const resizedEntry = _.clone(entry);
        let findNextShorterSlot = false;
        if (_.contains([Direction.UP, Direction.LEFT], direction)) {
            resizedEntry.endTimes = entry.endTimes.map((time) => time.addSeconds(-timeStep));
            findNextShorterSlot = true;
        } else {
            resizedEntry.endTimes = entry.endTimes.map((time) => time.addSeconds(timeStep));
        }

        if (activeFluffy.rules.time_slots_only) {
            //     rules, length,usePreferred, useHighResolution,fixedProperty,isCreate
            resizedEntry.applyRules(
                activeFluffy.rules,
                activeFluffy.length,
                false,
                false,
                "start",
                false,
                findNextShorterSlot
            );
        }

        const allowAvailabilityOverlap = _.isModKey(evt);

        this.setState({ transientEntry: resizedEntry });
        if (!coarseInterval) {
            // eslint-disable-next-line no-undef
            //mixpanel.track("Minor step used", {});
        }
        this.saveReservations(
            resizedEntry,
            {
                allowAvailabilityOverlap,
                originalHeaderObjects: resizedEntry.objects, // Assumes (true as of 2016-12-21) that a resize can never result in different objects, i.e. a change of header objects.
            },
            () => {
                this._moveInProgress = false;
                this.setState({ transientEntry: null });
            }
        );
    },

    _moveInProgress: false,

    moveEntry(x, y, coarseInterval, event) {
        if (this._moveInProgress === true) {
            return;
        }
        this._moveInProgress = true;
        const model = this.props.data;
        let entry =
            this.state.transientEntry ||
            this.getEntry(
                this.props.data.entries,
                this.getSelection().getCurrentReservationId(),
                model.currentEntryHeaderObjects
            );
        if (!entry) {
            this._moveInProgress = false;
            return;
        }
        entry = model.getEntryWithTimeSlots(entry);

        if (model.hideObstacles || model.readOnly) {
            this._moveInProgress = false;
            return;
        }

        if (!entry) {
            this._moveInProgress = false;
            return;
        }

        if (this.getSelection().isEditMode()) {
            this._moveInProgress = false;
            return;
        }

        const xHeaders = model.xHeader.getHeaders();
        const yHeaders = model.yHeader.getHeaders();
        const xIndexes = xHeaders.map((header) => header.indexOf(entry, true));
        const yIndexes = yHeaders.map((header) => header.indexOf(entry, true));

        let timeStep = this.getActiveFluffy().rules.minor_step;
        if (coarseInterval) {
            timeStep = this.getActiveFluffy().rules.major_step;
        }

        let xDateIndex = xHeaders.length - 1;
        if (xHeaders[xDateIndex] instanceof ObjectHeader) {
            xDateIndex = -1;
        }
        let yDateIndex = yHeaders.length - 1;
        if (yHeaders[yDateIndex] instanceof ObjectHeader) {
            yDateIndex = -1;
        }
        const xTimeIndex = xHeaders.findIndex(
            (header) => header instanceof TimeHeader || header instanceof TimePeriodHeader
        );
        const yTimeIndex = yHeaders.findIndex(
            (header) => header instanceof TimeHeader || header instanceof TimePeriodHeader
        );

        if (xTimeIndex > -1 && x !== 0) {
            xIndexes[xTimeIndex] =
                xIndexes[xTimeIndex] +
                x * (timeStep / (xHeaders[xTimeIndex].discreteStep || timeStep));
        } else if (yTimeIndex > -1 && y !== 0) {
            yIndexes[yTimeIndex] =
                yIndexes[yTimeIndex] +
                y * (timeStep / (yHeaders[yTimeIndex].discreteStep || timeStep));
        } else if (xDateIndex > -1 && x !== 0) {
            xIndexes[xDateIndex] = xIndexes[xDateIndex] + x;
        } else if (yDateIndex > -1 && y !== 0) {
            yIndexes[yDateIndex] = yIndexes[yDateIndex] + y;
        }

        const lockedProperty = model.hasHeaderType(TimePeriodHeader) ? null : "length";
        if (xIndexes[0] >= xHeaders[0].visibleValues || yIndexes >= yHeaders[0].visibleValues) {
            this._moveInProgress = false;
            return;
        }
        try {
            const movedEntry = this.updateEntry(entry, xIndexes, yIndexes, lockedProperty);
            if (
                movedEntry.startTimes.length !== movedEntry.endTimes.length ||
                !_.isEqual(entry.periods, movedEntry.periods)
            ) {
                this.handleCancelEntryDrag();
                this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
                this.removeListener(_.getEndEvent(event), this.endEntryCreate);
                this._moveInProgress = false;
                return;
            }
            const allowAvailabilityOverlap = _.isModKey(event);

            const oldHeaderObjects = model.getObjectsFromIndexes(xIndexes, yIndexes) || [];

            this._moveInProgress = true;
            if (!this.state.ghostEntry) {
                this.setState({ transientEntry: movedEntry });
                if (!coarseInterval) {
                    // eslint-disable-next-line no-undef
                    //mixpanel.track("Minor step used", {});
                }
                this.saveReservations(
                    movedEntry,
                    {
                        allowAvailabilityOverlap,
                        originalHeaderObjects: _.pluck(oldHeaderObjects, "id"),
                    },
                    () => {
                        this._moveInProgress = false;
                        this.setState({ transientEntry: null });
                    }
                );
            }
        } catch (error) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            this._moveInProgress = false;
            return;
        }
    },

    getMenuItems() {
        const menuItems = [];
        const self = this;
        const isMassChangeActive = this.props.activeLayer !== 0;

        menuItems.push({
            key: "calendar.switch",
            label: Language.get("cal_res_side_view_flip_axes"),
            action: () => {
                // eslint-disable-next-line no-undef
                //mixpanel.track("Flip Axes", {});
                self.context.update(self.props.data, self.props.data.switchHeaders());
            },
        });

        if (this.props.onSplit) {
            menuItems.push({
                key: "calendar.splitX",
                label: Language.get("cal_header_topleft_spilt_vertical"),
                action: () => {
                    self.props.onSplit(true);
                },
            });

            menuItems.push({
                key: "calendar.splitY",
                label: Language.get("cal_header_topleft_split_horizontal"),
                action: () => {
                    self.props.onSplit(false);
                },
            });
        }

        menuItems.push({
            isSeparator: true,
        });

        menuItems.push({
            key: "calendar.toList",
            label: Language.get("nc_cal_header_topleft_to_reservation_list"),
            action: () => {
                self.props.toList();
            },
        });

        menuItems.push({
            key: "calendar.toCancellationList",
            label: Language.get("nc_cancellation_list_title"),
            isDisabled: isMassChangeActive,
            action() {
                self.props.toCancellationList();
            },
        });

        menuItems.push({
            key: "calendar.toOrderList",
            label: Language.get("nc_cal_header_topleft_to_order_list"),
            isDisabled: isMassChangeActive,
            action: () => {
                self.props.toOrderList();
            },
        });

        menuItems.push({
            key: "calendar.toWaitingList",
            label: Language.get("dynamic_reserv_list_reserv_wl_wl_title"),
            action: () => {
                self.props.toWaitingList();
            },
        });

        menuItems.push({
            key: "calendar.toRequestList",
            label: Language.get("nc_cal_header_topleft_to_request_list"),
            isDisabled: isMassChangeActive,
            action: () => {
                self.props.toRequestList();
            },
        });

        menuItems.push({
            key: "calendar.toConflictList",
            label: Language.get("nc_conflict_list"),
            isDisabled: isMassChangeActive,
            action: () => {
                self.props.toConflictList();
            },
        });

        if (this.context.prefsComponentActive) {
            menuItems.push({
                key: "calendar.tePrefsComponent",
                label: Language.get("nc_activity_manager"),
                action() {
                    self.props.toPrefsComponent();
                },
            });
        }

        if (this.context.examComponentActive) {
            menuItems.push({
                key: "calendar.teExamComponent",
                label: Language.get("nc_exam"),
                action() {
                    self.props.toExamComponent();
                },
            });
        }

        if (this.context.memberCreatorActive) {
            menuItems.push({
                key: "calendar.toMemberObjectCreator",
                label: Language.get("nc_object_manager"),
                action() {
                    self.props.toMemberCreator();
                },
            });
        }

        menuItems.push({ isSeparator: true });
        menuItems.push({
            key: "calendar.createGroup",
            label: Language.get("nc_create_reservation_group"),
            action: () => {
                if (
                    (this.props.data.isLegalGroup(this.context.useNewReservationGroups) &&
                        this.props.data.selectionGroup.length > 1) ||
                    (this.context.useNewReservationGroups &&
                        this.props.data.selectionGroup.length === 0)
                ) {
                    self.createGroup();
                } else {
                    self.beginGroup();
                }
            },
        });

        if (this.props.onClose) {
            menuItems.push({
                isSeparator: true,
            });
            menuItems.push({
                key: "calendar.close",
                label: Language.get("nc_calendar_close"),
                action: self.props.onClose,
            });
        }

        return menuItems;
    },

    swapHeaders(isXAxis) {
        this.context.update(this.props.data, this.props.data.moveHeaderDown(isXAxis));
    },

    setHeader(isXAxis, newHeader) {
        this.context.update(this.props.data, this.props.data.setHeader(isXAxis, newHeader));
    },

    getIndexesFromCoordinates(coords) {
        const offset = _.nodeOffset(ReactDOM.findDOMNode(this.refs.entryLayer));
        return {
            x: this.getIndexes(Math.max(coords.x - offset.left, 0), true),
            y: this.getIndexes(Math.max(coords.y - offset.top, 0), false),
        };
    },

    getIndexes(position, useXHeader) {
        const header = useXHeader ? this.props.data.xHeader : this.props.data.yHeader;
        let size = useXHeader ? this.getEntryLayerSize().width : this.getEntryLayerSize().height;

        return header.getHeaders().map((hdr) => {
            // eslint-disable-next-line no-param-reassign
            position = Math.min(position, size);
            const index = Grid.getIndexFromPosition(
                position,
                hdr.visibleValues,
                size,
                hdr.isContinuous === true
            );
            // eslint-disable-next-line no-param-reassign
            position = position - Grid.getPositionFromIndex(index, hdr.visibleValues, size);
            size = Grid.getSizeFromIndexes(index, index + 1, hdr.visibleValues, size);
            return index;
        });
    },

    getCoordinateFromIndexes(indexes, useXHeader, header) {
        const headers = header.getHeaders();
        let size = useXHeader ? this.getEntryLayerSize().width : this.getEntryLayerSize().height;

        return indexes.reduce((pos, index, i) => {
            const headerPos = Grid.getPositionFromIndex(index, headers[i].visibleValues, size);
            size = Grid.getSizeFromIndexes(index, index + 1, headers[i].visibleValues, size);
            return pos + headerPos;
        }, 0);
    },

    // eslint-disable-next-line no-unused-vars
    isHeaderScrollable(header) {
        return true;
    },

    getHeaderSize(headers, sizeProperty) {
        return _.reduce(
            headers,
            (size, header) => {
                // eslint-disable-next-line no-param-reassign
                size =
                    size +
                    (!header.size || header.size < sizeProperty ? sizeProperty : header.size);
                return size + (header.showInfo ? sizeProperty : 0);
            },
            0
        );
    },

    getXHeaderHeight() {
        return this.getHeaderSize(this.props.data.xHeader.getHeaders(true), Header.MIN_X_SIZE);
    },

    getYHeaderWidth() {
        return this.getHeaderSize(this.props.data.yHeader.getHeaders(true), Header.MIN_Y_SIZE);
    },

    getEntryLayerSize() {
        const yWidth = this.getYHeaderWidth();
        const xHeight = this.getXHeaderHeight();

        return {
            width: this.props.size.width - yWidth,
            height: this.props.size.height - xHeight,
        };
    },

    getTimeForPosition(x, y, isStartTime) {
        const offset = _.nodeOffset(ReactDOM.findDOMNode(this.refs.entryLayer));
        const xIndexes = this.getIndexes(x - offset.left, true);
        const yIndexes = this.getIndexes(y - offset.top, false);
        let times = this.props.data.getTimeFromIndexes(xIndexes, yIndexes, isStartTime);
        try {
            times = MillenniumDateTime.createFromList(times);
        } catch (error) {
            times = [];
        }
        return _.asArray(times);
    },

    handleMouseDown(event) {
        this.setActive();
        this.closeDateSelect(event);
    },

    setActive() {
        if (!this.props.isActive) {
            this.props.onActiveCalendarChange(this.props.id);
        }
    },

    closeDateSelect(event) {
        if (!this.state.showDateSelector) {
            return;
        }
        let target = event.target;
        while (target !== null) {
            if (target === ReactDOM.findDOMNode(this._navbutton)) {
                return;
            }
            target = target.parentNode;
        }
        this.setState({
            showDateSelector: false,
        });
    },

    onFieldDialogDismissed(newFields) {
        const fields = newFields.map((field) => ({
            field,
            max: 0,
            min: 0,
        }));
        this.saveReservations(this.state.dragElement, {
            newFieldItems: fields,
            allowEditMode: true,
            autoObject: this.state.autoObject,
            didAuto: this.state.autoObject !== undefined && this.state.autoObject !== null,
            createGroup: this.state.createGroup || false,
        });
        this.props.hideLayer();
        if (this._isMounted) {
            this.setState({
                displayFieldDialog: false,
                autoObject: undefined,
                didAuto: undefined,
            });
        }
    },

    fireReservationEvent(ids, isNewReservation) {
        this.context.fireEvent(
            `calendar${this.props.id}`,
            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
            ids,
            isNewReservation
        );
    },

    isEmptyGroup(groups) {
        return groups.length === 0 || groups[0] === 0;
    },

    playSound(path) {
        const BASE_URL = window.CDN_HOST;
        if (this.context.user.mute === false) {
            Sound.play(`${BASE_URL}${path}`);
        }
    },

    // newFieldItems, allowEditMode = false, allowAvailabilityOverlap = false, autoObject = null, mcFluffy = this.getActiveFluffy(), didAuto = false, callback = _.noop
    saveReservations(entry, opts, callback = _.noop) {
        // TODO: If the entry is a size reservation and has a capacity reservation,
        // we should add it to the fluffy information
        console.log(entry.capacityReservationIds);
        const defaults = {
            newFieldItems: null,
            allowEditMode: false,
            allowAvailabilityOverlap: false,
            autoObject: null,
            mcFluffy: this.getActiveFluffy(),
            didAuto: false,
            originalHeaderObjects: [],
            createGroup: false,
        };
        const settings = _.extend({}, defaults, opts);

        if (settings.createGroup) {
            this.enableEditMode(entry, {
                createGroup: settings.createGroup,
                preservePreferDouble: true,
            });
            this.setState({
                dragType: null,
            });
            return;
        }

        const fluffy = McFluffy.create(settings.mcFluffy.toJson(), settings.mcFluffy.labels);
        const allowIncomplete = settings.mcFluffy.allowsIncomplete();
        fluffy.availability_overlap = settings.allowAvailabilityOverlap;
        if (settings.newFieldItems) {
            fluffy.fieldItems = settings.newFieldItems;
        }

        const E_9011 = -9011;
        const E_9017 = -9017;

        const saveDone = function (result, ids) {
            if (result instanceof APIError) {
                Log.warning(result.message, { code: result.code });
                if (settings.allowEditMode && _.contains([E_9011, E_9017], result.code)) {
                    this.enableEditMode(entry, {
                        fieldItems: settings.newFieldItems,
                        preservePreferDouble: true,
                    }); // Keep track of new fields, otherwise they are lost in the editing process
                    this.playSound("/sounds/notice.wav");
                    this.handleCancelEntryDrag(false);
                    return;
                }
                this.playSound("/sounds/notice.wav");
                this.handleCancelEntryDrag();
            } else {
                // eslint-disable-next-line no-undef
                mixpanel.track("Reservations made", {
                    "Number of reservations": entry.startTimes.length,
                    Calendar: {
                        "Has periods": this.props.data.hasPeriodHeaders(),
                        Providers: this.props.data.getProviderMap(),
                        Headers: _.mapObject(this.props.data.getHeaderTypeMap(), (val) =>
                            Boolean(val)
                        ),
                    },
                });

                const finishPostSave = (oldSel, sel) => {
                    this.playSound("/sounds/click.wav");
                    // Current fluffy item should not contain the auto object
                    this.fireReservationEvent(ids, true);

                    let newData = this.props.data;
                    if (!this.isEmptyGroup(sel.groups)) {
                        newData = this.props.data.immutableSet({ selectionGroup: [] });
                        if (!sel.isGroupMode) {
                            this.context.update(this.props.data, newData);
                        }
                    }
                    const handleGroupMode = (oldSelection, newSelection) => {
                        if (sel.isGroupMode) {
                            this.context.update(
                                newData,
                                newData.immutableSet({
                                    spotlightDate: this.context.useNewReservationGroups
                                        ? null
                                        : entry.startTimes[0].getMillenniumDate(),
                                })
                            );
                            const newEntry = _.clone(entry);
                            newEntry.reservationids = [];
                            this.context.update(
                                oldSelection,
                                newSelection.enableEditMode(newEntry, true)
                            );
                            if (!this.context.useNewReservationGroups) {
                                this.setSpotlightOnDate(
                                    entry.startTimes[0].getMillenniumDate(),
                                    null,
                                    newData,
                                    this.NO_SPOTLIGHT_TIMEOUT
                                );
                            }

                            return true;
                        }
                        return false;
                    };
                    if (!this.state.previousReservationId && !settings.didAuto) {
                        this.setCurrentReservation(
                            ids,
                            (oldSelection, newSelection) => {
                                if (!handleGroupMode(oldSelection, newSelection)) {
                                    this.context.update(oldSelection, newSelection);
                                }
                            },
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            true,
                            sel
                        );
                    } else {
                        if (!handleGroupMode(oldSel, sel)) {
                            this.context.update(oldSel, sel);
                        }
                    }
                    this.debounceLoadEntries(this.props);
                    callback();
                    this.handleCancelEntryDrag();
                };

                const selection = this.getSelection();
                if (selection.isGroupMode) {
                    this.handleAddMode(entry, ids, selection, (newSelection) => {
                        finishPostSave(selection, newSelection);
                    });
                } else {
                    finishPostSave(selection, selection);
                }
            }
        };

        // Placing a waiting list reservation in time.
        let isWaitingListReservation = false;
        const selection = this.getSelection();
        if (selection.reservations && selection.isWaitingListMode()) {
            isWaitingListReservation = true;
            // eslint-disable-next-line no-param-reassign
            entry.reservationids = selection.reservations;
        }

        if (entry.reservationids.length > 0 && !selection.isWaitingListMode()) {
            const reloadEntries = (resultingIds, hadPreviousReservation) => {
                if (!hadPreviousReservation && !_.isNullish(resultingIds)) {
                    this.setCurrentReservation(resultingIds);
                }
                this.loadEntries(this.props, () => {
                    callback();
                    this.handleCancelEntryDrag();
                });
            };

            const move = (toMove, postMove, hadPreviousReservation) => {
                const performMove = () => {
                    const allowDoubleObjects = fluffy.objectItems
                        .filter((item) => item.double)
                        .map((item) => item.object);
                    Reservation.move(
                        toMove,
                        allowDoubleObjects,
                        settings.allowAvailabilityOverlap,
                        settings.originalHeaderObjects,
                        (result, texts = [], resultingIds) => {
                            API.getPreferences(
                                `dismissedModalDialogs.assymetricMoveResult`,
                                (value) => {
                                    if (!value && texts.length > 0) {
                                        this.context.presentModal(
                                            <div>
                                                {texts.map((text, index) => (
                                                    <p key={index}>{text}</p>
                                                ))}
                                            </div>,
                                            "assymetricMoveResult",
                                            Language.get("dynamic_object_info_save_result")
                                        );
                                    }
                                    if (result instanceof APIError) {
                                        Log.warning(result.message, { code: result.code });
                                    } else {
                                        this.fireReservationEvent(resultingIds);
                                    }
                                    const sel = this.getSelection();
                                    if (sel.isWaitingListMode()) {
                                        this.context.update(sel, sel.disableWaitingListMode());
                                    }
                                    postMove(resultingIds, hadPreviousReservation);
                                }
                            );
                        },
                        this.context.useNewReservationGroups
                    );
                };

                // Add check here, if using new reservation groups check if we have the whole group
                // Otherwise display a warning
                if (
                    toMove[0].groups &&
                    toMove[0].groups.length > 0 &&
                    this.context.useNewReservationGroups
                ) {
                    const groups = toMove[0].groups;
                    const reservationIds = _.uniq(
                        _.flatten(toMove.map((etr) => etr.reservationids))
                    );
                    API.findReservationsList(
                        {
                            allReservations: true,
                            groupIds: groups,
                            useModifyPermission: false,
                        },
                        (result) => {
                            const allGroupReservationIds = result.parameters[0].map(
                                (res) => res.id
                            );
                            const groupIdsInEntries = [];
                            allGroupReservationIds.forEach((reservationId) => {
                                if (reservationIds.indexOf(reservationId) !== -1) {
                                    groupIdsInEntries.push(reservationId);
                                }
                            });
                            if (
                                !this.props.skipConfirmation &&
                                groupIdsInEntries.length !== allGroupReservationIds.length
                            ) {
                                // TODO Localize
                                this.context.presentModal(
                                    <p>{`${
                                        groupIdsInEntries.length
                                    } reservations in the group will be moved. There are ${
                                        allGroupReservationIds.length - groupIdsInEntries.length
                                    } other reservations outside the range of the calendar that will remain in place. Proceed?`}</p>,
                                    null,
                                    Language.tmp("Move partial reservation group?"),
                                    [
                                        {
                                            title: Language.get("dialog_cancel"),
                                            cb: () => {
                                                this.handleCancelEntryDrag(false);
                                            },
                                        },
                                        {
                                            title: Language.get("cal_res_below_move"),
                                            cb: () => {
                                                performMove();
                                            },
                                        },
                                    ],
                                    true
                                );
                            } else {
                                performMove();
                            }
                        }
                    );
                } else {
                    performMove();
                }
            };

            // eslint-disable-next-line no-param-reassign
            entry.reservationids = entry.clusterReservationIds || entry.reservationids; // Can entry be read-only here?
            let postMove = reloadEntries;
            const hadPreviousReservation = Boolean(this.state.previousReservationId);
            if (
                this.props.unlockedReservations &&
                this.props.unlockedReservations.length > 0 &&
                !entry.isLocked(this.props.unlockedReservations)
            ) {
                if (this.context.useNewReservationGroups) {
                    postMove = () => {
                        if (this.props.temporaryUnlock === true) {
                            this.props.endLockMode();
                        }
                        reloadEntries();
                    };
                    move([entry], postMove, hadPreviousReservation);
                } else {
                    API.ungroupReservations(entry.groups, (ungroupResult) => {
                        postMove = () => {
                            API.groupReservations(
                                ungroupResult[0].concat(entry.reservationids),
                                this.props.data.getClusterKind(),
                                (groupResult) => {
                                    if (groupResult[1] === false) {
                                        Log.error(Language.get("nc_reservation_group_failed"));
                                    } else {
                                        reloadEntries();
                                    }
                                }
                            );
                        };
                        // eslint-disable-next-line no-param-reassign
                        entry.groups = [];
                        move([entry], postMove, hadPreviousReservation);
                    });
                }
            } else {
                const mapReservationIds = (entries) => {
                    return entries.map((entry) =>
                        _.extend(entry.clone(), {
                            reservationids: entry.clusterReservationIds || entry.reservationids,
                        })
                    );
                };
                let toMove = [entry];
                if (this.context.useNewReservationGroups && entry.grouped) {
                    toMove = toMove.concat(mapReservationIds(this.state.groupedDragElements));
                } else if (
                    !entry.grouped &&
                    this.state.groupedDragElements &&
                    this.state.groupedDragElements.length > 0
                ) {
                    toMove = toMove.concat(mapReservationIds(this.state.groupedDragElements));
                }
                move(toMove, postMove, hadPreviousReservation);
            }

            // Reset previous current entry
            if (hadPreviousReservation) {
                this.setCurrentReservation(this.state.previousReservationId);
            }
            return;
        }

        if (settings.autoObject) {
            const autoName = settings.autoObject.name;

            fluffy.addObject(
                settings.autoObject,
                (newFluffy) =>
                    this.saveReservations(
                        entry,
                        _.extend({}, opts, {
                            mcFluffy: newFluffy,
                            autoObject: null,
                            didAuto: true,
                            allowAvailabilityOverlap: settings.allowAvailabilityOverlap,
                            isWaitingListReservation,
                        }),
                        (res, ids) => {
                            const saveDoneBound = saveDone.bind(this);
                            API.getPreferences(
                                `dismissedModalDialogs.confirm_auto_object`,
                                (value) => {
                                    if (value || value === null) {
                                        const yesButton = {
                                            title: Language.get("dialog_ok"),
                                            cb: () => {
                                                saveDoneBound(res, ids);
                                                this.handleCancelEntryDrag();
                                            },
                                            remember: true,
                                            value: false,
                                        };
                                        const buttons = [yesButton];
                                        this.context.presentModal(
                                            <div>
                                                {
                                                    <p>
                                                        {Language.get(
                                                            "nc_auto_object_added",
                                                            autoName
                                                        )}
                                                    </p>
                                                }
                                            </div>,
                                            "confirm_auto_object",
                                            Language.get("nc_auto_object_headline"),
                                            buttons,
                                            _.noop
                                        );
                                    } else {
                                        callback();
                                        this.handleCancelEntryDrag();
                                    }
                                }
                            );
                        }
                    ),
                false
            );
        } else {
            Reservation.save(
                entry,
                fluffy,
                saveDone.bind(this),
                allowIncomplete,
                settings.allowAvailabilityOverlap,
                isWaitingListReservation
            );
        }
    },

    isSingleClickReservationAllowed(event, checkTarget = false) {
        const allowSingleClickReservation = this.context.user.allowsSingleClickReservation();
        if (checkTarget) {
            if (event.shiftKey) {
                return allowSingleClickReservation && !this.isEventOnBlockingEntry(event);
            }
            return allowSingleClickReservation && !this.isEventOnEntry(event);
        }

        return allowSingleClickReservation;
    },

    handleAddMode(entry, ids, selection, callback) {
        if (
            this.isEmptyGroup(selection.groups) &&
            this.props.data.selectionGroup &&
            this.props.data.selectionGroup.length > 0
        ) {
            const allIds = ids.concat(
                _.flatten(this.props.data.selectionGroup.map((etr) => etr.reservationids))
            );
            API.groupReservations(allIds, this.props.data.getClusterKind(), (groupResult) => {
                if (groupResult[1] === false) {
                    Log.error(Language.get("nc_reservation_group_failed"));
                    callback(selection);
                } else {
                    this.fireReservationEvent(allIds);
                    callback(selection.setGroups(groupResult[0]));
                }
            });
        } else if (this.isEmptyGroup(selection.groups)) {
            const groupEntry = _.clone(entry);
            groupEntry.reservationids = ids;
            this.context.update(
                this.props.data,
                this.props.data.immutableSet({ selectionGroup: [groupEntry] })
            );
            callback(selection.immutableSet({ reservations: ids }));
        } else {
            if (this.context.useNewReservationGroups) {
                API.addReservationsToReservationGroup(selection.groups[0], ids, (groupResult) => {
                    if (groupResult === false) {
                        Log.error(Language.get("nc_reservation_group_failed"));
                    } else {
                        this.fireReservationEvent(ids);
                    }
                    callback(selection);
                });
            } else {
                API.addReservationsToGroups(
                    ids,
                    selection.groups,
                    this.props.data.getClusterKind(),
                    (groupResult) => {
                        if (groupResult === false) {
                            Log.error(Language.get("nc_reservation_group_failed"));
                        } else {
                            this.fireReservationEvent(ids);
                        }
                        callback(selection);
                    }
                );
            }
        }
    },

    addReservationToTime(entry) {
        // Use entry time to enable edit mode there, as if dragging an entry without objects
        const newEntry = _.clone(entry);
        newEntry.reservationids = [];
        newEntry.kind = EntryKind.NONE;
        this.enableEditMode(newEntry);
    },

    addToGroup(entry) {
        const selection = this.getSelection();
        const callback = (newSelection) => {
            if (!this.isEmptyGroup(newSelection.groups)) {
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({ selectionGroup: [] })
                );
            }
            const newEntry = _.clone(entry);
            newEntry.reservationids = [];
            this.context.update(selection, newSelection.enableEditMode(newEntry, true));
            this.debounceLoadEntries(this.props);
        };
        this.handleAddMode(entry, entry.reservationids, selection, callback);
    },

    editGroup(entry, groups, date) {
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                spotlightDate: this.context.useNewReservationGroups ? null : date,
            })
        );
        const newEntry = _.clone(entry);
        newEntry.reservationids = [];
        const selection = this.getSelection();
        this.context.update(
            selection,
            selection.toggleAddMode(groups).enableEditMode(newEntry, true)
        );
    },

    createGroupFromEntry(entry) {
        const ids = entry.reservationids;
        if (this.context.useNewReservationGroups) {
            this.createGroup(ids);
            return;
        }
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                selectionGroup: [entry],
                spotlightDate: this.context.useNewReservationGroups
                    ? null
                    : entry.startTimes[0].getMillenniumDate(),
            })
        );
        const selection = this.getSelection();
        const newEntry = _.clone(entry);
        newEntry.reservationids = [];
        this.context.update(
            selection,
            selection
                .immutableSet({ reservations: ids })
                .enableEditMode(newEntry, true)
                .enableAddMode([0], true)
        );
        this.onOverlapViewClose();
        this.fireReservationEvent(ids);
        this.debounceLoadEntries(this.props);
    },

    createGroup(
        ids = _.flatten(this.props.data.selectionGroup.map((entry) => entry.reservationids))
    ) {
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                spotlightDate: null,
                spotlightTime: null,
                useSpotlight: false,
            })
        );

        const postCreate = (result) => {
            if (result[1] === false) {
                Log.error(Language.get("nc_reservation_group_failed"));
            } else {
                const selection = this.getSelection();
                let toSet = selection.reservations;
                if (!_.find(ids, selection.reservations)) {
                    toSet = ids[0]; // Should I try to find the current of the group first?
                }
                this.context.update(
                    selection,
                    selection.setGroups(result[0]).immutableSet({ reservations: toSet })
                );
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({ selectionGroup: [] })
                );
                this.onOverlapViewClose();
                this.fireReservationEvent(_.flatten(ids));
                this.debounceLoadEntries(this.props);
            }
        };

        const allIds = _.flatten(ids) || [];
        if (this.context.useNewReservationGroups) {
            const name = this.context.user.promptForGroupName
                ? // eslint-disable-next-line no-alert
                  prompt(Language.get("nc_reservation_group_name"))
                : "";
            if (name === null) {
                return;
            }
            API.createReservationGroupWithReservations(
                name,
                allIds,
                (result) => {
                    const selection = this.getSelection();
                    /*let toSet = selection.reservations || [];
                    if (!_.find(ids, selection.reservations)) {
                        toSet = ids[0] || []; // Should I try to find the current of the group first?
                    }*/
                    this.context.update(
                        selection,
                        selection.setGroups([result]).immutableSet({ reservations: ids })
                    );
                    this.context.update(
                        this.props.data,
                        this.props.data.immutableSet({ selectionGroup: [] })
                    );
                    this.onOverlapViewClose();
                    this.fireReservationEvent(allIds);
                    this.debounceLoadEntries(this.props);
                },
                (errorMessage) => Log.error(errorMessage)
            );
        } else {
            API.groupReservations(allIds, this.props.data.getClusterKind(), postCreate);
        }
    },

    deleteGroup(groupIds) {
        if (this.context.useNewReservationGroups) {
            // eslint-disable-next-line no-unused-vars
            API.deleteReservationGroup(groupIds, (result) => {
                this.fireReservationEvent([]);
                const selection = this.getSelection();
                this.context.update(selection, selection.setGroups([]));
                this.onOverlapViewClose();
            });
        } else {
            API.ungroupReservations(groupIds, (result) => {
                this.fireReservationEvent(result[0]);
                const selection = this.getSelection();
                this.context.update(selection, selection.setGroups([]));
                this.onOverlapViewClose();
            });
        }
    },

    handleSelectionGroup(entry, addToSelection) {
        let selection = [].concat(this.props.data.selectionGroup);
        if (!addToSelection) {
            selection = [];
        } else {
            if (_.some(selection, (sel) => _.isEqual(sel.reservationids, entry.reservationids))) {
                selection = selection.filter(
                    (sel) => !_.isEqual(sel.reservationids, entry.reservationids)
                );
            } else {
                selection.push(entry);
            }
        }
        if (selection.length > 1) {
            this.enableTemporaryEscapeBinding(() => {
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({ selectionGroup: [] })
                );
            });
        }
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({ selectionGroup: selection })
        );
    },

    onLockedEntryClick() {
        if (this.props.temporaryUnlock === true) {
            this.props.endLockMode();
        }
    },

    onEntryClick(entry, addToSelection = false, shiftKey = false) {
        if (this.getSelection().isGroupMode && this.props.data.spotlightDate && addToSelection) {
            if (
                !this.context.useNewReservationGroups &&
                !_.some(entry.begins, (time) =>
                    time.getMillenniumDate().isSameDayAs(this.props.data.spotlightDate)
                )
            ) {
                return;
            }
        }
        this.handleSelectionGroup(entry, addToSelection);
        if (!addToSelection) {
            this.setCurrentReservation(entry.reservationids, (selection, newSelection) => {
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({
                        currentEntryHeaderObjects: entry.headerObjects || [],
                    })
                );
                let groupSelection = newSelection;
                if (!_.isEqual(newSelection.groups, entry.groups)) {
                    groupSelection = newSelection
                        .setGroups(entry.groups)
                        .immutableSet({ isGroupMode: false });
                    this.context.update(selection, groupSelection);
                } else {
                    this.context.update(selection, groupSelection);
                }
                if (groupSelection.groups.length > 0 && this.getSelection().isGroupMode) {
                    const newEntry = _.clone(entry);
                    newEntry.reservationids = [];
                    this.context.update(
                        groupSelection,
                        groupSelection.enableEditMode(newEntry, true)
                    );
                }
                if (shiftKey === true) {
                    setTimeout(() => {
                        // Changes selection, so needs to run after all other selection updates are done. How to solve this nicely?
                        this.enableEditMode(entry);
                    }, EDIT_MODE_TIMEOUT);
                }
            });
            this.context.fireEvent(`calendar${this.props.id}`, Macros.Event.SELECT_RESERVATION, {
                id: entry.reservationids,
                begin: entry.startTimes[0].getMts(),
                end: entry.endTimes[0].getMts(),
                status: _.asArray(entry.status).map((st) => ReservationStatus.statusForId(st)),
            });
            this.fireSetDateEvent(entry.startTimes[0].getMillenniumDate());
        }
    },

    findOverlappingEntries(inEntry, onlyVisible, callback) {
        const entry = this.props.data.getEntryWithTimeSlots(inEntry);
        this.props.data.findOverlappingEntries(
            this.getActiveFluffy(),
            this.getSelection().getCurrentReservationId(),
            entry.startTimes,
            entry.endTimes,
            onlyVisible,
            entry.objects,
            entry.periods,
            this.getSelection(this.props).obstacleTextTypes,
            callback
        );
    },

    showOverlappingEntriesInList(entry) {
        this.findOverlappingEntries(entry, true, (entries) => {
            const reservationIds = entries.reduce((acc, etr) => acc.concat(etr.reservationids), []);
            this.props.openStaticReservationList(false, reservationIds);
        });
    },

    showOverlappingEntries(entry) {
        this.findOverlappingEntries(entry, true, (entries) => {
            entries.forEach((item, index) => {
                // eslint-disable-next-line no-param-reassign
                item.overlapView = true;
                // eslint-disable-next-line no-param-reassign
                item.numOverlappingEntries = entries.length;
                // eslint-disable-next-line no-param-reassign
                item.overlappingEntryIndex = entries.length - index - 1;
            });

            this.setState({
                overlapView: true,
                overlapEntries: entries,
            });

            this._escBinding = Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE, true)[0];
            Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, () => {
                this.onOverlapViewClose();
                Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE);
                if (this._escBinding) {
                    Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, this._escBinding);
                }
                this._escBinding = null;
            });

            // eslint-disable-next-line no-undef
            /*mixpanel.track("Overlap", {
                "Number of entries": entries.length,
                Calendar: {
                    "Has periods": this.props.data.hasPeriodHeaders(),
                    Providers: this.props.data.getProviderMap(),
                    Headers: _.mapObject(this.props.data.getHeaderTypeMap(), (val) => Boolean(val)),
                },
            });*/
        });
    },

    onMouseUp(event) {
        if (this.isEventOnBlockingEntry(event)) {
            return;
        }
        if (
            !this.state.dragType &&
            this.props.copiedEntry &&
            (event.type === "contextmenu" || event.button === SECOND_BUTTON)
        ) {
            const upEvent = _.clone(event);
            ContextMenu.displayMenu(
                [
                    {
                        key: "entry.paste",
                        label: Language.get("nc_paste_reservation"),
                        action: () => this.onEntryPaste(upEvent),
                        shortcut: TimeEdit.presentShortcut("mod+v"),
                    },
                ],
                event
            );
        }
    },

    startEntryCreate(event) {
        if (this.props.data.readOnly || this.props.data.hideObstacles) {
            return;
        }
        if (this.props.temporaryUnlock === true) {
            this.props.endLockMode();
            return;
        }

        if (
            !this.context.useNewReservationGroups &&
            this.props.data.spotlightDate &&
            this.getSelection().isGroupMode
        ) {
            const indexes = this.getIndexesFromCoordinates(_.getClientPos(event));
            let times = this.props.data.getTimeFromIndexes(indexes.x, indexes.y, false);
            try {
                times = _.asArray(MillenniumDateTime.createFromList(times));
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                times = [];
            }
            if (
                !_.some(times, (time) =>
                    time.getMillenniumDate().isSameDayAs(this.props.data.spotlightDate)
                )
            ) {
                return;
            }
        }

        if (this.state.editedEntry) {
            if (!this.getSelection().isGroupMode) {
                return;
            }
            this.disableEditMode();
        }

        if (event.touches && event.touches.length > 1) {
            return;
        }
        if (
            window.PointerEvent &&
            event.nativeEvent &&
            event.nativeEvent instanceof window.PointerEvent
        ) {
            // Hack, Edge (and IE 11?) fire both PointerEvents and MouseEvents
            return;
        }

        if (event.type === "contextmenu" && ContextMenu.isActive()) {
            return;
        }

        // Prevent native context menu from appearing since that breaks right-click drag
        if (event.type === "contextmenu" || event.button === SECOND_BUTTON) {
            event.preventDefault();
        }

        if (
            this.isSingleClickReservationAllowed(event, true) &&
            this.props.isActive &&
            event.type !== "contextmenu" &&
            event.button !== SECOND_BUTTON &&
            !ContextMenu.isActive()
        ) {
            this.initEntryCreate(event, true);
            return;
        }

        if (
            this.getSelection().isWaitingListMode() &&
            this.getSelection().length &&
            event.button !== SECOND_BUTTON
        ) {
            this.initEntryCreate(event);
            return;
        }

        const originalEvent = _.clone(event);
        DragListener.add(event, () => {
            this.initEntryCreate(originalEvent);
        });
    },

    fluffyHasSize() {
        const fluffy = this.getActiveFluffy();
        if (!fluffy) {
            return false;
        }
        return fluffy.hasSize();
    },

    initEntryCreate(event, fromSingleClick = false) {
        if (event.touches) {
            event.preventDefault();
            event.stopPropagation();
            this._preventScroll = true;
        }

        if (this.state.dragType || ContextMenu.isActive()) {
            return;
        }

        const coords = _.getClientPos(event);
        const indexes = this.getIndexesFromCoordinates(coords);
        const entry = this.updateEntry(null, indexes.x, indexes.y, "length");
        if (!entry) {
            return;
        }

        if (this.fluffyHasSize()) {
            this.findOverlappingEntries(entry, true, (entries) => {
                const capacityEntry = entries.find(
                    (etr) =>
                        etr.remainingCapacity && etr.remainingCapacity > this.getActiveFluffy().size
                );
                /*if (!capacityEntry) {
                    this.handleCancelEntryDrag();
                    this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
                    this.removeListener(_.getEndEvent(event), this.endEntryCreate);
                    return;
                }*/
                if (capacityEntry) {
                    // TODO Double check if a capacity entry can ever have more than one reservation ID, and if so what should happen in that case
                    entry.capacityReservationIds = capacityEntry.reservationids;
                    if (entry.capacityReservationIds.length === 1) {
                        entry.capacityReservationId = capacityEntry.reservationids[0];
                    }
                    console.log(entry, capacityEntry);
                    const fluffy = this.getActiveFluffy();
                    const newFluffy = fluffy.setCapacityReservationId(
                        capacityEntry.reservationids[0]
                    );
                    this.context.update(
                        this.getSelection(),
                        this.getSelection().setFluffy(newFluffy)
                    );
                }
            });
        }
        const selection = this.getSelection();

        if (selection.isWaitingListMode() && selection.length) {
            entry.setLength(selection.length);
        }

        if (selection.duration) {
            entry.setLength(selection.duration);
        }

        const hasTimePeriod = this.props.data.hasHeaderType(TimePeriodHeader);
        let currentSlots = [];
        if (!hasTimePeriod) {
            const fluffy = this.getActiveFluffy();
            currentSlots = entry.applyRules(
                fluffy.rules,
                selection.duration || fluffy.length,
                fromSingleClick || selection.isWaitingListMode(),
                event.shiftKey,
                selection.length || fromSingleClick ? "length" : null,
                true,
                false,
                selection.duration
            );
            if (fluffy.rules.time_slots_only && currentSlots.length === 0) {
                this.handleCancelEntryDrag();
                return;
            }
        }

        entry.dragType = "create";

        this.enableTemporaryEscapeBinding();
        this.setState({
            currentSlots: limitSlotsToDayof(currentSlots, entry),
            dragElement: entry,
            dragStart: coords,
            dragType: event.button !== SECOND_BUTTON ? "create" : "availability",
        });

        if (fromSingleClick) {
            setTimeout(() => {
                if (this.state.dragElement) {
                    this.addListener(_.getMoveEvent(event), this.updateEntryCreate);
                }
            }, ENTRY_CREATE_DRAG_TIMEOUT);
        } else {
            this.addListener(_.getMoveEvent(event), this.updateEntryCreate);
        }
        this.addListener(_.getEndEvent(event), this.endEntryCreate);
    },

    updateEntryCreate(event) {
        if (event.touches) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (event.touches && event.touches.length > 1) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            return;
        }
        const button = event.buttons !== undefined ? event.buttons : event.button;
        if (button !== SECOND_BUTTON && _.contains(["availability"], this.state.dragType)) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            return;
        }
        if (ContextMenu.isActive()) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            return;
        }

        if (
            !_.contains(["create", "availability"], this.state.dragType) ||
            this.state.displayFieldDialog === true
        ) {
            return;
        }

        let entry = this.state.dragElement.clone();

        let indexes = this.getIndexesFromCoordinates(this.state.dragStart);
        let originTimes = this.props.data.getTimeFromIndexes(indexes.x, indexes.y, true);
        try {
            originTimes = _.asArray(MillenniumDateTime.createFromList(originTimes));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            originTimes = [];
        }
        originTimes = originTimes.map((originDateTime) => {
            const date = originDateTime.getMillenniumDate();
            const roundedTime = this.getRoundedTime(originDateTime.getMillenniumTime());
            return new MillenniumDateTime(date, roundedTime);
        });

        indexes = this.getIndexesFromCoordinates(_.getClientPos(event));
        let times = this.props.data.getTimeFromIndexes(indexes.x, indexes.y, false);
        try {
            times = _.asArray(MillenniumDateTime.createFromList(times));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            times = [];
        }

        let lockedProperty = times[0].isBefore(originTimes[0]) ? "end" : "start";
        if (this.getSelection().isWaitingListMode() && this.getSelection().length) {
            lockedProperty = "length";
        }

        const hasTimePeriod = this.props.data.hasHeaderType(TimePeriodHeader);
        if (hasTimePeriod) {
            lockedProperty = null;
        }

        const fluffy = this.getActiveFluffy();
        if (!fluffy.rules.time_slots) {
            // Make sure the entry is fixed to its original position, either at the start or the end
            if (lockedProperty === "start") {
                entry.startTimes = originTimes;
            }
            if (lockedProperty === "end") {
                entry.endTimes = originTimes;
            }
        }

        const oldPeriods = entry.periods;
        entry = this.updateEntry(entry, indexes.x, indexes.y, lockedProperty);
        if (
            entry.startTimes.length !== entry.endTimes.length ||
            !_.isEqual(oldPeriods, entry.periods)
        ) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            return;
        }

        let currentSlots = [];
        if (!hasTimePeriod) {
            currentSlots = entry.applyRules(
                fluffy.rules,
                fluffy.length,
                false,
                event.shiftKey,
                lockedProperty,
                originTimes,
                lockedProperty === "end"
            );
            if (fluffy.rules.time_slots_only && currentSlots.length === 0) {
                this.handleCancelEntryDrag();
                this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
                this.removeListener(_.getEndEvent(event), this.endEntryCreate);
                return;
            }
        }
        this.setState({ currentSlots: limitSlotsToDayof(currentSlots, entry), dragElement: entry });
    },

    getFirstFreeObject(entry, typeId, callback) {
        let searcher = this.props.getObjectSearch();
        const finish = (search) => {
            const finalSearch = search.immutableSet({
                beginTime: entry.startTimes.map((time) => time.getMts()),
                endTime: entry.endTimes.map((time) => time.getMts()),
                reserveMode: true,
            });
            finalSearch.search(0, (objects) => callback(objects[0]));
        };
        if (typeId !== searcher.type) {
            console.log("Type mismatch in getFirstFreeObject in Calendar", typeId, searcher.type);
            callback(AUTO_OBJECT_TYPE_ERROR);
        } else {
            finish(searcher);
        }
    },

    // Might be a useful hook to have in the future?
    // eslint-disable-next-line no-unused-vars
    fitsTimeSlot(entry) {
        return true;
    },

    fitsCluster(entry, clusterKind) {
        if (clusterKind === ClusterKind.DATE || clusterKind === ClusterKind.WEEKDAY) {
            return (
                entry.startTimes[0].getMillenniumDate().getDayNumber() ===
                entry.endTimes[0].getMillenniumDate().getDayNumber()
            );
        }
        if (clusterKind === ClusterKind.WEEK) {
            return (
                entry.startTimes[0].getWeek(Language.firstDayOfWeek, Language.daysInFirstWeek) ===
                entry.endTimes[0].getWeek(Language.firstDayOfWeek, Language.daysInFirstWeek)
            );
        }
        return true;
    },

    endEntryCreate(event) {
        if (event.touches && event.touches.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            this.handleCancelEntryDrag();
            return false;
        }

        if (
            !_.contains(["create", "availability", "copy"], this.state.dragType) ||
            this.state.displayFieldDialog === true
        ) {
            return false;
        }

        this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
        this.removeListener(_.getEndEvent(event), this.endEntryCreate);

        const coords = _.getClientPos(event);
        const isClick =
            Math.abs(coords.x - this.state.dragStart.x) < MIN_DRAG_LIMIT &&
            Math.abs(coords.y - this.state.dragStart.y) < MIN_DRAG_LIMIT;
        const selection = this.getSelection();

        if (
            isClick &&
            !this.isSingleClickReservationAllowed(event) &&
            !selection.isWaitingListMode()
        ) {
            return this.handleCancelEntryDrag();
        }

        const allowAvailabilityOverlap = _.isModKey(event);

        const entry = this.state.dragElement;

        if (!this.fitsTimeSlot(entry)) {
            this.handleCancelEntryDrag();
            return false;
        }

        if (this.state.dragType === "availability") {
            if (!this.props.getCurrentFluffyItem()) {
                return this.handleCancelEntryDrag();
            }

            const limitObjects = (availableObjects) => {
                const newSelection = selection
                    .setTimeLimit(entry)
                    .setAvailableObjects(availableObjects);
                this.context.update(selection, newSelection);
                return this.handleCancelEntryDrag();
            };

            ContextMenu.displayMenu(
                [
                    {
                        key: "availability.available",
                        label: Language.get("cal_func_res_show_free_objects"),
                        action: () => limitObjects(true),
                    },
                    {
                        key: "availability.not_available",
                        label: Language.get("cal_func_res_show_occupied_objects"),
                        action: () => limitObjects(false),
                    },
                ],
                event,
                this.handleCancelEntryDrag
            );

            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        if (this.getActiveFluffy().getObjects().length === 0) {
            this.saveEntry(entry, allowAvailabilityOverlap, null, selection.isGroupMode);
            return false;
        }

        const save = (etr, allowOverlap, autoObject = null) => {
            if (this.fluffyHasSize()) {
                const size = this.getActiveFluffy().size;
                this.findOverlappingEntries(etr, true, (entries) => {
                    const capacityEntry = entries.find((et) => et.capacity);
                    // TODO: Check that size entry is entirely inside capacity entry
                    // Otherwise display error and cancel
                    // Perhaps match so that the capacity reservation found matches the reservationid set as well?
                    if (capacityEntry) {
                        console.log(etr, capacityEntry);
                        if (!etr.isInside(capacityEntry)) {
                            alert(
                                Language.tmp(
                                    "The size entry must not exend outside the capacity entry"
                                )
                            );
                            this.handleCancelEntryDrag();
                            return;
                        }
                        if (capacityEntry.remainingCapacity < size) {
                            let shouldSave = confirm(
                                Language.tmp(
                                    `Remaining capacity ${capacityEntry.remainingCapacity} is smaller than ${size}. Proceed?`
                                )
                            );
                            if (shouldSave) {
                                this.saveEntry(etr, allowOverlap, autoObject);
                            } else {
                                this.handleCancelEntryDrag();
                            }
                        } else {
                            this.saveEntry(etr, allowOverlap, autoObject);
                        }
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        etr.capacityReservationIds = [];
                        this.saveEntry(etr, allowOverlap, autoObject);
                    }
                });
            } else {
                this.saveEntry(etr, allowOverlap, autoObject);
            }
        };

        const fluffyItem = this.props.getCurrentFluffyItem();
        if (fluffyItem) {
            const fluffyItemType = fluffyItem.type.id;
            const typeObject = this.getActiveFluffy().getFirstObjectOfType(fluffyItemType);
            if (!typeObject && fluffyItem.auto) {
                // If item has an object, something is already selected.
                this.getFirstFreeObject(entry, fluffyItemType, (autoObject) => {
                    if (autoObject === AUTO_OBJECT_TYPE_ERROR) {
                        // Do not log a user-visible error if Core got into a state where types didn't match.
                        save(entry, allowAvailabilityOverlap, null);
                    } else if (autoObject) {
                        // If not, there are no objects, thus no auto selection.
                        save(entry, allowAvailabilityOverlap, autoObject);
                    } else {
                        Log.error(Language.get("nc_err_res_no_auto_object_available"));
                        this.handleCancelEntryDrag();
                        return;
                    }
                });
            } else {
                save(entry, allowAvailabilityOverlap, null);
            }
        } else {
            save(entry, allowAvailabilityOverlap, null);
        }
        return false;
    },

    beginGroup() {
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                selectionGroup: [],
                spotlightDate: null,
                spotlightTime: null,
                useSpotlight: false,
            })
        );
        const selection = this.getSelection();
        this.context.update(
            selection,
            selection.enableEditMode(new Entry(), true).enableAddMode([0])
        );
    },

    saveEntry(entry, allowAvailabilityOverlap, autoObject, createGroup = false) {
        if (this.getActiveFluffy().hasMandatoryFields) {
            // … and those mandatory fields are not already filled in?
            this.props.showLayer();
            this.setState({ displayFieldDialog: true, autoObject, createGroup });
            return;
        }
        this.setState({ previousReservationId: null });
        this.saveReservations(entry, {
            allowEditMode: true,
            allowAvailabilityOverlap,
            autoObject,
            createGroup,
        });
    },

    handleCancelEntryDrag(setPrevious = true) {
        setTimeout(() => {
            // Wait to enable scrolling to avoid a jump when tapping to cancel a move or reservation in progress
            this._preventScroll = false;
        }, CANCEL_DRAG_TIMEOUT);
        this.props.hideLayer();
        if (this.state.previousReservationId && setPrevious) {
            this.setCurrentReservation(this.state.previousReservationId);
        }

        if (this._isMounted) {
            this.setState({
                currentSlots: [],
                dragElement: null,
                groupedDragElements: null,
                dragStart: null,
                dragType: null,
                dragIsHighResolution: false,
                displayFieldDialog: false,
                previousReservationId: null,
            });
        }
    },

    onEntryCopy(entry, event) {
        const model = this.props.data;
        const newEntry = model.getEntryWithTimeSlots(entry);
        const xIndexes = this.props.data.getEntryIndexes(entry, true, true);
        const yIndexes = this.props.data.getEntryIndexes(entry, false, true);
        const objects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];
        newEntry.types = _.pluck(objects, "type");

        const isSelected = (etr) =>
            _.some(model.selectionGroup, (sel) =>
                _.isEqual(sel.reservationids, etr.reservationids)
            );

        let groupedDragElements = [];
        let copiedGroup = false;
        if (isSelected(entry)) {
            groupedDragElements = model.entries
                .filter((etr) => isSelected(etr) && !etr.isPadding && !isSame(etr, entry))
                .map((etr) => model.getEntryWithTimeSlots(etr))
                .map((etr) =>
                    _.extend(etr.clone(), {
                        kind: EntryKind.NONE,
                    })
                );
        } else if (entry.grouped) {
            copiedGroup = true;
            groupedDragElements = model.entries
                .filter(
                    (etr) =>
                        etr.grouped &&
                        !isSame(etr, entry) &&
                        !etr.isPadding &&
                        !etr.isLocked(this.props.unlockedReservations)
                )
                .map((etr) => model.getEntryWithTimeSlots(etr))
                .map((etr) =>
                    _.extend(etr.clone(), {
                        kind: EntryKind.NONE,
                    })
                );
        }

        this.props.onEntryCopy(newEntry, groupedDragElements);
        if (copiedGroup) {
            Log.info(Language.get("nc_copied_group"));
        } else {
            Log.info(
                Language.get(
                    "nc_copied_reservations",
                    [
                        ...groupedDragElements.map((gDe) => gDe.reservationids),
                        ...entry.reservationids,
                    ].join(", ")
                )
            );
        }
        event.preventDefault();
    },

    getRoundedTime(time) {
        const DEFAULT_STEP = 300;
        const seconds = time.getTimeNumber();
        const fluffy = this.getActiveFluffy();

        let startStep = DEFAULT_STEP;
        if (fluffy && fluffy.rules.start_step) {
            startStep = fluffy.rules.start_step;
        }
        return time.addSeconds(-seconds % startStep);
    },

    getRoundedEndTime(time, event) {
        const DEFAULT_STEP = 300;
        const seconds = time.getTimeNumber();
        const fluffy = this.getActiveFluffy();

        let step = DEFAULT_STEP;
        if (fluffy && fluffy.rules.major_step) {
            step = fluffy.rules.major_step;
        }
        if (event.shiftKey) {
            if (fluffy && fluffy.rules.minor_step) {
                step = fluffy.rules.minor_step;
            }
        }
        const stepLength = seconds % step;
        if (stepLength === 0) {
            return time;
        }
        // eslint-disable-next-line no-magic-numbers
        if (stepLength < step / 2) {
            return time.addSeconds(-stepLength);
        }
        return time.addSeconds(step - stepLength);
    },

    _trackMousePosition(event) {
        if (!this._timeListener) {
            return;
        }
        this._lastMouseMoveEvent = _.clone(event);
        if (!event.touches) {
            const coords = _.getClientPos(event);
            const offset = _.nodeOffset(ReactDOM.findDOMNode(this.refs.entryLayer));
            if (coords.x >= offset.left && coords.y >= offset.top) {
                const timesForClick = this.getTimeForPosition(coords.x, coords.y, true);
                if (timesForClick.length > 0) {
                    const time = timesForClick[0].getMillenniumTime();
                    this._timeListener(this.getRoundedEndTime(time, event));
                }
            } else {
                this._timeListener(null);
            }
        }
    },

    _clearTrackedMousePosition(event) {
        this._lastMouseMoveEvent = null;
        if (!event.touches) {
            if (this._timeListener) {
                this._timeListener(null);
            }
        }
    },

    registerTimeListener(listener) {
        this._timeListener = listener;
    },

    onEntryPaste(event) {
        if (!this.props.copiedEntry || this.props.data.readOnly) {
            return;
        }
        if (event) {
            event.preventDefault();
        }

        let dragEntry = _.clone(this.props.copiedEntry);
        dragEntry.kind = EntryKind.NONE;

        const posEvent = this._lastMouseMoveEvent ? this._lastMouseMoveEvent : event;
        const coords = _.getClientPos(posEvent);
        const indexes = this.getIndexesFromCoordinates(coords);
        const prevXIndexes = this.props.data.getEntryIndexes(dragEntry, true, true);
        const prevYIndexes = this.props.data.getEntryIndexes(dragEntry, false, true);
        const hasTimePeriod = this.props.data.hasHeaderType(TimePeriodHeader);
        const offsetSeconds = hasTimePeriod
            ? 0
            : // eslint-disable-next-line no-magic-numbers
              -Math.round(this.props.copiedEntry.getLength() / 2);

        dragEntry = this.updateEntry(dragEntry, indexes.x, indexes.y, "length", offsetSeconds);
        const newXIndexes = this.props.data.getEntryIndexes(dragEntry, true, true);
        const newYIndexes = this.props.data.getEntryIndexes(dragEntry, false, true);

        const diffs = {
            x: prevXIndexes.map((pxI, index) => pxI - newXIndexes[index]),
            y: prevYIndexes.map((pyI, index) => pyI - newYIndexes[index]),
        };

        let currentSlots = [];
        if (!hasTimePeriod) {
            const fluffy = this.getActiveFluffy();
            currentSlots = dragEntry.applyRules(
                fluffy.rules,
                fluffy.length,
                false,
                posEvent.shiftKey,
                "length",
                false
            );
            if (fluffy.rules.time_slots_only && currentSlots.length === 0) {
                Log.error("No time slot");
                return;
            }
        }

        // Origin cell must have same types of header object
        if (!_.isEqual(_.sortBy(dragEntry.types), _.sortBy(this.props.copiedEntry.types))) {
            Log.error(Language.get("nc_paste_invalid_header_types"));
            return;
        }

        let groupedDragElements = this.props.copiedGroupedEntries || [];
        if (groupedDragElements) {
            groupedDragElements = groupedDragElements.map((groupEntry) => {
                // Modify the indexes with the same diff as the main entry
                const xIdx = this.props.data.getEntryIndexes(groupEntry, true, true);
                const yIdx = this.props.data.getEntryIndexes(groupEntry, false, true);
                const modX = xIdx.map((xI, index) => xI - diffs.x[index]);
                const modY = yIdx.map((yI, index) => yI - diffs.y[index]);
                return this.updateEntry(groupEntry, modX, modY, "length", NO_OFFSET);
            });
            if (!hasTimePeriod) {
                const fluffy = this.getActiveFluffy();
                groupedDragElements.forEach((groupEntry) =>
                    groupEntry.applyRules(
                        fluffy.rules,
                        fluffy.length,
                        false,
                        posEvent.shiftKey,
                        "length",
                        false
                    )
                );
            }
        }

        this.setState({
            currentSlots: limitSlotsToDayof(currentSlots, dragEntry),
            ghostEntry: true,
            originalEntry: _.clone(this.props.copiedEntry),
            dragElement: dragEntry,
            groupedDragElements,
            dragType: "copy",
            dragStart: { offsetSeconds },
        });

        this.enableTemporaryEscapeBinding(() => {
            this.removeListener("mousemove", this.updateEntryDrag);
            this.removeListener("mouseup", this.checkPasteTarget);
        });

        this.addListener("mousemove", this.updateEntryDrag);
        this.addListener("mouseup", this.checkPasteTarget);
    },

    isEventOnBlockingEntry(event) {
        let target = event.target;
        while (target !== null) {
            if (
                target.classList &&
                (target.classList.contains("obstacle") ||
                    target.classList.contains("standard") ||
                    target.classList.contains("complete"))
            ) {
                if (!target.classList.contains("backgroundEntry")) {
                    return true;
                }
            }
            target = target.parentNode;
        }

        return false;
    },

    isEventOnEntry(event) {
        let target = event.target;
        while (target !== null) {
            if (target.classList && target.classList.contains("entryContents")) {
                return true;
            }
            target = target.parentNode;
        }

        return false;
    },

    checkPasteTarget(event) {
        if (!this.isEventOnBlockingEntry(event)) {
            this.endEntryPaste(event);
            return;
        }

        this.handleCancelEntryDrag();
    },

    endEntryPaste(event) {
        this.removeListener("mousemove", this.updateEntryDrag);
        this.removeListener("mouseup", this.checkPasteTarget);

        const allowDoubleObjects = this.getActiveFluffy()
            .objectItems.filter((item) => item.double)
            .map((item) => item.object);
        const entry = _.clone(this.state.dragElement);
        entry.reservationids = entry.clusterReservationIds || entry.reservationids;

        // Cancel if entry is in a cell with a duplicate header object.
        const xHeaders = this.props.data.xHeader.getHeaders();
        const yHeaders = this.props.data.yHeader.getHeaders();
        const xIndexes = xHeaders.map((header) => header.indexOf(entry, true));
        const yIndexes = yHeaders.map((header) => header.indexOf(entry, true));
        const objects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];

        const hasDuplicatedObjects = _.uniq(_.pluck(objects, "id")).length < objects.length;
        if (hasDuplicatedObjects) {
            this.handleCancelEntryDrag();
            return;
        }

        // Sort object ids in correct order according to types
        entry.objects = this.props.copiedEntry.types.map((type) => {
            const index = entry.types.indexOf(type);
            return entry.objects[index];
        });

        // Work with grouped elements, the way endEntryDrag does

        const allowAvailabilityOverlap = _.isModKey(event);
        Reservation.copy(
            [entry, ...this.state.groupedDragElements],
            allowDoubleObjects,
            allowAvailabilityOverlap,
            this.props.copiedEntry.objects,
            (result, texts = [], resultingIds = []) => {
                if (result instanceof APIError) {
                    Log.warning(result.message, { code: result.code });
                    this.handleCancelEntryDrag();
                    return;
                }

                this.fireReservationEvent(resultingIds, true);
                this.loadEntries(this.props, () => {
                    this.setCurrentReservation(
                        resultingIds,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    );
                    this.handleCancelEntryDrag();
                });

                API.getPreferences(`dismissedModalDialogs.assymetricMoveResult`, (value) => {
                    if (!value && texts.length > 0) {
                        this.context.presentModal(
                            <div>
                                {texts.map((text, index) => (
                                    <p key={index}>{text}</p>
                                ))}
                            </div>,
                            "assymetricMoveResult",
                            Language.get("nc_assymetric_cluster_move_result_title")
                        );
                    }
                });
            },
            this.context.useNewReservationGroups
        );

        this.setState({ ghostEntry: false });
    },

    startEntryDrag(currentEntry, type, event, isHighRes) {
        const isHighResolution = isHighRes || false;
        if (this.props.data.readOnly || this.state.editedEntry || this.props.data.hideObstacles) {
            return;
        }

        this.setActive(event);
        event.stopPropagation();

        const originalEvent = _.clone(event);
        const listenEvent = _.getEndEvent(event);
        const cancelDragStart = () => {
            this.removeListener(listenEvent, cancelDragStart);
            clearTimeout(this._dragTimeout);
        };

        const timeout = event.touches ? TOUCH_DRAG_TIMEOUT : DEFAULT_DRAG_TIMEOUT;
        const setupDrag = () => {
            this._dragTimeout = setTimeout(() => {
                this.removeListener(listenEvent, cancelDragStart);
                this.initEntryDrag(currentEntry, type, originalEvent, isHighResolution);
            }, timeout);
            this.addListener(listenEvent, cancelDragStart);
        };
        if (
            this.getSelection().getCurrentReservationId() !== currentEntry.reservationids[0] ||
            !_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], currentEntry.kind)
        ) {
            // eslint-disable-next-line prefer-const
            let timer; // Add a delay before starting drag of non-current entries
            const moveEvent = _.getMoveEvent(event);
            const stopMoveFunction = () => {
                clearTimeout(timer);
                this.removeListener(moveEvent, stopMoveFunction);
                this.removeListener(listenEvent, stopMoveFunction);
            };
            this.addListener(moveEvent, stopMoveFunction);
            this.addListener(listenEvent, stopMoveFunction);
            timer = setTimeout(() => {
                this.removeListener(moveEvent, stopMoveFunction);
                this.removeListener(listenEvent, stopMoveFunction);
                setupDrag();
            }, DRAG_YELLOW_TIMEOUT);
        } else {
            setupDrag();
        }
    },

    initEntryDrag(currentEntry, type, event, isHighResolution) {
        if (event.touches) {
            event.preventDefault();
            event.stopPropagation();
        }

        // TODO Correct lock check - currentEntry.lock && currentEntry.lock === "soft"?
        if (currentEntry.lock && currentEntry.lock === "soft") {
            return;
        }

        // Cancel if entry is in a cell with a duplicate header object.
        const props = this.props;
        const model = props.data;
        const xHeaders = model.xHeader.getHeaders();
        const yHeaders = model.yHeader.getHeaders();
        const xIndexes = xHeaders.map((header) => header.indexOf(currentEntry, true));
        const yIndexes = yHeaders.map((header) => header.indexOf(currentEntry, true));
        const objects = model.getObjectsFromIndexes(xIndexes, yIndexes) || [];

        const hasDuplicatedObjects = _.uniq(_.pluck(objects, "id")).length < objects.length;
        if (hasDuplicatedObjects) {
            return;
        }

        if (
            this.getSelection().getCurrentReservationId() !== currentEntry.reservationids[0] ||
            !_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], currentEntry.kind)
        ) {
            let shouldCancelDrag = false;
            const onDragCancelled = () => {
                shouldCancelDrag = true;
                this.removeListener(_.getEndEvent(event), onDragCancelled);
            };
            this.addListener(_.getEndEvent(event), onDragCancelled);

            const currentEntries = model.entries.filter(
                (entry) => entry.entrypropertyid === TimeEdit.getCompletePropertyId()
            );
            const current = _.flatten(currentEntries.map((entry) => entry.reservationids));
            if (_.contains(current, this.getSelection().getCurrentReservationId())) {
                this.setState({
                    previousReservationId: this.getSelection().getCurrentReservationId(),
                });
            } else {
                this.setState({ previousReservationId: null });
            }

            this.setCurrentReservation(
                currentEntry.reservationids,
                (selection, newSelection) => {
                    this.context.update(
                        model,
                        model.immutableSet({
                            currentEntryHeaderObjects: currentEntry.headerObjects || [],
                        })
                    );
                    this.context.update(selection, newSelection);
                    this.loadEntries(this.props, (entries) => {
                        // Could we work without an extra load here? The point is to get the new entry possibly representing a cluster, not to force an extra load for the sake of it.
                        if (shouldCancelDrag) {
                            return;
                        }
                        this.removeListener(_.getEndEvent(event), onDragCancelled);

                        this.initEntryDrag(
                            this.getEntry(
                                entries,
                                this.getSelection().getCurrentReservationId(),
                                model.currentEntryHeaderObjects
                            ),
                            type,
                            event,
                            isHighResolution
                        );
                    });
                },
                true
            );
            return;
        }

        if (event.touches) {
            this._preventScroll = true;
        }

        this.enableTemporaryEscapeBinding();

        let dragEntry = _.clone(currentEntry);
        let offsetX = 0;
        let offsetY = 0;
        let offsetSeconds = 0;
        const coords = _.getClientPos(event);

        dragEntry = model.getEntryWithTimeSlots(dragEntry);

        if (type === "move") {
            const rect = event.currentTarget.getBoundingClientRect();
            offsetX = coords.x - rect.left;
            offsetY = coords.y - rect.top;

            let timeForClick = this.getTimeForPosition(coords.x, coords.y, false);
            timeForClick = _.find(
                timeForClick,
                (time) =>
                    time.getMts() >= currentEntry.startTimes[0].getMts() &&
                    time.getMts() <= currentEntry.endTimes[0].getMts()
            );
            if (!timeForClick) {
                this.handleCancelEntryDrag();
                return;
            }
            offsetSeconds = currentEntry.startTimes[0].getMts() - timeForClick.getMts();
        }

        const isSelected = (entry) =>
            model.selectionGroup.some((sel) => _.isEqual(sel.reservationids, entry.reservationids));

        let groupedDragElements = [];
        if (currentEntry.grouped) {
            groupedDragElements = model.entries
                .filter(
                    (entry) =>
                        entry.grouped &&
                        !isSame(entry, currentEntry) &&
                        !entry.isPadding &&
                        !entry.isLocked(this.props.unlockedReservations)
                )
                .map((entry) => model.getEntryWithTimeSlots(entry))
                .map((entry) =>
                    _.extend(entry.clone(), {
                        kind: EntryKind.NONE,
                    })
                );
            if (type !== "move" && !hasSameTimes(currentEntry, groupedDragElements)) {
                this.handleCancelEntryDrag();
                return;
            }
        } else if (isSelected(currentEntry)) {
            groupedDragElements = model.entries
                .filter(
                    (entry) => isSelected(entry) && !entry.isPadding && !isSame(entry, currentEntry)
                )
                .map((entry) => model.getEntryWithTimeSlots(entry))
                .map((entry) =>
                    _.extend(entry.clone(), {
                        kind: EntryKind.NONE,
                    })
                );
        }
        if (groupedDragElements.some((element) => element.lock && element.lock === "soft")) {
            this.handleCancelEntryDrag();
            return;
        }

        dragEntry.dragType = type;
        dragEntry.position = -1;
        this.setState({
            originalEntry: currentEntry,
            dragElement: dragEntry,
            groupedDragElements,
            dragStart: {
                offsetX,
                offsetY,
                offsetSeconds,
                startX: coords.x,
                startY: coords.y,
                target: event.target,
            },
            dragType: type,
            dragIsHighResolution: isHighResolution,
        });

        event.target.addEventListener(_.getMoveEvent(event), this.updateEntryDrag);
        event.target.addEventListener(_.getEndEvent(event), this.endEntryDrag);
        this.addListener(_.getMoveEvent(event), this.updateEntryDrag);
        this.addListener(_.getEndEvent(event), this.endEntryDrag);
    },

    updateEntryDrag(event) {
        if (event.touches) {
            event.preventDefault();
            event.stopPropagation();
            this._preventScroll = true;
        }
        if (["move", "copy", "resize-start", "resize-end"].indexOf(this.state.dragType) === -1) {
            return;
        }
        if (event.touches && event.touches.length > 1) {
            this.handleCancelEntryDrag();
            return;
        }
        const prevEntry = this.state.dragElement;

        const isHighResolution = this.state.dragIsHighResolution || false;

        const model = this.props.data;
        const coords = _.getClientPos(event);
        const HIRES_ADJUSTMENT = 5;
        if (isHighResolution) {
            // Allow more movement per time adjustment.
            if (model.xHeader.hasTime()) {
                coords.x =
                    coords.x - Math.abs(coords.x - this.state.dragStart.startX) / HIRES_ADJUSTMENT;
            } else {
                coords.y =
                    coords.y - Math.abs(coords.y - this.state.dragStart.startY) / HIRES_ADJUSTMENT;
            }
        }

        const hasTimePeriod = model.hasHeaderType(TimePeriodHeader);
        let lockedProperty = "length";
        if (hasTimePeriod) {
            lockedProperty = null;
        } else if (this.state.dragType === "resize-start") {
            lockedProperty = "end";
        } else if (this.state.dragType === "resize-end") {
            lockedProperty = "start";
        }

        const indexes = this.getIndexesFromCoordinates(coords);
        const prevXIndexes = model.getEntryIndexes(prevEntry, true, true);
        const prevYIndexes = model.getEntryIndexes(prevEntry, false, true);
        const entry = this.updateEntry(prevEntry, indexes.x, indexes.y, lockedProperty);
        const newXIndexes = model.getEntryIndexes(entry, true, true);
        const newYIndexes = model.getEntryIndexes(entry, false, true);
        const diffs = {
            x: prevXIndexes.map((pxI, index) => pxI - newXIndexes[index]),
            y: prevYIndexes.map((pyI, index) => pyI - newYIndexes[index]),
        };

        if (
            (lockedProperty !== "length" && entry.startTimes.length !== entry.endTimes.length) ||
            (!this.context.useNewReservationGroups &&
                entry.groups.length > 0 &&
                !_.isEqual(prevEntry.periods, entry.periods))
        ) {
            this.handleCancelEntryDrag();
            this.removeListener(_.getMoveEvent(event), this.updateEntryCreate);
            this.removeListener(_.getEndEvent(event), this.endEntryCreate);
            return;
        }

        let currentSlots = [];
        if (!hasTimePeriod) {
            const fluffy = this.getActiveFluffy();
            currentSlots = entry.applyRules(
                fluffy.rules,
                fluffy.length,
                false,
                event.shiftKey || isHighResolution,
                lockedProperty,
                false
            );
            const entryLength = entry.endTimes[0].getMts() - entry.startTimes[0].getMts();
            const hasSlotWithLength = (slots, length) =>
                _.some(slots, (slot) =>
                    _.some(
                        slot.end_times,
                        (endTime) => Math.abs(endTime - slot.start_time) === length
                    )
                );
            if (fluffy.rules.time_slots_only && !hasSlotWithLength(currentSlots, entryLength)) {
                return;
            }
        }
        // No slot visualization if moving an existing entry
        if (lockedProperty === "length") {
            currentSlots = [];
        }

        let groupedDragElements = this.state.groupedDragElements;
        if (groupedDragElements) {
            groupedDragElements = groupedDragElements.map((groupEntry) => {
                // Modify the indexes with the same diff as the main entry
                const xIdx = model.getEntryIndexes(groupEntry, true, true);
                const yIdx = model.getEntryIndexes(groupEntry, false, true);
                const modX = xIdx.map((xI, index) => xI - diffs.x[index]);
                const modY = yIdx.map((yI, index) => yI - diffs.y[index]);
                return this.updateEntry(groupEntry, modX, modY, lockedProperty, NO_OFFSET);
            });
            if (!hasTimePeriod) {
                const fluffy = this.getActiveFluffy();
                groupedDragElements.forEach((groupEntry) =>
                    groupEntry.applyRules(
                        fluffy.rules,
                        fluffy.length,
                        false,
                        event.shiftKey || isHighResolution,
                        lockedProperty,
                        false
                    )
                );
            }
        }

        this.setState({
            currentSlots: limitSlotsToDayof(currentSlots, entry),
            dragElement: entry,
            groupedDragElements,
        });
    },

    endEntryDrag(event) {
        if (event.touches) {
            event.preventDefault();
            event.stopPropagation();
            this._preventScroll = true;
        }

        if (["move", "resize-start", "resize-end"].indexOf(this.state.dragType) === -1) {
            return;
        }

        if (event.touches && event.touches.length > 0) {
            this.handleCancelEntryDrag();
            return;
        }

        this.state.dragStart.target.removeEventListener(
            _.getMoveEvent(event),
            this.updateEntryDrag
        );
        this.state.dragStart.target.removeEventListener(_.getEndEvent(event), this.endEntryDrag);
        this.removeListener(_.getMoveEvent(event), this.updateEntryDrag);
        this.removeListener(_.getEndEvent(event), this.endEntryDrag);

        const entry = this.state.dragElement;

        if (!this.fitsTimeSlot(entry)) {
            this.handleCancelEntryDrag();
            return;
        }

        if (!this.fitsCluster(entry, this.props.data.getClusterKind())) {
            // If in cluster and breaks cluster limits, i.e. on two days in a date cluster or on two weeks in a week cluster
            this.handleCancelEntryDrag();
            return;
        }

        // Cancel if entry is in a cell with a duplicate header object.
        const props = this.props;
        const model = props.data;
        const xHeaders = model.xHeader.getHeaders();
        const yHeaders = model.yHeader.getHeaders();
        let xIndexes = xHeaders.map((header) => header.indexOf(entry, true));
        let yIndexes = yHeaders.map((header) => header.indexOf(entry, true));
        const objects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];

        const hasDuplicatedObjects = _.uniq(_.pluck(objects, "id")).length < objects.length;
        if (hasDuplicatedObjects) {
            this.handleCancelEntryDrag();
            return;
        }

        // 2 Origin cell must have same types of header objects
        const originalEntry = this.state.originalEntry;
        xIndexes = xHeaders.map((header) => header.indexOf(originalEntry, true));
        yIndexes = yHeaders.map((header) => header.indexOf(originalEntry, true));
        const oldHeaderObjects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];
        const newObjectTypes = objects.map((object) => object.type);
        const oldObjectTypes = oldHeaderObjects.map((object) => object.type);

        const hasSameObjectTypes = _.difference(newObjectTypes, oldObjectTypes).length === 0;
        if (!hasSameObjectTypes) {
            this.handleCancelEntryDrag();
            return;
        }

        const selection = this.getSelection();

        if (!selection || !selection.reservations || selection.reservations.length === 0) {
            this.handleCancelEntryDrag();
            return;
        }
        if (
            entry.reservationids[0] !== selection.reservations[0] ||
            Entry.equals(this.state.originalEntry, entry)
        ) {
            this.handleCancelEntryDrag();
            return;
        }

        const allowAvailabilityOverlap = _.isModKey(event);

        const fineInterval = event.shiftKey || this.state.dragIsHighResolution;

        // TODO: Check if size, then check for capacity and whether it should be removed
        if (event.altKey === false) {
            this.saveReservations(entry, {
                allowAvailabilityOverlap,
                originalHeaderObjects: _.pluck(oldHeaderObjects, "id"),
            });
            return;
        }

        this.findOverlappingEntries(entry, false, (entries) => {
            let reservationIds = [];
            // eslint-disable-next-line no-param-reassign
            entries = entries.filter((overlappingEntry) => {
                reservationIds = reservationIds.concat(overlappingEntry.reservationids);
                return (
                    overlappingEntry.reservationids.length !== entry.reservationids.length ||
                    _.difference(overlappingEntry.reservationids, entry.reservationids).length > 0
                );
            });

            if (entries.length === 0) {
                if (fineInterval) {
                    // eslint-disable-next-line no-undef
                    //mixpanel.track("Minor step used", {});
                }
                this.saveReservations(entry, {
                    allowAvailabilityOverlap,
                    originalHeaderObjects: _.pluck(oldHeaderObjects, "id"),
                });
                return;
            }

            if (
                // eslint-disable-next-line no-alert
                !window.confirm(
                    Language.get("nc_dialog_move_x_reservations_to_waiting_list", entries.length)
                )
            ) {
                this.handleCancelEntryDrag();
                return;
            }

            reservationIds = _.unique(reservationIds);
            Reservation.moveToWaitingList(reservationIds, false, (result) => {
                // Moving all overlapping, they should all appear on the waiting list
                // eslint-disable-next-line no-console
                console.log(result);
                this.saveReservations(entry, {
                    allowAvailabilityOverlap,
                    originalHeaderObjects: _.pluck(oldHeaderObjects, "id"),
                });
                this.loadEntries(this.props);
                this.fireReservationEvent(reservationIds);
            });
        });
    },

    updateEntry(prevEntry, xIndexes, yIndexes, lockedProperty, offsetSeconds = 0) {
        // No prevEntry when starting
        let startTimes = this.props.data.getTimeFromIndexes(xIndexes, yIndexes, true);
        try {
            startTimes = _.asArray(MillenniumDateTime.createFromList(startTimes));
        } catch (e) {
            // There are cases where a date cannot be created (e.g. a custom date period can have a column without a date)
            // If so, simply return the previous entry
            return prevEntry;
        }

        if (offsetSeconds !== NO_OFFSET) {
            let offset = offsetSeconds;
            if (!offset && this.state.dragStart && this.state.dragStart.offsetSeconds) {
                offset = this.state.dragStart.offsetSeconds;
            }
            if (lockedProperty && offset) {
                startTimes = startTimes.map((item) => item.addSeconds(offset));
            }
        }

        if (
            this.props.data.spotlightDate &&
            !this.context.useNewReservationGroups &&
            this.getSelection().isGroupMode
        ) {
            if (
                !_.some(startTimes, (time) =>
                    time.getMillenniumDate().isSameDayAs(this.props.data.spotlightDate)
                )
            ) {
                return prevEntry;
            }
        }

        let entry;
        let endTimes;
        if (lockedProperty === "length") {
            if (!prevEntry) {
                endTimes = this.props.data.getTimeFromIndexes(xIndexes, yIndexes, false); // If no times in calendar, end on full day! Or on default end.
                endTimes = _.asArray(MillenniumDateTime.createFromList(endTimes));
                entry = new Entry(startTimes, endTimes);
            } else {
                entry = new Entry(
                    startTimes,
                    startTimes.map((item) => item.addSeconds(prevEntry ? prevEntry.getLength() : 0))
                ); // If no times in calendar, end on full day! Or on default end.
            }
        } else if (lockedProperty === "start") {
            endTimes = this.props.data.getTimeFromIndexes(xIndexes, yIndexes, false); // If no times in calendar, end on full day! Or on default end.
            endTimes = _.asArray(MillenniumDateTime.createFromList(endTimes));
            entry = new Entry(prevEntry.startTimes, endTimes);
        } else if (lockedProperty === "end") {
            entry = new Entry(startTimes, prevEntry.endTimes);
        } else if (!lockedProperty) {
            endTimes = this.props.data.getTimeFromIndexes(xIndexes, yIndexes, false);
            endTimes = _.asArray(MillenniumDateTime.createFromList(endTimes));
            entry = new Entry(startTimes, endTimes);
        }
        entry.reservationids = prevEntry ? prevEntry.reservationids : [];
        entry.clusterReservationIds = prevEntry ? prevEntry.clusterReservationIds : undefined;
        entry.capacityReservationIds = prevEntry ? prevEntry.capacityReservationIds : [];
        entry.grouped = prevEntry ? prevEntry.grouped : false;
        entry.groups = prevEntry ? prevEntry.groups : [];
        entry.dragType = prevEntry ? prevEntry.dragType : undefined;

        const objects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];
        entry.periods = this.props.data.getPeriodFromIndexes(xIndexes, yIndexes);
        entry.objects = objects.map((obj) => obj.id);
        entry.types = objects.map((obj) => obj.type);

        return entry;
    },

    getLayerContent() {
        let close = this.handleCancelEntryDrag;
        let content = (
            <FieldDialog
                reservationFields={this.getActiveFluffy().fieldItems.map(
                    (fieldItem) => fieldItem.field
                )}
                onCancel={this.handleCancelEntryDrag}
                onFinished={this.onFieldDialogDismissed}
            />
        );
        if (this.state.showEmailField === true) {
            close = this.mailDialogClosed;
            const onFinished = () => {
                // eslint-disable-next-line no-undef
                /*mixpanel.track("E-mail sent", {
                    "Explicit action": true,
                    "Invoked from calendar": true,
                    "Invoked from list": false,
                    "Number of reservations in message": this.state.emailReservationIds.length,
                });*/
                this.onMailDialogDismissed();
            };
            content = (
                <EmailDialog
                    reservations={this.state.emailReservationIds}
                    onFinished={onFinished}
                    onCancel={this.onMailDialogDismissed}
                />
            );
        }
        return (
            <Popover
                target="center"
                style={{ width: Popover.DEFAULT_WIDTH }}
                onClose={close}
                noClickToClose={true}
            >
                {content}
            </Popover>
        );
    },

    onLayerClose() {
        if (this.state.showEmailField) {
            this.onMailDialogDismissed();
            return;
        }

        this.handleCancelEntryDrag();
    },

    mailDialogClosed() {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            showEmailField: false,
            emailReservationIds: [],
        });
    },

    onMailDialogDismissed() {
        this.props.hideLayer();
        this.mailDialogClosed();
    },

    enableDrop(event) {
        if (!_.isEventDragDataOfType(event, ["application/x-timeedit-calendar"])) {
            return;
        }
        event.preventDefault();
    },

    onDrop(event) {
        if (_.getDragData(event, "application/x-timeedit-calendar") !== undefined) {
            this.props.onCalendarSwap(
                _.getDragData(event, "application/x-timeedit-calendar"),
                this.props.id
            );
        }
    },

    // callback includes selection, new selection and this.props.data. All need to be updated in context
    setCurrentReservation(
        reservationIds,
        cb = _.noop,
        isTemporary = false,
        willModify = false,
        isWaitingList = false,
        length = false,
        isNewReservation = false,
        selection = this.getSelection()
    ) {
        this.setState(
            {
                overlapView: this.state.overlapView ? false : this.state.overlapView,
            },
            () => {
                selection.setReservation(
                    reservationIds,
                    (newSelection) => {
                        if (!isTemporary) {
                            this.setState({ previousReservationId: null });
                        }
                        if (cb === _.noop) {
                            this.context.update(selection, newSelection);
                        } else {
                            cb(selection, newSelection);
                        }
                    },
                    willModify,
                    isWaitingList,
                    length,
                    isNewReservation,
                    this.props.data.getClusterKind()
                );
            }
        );
    },

    enableEditMode(entry, opts = {}, cb = _.noop) {
        this.onOverlapViewClose();
        const defaults = {
            addObject: undefined,
            fieldItems: undefined,
            selection: this.getSelection(),
            data: this.props.data,
            createGroup: false,
            preservePreferDouble: false,
        };
        const settings = _.extend({}, defaults, opts);
        const { data, addObject, fieldItems, selection } = settings;

        const xHeaders = data.xHeader.getHeaders();
        const yHeaders = data.yHeader.getHeaders();

        const getLastIndex = function (soughtEntry, header, i, headers) {
            try {
                if (i === headers.length - 1) {
                    return header.lastIndexOf(soughtEntry, true);
                }
                return header.indexOf(soughtEntry, true);
            } catch (err) {
                return -1;
            }
        };

        const getFirstIndex = function (subentry, header) {
            try {
                return header.indexOf(subentry, true);
            } catch (err) {
                return Infinity;
            }
        };

        const subentries = entry.getSubentries(data.getHeaderObjects()).filter((subentry) => {
            let x0;
            let y0;
            let x1;
            let y1;
            try {
                x0 = data.xHeader.indexOf(subentry, true);
            } catch (e) {
                x0 = 0;
            }
            try {
                x1 = data.xHeader.lastIndexOf(subentry, true);
            } catch (e) {
                x1 = 0;
            }
            try {
                y0 = data.yHeader.indexOf(subentry, true);
            } catch (e) {
                y0 = 0;
            }
            try {
                y1 = data.yHeader.lastIndexOf(subentry, true);
            } catch (e) {
                y1 = 0;
            }
            const isHidden = (x0 <= 0 && x1 <= 0) || (y0 <= 0 && y1 <= 0);
            return !isHidden;
        });
        const x0s = _.sortByNumericColumns(
            subentries.map((subentry) => xHeaders.map(getFirstIndex.bind(this, subentry)))
        );
        const y0s = _.sortByNumericColumns(
            subentries.map((subentry) => yHeaders.map(getFirstIndex.bind(this, subentry)))
        );
        const x1s = _.sortByNumericColumns(
            subentries.map((subentry) => xHeaders.map(getLastIndex.bind(this, subentry)))
        );
        const y1s = _.sortByNumericColumns(
            subentries.map((subentry) => yHeaders.map(getLastIndex.bind(this, subentry)))
        );

        const showSpotlight = () =>
            this.setState({
                editedEntry: entry,
                showSpotlight: true,
                spotlightCoordinates: {
                    x0: x0s[0],
                    y0: y0s[0],
                    x1: x1s[x1s.length - 1],
                    y1: y1s[y1s.length - 1],
                },
            });

        if (entry.kind === EntryKind.NONE) {
            if (selection.isGroupMode && !this.context.useNewReservationGroups) {
                this.setSpotlightOnDate(
                    entry.startTimes[0].getMillenniumDate(),
                    null,
                    this.props.data,
                    this.NO_SPOTLIGHT_TIMEOUT
                );
            } else {
                showSpotlight();
            }

            let newSelection = selection.enableEditMode(entry, settings.createGroup);
            if (fieldItems && fieldItems.length > 0) {
                newSelection = newSelection.setFluffyFields(
                    fieldItems.map((fieldItem) => fieldItem.field)
                );
            }

            const finish = (finalSelection) => {
                if (cb !== _.noop) {
                    cb(selection, finalSelection);
                    return;
                }
                this.context.update(selection, finalSelection);
                return;
            };

            // Rebuild new list of objects with header objects first, then set them all to McFluffy and use that fluffy
            // Should really check if the header object is present in the resulting fluffy, otherwise things have failed
            if (entry.objects) {
                const xIndexes = xHeaders.map((header) => header.indexOf(entry, true));
                const yIndexes = yHeaders.map((header) => header.indexOf(entry, true));
                const objects = this.props.data.getObjectsFromIndexes(xIndexes, yIndexes) || [];
                // Objects: id, typeId, name
                const headerObjects = objects.map((obj) => ({
                    id: obj.id,
                    typeId: obj.type,
                    name: obj.name,
                }));
                const fluffy = newSelection.fluffy;
                const fluffyObjects = fluffy
                    .getObjects()
                    .filter((fo) => !_.contains(entry.types, fo.typeId));
                const allObjects = headerObjects.concat(fluffyObjects);
                // Filter out other objects of same type as header objects
                fluffy.setObjects(allObjects, settings.preservePreferDouble, (resultFluffy) => {
                    if (
                        _.some(
                            fluffy.fieldItems,
                            (item) => item.field.values && item.field.values.length > 0
                        ) &&
                        _.isEqual(
                            fluffy.fieldItems.map((item) => item.field.id),
                            resultFluffy.fieldItems.map((item) => item.field.id)
                        )
                    ) {
                        // eslint-disable-next-line no-param-reassign
                        resultFluffy.fieldItems = fluffy.fieldItems;
                    }

                    finish(newSelection.setFluffy(resultFluffy));
                });
            } else {
                finish(newSelection);
            }
        }

        this.initReservationEdit(
            entry.reservationids,
            selection,
            data,
            (initializedSelection, newData, wasSuccessful) => {
                if (!wasSuccessful) {
                    Log.warning(Language.get("err_res_no_modify"));
                    return;
                }

                if (selection.isGroupMode && !this.context.useNewReservationGroups) {
                    this.setSpotlightOnDate(
                        entry.startTimes[0].getMillenniumDate(),
                        null,
                        this.props.data,
                        this.NO_SPOTLIGHT_TIMEOUT
                    );
                } else {
                    showSpotlight();
                }

                const newSelection = initializedSelection.enableEditMode(entry);
                if (addObject === undefined || addObject === null) {
                    if (cb !== _.noop) {
                        cb(selection, newSelection, newData);
                        return;
                    }
                    this.context.update(data, newData);
                    this.context.update(selection, newSelection);
                    return;
                }
                if (cb !== _.noop) {
                    newSelection.fluffy.addObject(
                        addObject,
                        (newFluffy) =>
                            cb(
                                selection,
                                newSelection.setFluffy(newFluffy, !newSelection.isGroupMode),
                                newData
                            ),
                        false
                    );
                    return;
                }
                newSelection.fluffy.addObject(
                    addObject,
                    (newFluffy) => {
                        this.context.update(data, newData);
                        this.context.update(
                            selection,
                            newSelection.setFluffy(newFluffy, !newSelection.isGroupMode)
                        );
                    },
                    false
                );
            }
        );
    },

    initReservationEdit(reservationIds, selection, data, cb) {
        selection.setReservation(
            reservationIds,
            (newSelection, wasSuccessful) => {
                if (!wasSuccessful) {
                    cb(selection, data, false);
                    return;
                }

                if (this.getSelection().getCurrentReservationId() !== reservationIds[0]) {
                    this.setState(
                        { previousReservationId: this.getSelection().getCurrentReservationId() },
                        () => {
                            cb(newSelection, data, true);
                        }
                    );
                } else {
                    cb(newSelection, data, true);
                }
            },
            true,
            false,
            false,
            false,
            this.props.data.getClusterKind()
        );
    },

    disableEditMode(showSpotlight = false) {
        this.handleCancelEntryDrag();
        // Reset previous current entry
        if (this.state.previousReservationId) {
            this.setCurrentReservation(this.state.previousReservationId);
        }
        this.setState({
            editedEntry: null,
            showSpotlight,
            overlapView: false,
            previousReservationId: null,
        });
    },

    emailReservation(reservationIds, includeSelection = true) {
        let mailIds = [].concat(reservationIds);
        if (includeSelection === true) {
            mailIds = mailIds.concat(
                this.props.data.selectionGroup.reduce(
                    (acc, entry) => acc.concat(entry.reservationids),
                    []
                )
            );
            mailIds = _.uniq(mailIds);
        }
        this.setState(
            {
                showEmailField: true,
                emailReservationIds: mailIds,
            },
            this.props.showLayer
        );
    },

    moveToWaitingList(reservationIds, singleReservation) {
        this.onOverlapViewClose();
        // eslint-disable-next-line no-unused-vars
        Reservation.moveToWaitingList(reservationIds, singleReservation, (result) => {
            this.loadEntries(this.props);
            this.fireReservationEvent(reservationIds);
        });
    },

    gotoToday() {
        this.setState({ tooltip: null, tooltipId: null });
        this.context.update(this.props.data, this.props.data.goToToday());
    },

    endSpotlight() {
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                useSpotlight: false,
                spotlightDate: null,
                spotlightTime: null,
            })
        );
        this.setState({
            showSpotlight: false,
            spotlightCoordinates: null,
        });
    },

    SPOTLIGHT_TIMEOUT: 1500,
    NO_SPOTLIGHT_TIMEOUT: 0,

    setSpotlightOnDate(
        spotlightDate = this.props.data.spotlightDate,
        spotlightTime = this.props.data.spotlightTime,
        data = this.props.data,
        timeout = this.SPOTLIGHT_TIMEOUT
    ) {
        let position = 0;
        let showSpot = true;
        let coordinates = null;
        let headerX = data.xHeader;
        let headerY = data.yHeader;
        let dateHeaderExists = false;
        let weekHeaderExists = false;
        let weekdayHeader = false;
        let weekdayOnXAxis = false;
        let weekDepth = 0;
        let weekdayDepth = 0;
        let weekAndWeekdayHeaders = false;
        let beginTimeIndex = null;
        let endTimeIndex = null;
        let timeIsXAxis = null;

        const withDepth = (pos, depth) => _.fill(_.range(depth), 0).concat(pos);

        const findHeaders = (header, isXAxis) => {
            const numHeaders = header.getHeaders().length;
            for (let i = 0; i < numHeaders; i++) {
                const currentHeader = header.getHeaders()[i];
                if (
                    currentHeader instanceof DateHeader ||
                    currentHeader instanceof DatePeriodHeader ||
                    currentHeader instanceof WeekPeriodHeader ||
                    currentHeader instanceof WeekdayHeader ||
                    currentHeader instanceof WeekdayPeriodHeader
                ) {
                    const foundHeader = currentHeader;

                    try {
                        // getIndexOfDate might throw an error if the date is not available
                        position = foundHeader.getIndexOfDate(spotlightDate, true);
                    } catch (ignoredError) {
                        return;
                    }

                    dateHeaderExists = true;
                    coordinates = {
                        x0: isXAxis ? withDepth(position, i) : [0],
                        y0: isXAxis ? [0] : withDepth(position, i),
                        x1: isXAxis ? withDepth(position + 1, i) : [headerX.visibleValues],
                        y1: isXAxis ? [headerY.visibleValues] : withDepth(position + 1, i),
                    };
                    if (isXAxis) {
                        headerX = foundHeader;
                    } else {
                        headerY = foundHeader;
                    }
                }
                if (currentHeader instanceof WeekHeader) {
                    weekHeaderExists = true;
                    weekDepth = i;
                }
                if (currentHeader instanceof WeekdayHeader) {
                    weekdayHeader = true;
                    weekdayDepth = i;
                    if (isXAxis) {
                        weekdayOnXAxis = true;
                    }
                }
                if (spotlightTime && currentHeader instanceof TimeHeader) {
                    timeIsXAxis = isXAxis;
                    beginTimeIndex = [currentHeader.getIndexOfTime(spotlightTime.beginTime, true)];
                    let endIndex = currentHeader.getIndexOfTime(spotlightTime.endTime, true);
                    if (endIndex <= 0) {
                        endIndex =
                            currentHeader.firstVisibleValue + currentHeader.visibleValues - 1;
                    }
                    endTimeIndex = [endIndex];
                }
            }
        };

        findHeaders(data.xHeader, true);
        findHeaders(data.yHeader, false);

        if (!dateHeaderExists) {
            if (weekHeaderExists && weekdayHeader) {
                weekAndWeekdayHeaders = true;
                position = spotlightDate.getDay();
                const FALLBACK_POSITION = 6;
                if (position >= 1) {
                    position = position - 1;
                } else {
                    position = FALLBACK_POSITION;
                }
                coordinates = {
                    x0: weekdayOnXAxis ? withDepth(position, weekdayDepth) : [0],
                    y0: weekdayOnXAxis ? [0] : withDepth(position, weekDepth),
                    x1: weekdayOnXAxis
                        ? withDepth(position + 1, weekdayDepth)
                        : [headerX.visibleValues],
                    y1: weekdayOnXAxis
                        ? [headerY.visibleValues]
                        : withDepth(position + 1, weekDepth),
                };
            } else {
                showSpot = false;
                coordinates = {};
            }
        }
        if (beginTimeIndex && endTimeIndex) {
            if (timeIsXAxis) {
                coordinates.x0 = beginTimeIndex;
                coordinates.x1 = endTimeIndex;
            } else {
                coordinates.y0 = beginTimeIndex;
                coordinates.y1 = endTimeIndex;
            }
        }

        this.setState({
            weekAndWeekdayHeaders,
            showSpotlight: showSpot,
            spotlightCoordinates: coordinates,
        });
        if (timeout !== this.NO_SPOTLIGHT_TIMEOUT) {
            this._spotlightTimeout = setTimeout(this.endSpotlight, timeout);
        }
    },

    onScroll(isHorizontal, pos) {
        this._shouldScrollDateFire = true;
        const header = this.props.data.getActiveHeader(isHorizontal);
        this.setState({ tooltip: null, tooltipId: null });
        this.context.update(
            header,
            header.immutableSet({
                firstVisibleValue: Math.round(pos * header.length()),
            })
        );
    },

    onScrollX(pos) {
        this.onScroll(true, pos);
    },

    onScrollY(pos) {
        this.onScroll(false, pos);
    },

    setActiveHeader(isXAxis, activeHeader) {
        const header = isXAxis ? this.props.data.xHeader : this.props.data.yHeader;
        this.context.update(header, header.setActiveHeader(activeHeader));
    },

    getDateInfo() {
        if (this.props.data.hasDayProvider()) {
            return null;
        }

        const dateInfoString = SimpleDateFormat.format(
            this.props.data.firstVisibleDate,
            Language.getDateFormat("date_f_yyyy_mm_dd")
        );
        return (
            <span key="date">
                <strong>{Language.get("cal_date")}</strong>: {dateInfoString}
            </span>
        );
    },

    updateTypeFilterInfo(props = this.props) {
        const updateString = () => {
            if (props.data.typeFilter.length === 0) {
                this.setState({ typeFilterString: "" });
                return;
            }

            const typeFilterString = props.data.typeFilter
                .map((id) => {
                    const found = _.find(this._types, (type) => type.id === id);
                    if (found) {
                        return found.name;
                    }
                    return id;
                })
                .join(", ");

            this.setState({
                typeFilterString,
            });
        };

        if (this._types) {
            updateString();
            return;
        }

        API.findTypes((types) => {
            this._types = types;
            updateString();
        });
    },

    getSpotlightStyle() {
        const overlayStyle = _.clone(this.props.size);

        if (!this.state.showSpotlight) {
            return overlayStyle;
        }
        if (
            !this.state.spotlightCoordinates ||
            _.any(_.values(this.state.spotlightCoordinates), (value) => value === undefined) ||
            _.values(this.state.spotlightCoordinates).length === 0
        ) {
            return overlayStyle;
        }

        const model = this.props.data;
        const headerX = model.xHeader;
        const headerY = model.yHeader;
        const entryCoords = this.state.spotlightCoordinates;
        let x = this.getCoordinateFromIndexes(entryCoords.x0, true, headerX);
        let y = this.getCoordinateFromIndexes(entryCoords.y0, false, headerY);
        const w = this.getCoordinateFromIndexes(entryCoords.x1, true, headerX) - x;
        const h = this.getCoordinateFromIndexes(entryCoords.y1, false, headerY) - y;
        x = x + this.getYHeaderWidth();
        y = y + this.getXHeaderHeight();

        const GRADIENT_DIVIDER = 2;
        const spotlightGradient = `radial-gradient(${w}px ${h}px at ${x + w / GRADIENT_DIVIDER}px ${
            y + h / GRADIENT_DIVIDER
        }px, transparent 0px, transparent 70%, rgba(0, 0, 0, 0.5) 80%)`;

        overlayStyle.backgroundImage = spotlightGradient;

        return overlayStyle;
    },

    onOverlapViewClose() {
        if (this.state.overlapView === false && this.state.overlapEntries === null) {
            return;
        }
        this.setState({
            overlapView: false,
            overlapEntries: null,
        });
    },

    setDateSelectVisible(visible) {
        this.setState({
            showDateSelector: visible,
        });
    },

    toggleConflictMode() {
        this.setState({
            isConflictMode: !this.state.isConflictMode,
        });
    },

    renderOverlapView(entryLayerSize, xHeight, yWidth) {
        if (!this.state.overlapView || !this.state.overlapEntries) {
            return null;
        }

        const indexHeader = new IndexHeader(
            this.state.overlapEntries && this.state.overlapEntries.length > 0
                ? this.state.overlapEntries[0].numOverlappingEntries
                : 0
        );
        const xHeader = this.props.data.xHeader.hasTime() ? this.props.data.xHeader : indexHeader;
        const yHeader = this.props.data.yHeader.hasTime() ? this.props.data.yHeader : indexHeader;

        const datePeriodHeader = this.props.data.getHeader(DatePeriodHeader);
        const isWeekCluster =
            this.props.data.getClusterKind() === ClusterKind.WEEK ||
            (datePeriodHeader && datePeriodHeader.isWeekPeriod);

        return (
            <div
                className="entryOverlay"
                style={{ left: yWidth, top: xHeight }}
                onClick={this.onOverlapViewClose}
            >
                <EntryLayer
                    flags={this.props.flags}
                    showTooltip={this.showTooltip}
                    hideTooltip={this.hideTooltip}
                    colorDefs={this.props.colorDefs}
                    isOverlapView={true}
                    onEntryClick={this.onEntryClick}
                    onLockedEntryClick={this.onLockedEntryClick}
                    selectionGroup={this.props.data.selectionGroup}
                    selectionContainsGroups={this.props.data.selectionContainsGroups()}
                    isGroupMode={this.getSelection().isGroupMode}
                    clusterKind={this.props.data.getClusterKind()}
                    isWeekCluster={isWeekCluster}
                    addToGroup={this.addToGroup}
                    addReservationToTime={this.addReservationToTime}
                    createGroup={this.createGroup}
                    createGroupFromEntry={this.createGroupFromEntry}
                    deleteGroup={this.deleteGroup}
                    editGroup={this.editGroup}
                    isLegalGroup={this.props.data.isLegalGroup(
                        this.context.useNewReservationGroups
                    )}
                    entries={this.state.overlapEntries}
                    isActive={this.props.isActive}
                    enableEditMode={this.enableEditMode}
                    onEntryDelete={this.onEntryDelete}
                    selectedReservationIds={this.getSelection().reservations}
                    moveToWaitingList={this.moveToWaitingList}
                    activeLayer={this.props.activeLayer}
                    size={entryLayerSize}
                    xHeader={xHeader}
                    yHeader={yHeader}
                    background={false}
                    headerObjects={[]}
                    spotlightDate={this.state.showSpotlight ? this.props.data.spotlightDate : null}
                    emailReservation={this.emailReservation}
                    maxClusterDepth={this.props.data.getMaxClusterDepth()}
                    getClusterValues={this.props.data.getClusterValues.bind(this.props.data)}
                    onEntryInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.onEditReservationExceptions}
                    infoEntryReservationIds={this.props.infoEntryReservationIds}
                    unlockedReservations={this.props.unlockedReservations}
                    temporaryUnlock={this.props.temporaryUnlock}
                    skipConfirmation={this.props.skipConfirmation}
                    endLockMode={this.props.endLockMode}
                    colorTypes={this.props.data.colorTypes}
                    readOnly={this.props.data.readOnly}
                    onDynamicReservationIdsChanged={this.props.onDynamicReservationIdsChanged}
                    openStaticReservationListAdd={this.props.openStaticReservationListAdd}
                    templateKind={this.props.data.templateKind}
                    paddingActive={this.props.data.isPaddingActive}
                    isConflictMode={this.state.isConflictMode}
                    isSizeMode={this.fluffyHasSize()}
                />
            </div>
        );
    },

    onSummaryDelete(summaryId) {
        API.deleteSumSetting(summaryId, (result) => {
            if (result === false) {
                // eslint-disable-next-line no-console
                console.log("Could not delete the summary");
                return;
            }
            // eslint-disable-next-line no-undef
            /*mixpanel.track("Summary deleted", {
                Summary: summaryId,
            });*/
            const filters = _.filter(this.state.filters, (filter) => filter.id !== summaryId);
            const selectedSummary =
                this.state.selectedSummary === summaryId ? 0 : this.state.selectedSummary;
            this.setState({
                filters,
                selectedSummary,
            });
            if (selectedSummary === 0) {
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({ sumTypes: [], sumIncludeMembers: [] })
                );
            }
        });
    },

    onSummaryDone(summary) {
        API.setSumSetting(summary, (result) => {
            if (!result.ok) {
                // eslint-disable-next-line no-console
                console.log("Could not save summary.");
                return;
            }
            // eslint-disable-next-line no-unused-vars
            this.props.updateSumTypes((updatedSumTypes) => {
                // eslint-disable-next-line no-undef
                /*mixpanel.track("Summary saved", {
                    Summary: result.id,
                });*/
                this.setState({
                    selectedSummary: result.id,
                });
                this.context.update(
                    this.props.data,
                    this.props.data.immutableSet({
                        sumTypes: summary.types,
                        sumIncludeMembers: summary.types.map(
                            (type) => summary.includeMembers.indexOf(type) !== -1
                        ),
                        sumIsFrameTime: summary.isFrameTime,
                        sumFrameTemplateGroup: summary.templateGroup,
                    })
                );
            });
        });
    },

    onSummaryChanged(summarySettings) {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("Summary selected", {
            Summary: summarySettings.selectedSum,
        });*/
        this.setState({
            selectedSummary: summarySettings.selectedSum,
        });
        const summary = _.find(
            this.state.filters,
            (filter) => filter.id === summarySettings.selectedSum
        ) || { types: [], includeMembers: [] };
        this.context.update(
            this.props.data,
            this.props.data.immutableSet({
                sumTypes: summary.types,
                sumIncludeMembers: summary.types.map(
                    (type) => summary.includeMembers.indexOf(type) !== -1
                ),
                sumWeek: summarySettings.selectedWeek,
                sumIsFrameTime: summary.frameTime,
                sumFrameTemplateGroup: summary.templateGroup,
            })
        );
    },

    onSumUnitChanged(sumUnit) {
        this.context.update(
            this.context.user,
            this.context.user.setReservationSummaryUnit(sumUnit)
        );
    },

    displaySumWindow() {
        this.setState({ displaySumWindow: !this.state.displaySumWindow });
    },

    getAvailableSlots() {
        const fluffy = this.getActiveFluffy();
        if (!fluffy || !fluffy.rules || !fluffy.rules.time_slots) {
            return null;
        }
        return fluffy.rules.time_slots.available_times;
    },

    getTimeSlots() {
        return this.state.currentSlots || [];
    },

    render() {
        if (this.props.data.isDateSelector) {
            return <UnsupportedCalendar onClose={this.props.onClose} size={this.props.size} />;
        }
        const entryLayerSize = this.getEntryLayerSize();

        const xHeaderSize = {
            width: entryLayerSize.width,
            height: this.getXHeaderHeight(),
        };

        const yHeaderSize = {
            width: this.getYHeaderWidth(),
            height: entryLayerSize.height,
        };

        const functionMode = this.props.data.functionMode;
        const templateKind = this.props.data.templateKind;
        const selection = this.getSelection();
        const isResizeDrag =
            ["create", "resize-start", "resize-end"].indexOf(this.state.dragType) > -1 &&
            (!selection.isWaitingListMode() || !selection.length);
        const timeAxis = this.props.data.xHeader.hasTime() ? "X" : "Y";

        const classNames = {
            active: this.props.isActive,
            inactive: !this.props.isActive,
            calendar: true,
            cluster: this.props.data.isCluster(),
            typeFilter: this.props.data.typeFilter.length > 0,
            select: FunctionMode.SELECT.equals(functionMode),
            statistics: FunctionMode.STATISTICS.equals(functionMode),
            dragX: isResizeDrag && timeAxis === "X",
            dragY: isResizeDrag && timeAxis === "Y",
            move:
                this.state.dragType === "move" ||
                this.state.dragType === "copy" ||
                (this.state.dragType === "create" &&
                    selection.isWaitingListMode() &&
                    selection.length > 0),
            info: TemplateKind.equals(TemplateKind.INFO_RESERVATION, templateKind),
            availability: TemplateKind.equals(TemplateKind.AVAILABILITY, templateKind),
        };

        let swapX = null;
        let swapY = null;
        let isXRemovable = false;
        let isYRemovable = false;
        if (this.props.data.xHeader.getHeaders().length > 1) {
            swapX = this.swapHeaders.bind(this, true);
            isXRemovable = true;
        }
        if (this.props.data.yHeader.getHeaders().length > 1) {
            swapY = this.swapHeaders.bind(this, false);
            isYRemovable = true;
        }

        let entries = [].concat(this.props.data.entries);
        if (this.state.dragType === "move") {
            entries = entries.filter((entry) => entry.kind !== EntryKind.OCCUPIED_HIDE);
        }
        const createEntryFilter =
            (comparisonEntry, filterGroups = false) =>
            (entry) => {
                if (
                    filterGroups &&
                    entry.grouped &&
                    !entry.isLocked(this.props.unlockedReservations)
                ) {
                    return false;
                }

                return (
                    comparisonEntry.reservationids.length !== entry.reservationids.length ||
                    _.difference(comparisonEntry.reservationids, entry.reservationids).length !== 0
                );
            };
        if (this.state.dragElement) {
            if (["move", "resize-start", "resize-end"].indexOf(this.state.dragType) > -1) {
                entries = entries.filter(createEntryFilter(this.state.dragElement, true));
            }
        }
        if (this.state.editedEntry) {
            entries = entries.filter(createEntryFilter(this.state.editedEntry));
            const editedEntry = _.clone(this.state.editedEntry);
            editedEntry.kind =
                this.state.editedEntry.kind === EntryKind.NONE
                    ? EntryKind.NONE
                    : EntryKind.COMPLETE;
            entries = entries.concat([editedEntry]);
        }

        const typeFilterInfo = this.state.typeFilterString ? (
            <span key="typefilter">
                <strong>{Language.get("nc_cal_res_side_view_filter_type")}</strong>:{" "}
                {this.state.typeFilterString}
            </span>
        ) : null;
        const calendarInfo = _.compact([this.getDateInfo(), typeFilterInfo]);
        const providerMap = this.props.data.getProviderMap();
        const xHeader = this.props.data.xHeader.hide ? null : (
            <Header
                isRoot={true}
                axis="x"
                size={xHeaderSize}
                data={this.props.data.xHeader}
                onHeaderSwap={swapX}
                isRemovable={isXRemovable}
                onHeaderChange={this.setHeader.bind(this, true)}
                getAvailableHeaders={this.props.data.getAvailableHeaders.bind(this.props.data)}
                onActiveHeaderChange={this.setActiveHeader.bind(this, true)}
                onDateClick={this.fireSetDateEvent}
                info={calendarInfo}
                currentDateTime={this.state.currentDateTime}
                providers={providerMap}
            />
        );
        const yHeader = this.props.data.yHeader.hide ? null : (
            <Header
                isRoot={true}
                axis="y"
                size={yHeaderSize}
                data={this.props.data.yHeader}
                onHeaderSwap={swapY}
                isRemovable={isYRemovable}
                onHeaderChange={this.setHeader.bind(this, false)}
                getAvailableHeaders={this.props.data.getAvailableHeaders.bind(this.props.data)}
                onActiveHeaderChange={this.setActiveHeader.bind(this, false)}
                onDateClick={this.fireSetDateEvent}
                currentDateTime={this.state.currentDateTime}
                providers={providerMap}
            />
        );
        let calendarMenu = null;
        if (xHeader && yHeader) {
            calendarMenu = (
                <CalendarMenu
                    isPrivate={this.props.data.privateSelected}
                    size={{ width: yHeaderSize.width, height: xHeaderSize.height }}
                    menuItems={this.getMenuItems()}
                    isActive={this.props.isActive}
                    calendarId={this.props.id}
                    showId={this.props.showId}
                />
            );
        }

        const overlapView = this.renderOverlapView(
            entryLayerSize,
            xHeaderSize.height,
            yHeaderSize.width
        );
        const overlayStyle = this.getSpotlightStyle();
        const overlayClasses = {
            overlay: true,
            dim: this.state.overlapView || this.state.displayFieldDialog,
            spotlight: this.state.showSpotlight,
            hidden:
                !this.state.overlapView &&
                !this.state.showSpotlight &&
                !this.state.displayFieldDialog,
            activeOverlay: this.props.isActive,
        };

        const allNavButtonsStyle = this.state.dragElement ? { opacity: 0.2 } : {};
        const datePeriodHeader = this.props.data.getHeader(DatePeriodHeader);
        const isWeekCluster =
            this.props.data.getClusterKind() === ClusterKind.WEEK ||
            (datePeriodHeader && datePeriodHeader.isWeekPeriod);

        const MIN_LAYER_WIDTH = 150;
        const MIN_LAYER_HEIGHT = 100;
        const navButtonsVisible =
            entryLayerSize.width > MIN_LAYER_WIDTH && entryLayerSize.height > MIN_LAYER_HEIGHT;

        let toolButtonsX = null;
        let toolButtonsY = null;
        if (this.props.isActive && navButtonsVisible) {
            toolButtonsX = (
                <ToolButtons
                    ref={(component) => {
                        this._navbutton = component;
                    }}
                    calendar={this.props.data}
                    isXAxis={true}
                    registerTimeListener={this.registerTimeListener}
                    showDateSelectButton={true}
                    toggleCommandBar={this.props.toggleCommandBar}
                    setDateSelectorVisibility={this.setDateSelectVisible}
                    showDateSelect={this.state.showDateSelector}
                />
            );
            toolButtonsY = (
                <ToolButtons
                    calendar={this.props.data}
                    isXAxis={false}
                    showDateSelectButton={false}
                    selectionSize={this.props.data.selectionGroup.length}
                    setDateSelectorVisibility={this.setDateSelectVisible}
                    showDateSelect={false}
                    showConflictModeButton={this.props.flags?.tecoreClientCalendarConflictMode}
                    toggleConflictMode={this.toggleConflictMode}
                    isConflictMode={this.state.isConflictMode}
                />
            );
        }

        let dragElements = this.state.dragElement ? [this.state.dragElement] : [];
        if (this.state.groupedDragElements) {
            dragElements = dragElements.concat(this.state.groupedDragElements);
        }

        const headers = this.props.data.getHeaderTypeMap();
        const clusterWeeks = headers.dateperiod
            ? headers.dateperiod.weeks.length > 0
                ? headers.dateperiod.weeks
                : headers.dateperiod.getWeeks(this.context.customWeekNames).map((dp) => dp.value)
            : this.props.data.getClusterValues();

        let visibleDates = [];
        try {
            visibleDates = this.props.data.getVisibleDates();
        } catch (ignore) {
            // Can I figure out a nicer way of doing this?
        }
        const periodValues = {};
        this.props.data.getPeriodCombinations().forEach((combo) => {
            _.keys(combo).forEach((key) => {
                if (!periodValues[key]) {
                    periodValues[key] = [combo[key]];
                } else {
                    periodValues[key].push(combo[key]);
                }
            });
        });
        return (
            <div
                className={_.classSet(classNames)}
                style={this.props.size}
                onWheel={this._handleScroll}
                onTouchMove={this._handleScroll}
                onMouseDown={this.handleMouseDown}
                onDragOver={this.enableDrop}
                onDrop={this.onDrop}
                onMouseMove={this._trackMousePosition}
                onMouseOut={this._clearTrackedMousePosition}
            >
                {this.state.tooltip}
                {overlapView}
                <div className={_.classSet(overlayClasses)} style={overlayStyle} />
                {this.state.filters.length > 0 || this.context.user.isAdmin ? (
                    <SummaryBar
                        displaySumWindow={this.displaySumWindow}
                        summaryText={getSummaryText(
                            this.state.summaries,
                            this.state.selectedSummary,
                            this.state.filters,
                            this.context.user.reservationSummaryUnit
                        )}
                    />
                ) : null}
                {this.state.displaySumWindow ? (
                    <SummaryWindow
                        summaries={this.state.summaries}
                        filters={this.state.filters}
                        selectedSummary={this.state.selectedSummary}
                        selectedFilter={
                            _.find(
                                this.state.filters,
                                (filter) => filter.id === this.state.selectedSummary
                            ) || null
                        }
                        selectedSummaryWeek={this.props.data.sumWeek}
                        selectedSumUnit={this.context.user.reservationSummaryUnit}
                        isVisible={this.state.displaySumWindow}
                        clusterWeeks={clusterWeeks}
                        onSummaryChanged={this.onSummaryChanged}
                        onSummaryDone={this.onSummaryDone}
                        onSummaryDelete={this.onSummaryDelete}
                        onSummaryCancelled={this.onSummaryCancelled}
                        onSumUnitChanged={this.onSumUnitChanged}
                        onClose={this.displaySumWindow}
                        templateKindNumber={templateKind.number}
                    />
                ) : null}
                {calendarMenu}
                {xHeader}
                {yHeader}
                <EntryLayer
                    flags={this.props.flags}
                    showTooltip={this.showTooltip}
                    hideTooltip={this.hideTooltip}
                    colorDefs={this.props.colorDefs}
                    isOverlapView={false}
                    maxClusterDepth={this.props.data.getMaxClusterDepth()}
                    getClusterValues={this.props.data.getClusterValues.bind(this.props.data)}
                    activeLayer={this.props.activeLayer}
                    clusterKind={this.props.data.getClusterKind()}
                    isWeekCluster={isWeekCluster}
                    selectedReservationIds={this.getSelection().reservations}
                    ref="entryLayer"
                    onMouseDown={this.startEntryCreate}
                    onMouseUp={this.onMouseUp}
                    dragElements={dragElements}
                    enableEditMode={this.enableEditMode}
                    onEntryDelete={this.onEntryDelete}
                    onEntryDragStart={this.startEntryDrag}
                    onEntryClick={this.onEntryClick}
                    onLockedEntryClick={this.onLockedEntryClick}
                    selectionGroup={this.props.data.selectionGroup}
                    selectionContainsGroups={this.props.data.selectionContainsGroups()}
                    addToGroup={this.addToGroup}
                    addReservationToTime={this.addReservationToTime}
                    createGroup={this.createGroup}
                    createGroupFromEntry={this.createGroupFromEntry}
                    deleteGroup={this.deleteGroup}
                    editGroup={this.editGroup}
                    isLegalGroup={this.props.data.isLegalGroup(
                        this.context.useNewReservationGroups
                    )}
                    isGroupMode={this.getSelection().isGroupMode}
                    entries={entries}
                    onEntryCopy={this.onEntryCopy}
                    availability={this.state.availableTime}
                    isAbsoluteAvailability={this.state.isAbsoluteAvailability}
                    availableSlots={this.getAvailableSlots()}
                    timeSlots={this.getTimeSlots()}
                    size={entryLayerSize}
                    emailReservation={this.emailReservation}
                    infoEntryReservationIds={this.props.infoEntryReservationIds}
                    onEntryInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.onEditReservationExceptions}
                    moveToWaitingList={this.moveToWaitingList}
                    isActive={this.props.isActive}
                    showOverlappingEntries={this.showOverlappingEntries}
                    showOverlappingEntriesInList={this.showOverlappingEntriesInList}
                    visibleDates={visibleDates}
                    periodValues={periodValues}
                    xHeader={this.props.data.xHeader}
                    yHeader={this.props.data.yHeader}
                    fallbackDate={
                        this.props.data.hasDayProvider() ? null : this.props.data.firstVisibleDate
                    }
                    headerObjects={this.props.data.getHeaderObjects()}
                    spotlightDate={this.state.showSpotlight ? this.props.data.spotlightDate : null}
                    unlockedReservations={this.props.unlockedReservations}
                    temporaryUnlock={this.props.temporaryUnlock}
                    skipConfirmation={this.props.skipConfirmation}
                    endLockMode={this.props.endLockMode}
                    colorTypes={this.props.data.colorTypes}
                    readOnly={this.props.data.readOnly}
                    onDynamicReservationIdsChanged={this.props.onDynamicReservationIdsChanged}
                    openStaticReservationListAdd={this.props.openStaticReservationListAdd}
                    templateKind={this.props.data.templateKind}
                    skipBackgroundText={this.props.skipBackgroundText}
                    paddingActive={this.props.data.isPaddingActive}
                    isConflictMode={this.state.isConflictMode}
                    isSizeMode={this.fluffyHasSize()}
                />
                <div className="allNavButtons" style={allNavButtonsStyle}>
                    {toolButtonsX}
                    {toolButtonsY}
                </div>
            </div>
        );
    },

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            nextProps.data !== this.props.data ||
            nextProps.size.width !== this.props.size.width ||
            nextProps.size.height !== this.props.size.height ||
            nextProps.selection !== this.props.selection ||
            nextProps.showId !== this.props.showId ||
            nextProps.data.entries.length !== this.props.data.entries.length ||
            nextProps.isActive !== this.props.isActive ||
            !_.isEqual(nextProps.infoEntryReservationIds, this.props.infoEntryReservationIds) ||
            nextProps.id !== this.props.id ||
            !_.isEqual(nextProps.data.selectionGroup, this.props.data.selectionGroup) ||
            !_.isEqual(nextProps.copiedEntry, this.props.copiedEntry) ||
            !_.isEqual(nextContext, this.context) ||
            nextProps.onActiveCalendarChange !== this.props.onActiveCalendarChange ||
            !_.isEqual(nextProps.unlockedReservations, this.props.unlockedReservations) ||
            nextProps.skipBackgroundText !== this.props.skipBackgroundText ||
            nextProps.data.isPaddingActive !== this.props.data.isPaddingActive
        ) {
            return true;
        }

        const willUpdateState =
            nextState.displayFieldDialog !== this.state.displayFieldDialog ||
            nextState.navBarPosition !== this.state.navBarPosition ||
            !_.isEqual(nextState.dragElement, this.state.dragElement) ||
            !_.isEqual(nextState.groupedDragElements, this.state.groupedDragElements) ||
            nextState.dragStart !== this.state.dragStart ||
            nextState.overlapView !== this.state.overlapView ||
            nextState.showSpotlight !== this.state.showSpotlight ||
            nextState.availableTime !== this.state.availableTime ||
            nextState.showDateSelector !== this.state.showDateSelector ||
            nextState.isConflictMode !== this.state.isConflictMode ||
            nextState.isAbsoluteAvailability !== this.state.isAbsoluteAvailability ||
            !_.isEqual(nextState.filters, this.state.filters) ||
            nextState.displaySumWindow !== this.state.displaySumWindow ||
            !_.isEqual(nextState.selectedSummary, this.state.selectedSummary) ||
            !_.isEqual(nextState.summaries, this.state.summaries) ||
            !_.isEqual(nextState.currentDateTime, this.state.currentDateTime) ||
            !_.isEqual(nextState.tooltip, this.state.tooltip) ||
            !_.isEqual(nextState.currentSlots, this.state.currentSlots);

        if (willUpdateState) {
            return true;
        }

        return !(
            _.isEqual(nextProps.data.entries, this.props.data.entries) ||
            this.props.data.entries.length + nextProps.data.entries.length === 0
        );
    },
});

module.exports = Calendar = withLDConsumer()(LayerComponent.wrap(Calendar));
