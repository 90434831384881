export const EntryKind = {
    NONE: -1,
    COMPLETE: 0,
    RESERVATION: 1,
    OBSTACLE: 2,
    OCCUPIED: 3,
    INFO: 4,
    AVAILABILITY: 5,
    GROUP_COMPLETE: 6,
    GROUP: 7,
    OBSTACLE_GROUP: 8,
    OCCUPIED_HIDE: 9,
    UNDEFINED: 1000,
} as const;

export const ClusterKind = {
    NONE: 0,
    WEEK: 1,
    WEEKDAY: 2,
    DATE: 3,
} as const;

export const ENTRY_CLASS = "entryContents";
