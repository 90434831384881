const React = require("react");
const Language = require("../lib/Language");
const MassMoveTimeSelect = require("./MassMoveTimeSelect");
const MassMoveDateSelect = require("./MassMoveDateSelect");
const MassMoveDurationSelect = require("./MassMoveDurationSelect");
const BooleanInput = require("./BooleanInput");
import { SimpleDateFormat, MillenniumDate } from "@timeedit/millennium-time";
// const OBMC = require("../lib/ReservationConstants").OBJECT_MASS_CHANGE;
const PropTypes = require("prop-types");

const START_YEAR = 2010;
const END_YEAR = 2040;
const END_MONTH = 12;
const END_DAY = 31;

class MassMoveReservationsDialog extends React.Component {
    state = {
        daysToMove: 0,
        hours: 0,
        minutes: 0,
        hoursAndMinutesAreForward: true,
        doubleReserveAll: false,
        isDurationRelative: true,
        durationHours: 0,
        durationMinutes: 0,
    };

    static contextTypes = {
        env: PropTypes.object,
    };

    showSelectedDate = (date) => {
        this.setState({ daysToMove: date.dayNumber - this.props.firstDate.dayNumber }, () =>
            this.props.onSelectionChanged(this.state)
        );
    };

    render() {
        const first = SimpleDateFormat.format(
            this.props.firstDate,
            Language.getDateFormat("date_f_yyyy_mm_dd")
        );
        const text = Language.get(
            "nc_mass_move_first_reservation_occurs_at_date_(weekday)",
            first,
            SimpleDateFormat.format(this.props.firstDate, Language.getDateFormat("date_f_eee"))
        );

        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                        style={{
                            borderRight: "1px solid grey",
                            marginRight: "40px",
                            paddingRight: "40px",
                        }}
                    >
                        {this._renderTimeSelect()}
                    </div>
                    <div>
                        <div className="massMoveInformation">
                            <p className="headline">
                                {this.props.isMassChangeCopy
                                    ? Language.get("nc_mass_move_select_date_to_move_copies_to")
                                    : Language.get("nc_mass_move_select_date_to_move_to")}
                            </p>
                            <p>{text}</p>
                        </div>
                        {this._renderDateSelect()}
                    </div>
                    <div
                        style={{
                            borderLeft: "1px solid grey",
                            marginLeft: "40px",
                            paddingLeft: "40px",
                        }}
                    >
                        {this._renderDurationSelect()}
                    </div>
                </div>
                <div key="dbBox">
                    <BooleanInput
                        defaultValue={this.state.doubleReserveAll}
                        onUpdate={this.onDoubleReserveAllChanged}
                    />{" "}
                    {Language.get("cal_selected_allow_double_res")}
                </div>
            </div>
        );
    }

    onHoursChanged = (hours) => {
        this.setState({ hours }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onMinutesChanged = (minutes) => {
        this.setState({ minutes }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onTimeDirectionChanged = (event) => {
        this.setState({ hoursAndMinutesAreForward: event.target.value === "true" }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onDoubleReserveAllChanged = (doubleReserveAll) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Allow double booking", {
            Checked: doubleReserveAll,
            "Change type": Object.keys(OBMC)[this.props.changeType],
        });*/
        this.setState({ doubleReserveAll }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onRelativeDurationChanged = (event) => {
        this.setState({ isDurationRelative: event.target.value === "true" }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onDurationHoursChanged = (durationHours) => {
        this.setState({ durationHours }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    onDurationMinutesChanged = (durationMinutes) => {
        this.setState({ durationMinutes }, () => {
            this.props.onSelectionChanged(this.state);
        });
    };

    _renderTimeSelect = () => (
        <MassMoveTimeSelect
            hours={this.state.hours}
            minutes={this.state.minutes}
            hoursAndMinutesAreForward={this.state.hoursAndMinutesAreForward}
            onHoursChanged={this.onHoursChanged}
            onMinutesChanged={this.onMinutesChanged}
            onDirectionChanged={this.onTimeDirectionChanged}
        />
    );

    _renderDateSelect = () => (
        <MassMoveDateSelect
            showSelectedDate={this.showSelectedDate}
            weekday={this.props.firstDate.getDay()}
            onClose={this.toggleCalendar}
            startDate={MillenniumDate.create(START_YEAR, 1, 1)}
            endDate={MillenniumDate.create(END_YEAR, END_MONTH, END_DAY)}
            now={this.props.firstDate}
            highlightDates={[this.props.firstDate]}
            isMassChangeCopy={this.props.isMassChangeCopy}
        />
    );

    _renderDurationSelect = () => (
        <MassMoveDurationSelect
            numberOfReservations={this.props.reservationIds.length}
            isDurationRelative={this.state.isDurationRelative}
            onRelativeDurationChanged={this.onRelativeDurationChanged}
            hours={this.state.durationHours}
            minutes={this.state.durationMinutes}
            onHoursChanged={this.onDurationHoursChanged}
            onMinutesChanged={this.onDurationMinutesChanged}
        />
    );
}

module.exports = MassMoveReservationsDialog;
