const _ = require("underscore");

const Settings = function (items) {
    this.items = items || [];
};

Settings.prototype.find = function (id) {
    return _.find(this.items, (el) => el.id === id);
};

module.exports = Settings;
