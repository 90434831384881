import { MillenniumWeekday, MillenniumWeek, SimpleDateFormat } from "@timeedit/millennium-time";
import _ from "underscore";

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const customStrings = {
    /* Strings which have not yet been implemented server-side */
    nc_key_del: "Delete",
    nc_error_crash_oops: "Oops.", // Required if SSO auth fails
    nc_error_crash_report_issue_info:
        "If you have the time, it would be great if you could report the problem at {0} and describe what happened right before you got here.", // Required if SSO auth fails
    nc_error_crash_click_here_to_show_technical_information:
        "Click here to show technical information...", // Required if SSO auth fails
    nc_error_crash_no_need_to_worry_just_reload:
        "No need to worry, but as you noticed something went wrong. Just reload the page and everything should be just fine!", // Required if SSO auth fails
    nc_client_update_needed:
        "TimeEdit has been updated. Click OK to reload and apply the update, or click Cancel to reload later.", // Available on server but can, in the very worst case, be needed before strings can be loaded.
    "nc_server_has_ended_session.":
        "You have been logged out. You will now be sent to the login page.", // Available on server but can, in the very worst case, be needed before strings can be loaded.
    nc_calendar_hide_padding: "Hide padding",
};

const Language = {
    firstDayOfWeek: MillenniumWeekday.MONDAY,
    daysInFirstWeek: 4,
    usesWeekNumbers: true,

    getCustomWeek(date, customWeekNames) {
        const week = new MillenniumWeek(date, Language.firstDayOfWeek, Language.daysInFirstWeek);
        const name = _.find(
            customWeekNames,
            (customWeek) => customWeek.dayNumber === week.date.dayNumber
        );
        if (name) {
            return name;
        }
        return null;
    },

    getCustomWeekShortest(date, customWeekNames) {
        const name = Language.getCustomWeek(date, customWeekNames);
        if (!name) {
            return null;
        }
        return name.getShortestName();
    },

    getRaw(key) {
        if (this.strings && this.strings.hasOwnProperty(key)) {
            return this.strings[key];
        }
        if (customStrings.hasOwnProperty(key)) {
            return customStrings[key];
        }
        return `## ${key} ##`;
    },

    /* Accepts an arbitrary number of additional arguments
     * which will be formatted into the string
     * if the string has placeholders.
     */
    get(key) {
        if (key === "nc_key_mod") {
            if (isMac) {
                return this.getRaw("nc_key_cmd");
            }
            return this.getRaw("nc_key_ctrl");
        }
        if (key === "nc_key_mod_short") {
            if (isMac) {
                return this.getRaw("nc_key_cmd_short");
            }
            return this.getRaw("nc_key_ctrl_short");
        }
        let result = this.getRaw(key);
        if (arguments.length > 1) {
            const numArgs = arguments.length - 1;
            let i, arg, index;
            for (i = 0; i < numArgs; i++) {
                // eslint-disable-next-line prefer-rest-params
                arg = arguments[i + 1];
                index = result.indexOf(`{${i}}`);
                if (index !== -1) {
                    result = result.replace(`{${i}}`, arg);
                }
            }
        }
        return result;
    },

    tmp(str) {
        if (process.env.NODE_ENV === "development") {
            console.warn(`Using non-localized string: ${str}`); // eslint-disable-line no-console
        }
        let result = str;
        if (arguments.length > 1) {
            const numArgs = arguments.length - 1;
            let i, arg, index;
            for (i = 0; i < numArgs; i++) {
                // eslint-disable-next-line prefer-rest-params
                arg = arguments[i + 1];
                index = result.indexOf(`{${i}}`);
                if (index !== -1) {
                    result = result.replace(`{${i}}`, arg);
                } else {
                    break;
                }
            }
        }
        return result;
    },

    getDateFormat(key) {
        if (this.dates && this.dates.hasOwnProperty(key)) {
            return this.dates[key];
        }
        if (customStrings.hasOwnProperty(key)) {
            return customStrings[key];
        }
        throw new Error(`Could not find date format with key "${key}".`);
    },

    setData(newStrings, newDates, firstDayOfWeek, daysInFirstWeek, usesWeekNumbers) {
        this.strings = newStrings;
        this.dates = newDates;
        this.firstDayOfWeek = firstDayOfWeek;
        this.daysInFirstWeek = daysInFirstWeek;
        this.usesWeekNumbers = usesWeekNumbers === 1;
    },

    findKeys(searchString) {
        const matches = {};
        let keys = Object.getOwnPropertyNames(this.strings);
        for (let i = 0; i < keys.length; i++) {
            if (this.strings[keys[i]].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
                matches[keys[i]] = this.strings[keys[i]];
            }
        }

        keys = Object.getOwnPropertyNames(customStrings);
        for (let i = 0; i < keys.length; i++) {
            if (customStrings[keys[i]].toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
                matches[keys[i]] = customStrings[keys[i]];
            }
        }

        return matches;
    },

    getShortWeekdayLabels() {
        const NUM_CHARS = 3;
        return [
            Language.get("zz_weekday_a_sunday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_b_monday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_c_tuesday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_d_wednesday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_e_thursday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_f_friday").substr(0, NUM_CHARS),
            Language.get("zz_weekday_g_saturday").substr(0, NUM_CHARS),
        ];
    },

    getWeekdayLabels() {
        return [
            Language.get("zz_weekday_a_sunday"),
            Language.get("zz_weekday_b_monday"),
            Language.get("zz_weekday_c_tuesday"),
            Language.get("zz_weekday_d_wednesday"),
            Language.get("zz_weekday_e_thursday"),
            Language.get("zz_weekday_f_friday"),
            Language.get("zz_weekday_g_saturday"),
        ];
    },
    formatWeekTableCell(startTime, endTime, customWeekNames) {
        let startWeekName = null;
        let endWeekName = null;
        if (customWeekNames.length > 0) {
            startWeekName = Language.getCustomWeek(startTime, customWeekNames);
            endWeekName = Language.getCustomWeek(endTime, customWeekNames);
            if (startWeekName === endWeekName && startWeekName !== null) {
                return startWeekName.getShortestName();
            } else if (startWeekName !== null && endWeekName !== null) {
                return `${startWeekName.getShortestName()} - ${endWeekName.getShortestName()}`;
            }
        }
        const start = SimpleDateFormat.format(startTime, Language.getDateFormat("date_f_yyyy_ww"));
        const end = SimpleDateFormat.format(endTime, Language.getDateFormat("date_f_yyyy_ww_end"));
        if (start === end) {
            if (startWeekName !== null) {
                return startWeekName.getShortestName();
            }
            return start;
        }
        return `${startWeekName ? startWeekName.getShortestName() : start} - ${
            endWeekName
                ? endWeekName.getShortestName()
                : SimpleDateFormat.format(endTime, Language.getDateFormat("date_f_w_end"))
        }`;
    },
    formatSingleWeekTableCell(time, isEnd, customWeekNames) {
        if (customWeekNames.length > 0) {
            const weekName = Language.getCustomWeek(time, customWeekNames);
            if (weekName) {
                return weekName.getShortestName();
            }
        }
        return SimpleDateFormat.format(
            time,
            isEnd
                ? Language.getDateFormat("date_f_yyyy_ww_end")
                : Language.getDateFormat("date_f_yyyy_ww")
        );
    },
};

module.exports = Language;
