const PropTypes = require("prop-types");
const React = require("react");
const Language = require("../lib/Language");
const CalendarMenu = require("./CalendarMenu");
const Macros = require("../models/Macros");
const _ = require("underscore");

const SECOND_BUTTON = 2;

class ListCalendar extends React.Component {
    static contextTypes = {
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        prefsComponentActive: PropTypes.bool,
        examComponentActive: PropTypes.bool,
        memberCreatorActive: PropTypes.bool,
    };

    componentDidMount() {
        this.registerMacros();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.deregisterMacros();
            this.registerMacros(this.props);
        }
    }

    componentWillUnmount() {
        this.deregisterMacros();
    }

    refresh = (refreshData) => {
        if (this._refreshFunction) {
            this._refreshFunction(refreshData);
        }
    };

    setRefreshFunction = (refreshFunction) => {
        this._refreshFunction = refreshFunction;
    };

    getKey = (props = this.props) => `${props.type}${props.id}`;

    getMacros = (props = this.props) => ({
        events: props.refreshEvents,
        actions: [
            {
                key: Macros.Action.REFRESH,
                action: (refreshData) => this.refresh(refreshData),
            },
        ],
    });

    registerMacros = (props = this.props) => {
        this.context.registerMacro(this.getKey(props), this.getMacros(props));
    };

    deregisterMacros = (props = this.props) => {
        this.context.deregisterMacro(this.getKey(props));
    };

    getMenuItems = () => {
        const menuItems = [];
        const self = this;
        const isMassChangeActive = this.props.activeLayer !== 0;

        if (this.props.onSplit) {
            menuItems.push({
                key: "calendar.splitX",
                label: Language.get("cal_header_topleft_spilt_vertical"),
                action() {
                    self.props.onSplit(true);
                },
            });

            menuItems.push({
                key: "calendar.splitY",
                label: Language.get("cal_header_topleft_split_horizontal"),
                action() {
                    self.props.onSplit(false);
                },
            });
            menuItems.push({
                isSeparator: true,
            });
        }

        menuItems.push({
            key: "calendar.toCalendar",
            label: Language.get("nc_cal_header_topleft_to_calendar"),
            action() {
                self.props.toCalendar();
            },
        });

        if (this.props.type !== "reservationList") {
            menuItems.push({
                key: "calendar.toList",
                label: Language.get("nc_cal_header_topleft_to_reservation_list"),
                action() {
                    self.props.toList();
                },
            });
        }

        if (this.props.type !== "cancellationList") {
            menuItems.push({
                key: "calendar.toCancellationList",
                label: Language.get("nc_cancellation_list_title"),
                isDisabled: isMassChangeActive,
                action() {
                    self.props.toCancellationList();
                },
            });
        }

        if (this.props.type !== "orderList") {
            menuItems.push({
                key: "calendar.toOrderList",
                label: Language.get("nc_cal_header_topleft_to_order_list"),
                isDisabled: isMassChangeActive,
                action() {
                    self.props.toOrderList();
                },
            });
        }

        if (this.props.type !== "conflictList") {
            menuItems.push({
                key: "calendar.toConflictList",
                label: Language.get("nc_conflict_list"),
                isDisabled: isMassChangeActive,
                action() {
                    self.props.toConflictList();
                },
            });
        }

        if (this.props.type !== "waitingList") {
            menuItems.push({
                key: "calendar.toWaitingList",
                label: Language.get("dynamic_reserv_list_reserv_wl_wl_title"),
                action: () => {
                    self.props.toWaitingList();
                },
            });
        }

        if (this.props.type !== "requestList") {
            menuItems.push({
                key: "calendar.toRequestList",
                label: Language.get("nc_cal_header_topleft_to_request_list"),
                action: () => {
                    self.props.toRequestList();
                },
            });
        }

        if (this.context.prefsComponentActive && this.props.type !== "prefsComponent") {
            menuItems.push({
                key: "calendar.tePrefsComponent",
                label: Language.get("nc_activity_manager"),
                action() {
                    self.props.toPrefsComponent();
                },
            });
        }

        if (this.context.examComponentActive && this.props.type !== "examComponent") {
            menuItems.push({
                key: "calendar.teExamComponent",
                label: Language.get("nc_exam"),
                action() {
                    self.props.toExamComponent();
                },
            });
        }

        if (this.context.memberCreatorActive && this.props.type !== "memberComponent") {
            menuItems.push({
                key: "calendar.toMemberComponent",
                label: Language.get("nc_object_manager"),
                action() {
                    self.props.toMemberCreator();
                },
            });
        }

        if (this.props.onClose) {
            menuItems.push({
                isSeparator: true,
            });
            menuItems.push({
                key: "calendar.close",
                label: Language.get("nc_list_close"),
                action: self.props.onClose,
            });
        }

        return menuItems;
    };

    getSelection = (props = this.props) => {
        if (props.data.privateSelected) {
            return props.data.selection;
        }
        return props.selection;
    };

    onActiveCalendarChange = (event) => {
        if (
            !this.props.isActive &&
            !_.isModKey(event) &&
            !event.shiftKey &&
            !(event.button === SECOND_BUTTON)
        ) {
            this.props.onActiveCalendarChange(this.props.id);
        }
    };

    render() {
        const width = 20;
        const height = 20;
        const calendarMenu = (
            <CalendarMenu
                isPrivate={this.props.data ? this.props.data.privateSelected : false}
                size={{ width, height }}
                menuItems={this.getMenuItems()}
                isActive={this.props.isActive}
                calendarId={this.props.id}
                showId={this.props.showId}
            />
        );

        const childProps = _.extend(
            {
                topLeft: calendarMenu,
                refresh: this.refresh,
                setRefreshFunction: this.setRefreshFunction,
                privateSelected: this.props.data ? this.props.data.privateSelected : false,
            },
            this.props
        );

        return (
            <div
                className={this.props.className}
                ref={this.props.listRef}
                style={this.props.style}
                onMouseDown={this.onActiveCalendarChange}
            >
                {React.cloneElement(this.props.children, childProps)}
            </div>
        );
    }
}

module.exports = ListCalendar;
