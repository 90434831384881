const React = require("react");
const Language = require("../lib/Language");
const _ = require("underscore");
const PropTypes = require("prop-types");

class ListSettingsStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedSearches: [],
            selectedSearch: -1,
            isSaveEnabled: true,
        };
    }

    componentDidMount() {
        this.loadSavedSearches(() => {
            this.updateSelectedSearch();
        });
    }

    loadSavedSearches = (callback = _.noop) => {
        this.props.getSavedSettings((savedSearches = []) => {
            this.setState({ savedSearches }, callback);
        });
    };

    isStateMatchingSearch = (search, treatUndefinedAsEqual = true) => {
        const keys = _.keys(this.props.searchSettings);
        return _.every(keys, (key) => {
            if (
                search.settings[key] === undefined &&
                this.props.searchSettings[key] !== undefined
            ) {
                return treatUndefinedAsEqual;
            }
            return _.isEqual(search.settings[key], this.props.searchSettings[key]);
        });
    };

    updateSelectedSearch = () => {
        const selectedSearch = this.state.savedSearches.findIndex(this.isStateMatchingSearch);
        this.setState({ selectedSearch });
    };

    onSavedSearchChange = (event) => {
        const index = parseInt(event.target.value, 10);
        if (index === -1) {
            return;
        }

        const search = this.state.savedSearches[index];
        this.props.onChange(search.settings);
        this.setState({ selectedSearch: index });
    };

    isOverwriteSaveEnabled = () => {
        const search = this.state.savedSearches[this.state.selectedSearch];
        if (_.isNullish(search)) {
            return false;
        }

        if (
            this.isStateMatchingSearch(this.state.savedSearches[this.state.selectedSearch], false)
        ) {
            return false;
        }

        return true;
    };

    onSearchSave = (createNew) => {
        this.setState({ isSaveEnabled: false });
        let name = undefined;
        if (!createNew && this.state.selectedSearch !== -1) {
            const search = this.state.savedSearches[this.state.selectedSearch];
            name = search.name;
        }

        this.props.saveSettings(name, (savedOk) => {
            this.setState({ isSaveEnabled: true });
            if (savedOk === true) {
                this.loadSavedSearches(this.updateSelectedSearch);
            }
        });
    };

    onSearchRemove = () => {
        const search = this.state.savedSearches[this.state.selectedSearch];
        if (!search) {
            return;
        }

        this.props.removeSettings(search.name, () => {
            this.loadSavedSearches(this.updateSelectedSearch);
        });
    };

    isSaveEnabled = () => this.state.isSaveEnabled;

    render() {
        let saveDefaultButton = null;
        if (this.props.saveDefaultSettings) {
            saveDefaultButton = (
                <button
                    className="save"
                    onClick={this.props.saveDefaultSettings}
                    disabled={!this.isSaveEnabled()}
                >
                    {Language.get("nc_save_as_default_filter")}
                </button>
            );
        }

        const currentSearch = this.state.savedSearches[this.state.selectedSearch];
        const removeIconTitle = currentSearch
            ? Language.get(
                  "dynamic_object_list_delete_category_search_name",
                  this.state.savedSearches[this.state.selectedSearch].name
              )
            : Language.get("dynamic_object_list_delete_category_search");

        return (
            <div className="saveSearchArea">
                <div className="searches">
                    <label>{Language.get("nc_my_filters")}</label>
                    <div className="selectRow">
                        <select
                            onChange={this.onSavedSearchChange}
                            value={this.state.selectedSearch}
                        >
                            <option disabled value={-1} key="-">
                                {Language.get("nc_latest_filter")}
                            </option>
                            <option disabled>──────────</option>
                            {this.state.savedSearches.map((search, index) => (
                                <option value={index} key={index}>
                                    {search.name}
                                </option>
                            ))}
                        </select>
                        <button
                            className="save"
                            title={removeIconTitle}
                            disabled={!currentSearch}
                            onClick={this.onSearchRemove}
                        >
                            {Language.get("nc_delete_filter")}
                        </button>
                    </div>
                </div>

                <div className="saveButtons">
                    <button
                        className="save"
                        onClick={() => this.onSearchSave(false)}
                        disabled={!this.isSaveEnabled() && !this.isOverwriteSaveEnabled()}
                    >
                        {Language.get("nc_save")}
                    </button>
                    <button
                        className="save"
                        onClick={() => this.onSearchSave(true)}
                        disabled={!this.isSaveEnabled()}
                    >
                        {Language.get("nc_save_as")}
                    </button>
                </div>

                {saveDefaultButton}
            </div>
        );
    }
}

ListSettingsStore.propTypes = {
    searchSettings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    getSavedSettings: PropTypes.func.isRequired,
    saveSettings: PropTypes.func.isRequired,
    removeSettings: PropTypes.func.isRequired,
    saveDefaultSettings: PropTypes.func,
};

module.exports = ListSettingsStore;
