const PropTypes = require("prop-types");
const React = require("react");
const Language = require("../lib/Language");
const CalendarIcon = require("./CalendarIcon");
const Calendar = require("../models/Calendar");
const EmptyHeader = require("../models/EmptyHeader");
const ReservationSearch = require("../models/ReservationSearch");
const OrderSearch = require("../models/OrderSearch");
const WaitingListSearch = require("../models/WaitingListSearch");
const RequestSearch = require("../models/RequestSearch");
const CancellationSearch = require("../models/CancellationSearch");
const ConflictSearch = require("../models/ConflictSearch");
const _ = require("underscore");

// Header Titles
const DateHeader = require("../models/DateHeader");
const TimeHeader = require("../models/TimeHeader");
const ObjectHeader = require("../models/ObjectHeader");
const WeekHeader = require("../models/WeekHeader");
const WeekdayHeader = require("../models/WeekdayHeader");
// Input types
const IntegerInput = require("./IntegerInput");
const BooleanInput = require("./BooleanInput");
const DateInput = require("./DateInput");
const TimeInput = require("./TimeInput");
const TimeRangeInput = require("./TimeRangeInput");
const ArrayInput = require("./ArrayInput");
const DateOffsetInput = require("./DateOffsetInput");
const GroupInput = require("./GroupInput");
const TypeInput = require("./TypeInput");
const ColorTypeInput = require("./ColorTypeInput");
const PeriodTypeInput = require("./PeriodTypeInput");

class Settings extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
        customWeekNames: PropTypes.array,
    };

    onHeaderUpdate = (header, settingsObject, data) => {
        const newHeader = settingsObject.set(data);
        this.context.update(header, newHeader);
    };

    onCalendarUpdate = (settingsObject, data) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("Calendar setting changed", {
            Setting: settingsObject.id,
            Value: data,
        });*/
        this.context.update(this.props.calendar, settingsObject.set(data));
    };

    getHeaderTitle = (header) => {
        if (header instanceof DateHeader) {
            return Language.get("cal_header_kind_date");
        }
        if (header instanceof TimeHeader) {
            return Language.get("cal_header_kind_time");
        }
        if (header instanceof ObjectHeader) {
            return Language.get("cal_header_kind_object");
        }
        if (header instanceof WeekHeader) {
            return Language.get("cal_header_kind_week");
        }
        if (header instanceof WeekdayHeader) {
            return Language.get("cal_header_kind_weekday");
        }

        return Language.get("cal_header_kind_period");
    };

    getSettingsComponent = (settings, onUpdate) => {
        if (settings.visible === false) {
            return null;
        }

        let input;
        let InputType;
        const disabled = _.isFunction(settings.isDisabled) ? settings.isDisabled() : false;
        if (settings.type === "integer") {
            InputType = IntegerInput;
            input = <InputType defaultValue={settings.get()} onUpdate={onUpdate} />;
        } else if (settings.type === "boolean") {
            InputType = BooleanInput;
            input = (
                <InputType defaultValue={settings.get()} onUpdate={onUpdate} disabled={disabled} />
            );
        } else if (settings.type === "date") {
            InputType = DateInput;
            input = (
                <InputType defaultValue={settings.get()} onUpdate={onUpdate} disabled={disabled} />
            );
        } else if (settings.type === "time") {
            InputType = TimeInput;
            input = <InputType defaultValue={settings.get()} onUpdate={onUpdate} />;
        } else if (settings.type === "timerange") {
            InputType = TimeRangeInput;
            input = <InputType defaultValue={settings.get()} onUpdate={onUpdate} />;
        } else if (settings.type === "array") {
            InputType = ArrayInput;
            input = (
                <InputType
                    defaultValue={settings.get()}
                    onUpdate={onUpdate}
                    limit={settings.limit}
                    defaultSize={7}
                />
            );
        } else if (settings.type === "dateoffset") {
            InputType = DateOffsetInput;
            input = <InputType defaultValue={settings.get()} onUpdate={onUpdate} />;
        } else if (settings.type === "groups") {
            InputType = GroupInput;
            input = (
                <InputType
                    defaultValue={settings.get()}
                    onUpdate={onUpdate}
                    availableValues={settings.availableValues()}
                />
            );
        } else if (settings.type === "type") {
            InputType = TypeInput;
            input = <InputType defaultValue={settings.get()} onUpdate={onUpdate} />;
        } else if (settings.type === "colortype") {
            InputType = ColorTypeInput;
            input = (
                <InputType size={1} limit={1} defaultValue={settings.get()} onUpdate={onUpdate} />
            );
        } else if (settings.type === "periodtype") {
            InputType = PeriodTypeInput;
            input = (
                <InputType size={1} limit={1} defaultValue={settings.get()} onUpdate={onUpdate} />
            );
        }

        return (
            <li key={settings.id}>
                <label>
                    <span title={settings.details || settings.label} className={"settingsLabel"}>
                        {settings.label}
                    </span>
                    {input}
                </label>
            </li>
        );
    };

    getCalendarSettings = () => {
        const booleanItems = [];
        const otherItems = [];
        this.props.calendar.getSettings(this.props.publicSelection).items.forEach((item) => {
            if (item.type === "boolean") {
                booleanItems.push(item);
            } else {
                otherItems.push(item);
            }
        });

        const mapFunction = function (setting) {
            const update = this.onCalendarUpdate.bind(this, setting);
            return this.getSettingsComponent(setting, update);
        };

        return (
            <form>
                <ul className="checkboxGroup">{booleanItems.map(mapFunction, this)}</ul>
                <ul>{otherItems.map(mapFunction, this)}</ul>
            </form>
        );
    };

    getHeaderSettings = (topHeader) => {
        const providers = this.props.calendar.getProviderMap();

        if (topHeader instanceof EmptyHeader) {
            return [];
        }

        return topHeader.getHeaders().map(function (header, index) {
            const booleanItems = [];
            const otherItems = [];
            header.getSettings(providers, this.context.customWeekNames).items.forEach((item) => {
                if (item.type === "boolean") {
                    booleanItems.push(item);
                } else {
                    otherItems.push(item);
                }
            });

            const componentMap = function (setting) {
                const update = this.onHeaderUpdate.bind(this, header, setting);
                return this.getSettingsComponent(setting, update);
            };

            const title = this.getHeaderTitle(header);
            return [
                <h4 key={`header${index}`}>
                    {Language.get("nc_calendar_settings_header")} {index + 1}{" "}
                    {title ? `- ${title}` : ""}
                </h4>,
                <form key={`form${index}`}>
                    <ul className="checkboxGroup">{booleanItems.map(componentMap, this)}</ul>
                    <ul>{otherItems.map(componentMap, this)}</ul>
                </form>,
            ];
        }, this);
    };

    render() {
        if (!this.props.calendar) {
            return <div />;
        }
        const id = this.props.getCalendarIndex(this.props.calendar) + 1;
        const icon = <CalendarIcon calendar={this.props.calendar} size={16} isActive={true} />;
        const lists = [
            {
                type: ReservationSearch,
                title: Language.get("nc_cal_header_topleft_to_reservation_list"),
            },
            { type: OrderSearch, title: Language.get("nc_cal_header_topleft_to_order_list") },
            {
                type: WaitingListSearch,
                title: Language.get("dynamic_reserv_list_reserv_wl_wl_title"),
            },
            { type: CancellationSearch, title: Language.get("nc_cancellation_list_title") },
            { type: RequestSearch, title: Language.get("nc_request_list_title") },
            { type: ConflictSearch, title: Language.get("nc_conflict_list") },
        ];

        const listItem = _.find(lists, (list) => this.props.calendar instanceof list.type);
        if (listItem) {
            return (
                <div className="settingsPane">
                    <h2>{Language.get("nc_menu_calendar_settings")}</h2>
                    <h3>
                        {icon} {listItem.title} {id}
                    </h3>
                    {this.getCalendarSettings()}
                </div>
            );
        }

        if (!(this.props.calendar instanceof Calendar)) {
            return (
                <div className="settingsPane">
                    <h2>{Language.get("nc_menu_calendar_settings")}</h2>
                    <p>{Language.get("nc_menu_calendar_settings_no_calendar")}</p>
                </div>
            );
        }

        const xHeaderSettings = this.getHeaderSettings(this.props.calendar.xHeader);
        const yHeaderSettings = this.getHeaderSettings(this.props.calendar.yHeader);
        if (xHeaderSettings.length > 0) {
            xHeaderSettings.unshift(
                <h3 key={"xHeadline"}>{Language.get("nc_calendar_settings_x_axis")}</h3>
            );
        }
        if (yHeaderSettings.length > 0) {
            yHeaderSettings.unshift(
                <h3 key={"yHeadline"}>{Language.get("nc_calendar_settings_y_axis")}</h3>
            );
        }

        return (
            <div className="settingsPane" key={this.props.calendar._modelId}>
                <h2>{Language.get("nc_menu_calendar_settings")}</h2>
                <h3>
                    {icon} {Language.get("menu_calendar")} {id}
                </h3>
                {this.getCalendarSettings()}
                {xHeaderSettings}
                {yHeaderSettings}
            </div>
        );
    }

    shouldComponentUpdate(nextProps) {
        return this.props.calendar !== nextProps.calendar;
    }
}

module.exports = Settings;
