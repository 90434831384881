import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import ContextMenu from "../lib/ContextMenu";
import Animation from "../lib/Animation";
import Language from "../lib/Language";
import Popover from "./Popover";
import { Reservation } from "../models/Reservation";
import ReservationInfoPopup from "./ReservationInfoPopup";
import ReservationStatus from "../lib/ReservationStatus";
import LayerComponent from "../lib/LayerComponent";
import API from "../lib/TimeEditAPI";
import _ from "underscore";
import Mousetrap from "@timeedit/mousetrap";
import { TimeEdit } from "../lib/TimeEdit";
import { MillenniumWeek } from "@timeedit/millennium-time";
import { ENTRY_CLASS, EntryKind } from "../lib/EntryConstants";
import { TimeConstants } from "../lib/TimeConstants";
import { Macros } from "../models/Macros";
import LayoutConstants from "../lib/LayoutConstants";
import { DragListener } from "../lib/DragListener";
import ReservationTooltip from "./ReservationTooltip";
import PaddingTooltip from "./PaddingTooltip";
import EntryLength from "./EntryLength";
import Log from "../lib/Log";
import Viewer from "../lib/Viewer";
import ExamConstants from "../lib/ExamConstants";
import ReservationConflictInfoTooltip, {
    CONFLICT_INFO_TOOLTIP_CLASSNAME,
    CONFLICT_TOOLTIP_LEFT_PADDING,
} from "./ReservationConflictInfoTooltip";

const { EXAM_MODE } = ExamConstants;

const MIN_WIDTH_FOR_INFO_ICON = 20;
const MIN_HEIGHT_FOR_INFO_ICON = 14;
const MIN_HEIGHT_FOR_TEXT_AND_INFO = 30;

const CLICK_TIMEOUT = 200;
const LEFT_PADDING = 260;

// TODO: Placed outside class to prevent spam warnings in browser console, due to the location of the language string. When this is not temp anymore, move it to correct place below.
const remaningCapacityTitle = Language.tmp("Remaining capacity"); // nc_remaining_capacity
const totalCapacityTitle = Language.tmp("Total capacity"); // nc_total_capacity
const capacityLabelTitle = Language.tmp("Capacity"); // nc_capacity
const sizeLabelTitle = Language.tmp("Size"); // nc_size
const overlapCountTitle = Language.tmp("Overlap count"); // nc_overlap_count
const overlapGroupCountTitle = Language.tmp("Overlap group count"); // nc_overlap_group_count

let Entry = createReactClass({
    displayName: "Entry",

    getInitialState() {
        return {
            waitingForDeletion: false,
            availableStatuses: null,
            currentStatus: null,
            canCancel: false,
            canEmail: false,
            canHaveExceptions: null,
            hasExamId: null,
            hasExamValue: false,
            examValueLoaded: false,
            sizeEntries: [],
        };
    },

    contextTypes: {
        update: PropTypes.func,
        fireEvent: PropTypes.func,
        user: PropTypes.object,
        getAppSize: PropTypes.func,
        presentModal: PropTypes.func,
        hasReservationExceptions: PropTypes.bool,
        customWeekNames: PropTypes.array,
        examComponentActive: PropTypes.bool,
        createExamRequest: PropTypes.func,
        examIdField: PropTypes.number,
        env: PropTypes.object,
        useNewReservationGroups: PropTypes.bool,
        isLockingEnabled: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
    },

    componentDidMount() {
        this._isMounted = true;
        this.props.setLayerContentProvider(this.getLayerContent);
        this._contextMenuRef = ContextMenu.addListener(
            ReactDOM.findDOMNode(this),
            this.getMenuItems,
            ContextMenu.RIGHT_CLICK
        );
        if (_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], this.props.data.kind)) {
            if (this.props.isLayerActive) {
                this.bindKeyboardShortcuts();
            }
        }
        this.registerMacros();

        // Get reference to closest parent calendar container once.
        this._parentCalendarElemRef = ReactDOM.findDOMNode(this).closest(
            ".active.calendar, .entryOverlay"
        );
    },

    componentDidUpdate(prevProps) {
        if (
            _.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], this.props.data.kind) &&
            !_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], prevProps.data.kind)
        ) {
            if (this.props.isLayerActive) {
                this.bindKeyboardShortcuts();
            }
        }

        if (this.props.isTooltipVisible === true && !this.state.reservationInfo) {
            this.loadTooltip(true);
        } else if (this.props.isTooltipVisible === false && prevProps.isTooltipVisible === true) {
            this.unrenderTooltip();
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ reservationInfo: null });
        }
    },

    registerMacros() {
        if (this.context.examIdField && this.props.data.reservationids.length === 1) {
            this.context.registerMacro(`entry${this.props.data.reservationids[0]}`, {
                events: [Macros.Event.RESERVATION_MADE_OR_MODIFIED],
                actions: [
                    {
                        key: Macros.Action.REFRESH,
                        action: (reservationIds) => {
                            if (reservationIds.indexOf(this.props.data.reservationids[0]) !== -1) {
                                this.setState({ examValueLoaded: false });
                            }
                        },
                    },
                ],
            });
        }
    },

    unrenderTooltip() {
        this.props.unrenderTooltip(
            this.props.data.isPadding
                ? `${this.props.data.reservationids.join(",")}:padding`
                : this.props.data.reservationids.join(",")
        );
    },

    bindKeyboardShortcuts() {
        if (this.props.data.isPadding) {
            return;
        }
        Mousetrap.unbindWithHelp("mod+i");
        Mousetrap.unbindWithHelp("mod+e");
        Mousetrap.unbindWithHelp("mod+c");
        Mousetrap.unbindWithHelp("mod+alt+j");
        Mousetrap.bindWithHelp(
            "mod+i",
            this.handleInfoShortcut.bind(this, false),
            undefined,
            Language.get("nc_cal_show_or_hide_reservation_info.")
        );

        Mousetrap.bindWithHelp(
            "mod+c",
            this.onCopy,
            undefined,
            Language.get("nc_copy_selected_reservation.")
        );

        const readOnly =
            this.props.readOnlyCalendar ||
            (this.props.data.lock && this.props.data.lock === "soft");
        const hasGroup = this.props.data.groups.length > 0;

        const editable = !(
            readOnly ||
            (!this.props.enableEditMode && !this.props.data.modifiable) ||
            (hasGroup && !this.props.isGroupMode) ||
            this.props.selectionGroup.length !== 0
        );
        if (editable) {
            Mousetrap.bindWithHelp(
                "mod+e",
                this.handleInfoShortcut.bind(this, true),
                undefined,
                Language.get("nc_cal_show_or_hide_reservation_info.")
            );
            Mousetrap.bindWithHelp(
                "mod+alt+j",
                () => {
                    this.props.enableEditMode(this.props.data);
                },
                undefined,
                Language.get("cal_func_res_change_object")
            );
        }
    },

    onCopy(event) {
        if (!window.getSelection()?.toString()) {
            this.props.onCopy(this.props.data, event);
        }
        return true;
    },

    componentWillUnmount() {
        this._isMounted = false;
        if (_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], this.props.data.kind)) {
            Mousetrap.unbindWithHelp("mod+i");
            Mousetrap.unbindWithHelp("mod+e");
            Mousetrap.unbindWithHelp("mod+c");
            Mousetrap.unbindWithHelp("mod+alt+j");
        }
        if (
            this.props.data &&
            this.props.data.reservationids &&
            this.props.data.reservationids.length === 1
        ) {
            this.context.deregisterMacro(`entry${this.props.data.reservationids[0]}`);
        }
    },

    setStatus(status) {
        if (this.state.currentStatus === status) {
            return;
        }

        let toChange = this.props.data.reservationids; // Always work with the current entry, even if it is part of a group
        if (this.useGroup()) {
            // But if multiple reservations have been clicked, work with the whole group
            toChange = this.getGroupIds();
        }

        const setStatus = () =>
            API.setReservationStatus(toChange, status.id, (result) => {
                const numSuccessful = result[0].filter((bool) => bool === true).length;
                if (numSuccessful === 0) {
                    Log.warning(Language.get("nc_edit_reservation_status_change_error"));
                    return;
                }

                if (numSuccessful < toChange.length) {
                    Log.warning(
                        Language.get(
                            "nc_edit_reservation_status_change_x_of_y_complete",
                            numSuccessful,
                            toChange.length
                        )
                    );
                } else {
                    Log.info(Language.get("nc_edit_reservation_status_change_complete"));
                }
                this.onUpdate();
                this.updateStatusData();
            });

        if (
            this.props.data.reservationids.length === 1 ||
            this.state.currentStatus !== ReservationStatus.UNKNOWN
        ) {
            setStatus();
            return;
        }

        const buttons = [
            { title: Language.get("dialog_yes"), cb: setStatus },
            { title: Language.get("dialog_no") },
        ];
        this.context.presentModal(
            <p>{Language.get("nc_confirm_entry_set_cluster_status")}</p>,
            null,
            null,
            buttons
        );
    },

    showContextMenu(event) {
        this._contextMenuRef = ContextMenu.displayMenu(this.getMenuItems(), event, _.noop);
    },

    updateStatusData(callback = _.noop) {
        API.getReservationStatus(this.props.data.reservationids, (result) => {
            API.okToCancelReservations(
                { reservationIds: this.props.data.reservationids },
                (cancelResult) => {
                    this.setState({
                        currentStatus: ReservationStatus.statusForId(result[0].status),
                        availableStatuses: result[1].map((item) =>
                            ReservationStatus.statusForId(item.status)
                        ),
                        canCancel: _.every(cancelResult.parameters[0], (status) => !status.details),
                        canEmail: _.every(
                            cancelResult.parameters[1],
                            (canEmail) => canEmail === true
                        ),
                    });
                    callback();
                }
            );
        });
    },

    checkCanHaveExceptions(callback = _.noop) {
        API.canHaveMemberExceptionObjectsForReservations(
            this.props.data.reservationids,
            (result) => {
                this.setState({ canHaveExceptions: result }, callback);
            }
        );
    },

    isCapacityOptionAvailable() {
        // Check centralized flag
        // Then also, I guess, check options on the reservation
        // saying if it can have capacity or not
        return process.env.NODE_ENV === "development" || this.props.flags?.examFlowV3;
    },

    getMenuItems() {
        // Looks like we cant trush that "this" is pointing on this class even if we use arrow functions. So just be on the safe declare self and us it exclusively in this method.
        const self = this;

        const notActiveLayer =
            self.props.activeLayer !== 0 && self.props.data.layer !== self.props.activeLayer;
        if (
            !self.props.data.readable ||
            notActiveLayer ||
            self.props.disableMenu ||
            self.isWrongKind() ||
            self.props.data.isPadding
        ) {
            return [];
        }
        const readOnly =
            self.props.readOnlyCalendar ||
            (self.props.data.lock && self.props.data.lock === "soft");
        const useGroup = self.useGroup() && self.props.selectionGroup.length > 1;

        if (!self.state.availableStatuses) {
            self.updateStatusData(() => ContextMenu.update(self._contextMenuRef));
        }
        if (self.state.canHaveExceptions === null) {
            self.checkCanHaveExceptions(() => ContextMenu.update(self._contextMenuRef));
        }

        if (
            self.context.examIdField &&
            self.props.data.reservationids.length === 1 &&
            self.state.examValueLoaded === false
        ) {
            API.exportReservations(self.props.data.reservationids, false, (result) => {
                let examId: any = null;
                if (result.length > 0) {
                    examId = _.find(
                        result[0].fields,
                        (field) => field.id === self.context.examIdField
                    );
                }
                self.setState(
                    {
                        examValueLoaded: true,
                        hasExamId: examId !== null && examId !== undefined ? true : false,
                        hasExamValue:
                            examId &&
                            examId.values &&
                            examId.values.length > 0 &&
                            examId.values[0] !== "",
                    },
                    () => ContextMenu.update(self._contextMenuRef)
                );
            });
        }

        const hasGroup = self.props.data.groups.length > 0;
        const isDeletable =
            !(hasGroup && !self.props.data.grouped) &&
            !(self.props.selectionGroup.length > 1 && self.props.selectionContainsGroups) &&
            !readOnly &&
            self.state.canCancel;

        const cancelSubmenu = [
            {
                key: "entry.cancel",
                label: hasGroup
                    ? Language.get("nc_cancel_reservation_group")
                    : Language.get("cal_func_res_delete"),
                isDisabled: !isDeletable,
                action: () => self.onDelete(),
            },
            {
                key: "entry.cancelAndEmail",
                label: hasGroup
                    ? Language.get("nc_cancel_reservation_group_email")
                    : Language.get("cal_func_res_delete_email"),
                isDisabled:
                    !TimeEdit.isEmailActive ||
                    !isDeletable ||
                    self.props.activeLayer !== 0 ||
                    self.state.canEmail === false,
                action: () => self.onDelete(true),
            },
        ];

        const exportSubmenu = [
            {
                key: "entry.exportAdd",
                label: Language.get("nc_add_to_my_list"),
                action: () =>
                    self.props.openStaticReservationListAdd(self.props.data.reservationids, true),
            },
            {
                key: "entry.exportRemove",
                label: Language.get("nc_remove_from_my_list"),
                action: () =>
                    self.props.openStaticReservationListAdd(self.props.data.reservationids, false),
            },
        ];

        if (self.props.data.groups.length > 0 && self.context.useNewReservationGroups) {
            exportSubmenu.push({
                key: "entry.groupAdd",
                label: Language.get("nc_add_group_to_my_list"),
                action: () => {
                    API.getGroupedEntries(
                        self.props.clusterKind,
                        self.props.data.reservationids,
                        {},
                        (result) => {
                            self.props.openStaticReservationListAdd(
                                _.flatten(result.map((entry) => entry.reservationids)),
                                true
                            );
                        }
                    );
                },
            });
            exportSubmenu.push({
                key: "entry.groupRemove",
                label: Language.get("nc_remove_group_from_my_list"),
                action: () => {
                    API.getGroupedEntries(
                        self.props.clusterKind,
                        self.props.data.reservationids,
                        {},
                        (result) => {
                            self.props.openStaticReservationListAdd(
                                _.flatten(result.map((entry) => entry.reservationids)),
                                false
                            );
                        }
                    );
                },
            });
        }

        const selectOption = {
            key: "entry.select",
            label: Language.tmp("Select"),
            isDisabled: self.isWrongKind(),
            action: (event) => {
                // Override if entry is not clickable, this is the purpose of this select option action.
                self._isClickable = true;
                self.onClick(event);
                self._isClickable = false;
            },
        };

        const infoOption = {
            key: "entry.showInfo",
            label: Language.get("menu_view_info"),
            isDisabled: self.isWrongKind(),
            action: (event) => {
                self.showInfo(event, true);
            },
            shortcut: TimeEdit.presentShortcut("mod+i"),
        };

        const groupOption = {
            key: "entry.group",
            label: Language.get("nc_create_reservation_group"),
            action: () => {
                if (useGroup && self.props.isLegalGroup) {
                    self.props.createGroup();
                } else if (self.props.selectionGroup.length === 0) {
                    self.props.createGroupFromEntry(self.props.data);
                }
            },
            isDisabled:
                readOnly ||
                hasGroup ||
                (self.props.selectionGroup.length > 0 && !self.props.isLegalGroup),
        };

        const ungroupOption = {
            key: "entry.ungroup",
            label: Language.get("nc_ungroup"),
            action: () => {
                self.props.deleteGroup(self.props.data.groups);
            },
        };

        const removeFromGroupOption = {
            key: "entry.removeFromGroup",
            label: Language.get("nc_remove_from_reservation_group"),
            action: () => {
                if (self.context.useNewReservationGroups) {
                    API.removeReservationsFromReservationGroup(
                        self.props.data.groups[0],
                        self.props.data.reservationids,
                        (result) => {
                            if (result === false) {
                                Log.error(
                                    Language.get("nc_could_not_remove_from_reservation_group")
                                );
                            } else {
                                self.onUpdate();
                            }
                        }
                    );
                } else {
                    API.removeReservationsFromGroups(self.props.data.reservationids, (result) => {
                        if (result === false) {
                            Log.error(Language.get("nc_could_not_remove_from_reservation_group"));
                        } else {
                            self.onUpdate();
                        }
                    });
                }
            },
        };

        const changeTimeOption = hasGroup
            ? {
                  key: "entry.changeTime",
                  label: Language.get("nc_menu_change_time"),
                  isDisabled: self.props.data.lock && self.props.data.lock === "soft",
                  action: () => {
                      self.context.fireEvent(
                          `entry`,
                          Macros.Event.CHANGE_RESERVATION_TIME,
                          self.props.data.reservationids,
                          true,
                          true
                      );
                  },
              }
            : null;

        const exceptionOption = self.context.hasReservationExceptions
            ? {
                  key: "entry.editExceptions",
                  label: Language.get("nc_reservation_exception_title"),
                  isDisabled: self.state.canHaveExceptions !== true,
                  action: () => {
                      if (self.props.onEditExceptions) {
                          self.props.onEditExceptions(self.props.data);
                      }
                  },
              }
            : null;

        const exportToList = Viewer.isEnabled(self.context.env)
            ? {
                  key: "entry.exportToList",
                  label: Language.get("nc_my_list"),
                  submenu: exportSubmenu,
              }
            : null;

        const editGroupOption = {
            key: "entry.editGroup",
            label: Language.get("nc_edit_reservation_group"),
            isDisabled: !self.props.data.grouped && !readOnly,
            action: () => {
                self.props.editGroup(
                    self.props.data,
                    self.props.data.groups,
                    self.props.data.startTimes[0].getMillenniumDate()
                );
            },
        };

        const changeFieldsOption = {
            key: "entry.editFields",
            label: Language.get("nc_cal_func_res_edit_fields"),
            isDisabled:
                readOnly ||
                (!self.props.enableEditMode && !self.props.data.modifiable) ||
                self.props.selectionGroup.length !== 0,
            action: () => {
                self.handleInfoShortcut(true);
            },
            shortcut: TimeEdit.presentShortcut("mod+e"),
        };

        const changeObjectsOption = {
            key: "entry.changeObject",
            label: Language.get("cal_func_res_change_object"),
            isDisabled:
                readOnly ||
                (!self.props.enableEditMode && !self.props.data.modifiable) ||
                self.props.selectionGroup.length !== 0,
            action: () => {
                self.props.enableEditMode(self.props.data);
            },
            shortcut: TimeEdit.presentShortcut("mod+alt+j"),
        };

        const divider = () => ({
            isSeparator: true,
        });

        const showUngroup = self.props.data.groups
            ? self.props.data.groups.length > 0 &&
              _.some(self.props.data.groups, (group) => group !== 0)
            : false;
        if (self.props.data.grouped && self.props.isGroupMode) {
            // Group mode, entry part of the group
            return [infoOption, divider(), changeObjectsOption, divider(), removeFromGroupOption];
        }
        if (!self.props.data.grouped && self.props.isGroupMode) {
            // Group mode, entry not part of the group
            return [
                infoOption,
                selectOption,
                divider(),
                changeObjectsOption,
                divider(),
                {
                    key: "entry.addToGroup",
                    label: Language.get("nc_add_to_reservation_group"),
                    action: () => {
                        self.props.addToGroup(self.props.data);
                        //self.onUpdate(); Not needed, addToGroup fires modification event
                    },
                    isDisabled: showUngroup && readOnly,
                },
            ];
        }

        const lockOption = this.context.isLockingEnabled()
            ? {
                  key: "entry.lock",
                  label:
                      self.props.data.lock && self.props.data.lock === "soft"
                          ? Language.get("nc_unlock_reservation")
                          : Language.get("nc_lock_reservation"),
                  action: () => {
                      API.getReservations(self.props.data.reservationids, (reservations) => {
                          if (self.props.data.lock && self.props.data.lock === "soft") {
                              if (
                                  // eslint-disable-next-line no-alert
                                  confirm(
                                      `${Language.get("nc_confirm_unlock")}\n\n${
                                          reservations[0].lockedDescription
                                      }`
                                  )
                              ) {
                                  API.unlockReservations(
                                      self.props.data.reservationids,
                                      (result) => {
                                          // eslint-disable-next-line no-console
                                          console.log(result);
                                          self.onUpdate();
                                      }
                                  );
                              }
                          } else {
                              // eslint-disable-next-line no-alert
                              let reason = prompt(
                                  Language.get("nc_reason_for_lock"),
                                  reservations[0].lockedDescription || ""
                              );
                              while (reason !== null && reason.trim() === "") {
                                  // eslint-disable-next-line no-alert
                                  reason = prompt(Language.get("nc_reason_for_lock"));
                              }
                              // User hit cancel
                              if (reason === null) {
                                  return;
                              }
                              API.lockReservations(
                                  self.props.data.reservationids,
                                  reason,
                                  (result) => {
                                      // eslint-disable-next-line no-console
                                      console.log(result);
                                      self.onUpdate();
                                  }
                              );
                          }
                      });
                  },
              }
            : null;

        let requestLabel = Language.get("nc_create_exam_request");
        if (this.props.data.reservationids.length > 1) {
            requestLabel = Language.get("nc_create_exam_requests");
        } else if (this.state.hasExamValue) {
            requestLabel = Language.get("nc_edit_exam_request");
        }

        const examRequestOption =
            self.context.examComponentActive && self.state.hasExamId
                ? {
                      key: "entry.createExamRequest",
                      label: requestLabel,
                      isDisabled: false,
                      action: () =>
                          API.getReservationsByExtid(this.props.data.reservationids, (result) => {
                              let mode = this.state.hasExamValue
                                  ? EXAM_MODE.EDIT
                                  : EXAM_MODE.CREATE;
                              if (result.length > 1) {
                                  mode = EXAM_MODE.CREATE_MULTI;
                              }
                              this.context.createExamRequest(result, mode);
                          }),
                  }
                : null;

        const CR_ORDERING = {
            PARALLELL: 1,
            SEQUENTIAL: 2,
            FREE: 3,
        };

        const capacityOption = self.isCapacityOptionAvailable()
            ? {
                  key: "entry.setCapacity",
                  label: Language.tmp("Set capacity"),
                  action: () => {
                      const capacity = parseInt(
                          prompt(Language.tmp("Enter capacity"), self.props.data.capacity || 0) ||
                              "",
                          10
                      );
                      if (capacity === null || isNaN(capacity)) {
                          return;
                      }
                      const allowUnderCapacity = true;
                      API.setReservationCapacity(
                          self.props.data.reservationids,
                          allowUnderCapacity,
                          capacity,
                          CR_ORDERING.PARALLELL,
                          (result) => {
                              console.log(result);
                              self.onUpdate();
                          }
                      );
                  },
              }
            : null;

        const menu = [
            infoOption,
            selectOption,
            {
                key: "entry.overlappingSubmenu",
                label: Language.get("nc_cal_func_res_show_overlapping_res"),
                isDisabled:
                    self.props.data.overlapCount === 0 || useGroup || self.props.isInOverlapView,
                action: (event) => {
                    self.props.showOverlappingEntries(self.props.data, event);
                },
                submenu: [
                    {
                        key: "entry.showOverlapping",
                        label: Language.get("nc_show_overlapping_in_calendar"),
                        isDisabled:
                            self.props.data.overlapCount === 0 ||
                            useGroup ||
                            self.props.isInOverlapView,
                        action: (event) => {
                            self.props.showOverlappingEntries(self.props.data, event);
                        },
                    },
                    {
                        key: "entry.showOverlappingInList",
                        label: Language.get("nc_show_overlapping_in_list"),
                        isDisabled:
                            self.props.data.overlapCount === 0 ||
                            useGroup ||
                            self.props.isInOverlapView,
                        action: (event) => {
                            self.props.showOverlappingEntriesInList(self.props.data, event);
                        },
                    },
                ],
            },
            divider(),
            changeFieldsOption,
            changeObjectsOption,
            changeTimeOption,
            {
                key: "entry.waitingList",
                label: Language.get("dynamic_reserv_list_reserv_wl_wl_title"),
                isDisabled:
                    readOnly ||
                    !self.props.data.modifiable ||
                    useGroup ||
                    (self.props.data.groups && self.props.data.groups.length > 0),
                submenu: [
                    {
                        key: "entry.toWaitingList",
                        label: Language.get("cal_func_res_move_to_wl"),
                        isDisabled: !self.props.data.modifiable || useGroup || readOnly,
                        action: () => {
                            self.onMoveToWaitingList();
                        },
                    },
                ],
            },
            exportToList,
            exceptionOption,
            {
                key: "entry.status",
                label: Language.get("cal_res_side_reservation_status"),
                isDisabled:
                    self.props.readOnlyCalendar ||
                    (self.state.availableStatuses && !self.props.data.modifiable),
                submenu: (self.state.availableStatuses || []).map((status) => ({
                    key: `entry.status.${status.id}`,
                    label: status.getStatusName(),
                    action: () => self.setStatus(status),
                    checked: () => self.state.currentStatus === status,
                })),
            },
            divider(),
            {
                key: "entry.copy",
                label: Language.get("cal_res_below_copy"),
                action: (event) => {
                    self.props.onCopy(self.props.data, event);
                },
                isDisabled: self.props.readOnlyCalendar,
                shortcut: TimeEdit.presentShortcut("mod+c"),
            },
            hasGroup ? editGroupOption : null,
            hasGroup ? ungroupOption : groupOption,
            {
                key: "entry.cancelMenu",
                label: hasGroup
                    ? Language.get("nc_cancel_reservation_group")
                    : Language.get("cal_func_res_delete"),
                isDisabled: !isDeletable,
                submenu: cancelSubmenu,
            },
            divider(),
            {
                key: "entry.sendEmail",
                label: Language.get("cal_func_res_email_reservation"),
                isDisabled: !TimeEdit.isEmailActive,
                action: () => {
                    self.props.emailReservation(self.props.data.reservationids);
                },
            },
            lockOption ? divider() : null,
            lockOption,
            examRequestOption ? divider() : null,
            examRequestOption,
            capacityOption ? divider() : null,
            capacityOption,
        ];

        return menu.filter((option) => option !== null);
    },

    getGroupIds() {
        return _.flatten(this.props.selectionGroup.map((entry) => entry.reservationids));
    },

    useGroup() {
        const ids = this.getGroupIds();
        return _.some(this.props.data.reservationids, (id) => _.contains(ids, id));
    },

    getGroupOrEntryIds() {
        let ids = this.getGroupIds();
        if (this.context.useNewReservationGroups && this.props.data.groups.length > 0) {
            ids = this.props.getVisibleReservationsInGroups(this.props.data.groups);
        }
        if (_.some(this.props.data.reservationids, (id) => _.contains(ids, id))) {
            return { ids, singleReservation: false };
        }
        return { ids: this.props.data.reservationids, singleReservation: true };
    },

    onMoveToWaitingList() {
        if (this.props.data.groups && this.props.data.groups.length > 0) {
            return;
        }
        const data = this.getGroupOrEntryIds();
        this.props.moveToWaitingList(data.ids, data.singleReservation);
    },

    onDelete(sendEmail = false) {
        const node = this._isMounted ? ReactDOM.findDOMNode(this) : null;
        const self = this;
        const ids = this.getGroupOrEntryIds().ids;
        this.props.onDelete(ids, sendEmail, () => {
            self.onInfoClose();
            if (node && this._isMounted) {
                this._isMounted = false;
                Animation.fallOut(node);
                /*for (let i = 0; i < node.childNodes.length; i++) {
                    Animation.fallOut(node.childNodes.item(i));
                }*/
                self.setState({ waitingForDeletion: true });
            }
        });
    },

    onClick(event) {
        event.stopPropagation();

        if (!this._isClickable || this.props.data.kind === EntryKind.NONE) {
            return;
        }

        if (this.state.onClickRecentlyCalled) {
            this.showInfo(event, true);
            return;
        }

        if (!this.props.data.readable) {
            return;
        }

        const addToSelection = _.isModKey(event);
        if (!addToSelection) {
            this.showInfo(event, false);
        }
        this.props.onClick(
            this.props.data,
            addToSelection,
            event.shiftKey,
            this.props.isEntryLocked
        );

        this.setState({ onClickRecentlyCalled: true });
        setTimeout(() => {
            if (this._isMounted) {
                this.setState({ onClickRecentlyCalled: false });
            }
        }, CLICK_TIMEOUT);
    },

    onReservationEditChange(isEditing) {
        this.setState({ showEditMode: isEditing }, this.props.forceLayerUpdate);
    },

    onInfoClose() {
        if (this._isMounted) {
            this.setState({ showEditMode: false });
        }
    },

    onUpdate() {
        this.context.fireEvent(
            `entry`,
            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
            this.props.data.reservationids
        );
    },

    getLayerContent() {
        const offset = _.nodeOffset(ReactDOM.findDOMNode(this).offsetParent);
        const target = {
            top: this.props.styles[0].top + offset.top,
            left: this.props.styles[0].left + offset.left,
            width: this.props.styles[0].width,
            height: this.props.styles[0].height,
        };

        return (
            <Popover
                key={this.props.data.reservationids[0]}
                target={target}
                style={{ width: Popover.DEFAULT_WIDTH }}
                onClose={this.onInfoClose}
                noClickToClose={true}
            >
                <ReservationInfoPopup
                    onDelete={this.onDelete}
                    onUpdate={this.onUpdate}
                    reservationIds={this.props.data.reservationids}
                    onClose={this.onInfoClose}
                    user={this.context.user}
                    update={this.context.update}
                    isEditMode={this.state.showEditMode}
                    onReservationEditChange={this.onReservationEditChange}
                    activeLayer={this.props.activeLayer}
                />
            </Popover>
        );
    },

    dragStart(isFirst, isLast, event) {
        this._isClickable = true;
        if (this.props.data.layer !== this.props.activeLayer) {
            return;
        }

        const leftButtonOrSingleTap = _.isSingleClick(event);
        if (
            !leftButtonOrSingleTap ||
            !this.props.onDragStart ||
            event.ctrlKey ||
            _.isModKey(event) || // This makes mod-clicking to group reservations easy, but requires availabilty overlap drags to add the mod key post-drag-start.
            this.props.data.kind === EntryKind.NONE
        ) {
            return;
        }

        // On background obstacles, let the mouse down event bubble up to Calendar
        if (this.props.data.occupied === false) {
            DragListener.add(event, () => {
                this._isClickable = false;
            });
            return;
        }
        if (!this.props.data.modifiable || this.props.data.isPartial) {
            event.stopPropagation();
            return;
        }

        let dragType = "move";
        let isHighResolution = false;

        if (isFirst || isLast) {
            if (_.matches(event.target, ".dragBottom")) {
                dragType = "resize-end";
            } else if (_.matches(event.target, ".dragTop")) {
                dragType = "resize-start";
            } else if (_.matches(event.target, ".dragRight")) {
                dragType = "resize-end";
            } else if (_.matches(event.target, ".dragLeft")) {
                dragType = "resize-start";
            }
            if (_.matches(event.target, ".dragBottomFine")) {
                dragType = "resize-end";
                isHighResolution = true;
            } else if (_.matches(event.target, ".dragTopFine")) {
                dragType = "resize-start";
                isHighResolution = true;
            } else if (_.matches(event.target, ".dragRightFine")) {
                dragType = "resize-end";
                isHighResolution = true;
            } else if (_.matches(event.target, ".dragLeftFine")) {
                dragType = "resize-start";
                isHighResolution = true;
            }
        }

        this.props.onDragStart(this.props.data, dragType, event, isHighResolution);
    },

    showInfo(event, showPanel = true, showEditMode = false) {
        event?.stopPropagation();
        const user = this.context.user;
        if (
            !user.useInfoPopover ||
            this.context.getAppSize().width < LayoutConstants.MIN_WIDTH_FOR_POPOVER
        ) {
            this.props.onInfoOpen(this.props.data.reservationids, showPanel, showEditMode);
        } else if (showPanel) {
            this.setState({ showEditMode });
            this.props.showLayer();
            this.props.onInfoOpen(this.props.data.reservationids, false, showEditMode);
        }
    },

    handleInfoShortcut(showEditMode, event) {
        if (showEditMode) {
            this.showInfo(event, true, true);
            return;
        }

        this.showInfo(event, true, false);
    },

    showObjectInfo(objectId: number) {
        this.props.onObjectInfo(objectId, false, true);
    },

    onDragOver(event) {
        if (
            _.isEventDragDataOfType(event, [
                "application/x-timeedit-object",
                "application/x-timeedit-navbar",
            ])
        ) {
            event.preventDefault();
            this.setState({ isDropTarget: true });
        }
        event.stopPropagation();
    },

    onDragLeave() {
        this.setState({ isDropTarget: false });
    },

    onDrop(event) {
        let data = _.getDragData(event, "application/x-timeedit-object");
        if (data) {
            event.stopPropagation();
            data = JSON.parse(data);
            this.props.enableEditMode(this.props.data, { addObject: data });
        }
        this.setState({ isDropTarget: false });
    },

    loadTooltip(forceUpdate) {
        if (this.props.data.isPadding) {
            if (!forceUpdate) {
                return;
            }
            const mousePosition = this.state.mousePosition;
            if (!mousePosition) {
                return;
            }
            this.props.renderTooltip(
                <PaddingTooltip
                    layerSize={this.props.layerSize}
                    tooltipLeft={
                        mousePosition.x - mousePosition.offset.left + LEFT_PADDING >
                        this.props.layerSize.width
                    }
                    isVisible={this.props.isTooltipVisible}
                    padding={this.props.data.padding}
                    mousePosition={mousePosition}
                />,
                `${this.props.data.reservationids.join(",")}:padding`
            );
            return;
        }
        if (!this.props.data.readable) {
            // No tooltips for ghost entries or private entries.
            return;
        }

        if (this.state.reservationInfo && !forceUpdate) {
            return;
        }

        Reservation.get(this.props.data.reservationids, (reservations) => {
            if (this._isMounted) {
                const mousePosition = this.state.mousePosition;
                if (!mousePosition) {
                    return;
                }
                this.setState({ reservationInfo: reservations }, () => {
                    if (this.props.isConflictMode) {
                        this.props.renderTooltip(
                            <ReservationConflictInfoTooltip
                                tooltipLeft={
                                    mousePosition.x -
                                        mousePosition.offset.left +
                                        CONFLICT_TOOLTIP_LEFT_PADDING >
                                    this.props.layerSize.width
                                }
                                isVisible={this.props.isTooltipVisible}
                                onClose={() => this.hideTooltip(true)}
                                mousePosition={mousePosition}
                                conflictingObjects={this.props.data.conflictingObjects}
                                reservationIds={this.props.data.reservationids}
                                showObjectInfo={this.showObjectInfo}
                            />,
                            this.props.data.reservationids.join(",")
                        );
                    } else {
                        this.props.renderTooltip(
                            <ReservationTooltip
                                tooltipLeft={
                                    mousePosition.x - mousePosition.offset.left + LEFT_PADDING >
                                    this.props.layerSize.width
                                }
                                isVisible={this.props.isTooltipVisible}
                                reservations={this.state.reservationInfo}
                                mousePosition={mousePosition}
                            />,
                            this.props.data.reservationids.join(",")
                        );
                    }
                });
            }
        });
    },

    trackMouse(event) {
        if (!this.props.parentCalendarElemRef) return;
        const offset = _.nodeOffset(this.props.parentCalendarElemRef);
        const clientPos = _.getClientPos(event);
        this.setState({
            mousePosition: { x: clientPos.x, y: clientPos.y, offset },
        });
    },

    hideTooltip(force = false, mouseMovedToEl?: HTMLElement) {
        if (mouseMovedToEl?.classList.contains(CONFLICT_INFO_TOOLTIP_CLASSNAME)) return;
        if (this.context.user.tooltipInSidebar) {
            this.props.onDynamicReservationIdsChanged([]);
        } else {
            if (!this.props.isTooltipLocked || force) {
                this.props.requestTooltip(this.props.entryKey, false, force);
                this.setState({ mousePosition: null });
            }
        }
    },

    onMouseOver() {
        if (!this.props.isTooltipLocked) {
            this.showTooltip();
        }
    },

    showTooltip() {
        if (this.context.user.tooltipInSidebar) {
            this.props.onDynamicReservationIdsChanged(this.props.data.reservationids);
        } else {
            this.props.requestTooltip(this.props.entryKey, true, false);
        }
    },

    render() {
        if (this.state.waitingForDeletion) {
            return null;
        }

        return (
            <>
                {this.props.styles.map((style, index) =>
                    this.renderSubentry(
                        index === 0,
                        index === this.props.styles.length - 1,
                        _.clone(style),
                        _.clone(this.props.classes[index]),
                        index
                    )
                )}
            </>
        );
    },

    isWrongKind() {
        return (
            // eslint-disable-next-line no-magic-numbers
            (this.props.data.kind === 4 && this.props.templateKind.name !== "TEMPLATE_KIND_INFO") ||
            // eslint-disable-next-line no-magic-numbers
            (this.props.data.kind === 5 &&
                this.props.templateKind.name !== "TEMPLATE_KIND_AVAILABILITY")
        );
    },

    renderSubentry(isFirst, isLast, styles, classes, key) {
        const WIDTH_MARGIN = 50;
        if (styles.left + styles.width > this.props.layerSize.width - WIDTH_MARGIN) {
            // eslint-disable-next-line no-param-reassign
            classes.leftSideContext = true;
        }
        let text: string | React.ReactNode[] = "";
        let extra = null;
        const hasOverlapCount = this.props.data.overlapCount > 1 && !this.props.data.isSideBySide;

        // eslint-disable-next-line no-param-reassign
        styles.width = Math.round(styles.width);
        // eslint-disable-next-line no-param-reassign
        styles.height = Math.round(styles.height);
        const lowHeight = styles.height < MIN_HEIGHT_FOR_TEXT_AND_INFO;

        if (isFirst) {
            if (!classes.ghost) {
                extra = this._renderClusterInfo(lowHeight);
            }

            if (classes.small !== true && !this.props.isTextless) {
                text = this.props.data.text;
            }

            if (
                this.props.isActiveInfoEntry &&
                (!this.context.user.useInfoPopover || this.props.isLayerShown)
            ) {
                // eslint-disable-next-line no-param-reassign
                classes.infoEntry = true;
            }
        }

        if (this.props.data.kind === EntryKind.EXTERNAL) {
            return (
                <div className={_.classSet(classes)} style={styles} key={key}>
                    {text}
                </div>
            );
        }

        let infoIcon: React.JSX.Element | null = (
            <span
                className="info"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(evt) => this.showInfo(evt)}
            />
        );
        if (
            classes.ghost ||
            !this.props.data.readable ||
            styles.width < MIN_WIDTH_FOR_INFO_ICON ||
            styles.height < MIN_HEIGHT_FOR_INFO_ICON ||
            this.isWrongKind() ||
            this.props.data.isPadding
        ) {
            infoIcon = null;
        }

        const lockIcon =
            this.props.data.lock && this.props.data.lock === "soft" ? (
                <span className="lock" />
            ) : null;

        const capacity = this.props.data.capacity ? (
            <span className="capacityEntry">
                {this.props.data.remainingCapacity !== undefined ? (
                    <span
                        title={`${remaningCapacityTitle}: ${this.props.data.remainingCapacity} (${totalCapacityTitle}: ${this.props.data.capacity})`}
                    >
                        <span className="capacityLabel">{capacityLabelTitle}</span>:
                        {`${this.props.data.remainingCapacity} (${this.props.data.capacity})`}
                    </span>
                ) : (
                    <div title={`${totalCapacityTitle}: ${this.props.data.capacity}`}>
                        <span className="capacityLabel">{capacityLabelTitle}</span>:
                        {this.props.data.capacity}
                    </div>
                )}
            </span>
        ) : null;

        const size = this.props.data.size ? (
            <div className="size" title={`${sizeLabelTitle}: ${this.props.data.size}`}>
                <span className="sizeLabel">{sizeLabelTitle}</span>:{this.props.data.size}
            </div>
        ) : null;

        if (classes.ghost) {
            text = [];
            if (isFirst) {
                text.push(
                    <span className="time startTime" key="start">
                        {this.props.data.startTimes[0].format("HH:mm")}
                    </span>
                );
            }
            if (isLast) {
                text.push(
                    <span className="time endTime" key="end">
                        {this.props.data.endTimes[0].format("ee:mm")}
                    </span>
                );
            }

            const shouldShowLength =
                this.props.data.dragType &&
                this.props.data.dragType !== "move" &&
                ((isLast && this.props.data.dragType !== "resize-start") ||
                    (isFirst && this.props.data.dragType === "resize-start"));
            if (shouldShowLength) {
                const length = Math.abs(
                    this.props.data.endTimes[0].getMts() - this.props.data.startTimes[0].getMts()
                );
                text.push(
                    <span key="len">
                        <EntryLength
                            containerWidth={styles.width}
                            containerHeight={styles.height}
                            length={length}
                        />
                    </span>
                );
            }
        }
        const hasCapacityInSizeMode = capacity && this.props.isSizeMode && !this.props.data.size;

        let overlapElement: React.JSX.Element | null = null;
        if (!this.props.data.isSideBySide && !hasCapacityInSizeMode) {
            if (hasOverlapCount && this.props.data.kind !== EntryKind.INFO) {
                overlapElement = (
                    <span className="overlapCount" title={overlapCountTitle}>
                        {this.props.data.overlapCount}
                    </span>
                );
            }
            if (this.props.data.overlapView) {
                overlapElement = (
                    <span className="overlapCount" title={overlapCountTitle}>
                        {this.props.data.reservationids.length}
                    </span>
                );
            }
        }

        let overlapGroupElement: React.JSX.Element | null = null;
        if (this.props.data.overlapGroup > 0 && classes.standard) {
            overlapGroupElement = (
                <span className="overlapGroupCount" title={overlapGroupCountTitle}>
                    {this.props.data.overlapGroup}
                </span>
            );
        }

        const isVerticalTime = this.props.isVertical;
        let dragElements: React.JSX.Element[] | null = [];
        let highResolutionDragElements: React.JSX.Element[] | null = [];
        if (isFirst && isVerticalTime && !classes.yStartPartial && !this.props.data.isPartial) {
            dragElements.push(<div className="dragTop" key={"dt"} />);
            highResolutionDragElements.push(<div className="dragTopFine dragHandle" key={"dtf"} />);
        } else if (
            isFirst &&
            !isVerticalTime &&
            !classes.xStartPartial &&
            !this.props.data.isPartial
        ) {
            dragElements.push(<div className="dragLeft" key={"dl"} />);
            highResolutionDragElements.push(
                <div className="dragLeftFine dragHandle" key={"dlf"} />
            );
        }
        if (isLast && isVerticalTime && !classes.yEndPartial && !this.props.data.isPartial) {
            dragElements.push(<div className="dragBottom" key={"db"} />);
            highResolutionDragElements.push(
                <div className="dragBottomFine dragHandle" key={"dbf"} />
            );
        } else if (
            isLast &&
            !isVerticalTime &&
            !classes.xEndPartial &&
            !this.props.data.isPartial
        ) {
            dragElements.push(<div className="dragRight" key={"dr"} />);
            highResolutionDragElements.push(
                <div className="dragRightFine dragHandle" key={"drf"} />
            );
        }

        if (classes.private || classes.backgroundEntry || classes.isPadding) {
            dragElements = null;
            highResolutionDragElements = null;
        }

        if (
            !_.contains([EntryKind.COMPLETE, EntryKind.GROUP_COMPLETE], this.props.data.kind) ||
            !this.props.isLayerActive
        ) {
            highResolutionDragElements = []; // Only show handles on current entry.
        }

        const isSizeReservationMode =
            this.props.data.capacity && this.props.isSizeMode && !this.props.data.size;
        // eslint-disable-next-line no-param-reassign
        classes.capacity = isSizeReservationMode;
        // eslint-disable-next-line no-param-reassign
        classes.hasGroup = this.props.data.groups && _.some(this.props.data.groups, (gr) => gr > 0);

        const HEIGHT_MODIFIER = 5;
        const innerStyles: React.CSSProperties = {
            height: this.props.isVertical
                ? "100%"
                : hasCapacityInSizeMode
                ? styles.height
                : styles.height - HEIGHT_MODIFIER, // Width was not needed and caused flexibility problems when set
        };
        if (this.context.user.fontSize) {
            innerStyles.fontSize = `${this.context.user.fontSize}px`;
        }
        if (isSizeReservationMode) {
            innerStyles.display = "flex";
            innerStyles.flexDirection = this.props.isVertical ? "row" : "column";
            innerStyles.paddingRight = this.props.isVertical ? "14px" : "unset";
            innerStyles.paddingBottom = this.props.isVertical ? "20px" : "30px";
        }

        // eslint-disable-next-line no-param-reassign
        classes.droppable = this.state.isDropTarget;

        let exceptionElement: React.ReactElement | null = null;
        if (this.props.data.memberExceptionCount && this.props.data.memberExceptionCount > 0) {
            const className = this.props.data.complete
                ? "membershipExceptionIndicatorLight"
                : "membershipExceptionIndicatorDark";
            exceptionElement = (
                <div className={className} title={String(this.props.data.memberExceptionCount)} />
            );
        }

        const entryTextClassses = { entryText: true, capacityEntryText: hasCapacityInSizeMode };

        let innerDiv: React.ReactElement;
        if (lowHeight) {
            innerDiv = (
                <div className={ENTRY_CLASS} style={innerStyles}>
                    <span className="extraInfo lowHeight">
                        {extra}
                        <span className={_.classSet(entryTextClassses)}>
                            {exceptionElement}
                            {text}
                            {hasCapacityInSizeMode && capacity}
                        </span>
                        {!hasCapacityInSizeMode && capacity}
                        {size}
                        {overlapElement}
                    </span>
                    {this.props.sizeEntries}
                    {infoIcon}
                    {lockIcon}
                </div>
            );
        } else {
            innerDiv = (
                <div className={ENTRY_CLASS} style={innerStyles}>
                    <span className={_.classSet(entryTextClassses)}>
                        {exceptionElement}
                        {text}
                        {hasCapacityInSizeMode && capacity}
                    </span>
                    {!hasCapacityInSizeMode && capacity}
                    {size}
                    <span className="extraInfo">
                        {extra}
                        {overlapElement}
                    </span>
                    {this.props.sizeEntries}
                    {infoIcon}
                    {lockIcon}
                </div>
            );
        }

        if (this.props.data.kind === EntryKind.INFO) {
            return (
                <div className={_.classSet(classes)} style={styles} key={key}>
                    {innerDiv}
                </div>
            );
        }

        let statusIndicator: React.ReactElement | null = null;
        if (classes.incomplete) {
            statusIndicator = <div className="incompleteIndicator topRightCorner" />;
        }
        if (classes.denied) {
            statusIndicator = <div className="rejectedIndicator topRightCorner" />;
        }
        if (classes.requested) {
            statusIndicator = <div className="requestedIndicator topRightCorner" />;
        }
        if (classes.preliminary) {
            statusIndicator = <div className="preliminaryIndicator topRightCorner" />;
        }
        if (classes.planned) {
            statusIndicator = <div className="plannedIndicator topRightCorner" />;
        }
        if ((classes.preliminary || classes.planned) && classes.incomplete) {
            statusIndicator = (
                <div className="incompletePreliminaryPlannedIndicator topRightCorner" />
            );
        }

        if (this.props.borderAndTextOnly) {
            // eslint-disable-next-line no-param-reassign
            styles.background = "none";
            // eslint-disable-next-line no-param-reassign
            styles.pointerEvents = "none";
        }

        const mouseEventHandlerProps: React.HtmlHTMLAttributes<HTMLDivElement> = {
            onTouchStart: this.dragStart.bind(this, isFirst, isLast),
            onMouseDown: this.dragStart.bind(this, isFirst, isLast),
            onDragOver: this.onDragOver,
            onDragLeave: this.onDragLeave,
            onClick: this.onClick,
            onMouseOver: this.onMouseOver,
            onMouseOut: (e) => this.hideTooltip(false, e.relatedTarget),
            onDrop: this.onDrop,
        };

        return (
            <div
                {...(!isSizeReservationMode && mouseEventHandlerProps)}
                className={_.classSet(classes)}
                style={styles}
                onMouseMove={this.context.user.tooltipInSidebar ? _.noop : this.trackMouse}
                ref={key}
                key={key}
            >
                {statusIndicator}
                {overlapGroupElement}
                {innerDiv}
                {!isSizeReservationMode && dragElements}
            </div>
        );
    },

    _renderClusterInfo(isLowHeight = false) {
        if (this.props.data.reservationids.length === 0) {
            return null;
        }

        const clusterValues = this.props.getClusterValues(this.props.data.periods);
        if (clusterValues.length <= 1) {
            return null;
        }

        if (
            this.props.data.reservationids.length < clusterValues.length &&
            this.props.isWeekCluster
        ) {
            const weeks = this.props.data.startTimes.map(
                (dateTime) =>
                    new MillenniumWeek(dateTime, Language.firstDayOfWeek, Language.daysInFirstWeek)
            );
            const displayWeekYear =
                weeks[0].weeksBetween(weeks[weeks.length - 1]) >= TimeConstants.MIN_WEEKS_PER_YEAR;
            const MAX_SECTIONS = 5;
            let wt: string | null = null;
            if (!isLowHeight) {
                const weekText = MillenniumWeek.toString(weeks, (period, index, allPeriods) => {
                    if (index === MAX_SECTIONS && allPeriods.length > MAX_SECTIONS) {
                        return "...";
                    }
                    if (index > MAX_SECTIONS) {
                        return null;
                    }

                    if (Array.isArray(period)) {
                        if (this.context.customWeekNames.length > 0) {
                            return Language.formatWeekTableCell(
                                period[0].date,
                                period[period.length - 1].date,
                                this.context.customWeekNames
                            );
                        }
                        if (period.length === 1) {
                            return `${Language.get("nc_entry_week_short")}${period[0].week(
                                displayWeekYear
                            )}`;
                        }
                        return `${Language.get("nc_entry_week_short")}${period[0].week(
                            displayWeekYear
                        )}-${period[period.length - 1].week(displayWeekYear)}`;
                    }
                    if (this.context.customWeekNames.length > 0) {
                        return Language.formatSingleWeekTableCell(
                            period.date,
                            false,
                            this.context.customWeekNames
                        );
                    }
                    return `${Language.get("nc_entry_week_short")}${period.week(displayWeekYear)}`;
                });
                wt = ` (${weekText})`;
            }
            return (
                <span key="clusterInfo" className="clusterInfo">
                    <strong>{this.props.data.reservationids.length}</strong>
                    {wt}
                </span>
            );
        }

        return (
            <span key="clusterInfo" className="clusterInfo">
                <strong>{this.props.data.reservationids.length}</strong>
            </span>
        );
    },

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            !_.isEqual(this.props.classes, nextProps.classes) ||
            !_.isEqual(this.props.selectedReservationIds, nextProps.selectedReservationIds) ||
            this.props.clusterDepth !== nextProps.clusterDepth ||
            !_.isEqual(this.props.data, nextProps.data) ||
            this.props.isVertical !== nextProps.isVertical ||
            this.props.isActiveInfoEntry !== nextProps.isActiveInfoEntry ||
            this.props.isLayerShown !== nextProps.isLayerShown ||
            this.props.isTooltipVisible !== nextProps.isTooltipVisible ||
            this.props.isWeekCluster !== nextProps.isWeekCluster ||
            !_.isEqual(this.props.layerSize, nextProps.layerSize) ||
            !_.isEqual(this.props.styles, nextProps.styles) ||
            this.props.isLayerActive !== nextProps.isLayerActive ||
            this.state.waitingForDeletion !== nextState.waitingForDeletion ||
            this.state.reservationInfo !== nextState.reservationInfo ||
            this.state.mousePosition !== nextState.mousePosition ||
            !_.isEqual(this.state.availableStatuses, nextState.availableStatuses) ||
            this.context.user !== nextContext.user ||
            this.props.sizeEntries !== nextProps.sizeEntries
        );
    },
});

export default Entry = LayerComponent.wrap(Entry);
