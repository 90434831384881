const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
const User = require("../models/User");
const View = require("../models/View");
const Section = require("./Section");
const Calendar = require("../models/Calendar");
const SectionModel = require("../models/Section");
const TabbedSection = require("./TabbedSection");
const SelectionPane = require("./SelectionPane");
const ViewErrorBoundary = require("./ViewErrorBoundary");
const Menu = require("./Menu");
const LoadIndicator = require("./LoadIndicator");
const MessageBar = require("./MessageBar");
const MassChangeBar = require("./MassChangeBar");
const Mousetrap = require("@timeedit/mousetrap");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const MenuModel = require("../models/Menu");
const Macros = require("../models/Macros");
const Tutorial = require("../lib/Tutorial");
const TemplateKind = require("../models/TemplateKind");
const Language = require("../lib/Language");
const Tutorials = require("../lib/Tutorials");
const AboutDialog = require("./About");
const ObjectManager = require("./ObjectManager");
const CommandBar = require("./CommandBar");
const ResizeableComponent = require("../lib/ResizeableComponent");
const ShortcutOverlay = require("./ShortcutOverlay");
const ReservationSearch = require("../models/ReservationSearch");
const StaticReservations = require("../models/StaticReservations");
const ModalDialog = require("./ModalDialog");
const McFluffy = require("../models/McFluffy");
const ReservationStatus = require("../lib/ReservationStatus");
const Log = require("../lib/Log");
const Experiment = require("../models/Experiment");
const ExceptionEditor = require("./ExceptionEditor");
const PrimaryFieldManager = require("../models/PrimaryFieldManager");
const ExamData = require("../models/ExamData");
const MemberData = require("../models/MemberData");
const TimeConstants = require("../lib/TimeConstants");
import { EXAM_MODE, EXAM_NOT_AUTHORIZED, EXAM_NOT_FOUND } from "../lib/ExamConstants";
import { Scratchpad } from "../models/Scratchpad";
// Webpack alias for @resouces
// eslint-disable-next-line import/no-unresolved
import "@resources/sass/bundle.sass";

import {
    SimpleDateFormat,
    MillenniumDate,
    MillenniumDateTime,
    MillenniumWeekday,
    MillenniumWeek,
} from "@timeedit/millennium-time";
import { context } from "@timeedit/te-auth-components/lib";
import { setupMixpanel } from "../lib/TrackingTools";
import { createReservationGroupNameFromTrack } from "../lib/ActivityUtils";
const Viewer = require("../lib/Viewer");
const WeekdayHeader = require("../models/WeekdayHeader");
import { toSearchCriteriaFromObjects } from "../lib/FilterUtils";
import { hasPermissions } from "@timeedit/types/lib/utils/permissionCalculator";
import { isArray } from "underscore";

import CoreLDInitializer from "./CoreLDInitializer";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { OPERATION_TYPES, REQUEST_TYPES } from "./preferences/PrefsCoreAPI";

const UpdateTimeConstants = {
    // eslint-disable-next-line no-magic-numbers
    INDICATE_UPDATE: 6 * 60 * 60 * 1000, // 6 hours
    // eslint-disable-next-line no-magic-numbers
    UPDATE_IF_INACTIVE: 36 * 60 * 60 * 1000, // 36 hours
    // eslint-disable-next-line no-magic-numbers
    FORCE_UPDATE: 48 * 60 * 60 * 1000, // 48 hours
};

const AM_ACTION = {
    SCHEDULE: "schedule",
    CANCEL: "cancel",
};

// eslint-disable-next-line no-magic-numbers
const INACTIVITY_THRESHOLD_TIME = 10 * 60 * 1000; // 10 minutes
const FIVEHUNDRED_MILLIS = 500;

const LAYER_KIND_EXPERIMENT = 3;

const MOUSETRAP_ESCAPE = "esc";

const pushToFront = (array, value) => {
    const result = array.filter((val) => val !== value);
    result.splice(0, 0, value);
    return result;
};

const hasExamId = (reservation, examFieldId) => {
    let hasId = false;
    if (!reservation.fields) {
        return hasId;
    }
    reservation.fields.forEach((field) => {
        if (field.id === examFieldId) {
            if (field.values && field.values.length > 0 && field.values[0] !== "") {
                hasId = true;
            }
        }
    });
    return hasId;
};

const getLanguageString = function () {
    return navigator.languages ? navigator.languages[0] : navigator.language;
};

const anyCalendarHasWeekdayHeader = (allCalendars) => {
    if (allCalendars.length === 0) {
        return false;
    }
    return allCalendars.findIndex(
        (calendar) => calendar instanceof Calendar && calendar.hasHeaderType(WeekdayHeader)
    );
};

const doPrompt = (
    message?: string | undefined,
    _default?: string | undefined
): string | undefined => prompt(message, _default) ?? undefined;

const trackFits = (track, weeks) => track.length >= weeks.length;

let primaryFieldManager;
let _prevEscBinding = [];

const App = createReactClass({
    displayName: "App",
    moveLayers: {},

    getInitialState() {
        return {
            showSelection: true,
            showCalendarIds: false,
            showObjectManager: false,
            selectionHeight: 230,
            activeCalendar: 0,
            aboutVisible: false,
            commandBarVisible: false,
            shortcutOverlayVisible: false,
            macros: new Macros().freeze(),
            objectSearch: null,
            currentFluffyItem: null,
            menuPosition: MenuModel.POSITION.LEFT,
            reservationInfoIds: [],
            orderInfoIds: [],
            isEntryInfo: false,
            copiedEntry: null,
            copiedGroupEntries: [],
            updateAvailable: null,
            massChangeEnabled: true,
            activeLayer: 0,
            massChangeProgress: 0,
            filters: [],
            colorDefs: [],
            objectIds: [],
            incompleteObjects: [],
            dynamicReservationIds: [],
            prefsComponentActive: false,
            examComponentActive: false,
            memberCreatorActive: false,
            targetExternalAppsEnvironment: {},
            hasReservationExceptions: false,
            multiPanelOrder: ["object", "reservation", "order"],
            skipBackgroundText: false,
            showEnableCustomWeekNames: false,
            customWeekNames: [],
            objectRequestCallback: null,
            layers: [],
            activityManagerReservationCallback: null,
            examAPI: null,
            examRequest: null,
            examIdField: undefined,
            examTemplate: undefined,
            useNewReservationGroups: false,
            loadFinished: false,
            schedulingTracks: {},
            currentTrack: null,
            trackSchedulingCallback: null,
            finishedTracks: [],
            mappedResult: [],
            scratchpad: new Scratchpad(this.onPinboardChanged),
            lockedReservationIds: [],
        };
    },

    doLogout() {
        if (this.context?.logout) {
            this.context.logout();
        }
    },

    getAuthState() {
        return this.context?.authState || "";
    },

    getChildContext() {
        return {
            update: TimeEdit.State.replace,
            user: this.props.user,
            registerMacro: this.registerMacro,
            fireEvent: this.fireEvent,
            deregisterMacro: this.deregisterMacro,
            presentModal: this.presentModal,
            dismissModal: this.handleDismissModal,
            focusFirstCalendar: this.focusFirstCalendar,
            getAppSize: this.getAppSize,
            colorDefs: this.state.colorDefs,
            prefsComponentActive: this.state.prefsComponentActive,
            examComponentActive: this.state.examComponentActive,
            memberCreatorActive: this.state.memberCreatorActive,
            targetExternalAppsEnvironment: this.state.targetExternalAppsEnvironment,
            hasReservationExceptions: this.state.hasReservationExceptions,
            getViewId: this.getViewId,
            showEnableCustomWeekNames: this.state.showEnableCustomWeekNames,
            customWeekNames: this.state.customWeekNames,
            primaryFieldManager,
            registerExamAPI: this.registerExamAPI,
            createExamRequest: this.createExamRequest,
            examRequest: this.state.examRequest,
            toggleSelection: this.handleToggleSelection,
            examIdField: this.state.examIdField,
            examTemplate: this.state.examTemplate,
            clearExamRequest: this.clearExamRequest,
            usingSSO: this.props.usingSSO || false,
            getAuthState: this.getAuthState,
            ssoLogout: this.doLogout,
            env: this.props.env,
            useNewReservationGroups: this.state.useNewReservationGroups,
            isLockingEnabled: this.isLockingEnabled,
            scratchpad: this.state.scratchpad,
            envIsNotProduction: this.envIsNotProduction,
        };
    },

    childContextTypes: {
        update: PropTypes.func,
        user: PropTypes.object,
        registerMacro: PropTypes.func,
        fireEvent: PropTypes.func,
        deregisterMacro: PropTypes.func,
        presentModal: PropTypes.func,
        dismissModal: PropTypes.func,
        focusFirstCalendar: PropTypes.func,
        getAppSize: PropTypes.func,
        colorDefs: PropTypes.array,
        prefsComponentActive: PropTypes.bool,
        examComponentActive: PropTypes.bool,
        memberCreatorActive: PropTypes.bool,
        targetExternalAppsEnvironment: PropTypes.object,
        hasReservationExceptions: PropTypes.bool,
        getViewId: PropTypes.func,
        showEnableCustomWeekNames: PropTypes.bool,
        customWeekNames: PropTypes.array,
        primaryFieldManager: PropTypes.object,
        registerExamAPI: PropTypes.func,
        createExamRequest: PropTypes.func,
        examRequest: PropTypes.object,
        toggleSelection: PropTypes.func,
        examIdField: PropTypes.number,
        examTemplate: PropTypes.string,
        clearExamRequest: PropTypes.func,
        usingSSO: PropTypes.bool,
        getAuthState: PropTypes.func,
        ssoLogout: PropTypes.func,
        env: PropTypes.object,
        useNewReservationGroups: PropTypes.bool,
        isLockingEnabled: PropTypes.func,
        scratchpad: PropTypes.object,
        envIsNotProduction: PropTypes.func,
    },

    isLockingEnabled() {
        return true;
    },

    envIsBeta() {
        return process.env.NODE_ENV === "development" || this.props.env.serverEnv === "beta";
    },

    envIsNotProduction() {
        return process.env.NODE_ENV === "development" || this.props.env.serverEnv !== "production";
    },

    createExamRequest(data, mode) {
        // Yes, we should be able to merge this into a single code path and always use an array
        // But we want to keep things separate for the Exam plugin in the first version
        const offset = TimeConstants.UNIX_MILLENNIUM_OFFSET;
        if (Array.isArray(data)) {
            const fieldIds: number[] = [];
            const mappedData = data.map((d) => {
                const mapped = { ...d };
                mapped.begin = d.begin + offset;
                mapped.end = d.end + offset;
                mapped.modified = d.modified + offset;
                mapped.created = d.created + offset;
                mapped.mode = mode;
                fieldIds.push(...mapped.fields.map((field) => field.id));
                return mapped;
            });
            API.getFields(fieldIds, (fields) => {
                // Map extids to all fields
                fields.forEach((field) => {
                    mappedData.forEach((data) => {
                        data.fields.forEach((f) => {
                            if (f.id === field.id) {
                                f.extid = field.extid;
                            }
                        });
                    });
                });

                if (!this.state.examAPI) {
                    this.setState({ examRequest: mappedData }, () =>
                        this.toggleExamComponent(false)
                    );
                } else {
                    this.fireEvent(`app`, Macros.Event.EXAM_REQUEST_UPDATE, mappedData);
                }
            });
        } else {
            // Fire event to create Exam component
            // Use same logic as for the reservation list keyboard shortcut
            const mappedData = { ...data };
            mappedData.begin = data.begin + offset;
            mappedData.end = data.end + offset;
            mappedData.modified = data.modified + offset;
            mappedData.created = data.created + offset;
            mappedData.mode = mode;
            API.getFields(
                data.fields.map((field) => field.id),
                (fields) => {
                    fields.forEach((field, index) => {
                        mappedData.fields[index].extid = field.extid;
                    });
                    if (!this.state.examAPI) {
                        this.setState({ examRequest: mappedData }, () =>
                            this.toggleExamComponent(false)
                        );
                    } else {
                        this.fireEvent(`app`, Macros.Event.EXAM_REQUEST_UPDATE, mappedData);
                    }
                }
            );
        }
    },

    clearExamRequest(callback) {
        this.setState({ examRequest: null }, callback);
    },

    registerExamAPI(examAPI) {
        // eslint-disable-next-line no-console
        console.log("Registered Exam API");
        if (this.state.examRequest) {
            let requests;
            if (Array.isArray(this.state.examRequest)) {
                requests = this.state.examRequest.map((request) => Object.assign({}, request));
            } else {
                requests = Object.assign({}, this.state.examRequest);
            }
            setTimeout(() => {
                this.fireEvent(`app`, Macros.Event.EXAM_REQUEST_UPDATE, requests);
            }, FIVEHUNDRED_MILLIS);
        }
        this.setState({ examAPI, examRequest: null });
    },

    clearSchedulingTracks() {
        // Can be invoked while no track scheduling is in progress
        const tCb = this.state.trackSchedulingCallback;
        this.setState(
            {
                schedulingTracks: {},
                currentTrack: null,
                trackSchedulingCallback: null,
                finishedTracks: [],
                mappedResult: [],
            },
            () => {
                if (tCb) {
                    tCb({ action: AM_ACTION.CANCEL });
                }
            }
        );
    },

    setSchedulingTracks(tracks, callback) {
        const key =
            Object.keys(tracks).find(
                (trackKey) => !tracks[trackKey].every((entry) => entry.reservationId)
            ) || Object.keys(tracks)[0];
        const finishedTracks = Object.keys(tracks).filter((trackKey) =>
            tracks[trackKey].every((entry) => entry.reservationId)
        );
        this.setState(
            {
                schedulingTracks: tracks,
                currentTrack: tracks[key],
                trackSchedulingCallback: callback,
                finishedTracks,
            },
            () => {
                this.onCurrentTrackChanged([key]);
            }
        );
    },

    goToNextTrack(currentTrack, mappedResult) {
        const currentKey = Object.keys(this.state.schedulingTracks).find(
            (key) => this.state.schedulingTracks[key] === currentTrack
        );
        const mappedActivities = mappedResult.map((mr) => mr.activityId);
        const finished = currentTrack.every(
            (activity) =>
                mappedActivities.indexOf(activity.activityId) !== -1 || activity.reservationId
        );
        if (finished) {
            const finishedTracks =
                this.state.finishedTracks.indexOf(currentKey) === -1
                    ? [...this.state.finishedTracks, currentKey]
                    : this.state.finishedTracks;
            const nextKey = Object.keys(this.state.schedulingTracks).find(
                (key) => finishedTracks.indexOf(key) === -1
            );
            if (nextKey) {
                this.setState({ finishedTracks, mappedResult }, () => {
                    this.onCurrentTrackChanged([nextKey]);
                });
            } else {
                // All tracks scheduled. Burst of confetti?
                this.clearSchedulingTracks();
            }
        } else {
            this.setState({ mappedResult }, () => {
                // Preserve/re-set duration here, if any
                this.onCurrentTrackChanged([currentKey]);
            });
        }
    },

    onCurrentTrackChanged(trackKey) {
        // Array of strings

        const updateObjects = () => {
            if (!this.state.currentTrack || this.state.currentTrack.length === 0) {
                return;
            }
            const { objects, filters, fields } = this.filterToCommonObjects(
                this.state.currentTrack
            );

            API.getObjectNamesByExtid(objects.map((obj) => obj.value).flat(), false, (objs) => {
                API.getTypesByExtid(
                    [...objects.map((obj) => obj.extId), ...filters.map((flt) => flt.extId)],
                    true,
                    (types) => {
                        API.getFieldsByExtid(
                            fields.map((f) => f.extId),
                            (foundFields) => {
                                const resultingFields = fields.map((field) => {
                                    const result = { ...field };
                                    result.extid = field.extId;
                                    result.fieldExtId = field.extId;
                                    const foundField = _.find(
                                        foundFields.filter((f) => Boolean(f)),
                                        (ff) => ff.extid === result.extid
                                    );
                                    if (foundField) {
                                        result.id = foundField.id;
                                    }
                                    result.values = (
                                        Array.isArray(field.value) ? field.value : [field.value]
                                    )
                                        .map((value) => {
                                            // Server wants boolean field values as strings
                                            if (value === true || value === "true") {
                                                return "1";
                                            }
                                            if (value === false || value === "false") {
                                                return "0";
                                            }
                                            if (value === "null") {
                                                return null;
                                            }
                                            return value;
                                        })
                                        .filter((value) => value !== null);

                                    return result;
                                });

                                this.fireEvent(
                                    `app`,
                                    Macros.Event.SET_EXTERNAL_SELECTION,
                                    {
                                        fields: resultingFields,
                                        objects: objs.map((obj, index) => {
                                            const type = types[index];
                                            return Object.assign({}, obj, {
                                                type,
                                                typeId: type.id,
                                            });
                                        }),
                                        duration:
                                            this.state.schedulingTracks[trackKey[0]][0].duration,
                                    },
                                    () => {
                                        if (filters && filters.length > 0) {
                                            this.fireEvent(
                                                `app`,
                                                Macros.Event.SET_EXTERNAL_OBJECT_SEARCH_CRITERIA,
                                                {
                                                    searchCriteria: toSearchCriteriaFromObjects(
                                                        filters
                                                    ).map((filter) => {
                                                        const type = _.find(
                                                            types,
                                                            (tp) => tp.extid === filter.type.extid
                                                        );
                                                        return Object.assign({}, filter, { type });
                                                    }),
                                                    openSearchSettings: false,
                                                },
                                                _.noop
                                            );
                                        }
                                    }
                                );
                            }
                        );
                    }
                );
            });
        };
        if (this.state.currentTrack === this.state.schedulingTracks[trackKey[0]]) {
            updateObjects();
        } else {
            this.setState(
                { currentTrack: this.state.schedulingTracks[trackKey[0]] },
                updateObjects
            );
        }
    },

    filterToCommonObjects(activities) {
        // Should return a list of objects shared by all activities
        // The activities all belong to the same track
        // Type information is needed to check which objects go together
        // Would it be useful to be able to pass in a type as well and just find objects of that type?
        const commonObjects: any[] = [];
        const commonFilters: any[] = [];
        const commonFields: any[] = [];
        const firstActivity = activities[0];

        /** Returns true if the current value obj value is of type object  */
        const isObject = (obj) => obj.type === "object" && isArray(obj.value);
        /** Returns true if the current value obj value is of type filter  */
        const isFilter = (obj) => obj.type === "object" && obj.value?.categories != null;

        const isField = (obj) => obj.type === "field";

        const isObjectOrFilter = (value) => isObject(value) || isFilter(value);

        firstActivity.values
            .filter((value) => isObjectOrFilter(value))
            .forEach((object) => {
                if (
                    activities.every((act) =>
                        _.any(
                            act.values.filter((value) => isObjectOrFilter(value)),
                            (obj) =>
                                _.isEqual(obj.value, object.value) && object.extId === obj.extId // extId is the type ID, for some reason
                        )
                    )
                ) {
                    if (isObject(object)) {
                        object.value.forEach((val) => {
                            commonObjects.push(Object.assign({}, object, { value: [val] }));
                        });
                    } else {
                        commonFilters.push(object);
                    }
                }
            });

        firstActivity.values
            .filter((value) => isField(value))
            .forEach((field) => commonFields.push(field));

        return { objects: commonObjects, filters: commonFilters, fields: commonFields };
    },

    mapReservationsToActivities(reservationIds, callback = _.noop) {
        API.exportReservations(reservationIds, true, (fullReservations) => {
            // Get the week for each reservation
            // Match the week pattern to set tracks/activities
            const weeks = fullReservations.map((reservation) =>
                new MillenniumDateTime(reservation.begin)
                    .getMillenniumDate()
                    .getMillenniumWeek(Language.firstDayOfWeek, Language.daysInFirstWeek)
            );
            const track = this.state.currentTrack;
            const mappedResult = this.state.mappedResult || []; // Grab any already mapped result
            if (trackFits(track, weeks)) {
                track.forEach((activity) => {
                    weeks.forEach((week, index) => {
                        // We can only pick each reservation once. How do we re-do the mappings?
                        if (activity.week === week._week) {
                            // Only add if neither the activity nor the reservation has already been mapped, right?
                            if (
                                !activity.reservationId &&
                                mappedResult
                                    .map((mr) => mr.reservationId)
                                    .indexOf(fullReservations[index].id) === -1 &&
                                mappedResult
                                    .map((mr) => mr.activityId)
                                    .indexOf(activity.activityId) === -1
                            ) {
                                mappedResult.push({
                                    activityId: activity.activityId,
                                    reservationId: fullReservations[index].id,
                                });
                            }
                        }
                    });
                });
                // eslint-disable-next-line no-console
                console.log(mappedResult);
                if (
                    this.state.useNewReservationGroups &&
                    this.props.user.createGroupsForReservationTracks
                ) {
                    API.createReservationGroupWithReservations(
                        createReservationGroupNameFromTrack(track),
                        reservationIds,
                        (result) => {
                            Log.info("Reservation group created", result);
                            // Do we want to do something similar here to what we do in Calendar when a group is created?
                        },
                        (error) => {
                            Log.info("Error creating reservation group", error);
                        }
                    );
                }
                callback(mappedResult);
                this.goToNextTrack(track, mappedResult);
            } else {
                callback();
            }
        });
    },

    onPinboardChanged(objects) {
        const obs = Array.isArray(objects) ? [JSON.stringify(objects)] : [];
        API.setPreferences("pinboardObjects", obs, _.noop);
    },

    getViewId() {
        return this.props.view ? this.props.view.id : 0;
    },

    getAppSize() {
        return { width: this.state.width, height: this.state.height };
    },

    setupMousetrap() {
        const forceUpdate = () => {
            if (this._isReloading) {
                return;
            }
            if (window.confirm(Language.get("nc_client_update_needed"))) {
                // eslint-disable-line no-alert
                this._isReloading = true;
                document.location.reload();
            }
        };
        API.getMessageDispatcher().on("updateAvailable", () => {
            // Register the first time the client becomes aware of an available update
            if (!this.state.updateAvailable) {
                this.setState({ updateAvailable: Date.now() });
                return;
            }

            // After being aware for UPDATE_IF_INACTIVE, set a timeout that waits for
            // the client to be inactive for INACTIVITY_THRESHOLD_TIME and reload the page at that point
            if (
                !this._reloadTimeout &&
                Date.now() >= this.state.updateAvailable + UpdateTimeConstants.UPDATE_IF_INACTIVE
            ) {
                const INACTIVE_CHECK_INTERVAL = 5000;

                this._reloadTimeout = setInterval(() => {
                    if (
                        this._lastComponentActivity &&
                        Date.now() >= this._lastComponentActivity + INACTIVITY_THRESHOLD_TIME
                    ) {
                        forceUpdate();
                    }
                }, INACTIVE_CHECK_INTERVAL);
                return;
            }

            // After being aware for FORCE_UPDATE, force an update no matter what
            if (Date.now() >= this.state.updateAvailable + UpdateTimeConstants.FORCE_UPDATE) {
                forceUpdate();
            }
        });

        const registeredShortcuts = {};

        Mousetrap.bindWithHelp = function (
            keys,
            callback,
            action = undefined,
            description = "",
            hidden = false
        ) {
            if (!callback || callback === Mousetrap.noop) {
                return;
            }
            if (keys === "esc" && description === "") {
                // eslint-disable-next-line no-param-reassign
                description = Language.get("nc_shortcut_text_cancel.");
            }
            if (!hidden || process.env.NODE_ENV === "development") {
                registeredShortcuts[keys] = { keys, description, hidden };
            }
            if (!callback.isWrapped) {
                const wrapped = Mousetrap.bind(keys, (event) => callback(event) || false, action);
                wrapped.isWrapped = true;
            } else {
                Mousetrap.bind(keys, callback, action);
            }
        };

        Mousetrap.unbindWithHelp = function (keys, action, returnPrevious) {
            delete registeredShortcuts[keys];
            return Mousetrap.unbind(keys, action, returnPrevious);
        };

        Mousetrap.getShortcuts = function () {
            return registeredShortcuts;
        };

        Mousetrap.presentShortcut = function (keyCombinations) {
            if (keyCombinations instanceof Array) {
                // eslint-disable-next-line no-param-reassign
                keyCombinations = keyCombinations.join(", ");
            }

            const KEYS = [
                "shift",
                "up",
                "down",
                "left",
                "right",
                "backspace",
                "space",
                "mod",
                "alt",
                "esc",
                "enter",
                "del",
            ];
            return KEYS.reduce((output, key) => {
                const regex = new RegExp(`(\\+|\\s|^)${key}(\\+|\\s|$)`);
                return output.replace(regex, `$1${Language.get(`nc_key_${key}`)}$2`);
            }, keyCombinations);
        };

        Mousetrap.bindWithHelp(
            "shift+mod+alt+c",
            () => {
                window.localStorage.clear();
            },
            undefined,
            Language.get("nc_help_delete_locally_stored_information.")
        );

        // Override of default Mousetrap event stopping to allow handling of enter and escape when in input and select form elements.
        Mousetrap.stopCallback = function (e, element, combo) {
            // If the element has the class "mousetrap" then no need to stop
            if (` ${element.className} `.indexOf(" mousetrap ") > -1) {
                return false;
            }

            // Stop for input, select, and textarea. Unless it's enter or escape and not in a text area
            const isFormElement =
                element.tagName === "INPUT" ||
                element.tagName === "SELECT" ||
                element.tagName === "TEXTAREA" ||
                (element.contentEditable && element.contentEditable === "true");
            if (
                isFormElement &&
                (combo === "mod+i" ||
                    combo === "mod+s" ||
                    combo === "mod+e" ||
                    combo === "mod+k" ||
                    combo === "esc")
            ) {
                return false;
            }
            if (element.tagName === "SELECT" && (combo === "mod+c" || combo === "mod+v")) {
                return false;
            }
            if (isFormElement && element.tagName !== "TEXTAREA" && combo === "enter") {
                return false;
            }
            return isFormElement;
        };

        Mousetrap.bindWithHelp(
            "h",
            this.toggleShortcutOverlay,
            undefined,
            Language.get("nc_help_display_shortcuts.")
        );

        Mousetrap.bindWithHelp(
            "mod+i",
            () => {
                API.getSupportInfo((supportURL, supportInfoLines) => {
                    this.toggleAboutDialog(supportInfoLines, true);
                });
            },
            undefined,
            Language.get("nc_about_version_log")
        );

        Mousetrap.bindWithHelp(
            ["<", "mod+k"],
            (event) => {
                event.preventDefault();
                this.toggleCommandBar();
            },
            undefined,
            Language.get("nc_help_show_command_bar.")
        );

        Mousetrap.bindWithHelp(
            "shift+mod+b",
            (event) => {
                event.preventDefault();
                this.toggleReservationList(false);
            },
            undefined,
            Language.get("nc_help_toggle_reservation_list_vertical.")
        );

        Mousetrap.bindWithHelp(
            "shift+alt+mod+b",
            (event) => {
                event.preventDefault();
                this.toggleReservationList(true);
            },
            undefined,
            Language.get("nc_help_toggle_reservation_list_horizontal.")
        );

        Mousetrap.bindWithHelp(
            "alt+right",
            this.changeCalendar.bind(this, 1),
            undefined,
            Language.get("nc_help_select_next_calendar.")
        );
        Mousetrap.bindWithHelp(
            "alt+left",
            this.changeCalendar.bind(this, -1),
            undefined,
            Language.get("nc_help_select_previous_calendar.")
        );
        if (process.env.NODE_ENV === "development") {
            Mousetrap.bindWithHelp(
                "shift+alt+mod+t",
                this.toggleBackgroundText,
                undefined,
                "Toggle calendar background text"
            );
        }
    },

    updateViewer() {
        const ids = Viewer.loadAndClear();
        if (_.isEmpty(ids) || ids === "") {
            return;
        }
        this.openStaticReservationListAdd(ids, true);
    },

    loginFinished() {
        this.props.setResizeEndCallback(this.onResizeEnd);
        window.addEventListener("storage", this.updateViewer);
        window.addEventListener("resize", this.updateDimensions);
        if (process.env.NODE_ENV === "development") {
            TimeEdit.scratchpad = this.state.scratchpad;
            TimeEdit.showGroupManager = (requests) => {
                this.showGroupManager({ groupRequests: requests || [] }, (result) => {
                    // eslint-disable-next-line no-console
                    console.log(result);
                });
            };
            TimeEdit.switchToWeekView = () => {
                this.switchToWeekView();
            };
            TimeEdit.setWeeks = () => {
                this.setWeeks(
                    MillenniumWeek.create(
                        ["2307", "2302", "2309", "2306", "2303", "2304", "2308", "2301"],
                        Language.firstDayOfWeek,
                        Language.daysInFirstWeek
                    )
                );
            };
            TimeEdit.switchToWeekViewAndSetWeeks = () => {
                this.switchToWeekViewAndSetWeeks(
                    MillenniumWeek.create(
                        ["2307", "2302", "2309", "2306", "2303", "2304", "2308", "2301"],
                        Language.firstDayOfWeek,
                        Language.daysInFirstWeek
                    )
                );
            };
            TimeEdit.switchToWeekViewAndSetIncorrectWeeks = () => {
                this.switchToWeekViewAndSetWeeks(
                    MillenniumWeek.create(
                        ["2307", "2302", "2309", "2306", "2302", "2303", "2304", "2308", "2301"],
                        Language.firstDayOfWeek,
                        Language.daysInFirstWeek
                    )
                );
            };
            TimeEdit.setSchedulingTracks = () => {
                this.setSchedulingTracks({
                    Petronella: [
                        {
                            activityId: 5,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                            reservationId: 40,
                        },
                        {
                            activityId: 6,
                            reservationId: 42,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                        },
                    ],
                    Morgan: [
                        {
                            activityId: 7,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                            reservationId: 50,
                        },
                        {
                            activityId: 8,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                        },
                    ],
                    Flasklock: [
                        {
                            activityId: 11,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                            reservationId: 70,
                        },
                        {
                            activityId: 12,
                            reservationId: 72,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                        },
                    ],
                    Grytlock: [
                        {
                            activityId: 9,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                            reservationId: 60,
                        },
                        {
                            activityId: 10,
                            values: [{ type: "object", value: { extId: "room", value: ["r14"] } }],
                        },
                    ],
                });
            };
            TimeEdit.goToNextTrack = () => {
                this.goToNextTrack(this.state.currentTrack, this.state.mappedResult);
            };
            TimeEdit.clearschedulingTracks = () => {
                this.clearSchedulingTracks();
            };
            TimeEdit.testSCHED31 = () => {
                const typeList = ["room"];
                API.getTypesByExtid(typeList, false, (result) => {
                    // eslint-disable-next-line no-console
                    console.log("1", result);
                    API.getTypesByExtid(["room"], false, (result2) => {
                        // eslint-disable-next-line no-console
                        console.log("2", result2);
                    });
                });
                API.getTypesByExtid(typeList, false, (result) => {
                    // eslint-disable-next-line no-console
                    console.log("3", result);
                    API.getTypesByExtid(["room", "course"], false, (result2) => {
                        // eslint-disable-next-line no-console
                        console.log("4", result2);
                    });
                });
                API.getTypesByExtid(typeList, false, (result) => {
                    // eslint-disable-next-line no-console
                    console.log("5", result);
                    API.getTypesByExtid(typeList, false, (result2) => {
                        // eslint-disable-next-line no-console
                        console.log("6", result2);
                    });
                });
            };
            // _te_182, _te_82, Biffbertils bastanta bokningsgrupp
            TimeEdit.testGroupSetting = (searchString = "AQVA") => {
                this.fireEvent("app", Macros.Event.LIST_RESERVATION_GROUP, searchString);
            };
            TimeEdit.getRecentlyUsedState = () => {
                return TimeEdit.State.get().user.recentlyUsedState;
            };
            TimeEdit.setRecentlyUsed = (typeId, checked) => {
                const user = TimeEdit.State.get().user;
                TimeEdit.State.replace(user, user.setRecentlyUsedChecked(typeId, checked));
            };
            TimeEdit.clearRecentlyUsed = () => {
                const user = TimeEdit.State.get().user;
                TimeEdit.State.replace(user, user.clearRecentlyUsedChecks());
            };
        }

        primaryFieldManager = new PrimaryFieldManager(this.registerMacro, this.fireEvent);

        const results: any = {};
        const calls = [
            (done) => {
                API.getStartupData((data) => {
                    // eslint-disable-next-line no-console
                    console.log(data);
                    results.viewId = data.view ? data.view.id : View.DEFAULT_VIEW;
                    results.layerId = data.activeLayer;
                    if (process.env.NODE_ENV === "development") {
                        TimeEdit.colorDefs = data.colorDefs;
                    }
                    results.colorDefs = data.colorDefs;
                    results.useNewReservationGroups = data.useNewReservationGroups;
                    results.timezones = data.timezones || [];
                    TimeEdit.rootType = data.specialTypes[0].id;
                    TimeEdit.locationTypes = data.specialTypes[3].map((type) => type.id);
                    TimeEdit.reservationTextField = data.specialFields[0];
                    TimeEdit.isEmailActive = data.supportsEmail;
                    if (process.env.NODE_ENV === "development") {
                        TimeEdit.isEmailActive = true;
                    }
                    TimeEdit.entryPropertyDefinitions = data.propertyDefs;
                    if (data.supportsExceptions || process.env.NODE_ENV === "development") {
                        results.hasReservationExceptions = true;
                    } else {
                        results.hasReservationExceptions = false;
                    }
                    const texts = [...data.backgroundText];
                    if (process.env.BRANCH) {
                        texts.push(process.env.BRANCH);
                    }
                    TimeEdit.calendarBackgroundTexts = texts;
                    const version = { ...data.versionInfo };
                    const versionString = version.server.version;
                    const versionParts = versionString.split(".");
                    version.server.major = parseInt(versionParts[0], 10);
                    version.server.minor =
                        versionParts.length > 1 ? parseInt(versionParts[1], 10) : 0;
                    TimeEdit.serverInfo = version;
                    if (data.pinboardObjects) {
                        let pinboard = new Scratchpad(this.onPinboardChanged);
                        pinboard.setObjects(JSON.parse(data.pinboardObjects));
                        this.setState({ scratchpad: pinboard });
                        if (process.env.NODE_ENV === "development") {
                            TimeEdit.scratchpad = pinboard;
                        }
                    }

                    this.updateWindowTitle(this.props);
                    if (data.lastFluffy) {
                        let lastFluffy;
                        if (data.lastFluffy) {
                            lastFluffy = JSON.parse(data.lastFluffy);
                        }
                        const selection = TimeEdit.State.get().selection;
                        if (
                            lastFluffy &&
                            (lastFluffy.template_kind === undefined ||
                                lastFluffy.template_kind === TemplateKind.RESERVATION)
                        ) {
                            API.getObjectNames(
                                lastFluffy.objects
                                    .map((obj, index) => ({
                                        id: obj.id,
                                        type: lastFluffy.types[index].id,
                                    }))
                                    .filter((id) => id !== 0),
                                false,
                                (namedObjects) => {
                                    TimeEdit.State.replace(
                                        selection,
                                        selection.setFluffy(
                                            McFluffy.create(lastFluffy, namedObjects)
                                        )
                                    );
                                    done();
                                }
                            );
                        } else {
                            selection.createFluffy(null, 0, null, (newSelection) => {
                                TimeEdit.State.replace(selection, newSelection);
                                done();
                            });
                        }
                    } else {
                        done();
                    }
                });
            },

            (done) => {
                User.getCurrent((user) => {
                    const app = TimeEdit.State.get();
                    const menu = app.menu;
                    // eslint-disable-next-line no-param-reassign
                    user.useTouch = false;
                    TimeEdit.State.update(app, { user });
                    if (user.biggerFont) {
                        document.body.classList.add("bigger");
                    }
                    if (user.isAdmin) {
                        const newItems = [
                            ...menu.items,
                            {
                                id: "admin",
                                label: "user_permission_admin",
                            },
                        ];

                        const newMenu = new MenuModel(newItems);
                        newMenu.freeze();
                        newMenu.updateFromPreferences((newerMenu) => {
                            TimeEdit.State.update(menu, newerMenu);
                        });
                    } else {
                        menu.updateFromPreferences((newMenu) => {
                            TimeEdit.State.update(menu, newMenu);
                        });
                    }
                    this.setState({
                        showSelection: user.showLists,
                        menuPosition: user.menuPosition,
                    });
                    done();
                });
            },

            (done) => {
                API.getUserLocale(getLanguageString(), (result) => {
                    const res = result.parameters;
                    Language.setData(
                        res[0].values,
                        res[1].values,
                        MillenniumWeekday.getByRepresentation(
                            res[1].values.date_p_first_day_of_week
                        ),
                        parseInt(res[1].values.date_p_number_of_days_in_first_week, 10),
                        parseInt(res[1].values.date_p_has_support_for_week_numbers, 10)
                    );
                    SimpleDateFormat.setLocale(Language);
                    this.setupMousetrap();
                    this.registerMacros();
                    done();
                });
            },
            (done) => {
                API.getReservationLayers(LAYER_KIND_EXPERIMENT, (result) => {
                    if (result.length > 0) {
                        results.myLayers = result;
                        API.getPreferences("moveLayers", (moveLayers) => {
                            results.moveLayers = moveLayers === null ? {} : JSON.parse(moveLayers);
                            done();
                        });
                    } else {
                        results.myLayers = [];
                        results.moveLayers = [];
                        const HELP_TIMEOUT = 2000;
                        setTimeout(() => this.setupHelp(), HELP_TIMEOUT);
                        done();
                    }
                });
            },
            (done) => {
                if (!this.props.usingSSO) {
                    API.hasExternalPermissions(["prefsincore", "examincore"], (result) => {
                        if (result[0] === true || process.env.NODE_ENV === "development") {
                            results.hasPrefsComponent = true;
                        }
                        if (result[1] === true || process.env.NODE_ENV === "development") {
                            results.hasExamComponent = true;
                        }
                        done();
                    });
                } else {
                    // todo: once exam feel ready, check for exam scope like we
                    // do for AM rather than sending bellow request to the server
                    API.hasExternalPermissions(["examincore"], (result) => {
                        if (result[0] === true || process.env.NODE_ENV === "development") {
                            results.hasExamComponent = true;
                        }
                        results.hasPrefsComponent = hasPermissions(
                            this.props.token.appPermissions,
                            this.props.token.scopes,
                            {
                                scopes: ["TE_AM::user"],
                            }
                        );
                        done();
                    });
                }
            },
            (done) => {
                API.targetExternalAppsEnvironment((envSettings) => {
                    results.targetExternalAppsEnvironment = envSettings;
                    if (process.env.NODE_ENV === "development") {
                        results.examIdField = 200;
                    }
                    let finalEnv =
                        process.env.NODE_ENV === "development"
                            ? "beta"
                            : envSettings.exam || "beta";
                    if (
                        finalEnv === "beta" &&
                        window.location.pathname.indexOf("beta_nl_uu") !== -1
                    ) {
                        finalEnv = "production";
                    }
                    if (
                        this.props.env.platformVersion &&
                        this.props.env.platformVersion.indexOf("2") !== 0
                    ) {
                        finalEnv = this.props.env.serverEnv;
                    }
                    API.getExamSettings(
                        finalEnv,
                        this.props.env.platformVersion,
                        (es) => {
                            // eslint-disable-next-line no-console
                            console.log("Got Exam settings for environment", finalEnv, es);
                            if (es.appSettings || es.paramMappings) {
                                // If no error code
                                // Dig out the Exam settings
                                if (es.appSettings) {
                                    // Platform v2 and lower
                                    const examSettings = _.find(
                                        es.appSettings,
                                        (setting) => setting.appName === "teExam"
                                    );
                                    if (
                                        examSettings &&
                                        examSettings.examInCore &&
                                        examSettings.examInCore.examId
                                    ) {
                                        // Dig out the relevant settings from there
                                        API.getFieldsByExtid(
                                            [examSettings.examInCore.examId.fieldExt],
                                            (fields) => {
                                                if (fields && fields[0]) {
                                                    results.examIdField = fields[0].id;
                                                    // eslint-disable-next-line no-console
                                                    console.log(results.examIdField);
                                                }
                                                done();
                                            }
                                        );
                                        // results.examTemplate = settings.examInCore.examTemplate
                                    } else {
                                        done();
                                    }
                                } else if (es.paramMappings) {
                                    // Platform v3 and later
                                    const examSettings = es.paramMappings.find(
                                        (setting) => setting.appFieldId === "exam_id"
                                    );
                                    if (
                                        examSettings &&
                                        examSettings.fieldExt &&
                                        examSettings.fieldExt !== null &&
                                        examSettings.fieldExt !== ""
                                    ) {
                                        // Dig out the relevant settings from there
                                        API.getFieldsByExtid([examSettings.fieldExt], (fields) => {
                                            if (fields && fields[0]) {
                                                results.examIdField = fields[0].id;
                                                // eslint-disable-next-line no-console
                                                console.log(results.examIdField);
                                            }
                                            done();
                                        });
                                    } else {
                                        done();
                                    }
                                } else {
                                    done();
                                }
                            } else {
                                // eslint-disable-next-line no-console
                                switch (es.code || es.status) {
                                    case EXAM_NOT_AUTHORIZED:
                                        // eslint-disable-next-line no-console
                                        console.log(
                                            `Not authorized to access Exam environment '${
                                                envSettings.exam || "beta"
                                            }'. Ask engineering to check tokens.`
                                        );
                                        break;
                                    case EXAM_NOT_FOUND:
                                        // eslint-disable-next-line no-console
                                        console.log(
                                            `The customer signature '${
                                                TimeEdit.serverInfo
                                                    ? TimeEdit.serverInfo.server.signature
                                                    : "unknown"
                                            }' has not been set up in Exam environment '${
                                                envSettings.exam || "beta"
                                            }'. Perhaps it lacks an initial 'cloud_'?`
                                        );
                                        break;
                                    default:
                                        // eslint-disable-next-line no-console
                                        console.log(es.code || es.status);
                                }
                                // eslint-disable-next-line no-console
                                console.log(es.code || es.status);
                                done();
                            }
                        },
                        // eslint-disable-next-line no-unused-vars
                        (error) => {
                            // eslint-disable-next-line no-console
                            console.log("Error getting Exam settings", error);
                            done();
                        }
                    );
                });
            },
            (done) => {
                API.findSumSettings((result) => {
                    API.getSumSetting(result[0], (filters) => {
                        results.filters = filters;
                        done();
                    });
                });
            },
            (done) => {
                const DAYS_BACK = 365;
                const DAYS_AHEAD = 1095;
                API.showEnableCustomWeekNames((result) => {
                    results.showEnableCustomWeekNames = result;
                    if (result === true) {
                        API.getCustomWeekNames(
                            MillenniumDate.today().getDayNumber() - DAYS_BACK,
                            MillenniumDate.today().getDayNumber() + DAYS_AHEAD,
                            (customWeekNames) => {
                                results.customWeekNames = customWeekNames.filter(
                                    (weekName) => !weekName.isEmpty()
                                );
                                done();
                            }
                        );
                    } else {
                        done();
                    }
                });
            },
        ];

        _.runSync(calls, () => {
            this.setView(results.viewId);
            this.moveLayers = results.moveLayers;
            if (results.layerId) {
                // eslint-disable-next-line no-alert
                alert(Language.get("nc_dialog_working_in_draft_help"));
                this.setActiveLayer(results.layerId);
            }
            this.setState(
                {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    colorDefs: results.colorDefs,
                    prefsComponentActive: results.hasPrefsComponent || false,
                    examComponentActive: results.hasExamComponent || false,
                    useNewReservationGroups: results.useNewReservationGroups || false,
                    memberCreatorActive:
                        process.env.NODE_ENV === "development" ||
                        this.props.env.serverEnv === "beta" ||
                        this.props.env.serverEnv === "staging" ||
                        this.props.env.serverEnv === "test",
                    targetExternalAppsEnvironment: results.targetExternalAppsEnvironment,
                    hasReservationExceptions: results.hasReservationExceptions,
                    filters: results.filters,
                    showEnableCustomWeekNames: results.showEnableCustomWeekNames,
                    customWeekNames: this.props.user.useCustomWeekNames
                        ? results.customWeekNames || []
                        : [],
                    layers: results.myLayers,
                    examIdField: results.examIdField || undefined,
                    examTemplate: results.examTemplate || undefined,
                    timezones: results.timezones || [],
                    loadFinished: true,
                },
                () => {
                    setupMixpanel(this.props.user, this.props.env.serverEnv);
                    // eslint-disable-next-line no-undef
                    mixpanel.track("Core loaded", {});
                }
            );
        });
    },

    componentDidMount() {
        // If we're not using SSO, login is finished on mount. If we are using SSO, login is finished when we have a token
        if (
            this.props.usingSSO === true &&
            this.props.token === null &&
            this.props.logoutAuth === true
        ) {
            this.doLogout();
            return;
        }

        if ((this.props.usingSSO === true && this.props.token) || !this.props.usingSSO) {
            API.getMessageDispatcher().onLogout = this.doLogout;
            this.loginFinished();
        }
    },

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.usingSSO === true &&
            this.props.token === null &&
            this.props.logoutAuth === true
        ) {
            this.doLogout();
            return;
        }
        if (this.props.usingSSO === true && this.props.token && !prevProps.token) {
            this.loginFinished();
        } else if (
            !this.props.usingSSO ||
            (this.props.usingSSO && this.props.token && prevProps.token)
        ) {
            // What we want to say here: run the usual componentDidUpdate if not using SSO, or if using SSO and already logged in
            this.doUpdateComponent(prevProps, prevState);
        }
    },

    doUpdateComponent(prevProps) {
        if (prevProps.view !== this.props.view) {
            this.updateWindowTitle(this.props);
        }

        if (
            prevProps.user &&
            !prevProps.user.useInfoPopover &&
            this.props.user.useInfoPopover &&
            this.props.menu.isOpen
        ) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.close());
            this.setState({ reservationInfoIds: [] });
        }
        if (prevProps.user && !this.props.user.useInfoPopover && prevProps.user.useInfoPopover) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.open("info"));
        }

        if (this.props.view === prevProps.view || !this.props.view || !prevProps.view) {
            return;
        }

        if (this.props.view.id !== prevProps.view.id) {
            this.setState({ activeCalendar: 0 });
            return;
        }

        const children = this.props.view.section.getAllChildren();
        if (this.state.activeCalendar >= children.length) {
            this.setState({ activeCalendar: 0 });
            return;
        }

        if (this.props.view && (!prevProps.view || this.props.view.id !== prevProps.view.id)) {
            API.getPreferences("activeCalendar", [this.props.view.id], (result) => {
                if (result && result.length > 0) {
                    this.setActiveCalendar(JSON.parse(result[0]));
                } else {
                    this.setActiveCalendar(
                        this.props.view.section
                            .getAllChildren()
                            .findIndex((child) => child.selected === true)
                    );
                }
            });
        }

        if (this.state.updateAvailable) {
            this._lastComponentActivity = Date.now();
        }
    },

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("storage", this.updateViewer);
        this.deregisterMacro(`primaryFieldManager`);
        this.deregisterMacro("app");
    },

    shouldIndicateUpdate() {
        const shouldIndicateUpdate =
            this.state.updateAvailable !== null &&
            Date.now() >= this.state.updateAvailable + UpdateTimeConstants.INDICATE_UPDATE;

        if (shouldIndicateUpdate && !this._indicationEventRecorded) {
            this._indicationEventRecorded = true;
        }

        return shouldIndicateUpdate;
    },

    onResizeEnd() {
        const height = Math.max(
            this.state.height - _.getClientPos(this.props.lastMousePosition).y,
            this.MIN_BELOWBAR_HEIGHT
        );
        this.setState({
            selectionHeight: height,
        });
    },

    handleEntryInfoOpen(entry, showPanel = false, editMode = false) {
        let reservationInfoIds = [];
        if (_.isArray(entry)) {
            reservationInfoIds = entry.map((etr) => {
                if (etr.reservationids) {
                    return etr.reservationids;
                }
                return etr;
            });
        } else {
            reservationInfoIds = entry ? entry.reservationids : [];
        }

        // If ids are passed instead of an entry, there might be multiple reservations
        // that are not clustered.
        const isEntryInfo = !_.isArray(entry);

        this.setState({
            isInfoEntryEdited: editMode,
            reservationInfoIds,
            isEntryInfo,
            multiPanelOrder: pushToFront(this.state.multiPanelOrder, "reservation"),
        });
        if (showPanel) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.open("info"));
        }
    },

    handleEditReservationExceptions(entry) {
        let ids = entry.reservationids;
        const selectionGroup = this.getActiveCalendar().selectionGroup;
        if (selectionGroup.length > 1) {
            ids = _.flatten(selectionGroup.map((etr) => etr.reservationids));
        }
        this.presentModernModal(
            <ExceptionEditor
                reservationIds={ids}
                onClose={() => {
                    this.fireEvent(`app`, Macros.Event.RESERVATION_MADE_OR_MODIFIED, [ids]);
                    // Set reservationInfoIds explicitly, causing the info panel to update. Components don't (and shouldn't) react to events sent by themselves
                    this.setState({
                        reservationInfoIds: ids,
                    });
                    this.handleDismissModal();
                }}
                user={TimeEdit.State.get().user}
            />,
            null,
            `${Language.get("nc_reservation_exception_title")} (${
                entry.reservationids.length
            } ${Language.get("admin_table_unit_reservation")})`,
            "NO_BUTTONS"
        );
    },

    handleOrderInfoOpen(orderIds, openIfClosed = true) {
        this.setState({
            orderInfoIds: orderIds,
            multiPanelOrder: pushToFront(this.state.multiPanelOrder, "order"),
        });
        if (openIfClosed) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.open("info"));
        }
    },

    onReservationEditChange(isEdited = true) {
        this.setState({ isInfoEntryEdited: isEdited });
    },

    _dynamicReservationInfoTimeout: null,

    onDynamicReservationIdsChanged(newDynamicIds = []) {
        clearTimeout(this._dynamicReservationInfoTimeout);
        this._dynamicReservationInfoTimeout = setTimeout(() => {
            this.setState({ dynamicReservationIds: newDynamicIds });
        }, FIVEHUNDRED_MILLIS);
    },

    updateInfoMenuState() {
        if (
            this.state.reservationInfoIds.length === 0 &&
            this.state.objectIds.length === 0 &&
            this.state.orderInfoIds.length === 0 &&
            this.state.dynamicReservationIds.length === 0
        ) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.close());
        }
    },

    onReservationInfoClose() {
        this.setState({
            reservationInfoIds: [],
        });
    },

    onDynamicReservationInfoClose() {
        this.setState({
            dynamicReservationIds: [],
        });
    },

    onObjectInfoClose() {
        this.setState({
            types: [],
            objectIds: [],
        });
    },

    onOrderInfoClose() {
        this.setState({
            orderInfoIds: [],
        });
    },

    onLayerCreated(layerId, move) {
        if (move !== undefined) {
            this.moveLayers[layerId] = move;
        } else {
            delete this.moveLayers[layerId];
        }
        const allLayerIds = this.state.layers.map((lr) => lr.id);
        Object.keys(this.moveLayers).forEach((key) => {
            const kv = parseInt(key, 10);
            if (allLayerIds.indexOf(kv) === -1) {
                // eslint-disable-next-line no-console
                console.log("Deleting", key);
                delete this.moveLayers[key];
            }
        });
        API.setPreferences("moveLayers", [JSON.stringify(this.moveLayers)], _.noop);
        this.reloadLayers(() => {
            this.setActiveLayer(layerId || 0);
        });
    },

    setActiveLayer(layerId = 0) {
        this.setState(
            {
                activeLayer: layerId || 0,
            },
            () => {
                API.setPreferences("activeLayer", [layerId], _.noop);
                const updatedSelection = this.props.selection.isEditMode()
                    ? this.props.selection.disableEditMode().setLayer(layerId)
                    : this.props.selection.setLayer(layerId);
                const updatedFluffy = updatedSelection.fluffy;
                const saveFluffy = updatedFluffy.toJson();
                saveFluffy.labels = updatedFluffy.labels;
                API.setPreferences("currentFluffy", [JSON.stringify(saveFluffy)], _.noop);
                TimeEdit.State.replace(this.props.selection, updatedSelection);
            }
        );
    },

    reloadLayers(callback = _.noop) {
        API.getReservationLayers(LAYER_KIND_EXPERIMENT, (result) => {
            this.setState({ layers: result }, callback);
        });
    },

    getLayerName(suggestedName) {
        // eslint-disable-next-line no-alert
        let name = doPrompt(Language.get("nc_draft_name_(must_be_unique)"), suggestedName || "");
        // eslint-disable-next-line no-loop-func
        const maxLength = 31;
        let length = new TextEncoder().encode(name).length;
        // eslint-disable-next-line no-loop-func
        while (_.any(this.state.layers, (layer) => layer.name === name) || length > maxLength) {
            if (length > maxLength) {
                // eslint-disable-next-line no-alert
                name = doPrompt(
                    Language.get("err_layer_name_too_long", maxLength, maxLength),
                    name
                );
            } else {
                // eslint-disable-next-line no-alert
                name = doPrompt(Language.get("nc_draft_name_(must_be_unique)"), name);
            }
            length = new TextEncoder().encode(name).length;
        }
        if (name === "" || _.isNullish(name)) {
            // The user hit cancel in the dialog
            return false;
        }
        return name;
    },

    reportProgress(newProgress) {
        this.setState(newProgress);
    },

    performExperiment(ignoreUnchangedReservations = false, allowDoubleReservations = false) {
        const persist = () => {
            Experiment.persist(
                this.state.activeLayer,
                ignoreUnchangedReservations,
                allowDoubleReservations,
                this.reportProgress,
                this.presentModal,
                this.setActiveLayer,
                (layerId) => {
                    this.fireEvent(`app`, Macros.Event.RESERVATION_MADE_OR_MODIFIED, [true]);
                    this.onLayerCreated(layerId);
                }
            );
        };
        if (!allowDoubleReservations) {
            persist();
        } else {
            this.presentModal(
                <p>{Language.get("nc_mass_change_allow_double_warning")}</p>,
                null,
                Language.get("cal_selected_allow_double_res"),
                [
                    { title: Language.get("dialog_cancel") },
                    { title: Language.get("cal_res_below_ok"), cb: persist },
                ],
                true
            );
        }
    },

    cancelExperiment() {
        Experiment.cancel(
            this.state.activeLayer,
            this.reportProgress,
            this.presentModal,
            this.onLayerCreated
        );
    },

    setOrder(order, descriptionField, commentField) {
        this.handleOrderInfoOpen([order.id], false);
        const selection = this.props.selection;
        API.getTemplateGroups(selection.fluffy.templateKind.number, (templateGroups) => {
            const templateGroupIds = templateGroups.parameters[0].map((group) => group.id);
            let setResult: any = false;
            let labels = false;
            const fields: { id: number; values: string[] }[] = [];
            if (!_.isNullish(descriptionField)) {
                fields.push({ id: descriptionField, values: [order.description] });
            }
            if (!_.isNullish(commentField)) {
                fields.push({ id: commentField, values: [order.comment] });
            }

            _.runAsync(
                [
                    (done) => {
                        API.setOrderRowToMcFluffy(
                            order.id,
                            selection.fluffy.toJson(),
                            templateGroupIds,
                            (result) => {
                                setResult = result;
                                done();
                            }
                        );
                    },
                    (done) => {
                        const ids = order.objects.concat(order.extra_objects || []).map((obj) => ({
                            id: obj.id,
                            type: obj.type.id,
                        }));
                        API.getObjectNames(ids, false, (result) => {
                            labels = result;
                            done();
                        });
                    },
                ],
                () => {
                    // What do do if !success (result.parameters[1])
                    if (setResult.parameters[1]) {
                        const newFluffy = McFluffy.create(setResult.parameters[0], labels);
                        newFluffy.rules = setResult.parameters[2];
                        if (fields.length > 0) {
                            TimeEdit.State.update(
                                selection,
                                selection.setFluffy(newFluffy.setFields(fields))
                            );
                        } else {
                            TimeEdit.State.update(selection, selection.setFluffy(newFluffy));
                        }
                        this.focusBestCalendar();
                        // Adjusting visible calendar period is done by the calendar listening to the same event
                    }
                }
            );
        });
    },

    handleObjectsSaved(objectIds) {
        if (this.state.objectRequestCallback) {
            this.state.objectRequestCallback(objectIds);
            this.setState({ objectRequestCallback: null });
        }
        this.reloadFunctions.forEach((reloadFunction) => reloadFunction());
    },

    handleFluffyItemChanged(newFluffyItem) {
        this.setState({ currentFluffyItem: newFluffyItem });
    },

    mapIds(idList) {
        let ids: number[] = [];
        if (idList instanceof Array) {
            ids = idList.map((item) => item.id || item);
        } else if (idList instanceof Object) {
            ids = [idList.id];
        } else {
            ids = [idList]; // Naked ID
        }
        return ids;
    },

    handleNewObject(typeId, title) {
        this.showObjectManager(this.mapIds(typeId), [], [], false, false, title);
    },

    handleObjectInfo(object, createCopy = false, viewOnly = false, title) {
        const objectIds = this.mapIds(object);
        // eslint-disable-next-line no-undef
        /*mixpanel.track("Object info", {
            "Number of objects": objectIds.length,
            "Viewing only": viewOnly,
            "Copying object": createCopy,
        });*/
        this.showObjectManager([], objectIds, [], createCopy, viewOnly, title);
    },

    handleSetVisibleCalendarIds(value) {
        this.setState({ showCalendarIds: value });
    },

    handleViewChange(id) {
        this.setView(id);
    },

    setView(id) {
        View.find(id, (newView) => {
            if (newView instanceof Error) {
                // eslint-disable-next-line no-console
                console.error(newView);
                // eslint-disable-next-line no-alert
                window.alert(newView.message);
            }

            if (!newView || newView instanceof Error) {
                View.find(View.DEFAULT_VIEW, (defaultView) => {
                    // eslint-disable-next-line no-param-reassign
                    if (defaultView instanceof Error) {
                        // eslint-disable-next-line no-console
                        console.error(defaultView);
                        // eslint-disable-next-line no-alert
                        window.alert("Could not load default view, please contact support.");
                        TimeEdit.State.update(TimeEdit.State.get().setView(null));
                        return;
                    }
                    defaultView.id = id;
                    API.setPreferences("view", [{ class: "viewid", id }], _.noop);
                    TimeEdit.State.update(TimeEdit.State.get().setView(defaultView));
                });
            } else {
                API.setPreferences("view", [{ class: "viewid", id: newView.id }], _.noop);
                TimeEdit.State.update(TimeEdit.State.get().setView(newView));
            }

            return null;
        });
    },

    hasCalendarWithIndex(index) {
        return this.props.view.section.getAllChildren().length > index;
    },

    setActiveCalendar(id) {
        const activeCalendar = this.hasCalendarWithIndex(id) ? id : 0;
        this.setState({ activeCalendar });
    },

    handleActiveCalendarChange(id) {
        API.setPreferences("activeCalendar", [this.props.view.id], [JSON.stringify(id)], _.noop); // Intentional stringify, otherwise index 0 won't get saved.
        this.setActiveCalendar(id);
    },

    handleDragEnd() {
        const user = TimeEdit.State.get().user;
        if (this.state.menuPosition !== user.menuPosition) {
            this.setState({ menuPosition: user.menuPosition });
        }
    },

    handleDrop(event) {
        if (!_.isEventDragDataOfType(event, ["application/x-timeedit-menu"])) {
            return;
        }
        event.preventDefault();
        const user = TimeEdit.State.get().user;
        if (this.state.menuPosition !== user.menuPosition) {
            TimeEdit.State.update(user, user.setMenuPosition(this.state.menuPosition));
        }
    },

    setObjectManagerCloseCheck(checkFunction) {
        this._objectManagerCloseCheck = checkFunction;
    },

    handleToggleSelection(event) {
        API.setPreferences("showLists", [!this.state.showSelection], _.noop);
        this.setState({ showSelection: !this.state.showSelection });
        if (event) {
            event.preventDefault();
        }
    },

    handleDismissModal() {
        this.setState({ modalDialogVisible: false, modalDialog: null });
    },

    handleDismissSecondModal() {
        this.setState({ secondModalDialogVisible: false, secondModalDialog: null });
    },

    isRequest(reservation) {
        if (!reservation.status) {
            return false;
        }
        return reservation.status.some(
            (status) => status.status === ReservationStatus.C_REQUESTED.id
        );
    },

    registerMacros() {
        this.registerMacro("app", {
            events: [Macros.Event.SELECT_ORDER],
            actions: [
                {
                    key: Macros.Action.SET_ORDER,
                    action: (order, descriptionField, commentField) => {
                        this.setOrder(order, descriptionField, commentField);
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.CHANGE_RESERVATION_TIME],
            actions: [
                {
                    key: Macros.Action.CHANGE_RESERVATION_TIME,
                    action: (reservationIds, skipConfirmation = false, isTemporary = false) => {
                        const self = this;
                        _prevEscBinding = Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE, true);
                        Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, () => {
                            self.endLockMode();
                        });
                        this.setState({
                            unlockedReservations: reservationIds,
                            skipConfirmation,
                            temporaryUnlock: isTemporary,
                        });
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.RESERVATION_MADE_OR_MODIFIED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    fireLast: true,
                    action: (
                        reservationIds = [],
                        isNewManualReservation = false,
                        isMadeByExam = false
                    ) => {
                        if (this.state.reservationInfoIds.length && reservationIds === undefined) {
                            return;
                        }
                        if (reservationIds.length === 0) {
                            return;
                        }
                        this.disableTemporarySingleClick();
                        // Inform AM if working with a track
                        if (this.state.currentTrack && isNewManualReservation === true) {
                            this.mapReservationsToActivities(reservationIds, (mappedResult) => {
                                if (mappedResult) {
                                    this.state.trackSchedulingCallback({
                                        action: AM_ACTION.SCHEDULE,
                                        payload: mappedResult,
                                    });
                                }
                            });
                        }
                        if (
                            isMadeByExam !== true &&
                            isNewManualReservation !== true &&
                            this.state.examIdField &&
                            this.state.examComponentActive
                        ) {
                            API.exportReservations(reservationIds, true, (reservations) => {
                                reservations.forEach((reservation) => {
                                    if (hasExamId(reservation, this.state.examIdField)) {
                                        if (
                                            // eslint-disable-next-line no-alert
                                            window.confirm(Language.get("nc_update_exam_request"))
                                        ) {
                                            // Open exam plugin
                                            this.createExamRequest(reservation, EXAM_MODE.MOVE);
                                        }
                                    }
                                });
                            });
                        }
                        if (
                            isNewManualReservation === true &&
                            this.state.activityManagerReservationCallback !== null
                        ) {
                            const amCallback = this.state.activityManagerReservationCallback;
                            this.setState(
                                {
                                    activityManagerReservationCallback: null,
                                    reservationInfoIds: reservationIds,
                                },
                                () => {
                                    amCallback(reservationIds);
                                }
                            );
                        } else {
                            this.setState({
                                reservationInfoIds: reservationIds,
                            });
                        }
                        // If a number of reservations have been refreshed, I would like to reload the info display if it contains any of said reservations
                        // I don't really want to set it to the new list, it could be anything!
                        // Guess info panel would need to pass a callback function up to provide reload regardless of id changes
                        // If I do that, should I even set ids here? On a refresh I'd assume I just want to update what's present.
                        // Okay, guess I might want to show a completely new reservation as well?
                        // See https://timeedit.atlassian.net/browse/DEV-1899
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.CLEAR_SELECTION],
            actions: [
                {
                    key: Macros.Action.CLEAR_SELECTION,
                    action: () => {
                        if (this.state.activityManagerReservationCallback !== null) {
                            this.setState({ activityManagerReservationCallback: null });
                        }
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.SELECT_REQUEST],
            actions: [
                {
                    key: Macros.Action.SET_REQUEST,
                    action: () => {
                        this.focusFirstCalendar();
                    },
                },
            ],
        });

        this.registerMacro(`app`, {
            events: [Macros.Event.SET_EXTERNAL_TEMPLATE_KIND],
            actions: [
                {
                    key: Macros.Action.SET_EXTERNAL_TEMPLATE_KIND,
                    action: (templateKindId, callback) => {
                        const allCalendars = this.props.view.section.getAllChildren();
                        const firstCalendarIndex = allCalendars.findIndex(
                            (calendar) => calendar instanceof Calendar
                        );
                        const calendar = allCalendars[firstCalendarIndex];
                        if (calendar) {
                            TimeEdit.State.update(
                                calendar,
                                calendar.setTemplateKind(templateKindId)
                            );
                        }
                        if (callback && _.isFunction(callback)) {
                            callback();
                        }
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.REQUEST_OPERATION],
            actions: [
                {
                    key: Macros.Action.REQUEST_OPERATION,
                    action: ({ operationType, data, callback }) => {
                        //console.log(operationType, data);
                        if (operationType === REQUEST_TYPES.NEW_OBJECT) {
                            this.showObjectManager(
                                data.types,
                                [],
                                [],
                                false,
                                false,
                                Language.get("cal_selected_new_object"),
                                data.fields,
                                callback
                            );
                        }
                        if (operationType === REQUEST_TYPES.EDIT_OBJECT) {
                            this.showObjectManager(
                                data.types,
                                data.objectIds,
                                data.incompleteObjects,
                                false,
                                false,
                                Language.get(`cal_avail_list_show_object_info`),
                                data.fields,
                                callback
                            );
                        }
                        if (operationType === REQUEST_TYPES.MISSING_OBJECT) {
                            this.showObjectManager(
                                data.types,
                                [],
                                [],
                                false,
                                false,
                                Language.get("nc_missing_object"),
                                data.fields,
                                callback
                            );
                        }
                        if (operationType === OPERATION_TYPES.CREATE_RESERVATION) {
                            this.clearSchedulingTracks();
                            this.addReservationListener(callback);
                        }
                        if (operationType === OPERATION_TYPES.CREATE_OBJECTS) {
                            this.showGroupManager(data, callback);
                        }
                        if (operationType === OPERATION_TYPES.SET_WEEKS) {
                            if (data.tracks) {
                                this.cancelManualReservationOperation(() => {
                                    this.switchToWeekViewAndSetWeeks(data.weeks, () => {
                                        this.setSchedulingTracks(
                                            data.tracks,
                                            data.onScheduleActivity
                                        );
                                    });
                                });
                            } else {
                                this.switchToWeekViewAndSetWeeks(data.weeks);
                            }
                        }
                        if (operationType === OPERATION_TYPES.CANCEL_AM_SCHEDULING) {
                            this.clearSchedulingTracks();
                            this.cancelManualReservationOperation();
                            this.disableTemporarySingleClick();
                        }
                    },
                },
            ],
        });

        this.registerMacro("app", {
            events: [Macros.Event.AM_CLOSED],
            actions: [
                {
                    key: Macros.Action.AM_CLOSED,
                    action: () => {
                        this.clearSchedulingTracks();
                    },
                },
            ],
        });
    },

    registerMacro(...args) {
        this.setState((state) => {
            const macros = state.macros.register.apply(state.macros, args);
            return { macros };
        });
    },

    fireEvent(id, event, ...args) {
        const actions = this.state.macros.getMatchingActions(id, event);
        setTimeout(
            () =>
                actions.forEach((action) => {
                    action.apply(null, args.concat(id));
                }),
            1
        );
    },

    deregisterMacro(...args) {
        this.setState((state) => {
            const macros = state.macros.deregister.apply(state.macros, args);
            return { macros };
        });
    },

    addReservationListener(callback) {
        this.setState({ activityManagerReservationCallback: callback });
    },

    cancelManualReservationOperation(callback = _.noop) {
        TimeEdit.State.update(
            this.props.selection,
            this.props.selection.immutableSet({ duration: false })
        );
        if (this.state.activityManagerReservationCallback) {
            this.setState({ activityManagerReservationCallback: null }, callback);
        } else {
            callback();
        }
    },

    disableTemporarySingleClick() {
        if (this.props.user.allowTemporarySingleClickReservation) {
            TimeEdit.State.update(
                this.props.user,
                this.props.user.immutableSet({
                    allowTemporarySingleClickReservation: false,
                })
            );
        }
    },

    switchToWeekView(onFinished) {
        const allCalendars = this.props.view.section.getAllChildren();
        const index = allCalendars.findIndex((calendar) => calendar instanceof Calendar);
        const weekdayIndex = anyCalendarHasWeekdayHeader(allCalendars);
        if (weekdayIndex && weekdayIndex > -1) {
            this.setActiveCalendar(weekdayIndex);
            if (onFinished) {
                onFinished();
            }
            return;
        }

        if (index !== -1) {
            const calendar = allCalendars[index];
            const header = new WeekdayHeader(TimeConstants.DAYS_PER_WEEK, 0);
            header.showInfo = true;
            TimeEdit.State.update(
                calendar,
                calendar.setHeader(true, header.freeze().setLimits(calendar.limits))
            );
            this.setActiveCalendar(index);
            if (onFinished) {
                onFinished();
            }
        }
    },

    switchToWeekViewAndSetWeeks(weeks, callback = _.noop) {
        this.switchToWeekView(() => {
            this.setWeeks(weeks, callback);
        });
    },

    setWeeks(weeks, callback = _.noop) {
        const allCalendars = this.props.view.section.getAllChildren();
        const calendar = allCalendars.find((cal) => {
            if (cal instanceof Calendar) {
                const headers = cal.getHeaderTypeMap();
                if (headers.weekday) {
                    return true;
                }
                return false;
            }
            return false;
        });
        if (calendar) {
            const providers = calendar.getProviderMap();
            if (providers.weekday) {
                // Completely redundant check? Should we check something else?
                const headers = calendar.getHeaderTypeMap();
                const provider = headers.weekday;
                TimeEdit.State.update(
                    provider,
                    provider.immutableSet({ weeks: _.sortBy(weeks, "_weekWithYear") })
                );
            }
        }
        callback();
    },

    focusFirstCalendar(publicOnly = true) {
        const allCalendars = this.props.view.section.getAllChildren();
        let firstPublicIndex = allCalendars.findIndex(
            (calendar) =>
                calendar instanceof Calendar && !calendar.privateSelected && !calendar.readOnly
        );
        if (firstPublicIndex === -1 && !publicOnly) {
            firstPublicIndex = allCalendars.findIndex(
                (calendar) => calendar instanceof Calendar && !calendar.readOnly
            );
        }
        if (firstPublicIndex > -1) {
            this.setActiveCalendar(firstPublicIndex);
        }
    },

    // Thought: "Focus the calendar in which I am already doing work". Currently used only when clicking an order
    focusBestCalendar() {
        const allCalendars = this.props.view.section.getAllChildren();
        const bestIndex = allCalendars.findIndex(
            (calendar) => calendar.selectionGroup && calendar.selectionGroup.length > 0
        );
        if (bestIndex > -1) {
            this.setActiveCalendar(bestIndex);
        } else {
            this.focusFirstCalendar();
        }
    },

    updateDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    },

    updateWindowTitle(props) {
        let title = `TimeEdit » ${TimeEdit.serverInfo.database.name}`;
        if (props.view && props.view.id !== 1) {
            title = `${title} » ${props.view.name}`;
        }
        if (document.title !== title) {
            document.title = title;
        }
    },

    hideAboutDialog() {
        this.setState({ aboutVisible: false });
    },

    toggleAboutDialog(supportInfoLines = [], showSupportText = false) {
        this.setState({
            aboutVisible: !this.state.aboutVisible,
            supportInfoLines,
            showSupportText,
        });
    },

    handleHideShortcutOverlay() {
        this.setState({ shortcutOverlayVisible: false });
    },

    toggleShortcutOverlay() {
        this.setState({ shortcutOverlayVisible: !this.state.shortcutOverlayVisible });
    },

    hideCommandBar() {
        this.setState({ commandBarVisible: false });
    },

    toggleCommandBar() {
        if (!(this.getActiveCalendar() instanceof Calendar)) {
            Log.info(Language.get("nc_activate_calendar_to_use_command_bar"));
            return;
        }
        this.setState({ commandBarVisible: !this.state.commandBarVisible });
    },

    endLockMode() {
        if (_prevEscBinding.length > 0) {
            Mousetrap.unbindWithHelp(MOUSETRAP_ESCAPE);
            if (_prevEscBinding[0]) {
                Mousetrap.bindWithHelp(MOUSETRAP_ESCAPE, _prevEscBinding[0]);
                _prevEscBinding = [];
            }
        }
        this.setState({
            unlockedReservations: null,
            skipConfirmation: false,
            temporaryUnlock: false,
        });
    },

    updateSumTypes(callback) {
        API.findSumSettings((result) => {
            API.getSumSetting(result[0], (filters) => {
                this.setState({ filters });
                if (callback) {
                    callback(filters);
                }
            });
        });
    },

    presentModernModal(
        content,
        displayKey = null,
        title = null,
        buttons = null,
        onRemember = _.noop,
        useContentSize = false,
        hasCopyButton = false
    ) {
        this.presentModal(
            content,
            displayKey,
            title,
            buttons,
            onRemember,
            useContentSize,
            hasCopyButton,
            true
        );
    },

    presentModal(
        content,
        displayKey = null,
        title = null,
        buttons = null,
        onRemember = _.noop,
        useContentSize = false,
        hasCopyButton = false,
        isModernStyle = false
    ) {
        if (!this.state.modalDialogVisible) {
            const dialogProps = {
                content,
                displayKey,
                title,
                buttons,
                onRemember,
                useContentSize,
                hasCopyButton,
                isModernStyle,
            };
            this.setState({ modalDialogVisible: true, dialogProps });
        } else if (this.state.modalDialogVisible && !this.state.secondModalDialogVisible) {
            const dialog = (
                <ModalDialog
                    hasCopyButton={hasCopyButton}
                    onClose={this.handleDismissSecondModal}
                    displayKey={displayKey}
                    title={title}
                    buttons={buttons}
                    onRemember={onRemember}
                    isModernStyle={isModernStyle}
                >
                    {content}
                </ModalDialog>
            );
            this.setState({ secondModalDialogVisible: true, secondModalDialog: dialog });
        }
    },

    changeCalendar(numSteps) {
        const currentIndex = this.state.activeCalendar;
        let newIndex = currentIndex + numSteps;
        const numCalendars = this.props.view.section.getAllChildren().length;
        if (newIndex < 0) {
            newIndex = numCalendars - 1;
        }
        if (newIndex >= numCalendars) {
            newIndex = 0;
        }
        this.handleActiveCalendarChange(newIndex);
    },

    setupHelp() {
        const options = {
            timeout: 0,
            shouldShowTutorial: (viewedHelp) =>
                !viewedHelp.basicTutorial &&
                this.state.showSelection &&
                this.state.activeLayer === 0,
        };
        Tutorial.show(
            "basicTutorial",
            Tutorials.getWelcomeSteps(this.state.menuPosition === MenuModel.POSITION.RIGHT),
            options
        );
    },

    openStaticReservationListAdd(reservationIds, add) {
        const section = this.props.view.section;
        const name = Language.get("nc_my_list");
        if (section.second && section.second.hasStaticReservations()) {
            const ids = add
                ? _.union(section.second.first.reservationIds, reservationIds)
                : _.difference(section.second.first.reservationIds, reservationIds);
            this.openStaticReservationList(false, ids, name);
            return;
        }

        this.openStaticReservationList(false, reservationIds, name);
    },

    openStaticReservationList(horizontally, reservationIds, name) {
        const section = this.props.view.section;
        const limits = section.first.limits;
        if (section.second && section.second.hasStaticReservations()) {
            const newSection = section.immutableSet({
                second: new SectionModel(
                    new StaticReservations(limits, null, reservationIds, name),
                    null,
                    null,
                    null
                ),
            });
            TimeEdit.State.update(section, newSection);
        } else if (section.second) {
            const DEFAULT_WEIGHT = 0.5;
            const newSection = new SectionModel(
                section,
                new SectionModel(
                    new StaticReservations(limits, null, reservationIds, name),
                    null,
                    null,
                    null
                ),
                DEFAULT_WEIGHT,
                horizontally
            );
            TimeEdit.State.update(section, newSection);
        } else {
            const splitSection = section.split(horizontally, true).immutableSet({
                second: new SectionModel(
                    new StaticReservations(limits, null, reservationIds, name),
                    null,
                    null,
                    null
                ),
            });
            TimeEdit.State.update(section, splitSection);
        }
    },

    toggleReservationList(horizontally) {
        let splitToList = true;
        const section = this.props.view.section;
        if (section.second && section.second.hasReservationList()) {
            splitToList = false;
        }
        if (splitToList) {
            if (section.second) {
                const DEFAULT_WEIGHT = 0.5;
                const newSection = new SectionModel(
                    section,
                    new SectionModel(new ReservationSearch(section.first.limits), null, null, null),
                    DEFAULT_WEIGHT,
                    horizontally
                );
                TimeEdit.State.update(section, newSection);
            } else {
                TimeEdit.State.update(section, section.split(horizontally, true));
            }
        } else {
            TimeEdit.State.update(section, section.close(false));
        }
    },

    toggleExamComponent(horizontally = false) {
        let splitToList = true;
        const section = this.props.view.section;
        if (section.second && section.second.hasExamComponent()) {
            splitToList = false;
        }
        if (splitToList) {
            if (section.second) {
                const DEFAULT_WEIGHT = 0.5;
                const newSection = new SectionModel(
                    section,
                    new SectionModel(new ExamData(section.first.limits), null, null, null),
                    DEFAULT_WEIGHT,
                    horizontally
                );
                TimeEdit.State.update(section, newSection);
            } else {
                TimeEdit.State.update(
                    section,
                    section.splitToComponent(horizontally, new ExamData(section.first.limits))
                );
            }
        } else {
            TimeEdit.State.update(section, section.close(false));
        }
    },

    showObjectManager(
        types,
        objectIds,
        incompleteObjects,
        createCopy,
        viewOnly,
        title,
        newFieldValues = [],
        callback = _.noop
    ) {
        let sortOrder = this.state.multiPanelOrder;
        if (viewOnly) {
            sortOrder = pushToFront(sortOrder, "object");
        }
        this.setState({
            showObjectManager: !viewOnly, // Show in side panel if view only
            types,
            objectIds,
            incompleteObjects,
            copySelectedObject: createCopy,
            objectManagerViewOnly: viewOnly,
            objectManagerTitle: title,
            objectManagerNewFieldValues: newFieldValues,
            multiPanelOrder: sortOrder,
            objectRequestCallback: callback,
        });
        if (this.refs.objectManager) {
            this.refs.objectManager.reset();
        }

        this._previousEscBinding = Mousetrap.unbindWithHelp("esc", true)[0];
        if (viewOnly) {
            TimeEdit.State.replace(this.props.menu, this.props.menu.open("info"));
            Mousetrap.bindWithHelp("esc", () => {
                TimeEdit.State.replace(this.props.menu, this.props.menu.close());
            });
        } else {
            Mousetrap.bindWithHelp("esc", this.handleHideObjectManager);
        }
    },

    handleHideObjectManager(event, invokeCallback = true) {
        if (this._objectManagerCloseCheck && invokeCallback) {
            if (!this._objectManagerCloseCheck()) {
                return;
            }
        }
        Mousetrap.unbindWithHelp("esc");
        if (this._previousEscBinding) {
            Mousetrap.bindWithHelp("esc", this._previousEscBinding);
        }

        const callback = this.state.objectRequestCallback;

        this.setState({
            showObjectManager: false,
            copySelectedObject: false,
            objectManagerViewOnly: false,
            objectManagerNewFieldValues: [],
            objectRequestCallback: null,
        });

        if (callback) {
            callback(null);
        }
    },

    showGroupManager(data, callback) {
        const horizontally = false;
        let splitToList = true;
        const section = this.props.view.section;
        const allSections = this.props.view.section.getAllChildren();
        const existingManager = _.find(allSections, (sec) => sec instanceof MemberData);
        if (existingManager) {
            splitToList = false;
            const sectionToReplace = this.props.view.section.getCalendarSection(existingManager);
            // Update existing group manager with new props
            TimeEdit.State.update(
                sectionToReplace,
                sectionToReplace.setComponent(
                    new MemberData(
                        sectionToReplace.first.limits,
                        null,
                        [],
                        data.groupRequests,
                        [],
                        callback
                    )
                )
            );
        }
        if (splitToList) {
            if (section.second) {
                const DEFAULT_WEIGHT = 0.5;
                const newSection = new SectionModel(
                    section,
                    new SectionModel(
                        new MemberData(
                            section.first.limits,
                            null,
                            [],
                            data.groupRequests,
                            [],
                            callback
                        ),
                        null,
                        null,
                        null
                    ),
                    DEFAULT_WEIGHT,
                    horizontally
                );
                TimeEdit.State.update(section, newSection);
            } else {
                TimeEdit.State.update(
                    section,
                    section.splitToComponent(
                        horizontally,
                        new MemberData(
                            section.first.limits,
                            null,
                            [],
                            data.groupRequests,
                            [],
                            callback
                        )
                    )
                );
            }
        }
    },

    reloadFunctions: [],

    addReloadFunction(reloadFunction) {
        this.reloadFunctions.push(reloadFunction);
    },

    handleCalendarSwap(from, to) {
        const calendars = this.props.view.section.getAllChildren();
        const fromSection = this.props.view.section.getCalendarSection(calendars[from]);
        const toSection = this.props.view.section.getCalendarSection(calendars[to]);
        const toCalendar = calendars[to];
        TimeEdit.State.update(toSection, toSection.setComponent(calendars[from]));
        TimeEdit.State.update(fromSection, fromSection.setComponent(toCalendar));
        this.setActiveCalendar(to);
    },

    getCalendarIndex(calendar) {
        let index = -1;
        this.props.view.section.getAllChildren().some((item, i) => {
            if (item === calendar) {
                index = i;
                return true;
            }
            return false;
        });
        return index;
    },

    getActiveCalendar() {
        const activeId = this.state.activeCalendar;
        let calendar;
        this.props.view.section.getAllChildren().forEach((child, i) => {
            if (i === activeId) {
                calendar = child;
            }
        });
        return calendar;
    },

    handleUpdateObjectSearcher(objectSearch) {
        this.setState({ objectSearch });
    },

    getObjectSearch() {
        return this.state.objectSearch;
    },

    getCurrentFluffyItem() {
        return this.state.currentFluffyItem;
    },

    handleEnableDrop(event) {
        if (!_.isEventDragDataOfType(event, ["application/x-timeedit-menu"])) {
            return;
        }

        event.preventDefault();
        const POSITION_DIVISOR = 2;

        if (
            event.clientX > this.state.width / POSITION_DIVISOR &&
            this.state.menuPosition !== MenuModel.POSITION.RIGHT
        ) {
            this.setState({ menuPosition: MenuModel.POSITION.RIGHT });
            return;
        }
        if (
            event.clientX < this.state.width / POSITION_DIVISOR &&
            this.state.menuPosition !== MenuModel.POSITION.LEFT
        ) {
            this.setState({ menuPosition: MenuModel.POSITION.LEFT });
            return;
        }
    },

    updateCopiedEntry(entry, groupedEntries = []) {
        this.setState({
            copiedEntry: entry,
            copiedGroupEntries: groupedEntries,
        });
    },

    macros: [
        { action: Macros.Action.SET_ORDER },
        { action: Macros.Action.SET_OBJECT },
        { action: Macros.Action.SET_RESERVATION },
    ],

    MIN_BELOWBAR_HEIGHT: 180,

    onModalDragStart(event) {
        const dialog = document.getElementsByClassName("draggableDialog")[0] as HTMLElement;
        if (!dialog) {
            return;
        }
        this._dragOffsetLeft = event.clientX - dialog.offsetLeft;
        this._dragOffsetTop = event.clientY - dialog.offsetTop;
    },

    onModalDragOver(event) {
        event.preventDefault();
    },

    onModalDrop(event) {
        event.preventDefault();
        const left = event.clientX - this._dragOffsetLeft;
        const top = event.clientY - this._dragOffsetTop;
        this.setState({
            dialogPosition: "absolute",
            dialogLeft: `${left}px`,
            dialogTop: `${top}px`,
        });
    },

    toggleBackgroundText() {
        this.setState({ skipBackgroundText: !this.state.skipBackgroundText });
    },

    getActiveLayerTitle() {
        const layer = _.find(this.state.layers, (lr) => lr.id === this.state.activeLayer);
        if (layer) {
            return layer.name;
        }
        return "";
    },

    isActiveLayerMovingReservations() {
        return this.moveLayers ? this.moveLayers[this.state.activeLayer] || false : false;
    },

    render() {
        if (this.props.usingSSO === true && !this.props.token) {
            return null;
        }

        if (!this.state.loadFinished) {
            return null;
        }

        let menuWidth = 55;
        const OPEN_MENU_WIDTH = 300;
        if (this.props.menu.isOpen) {
            menuWidth = menuWidth + OPEN_MENU_WIDTH;
        }

        const style: React.CSSProperties = {
            width: this.state.width - menuWidth,
            height: this.state.height,
        };

        if (this.state.menuPosition === MenuModel.POSITION.RIGHT) {
            style.marginLeft = 0;
        } else {
            style.marginLeft = menuWidth;
        }

        const hasNoView = !this.props.view || !this.props.view.section;

        const sectionWidth = style.width;
        const MASS_BAR_HEIGHT = 60;
        const selectionHeight = this.state.showSelection ? this.state.selectionHeight : 0;
        const massChangeBarHeight = this.state.activeLayer !== 0 ? MASS_BAR_HEIGHT : 0;
        const sectionSize = {
            width: sectionWidth,
            height: this.state.height - selectionHeight - massChangeBarHeight,
        };
        const selectionSize = {
            width: sectionWidth,
            height: this.state.selectionHeight,
        };

        const allCalendars = hasNoView ? [] : this.props.view.section.getAllChildren();
        const activeCalendar = allCalendars[this.state.activeCalendar];

        let selectionModel = this.props.selection;
        if (activeCalendar && activeCalendar.privateSelected === true) {
            selectionModel = activeCalendar.selection;
        }

        const messageSize = { width: sectionWidth, marginLeft: style.marginLeft };
        const massChangeBarSize = { width: sectionWidth, height: massChangeBarHeight };

        const rulerClass = ["ruler", "horizontalRuler"];
        if (!this.state.showSelection) {
            rulerClass.push("hidden");
        }

        const rulerStyle = {
            top: this.state.height - this.state.selectionHeight,
        };
        if (this.props.isResizing) {
            rulerStyle.top = this.props.lastMousePosition.clientY;
        }

        let SectionType = Section;
        const isTouch = false;
        const TAB_TRIGGER_WIDTH = 768;
        const TAB_TRIGGER_HEIGHT = 400;
        const TAB_TOUCH_LIMIT_WIDTH = 600;
        const TAB_TOUCH_LIMIT_HEIGHT = 400;
        let useTabbedSection =
            isTouch &&
            (this.state.width <= TAB_TRIGGER_WIDTH || this.state.height <= TAB_TRIGGER_HEIGHT); // Touch device and size corresponding to iPad Mini or smaller
        useTabbedSection =
            useTabbedSection ||
            (!isTouch &&
                (this.state.width <= TAB_TOUCH_LIMIT_WIDTH ||
                    this.state.height <= TAB_TOUCH_LIMIT_HEIGHT));
        if (useTabbedSection) {
            SectionType = TabbedSection;
        }

        let objectManager = <span />;
        if (this.state.showObjectManager) {
            objectManager = (
                <ObjectManager
                    flags={this.props.flags}
                    ref="objectManager"
                    title={this.state.objectManagerTitle}
                    newFieldValues={this.state.objectManagerNewFieldValues}
                    viewOnly={this.state.objectManagerViewOnly}
                    width={this.state.width}
                    height={this.state.height}
                    handleHideObjectManager={this.handleHideObjectManager}
                    types={this.state.types}
                    objectIds={this.state.objectIds}
                    incompleteObjects={this.state.incompleteObjects}
                    createCopy={this.state.copySelectedObject}
                    onSave={this.handleObjectsSaved}
                    setOnCloseCheck={this.setObjectManagerCloseCheck}
                />
            );
        }

        let aboutDialog = <span />;
        if (this.state.aboutVisible) {
            aboutDialog = (
                <AboutDialog
                    ref="aboutDialog"
                    close={this.hideAboutDialog}
                    supportInfoLines={this.state.supportInfoLines}
                    showSupportText={this.state.showSupportText}
                />
            );
        }

        let shortcutOverlay: React.ReactElement | null = null;
        if (this.state.shortcutOverlayVisible) {
            shortcutOverlay = <ShortcutOverlay onClose={this.handleHideShortcutOverlay} />;
        }

        let commandBar = <span />;
        if (this.state.commandBarVisible) {
            const getFluffy = function () {
                return selectionModel.fluffy;
            };
            commandBar = (
                <CommandBar
                    onInfoOpen={this.handleEntryInfoOpen}
                    close={this.hideCommandBar}
                    selection={selectionModel}
                    activeCalendar={this.getActiveCalendar()}
                    getFluffy={getFluffy}
                />
            );
        }

        let massChangeBar: React.ReactElement | null = null;
        if (this.state.activeLayer !== 0) {
            massChangeBar = (
                <MassChangeBar
                    isWorking={this.state.isWorking}
                    progress={this.state.massChangeProgress}
                    style={massChangeBarSize}
                    isModify={this.isActiveLayerMovingReservations()}
                    title={this.getActiveLayerTitle()}
                    performMassChange={this.performExperiment}
                    cancelMassChange={this.cancelExperiment}
                />
            );
        }

        const isInfoPanelOpen = this.props.menu.activeMenu === "info" && this.props.menu.isOpen;
        const reservationIds =
            this.props.user.useInfoPopover || isInfoPanelOpen ? this.state.reservationInfoIds : [];

        let modalDialog: React.ReactElement | null = null;
        if (this.state.modalDialogVisible) {
            const dP = this.state.dialogProps;
            modalDialog = (
                <div
                    className="modalOverlay"
                    onDragOver={this.onModalDragOver}
                    onDrop={this.onModalDrop}
                >
                    {
                        <ModalDialog
                            onDragStart={this.onModalDragStart}
                            style={{
                                top: this.state.dialogTop,
                                left: this.state.dialogLeft,
                                position: this.state.dialogPosition,
                            }}
                            hasCopyButton={dP.hasCopyButton}
                            onClose={this.handleDismissModal}
                            displayKey={dP.displayKey}
                            title={dP.title}
                            buttons={dP.buttons}
                            onRemember={dP.onRemember}
                            useContentSize={dP.useContentSize}
                            isModernStyle={dP.isModernStyle}
                        >
                            {dP.content}
                        </ModalDialog>
                    }
                </div>
            );
        }

        let secondModalDialog: React.ReactElement | null = null;
        if (this.state.secondModalDialog && this.state.secondModalDialogVisible) {
            secondModalDialog = <div className="modalOverlay">{this.state.secondModalDialog}</div>;
        }

        const className = TimeEdit.State.get().user.theme;

        return (
            <CoreLDInitializer
                region={this.props.env.serverRegion}
                customerSignature={TimeEdit.serverInfo?.server.signature}
                usingSSO={this.props.usingSSO ?? false}
                token={this.props.token}
            >
                <div
                    style={style}
                    className={className}
                    onDragOver={this.handleEnableDrop}
                    onDrop={this.handleDrop}
                >
                    <LoadIndicator style={messageSize} />
                    <MessageBar style={messageSize} />
                    <Menu
                        multiPanelOrder={this.state.multiPanelOrder}
                        data={this.props.menu}
                        appHeight={this.state.height}
                        appWidth={this.state.width}
                        activeCalendar={activeCalendar}
                        publicSelection={this.props.selection}
                        showSelection={this.state.showSelection}
                        position={this.state.menuPosition}
                        onSelectionToggle={this.handleToggleSelection}
                        showAbout={this.toggleAboutDialog}
                        showShortcuts={this.toggleShortcutOverlay}
                        onSettingsVisibilityChange={this.handleSetVisibleCalendarIds}
                        onViewChange={this.handleViewChange}
                        view={this.props.view}
                        layers={this.state.layers}
                        activeLayer={this.state.activeLayer}
                        setActiveLayer={this.setActiveLayer}
                        onLayerCreated={this.onLayerCreated}
                        onRemoveLayer={this.cancelExperiment}
                        onPersistLayer={this.performExperiment}
                        reloadLayers={this.reloadLayers}
                        getLayerName={this.getLayerName}
                        getCalendarIndex={this.getCalendarIndex}
                        macroManager={this.state.macros}
                        onDragEnd={this.handleDragEnd}
                        reservationIds={reservationIds}
                        dynamicReservationIds={this.state.dynamicReservationIds}
                        orderIds={this.state.orderInfoIds}
                        isEntryInfo={this.state.isEntryInfo}
                        isInfoEntryEdited={this.state.isInfoEntryEdited}
                        onReservationEditChange={this.onReservationEditChange}
                        onEntryInfoOpen={this.handleEntryInfoOpen}
                        onOrderInfoOpen={this.handleOrderInfoOpen}
                        hasAvailableUpdate={this.shouldIndicateUpdate()}
                        resetHelp={this.setupHelp}
                        types={this.state.types}
                        objectIds={this.state.objectIds}
                        onReservationInfoClose={this.onReservationInfoClose}
                        onDynamicReservationInfoClose={this.onDynamicReservationInfoClose}
                        onObjectInfo={this.handleObjectInfo}
                        onObjectInfoClose={this.onObjectInfoClose}
                        onOrderInfoClose={this.onOrderInfoClose}
                    />
                    {massChangeBar}
                    <ViewErrorBoundary size={sectionSize} id={hasNoView ? 0 : this.props.view.id}>
                        <SectionType
                            key={hasNoView ? 0 : this.props.view.id}
                            data={hasNoView ? null : this.props.view.section}
                            size={sectionSize}
                            selection={this.props.selection}
                            activeLayer={this.state.activeLayer}
                            massChangeEnabled={this.state.massChangeEnabled}
                            filters={this.state.filters}
                            updateSumTypes={this.updateSumTypes}
                            isMassChangeCopy={!this.isActiveLayerMovingReservations()}
                            setActiveLayer={this.setActiveLayer}
                            onLayerCreated={this.onLayerCreated}
                            getLayerName={this.getLayerName}
                            layers={this.state.layers}
                            showCalendarIds={this.state.showCalendarIds}
                            toggleCommandBar={this.toggleCommandBar}
                            activeCalendarId={this.state.activeCalendar}
                            onActiveCalendarChange={this.handleActiveCalendarChange}
                            getCalendarIndex={this.getCalendarIndex}
                            onCalendarSwap={this.handleCalendarSwap}
                            getCurrentFluffyItem={this.getCurrentFluffyItem}
                            getObjectSearch={this.getObjectSearch}
                            onEntryInfoOpen={this.handleEntryInfoOpen}
                            onEditReservationExceptions={this.handleEditReservationExceptions}
                            onOrderInfoOpen={this.handleOrderInfoOpen}
                            onDynamicReservationIdsChanged={this.onDynamicReservationIdsChanged}
                            infoEntryReservationIds={reservationIds}
                            copiedEntry={this.state.copiedEntry}
                            copiedGroupedEntries={this.state.copiedGroupEntries}
                            onEntryCopy={this.updateCopiedEntry}
                            unlockedReservations={this.state.unlockedReservations}
                            temporaryUnlock={this.state.temporaryUnlock}
                            endLockMode={this.endLockMode}
                            skipConfirmation={this.state.skipConfirmation}
                            openStaticReservationList={this.openStaticReservationList}
                            openStaticReservationListAdd={this.openStaticReservationListAdd}
                            skipBackgroundText={this.state.skipBackgroundText}
                            examIdField={this.state.examIdField}
                            setWeeks={this.setWeeks}
                        />
                    </ViewErrorBoundary>
                    <div
                        className={rulerClass.join(" ")}
                        style={rulerStyle}
                        onTouchStart={this.props.onResizeStart}
                        onMouseDown={this.props.onResizeStart}
                    />
                    <SelectionPane
                        flags={this.props.flags}
                        getObjectSearch={this.getObjectSearch}
                        size={selectionSize}
                        data={selectionModel}
                        onObjectSearcherChange={this.handleUpdateObjectSearcher}
                        activeCalendar={activeCalendar}
                        activeLayer={this.state.activeLayer}
                        isVisible={this.state.showSelection}
                        onNewObject={this.handleNewObject}
                        onFluffyItemChanged={this.handleFluffyItemChanged}
                        addReloadFunction={this.addReloadFunction}
                        onObjectInfo={this.handleObjectInfo}
                        onEntryInfoOpen={this.handleEntryInfoOpen}
                        unlockedReservations={this.state.unlockedReservations}
                        temporaryUnlock={this.state.temporaryUnlock}
                        skipConfirmation={this.state.skipConfirmation}
                        endLockMode={this.endLockMode}
                        user={this.props.user}
                        schedulingTracks={this.state.schedulingTracks}
                        mappedResult={this.state.mappedResult}
                        onCancelActivityScheduling={() => {
                            this.clearSchedulingTracks();
                        }}
                        currentTrack={this.state.currentTrack}
                        onCurrentTrackChanged={this.onCurrentTrackChanged}
                    />
                    {objectManager}
                    {aboutDialog}
                    {shortcutOverlay}
                    {commandBar}
                    {modalDialog}
                    {secondModalDialog}
                </div>
            </CoreLDInitializer>
        );
    },
});

App.contextType = context;

module.exports = withLDConsumer()(ResizeableComponent.wrap(App));
