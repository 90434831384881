const NamedWeek = function (jsonData) {
    this.dayNumber = 0;
    this.xShortName = "";
    this.shortName = "";
    this.mediumName = "";
    this.longName = "";
    if (jsonData) {
        this.dayNumber = jsonData.dayNumber || 0;
        this.xShortName = jsonData.xshortName || "";
        this.shortName = jsonData.shortName || "";
        this.mediumName = jsonData.mediumName || "";
        this.longName = jsonData.longName || "";
    }
};

NamedWeek.toJson = (namedWeeks) => {
    const jsonArray = [];
    // dayNumbers
    jsonArray.push(namedWeeks.map((namedWeek) => namedWeek.dayNumber));
    // xshortNames
    jsonArray.push(namedWeeks.map((namedWeek) => namedWeek.xShortName));
    // shortNames
    jsonArray.push(namedWeeks.map((namedWeek) => namedWeek.shortName));
    // mediumNames
    jsonArray.push(namedWeeks.map((namedWeek) => namedWeek.mediumName));
    // longNames
    jsonArray.push(namedWeeks.map((namedWeek) => namedWeek.longName));
    return jsonArray;
};

NamedWeek.prototype.getLongestName = function () {
    if (this.longName !== "") {
        return this.longName;
    }
    if (this.mediumName !== "") {
        return this.mediumName;
    }
    if (this.shortName !== "") {
        return this.shortName;
    }
    return this.xShortName;
};

NamedWeek.prototype.getShortestName = function () {
    if (this.xShortName !== "") {
        return this.xShortName;
    }
    if (this.shortName !== "") {
        return this.shortName;
    }
    if (this.mediumName !== "") {
        return this.mediumName;
    }
    return this.longName;
};

NamedWeek.prototype.isEmpty = function () {
    return (
        this.xShortName === "" &&
        this.shortName === "" &&
        this.mediumName === "" &&
        this.longName === ""
    );
};

NamedWeek.create = (jsonDataArray) => jsonDataArray.map((jsonData) => new NamedWeek(jsonData));

module.exports = NamedWeek;
