const PropTypes = require("prop-types");
const React = require("react");
const Combobox = require("./combobox");
const Token = require("./token");

class TokenInput extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        loadingComponent: PropTypes.any,
        onFocus: PropTypes.func,
        onInput: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        tokenAriaFunc: PropTypes.func,
        onRemove: PropTypes.func.isRequired,
        selected: PropTypes.array.isRequired,
        menuContent: PropTypes.any,
        showListOnFocus: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    state = {
        selectedToken: null,
    };

    handleClick = () => {
        this.comboLi.querySelector("input").focus();
    };

    handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleInput = (inputValue) => {
        this.props.onInput(inputValue);
    };

    handleSelect = (event, option) => {
        const input = this.comboLi.querySelector("input");
        this.props.onSelect(event, option);
        this.setState({
            selectedToken: null,
        });
        this.props.onInput(input.value);
    };

    handleRemove = (value) => {
        const input = this.comboLi.querySelector("input");
        this.props.onRemove(value);
        input.focus();
    };

    handleRemoveLast = () => {
        this.props.onRemove(this.props.selected[this.props.selected.length - 1]);
    };

    render() {
        const isDisabled = this.props.isDisabled;
        const tokens = this.props.selected.map((token) => (
            <Token
                tokenAriaFunc={this.props.tokenAriaFunc}
                onFocus={this.handleFocus}
                onRemove={this.handleRemove}
                value={token}
                name={token.name}
                key={token.id}
            />
        ));

        const classes = ["ic-tokens flex"];
        if (isDisabled) {
            classes.push("ic-tokens-disabled");
        }

        return (
            <ul className={classes.join(" ")} onClick={this.handleClick}>
                {tokens}
                <li className="inline-flex" ref={(e) => (this.comboLi = e)}>
                    <Combobox
                        id={this.props.id}
                        aria-label={this.props["combobox-aria-label"]}
                        ariaDisabled={isDisabled}
                        onFocus={this.handleFocus}
                        onInput={this.handleInput}
                        showListOnFocus={this.props.showListOnFocus}
                        onSelect={this.handleSelect}
                        onRemoveLast={this.handleRemoveLast}
                        value={this.state.selectedToken}
                        isDisabled={isDisabled}
                        placeholder={this.props.placeholder}
                    >
                        {this.props.menuContent}
                    </Combobox>
                </li>
                {this.props.isLoading && (
                    <li className="ic-tokeninput-loading flex">{this.props.loadingComponent}</li>
                )}
            </ul>
        );
    }
}

module.exports = TokenInput;
