const Model = require("./Model");
const View = require("./View");

const ViewList = function (views) {
    Model.call(this, "ViewList");
    this.views = views || [];
};

ViewList.prototype = Object.create(Model.prototype);

ViewList.prototype.updateViews = function (callback) {
    const self = this;
    View.find(null, (newViews) => {
        callback(
            self.immutableSet({
                views: newViews,
            })
        );
    });
};

module.exports = ViewList;
