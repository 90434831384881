const React = require("react");

const TextDisplay = props => {
    const headline = props.headline ? <h1>{props.headline}</h1> : null;
    const sections = props.sections.map((section, index) => {
        if (!section.text) {
            return null;
        }
        if (section.headline) {
            return (
                <div key={index}>
                    <h2>{section.headline}</h2>
                    <p>{section.text}</p>
                </div>
            );
        }
        return <p key={index}>{section.text}</p>;
    });

    return (
        <div className="textDisplay">
            {headline}
            {sections}
        </div>
    );
};

module.exports = TextDisplay;
