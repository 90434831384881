const Header = require("./Header");
import { MillenniumDateTime } from "@timeedit/millennium-time";
const Language = require("../lib/Language");
const _ = require("underscore");

const PeriodHeader = function (visibleValues, firstVisibleValue, subheader) {
    Header.call(this, visibleValues || 1, firstVisibleValue, subheader, "PeriodHeader");
    this.id = 0;
    this.names = [];
    this.values = [];
    this.size = 18;
};

PeriodHeader.KIND = {
    TIME: 1,
    DATE: 2,
    WEEK: 3,
    WEEKDAY: 4,
};

PeriodHeader.prototype = Object.create(Header.prototype);

PeriodHeader.prototype.getKind = function () {
    throw new Error("All period headers must implement getKind");
};

PeriodHeader.prototype.validateValues = function () {
    if (this.names.length !== this.getValues().length) {
        throw new Error("Invalid period header");
    }
    let i;
    const isInvalid = this.values.some((value, index) => {
        i = index;
        return !Array.isArray(value) || value.length === 0;
    });
    if (isInvalid) {
        throw new Error(
            `Invalid period header in period header ${this.id}, no value for "${this.names[i]}"`
        );
    }
};

PeriodHeader.prototype.indexOf = function (entry, onlyVisible) {
    if (
        !entry.hasOwnProperty("periods") ||
        entry.periods === undefined ||
        !entry.periods.hasOwnProperty(this.getId())
    ) {
        if (this.isSimplePeriod()) {
            return this.getSimplePeriodIndex(entry, onlyVisible);
        }
        throw new Error("Could not find matching period for entry.");
    }

    let index = entry.periods[this.getId()];
    if (Array.isArray(index)) {
        index = index[0];
    }
    if (onlyVisible) {
        return index - this.firstVisibleValue;
    }

    return index;
};

PeriodHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    if (
        !entry.hasOwnProperty("periods") ||
        entry.periods === undefined ||
        !entry.periods.hasOwnProperty(this.getId())
    ) {
        if (this.isSimplePeriod()) {
            return this.getSimplePeriodIndex(entry, onlyVisible);
        }
        throw new Error("Could not find matching period for entry.");
    }

    let index = entry.periods[this.getId()];
    if (Array.isArray(index)) {
        index = index[index.length - 1];
    }
    index = index + 1;
    if (onlyVisible) {
        return index - this.firstVisibleValue;
    }
    return index;
};

PeriodHeader.prototype.valueAt = function (index, onlyVisible = true) {
    if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
        throw new Error(`Index out of bounds in PeriodHeader.valueAt(${index})`);
    }

    const values = onlyVisible ? this.getVisibleValues() : this.getValues();
    return values[index];
};

// eslint-disable-next-line no-unused-vars
PeriodHeader.prototype.getLabel = function (value, size) {
    // eslint-disable-line no-unused-vars
    let label = "N/A";
    this.getValues().forEach(function (item, index) {
        if (item === value) {
            label = this.names[index];
        }
    }, this);
    return label;
};

PeriodHeader.prototype.getInfo = function (value) {
    if (value === this.values[this.firstVisibleValue]) {
        return this.name;
    }

    return null;
};

PeriodHeader.prototype.getValues = function () {
    return this.values;
};

PeriodHeader.prototype.getClusterDepth = function (index) {
    return this.getValues()[index].length;
};

// eslint-disable-next-line no-unused-vars
PeriodHeader.prototype.getMaxClusterDepth = function (index) {
    // eslint-disable-line no-unused-vars
    return this.getValues().reduce((max, values) => Math.max(values.length, max), 1);
};

/**
 * A simple period is a period where all cells only contains a single value and
 * no values overlap eachother.
 */
// eslint-disable-next-line no-unused-vars
PeriodHeader.prototype.isSimplePeriod = function (index) {
    // eslint-disable-line no-unused-vars
    const hasOnlySingleValues = this.getValues().every((values) => values.length === 1);
    if (!hasOnlySingleValues) {
        return false;
    }

    const values = _.flatten(this.getValues());
    const getMatchingValues = (value) => values.filter((otherValue) => value.equals(otherValue));
    return values.every((value) => getMatchingValues(value).length === 1);
};

PeriodHeader.getDateTimeFromPeriods = function (headers, indexes) {
    const WeekPeriodHeader = require("./WeekPeriodHeader"); // eslint-disable-line global-require
    const WeekdayPeriodHeader = require("./WeekdayPeriodHeader"); // eslint-disable-line global-require
    const TimePeriodHeader = require("./TimePeriodHeader"); // eslint-disable-line global-require

    let weekPeriod;
    let weekdayPeriod;
    const dateTimeObjects = _.filter(
        headers.map((header, i) => {
            if (header instanceof WeekPeriodHeader) {
                weekPeriod = header;
            } else if (header instanceof WeekdayPeriodHeader) {
                weekdayPeriod = header;
            } else if (header instanceof TimePeriodHeader) {
                return undefined;
            }
            return header.getValues()[indexes[i]];
        }),
        (idx) => idx !== undefined
    ); // Filtering works around the case where a view is changing and the header basically contains nothing, then the array will contain a single undefined

    if (!weekPeriod && weekdayPeriod) {
        dateTimeObjects.push(weekdayPeriod.getWeeks().map((wk) => wk.value));
    } else if (weekPeriod && !weekdayPeriod) {
        dateTimeObjects.push(weekPeriod.weekdays);
    }

    if (dateTimeObjects.length === 0 || _.flatten(dateTimeObjects).length === 0) {
        return [];
    }

    try {
        return MillenniumDateTime.createFromList(dateTimeObjects);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

// eslint-disable-next-line no-unused-vars
PeriodHeader.prototype.getSettings = function (providers) {
    // eslint-disable-line no-unused-vars
    const settings = Header.prototype.getSettings.call(this);
    const self = this;

    const firstVisibleValue = settings.find("firstVisibleValue");
    firstVisibleValue.label = Language.get("nc_cal_reservation_list_column_start_period");
    firstVisibleValue.type = "array";
    firstVisibleValue.limit = 1;
    firstVisibleValue.get = () =>
        this.getValues().map((period, index) => ({
            value: index,
            label: self.getLabel(period, Header.Label.XL, index, false),
            selected: index === self.firstVisibleValue,
        }));

    const visibleValues = settings.find("visibleValues");
    visibleValues.label = Language.get("cal_res_side_view_visible_periods");

    return settings;
};

PeriodHeader.prototype.periodsToJSON = function () {
    throw new Error("All period headers must implement periodsToJSON");
};

PeriodHeader.prototype.toJSON = function () {
    const json = Header.prototype.toJSON.call(this);
    return _.extend(json, {
        id: this.id,
        names: this.names,
        kind: "date",
        size: this.size,
    });
};

const DEFAULT_SIZE = 18;

PeriodHeader.fromPeriod = function (period) {
    const WeekPeriodHeader = require("./WeekPeriodHeader"); // eslint-disable-line global-require
    const WeekdayPeriodHeader = require("./WeekdayPeriodHeader"); // eslint-disable-line global-require
    const DatePeriodHeader = require("./DatePeriodHeader"); // eslint-disable-line global-require
    const TimePeriodHeader = require("./TimePeriodHeader"); // eslint-disable-line global-require

    const data = _.extend({}, period, {
        visibleValues: 1,
        firstVisibleValue: 0,
        weekDays: [],
        weeks: [],
        size: period.size || DEFAULT_SIZE,
    });

    let header;
    switch (data.kind) {
        case "perioddate":
            header = DatePeriodHeader.parse(data);
            break;
        case "periodweek":
            header = WeekPeriodHeader.parse(data);
            break;
        case "periodweekday":
            header = WeekdayPeriodHeader.parse(data);
            break;
        case "periodtime":
            header = TimePeriodHeader.parse(data);
            break;
        default:
            throw new Error(`Unknown header type in PeriodHeader.fromPeriod: ${data.kind}.`);
    }

    header.name = period.name;
    return header.freeze();
};

module.exports = PeriodHeader;
