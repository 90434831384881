const API = require("../lib/TimeEditAPI");
const PropTypes = require("prop-types");
const React = require("react");
const ReservationInfo = require("./ReservationInfo");
const _ = require("underscore");
const Language = require("../lib/Language");

class ReservationInfoPopup extends React.Component {
    static propTypes = {
        reservationIds: PropTypes.array.isRequired,
        user: PropTypes.object.isRequired,
        update: PropTypes.func.isRequired,
    };

    onDockChange = () => {
        const user = this.props.user;
        this.props.update(user, user.immutableSet({ useInfoPopover: !user.useInfoPopover }));
        API.setPreferences("useInfoPopover", [!user.useInfoPopover], _.noop);
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const user = this.props.user;
        const dockClasses = {
            dockButton: true,
            dockInfo: user.useInfoPopover,
            popupInfo: !user.useInfoPopover,
        };

        const closeButton = !this.props.onClose ? null : (
            <div className="closeButton" onClick={this.props.onClose} />
        );
        const title =
            this.props.reservationIds.length > 1
                ? Language.get("period_prefs_cluster")
                : `${Language.get("cal_func_res_reservation")} #${this.props.reservationIds[0]}`;
        return (
            <div>
                <h3 className="header">
                    {title}
                    <div className="actionButtons">
                        <div className={_.classSet(dockClasses)} onClick={this.onDockChange} />
                        {closeButton}
                    </div>
                </h3>
                <ReservationInfo {...this.props} />
            </div>
        );
    }
}

module.exports = ReservationInfoPopup;
