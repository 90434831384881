const React = require("react");
const PropTypes = require("prop-types");
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const TemplateGroupList = require("./TemplateGroupList");

const toggleInArray = (array, value) => {
    if (array.indexOf(value) === -1) {
        return [].concat(array).concat([value]);
    }
    return _.filter(array, val => val !== value);
};

const addToArray = (array, value) => {
    if (array.indexOf(value) === -1) {
        return [].concat(array).concat([value]);
    }
    return array;
};

const removeFromArray = (array, value) => _.filter(array, val => val !== value);

class SummaryEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            types: [],
            id: props.selectedFilter ? props.selectedFilter.id : 0,
            name: props.selectedFilter ? props.selectedFilter.name : "",
            selectedTypes: props.selectedFilter ? props.selectedFilter.types : [],
            includeMembers: props.selectedFilter ? props.selectedFilter.includeMembers : [],
            isFrameTime: props.selectedFilter ? props.selectedFilter.frameTime : false,
            templateGroup: props.selectedFilter ? props.selectedFilter.templateGroup : 0,
            templateGroups: [],
        };
        API.findAliasTypes(types => {
            API.getTemplateGroups(this.props.templateKindNumber, result => {
                this.setState({ types, templateGroups: result.parameters[0] });
            });
        });
    }

    static contextTypes = {
        user: PropTypes.object.isRequired,
    };

    onTypeChecked(event) {
        const typeId = parseInt(event.target.value, 10);
        this.setState({
            selectedTypes: toggleInArray(this.state.selectedTypes, typeId),
            includeMembers: addToArray(this.state.includeMembers, typeId),
        });
    }

    onTypeRemoved(typeId) {
        this.setState({
            selectedTypes: toggleInArray(this.state.selectedTypes, typeId),
            includeMembers: removeFromArray(this.state.includeMembers, typeId),
        });
    }

    onMemberChecked(typeId) {
        this.setState({ includeMembers: toggleInArray(this.state.includeMembers, typeId) });
    }

    onTemplateGroupChanged(templateGroup) {
        this.setState({ templateGroup });
    }

    onIsFrameTimeChecked(event) {
        const isChecked = event.target.value === "true" ? false : true;
        this.setState({ isFrameTime: isChecked });
    }

    onDone() {
        this.props.onDone(
            this.state.name,
            this.state.id,
            this.state.selectedTypes,
            this.state.includeMembers,
            this.state.isFrameTime,
            this.state.templateGroup
        );
    }

    onDelete() {
        this.props.onDelete(this.state.id);
    }

    onCancel() {
        this.props.onCancel();
    }

    onNameChange(event) {
        this.setState({ name: event.target.value });
    }

    isDuplicateName(types, type) {
        let isDuplicate = false;
        types.forEach(tp => {
            if (tp.id === type.id) {
                return;
            }
            if (tp.name === type.name) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    }

    render() {
        return (
            <div>
                <div className="summaryButtonContainer">
                    <button className="uiText teButton hollow" onClick={this.onCancel.bind(this)}>
                        {Language.get("dialog_cancel")}
                    </button>
                    <button
                        className="uiText teButton"
                        disabled={this.state.name === ""}
                        onClick={this.onDone.bind(this)}
                    >
                        {Language.get("nc_dialog_done")}
                    </button>
                </div>
                <div>
                    <input
                        className="nameField"
                        type="text"
                        value={this.state.name}
                        onChange={this.onNameChange.bind(this)}
                    />
                </div>
                <div key="frameTimeCheckbox">
                    {Language.get("nc_displays_frame_time")}{" "}
                    <input
                        value={this.state.isFrameTime}
                        type="checkbox"
                        checked={this.state.isFrameTime === true}
                        onChange={this.onIsFrameTimeChecked.bind(this)}
                    />
                </div>
                <div key="frameTimeTemplateGroup">
                    {Language.get("dynamic_reserv_list_reservation_situation")}
                    <br />
                    <TemplateGroupList
                        value={this.state.templateGroup || 0}
                        onObjectSelect={this.onTemplateGroupChanged.bind(this)}
                        templateGroups={this.state.templateGroups}
                    />
                </div>
                <div className="filterTypesPane">
                    {this.state.selectedTypes.map(typeId => {
                        const type = _.find(this.state.types, tp => tp.id === typeId);
                        if (!type) {
                            return null;
                        }
                        return (
                            <div key={type.id}>
                                {type.name}
                                <input
                                    type="button"
                                    className="removeButton rightAligned"
                                    onClick={this.onTypeRemoved.bind(this, type.id)}
                                />
                                {_.contains(this.state.selectedTypes, type.id) ? (
                                    <div style={{ paddingLeft: "20px" }}>
                                        <input
                                            value={type.id}
                                            type="checkbox"
                                            checked={_.contains(this.state.includeMembers, type.id)}
                                            onChange={this.onMemberChecked.bind(this, type.id)}
                                        />
                                        {Language.get("nc_include_members")}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                    <div>
                        <select value={0} onChange={this.onTypeChecked.bind(this)}>
                            <option value={0}>{Language.get("nc_add_summary_type")}</option>
                            {_.filter(
                                this.state.types,
                                type => !_.contains(this.state.selectedTypes, type.id)
                            ).map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.name}{" "}
                                    {this.isDuplicateName(this.state.types, type)
                                        ? `(${type.extid})`
                                        : null}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {this.state.id > 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "space-between",
                        }}
                    >
                        <input
                            type="button"
                            className="uiText teButton dangerous"
                            style={{ marginLeft: "auto" }}
                            onClick={this.onDelete.bind(this)}
                            value={Language.get("nc_list_option_delete_selected")}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

module.exports = SummaryEditor;
