const PropTypes = require("prop-types");
const React = require("react");
const ListCalendar = require("./ListCalendar");
const WaitingList = require("./WaitingList");
const Macros = require("../models/Macros");

class WaitingListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
        focusFirstCalendar: PropTypes.func,
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
    ];

    onSelection = (selection) => {
        this.context.fireEvent(
            `waitingList${this.props.id}`,
            Macros.Event.SELECT_RESERVATION,
            selection
        );
        this.context.focusFirstCalendar();
        this.props.onInfoOpen([selection.id], false);
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"waitingList"}
                refreshEvents={this.getRefreshEvents()}
                onSelection={this.onSelection}
            >
                <WaitingList />
            </ListCalendar>
        );
    }
}

module.exports = WaitingListCalendar;
