import { MillenniumWeek, MillenniumWeekday, SimpleDateFormat } from "@timeedit/millennium-time";
const PeriodHeader = require("./PeriodHeader");
const Locale = require("../lib/Language");
const _ = require("underscore");

const DAYS_IN_WEEK = 7;

const WeekPeriodHeader = function (visibleValues, firstVisibleValue, subheader) {
    PeriodHeader.call(this, visibleValues || 1, firstVisibleValue, subheader, "WeekPeriodHeader");
    this.weekdays = [];
};

WeekPeriodHeader.parse = function (data) {
    // eslint-disable-line no-unused-vars
    const header = new WeekPeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.values = data.periods.map((period) =>
        MillenniumWeek.create(period, Locale.firstDayOfWeek, Locale.daysInFirstWeek)
    );

    header.weekdays = data.weekDays.map((weekday) =>
        MillenniumWeekday.getByRepresentation(weekday)
    );
    if (header.weekdays.length === 0) {
        header.weekdays = [Locale.firstDayOfWeek];
    }

    header.validateValues();

    return header;
};

WeekPeriodHeader.prototype = Object.create(PeriodHeader.prototype);

WeekPeriodHeader.prototype.getKind = function () {
    return PeriodHeader.KIND.WEEK;
};

const getWeekFromDayNumber = function (day) {
    const week = new MillenniumWeek(day, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    return {
        label: SimpleDateFormat.format(
            week.getStartOfWeek(),
            Locale.getDateFormat("date_f_yyyy_ww_l")
        ),
        value: week.week(),
    };
};

WeekPeriodHeader.prototype.getIndexOfDate = function (date, onlyVisible = false) {
    const soughtDay = date.getDayNumber();
    const soughtWeek = getWeekFromDayNumber(soughtDay);
    return this.getIndexOfWeek(soughtWeek, onlyVisible);
};

WeekPeriodHeader.prototype.getIndexOfWeek = function (soughtWeek, onlyVisible = false) {
    const values = onlyVisible ? this.getVisibleValues() : this.getValues();
    return values.findIndex((weeks) => weeks.some((week) => week.week() === soughtWeek));
};

WeekPeriodHeader.prototype.getSettings = function (providers) {
    const settings = PeriodHeader.prototype.getSettings.call(this);
    const self = this;

    settings.items.push({
        id: "period",
        label: Locale.get("cal_list_weeks"),
        type: "groups",
        availableValues() {
            const startDay = self.limits.getStartDate().getDayNumber();
            return _.range(
                startDay,
                startDay + self.limits.dayCount + DAYS_IN_WEEK - 1,
                DAYS_IN_WEEK
            ).map(getWeekFromDayNumber);
        },
        get() {
            return {
                names: self.names,
                values: self.values.map((period) => period.map((item) => item.week())),
            };
        },
        set(groups) {
            return self.immutableSet({
                names: groups.names,
                values: groups.values.map((period) =>
                    period.map((week) =>
                        MillenniumWeek.create(week, Locale.firstDayOfWeek, Locale.daysInFirstWeek)
                    )
                ),
            });
        },
    });

    if (providers.weekday === true) {
        return settings;
    }

    settings.items.push({
        id: "weekdays",
        label: Locale.get("cal_list_weekdays"),
        type: "array",
        limit: DAYS_IN_WEEK,
        get: self.getWeekdays.bind(self),
        set(val) {
            return self.immutableSet({ weekdays: val });
        },
    });

    return settings;
};

WeekPeriodHeader.prototype.getSimplePeriodIndex = function (entry, onlyVisible = false) {
    if (!this.isSimplePeriod()) {
        throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
    }

    const isMatch = (value) =>
        value.week(true) ===
        entry.startTimes[0].getWeek(true, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    const index = _.flatten(this.getValues()).findIndex(isMatch);
    if (!onlyVisible) {
        return index;
    }
    return index - this.firstVisibleValue;
};

WeekPeriodHeader.prototype.getWeekdays = function () {
    const labels = Locale.getWeekdayLabels();
    const values = MillenniumWeekday.getLocalizedWeekdayList(Locale.firstDayOfWeek).map((val) => ({
        value: val,
        label: labels[val.getDay()],
    }));

    return values.map((item) =>
        _.extend(item, {
            selected: this.weekdays.some((weekday) => weekday === item.value),
        })
    );
};

WeekPeriodHeader.prototype.periodsToJSON = function () {
    return _.flatten(this.values.map((weeks) => weeks.map((week) => week.week())));
};

WeekPeriodHeader.prototype.toJSON = function () {
    const json = PeriodHeader.prototype.toJSON.call(this);
    return _.extend(json, {
        dayProvider: true,
        kind: "periodweek",
        periods: this.values.map((weeks) => weeks.map((week) => week.week())),
        weekDays: this.weekdays.map((weekday) => weekday.getRepresentation()),
    });
};

module.exports = WeekPeriodHeader;
