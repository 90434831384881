/* eslint-disable no-unused-vars */
import ColorCell from "../components/ColorCell";
import Language from "../lib/Language";
import _ from "underscore";

const TYPE_COLUMN_OFFSET = 1000;
const FIELD_COLUMN_OFFSET = 10000;

const INFO_COLLISION = "COLLIDES_WITH_INFO_RESERVATIONS";

const ColumnConstants = {
    TYPE_COLUMN_OFFSET,
    FIELD_COLUMN_OFFSET,
    INFO_COLLISION,

    getCollisionColumn: () => ({
        id: TYPE_COLUMN_OFFSET,
        name: Language.get("nc_mass_change_collisions"),
        primary: true,
    }),
    getMessageColumn: () => ({
        name: Language.get("cal_res_side_email_message"),
        sortable: false,
        id: TYPE_COLUMN_OFFSET - 1,
    }),
    RESERVATION_SORT_ORDERS: {
        ID_ASC: 0,
        ID_DESC: 1,
        TIME_ASC: 2,
        TIME_DESC: 3,
        MODIFIED_ASC: 4,
        MODIFIED_DESC: 5,
        TAGS_ASC: 6,
        TAGS_DESC: 7,
        CREATED_ASC: 8,
        CREATED_DESC: 9,
    },

    checkboxCellRenderer: (
        cellData,
        columnData,
        columnIndex,
        dataKey,
        isScrolling,
        rowData,
        rowIndex
    ) => {
        const values = _.asArray(cellData.cellData);
        if (
            values.length === 0 ||
            _.every(values, (val) => val === 0 || val === "" || val === "0")
        ) {
            return "";
        }
        return <span className={"chkField checked"} />;
    },

    collisionCellRenderer: (
        cellData,
        columnData,
        columnIndex,
        dataKey,
        isScrolling,
        rowData,
        rowIndex
    ) => {
        const red = {
            baseColor: "C62E2D",
            basePattern: "",
            name: "Red",
            id: "red",
        };
        const green = {
            baseColor: "32D296",
            basePattern: "",
            name: "Green",
            id: "green",
        };
        const yellow = {
            baseColor: "E2CB2B",
            basePattern: "",
            name: "Yellow",
            id: "yellow",
        };
        let color = green;
        let title = Language.get("nc_no_collisions");
        const collisions = cellData.cellData;
        if (collisions === INFO_COLLISION) {
            color = yellow;
            title = Language.get("nc_tooltip_reservation_overlaps_info_reservations");
        } else if (collisions > 0) {
            color = red;
            title = Language.get("nc_x_collisions", collisions);
        }
        return (
            <ColorCell
                title={title}
                round={true}
                name={color.name}
                color={color.baseColor}
                pattern={color.basePattern}
                id={color.id}
            />
        );
    },
};

module.exports = ColumnConstants;
