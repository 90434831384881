const Model = require("./Model");
const Limits = require("./Limits");
const Language = require("../lib/Language");
const _ = require("underscore");

/**
 * Base header object.
 */
const Header = function (visibleValues, firstVisibleValue, subheader, typeName) {
    Model.call(this, typeName ? typeName : "Header");
    this.visibleValues = visibleValues < 1 ? 1 : visibleValues;
    this.subheader = subheader || null;
    this.isActive = false;
    this.isScrollable = true;
    this.showInfo = false;
    this.hideGrid = false;
    this.hide = false;
    this.limits = null;
    this.majorStepLimit = 0;
    this.size = 0;
    this._firstVisibleValue = firstVisibleValue || 0;
    this.typeName = typeName || "Header";
    this.isSideBySide = false;
    this.sideBySideValues = [];
};

Header.prototype = Object.create(Model.prototype);

Header.Label = { XS: 0, S: 1, M: 2, L: 3, XL: 4 };

Object.defineProperty(Header.prototype, "firstVisibleValue", {
    get() {
        return this._firstVisibleValue;
    },
    set(val) {
        if (!_.isNumber(val)) {
            throw new Error(Language.get("nc_validation_error_not_an_integer", val));
        }
        if (val % 1 !== 0) {
            throw new Error(Language.get("nc_validation_error_not_an_integer", val));
        }
        this._firstVisibleValue = val;
        if (val < 0) {
            this._firstVisibleValue = 0;
        }
        if (this.isDateOrTime() && val > this.length() - this.visibleValues) {
            this._firstVisibleValue = this.length() - this.visibleValues;
        }
    },
    enumerable: true,
});

Header.prototype.isDateOrTime = function () {
    return this.typeName === "DateHeader" || this.typeName === "TimeHeader";
};

Header.prototype.getVisibleValues = function () {
    return this.getValues().filter(function (el, index) {
        return (
            index >= this.firstVisibleValue && index < this.firstVisibleValue + this.visibleValues
        );
    }, this);
};

Header.prototype.getValues = function () {
    throw new Error("Header must implement getValues method.");
};

Header.prototype.getSections = function () {
    return [];
};

Header.prototype.getHeaders = function (onlyVisible = false) {
    const headers = [];
    let currentHeader = this;
    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentHeader !== null && (!onlyVisible || !currentHeader.hide)) {
        headers.push(currentHeader);
        currentHeader = currentHeader.subheader;
    }
    return headers;
};

Header.prototype.getActiveHeader = function () {
    return _.find(this.getHeaders(), (header) => header.isActive);
};

Header.prototype.getNumberOfHeaderItems = function () {
    let currentHeader = this;
    let count = 0;
    while (currentHeader !== null) {
        if (currentHeader.hide) {
            break;
        }
        // eslint-disable-next-line no-magic-numbers
        count = count + (currentHeader.showInfo ? 2 : 1);
        currentHeader = currentHeader.subheader;
    }
    return count;
};

Header.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    const diff = {
        limits,
    };

    if (this.subheader) {
        diff.subheader = this.subheader.setLimits(limits);
    }

    return this.immutableSet(diff);
};

Header.prototype.addSubheader = function (subheader) {
    return this.immutableSet({
        subheader: subheader.immutableSet({ subheader: this.subheader }),
    });
};

Header.prototype.setActiveHeader = function (activeHeader) {
    const diff = {
        isActive: this === activeHeader,
    };

    if (this.subheader) {
        diff.subheader = this.subheader.setActiveHeader(activeHeader);
    }

    return this.immutableSet(diff);
};

Header.prototype.hasTime = function () {
    // eslint-disable-next-line global-require
    const TimeHeader = require("./TimeHeader");
    if (this instanceof TimeHeader) {
        return true;
    }
    return this.subheader ? this.subheader.hasTime() : false;
};

Header.prototype.hasTimePeriod = function () {
    // eslint-disable-next-line global-require
    const PeriodHeader = require("./PeriodHeader");
    // eslint-disable-next-line global-require
    const TimePeriodHeader = require("./TimePeriodHeader");
    if (this instanceof PeriodHeader || this instanceof TimePeriodHeader) {
        return true;
    }
    return this.subheader ? this.subheader.hasTimePeriod() : false;
};

Header.prototype.increaseFirstVisibleValue = function () {
    if (this.majorStepLimit && this.visibleValues >= this.majorStepLimit) {
        return this.increaseMajorStep();
    }

    if (this.firstVisibleValue + this.visibleValues + 1 > this.length(false)) {
        return this;
    }
    return this.immutableSet({
        firstVisibleValue: this.firstVisibleValue + 1,
    });
};

Header.prototype.decreaseFirstVisibleValue = function () {
    if (this.majorStepLimit && this.visibleValues >= this.majorStepLimit) {
        return this.decreaseMajorStep();
    }

    if (this.firstVisibleValue - 1 < 0) {
        return this;
    }
    return this.immutableSet({
        firstVisibleValue: this.firstVisibleValue - 1,
    });
};

Header.prototype.increaseMajorStep = function () {
    throw new Error("Header must implement increaseMajorStep method if a major step limit is set.");
};

Header.prototype.decreaseMajorStep = function () {
    throw new Error("Header must implement increaseMajorStep method if a major step limit is set.");
};

Header.prototype.getPosition = function (entry) {
    let position = this.indexOf(entry, true) / this.visibleValues;

    if (this.subheader) {
        position += this.subheader.getPosition(entry) / this.visibleValues;
    }

    return position;
};

Header.prototype.swapHeader = function () {
    return this.immutableSet((newHeader) => {
        const newTopHeader = newHeader.subheader.subheader;
        // eslint-disable-next-line no-param-reassign
        newHeader.subheader.subheader = newTopHeader.subheader;
        newTopHeader.subheader = newHeader.subheader;
        // eslint-disable-next-line no-param-reassign
        newHeader.subheader = newTopHeader;
    });
};

Header.prototype.setSubheader = function (newSubheader) {
    let isActive = this.isActive;
    if (!newSubheader && this.subheader && this.subheader.isActive) {
        isActive = true;
    }

    if (!newSubheader) {
        // eslint-disable-next-line no-param-reassign
        newSubheader = this.subheader ? this.subheader.subheader : null;
    }
    return this.immutableSet({
        subheader: newSubheader,
        isActive,
    });
};

Header.prototype.setShowGrid = function (showGrid) {
    let subheader = this.subheader;
    if (subheader && !showGrid) {
        subheader = subheader.setShowGrid(false);
    }

    return this.immutableSet({
        hideGrid: !showGrid,
        subheader,
    });
};

Header.prototype.setVisibleValues = function (visibleValues) {
    if (visibleValues < 1) {
        // eslint-disable-next-line no-param-reassign
        visibleValues = 1;
    }

    const max = this.length();
    if (visibleValues > max) {
        // eslint-disable-next-line no-param-reassign
        visibleValues = max;
    }

    const delta = { visibleValues };
    if (this.firstVisibleValue + visibleValues > this.length()) {
        delta.firstVisibleValue = this.length() - visibleValues;
    }

    return this.immutableSet(delta);
};

Header.prototype.setSize = function (size) {
    return this.immutableSet({ size });
};

Header.prototype.resetSize = function () {
    let subheader = this.subheader;
    if (subheader) {
        subheader = subheader.resetSize();
    }

    return this.immutableSet({
        size: 0,
        subheader,
    });
};

// eslint-disable-next-line no-unused-vars
Header.prototype.isCurrent = function (date, currentDateTime) {
    return false;
};

Header.prototype.hasInfo = function () {
    return true;
};

// eslint-disable-next-line no-unused-vars
Header.prototype.getInfo = function (value) {
    return null;
};

Header.prototype.getCellsPerInfoCell = function () {
    return 1;
};

// eslint-disable-next-line no-unused-vars
Header.prototype.isNewSection = function (index) {
    return false;
};

Header.prototype.length = function (onlyVisible) {
    if (onlyVisible) {
        return this.getVisibleValues().length;
    }

    return this.getValues().length;
};

Header.prototype.toggleSideBySideForValue = function (value) {
    const val = JSON.stringify(value);
    if (this.sideBySideValues.indexOf(val) !== -1) {
        return this.immutableSet({
            sideBySideValues: this.sideBySideValues.filter((idx) => idx !== val),
        });
    }
    return this.immutableSet({ sideBySideValues: this.sideBySideValues.concat(val) });
};

Header.prototype.isSideBySideAtIndex = function (index) {
    return (
        this.isSideBySide ||
        this.sideBySideValues.indexOf(JSON.stringify(this.getVisibleValues()[index])) !== -1
    );
};

// eslint-disable-next-line no-unused-vars
Header.prototype.getSettings = function (providers) {
    // eslint-disable-next-line global-require
    const Settings = require("./Settings");
    const self = this;
    const list = new Settings([
        {
            id: "firstVisibleValue",
            label: Language.get("nc_header_settings_first_visible_value"),
            type: "integer",
            get() {
                return self.firstVisibleValue;
            },
            set(val) {
                return self.immutableSet({ firstVisibleValue: val });
            },
        },
        {
            id: "visibleValues",
            label: Language.get("nc_header_settings_visible_values"),
            type: "integer",
            get() {
                return self.visibleValues;
            },
            set(val) {
                return self.setVisibleValues(val);
            },
        },
        {
            id: "showGrid",
            label: Language.get("cal_res_side_view_grid"),
            type: "boolean",
            get() {
                return !self.hideGrid;
            },
            set(val) {
                return self.setShowGrid(val);
            },
        },
        {
            id: "showHeader",
            label: Language.get("cal_res_side_view_header"),
            type: "boolean",
            get() {
                return !self.hide;
            },
            set(val) {
                return self.immutableSet({ hide: !val });
            },
        },
    ]);

    if (this.hasInfo() || this.showInfo === true) {
        list.items.push({
            id: "showInfo",
            label: Language.get("cal_res_side_view_info_header"),
            type: "boolean",
            get() {
                return self.showInfo;
            },
            set(val) {
                return self.immutableSet({ showInfo: val });
            },
        });
    }

    if (!this.subheader) {
        list.items.push({
            id: "isSideBySide",
            label: Language.get("nc_side_by_side_title"),
            details: Language.get("nc_side_by_side_details"),
            type: "boolean",
            get() {
                return self.isSideBySide;
            },
            set(val) {
                return self.immutableSet({ isSideBySide: val });
            },
        });
    }

    return list;
};

Header.prototype.toJSON = function () {
    return {
        firstValue: this.firstVisibleValue,
        hideGrid: this.hideGrid,
        hideHeader: this.hide,
        scrollable: this.isScrollable,
        showInfo: this.showInfo,
        visibleValues: this.visibleValues,
        timeProvider: false,
        dayProvider: false,
        size: 0,
        isSideBySide: this.subheader ? false : this.isSideBySide,
        sideBySideValues: this.sideBySideValues,
    };
};

module.exports = Header;
