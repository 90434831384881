const Kind = function (number, name, mode) {
    this.number = number;
    this.name = name;
    this.mode = mode.toLowerCase();
};

const TemplateKind = {};
const kinds = ["NULL", "RESERVATION", "AVAILABILITY", "INFO_RESERVATION"];

TemplateKind.get = function (number) {
    const intValue = parseInt(number, 10) || 0;
    return TemplateKind[kinds[intValue]];
};

TemplateKind.getFromString = function (string) {
    const result = TemplateKind[string.toUpperCase()];
    if (result) {
        return result;
    }
    return TemplateKind.NULL;
};

TemplateKind.equals = function (kindA, kindB) {
    if (kindA === kindB) {
        return true;
    }

    if (kindA === null || kindA === undefined || kindB === null || kindB === undefined) {
        return false;
    }

    return kindA.number === kindB.number;
};

module.exports = (function () {
    kinds.forEach((mode, index) => {
        TemplateKind[mode] = new Kind(index, `TEMPLATE_KIND_${mode}`, mode);
    });
    return TemplateKind;
})();
