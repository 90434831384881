const React = require("react");
const _ = require("underscore");

const SelectSignature = props => {
    const selectProps = _.pick(props, ["name", "onChange", "value", "className"]);
    return (
        <select {...selectProps}>
            {props.values.map(value => (
                <option key={value.signature} value={value.signature}>
                    {value.name}
                </option>
            ))}
        </select>
    );
};

module.exports = SelectSignature;
