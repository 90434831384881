const React = require("react");
const createReactClass = require("create-react-class");
import { MillenniumDateTime, MillenniumTime, SimpleDateFormat } from "@timeedit/millennium-time";
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const FieldInput = require("./FieldInput");
const TC = require("../lib/TimeConstants");

const TOOLTIP_LEFT_OFFSET = 265;
const TOOLTIP_RIGHT_OFFSET = -10;

const ReservationTooltip = createReactClass({
    displayName: "ReservationTooltip",

    getInitialState() {
        return {
            types: [],
        };
    },

    componentDidMount() {
        this._isMounted = true;
        API.findTypes((types) => {
            if (this._isMounted) {
                this.setState({ types });
            }
        });
        API.findFields((fields) => {
            API.getFieldDefs(
                fields.map((f) => f.id),
                (defs) => {
                    if (this._isMounted) {
                        this.setState({ fields: defs });
                    }
                }
            );
        });
    },

    componentWillUnmount() {
        this._isMounted = false;
    },

    getTypeName(typeId) {
        const found = _.find(this.state.types, (type) => type.id === typeId);
        return found ? found.name : typeId;
    },

    renderDuration(length) {
        if (length === 0 || isNaN(length)) {
            return "0:00";
        }
        const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
        const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
        // eslint-disable-next-line no-magic-numbers
        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    },

    _renderMultiTime(startTimes, endTimes, names) {
        const allOnSameDate = (st, et) => {
            const dates = st
                .map((start) => start.format("yyyy-MM-dd"))
                .concat(et.map((end) => end.format("JJJJ-NN-bb")));
            const date = dates[0];
            return _.every(dates, (dt) => dt === date);
        };
        if (allOnSameDate(startTimes, endTimes)) {
            const rows = [
                <tr key="dateTimeFirst">
                    <th>{Language.get("cal_res_side_reservation_date")}</th>
                    <td>
                        {SimpleDateFormat.format(
                            startTimes[0],
                            Language.getDateFormat("date_f_yyyy_mm_dd")
                        )}
                    </td>
                </tr>,
            ];
            // if (this.state.reservations.length === 1 || !clusterProps.time) { // Is this check needed?
            rows.push(
                startTimes.map((start, index) => {
                    if (index === 0) {
                        return (
                            <tr key={`dateTimeFirst${index}`}>
                                <th>{Language.get("cal_res_side_reservation_time")}</th>
                                <td>
                                    <b>
                                        {SimpleDateFormat.format(
                                            start,
                                            Language.getDateFormat("date_f_hh_mm")
                                        )}{" "}
                                        -{" "}
                                        {SimpleDateFormat.format(
                                            endTimes[index],
                                            Language.getDateFormat("date_f_hh_mm_end")
                                        )}{" "}
                                        ({names[index]})
                                    </b>
                                </td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={`dateTimeSecond${index}`}>
                            <th />
                            <td>
                                {SimpleDateFormat.format(
                                    start,
                                    Language.getDateFormat("date_f_hh_mm")
                                )}{" "}
                                -{" "}
                                {SimpleDateFormat.format(
                                    endTimes[index],
                                    Language.getDateFormat("date_f_hh_mm_end")
                                )}{" "}
                                ({names[index]})
                            </td>
                        </tr>
                    );
                })
            );
            //}
            return rows;
        }
        const startLines = startTimes.map((start, index) => {
            if (index === 0) {
                return (
                    <tr key={`dateTimeFirst${index}`}>
                        <th>{Language.get("cal_res_side_reservation_begin")}</th>
                        <td>
                            <b>
                                {SimpleDateFormat.format(
                                    start,
                                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                                )}{" "}
                                ({names[index]})
                            </b>
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={`dateTimeFirst${index}`}>
                    <th />
                    <td>
                        {SimpleDateFormat.format(
                            start,
                            Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                        )}{" "}
                        ({names[index]})
                    </td>
                </tr>
            );
        });
        const endLines = endTimes.map((end, index) => {
            if (index === 0) {
                return (
                    <tr key={`dateTimeSecond${index}`}>
                        <th>{Language.get("cal_res_side_reservation_end")}</th>
                        <td>
                            <b>
                                {SimpleDateFormat.format(
                                    end,
                                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                                )}{" "}
                                ({names[index]})
                            </b>
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={`dateTimeSecond${index}`}>
                    <th />
                    <td>
                        {SimpleDateFormat.format(
                            end,
                            Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                        )}{" "}
                        ({names[index]})
                    </td>
                </tr>
            );
        });
        return startLines.concat(endLines);
    },

    renderTime(start, end, length) {
        if (start.format("yyyy-MM-dd") === end.format("JJJJ-NN-bb")) {
            return [
                <tr key="begin">
                    <td>
                        <b>{Language.get("cal_res_side_reservation_date")}</b>
                    </td>
                    <td>
                        {SimpleDateFormat.format(
                            start,
                            Language.getDateFormat("date_f_yyyy_mm_dd")
                        )}
                    </td>
                </tr>,
                <tr key="end">
                    <td>
                        <b>{Language.get("cal_res_side_reservation_time")}</b>
                    </td>
                    <td>
                        {SimpleDateFormat.format(start, Language.getDateFormat("date_f_hh_mm"))} -{" "}
                        {SimpleDateFormat.format(end, Language.getDateFormat("date_f_hh_mm_end"))} (
                        {this.renderDuration(length)})
                    </td>
                </tr>,
            ];
        }

        return [
            <tr key="begin">
                <td>
                    <b>{Language.get("cal_res_side_reservation_begin")}</b>
                </td>
                <td>
                    {SimpleDateFormat.format(
                        start,
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                    )}
                </td>
            </tr>,
            <tr key="end">
                <td>
                    <b>{Language.get("cal_res_side_reservation_end")}</b>
                </td>
                <td>
                    {SimpleDateFormat.format(
                        end,
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                    )}
                </td>
            </tr>,
        ];
    },

    render() {
        if (!this.props.reservations || this.props.reservations.length === 0) {
            return null;
        }
        const res = this.props.reservations[0];
        if (!res) {
            return null;
        }
        let objects = [];
        let prevTypeName = null;

        const convertFieldValues = (fieldId, values = []) => {
            const def = _.find(this.state.fields, (fld) => fld.id === fieldId);
            if (!def) {
                return values;
            }
            if (def.kind === FieldInput.fieldKind.LENGTH) {
                return values.map((val) => new MillenniumTime(parseInt(val, 10)).format("HH:mm"));
            }
            if (def.kind === FieldInput.fieldKind.BOOLEAN) {
                return values.map((val) => {
                    if (val === "1") {
                        return Language.get("dynamic_field_yes");
                    }
                    return Language.get("dynamic_field_no");
                });
            }
            return values;
        };

        if (res.objects) {
            objects = res.objects.map((object, index) => {
                if (!object || !object.fields) {
                    return null;
                }
                let typeName = this.getTypeName(object.type.id);
                if (typeName === prevTypeName) {
                    typeName = null;
                } else {
                    prevTypeName = typeName;
                }
                const values = object.fields.map((field) =>
                    convertFieldValues(field.id, field.values).join(", ")
                );
                return (
                    <tr key={index}>
                        <td className="fieldLabel">
                            <b>{typeName}</b>
                        </td>
                        <td className="wrapText" title={values}>
                            {values}
                        </td>
                    </tr>
                );
            });
        }

        const getFieldName = (id) => {
            const field = _.find(this.state.fields, (fld) => fld.id === id);
            if (field) {
                return field.name;
            }
            return id;
        };

        let fields = null;
        if (res.fields) {
            fields = res.fields
                .map((resField) => ({
                    id: resField.id,
                    value: convertFieldValues(resField.id, resField.values).join(", "),
                }))
                .filter((element) => element.value !== "")
                .map((fld, index) => {
                    const style = index === 0 ? { paddingTop: "5px" } : {};
                    return (
                        <tr key={index}>
                            <td className="fieldLabel" style={style}>
                                <strong>{getFieldName(fld.id)}</strong>
                            </td>
                            <td className="wrapText" style={style}>
                                {fld.value}
                            </td>
                        </tr>
                    );
                });
        }

        let timeText = this.renderTime(
            new MillenniumDateTime(res.begin),
            new MillenniumDateTime(res.end),
            res.length
        );

        if (res.timezones) {
            const rowData = { startTimes: [], endTimes: [], names: [] };
            res.timezones.forEach((tz) => {
                rowData.startTimes.push(new MillenniumDateTime(tz.begin));
                rowData.endTimes.push(new MillenniumDateTime(tz.end));
                rowData.names.push(tz.name);
            });
            timeText = this._renderMultiTime(rowData.startTimes, rowData.endTimes, rowData.names);
        }

        const locks =
            res.lock && res.lock === "soft" ? (
                <tr key="locks">
                    <td className="fieldLabel">
                        <strong>{Language.get("nc_reservation_locked")}</strong>
                    </td>
                    <td className="wrapText">{res.lockedDescription || ""}</td>
                </tr>
            ) : null;

        const text = (
            <span>
                <table>
                    <tbody>
                        {timeText}
                        {objects}
                        {fields}
                        {locks}
                    </tbody>
                </table>
            </span>
        );

        let tooltipStyle = null;
        if (this.props.mousePosition) {
            const leftOffset = this.props.tooltipLeft ? TOOLTIP_LEFT_OFFSET : TOOLTIP_RIGHT_OFFSET;
            const top = this.props.mousePosition.y - this.props.mousePosition.offset.top;
            let left =
                this.props.mousePosition.x - leftOffset - this.props.mousePosition.offset.left;
            if (this.props.tooltipLeft) {
                if (left < 0) {
                    left -= left;
                }
            }
            tooltipStyle = { top, left };
        }
        if (!tooltipStyle) {
            return null;
        }
        return this.props.isVisible ? (
            <div ref="tooltip" className="entryTooltip" style={tooltipStyle}>
                {text}
            </div>
        ) : null;
    },
});

module.exports = ReservationTooltip;
