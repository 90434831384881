const PropTypes = require("prop-types");
const React = require("react");
const API = require("../lib/TimeEditAPI");
const ArrayInput = require("./ArrayInput");
const _ = require("underscore");

const DEFAULT_SIZE = 5;

class ColorTypeInput extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
        onUpdate: PropTypes.func,
    };

    state = {
        types: [],
    };

    componentDidMount() {
        API.getColorTypes(types => {
            this.setState({ types: [{ id: 0, name: "-" }].concat(types) });
        });
    }

    render() {
        const types = this.state.types.map(type => ({
            value: type.id,
            label: type.name,
            selected: _.contains(this.props.defaultValue, type.id) || type.id === 0 && this.props.defaultValue.length === 0,
        }));
        return (
            <ArrayInput
                defaultValue={types}
                disabled={this.state.types.length === 0}
                onUpdate={this.props.onUpdate}
                limit={this.props.limit || 0}
                defaultSize={this.props.defaultSize || DEFAULT_SIZE}
            />
        );
    }
}

module.exports = ColorTypeInput;
