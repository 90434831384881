const React = require("react");
const IntegerInput = require("./IntegerInput");
const ArrayInput = require("./ArrayInput");
const Language = require("../lib/Language");
import { MillenniumDate } from "@timeedit/millennium-time";
import { DAYS_PER_WEEK } from "../lib/TimeConstants";

const Unit = {
    DAYS: 1,
    WEEKS: 7,
};

const MAX_ABS_DAY = 65535;
const DAYS_AHEAD = 100;

class DateOffsetInput extends React.Component {
    constructor(props) {
        super(props);
        const defaultUnit = props.defaultValue % Unit.WEEKS === 0 ? Unit.WEEKS : Unit.DAYS;
        const defaultValue = props.defaultValue / defaultUnit;

        this.state = {
            value: defaultValue,
            unit: defaultUnit,
        };
    }

    adjustValue = (val, unit) => {
        const dayValue = val * unit;
        const absoluteDay = MillenniumDate.today().getDayNumber() + dayValue;
        if (unit === Unit.DAYS) {
            if (absoluteDay < 0) {
                // eslint-disable-next-line no-param-reassign
                val = val + Math.abs(absoluteDay) + DAYS_AHEAD;
            }
            if (absoluteDay > MAX_ABS_DAY) {
                // eslint-disable-next-line no-param-reassign
                val = MAX_ABS_DAY;
            }
        }
        if (unit === Unit.WEEKS) {
            if (absoluteDay < 0) {
                // eslint-disable-next-line no-param-reassign
                val = Math.floor((dayValue + Math.abs(absoluteDay) + DAYS_AHEAD) / DAYS_PER_WEEK);
            }
            if (absoluteDay > MAX_ABS_DAY) {
                // eslint-disable-next-line no-param-reassign
                val = Math.floor(
                    (MAX_ABS_DAY - MillenniumDate.today().getDayNumber()) / DAYS_PER_WEEK
                );
            }
        }
        return val;
    };

    setValue = (val) => {
        if (val === this.state.value) {
            return;
        }

        // eslint-disable-next-line no-param-reassign
        val = this.adjustValue(val, this.state.unit);

        this.setState({ value: val });
        this.props.onUpdate(val * this.state.unit);
    };

    setUnit = (unit) => {
        if (unit === this.state.unit) {
            return;
        }

        const newValue = this.adjustValue(
            Math.round(this.state.value * (this.state.unit / unit)),
            unit
        );
        this.props.onUpdate(newValue * unit);
        this.setState({
            value: newValue,
            unit,
        });
    };

    render() {
        const units = Object.keys(Unit).map((unit) => ({
            label: Language.get(
                `cal_reservation_action_button_${unit.toLowerCase()}`
            ).toLowerCase(),
            value: Unit[unit],
            selected: Unit[unit] === this.state.unit,
        }));

        return (
            <div className="multiInput">
                <IntegerInput defaultValue={this.state.value} onUpdate={this.setValue} />
                <ArrayInput defaultValue={units} onUpdate={this.setUnit} limit={1} />
            </div>
        );
    }
}

module.exports = DateOffsetInput;
