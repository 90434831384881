const buffers = {};

const AudioContext = window.AudioContext || window.webkitAudioContext;
const context = AudioContext ? new AudioContext() : null;

const playBuffer = function (buffer) {
    if (!context) {
        return;
    }
    const source = context.createBufferSource();
    source.buffer = buffer;
    source.connect(context.destination);
    source.start(0);
};

const Sound = {};
Sound.load = function (url, cb) {
    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer";

    // Decode asynchronously
    request.onload = function () {
        if (!context) {
            return;
        }
        context.decodeAudioData(
            request.response,
            (buffer) => {
                if (cb) {
                    cb(buffer);
                }
            },
            (err) => {
                throw err;
            }
        );
    };

    request.send();
};

Sound.play = function (url) {
    if (buffers[url]) {
        playBuffer(buffers[url]);
        return;
    }

    Sound.load(url, (buffer) => {
        buffers[url] = buffer;
        playBuffer(buffer);
    });
};

module.exports = Sound;
