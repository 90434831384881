const PropTypes = require("prop-types");
const React = require("react");
const ListCalendar = require("./ListCalendar");
const ConflictList = require("./ConflictList");
const Macros = require("../models/Macros");

class ConflictListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
        Macros.Event.RESERVATION_RESTORED,
    ];

    onSelection = (firstReservation, secondReservation) => {
        this.context.fireEvent(
            `conflictList${this.props.id}`,
            Macros.Event.SELECT_RESERVATION,
            firstReservation
        );
        this.props.onInfoOpen([firstReservation.id, secondReservation.id], false);
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"conflictList"}
                refreshEvents={this.getRefreshEvents()}
                onSelection={this.onSelection}
            >
                <ConflictList />
            </ListCalendar>
        );
    }
}

module.exports = ConflictListCalendar;
