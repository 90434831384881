const React = require("react");
const CSRF = require("../../lib/CSRF");
const _ = require("underscore");
const SelectSignature = require("./SelectSignature");
const API = require("../../lib/TimeEditAPI");
const LangUtils = require("../../lib/LangUtils");

class Login extends React.Component {
    state = {
        createUserUrl: null,
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedDatabase === prevProps.selectedDatabase) {
            return;
        }

        const database = this.props.selectedDatabase.split("@")[0];
        API.getWebLoginCreateUserUrl(
            this.props.client,
            database,
            this.getLanguageString(),
            (result) => {
                if (!result || !result.parameters[0]) {
                    return;
                }

                this.setState({ createUserUrl: result.parameters[0] });
            }
        );
    }

    getLanguageString = () => (navigator.languages ? navigator.languages[0] : navigator.language);

    onSubmit = (event) => {
        const signature = document.getElementsByName("database")[0].value;
        const username = document.getElementsByName("username")[0].value;

        let errorMessage = null;
        if (!signature) {
            errorMessage = this.props.strings.nc_login_no_database_selected;
        }
        if (!username) {
            errorMessage = this.props.strings.nc_login_enter_username;
        }

        if (this.props.isRootLogin) {
            const rootUsername = document.getElementsByName("rootUsername")[0].value;
            const rootPassword = document.getElementsByName("rootPassword")[0].value;

            if (!rootUsername) {
                errorMessage = this.props.strings.nc_login_enter_username;
            }
            if (!rootPassword) {
                errorMessage = this.props.strings.nc_login_enter_password;
            }
        } else {
            const password = document.getElementsByName("password")[0].value;

            if (!password) {
                errorMessage = this.props.strings.nc_login_enter_password;
            }
        }

        if (errorMessage) {
            this.props.setErrorMessage(errorMessage);
            event.preventDefault();
        }
    };

    render() {
        return (
            <div>
                <form method="post" onSubmit={this.onSubmit}>
                    <ul>
                        <li>{this._renderDatabaseList()}</li>
                        {this.props.isRootLogin
                            ? this._renderRootLogin()
                            : this._renderStandardLogin()}
                        <li>{this._renderAuthServerList()}</li>
                        <li>
                            <input
                                type="hidden"
                                name="_csrf"
                                id="csrfToken"
                                value={CSRF.getToken()}
                            />
                            <input
                                type="submit"
                                id="loginButton"
                                disabled={!this.props.isAvailable}
                                value={this.props.strings.login_login}
                            />
                            <input type="hidden" name="lang" id="lang" value={LangUtils.getUserLanguage()} />
                        </li>
                    </ul>
                </form>

                <a className="forgotPassword" href="login/reset">
                    &raquo; {this.props.strings.nc_login_forgot_password}
                </a>
                {this._renderCreateUserLink()}
            </div>
        );
    }

    _renderStandardLogin = () => {
        const STRINGS = this.props.strings;
        return [
            <li key="username">
                <input
                    className="username"
                    name="username"
                    placeholder={STRINGS.login_user}
                    autoCorrect="off"
                    autoCapitalize="none"
                    autoFocus={true}
                    type="text"
                />
            </li>,
            <li key="password">
                <input
                    className="password"
                    name="password"
                    placeholder={STRINGS.login_password}
                    type="password"
                />
            </li>,
        ];
    };

    _renderRootLogin = () => [
        <li key="rootUsername">
            <input
                className="username"
                name="rootUsername"
                placeholder="Root username"
                autoCorrect="off"
                autoCapitalize="none"
                autoFocus={true}
                type="text"
            />
        </li>,
        <li key="rootPassword">
            <input
                className="password"
                name="rootPassword"
                placeholder="Root password"
                type="password"
            />
        </li>,
        <li key="username">
            <input
                name="username"
                placeholder="Username"
                autoCorrect="off"
                autoCapitalize="none"
                autoFocus={true}
                type="text"
            />
        </li>,
    ];

    _renderCreateUserLink = () => {
        if (!this.state.createUserUrl) {
            return null;
        }

        return (
            <a className="createUser" href={this.state.createUserUrl}>
                &raquo; {this.props.strings.nc_login_new_user}
            </a>
        );
    };

    _renderDatabaseList = () => {
        if (this.props.databases.length === 0) {
            return null;
        }

        return (
            <SelectSignature
                values={this.props.databases}
                name="database"
                onChange={this.props.onDatabaseSelected}
                value={this.props.selectedDatabase}
                className={_.classSet({ hidden: this.props.databases.length === 1 })}
            />
        );
    };

    _renderAuthServerList = () => {
        if (this.props.authServers.length === 0) {
            return null;
        }

        return (
            <SelectSignature
                values={this.props.authServers}
                name="authserver"
                className={_.classSet({ hidden: this.props.authServers.length === 1 })}
            />
        );
    };
}

module.exports = Login;
