const Header = require("./Header");

const EmptyHeader = function () {
    Header.call(this, 1, 0, null, "EmptyHeader");
    this.hide = true;
};

EmptyHeader.prototype = Object.create(Header.prototype);

// eslint-disable-next-line no-unused-vars
EmptyHeader.prototype.indexOf = function (entry, onlyVisible) {
    return 0;
};

// eslint-disable-next-line no-unused-vars
EmptyHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    return 1;
};

EmptyHeader.prototype.getValues = function () {
    return [{}];
};

EmptyHeader.prototype.valueAt = function () {
    return {};
};

// eslint-disable-next-line no-unused-vars
EmptyHeader.prototype.getLabel = function (obj, size) {
    return "";
};

// eslint-disable-next-line no-unused-vars
EmptyHeader.prototype.getLabel = function (obj, size) {
    return "";
};

// eslint-disable-next-line no-unused-vars
EmptyHeader.prototype.getSettings = function (providers) {
    return null;
};

module.exports = EmptyHeader;
