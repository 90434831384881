import React from "react";
import _ from "underscore";
import Language from "../lib/Language";
import ArrayInput from "./ArrayInput";

const getProgress = (track, mapData, numWeeks) => {
    const mappedIds = _.uniq([...mapData.map(data => data.activityId).filter(id => _.any(track, activity => activity.activityId === id)), ...track.filter(entry => entry.reservationId).map(entry => entry.activityId)]);
    const done = mappedIds.length/numWeeks;
    const total = track.length/numWeeks;
    // Done = done === total
    // Partial = done > 0 && done !== total
    return `(${done}/${total})`;
}

const TrackPane = (props: { tracks: any | undefined, mappedResult: any | undefined, onUpdate: any, currentTrack: any, onCancel: any, outerHeight: number, numWeeks: number }) => {
    if (!props.tracks || Object.keys(props.tracks).length === 0) {
        return null;
    }

    const HEIGHT_MODIFIER = 70;

    return (<div style={{ display: "flex" }}>
        <div className="listTopLine"><h1>Tracks</h1></div>
        <ArrayInput defaultSize={7} height={props.outerHeight - HEIGHT_MODIFIER} onUpdate={props.onUpdate} defaultValue={Object.entries(props.tracks).map(entry => ({
            label: `${entry[0]} ${getProgress(entry[1], props.mappedResult, props.numWeeks)}`,
            value: entry[0],
            selected: props.currentTrack === entry[1],
        }))}/>
        <button className="prefsOperationButton cancel" onClick={props.onCancel}>{Language.get("dialog_cancel")}</button>
    </div>)
};

export default TrackPane;