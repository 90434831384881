const EMERGENCY = 0;
const ALERT = 1;
const CRITICAL = 2;
const ERROR = 3;
const WARNING = 4;
const NOTICE = 5;
const INFO = 6;
const DEBUG = 7;

const messages = [];
const listeners = [];

const notify = (newEvent) => {
    const callbacks = listeners
        .filter((item) => item.levels.indexOf(newEvent.level) !== -1)
        .map((item) => item.listener);
    callbacks.forEach((callback) => callback(newEvent));
};

const log = (message, context, level) => {
    const logEvent = {
        time: Date.now(),
        level,
        message,
        context: context || {},
    };
    messages.push(logEvent);
    notify(logEvent);
};

const emergency = (message, context) => {
    log(message, context, EMERGENCY);
};

const alert = (message, context) => {
    log(message, context, ALERT);
};

const critical = (message, context) => {
    log(message, context, CRITICAL);
};

const error = (message, context) => {
    log(message, context, ERROR);
};

const warning = (message, context) => {
    log(message, context, WARNING);
};

const notice = (message, context) => {
    log(message, context, NOTICE);
};

const info = (message, context) => {
    log(message, context, INFO);
};

const debug = (message, context) => {
    log(message, context, DEBUG);
};

const addListener = (listener, levels) => {
    listeners.push({
        listener,
        levels: Array.isArray(levels) ? levels : [levels],
    });
};

module.exports = {
    listen: addListener,
    emergency,
    alert,
    critical,
    error,
    warning,
    notice,
    info,
    debug,
    EMERGENCY,
    ALERT,
    CRITICAL,
    ERROR,
    WARNING,
    NOTICE,
    INFO,
    DEBUG,
    ALL: [DEBUG, INFO, NOTICE, WARNING, ERROR, CRITICAL, ALERT, EMERGENCY],
    ALL_LOADS_FINISHED_MSG: "allLoadsFinished",
};
