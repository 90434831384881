const PropTypes = require("prop-types");
const React = require("react");
const Calendar = require("./Calendar");
const ReservationList = require("../models/ReservationSearch");
const ReservationListCalendar = require("./ReservationListCalendar");
const WaitingListCalendar = require("./WaitingListCalendar");
const CancellationList = require("../models/CancellationSearch");
const CancellationListCalendar = require("./CancellationListCalendar");
const RequestList = require("../models/RequestSearch");
const StaticReservations = require("../models/StaticReservations");
const StaticReservationListCalendar = require("./StaticReservationListCalendar");
const RequestListCalendar = require("./RequestListCalendar");
const OrderList = require("../models/OrderSearch");
const WaitingList = require("../models/WaitingListSearch");
const ConflictList = require("../models/ConflictSearch");
const OrderListCalendar = require("./OrderListCalendar");
const ConflictListCalendar = require("./ConflictListCalendar");
const CalendarIcon = require("./CalendarIcon");
const PrefsComponentCalendar = require("./preferences/PrefsComponentCalendar");
const ExamComponentCalendar = require("./exam/ExamComponentCalendar");
import MemberObjectCreatorCalendar from "./memberobjectcreator/MemberObjectCreatorCalendar";
const MemberCreator = require("../models/MemberData");
const PrefsData = require("../models/PrefsData");
const ExamData = require("../models/ExamData");
const _ = require("underscore");

const SIZE_MODIFIER = 30;

class TabbedSection extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
        clearExamRequest: PropTypes.func,
    };

    setActiveCalendar = (id) => {
        this.props.onActiveCalendarChange(id);
    };

    getActiveCalendar = () => {
        if (!this.props.data) {
            return <div />;
        }
        const calendar = this.props.data.getAllChildren()[this.props.activeCalendarId];
        let size = this.props.size;
        if (this.props.data.getAllChildren().length > 1) {
            size = { width: size.width, height: size.height - SIZE_MODIFIER };
        }
        if (calendar instanceof ReservationList) {
            return (
                <ReservationListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    infoEntryReservationIds={this.props.infoEntryReservationIds}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.handleEditReservationExceptions}
                />
            );
        } else if (calendar instanceof WaitingList) {
            return (
                <WaitingListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toRequestList={this.toRequestList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    infoEntryReservationIds={this.props.infoEntryReservationIds}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.handleEditReservationExceptions}
                />
            );
        } else if (calendar instanceof OrderList) {
            return (
                <OrderListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toConflictList={this.toConflictList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    onInfoOpen={this.props.onOrderInfoOpen}
                />
            );
        } else if (calendar instanceof CancellationList) {
            return (
                <CancellationListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toList={this.toList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    onEditReservationExceptions={this.props.handleEditReservationExceptions}
                />
            );
        } else if (calendar instanceof RequestList) {
            return (
                <RequestListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toList={this.toList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                />
            );
        } else if (calendar instanceof StaticReservations) {
            return (
                <StaticReservationListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={0}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    infoEntryReservationIds={this.props.infoEntryReservationIds}
                    onInfoOpen={this.props.onEntryInfoOpen}
                    reservationIds={calendar.first.reservationIds}
                />
            );
        } else if (calendar instanceof PrefsData) {
            return (
                <PrefsComponentCalendar
                    className="embeddedList"
                    data={calendar}
                    key={this.props.activeCalendarId}
                    id={this.props.activeCalendarId}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                />
            );
        } else if (calendar instanceof ExamData) {
            return (
                <ExamComponentCalendar
                    className="embeddedList"
                    data={calendar}
                    key={this.props.activeCalendarId}
                    id={this.props.activeCalendarId}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                />
            );
        } else if (calendar instanceof ConflictList) {
            return (
                <ConflictListCalendar
                    id={this.props.activeCalendarId}
                    size={size}
                    selection={this.props.selection}
                    data={calendar}
                    activeLayer={this.props.activeLayer}
                    massChangeEnabled={this.props.massChangeEnabled}
                    isMassChangeCopy={this.props.isMassChangeCopy}
                    setActiveLayer={this.props.setActiveLayer}
                    onLayerCreated={this.props.onLayerCreated}
                    getLayerName={this.getLayerName}
                    layers={this.props.layers}
                    showId={this.props.showCalendarIds}
                    isActive={true}
                    getCurrentFluffyItem={this.props.getCurrentFluffyItem}
                    toCalendar={this.toCalendar}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    toList={this.toList}
                    toOrerList={this.toOrderList}
                    toCancellationList={this.toCancellationList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                    className="embeddedList"
                    style={this.props.size}
                    onInfoOpen={this.props.onEntryInfoOpen}
                />
            );
        } else if (calendar instanceof MemberCreator) {
            return (
                <MemberObjectCreatorCalendar
                    {...this.props}
                    className="embeddedList"
                    data={calendar}
                    key={this.props.activeCalendarId}
                    id={this.props.activeCalendarId}
                    style={this.props.size}
                    toCalendar={this.toCalendar}
                    toList={this.toList}
                    toCancellationList={this.toCancellationList}
                    toOrderList={this.toOrderList}
                    toConflictList={this.toConflictList}
                    toWaitingList={this.toWaitingList}
                    toRequestList={this.toRequestList}
                    toPrefsComponent={this.toPrefsComponent}
                    toExamComponent={this.toExamComponent}
                    toMemberCreator={this.toMemberCreator}
                    onActiveCalendarChange={this.props.onActiveCalendarChange}
                    onCalendarSwap={this.props.onCalendarSwap}
                />
            );
        }
        return (
            <Calendar
                {...this.props}
                data={calendar}
                id={this.props.activeCalendarId}
                size={size}
                toList={this.toList}
                toCancellationList={this.toCancellationList}
                toOrderList={this.toOrderList}
                toConflictList={this.toConflictList}
                toWaitingList={this.toWaitingList}
                toRequestList={this.toRequestList}
                toPrefsComponent={this.toPrefsComponent}
                toExamComponent={this.toExamComponent}
                toMemberCreator={this.toMemberCreator}
                showId={this.props.showCalendarIds}
                isActive={true}
                onEditReservationExceptions={this.props.handleEditReservationExceptions}
            />
        );
    };

    getActiveSection = () => {
        const calendar = this.props.data.getAllChildren()[this.props.activeCalendarId];
        return this.props.data.getCalendarSection(calendar);
    };

    toList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toList(this.props.selection));
    };

    toCancellationList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toCancellationList(this.props.selection));
    };

    toOrderList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toOrderList(this.props.selection));
    };

    toWaitingList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toWaitingList(this.props.selection));
    };

    toRequestList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toRequestList(this.props.selection));
    };

    toConflictList = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toConflictList(this.props.selection));
    };

    toCalendar = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toCalendar(this.props.selection));
    };

    toPrefsComponent = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toPrefsComponent(this.props.selection));
    };

    toExamComponent = () => {
        this.context.clearExamRequest(() => {
            const section = this.getActiveSection();
            this.context.update(section, section.toExamComponent(this.props.selection));
        });
    };

    toMemberCreator = () => {
        const section = this.getActiveSection();
        this.context.update(section, section.toMemberCreator(this.props.selection));
    };

    render() {
        let calendarTabs = null;
        const calendars = this.props.data.getAllChildren();
        if (calendars.length > 1) {
            calendarTabs = (
                <ul className="calendarList">
                    {calendars.map(function (calendar, index) {
                        let cssClass = "";
                        if (index === this.props.activeCalendarId) {
                            cssClass = "active";
                        }
                        return (
                            <li
                                key={index}
                                className={cssClass}
                                onClick={this.setActiveCalendar.bind(this, index)}
                            >
                                <CalendarIcon
                                    calendar={calendar}
                                    size={24}
                                    isActive={index === this.props.activeCalendarId}
                                />
                            </li>
                        );
                    }, this)}
                </ul>
            );
        }
        return (
            <div className="tabbedSection" style={this.props.size}>
                {calendarTabs}

                {this.getActiveCalendar()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.data !== this.props.data ||
            nextProps.size.width !== this.props.size.width ||
            nextProps.size.height !== this.props.size.height ||
            nextProps.selection !== this.props.selection ||
            nextProps.showCalendarIds !== this.props.showCalendarIds ||
            nextProps.getCalendarIndex !== this.props.getCalendarIndex ||
            nextProps.onActiveCalendarChange !== this.props.onActiveCalendarChange ||
            nextProps.activeCalendarId !== this.props.activeCalendarId ||
            nextProps.copiedEntry !== this.props.copiedEntry ||
            !_.isEqual(nextProps.copiedGroupedEntries, this.props.copiedGroupedEntries)
        );
    }
}

module.exports = TabbedSection;
