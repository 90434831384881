const React = require("react");
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

class MassChangeBar extends React.Component {
    state = {
        ignoreUnchangedReservations: false,
        allowMassChangeDoubleReservations: false,
    };
    componentDidMount() {
        if (this.props.isModify) {
            API.getPreferences("ignoreUnchangedReservations", (ignore) => {
                this.setState({ ignoreUnchangedReservations: ignore || false });
            });
        }
    }
    onIgnoreUnchangedChanged(event) {
        API.setPreferences("ignoreUnchangedReservations", [event.target.checked], _.noop);
        this.setState({
            ignoreUnchangedReservations: event.target.checked,
        });
    }
    onAllowMassChangeDoubleReservationsChanged(event) {
        this.setState({
            allowMassChangeDoubleReservations: event.target.checked,
        });
    }

    render() {
        const loadIndicator = this.props.isWorking ? (
            <div className="text" style={{ float: "right", marginRight: "15px" }}>
                {Language.get("nc_mass_change_working", this.props.progress)}
            </div>
        ) : null;
        const ignoreUnchanged = this.props.isModify ? (
            <div
                className="ignoreUnchangedReservations"
                style={{ float: "right", marginRight: "15px" }}
            >
                <input
                    type="checkbox"
                    onChange={this.onIgnoreUnchangedChanged.bind(this)}
                    checked={this.state.ignoreUnchangedReservations}
                />
                {Language.get("nc_mass_change_ignore_unchanged")}
            </div>
        ) : null;
        const allowDoubleReservations = (
            <div
                className="ignoreUnchangedReservations"
                style={{ float: "right", marginRight: "15px" }}
            >
                <input
                    type="checkbox"
                    onChange={this.onAllowMassChangeDoubleReservationsChanged.bind(this)}
                    checked={this.state.allowMassChangeDoubleReservations}
                />
                {Language.get("nc_mass_change_allow_double_res")}
            </div>
        );
        const buttons = (
            <div>
                <div className="btnGroup horizontal">
                    <button
                        className="save massChangeBarBtn xWide"
                        disabled={this.props.isWorking}
                        onClick={() => {
                            this.props.performMassChange(
                                this.state.ignoreUnchangedReservations,
                                this.state.allowMassChangeDoubleReservations
                            );
                        }}
                    >
                        {Language.get("nc_mass_change_perform")}
                    </button>
                </div>
                {ignoreUnchanged}
                {allowDoubleReservations}
            </div>
        );

        return (
            <div ref="massChangeBar" className="massChangeBar" style={this.props.style}>
                <div className="container">
                    <div className="text">{this.props.title}</div>
                    {loadIndicator || buttons}
                </div>
            </div>
        );
    }
}

module.exports = MassChangeBar;
