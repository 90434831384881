const Model = require("./Model");
const Calendar = require("./Calendar");
const ReservationSearch = require("./ReservationSearch");
const CancellationSearch = require("./CancellationSearch");
const WaitingListSearch = require("./WaitingListSearch");
const PrefsData = require("./PrefsData");
const ExamData = require("./ExamData");
const MemberData = require("./MemberData");
const StaticReservations = require("./StaticReservations");
const OrderSearch = require("./OrderSearch");
const RequestSearch = require("./RequestSearch");
const ConflictSearch = require("./ConflictSearch");
const Selection = require("./Selection");
const McFluffy = require("./McFluffy");

const Section = function (first, second, weight, hasVerticalLayout) {
    Model.call(this, "Section");
    this.first = first;
    this.second = second || null;
    this.weight = weight || 1.0;
    this.hasVerticalLayout = hasVerticalLayout || false;
};

Section.prototype = Object.create(Model.prototype);

Section.prototype.hasCalendar = function () {
    return this.first instanceof Calendar;
};

Section.prototype.setComponent = function (calendar) {
    return this.immutableSet({
        first: calendar,
        second: null,
        weight: 1.0,
        previous: this.first,
    });
};

Section.prototype.hasReservationList = function () {
    return this.first instanceof ReservationSearch;
};

Section.prototype.hasCancellationList = function () {
    return this.first instanceof CancellationSearch;
};

Section.prototype.hasWaitingList = function () {
    return this.first instanceof WaitingListSearch;
};

Section.prototype.hasOrderList = function () {
    return this.first instanceof OrderSearch;
};

Section.prototype.hasRequestList = function () {
    return this.first instanceof RequestSearch;
};

Section.prototype.hasStaticReservations = function () {
    return this.first instanceof StaticReservations;
};

Section.prototype.hasPrefsComponent = function () {
    return this.first instanceof PrefsData;
};

Section.prototype.hasExamComponent = function () {
    return this.first instanceof ExamData;
};

Section.prototype.hasConflictList = function () {
    return this.first instanceof ConflictSearch;
};

Section.prototype.hasMemberCreator = function () {
    return this.first instanceof MemberData;
};

Section.prototype.hasChild = function () {
    return (
        this.hasCalendar() ||
        this.hasReservationList() ||
        this.hasCancellationList() ||
        this.hasOrderList() ||
        this.hasWaitingList() ||
        this.hasRequestList() ||
        this.hasStaticReservations() ||
        this.hasPrefsComponent() ||
        this.hasExamComponent() ||
        this.hasConflictList() ||
        this.hasMemberCreator()
    );
};

Section.prototype.splitToComponent = function (splitHorizontally = false, component) {
    // eslint-disable-next-line no-undef
    /*mixpanel.track("Splitting calendar", {
        Horizontally: splitHorizontally,
        "To component": component,
    });*/
    const self = this;
    return this.immutableSet({
        hasVerticalLayout: splitHorizontally,
        weight: 0.5,
        first: new Section(self.first, null, null, null),
        second: new Section(component, null, null, null),
    });
};

Section.prototype.split = function (splitHorizontally = false, splitWithReservationList = false) {
    // eslint-disable-next-line no-undef
    /*     mixpanel.track("Splitting calendar", {
        Horizontally: splitHorizontally,
        "To reservation list": splitWithReservationList,
    }); */
    const self = this;
    return this.immutableSet({
        hasVerticalLayout: splitHorizontally,
        weight: 0.5,
        first: new Section(self.first, null, null, null),
        second: new Section(
            splitWithReservationList
                ? new ReservationSearch(this.first.limits)
                : Calendar.create(this.first.limits),
            null,
            null,
            null
        ),
    });
};

Section.prototype.toList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating reservation list", {});
    if (this.previous && this.previous instanceof ReservationSearch) {
        return this.setComponent(this.previous);
    }
    const newSelection = new Selection().freeze();
    return this.setComponent(
        new ReservationSearch(
            this.first.limits,
            newSelection.immutableSet({
                fluffy: McFluffy.create(selection.fluffy.toJson(), selection.fluffy.labels),
            })
        )
    );
};

Section.prototype.toCancellationList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating cancellation list", {});
    if (this.previous && this.previous instanceof CancellationSearch) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new CancellationSearch(this.first.limits, selection));
};

Section.prototype.toOrderList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating order list", {});
    if (this.previous && this.previous instanceof OrderSearch) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new OrderSearch(this.first.limits, selection));
};

Section.prototype.toWaitingList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating waiting list", {});
    if (this.previous && this.previous instanceof WaitingListSearch) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new WaitingListSearch(this.first.limits, selection));
};

Section.prototype.toRequestList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating request list", {});
    if (this.previous && this.previous instanceof RequestSearch) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new RequestSearch(this.first.limits, selection));
};

Section.prototype.toConflictList = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating conflict list", {});
    if (this.previous && this.previous instanceof ConflictSearch) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new ConflictSearch(this.first.limits, selection));
};

Section.prototype.toCalendar = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating calendar", {});
    if (this.previous && this.previous instanceof Calendar) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(Calendar.create(this.first.limits, selection));
};

Section.prototype.toPrefsComponent = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating Preferences component", {});
    if (this.previous && this.prevous instanceof PrefsData) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new PrefsData(this.first.limites, selection));
};

Section.prototype.toExamComponent = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating Exam component", {});
    if (this.previous && this.previous instanceof ExamData) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new ExamData(this.first.limits, selection));
};

Section.prototype.toMemberCreator = function (selection) {
    // eslint-disable-next-line no-undef
    //mixpanel.track("Creating member object creator", {});
    if (this.previous && this.previous instanceof MemberData) {
        return this.setComponent(this.previous);
    }
    return this.setComponent(new MemberData(this.first.limits, selection));
};

Section.prototype.close = function (first) {
    const otherSection = first ? "second" : "first";

    return this.immutableSet((section) => {
        const layout = section[otherSection].hasVerticalLayout;
        const fst = section[otherSection].first;
        const second = section[otherSection].second;

        // eslint-disable-next-line no-param-reassign
        section.first = fst;
        // eslint-disable-next-line no-param-reassign
        section.second = second;
        // eslint-disable-next-line no-param-reassign
        section.hasVerticalLayout = layout;
    });
};

Section.prototype.getAllChildren = function () {
    if (this.hasChild()) {
        return [this.first];
    }
    return [].concat(this.first.getAllChildren()).concat(this.second.getAllChildren());
};

Section.prototype.getCalendarSection = function (calendar) {
    const stack = [this];
    let section;

    while (stack.length > 0) {
        section = stack.pop();
        if (section.first === calendar) {
            return section;
        } else if (!section.hasChild()) {
            stack.unshift(section.first);
            stack.unshift(section.second);
        }
    }

    return null;
};

Section.prototype.toJSON = function (x = 0, y = 0, width = 1.0, height = 1.0) {
    if (this.hasChild()) {
        const calendar = this.first.toJSON();
        calendar.orientation = {
            x,
            y,
            width,
            height,
        };
        return [calendar];
    }

    if (this.hasVerticalLayout) {
        return this.first
            .toJSON(x, y, width, height * this.weight)
            .concat(
                this.second.toJSON(x, y + height * this.weight, width, height * (1 - this.weight))
            );
    }

    return this.first
        .toJSON(x, y, width * this.weight, height)
        .concat(this.second.toJSON(x + width * this.weight, y, width * (1 - this.weight), height));
};

module.exports = Section;
