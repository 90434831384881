const React = require("react");
const API = require("../lib/TimeEditAPI");
const Language = require("../lib/Language");

class TypeSelection extends React.Component {
    state = {
        types: [],
        selectedTypes: [],
        typeTree: null,
    };

    componentDidMount() {
        API.getTypeTree((tree) => {
            this.setState({ typeTree: tree });
            this.updateAvailableTypes(this.props.selectedTypes);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTypes !== prevProps.selectedTypes) {
            this.updateAvailableTypes(this.props.selectedTypes);
        }
    }

    getType = (typeId) => {
        let result = null;
        this.props.allTypes.forEach((type) => {
            if (type.id === typeId) {
                result = type;
            }
        });
        return result;
    };

    updateAvailableTypes = (selectedTypes) => {
        API.findNextCreateTypes(selectedTypes, (createTypes) => {
            this.setState({
                types: createTypes,
                selectedTypes,
            });
        });
    };

    addType = (event) => {
        const type = this.getType(parseInt(event.target.value, 10));
        const selectedTypes = this.state.selectedTypes;
        selectedTypes.push(type.id);
        this.updateAvailableTypes(selectedTypes);
    };

    removeType = (event) => {
        const typeId = parseInt(event.target.value, 10);
        const selectedTypes = this.state.selectedTypes;
        selectedTypes.splice(selectedTypes.indexOf(typeId), 1);
        this.updateAvailableTypes(selectedTypes);
    };

    onSave = (event) => {
        if (this.props.onSave) {
            this.props.onSave(this.state.selectedTypes);
        }
        return event.preventDefault();
    };

    onCancel = (event) => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        return event.preventDefault();
    };

    isDisabled = (typeId, activeTypes) => {
        let result = true;
        activeTypes.forEach((activeType) => {
            if (activeType.id === typeId) {
                result = false;
            }
        });
        return result;
    };

    typeTreeOptions = (typeTree, activeTypes, depth = 0) => {
        if (!typeTree) {
            return null;
        }
        const indentation = [];
        for (let j = 0; j < depth; j++) {
            indentation.push("\u00a0\u00a0");
        }
        const subNodes = [];
        subNodes.push(
            <option
                disabled={this.isDisabled(typeTree.id, activeTypes)}
                key={typeTree.id}
                value={typeTree.id}
            >
                {indentation.join("") + typeTree.name}
            </option>
        );
        if (typeTree.subtypes) {
            // eslint-disable-next-line no-param-reassign
            depth++;
            for (let i = 0; i < typeTree.subtypes.length; i++) {
                subNodes.push(this.typeTreeOptions(typeTree.subtypes[i], activeTypes, depth));
            }
        }
        return subNodes;
    };

    typeOptions = (types) => {
        if (!types) {
            return null;
        }
        return types.map(function (type) {
            if (!type.id) {
                // eslint-disable-next-line no-param-reassign
                type = this.getType(type);
            }
            return (
                <option key={type.id} value={type.id}>
                    {type.name}
                </option>
            );
        }, this);
    };

    render() {
        return (
            <div className="typeSelection objectDefinitionPane">
                <table>
                    <tbody>
                        <tr>
                            <td className="column">
                                <select className="definitionList" onChange={this.addType}>
                                    <option disabled={true}>
                                        {Language.get("dynamic_object_type_info_add")}
                                    </option>
                                    {this.typeTreeOptions(this.state.typeTree, this.state.types)}
                                </select>
                            </td>
                            <td className="column">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="column">
                                <select
                                    className="definitionList"
                                    size="5"
                                    onChange={this.removeType}
                                >
                                    {this.typeOptions(this.state.selectedTypes)}
                                </select>
                            </td>
                            <td className="column">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                <form className="bottomButtons" onSubmit={this.onSave}>
                    <button className="cancel" onClick={this.onCancel}>
                        {Language.get("dialog_cancel")}
                    </button>
                    <button type="submit" className="save">
                        {Language.get("nc_dialog_done")}
                    </button>
                </form>
            </div>
        );
    }
}

module.exports = TypeSelection;
