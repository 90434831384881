import Language from "../lib/Language";

const NO_COLOR = "noColor";
const ALL_COLORS = "allColors";

const present = (color) => {
    if (color === NO_COLOR) {
        return Language.get("admin_no_color");
    }
    if (color === ALL_COLORS) {
        return Language.get("admin_all_colors");
    }
    return "";
};

const ColorCell = (props) => {
    let bgColor = `#${props.color}`;
    if (props.color === NO_COLOR) {
        bgColor = "#ffffff";
    }
    if (props.color === ALL_COLORS) {
        bgColor = "#ffffff";
    }
    const borderless = props.color === NO_COLOR || props.color === ALL_COLORS;
    let border = "1px solid black";
    if (borderless) {
        border = "none";
    }
    const classNames = [props.pattern];
    if (props.isselected) {
        classNames.push("selectedColor");
    }
    const borderRadius = props.round ? "8px" : "2px";
    return (
        <div
            style={{ display: "flex" }}
            onClick={() => {
                if (!props.onColorSelected) {
                    return;
                }
                props.onColorSelected({
                    color: props.color,
                    pattern: props.pattern,
                    id: props.id,
                });
            }}
        >
            <div
                title={props.title || present(props.color)}
                className={classNames.join(" ")}
                style={{
                    border,
                    width: "16px",
                    height: "16px",
                    borderRadius,
                    backgroundColor: bgColor,
                    padding: "2px",
                }}
            />{" "}
            <div className="colorLabel">{props.showNames ? present(props.color) : null}</div>
        </div>
    );
};

ColorCell.NO_COLOR = NO_COLOR;
ColorCell.ALL_COLORS = ALL_COLORS;

module.exports = ColorCell;
