const React = require("react");
const DateSelect = require("./DateSelect");
import { MillenniumDate } from "@timeedit/millennium-time";
const Language = require("../lib/Language");
import _ from "underscore";

// These are just to display a useful range, no need to worry about leap days and the like
const DAYS_PER_YEAR = 365;
const DAYS_TEN_YEARS = 3650;

class DateRange extends React.Component {
    state = {
        startDate: this.props.startDate || null,
        endDate: this.props.endDate || null,
    };
    startSelected(startDate) {
        let date = startDate[0];
        // eslint-disable-next-line no-magic-numbers
        if (startDate.length === 2) {
            date = startDate[1];
        }
        this.setState({ startDate: date || null }, () => this.notify());
    }
    endSelected(endDate) {
        let date = endDate[0];
        // eslint-disable-next-line no-magic-numbers
        if (endDate.length === 2) {
            date = endDate[1];
        }
        this.setState({ endDate: date || null }, () => this.notify());
    }
    onSelectionFinished() {
        this.props.onFinished(this.state);
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }
    render() {
        const today = MillenniumDate.today();
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <div>
                    <label>{Language.get("cal_reservation_list_column_start_date")}</label>
                    <DateSelect
                        onChange={this.startSelected.bind(this)}
                        onClose={_.noop}
                        startDate={today.addDays(-DAYS_PER_YEAR)}
                        endDate={today.addDays(DAYS_TEN_YEARS)}
                        now={this.state.startDate}
                        values={this.state.startDate ? [this.state.startDate] : []}
                        hideWeekends={this.props.hideWeekends || false}
                        showTodayButton={false}
                    />
                </div>
                <div>
                    <label>{Language.get("cal_reservation_list_column_end_date")}</label>
                    <DateSelect
                        onChange={this.endSelected.bind(this)}
                        onClose={_.noop}
                        startDate={today.addDays(-DAYS_PER_YEAR)}
                        endDate={today.addDays(DAYS_TEN_YEARS)}
                        now={this.state.endDate}
                        values={this.state.endDate ? [this.state.endDate] : []}
                        hideWeekends={this.props.hideWeekends || false}
                        showTodayButton={false}
                    />
                </div>
            </div>
        );
    }
}

module.exports = DateRange;
