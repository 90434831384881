const _ = require("underscore");

const MIN_DRAG_LIMIT = 3;

exports.add = (event, callback) => {
    const moveEvent = _.getMoveEvent(event);
    const cancelEvent = _.getEndEvent(event);
    const originalPosition = _.getClientPos(event);
    let removeEventListeners; // eslint-disable-line prefer-const

    const waitForMove = (evt) => {
        const pos = _.getClientPos(evt);
        if (
            Math.abs(originalPosition.x - pos.x) < MIN_DRAG_LIMIT &&
            Math.abs(originalPosition.y - pos.y) < MIN_DRAG_LIMIT
        ) {
            return;
        }
        removeEventListeners();
        callback();
    };

    removeEventListeners = () => {
        window.removeEventListener(cancelEvent, removeEventListeners);
        window.removeEventListener(moveEvent, waitForMove);
    };

    window.addEventListener(moveEvent, waitForMove);
    window.addEventListener(cancelEvent, removeEventListeners);
};
