const _ = require("underscore");
const { ClusterKind } = require("../lib/EntryConstants");
const FieldInput = require("../components/FieldInput");
import { MillenniumDateTime } from "@timeedit/millennium-time";
const NamedWeek = require("./NamedWeek");
const FieldKind = FieldInput.fieldKind;
const Language = require("../lib/Language");

type Dispatcher = any;

type Langs = [{ id: null | number; label: string }];

type LastFindObject = {
    query: null | number;
    response: null | {
        totalNumber: null | number;
        objects: any;
    };
};

type addToMcFluffyParams = {
    mcFluffy: object;
    typeId: number;
    objectId: number;
};

type setToMcFluffyParams = {
    mcFluffy: object;
    objects: object;
    preservePreferDouble: boolean;
};

type TEObject = {
    id: number;
    extid: string;
    fields: [];
};

let dispatcher: Dispatcher = null;
let logger: any = null;
let types = [];
let fields = [];
const fieldDefs = {};
const limitCache = {};
let typeFieldCache = {};
let allFieldsCache = {};
let userSearchFieldCache: null | number[] | object = null;
let entryPropertyDefs = [];
let versionInfo = null;
const templateGroupCache = {};
const MASS_BATCH_SIZE = 50;
const STATUS_BATCH_SIZE = 500;
const GET_OBJECTS_BATCH_SIZE = 1000;
const GET_RESERVATIONS_BATCH_SIZE = 1000;
const EXPORT_RESERVATIONS_BATCH_SIZE = 1000;
const CANCEL_RESERVATIONS_BATCH_SIZE = 1000;
const DELETE_OBJECTS_BATCH_SIZE = 1000;
const FIND_OBJECTS_BATCH_SIZE = 1000;

const DONT_REPEAT_ERRORS = ["-301", "-302", "-303", "-401", "-402", "-403", "-404"];

/* API calls */

const getVersionInfo = function (callback) {
    if (versionInfo !== null) {
        callback(versionInfo);
        return;
    }

    dispatcher.send("getVersionInfo", {}, (result) => {
        try {
            versionInfo = result.parameters[0];
            callback(versionInfo);
        } catch (error) {
            console.error("Error in getVersionInfo", error);
        }
    });
};

const getLanguages = function (callback, stringLanguages = true) {
    dispatcher.send("getLanguages", { stringLang: stringLanguages, onlyUsed: false }, (res) => {
        try {
            const langs: Langs = [{ id: null, label: "" }];
            for (let i = 0; i < res.parameters[0].length; i++) {
                langs.push({
                    id: res.parameters[0][i],
                    label: res.parameters[1][i],
                });
            }
            callback(langs);
        } catch (error) {
            console.error("Error in getLanguages", error);
        }
    });
};

const findReservations = function (query, callback) {
    dispatcher.send("findReservations", query, callback);
};

const findReservationsList = function (query, callback, errorCallback = _.noop) {
    // Parameter index 5 and 6: bool for success, error message if not success
    dispatcher.send("findReservationsList", query, callback, query.longOperation, errorCallback);
};

const findAllReservationsList = function (query, callback, errorCallback = _.noop) {
    findReservationsList(
        query,
        (result) => {
            try {
                const totalNumber = result.parameters[2];
                if (totalNumber > result.parameters[0].length) {
                    const calls: any[] = [];
                    let totalResult: any[] = [result.parameters[0]];
                    for (
                        let i = result.parameters[0].length;
                        i < totalNumber;
                        i = i + (query.numberOfRows || 100)
                    ) {
                        calls.push((done) => {
                            findReservationsList(
                                Object.assign({}, query, { startRow: i }),
                                (subResult) => {
                                    totalResult.push(subResult.parameters[0]);
                                    done();
                                },
                                (error) => {
                                    errorCallback(error);
                                    done();
                                }
                            );
                        });
                    }
                    _.runSync(calls, () => {
                        callback(_.flatten(totalResult));
                    });
                } else {
                    callback(result.parameters[0]);
                }
            } catch (error) {
                console.error("Error in findAllReservationsList", error);
            }
        },
        errorCallback
    );
};

const findReservationsCancelledList = function (query, callback, errorCallback = _.noop) {
    // Parameter index 5 and 6: bool for success, error message if not success
    dispatcher.send(
        "findReservationsCancelledList",
        query,
        callback,
        query.longOperation,
        errorCallback
    );
};

const okToCancelReservations = function (query, callback) {
    let fullResult = [];
    let fullEmailResult = [];
    const calls = _.splitArray(query.reservationIds, STATUS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send(
            "okToCancelReservations",
            { reservationIds: batch },
            (result) => {
                try {
                    fullResult = fullResult.concat(result.parameters[0]);
                    fullEmailResult = fullEmailResult.concat(result.parameters[1]);
                } catch (error) {
                    console.error("Error in okToCancelReservations", error);
                } finally {
                    done();
                }
            },
            true
        );
    });
    _.runSync(calls, () => {
        callback({ parameters: [fullResult, fullEmailResult] });
    });
};

const okToChangeViews = function (viewIds, callback) {
    dispatcher.send("okToChangeViews", { viewIds }, callback);
};

const okToPublishView = function (viewId, callback) {
    dispatcher.send("okToPublishView", { viewId }, callback);
};

const findOrders = function (query, callback) {
    dispatcher.send("findOrders", query, callback);
};

const getOrders = function (orderIds, callback) {
    dispatcher.send("getOrders", { orderIds, createObjectsWithNames: true }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getOrders", error);
        }
    });
};

const exportOrders = function (orderIds, callback) {
    dispatcher.send("exportOrders", { orderIds, createObjectsWithNames: true }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in exportOrders", error);
        }
    });
};

const importOrders = function (orders, callback) {
    dispatcher.send("importOrders", { orders }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in importOrders", error);
        }
    });
};

const getReservations = function (reservationIds, callback) {
    let fullResult = [];
    const calls = _.splitArray(reservationIds, GET_RESERVATIONS_BATCH_SIZE).map(
        (batch) => (done) => {
            dispatcher.send("getReservations", { reservationIds: batch }, (result) => {
                try {
                    fullResult = fullResult.concat(result.parameters[0]);
                } catch (error) {
                    console.error("Error in getReservations", error);
                } finally {
                    done();
                }
            });
        }
    );
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const getReservationsByExtid = function (reservationIds, callback) {
    let fullResult = [];
    const calls = _.splitArray(reservationIds, GET_RESERVATIONS_BATCH_SIZE).map(
        (batch) => (done) => {
            dispatcher.send(
                "getReservations",
                { reservationIds: batch, useExtid: true },
                (result) => {
                    try {
                        fullResult = fullResult.concat(result.parameters[0]);
                    } catch (error) {
                        console.error("Error in getReservationsByExtid", error);
                    } finally {
                        done();
                    }
                }
            );
        }
    );
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const getReservationsCancelled = function (reservationIds, callback) {
    dispatcher.send("getReservationsCancelled", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getReservationsCancelled", error);
        }
    });
};

const getReservationsHistory = function (reservationIds, callback) {
    dispatcher.send("getReservationsHistory", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getReservationsHistory", error);
        }
    });
};

const exportReservations = function (reservationIds, includeExtraInfo, callback) {
    let fullResult = [];
    const calls = _.splitArray(reservationIds, EXPORT_RESERVATIONS_BATCH_SIZE).map(
        (batch) => (done) => {
            dispatcher.send(
                "exportReservations",
                { reservationIds: batch, includeExtraInfo, useExtid: true },
                (result) => {
                    try {
                        fullResult = fullResult.concat(result.parameters[0]);
                    } catch (error) {
                        console.error("Error in exportReservations", error);
                    } finally {
                        done();
                    }
                }
            );
        }
    );
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const importReservations = function (reservations, allowIncomplete, templateGroupId, callback) {
    const params = { reservations, allowIncomplete, templateGroup: templateGroupId };
    dispatcher.send("importReservations", params, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in importReservations", error);
        }
    });
};

const getModifiableReservationFields = function (reservationIds, callback) {
    dispatcher.send("getModifiableReservationFields", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0], result.parameters[1]);
        } catch (error) {
            console.error("Error in getModifiableReservationFields", error);
        }
    });
};

const setModifiableReservationFields = function (reservationIds, modifiableFields, callback) {
    dispatcher.send(
        "setModifiableReservationFields",
        { reservationIds, fields: modifiableFields },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in setModifiableReservationFields", error);
            }
        }
    );
};

const getAssociatedTypes = function (typeId, callback) {
    dispatcher.send("getAssociatedTypes", { type: typeId }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getAssociatedTypes", error);
        }
    });
};

const getAssociatedObjects = function (objects, type, callback) {
    if (_.isFunction(type)) {
        callback = type;
        type = undefined;
    }

    dispatcher.send("getAssociatedObjects", { objects, type }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getAssociatedObjects", error);
        }
    });
};

const getSuggestedObjectsSimple = function (
    selectedObject,
    resultTypeId,
    allObjectIds,
    beginTime,
    endTime,
    callback
) {
    dispatcher.send(
        "getSuggestedObjectsSimple",
        { selectedObject, resultType: resultTypeId, allObjectIds, beginTime, endTime },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in getSuggestedObjectsSimple", error);
            }
        }
    );
};

const getSuggestedObjectsAdvanced = function (
    selectedObject,
    otherObjects,
    isReservationMode,
    templateGroupId,
    beginTime,
    endTime,
    skipLastReserved,
    callback
) {
    dispatcher.send(
        "getSuggestedObjectsAdvanced",
        {
            selectedObject,
            otherObjects,
            isReservationMode,
            templateGroupId,
            beginTime: beginTime || 0,
            endTime: endTime || 0,
            allObjectIds: [],
            reservationId: 0,
            skipLastReserved,
        },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in getSuggestedObjectsAdvanced", error);
            }
        }
    );
};

const getSuggestedObjectsAdvanced2 = function (
    selectedObject,
    otherObjects,
    isReservationMode,
    templateGroupId,
    beginTime,
    endTime,
    allObjectIds,
    reservationId,
    skipLastReserved,
    callback
) {
    dispatcher.send(
        "getSuggestedObjectsAdvanced",
        {
            selectedObject,
            otherObjects,
            isReservationMode,
            templateGroupId,
            beginTime,
            endTime,
            allObjectIds,
            reservationId,
            skipLastReserved,
        },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in getSuggestedObjectsAdvanced2", error);
            }
        }
    );
};

const deleteFromSuggestedObjects = function (objectId, callback) {
    dispatcher.send("deleteFromSuggestedObjects", { objectId }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in deleteFromSuggestedObjects", error);
        }
    });
};

const findAllSearchObjects = function (alreadyFound, totalNumber, query, callback) {
    const query2 = { ...query };
    query2.startRow = alreadyFound.length;
    query2.useExtid = true;
    dispatcher.send("findObjects", query2, (result2) => {
        try {
            const totalFound = [...alreadyFound, ...result2.parameters[0]];
            if (totalFound.length === totalNumber) {
                callback(totalFound);
            } else {
                findAllSearchObjects(totalFound, totalNumber, query, callback);
            }
        } catch (error) {
            console.error("Error in findAllSearchObjects", error);
        }
    });
};

const finishFindObjects = function (foundObjects, totalNumber, query, callback) {
    let primaryFieldIndex = query.returnFields.findIndex(
        (returnField) => returnField === query.primaryField
    );
    if (primaryFieldIndex === -1) {
        primaryFieldIndex = 0;
    }

    const objects = foundObjects.map((element) => {
        return {
            ...element,
            name: element.fields[primaryFieldIndex]
                ? element.fields[primaryFieldIndex].values[0]
                : "",
            typeId: query.type,
            reservationKind: element.reservationkind || 0,
        };
    });
    lastFindObjects = {
        query,
        response: {
            objects,
            totalNumber,
        },
    };
    _findObjectsCacheTimeout = window.setTimeout(() => {
        lastFindObjects = { query: null, response: null };
    }, 300000);
    callback(objects, totalNumber);
};

let lastFindObjects: LastFindObject = {
    query: null,
    response: { totalNumber: null, objects: null },
};
let _findObjectsCacheTimeout: number = 0; // does it matter if this is set to 0 by default?????

let errorFindObjectsQueries: any[] = [];
let errorFindObjectsMessages: string[] = [];

const findObjects = function (query, callback) {
    // We always want the type to be a pure ID. Move away from passing a whole TE JSON object around.
    if (typeof query.type === "object" && query.type !== null) {
        throw new Error(
            `Invoked findObjects with an object instead of a type ID ${JSON.stringify(query)}`
        );
    }

    query.returnFields = query.returnFields || [];
    if (query.returnFields.length === 0 && query.generalSearchFields) {
        // Fields should also become pure IDs.
        query.returnFields = query.generalSearchFields.map((field) =>
            field.id ? field.id : field
        );
    } else if (query.returnFields.length > 0) {
        query.returnFields = query.returnFields.map((field) => (field.id ? field.id : field));
    }

    if (_.isEqual(query, lastFindObjects.query)) {
        callback(lastFindObjects.response?.objects, lastFindObjects.response?.totalNumber);
        return;
    }
    clearTimeout(_findObjectsCacheTimeout);

    if (query.searchObjects && query.searchObjects.length) {
        query.numberOfRows = Math.min(
            Math.max(query.numberOfRows || 0, query.searchObjects.length),
            FIND_OBJECTS_BATCH_SIZE
        );
    }

    let index = errorFindObjectsQueries.findIndex((errorQuery) => _.isEqual(query, errorQuery));
    if (index > -1) {
        if (logger) {
            let message = errorFindObjectsMessages[index];
            // Don't report too many object matching a basic query
            // The best cause of action is to enter a longer query
            if (message.indexOf("-1054") === -1) {
                logger.info(message);
            }
        }
        callback([], 0);
        return;
    }

    dispatcher.send(
        "findObjects",
        query,
        (result) => {
            try {
                const foundObjects = result.parameters[0];
                // Parameter 1 is a datetime object
                const totalNumber = result.parameters[2];

                if (
                    query.searchObjects &&
                    query.searchObjects.length > 0 &&
                    foundObjects.length < totalNumber
                ) {
                    findAllSearchObjects(foundObjects, totalNumber, query, (allFound) => {
                        finishFindObjects(allFound, totalNumber, query, callback);
                    });
                } else {
                    finishFindObjects(foundObjects, totalNumber, query, callback);
                }
            } catch (error) {
                console.error("Error in findObjects", error);
            }
        },
        false,
        (errorType, message, response) => {
            if (logger) {
                let message = response;
                if (DONT_REPEAT_ERRORS.some((errorCode) => response.indexOf(errorCode) !== -1)) {
                    if (response.indexOf("-302") > -1) {
                        message = Language.get("nc_too_many_exact_search_fields");
                    }

                    errorFindObjectsQueries.push({ ...query });
                    errorFindObjectsMessages.push(message);
                }
                // Don't report too many object matching a basic query
                // The best cause of action is to enter a longer query
                if (response.indexOf("-1054") === -1) {
                    logger.info(message);
                }
            }
        }
    );
};

const exportObjects = function (objectIds, callback) {
    if (!objectIds || objectIds.length === 0) {
        callback([]);
        return;
    }
    dispatcher.send(
        "exportObjects",
        { objectIds, useExtid: true },
        (result) => {
            try {
                result.parameters[0].forEach((obj) => {
                    if (obj.members) {
                        obj.members = obj.members.map((member) => {
                            if (member.object) {
                                return {
                                    begin: new MillenniumDateTime(member.begin),
                                    end: new MillenniumDateTime(member.end),
                                    object: member.object,
                                };
                            }
                            return member;
                        });
                    }
                });
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in exportObjects", error);
            }
        },
        true
    );
};

const exportObjectsBatch = function (objectIds, callback) {
    if (!objectIds || objectIds.length === 0) {
        callback([]);
        return;
    }
    const fullResult: object[] = [];
    const calls = _.splitArray(objectIds, GET_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send(
            "exportObjects",
            { objectIds: batch, useExtid: true },
            (result) => {
                try {
                    result.parameters[0].forEach((obj) => {
                        if (obj.members) {
                            obj.members = obj.members.map((member) => {
                                if (member.object) {
                                    return {
                                        begin: new MillenniumDateTime(member.begin),
                                        end: new MillenniumDateTime(member.end),
                                        object: member.object,
                                    };
                                }
                                return member;
                            });
                        }
                        fullResult.push(obj);
                    });
                } catch (error) {
                    console.error("Error in exportObjectsBatch", error);
                } finally {
                    done();
                }
            },
            true
        );
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const importObjects = function (objects, addUserOrganization, mode, callback) {
    let params = { objects, addUserOrganization, mode };
    dispatcher.send(
        "importObjects",
        params,
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in importObjects", error);
            }
        },
        true
    );
};

const importObjectsBatch = function (objects, addUserOrganization, mode, callback) {
    let params = { objects, addUserOrganization, mode };
    let fullResult = [];
    const calls = _.splitArray(objects, GET_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send(
            "importObjects",
            { objects: batch, addUserOrganization, mode },
            (result) => {
                try {
                    fullResult = fullResult.concat(result.parameters[0]);
                } catch (error) {
                    console.error("Error in importObjectsBatch", error);
                } finally {
                    done();
                }
            },
            true
        );
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const createEditableObject = function (typeIds, objectId, callback) {
    const params = {
        typeIds,
        objectId,
    };
    dispatcher.send("createEditableObject", params, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in createEditableObject", error);
        }
    });
};

const deleteObjects = function (objectIds, inactivate, callback) {
    let fullResult = [];
    const calls = _.splitArray(objectIds, DELETE_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send("deleteObjects", { objectIds: batch, inactivate }, (result) => {
            try {
                fullResult = fullResult.concat(result.parameters[0]);
            } catch (error) {
                console.error("Error in deleteObjects", error);
            } finally {
                done();
            }
        });
    });
    _.runSync(calls, () => {
        callback(_.flatten(fullResult, 1));
    });
};

const getTypeDefsExtended = function (typeIds, callback) {
    if (typeIds.length === 0) {
        callback([]);
        return;
    }
    dispatcher.send("getTypeDefsExtended", { types: typeIds }, (result) => {
        // Works around a server bug before c72624d8e1507b436474e3fbafe8b17077a0a7f5
        try {
            if (
                typeIds.length === 1 &&
                result.parameters[0].length === 2 &&
                result.parameters[0][0] === null
            ) {
                callback([result.parameters[0][1]]);
                return;
            }
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getTypeDefsExtended", error);
            callback([]);
        }
    });
};

const getTypes = function (typeIds, ignoreAlias, callback) {
    if (typeIds.length === 0) {
        callback([]);
        return;
    }
    dispatcher.send("getTypes", { types: typeIds, ignoreAlias }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getTypes", error);
            callback([]);
        }
    });
};

let _namedTypesCache = {};

const getTypesByExtid = function (typeIds, ignoreAlias, callback) {
    if (typeIds.length === 0) {
        callback([]);
        return;
    }
    let foundTypes: object[] = [];
    typeIds.forEach((typeId, index) => {
        if (_namedTypesCache[typeId]) {
            foundTypes[index] = _namedTypesCache[typeId];
        }
    });
    let soughtTypes = typeIds.filter((typeId) => _namedTypesCache[typeId] === undefined);
    if (soughtTypes.length === 0) {
        callback(foundTypes);
        return;
    }
    dispatcher.send("getTypes", { types: soughtTypes, ignoreAlias, useExtid: true }, (result) => {
        try {
            const newTypes = result.parameters[0];
            newTypes.forEach((newType) => {
                _namedTypesCache[newType.extid] = newType;
                typeIds.forEach((typeId, index) => {
                    if (typeId === newType.extid) {
                        foundTypes[index] = newType;
                    }
                });
            });
            callback(foundTypes);
        } catch (error) {
            console.error("Error in getTypesByExtid", error);
            callback([]);
        }
    });
};

const getRelatedTypes = function (
    types,
    hasMembers,
    hasRelated,
    hasAvailabilityRelated,
    hasOptionalRelated,
    callback
) {
    const params = {
        types,
        hasMembers,
        hasRelated,
        hasAvailabilityRelated,
        hasOptionalRelated,
    };
    dispatcher.send("getRelatedTypes", params, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in getRelatedTypes", error);
        }
    });
};

const getCreateTypes = function (typeIds, callback) {
    dispatcher.send("getCreateTypes", { types: typeIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getCreateTypes", error);
        }
    });
};

const getCreateTypesKinds = function (typeIds, callback) {
    dispatcher.send("getCreateTypesKinds", { types: typeIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getCreateTypesKinds", error);
        }
    });
};

const findNextCreateTypes = function (typeIds, callback) {
    dispatcher.send("findNextCreateTypes", { types: typeIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in findNextCreateTypes", error);
        }
    });
};

const getTypeTree = function (callback) {
    dispatcher.send("getTypeTree", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getTypeTree", error);
        }
    });
};

// beginTime, endTime, fluffy, objectLists
const findEntries = function (query, callback) {
    const toMts = (datetime) => datetime.getMts();
    if (!Array.isArray(query.beginTime)) {
        query.beginTime = [query.beginTime];
    }
    query.beginTime = query.beginTime.map(toMts);
    if (!Array.isArray(query.endTime)) {
        query.endTime = [query.endTime];
    }
    query.endTime = query.endTime.map(toMts);

    const Entry = require("../models/Entry");
    const AvailabilityEntry = require("../models/AvailabilityEntry");
    dispatcher.send("findEntries", query, (response) => {
        try {
            const entries = response.parameters[0].map((item) => Entry.create(item));
            let availability = response.parameters[1] || [];
            const isAbsoluteAvailability = response.parameters[2];
            availability = availability.map((item) => AvailabilityEntry.create(item));
            const summaries = response.parameters[3];
            callback(entries, availability, isAbsoluteAvailability, summaries);
        } catch (error) {
            console.error("Error in findEntries", error);
        }
    });
};

const getAllEntryPropertyDefs = function (callback) {
    if (entryPropertyDefs && entryPropertyDefs.length > 0) {
        callback(entryPropertyDefs);
    } else {
        dispatcher.send("getAllEntryPropertyDefs", {}, (result) => {
            try {
                entryPropertyDefs = result.parameters[0];
                callback(entryPropertyDefs);
            } catch (error) {
                console.error("Error in getAllEntryPropertyDefs", error);
            }
        });
    }
};

const findAliasTypes = function (callback) {
    dispatcher.send("findTypes", { searchString: "", ignoreAlias: false }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in findAliasTypes", error);
        }
    });
};

const findTypes = function (callback) {
    if (types && types.length > 0) {
        callback(types);
    } else {
        dispatcher.send("findTypes", { searchString: "", ignoreAlias: true }, (result) => {
            try {
                types = result.parameters[0];
                callback(types);
            } catch (error) {
                console.error("Error in findTypes", error);
            }
        });
    }
};

const findFields = function (callback, useCache = true) {
    if (fields && fields.length > 0 && useCache) {
        callback(fields);
    } else {
        dispatcher.send("findFields", { searchString: "" }, (result) => {
            try {
                fields = result.parameters[0]; // Cache updates even if useCache is false, this is intentional
                callback(fields);
            } catch (error) {
                console.error("Error in findFields", error);
            }
        });
    }
};

const getAllFields = function (type, callback) {
    const key = String(type);
    if (allFieldsCache[key]) {
        callback(allFieldsCache[key]);
    } else {
        dispatcher.send("getAllFields", { type }, (result) => {
            try {
                allFieldsCache[key] = result.parameters[0];
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getAllFields", error);
            }
        });
    }
};

const findReservationFields = (callback) => {
    dispatcher.send("findReservationFields", {}, (result) => {
        try {
            getFieldDefs(
                result.parameters[0].map((res) => res.id),
                undefined,
                (resResult) => {
                    callback(resResult);
                }
            );
        } catch (error) {
            console.error("Error in findReservationFields", error);
        }
    });
};

const getFieldsForReservationLists = (callback) => {
    dispatcher.send("findReservationFields", {}, (result) => {
        try {
            getFieldDefs(
                result.parameters[0].map((res) => res.id),
                undefined,
                (resResult) => {
                    callback(resResult.filter((field) => field.listable === true));
                }
            );
        } catch (error) {
            console.error("Error in getFieldsForReservationLists", error);
        }
    });
};

const findFieldsForTypes = function (types, includeChildTypeFields, primaryFieldsOnly, callback) {
    const params = { types, includeChildTypeFields, primaryFieldsOnly };
    const key = JSON.stringify(params);
    if (typeFieldCache[key]) {
        callback(typeFieldCache[key]);
    } else {
        dispatcher.send("findFieldsForTypes", params, (result) => {
            try {
                typeFieldCache[key] = result.parameters[0];
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in findFieldsForTypes", error);
            }
        });
    }
};

const findFieldsForReservations = function (callback) {
    dispatcher.send("findFieldsForReservations", {}, (result) => {
        try {
            return callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in findFieldsForReservations", error);
            return;
        }
    });
};

const getFieldDefs = function (fieldIds, type, callback, useCache = true) {
    if (_.isFunction(type)) {
        useCache = callback;
        if (useCache === undefined) {
            useCache = true;
        }
        callback = type;
        type = undefined;
    }
    const key = `${type !== undefined ? type.id : "noType"};${fieldIds.join(":")}`;
    if (useCache && fieldDefs[key]) {
        callback(JSON.parse(JSON.stringify(fieldDefs[key])));
    } else {
        dispatcher.send("getFieldDefs", { ids: fieldIds, type }, (result) => {
            try {
                fieldDefs[key] = result.parameters[0];
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getFieldDefs", error);
            }
        });
    }
};

const getFieldDefsForType = function (type, includeChildTypeFields = true, callback = _.noop) {
    findFieldsForTypes([type], includeChildTypeFields, false, (result) => {
        getFieldDefs(
            result.map((field) => field.id),
            type,
            (defResult) => callback(defResult)
        );
    });
};

const getPrimaryFieldDefsForType = function (
    type,
    includeChildTypeFields = true,
    callback = _.noop
) {
    findFieldsForTypes([type], includeChildTypeFields, true, (result) => {
        getFieldDefs(
            result.map((field) => field.id),
            type,
            (defResult) => callback(defResult)
        );
    });
};

const getFieldDefsForReservations = function (callback = _.noop) {
    findFieldsForReservations((result) => {
        getFieldDefs(
            result.map((field) => field.id),
            undefined,
            (defResult) => callback(defResult)
        );
    });
};

const getAllFieldDefs = function (type, callback) {
    getAllFields(type, (result) => {
        getFieldDefs(
            result.map((field) => field.id),
            undefined,
            (defResult) => callback(defResult)
        );
    });
};

const findCategories = function (typeId, callback) {
    getAllFieldDefs(typeId, (result) => {
        callback(_.filter(result, (field) => field.kind === FieldInput.fieldKind.CATEGORY));
    });
};

const getFields = function (fieldIds, callback) {
    if (fieldIds.length === 0) {
        callback([]);
        return;
    }
    dispatcher.send("getFields", { ids: fieldIds, useExtid: true }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getFields", error);
            callback([]);
        }
    });
};

let _extidFieldsCache = {};

const getFieldsByExtid = function (fieldIds, callback) {
    if (fieldIds.length === 0) {
        callback([]);
        return;
    }
    const foundFields: object[] = [];
    fieldIds.forEach((fieldId, index) => {
        if (_extidFieldsCache[fieldId]) {
            foundFields[index] = _extidFieldsCache[fieldId];
        }
    });
    let ids = fieldIds.filter((fieldId) => _extidFieldsCache[fieldId] === undefined);
    if (ids.length === 0) {
        callback(foundFields);
        return;
    }
    dispatcher.send("getFields", { ids, useExtid: true }, (result) => {
        try {
            result.parameters[0].forEach((field) => {
                _extidFieldsCache[field.extid] = field;
                fieldIds.forEach((fieldId, index) => {
                    if (fieldId === field.extid) {
                        foundFields[index] = field;
                    }
                });
            });
            callback(foundFields);
        } catch (error) {
            console.error("Error in getFieldsByExtid", error);
            callback([]);
        }
    });
};

const setPrimaryField = function (type, field, callback) {
    //console.error("setPrimaryField", type, field);
    dispatcher.send("setPrimaryField", { type, field }, callback);
};

const getPrimaryFields = function (type, callback) {
    dispatcher.send("getPrimaryFields", { type }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getPrimaryFields", error);
        }
    });
};

const getObjectsByExtid = function (objectIds, callback) {
    const fullResult: object[] = [];
    if (objectIds.length === 0) {
        callback(fullResult);
        return;
    }
    const calls = _.splitArray(objectIds, GET_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send("getObjects", { ids: batch, useExtid: true }, (result) => {
            try {
                _.each(result.parameters[0], (element) => {
                    fullResult.push({
                        id: element.id,
                        fields: element.fields,
                        reservationKind: element.reservationkind,
                        color: element.color,
                        extid: element.extid,
                    });
                });
            } catch (error) {
                console.error("Error in getObjectsByExtid", error);
            } finally {
                done();
            }
        });
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const getObjects = function (objectIds, callback) {
    const fullResult: object[] = [];
    if (objectIds.length === 0) {
        callback(fullResult);
        return;
    }
    const calls = _.splitArray(objectIds, GET_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send("getObjects", { ids: batch }, (result) => {
            try {
                _.each(result.parameters[0], (element) => {
                    fullResult.push({
                        id: element.id,
                        fields: element.fields,
                        reservationKind: element.reservationkind,
                        color: element.color,
                        extid: element.extid,
                    });
                });
            } catch (error) {
                console.error("Error in getObjects", error);
            } finally {
                done();
            }
        });
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const getObjectNames = function (objectIds, activeOnly, callback) {
    const fullResult: object[] = [];
    if (objectIds.length === 0) {
        callback(fullResult);
        return;
    }
    const calls = _.splitArray(objectIds, GET_OBJECTS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send("getObjectNames", { ids: batch, activeOnly }, (result) => {
            try {
                _.each(result.parameters[0], (element) => {
                    if (element !== null) {
                        fullResult.push({
                            id: element.id,
                            fields: element.fields,
                        });
                    }
                });
            } catch (error) {
                console.error("Error in getObjectNames", error);
            } finally {
                done();
            }
        });
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

let _namedObjectsCache = {};

const clearAmCaches = () => {
    _namedObjectsCache = {};
    _namedTypesCache = {};
    _extidFieldsCache = {};
};

const getObjectNamesByExtid = function (objectIds, activeOnly, callback) {
    const fullResult: TEObject[] = [];
    if (objectIds.length === 0) {
        callback(fullResult);
        return;
    }
    // Pick any existing objects from the cache
    // Make calls for the rest
    let objects = objectIds
        .filter((object) => (typeof object === "object" ? object.id : object))
        .map((object) => ({
            extId: object.id ?? object,
            type: object.type ?? "",
        }));
    objects.forEach((obj, index) => {
        if (_namedObjectsCache[obj.extId]) {
            fullResult[index] = _namedObjectsCache[obj.extId];
        }
    });
    let searchObjects = objects.filter((obj) => _namedObjectsCache[obj.extId] === undefined);
    if (searchObjects.length === 0) {
        callback(fullResult);
        return;
    }
    const calls = _.splitArray(
        searchObjects.filter((objId) => objId),
        GET_OBJECTS_BATCH_SIZE
    ).map((batch) => (done) => {
        dispatcher.send("getObjectNames", { ids: batch, activeOnly, useExtid: true }, (result) => {
            try {
                _.each(result.parameters[0], (element) => {
                    if (element !== null) {
                        objects.forEach((obj, index) => {
                            if (obj.extId === element.extid) {
                                fullResult[index] = {
                                    id: element.id,
                                    extid: element.extid,
                                    fields: element.fields,
                                };
                            }
                        });
                    }
                });
            } catch (error) {
                console.error("Error in getObjectNamesByExtid", error);
            } finally {
                done();
            }
        });
    });
    _.runSync(calls, () => {
        // Update cache with fetched objects
        fullResult.forEach((object) => {
            _namedObjectsCache[object.extid] = object;
        });
        callback(fullResult);
    });
};

const getObjectsLimited = function (objectIds, callback) {
    if (objectIds.length > 1000) {
        const subset = objectIds.slice(0, 999);
        getObjects(subset, callback);
    } else {
        getObjects(objectIds, callback);
    }
};

const getDatabases = function (client, callback) {
    dispatcher.send("getAllDatabases", { client }, callback);
};

const getAuthServers = function (client, database, callback, timeoutFunction) {
    dispatcher.send("getAuthServers", { client, database }, callback, timeoutFunction);
};

const getAllAuthServers = function (client, database, callback, timeoutFunction) {
    dispatcher.send("getAllAuthServers", { client, database }, callback, timeoutFunction);
};

const getSingleSignOnServers = function (database, callback) {
    dispatcher.send("getSingleSignOnServers", { database }, callback);
};

const getServers = function (callback) {
    dispatcher.send("getServers", {}, callback);
};

const getTemplateGroups = function (templateKind, callback) {
    if (templateGroupCache.hasOwnProperty(templateKind)) {
        callback(templateGroupCache[templateKind]);
        return;
    }

    dispatcher.send("getTemplateGroups", { templateKind, useExtid: true }, (result) => {
        templateGroupCache[templateKind] = result;
        callback(result);
    });
};

// Sorting: USER_SORT_ID = 0, USER_SORT_LOGIN, USER_SORT_LAST_NAME, USER_SORT_FIRST_NAME, USER_SORT_LAST_LOGIN, USER_SORT_MODIFIED
const findUsers = function (
    query,
    callback = (result) => {
        console.error(result);
    }
) {
    // authServer = "timeedit", loginName = null, name = null, email = null, allowedToLoginOnly = true, sortOrder = 0, startRow = 0, numberOfRows = 20
    query.authServer = query.authServer || "timeedit";
    query.sortOrder = query.sortOrder || 0;
    query.startRow = query.startRow || 0;
    query.numberOfRows = query.numberOfRows || 20;
    dispatcher.send("findUsers", query, callback);
};

const getUsers = function (userIds, callback) {
    dispatcher.send("getUsers", { userIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getUsers", error);
        }
    });
};

const exportUsers = function (userIds, callback) {
    dispatcher.send("exportUsers", { userIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in exportUsers", error);
        }
    });
};

const updateMcFluffy = function (mcFluffy, callback) {
    dispatcher.send("updateMcFluffy", { mcFluffy }, callback);
};

const addToMcFluffy = function (mcFluffy, typeId, objectId, callback) {
    const parameters: addToMcFluffyParams = { mcFluffy, typeId, objectId };
    if (objectId) {
        parameters.objectId = objectId;
    }
    dispatcher.send("addToMcFluffy", parameters, callback);
};

const addToMcFluffyMulti = function (mcFluffy, typeId, objectIds, callback) {
    const parameters = { mcFluffy, typeId, objectIds };
    dispatcher.send("addToMcFluffy", parameters, callback);
};

const addToMcFluffyServer = function (mcFluffy, typesWithObjects, callback) {
    const parameters = { mcFluffy, typesWithObjects };
    dispatcher.send("addToMcFluffyServer", parameters, callback);
};

const setToMcFluffy = function (
    mcFluffy,
    objects,
    preservePreferDouble = false,
    callback = _.noop
) {
    const parameters: setToMcFluffyParams = { mcFluffy, objects, preservePreferDouble };
    parameters.objects = objects.map((object) => ({
        id: object.object ? object.object.id : object.id,
        typeid: object.typeId ? object.typeId : object.type.id,
    }));
    if (preservePreferDouble === true) {
        parameters.preservePreferDouble = true;
    }
    dispatcher.send("setToMcFluffy", parameters, callback);
};

const setReservationToMcFluffy = function (
    reservationIds,
    mcFluffy,
    modify,
    templateGroups,
    isNewReservation,
    callback
) {
    if (_.isFunction(modify)) {
        callback = modify;
    }
    if (_.isFunction(templateGroups)) {
        callback = modify;
    }
    const parameters = { mcFluffy, reservationIds, modify, templateGroups, isNewReservation };
    dispatcher.send("setReservationToMcFluffy", parameters, callback);
};

const setOrderRowToMcFluffy = function (orderRowId, mcFluffy, templateGroups, callback) {
    // In: orderRowId, fluffy, templateGroupIds
    const parameters = { orderRowId, mcFluffy, templateGroups };
    dispatcher.send("setOrderRowToMcFluffy", parameters, callback);
};

const cancelReservation = function (reservationIds, opts = { longOperation: undefined }, callback) {
    if (_.isFunction(opts)) {
        callback = opts;
    }

    const defaults = {
        singleTransaction: true,
        addGroupReservations: false,
    };
    const settings = _.extend({}, defaults, opts);

    let fullResult = [];
    const calls = _.splitArray(reservationIds, CANCEL_RESERVATIONS_BATCH_SIZE).map(
        (batch) => (done) => {
            dispatcher.send(
                "cancelReservations",
                { reservationIds: batch, ...settings },
                (result) => {
                    try {
                        fullResult = fullResult.concat(result.parameters[0]);
                    } catch (error) {
                        console.error("Error in cancelReservation", error);
                    } finally {
                        done();
                    }
                },
                opts.longOperation
            );
        }
    );
    _.runSync(calls, () => {
        callback({ parameters: fullResult });
    });
};

const restoreReservations = function (reservationIds, allowDoubleReservation, callback) {
    dispatcher.send("restoreReservations", { reservationIds, allowDoubleReservation }, callback);
};

// Separate function just for clarity, invokes the same API call as restoreReservations above
const restoreReservationsFromHistory = function (
    reservationIds,
    modified,
    modifiedBy,
    allowDoubleReservation,
    callback
) {
    dispatcher.send(
        "restoreReservations",
        {
            reservationIds,
            modified,
            modifiedBy,
            allowDoubleReservation,
        },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in restoreReservationsFromHistory", error);
            }
        }
    );
};

const removeFromMcFluffy = function (mcFluffy, idValue, isValueTypeId, callback) {
    const parameters: any = { mcFluffy };
    if (isValueTypeId) {
        parameters.typeId = idValue;
    } else {
        parameters.objectId = idValue;
    }
    dispatcher.send("removeFromMcFluffy", parameters, callback);
};

const replaceInMcFluffy = function (
    mcFluffy,
    idValue,
    isValueTypeId,
    newTypeId,
    newObjectId,
    callback
) {
    const parameters: any = { mcFluffy, newTypeId };
    if (isValueTypeId) {
        parameters.typeId = idValue;
    } else {
        parameters.objectId = idValue;
    }
    if (newObjectId) {
        parameters.newObjectId = newObjectId;
    }
    dispatcher.send("replaceInMcFluffy", parameters, callback);
};

const reserveMcFluffy = function (mcFluffy, headerObjects, callback) {
    dispatcher.send("reserveMcFluffy", { mcFluffy, headerObjects }, callback);
};

const getLimitation = function (functionName, propertyName, callback) {
    if (limitCache[functionName]) {
        callback(limitCache[functionName][propertyName].value);
    } else {
        dispatcher.send("getLimitations", { functionName }, (result) => {
            try {
                result = result.parameters;
                limitCache[functionName] = {};
                const numResults = result[0].length;
                let i;
                for (i = 0; i < numResults; i++) {
                    limitCache[functionName][result[0][i]] = {
                        value: result[1][i],
                        description: result[2][i],
                    };
                }
                if (limitCache[functionName][propertyName]) {
                    callback(limitCache[functionName][propertyName].value);
                } else {
                    callback(-1);
                }
            } catch (error) {
                console.error("getLimitations", error);
            }
        });
    }
};

const getUserLocale = function (languageString, useCurrentUser, callback) {
    if (_.isFunction(useCurrentUser)) {
        callback = useCurrentUser;
        useCurrentUser = true;
    }
    dispatcher.send(
        "getUserLocale",
        { language: languageString, dateLanguage: languageString, useCurrentUser },
        callback
    );
};

const getWebLoginCreateUserUrl = function (client, database, language, callback) {
    dispatcher.send("getWebLoginCreateUserUrl", { client, database, language }, callback);
};

const getCurrentUserPrefs = function (callback) {
    dispatcher.send("getCurrentUserPrefs", {}, (result) => {
        try {
            const parameters = result.parameters;
            const prefs = {
                isExternalUser: parameters[0],
                login: parameters[1],
                firstName: parameters[2],
                lastName: parameters[3],
                email: parameters[4],
                language: parameters[6],
                dateFormat: parameters[7],
                emailStatus: parameters[8].status,
                reservationStatus: parameters[13].status,
                timezone: parameters[14],
                displayTimezones: parameters[15],
                useCustomWeekNames: parameters[16],
            };
            callback(prefs);
        } catch (error) {
            console.error("Error in getCurrentUserPrefs", error);
        }
    });
};

const setCurrentUserPrefs = function (userPrefs, callback) {
    dispatcher.send("setCurrentUserPrefs", userPrefs, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in setCurrentUserPrefs", error);
        }
    });
};

const getObjectTemplate = function (typeIds, callback) {
    dispatcher.send("getObjectTemplate", { typeids: typeIds }, callback);
};

const getOrganizations = function (options, callback) {
    dispatcher.send("getOrganizations", options, callback);
};

const changePassword = function (newPassword, callback) {
    dispatcher.send("changePassword", { newPassword, confirmPassword: newPassword }, callback);
};

const updatePassword = function (oldPassword, newPassword, callback) {
    dispatcher.send("updatePassword", { oldPassword, newPassword }, callback);
};

const getCurrentUserObjects = function (typeIds, includeLastSelected, callback) {
    dispatcher.send("getCurrentUserObjects", { types: typeIds, includeLastSelected }, (result) => {
        try {
            const objects = result.parameters[0].map((object) => object.id);
            callback(objects);
        } catch (error) {
            console.error("Error in getCurrentUserObjects", error);
        }
    });
};

const deleteObjectRelation = function (objectId, fluffy, callback) {
    dispatcher.send("deleteObjectRelation", { objectId, mcFluffy: fluffy }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in deleteObjectRelation", error);
        }
    });
};

const deleteFromLatest = function (objectId, callback) {
    dispatcher.send("deleteObjectFavorite", { objectId }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in deleteObjectFavorite", error);
        }
    });
};

const findHPeriods = function (callback) {
    dispatcher.send("findHPeriods", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in findHPeriods", error);
        }
    });
};

const getHPeriodDefs = function (id, callback) {
    dispatcher.send("getHPeriodDefs", { id }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getHPeriodDefs", error);
        }
    });
};

const getPrefsKey = (key, refs, language, isCurrentUser) =>
    `${key}:${JSON.stringify(refs)}:${String(language)}:${String(isCurrentUser)}`;

const prefsCache = {};

const setPreferences = function (key, refs, values, language, callback) {
    if (!callback && !language && _.isFunction(values)) {
        callback = values;
        values = refs;
        refs = undefined;
    } else if (!callback && _.isFunction(language)) {
        callback = language;
        language = undefined;
    }
    const isCurrentUser = language === undefined;
    values = values.map((value) => {
        if (_.isBoolean(value)) {
            return value ? "true" : "false";
        }
        return value;
    });
    const prefsKey = getPrefsKey(key, refs, language, isCurrentUser);
    if (_.isEqual(prefsCache[prefsKey], values)) {
        //console.error("Setting same value, ignoring", key, values);
        callback(true);
        return;
    }
    prefsCache[prefsKey] = values;
    dispatcher.send(
        "setPreferences",
        { key, references: refs, values, language, isCurrentUser },
        (result) => {
            if (!result.parameters) {
                callback(false);
            } else {
                callback(result.parameters.ok);
            }
        }
    );
};

// Saves preferences used by every user who has not saved their own for the key and reference.
const setDefaultPreferences = function (key, refs, values, language, callback) {
    if (!callback && !language && _.isFunction(values)) {
        callback = values;
        values = refs;
        refs = undefined;
    } else if (!callback && _.isFunction(language)) {
        callback = language;
        language = undefined;
    }
    values = values.map((value) => {
        if (_.isBoolean(value)) {
            return value ? "true" : "false";
        }
        return value;
    });
    prefsCache[getPrefsKey(key, refs, language, false)] = undefined;
    prefsCache[getPrefsKey(key, refs, language, true)] = undefined;
    dispatcher.send(
        "setPreferences",
        { key, references: refs, values, language, isCurrentUser: false },
        (result) => {
            if (!result.parameters) {
                callback(false);
            } else {
                callback(result.parameters.ok);
            }
        }
    );
};

const parsePrefsValues = (values, refs) => {
    let outValue;
    if (values.length === 1 && values[0] === "") {
        outValue = null;
    } else if (refs) {
        outValue = values;
    } else if (values[0] === "true") {
        outValue = true;
    } else if (values[0] === "false") {
        outValue = false;
    } else {
        outValue = values[0];
    }
    return outValue;
};

const getPreferences = function (key, refs, language, callback) {
    if (!callback && !language && _.isFunction(refs)) {
        callback = refs;
        refs = undefined;
    } else if (!callback && _.isFunction(language)) {
        callback = language;
        language = undefined;
    }
    const isCurrentUser = language === undefined;
    const prefsKey = getPrefsKey(key, refs, language, isCurrentUser);
    if (prefsCache[prefsKey] !== undefined) {
        //console.error("Returning prefs value from cache", key);
        callback(parsePrefsValues(prefsCache[prefsKey], refs));
    } else {
        dispatcher.send(
            "getPreferences",
            { key, references: refs, language, isCurrentUser },
            (result) => {
                try {
                    const values = result.parameters.values;
                    prefsCache[prefsKey] = values;
                    callback(parsePrefsValues(values, refs));
                } catch (error) {
                    console.error("Error in getPreferences", error);
                }
            }
        );
    }
};

const parsePrefValue = (value) => {
    let outValue;
    if (value.length === 1 && value[0] === "") {
        outValue = null;
    } else if (value.length === 1 && value[0] === "true") {
        outValue = true;
    } else if (value.length === 1 && value[0] === "false") {
        outValue = false;
    } else if (value === "true") {
        outValue = true;
    } else if (value === "false") {
        outValue = false;
    } else {
        outValue = value;
    }
    return outValue;
};

const getPreferencesMulti = function (keys, callback) {
    dispatcher.send("getPreferencesMulti", { keys, language: undefined }, (result) => {
        const parsedValues = {};
        try {
            Object.entries(result.parameters.values).forEach((entry) => {
                parsedValues[entry[0]] = parsePrefValue(entry[1]);
                prefsCache[entry[0]] = entry[1];
            });
        } catch (error) {
            console.error("Error in getPreferencesMulti", error);
        } finally {
            callback(parsedValues);
        }
    });
};

const getOtherUserPrefs = function (callback) {
    dispatcher.send("getOtherUserPrefs", {}, (result) => {
        try {
            callback(result[0]);
        } catch (error) {
            console.error("Error in getOtherUserPrefs", error);
        }
    });
};

const getStartupData = function (callback) {
    dispatcher.send("getStartupData", {}, (result) => {
        try {
            callback(result[0]);
        } catch (error) {
            console.error("Error in getStartupData", error);
        }
    });
};

const getSupportInfo = function (callback) {
    dispatcher.send("getSupportInfo", {}, (result) => {
        try {
            return callback(result.parameters[0], result.parameters[1], result.parameters[2] || []);
        } catch (error) {
            console.error("Error in getSupportInfo", error);
            return;
        }
    });
};

const supportsEmail = function (callback) {
    dispatcher.send("supportsEmail", {}, (result) => {
        try {
            return callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in supportsEmail", error);
            return;
        }
    });
};

const getEmailReservationPrefs = function (reservationIds, callback) {
    /*
            0: OK           boolean
            1: from         string
            2: toObjects    [string] - mottagare från objekt
            3: toMembers    [string] - mottagare från medlemmar
            4: ccMe         string - min adress om jag vill ha CC
            5: ccReserver   [string] - bokarens e-post om jag inte gjort bokningen
            6: fields       [fieldName] - bokningsfält som kan inkluderas
            7: subject      string
            8: message      string
            9: replyTo      string
        */
    dispatcher.send("getEmailReservationPrefs", { reservationIds }, (result) => {
        try {
            result = {
                ok: result.parameters[0],
                from: result.parameters[1],
                toObjects: result.parameters[2],
                toMembers: result.parameters[3],
                ccMe: result.parameters[4],
                ccReserver: result.parameters[5],
                fields: result.parameters[6],
                subject: result.parameters[7],
                message: result.parameters[8],
                replyTo: result.parameters[9],
            };
            return callback(result);
        } catch (error) {
            console.error("Error in getEmailReservationPrefs", error);
            return;
        }
    });
};

const sendEmailReservation = function (emailSettings, callback) {
    /*
            from            string
            to              [string]
            cc              [string]
            subject         [string]
            message         [string]
            reservationIds  [reservationId]
            fieldIds        [fieldId]
            replyTo        string
        */
    dispatcher.send("sendEmailReservation", emailSettings, (result) => {
        try {
            const errorObject = {
                message: result.parameters[1],
                details: result.parameters[2],
            };
            return callback(result.parameters[0] ? null : errorObject);
        } catch (error) {
            console.error("Error in sendEmailReservation", error);
            return null;
        }
    });
};

const findOrderRowsList = function (query, callback) {
    dispatcher.send("findOrderRowsList", query, callback, true);
};

const getOrderRows = function (query, callback) {
    dispatcher.send("getOrderRows", query, callback);
};

const getAuthenticatedUserInfo = function (callback) {
    dispatcher.send("getAuthenticatedUserInfo", {}, callback);
};

const getSystemBackgroundText = function (callback) {
    dispatcher.send("getSystemBackgroundText", {}, (result) => callback(result.parameters[0]));
};

const getSpecialTypes = function (callback) {
    dispatcher.send("getSpecialTypes", {}, (result) => callback(result.parameters));
};

const getSpecialFields = function (callback) {
    dispatcher.send("getSpecialFields", {}, (result) => callback(result.parameters));
};

const getPeriods = function (periodIds, callback) {
    dispatcher.send("getPeriods", { periodIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getPeriods", error);
        }
    });
};

const getModifiableObjectFields = function (objectId, callback) {
    dispatcher.send("getModifiableObjectFields", { objectId }, (result) => {
        try {
            return callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getModifiableObjectFields", error);
            return;
        }
    });
};

const okToModifyObjects = function (objectIds, callback) {
    dispatcher.send("okToModifyObjects", { objectIds: _.asArray(objectIds) }, (result) => {
        try {
            return callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in okToModifyObjects", error);
            return;
        }
    });
};

const okToModifyOrders = function (orderIds, callback) {
    dispatcher.send("okToModifyOrders", { orderIds: _.asArray(orderIds) }, (result) => {
        try {
            return callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in okToModifyOrders", error);
        }
    });
};

const moveReservationsInTime = function (
    reservationIds,
    numSeconds,
    addGroupReservations,
    doubleReserveAll,
    durationSeconds,
    isDurationRelative,
    callback,
    errorCallback
) {
    dispatcher.send(
        "moveReservationsInTime",
        {
            timeout: 0,
            reservationIds,
            seconds: numSeconds,
            addGroupReservations,
            doubleReserveAll,
            durationSeconds,
            isDurationRelative,
        },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in moveReservationsInTime", error);
            }
        },
        true,
        errorCallback
    );
};

const copyReservationsInTime = function (
    reservationIds,
    numSeconds,
    addGroupReservations,
    doubleReserveAll,
    callback,
    errorCallback
) {
    dispatcher.send(
        "copyReservationsInTime",
        { timeout: 0, reservationIds, seconds: numSeconds, addGroupReservations, doubleReserveAll },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in copyReservationsInTime", error);
            }
        },
        true,
        errorCallback
    );
};

const replaceObjectsOnReservations = function (
    reservationIds,
    newObjectId,
    typeId,
    oldObjectId,
    oldTypeId,
    allowAvailabilityOverlap,
    addIfMissingTypeOnly,
    allowIncomplete,
    allowDoubleBooking,
    callback,
    errorCallback
) {
    dispatcher.send(
        "replaceObjectsOnReservations",
        {
            timeout: 0,
            reservationIds,
            newObjectId,
            typeId,
            oldObjectId,
            oldTypeId,
            allowAvailabilityOverlap,
            addIfMissingTypeOnly,
            allowIncomplete,
            allowDoubleBooking,
        },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in replaceObjectsOnReservations", error);
            }
        },
        true,
        errorCallback
    );
};

const dayNumberFromString = function (dateString, callback) {
    dispatcher.send("dayNumberFromString", { dateString }, (result) => callback(result.parameters));
};

const reservationClusterChangeTime = function (
    reservationIds,
    groupIds,
    timeslots,
    allowAsymmetric,
    copy,
    allowDoubleObjects,
    allowAvailabilityOverlap,
    newObjects,
    oldObjects,
    callback
) {
    dispatcher.send(
        "reservationClusterChangeTime",
        {
            reservationIds,
            groupIds,
            timeslots,
            allowAsymmetric,
            copy,
            allowDoubleObjects,
            allowAvailabilityOverlap,
            newObjects,
            oldObjects,
        },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in reservationClusterChangeTime", error);
            }
        }
    );
};

const getValidReservationStatus = function (statusList, callback) {
    dispatcher.send("getValidReservationStatus", { statusList }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getValidReservationStatus", error);
        }
    });
};

const getReservationStatus = function (reservationIds, callback) {
    dispatcher.send("getReservationStatus", { reservationIds }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in getReservationStatus", error);
        }
    });
};

const setReservationStatus = function (reservationIds, status, callback) {
    dispatcher.send(
        "setReservationStatus",
        { reservationIds, status },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in setReservationStatus", error);
            }
        },
        true
    );
};

const createReservationLayer = function (name, description, callback) {
    dispatcher.send("createReservationLayer", { name, description }, (result) => {
        try {
            callback(result.parameters[0], result.parameters[1]);
        } catch (error) {
            console.error("Error in createReservationLayer", error);
        }
    });
};

// If deleteReservations is false, the layer won't be deleted if there are any reservations on it
const deleteReservationLayer = function (layerId, deleteReservations, callback, errorCallback) {
    dispatcher.send(
        "deleteReservationLayer",
        { layerId, deleteReservations },
        (result) => {
            try {
                // deleted - true or false
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in deleteReservationLayer", error);
            }
        },
        true,
        errorCallback
    );
};

const addToReservationLayer = function (
    layerId,
    reservationIds,
    move,
    otherReservationids,
    addGroupReservations,
    ignoreFields,
    groupMap,
    callback,
    errorCallback
) {
    dispatcher.send(
        "addToReservationLayer",
        {
            layerId,
            reservationIds,
            move,
            otherReservationids,
            addGroupReservations,
            ignoreFields,
            groupMap,
        },
        (result) => {
            try {
                // Status array, all reservations, bool success
                callback({
                    status: result.parameters[0],
                    allReservations: result.parameters[1],
                    success: result.parameters[2],
                    groupMap: result.parameters[3],
                });
            } catch (error) {
                console.error("Error in addToReservationLayer", error);
            }
        },
        true,
        errorCallback
    );
};

const deleteFromReservationLayer = function (reservationIds, callback, errorCallback) {
    dispatcher.send(
        "deleteFromReservationLayer",
        { reservationIds },
        (result) => {
            try {
                // Status array
                callback(result.parameters[0]);
            } catch (error) {
                console.error("deleteFromReservationLayer", error);
            }
        },
        true,
        errorCallback
    );
};

const persistReservationLayer = function (
    layerIdOrReservationIds,
    ignoreUnchangedReservations,
    allowDoubleReservations,
    callback,
    errorCallback
) {
    if (_.isArray(layerIdOrReservationIds)) {
        dispatcher.send(
            "persistReservationLayer",
            {
                reservationIds: layerIdOrReservationIds,
                ignoreUnchangedReservations,
                allowDoubleReservations,
            },
            (result) => {
                try {
                    callback(result.parameters[0]);
                } catch (error) {
                    console.error("Error in persistReservationLayer", error);
                }
            },
            true,
            errorCallback
        );
        return;
    }
    dispatcher.send(
        "persistReservationLayer",
        { layerId: layerIdOrReservationIds, ignoreUnchangedReservations, allowDoubleReservations },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in persistReservationLayer", error);
            }
        },
        true,
        errorCallback
    );
};

const checkPersistReservationLayer = function (layerId, callback) {
    dispatcher.send("checkPersistReservationLayer", { layerId }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in checkPersistReservationLayer", error);
        }
    });
};

const getReservationLayers = function (layerKind, callback) {
    dispatcher.send("getReservationLayers", { layerKind }, (result) => {
        try {
            // Kind 3 is experiment layers
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getReservationLayers", error);
        }
    });
};

const getReservationLayerDefs = function (layerIds, callback) {
    dispatcher.send("getReservationLayerDefs", { layerIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getReservationLayerDefs", error);
        }
    });
};

const getReservationsOnLayer = function (layerId, callback) {
    dispatcher.send("getReservationsOnLayer", { layerId }, (result) => {
        // 0 - new, 1 - modified, 2 - cancelled
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in getReservationsOnLayer", error);
        }
    });
};

const renameReservationLayer = function (layerId, name, description, callback) {
    dispatcher.send("renameReservationLayer", { layerId, name, description }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in renameReservationLayer", error);
        }
    });
};

const hasFeatures = function (features, callback) {
    dispatcher.send("hasFeature", { features }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in hasFeatures", error);
        }
    });
};

const hasExternalPermissions = function (permissions, callback) {
    dispatcher.send("hasExternalPermissions", { permissions }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in hasExternalPermissions", error);
        }
    });
};

// Checks the given layer for collisions with the base layer.
// Can be extended - by adding the collisionLayer parameter - to check against other layers
const getReservationCollisions = function (layerId, callback) {
    dispatcher.send(
        "getReservationCollisions",
        { layerId, collisionLayerId: 0 },
        (result) => {
            try {
                // [0] - reservations, [1] their colliding reservations, [2] their colliding objects, [3] their colliding info reservations
                const cR = result.parameters[1];
                const cO = result.parameters[2];
                const cI = result.parameters[3];
                callback(
                    result.parameters[0].map((res, index) => ({
                        id: res.id,
                        collidingReservations: cR[index].map((r) => r.id),
                        collidingObjects: cO[index].map((o) => o.id),
                        collidingInfoReservations: cI[index].map((i) => i.id),
                    }))
                );
            } catch (error) {
                console.error("Error in getReservationCollisions", error);
            }
        },
        true
    );
};

const addReservationsToGroups = function (
    reservationIds,
    groupIds,
    clusterKind = ClusterKind.DATE,
    callback
) {
    dispatcher.send(
        "addReservationsToGroups",
        { reservationIds, groupIds, clusterKind },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in addReservationsToGroups", error);
            }
        }
    );
};

const removeReservationsFromGroups = function (reservationIds, callback) {
    dispatcher.send("removeReservationsFromGroups", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in removeReservationsFromGroups", error);
        }
    });
};

const groupReservations = function (
    reservationIds,
    clusterKind = ClusterKind.DATE,
    callback = _.noop
) {
    if (_.isFunction(clusterKind)) {
        callback = clusterKind;
        clusterKind = ClusterKind.DATE;
    }
    dispatcher.send("groupReservations", { clusterKind, reservationIds }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in groupReservations", error);
        }
    });
};

const ungroupReservations = function (groupIds, callback = _.noop) {
    dispatcher.send("ungroupReservations", { groupIds }, (result) => {
        try {
            callback([result.parameters[0].map((res) => res.id), result.parameters[1]]);
        } catch (error) {
            console.error("Error in ungroupReservations", error);
        }
    });
};

const getGroupedEntries = function (
    clusterKind = ClusterKind.NONE,
    reservationIds,
    opts = { typeFields: null },
    callback = _.noop
) {
    if (_.isFunction(opts)) {
        callback = opts;
    }

    dispatcher.send(
        "getGroupedEntries",
        { clusterKind, reservationIds, typeFields: opts.typeFields || [] },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getGroupedEntries", error);
            }
        }
    );
};

const findSumSettings = function (callback) {
    dispatcher.send("findSumSettings", {}, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in findSumSettings", error);
        }
    });
};

const getSumSetting = function (ids = [], callback) {
    dispatcher.send("getSumSetting", { ids }, (result) => {
        try {
            result.parameters[0].forEach((sumSetting) => {
                sumSetting.types = sumSetting.types.map((type) => type.id);
                sumSetting.includeMembers = _.filter(
                    sumSetting.types,
                    (type, index) => sumSetting.includeMembers[index] === true
                );
            });
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getSumSetting", error);
        }
    });
};

const setSumSetting = function (sumSetting, callback) {
    dispatcher.send("setSumSetting", sumSetting, (result) => {
        try {
            callback({ ok: result.parameters[0], id: result.parameters[1] });
        } catch (error) {
            console.error("Error in setSumSetting", error);
        }
    });
};

const deleteSumSetting = function (id, callback) {
    dispatcher.send("deleteSumSetting", { id }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in deleteSumSetting", error);
        }
    });
};

const getColorDefs = function (callback) {
    dispatcher.send("getColorDefs", { objectColorsOnly: false }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getColorDefs", error);
        }
    });
};

const getColorTypes = function (callback) {
    dispatcher.send("getColorTypes", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getColorTypes", error);
        }
    });
};

const getTypesOnReservations = function (reservationIds, callback) {
    dispatcher.send("getTypesOnReservations", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getTypesOnReservations", error);
        }
    });
};

const getObjectsOnReservations = function (reservationIds, typeId, callback) {
    dispatcher.send(
        "getObjectsOnReservations",
        { reservationIds, typeId, reservable: false },
        (result) => {
            try {
                //[0]: Objects, [1]: occurances on reservations
                callback(result.parameters[0], result.parameters[1]);
            } catch (error) {
                console.error("Error in getObjectsOnReservations", error);
            }
        }
    );
};

const getReservableTypesForType = function (typeId, callback) {
    dispatcher.send("getReservableTypesForType", typeId !== null ? { typeId } : {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getReservableTypesForType", error);
        }
    });
};

const getReservationsWithObjects = function (
    reservationIds,
    removeObjectIds,
    addOjectIds,
    addTypeId,
    addIfMissingTypeOnly,
    callback
) {
    dispatcher.send(
        "getReservationsWithObjects",
        { reservationIds, removeObjectIds, addOjectIds, addTypeId, addIfMissingTypeOnly },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getReservationsWithObjects", error);
            }
        }
    );
};

const getReservationsWithTime = function (
    reservationIds,
    addGroupReservations,
    callback,
    errorCallback
) {
    dispatcher.send(
        "getReservationsWithTime",
        { reservationIds, addGroupReservations },
        (result) => {
            try {
                // Success, reservationIds, timeslots, firstBegin, lastEnd
                callback(
                    result.parameters[0],
                    result.parameters[1],
                    result.parameters[2],
                    result.parameters[3],
                    result.parameters[4]
                );
            } catch (error) {
                console.error("Error in getReservationsWithTime", error);
            }
        },
        true,
        errorCallback
    );
};

const findPeriods = function (callback) {
    dispatcher.send("findPeriods", {}, (result) => {
        try {
            callback(result.parameters[0], result.parameters[1]); // Periods, total number of rows
        } catch (error) {
            console.error("Error in findPeriods", error);
        }
    });
};

const okToSetReservationOrganizations = function (reservationIds, callback) {
    dispatcher.send("okToSetReservationOrganizations", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]); // Just returns true or false
        } catch (error) {
            console.error("Error in okToSetReservationOrganizations", error);
        }
    });
};

const setReservationOrganizations = function (reservationIds, organizationIds, callback) {
    dispatcher.send(
        "setReservationOrganizations",
        { reservationIds, organizationIds },
        (result) => {
            try {
                callback(result.parameters[0]); // Array of status
            } catch (error) {
                console.error("Error in setReservationOrganizations", error);
            }
        }
    );
};

const getTimezones = function (callback) {
    dispatcher.send("getTimeZones", {}, (result) => {
        try {
            const zones = result.parameters[0].map((shortName, index) => ({
                shortName,
                name: result.parameters[2][index],
                offset: result.parameters[1][index],
            }));
            callback(zones);
        } catch (error) {
            console.error("Error in getTimezones", error);
        }
    });
};

const getTimezonesList = function (callback) {
    dispatcher.send("getTimeZonesList", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getTimezonesList", error);
        }
    });
};

const getCurrentDateTime = function (callback) {
    dispatcher.send("getCurrentDateTime", {}, (result) => {
        try {
            callback(new MillenniumDateTime(result.parameters[0].datetime));
        } catch (error) {
            console.error("Error in getCurrentDateTime", error);
        }
    });
};

const prefsLoginUser = function (username, password, callback) {
    dispatcher.send("prefsLoginUser", { username, password }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsLoginUser", error);
        }
    });
};

const prefsGetUser = function (token, callback) {
    dispatcher.send("prefsGetUser", { token }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsGetUser", error);
        }
    });
};

const prefsGetForms = function (token, callback) {
    dispatcher.send("prefsGetForms", { token }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsGetForms", error);
        }
    });
};

const prefsGetFormSubmissions = function (token, formId, callback) {
    dispatcher.send("prefsGetFormSubmissions", { token, formId }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsGetFormSubmissions", error);
        }
    });
};

const prefsGetFormSubmission = function (token, formId, submissionId, callback) {
    dispatcher.send("prefsGetFormSubmission", { token, formId, submissionId }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsGetFormSubmission", error);
        }
    });
};

const prefsGetElements = function (token, callback) {
    dispatcher.send("prefsGetElements", { token }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in prefsGetElements", error);
        }
    });
};

const getMemberTypesForReservations = function (reservationIds, callback) {
    dispatcher.send("getMemberTypesForReservations", { reservationIds }, (result) => {
        try {
            callback({
                parentMemberTypes: result.parameters[0],
                childMemberTypes: result.parameters[1],
            });
        } catch (error) {
            console.error("Error in getMemberTypesForReservations", error);
        }
    });
};

const getMemberObjectsForReservations = function (
    reservationIds,
    groupTypeId,
    memberTypeId,
    callback
) {
    dispatcher.send(
        "getMemberObjectsForReservations",
        {
            reservationIds,
            groupTypeId,
            memberTypeId,
        },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getMemberObjectsForReservations", error);
            }
        }
    );
};

const getMemberExceptionObjectsForReservations = function (reservationIds, typeId, callback) {
    dispatcher.send(
        "getMemberExceptionObjectsForReservations",
        { reservationIds, typeId },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in getMemberExceptionObjectsForReservations", error);
            }
        }
    );
};

const saveExceptionObjectsForReservations = function (
    reservationIds,
    addObjectTypes,
    addObjects,
    removeObjects,
    callback
) {
    dispatcher.send(
        "saveExceptionObjectsForReservations",
        { reservationIds, addObjectTypes, addObjects, removeObjects },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in saveExceptionObjectsForReservations", error);
            }
        }
    );
};

const canHaveMemberExceptionObjectsForReservations = function (reservationIds, callback) {
    dispatcher.send(
        "canHaveMemberExceptionObjectsForReservations",
        { reservationIds },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in canHaveMemberExceptionObjectsForReservations", error);
            }
        }
    );
};

const supportsReservationExceptions = function (callback) {
    dispatcher.send("supportsReservationExceptions", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in supportsReservationExceptions", error);
        }
    });
};

const findObjectFieldsForReservationList = function (fieldKinds, callback) {
    dispatcher.send("findObjectFieldsForReservationList", { fieldKinds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in findObjectFieldsForReservationList", error);
        }
    });
};

const getObjectCategories = (callback) => {
    getFieldDefsForReservations((reservationFields) => {
        findObjectFieldsForReservationList(null, (objectFields) => {
            getFieldDefs(
                objectFields.map((fld) => fld.id),
                undefined,
                (defs) => {
                    const reservationCategories = reservationFields.filter(
                        (field) => field.kind === FieldKind.CATEGORY
                    );
                    const reservationCheckboxes = reservationFields.filter(
                        (field) => field.kind === FieldKind.BOOLEAN
                    );
                    const categories = defs.filter((field) => field.kind === FieldKind.CATEGORY);
                    const checkboxes = defs.filter((field) => field.kind === FieldKind.BOOLEAN);
                    callback({
                        availableReservationCategories: reservationCategories,
                        availableReservationCheckboxes: reservationCheckboxes,
                        availableObjectCategories: categories,
                        availableObjectCheckboxes: checkboxes,
                    });
                }
            );
        });
    });
};

const moveReservationsFromWaitingList = (
    reservationId,
    timeslots,
    allowDoubleObjects,
    allowAvailabilityOverlap,
    callback
) => {
    dispatcher.send(
        "moveReservationsFromWaitingList",
        { reservationId, timeslots, allowDoubleObjects, allowAvailabilityOverlap },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in moveReservationsFromWaitingList", error);
            }
        }
    );
};

const moveReservationsToWaitingList = (reservationIds, createSingleReservation, callback) => {
    dispatcher.send(
        "moveReservationsToWaitingList",
        { reservationIds, createSingleReservation },
        (result) => {
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in moveReservationsToWaitingList", error);
            }
        }
    );
};

const showEnableCustomWeekNames = (callback) => {
    dispatcher.send("showEnableCustomWeekNames", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in showEnableCustomWeekNames", error);
        }
    });
};

const getCustomWeekNames = (beginDayNumber, endDayNumber, callback) => {
    dispatcher.send("getCustomWeekNames", { beginDayNumber, endDayNumber }, (result) => {
        try {
            callback(NamedWeek.create(result.parameters[0]));
        } catch (error) {
            console.error("Error in getCustomWeekNames", error);
        }
    });
};

const targetExternalAppsOnStaging = (callback) => {
    dispatcher.send("targetExternalAppsOnStaging", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in targetExternalAppsOnStaging", error);
        }
    });
};

const targetExternalAppsEnvironment = (callback) => {
    dispatcher.send("targetExternalAppsEnvironment", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in targetExternalAppsEnvironment", error);
        }
    });
};

const getUserSearchFields = (typeId, callback) => {
    if (userSearchFieldCache !== null) {
        return callback(userSearchFieldCache[typeId] || null);
    }
    getPreferences("userSearchFields", undefined, undefined, (result: string) => {
        if (result) {
            userSearchFieldCache = JSON.parse(result);
            callback(userSearchFieldCache?.[typeId] || null);
        }
        callback(null);
    });
};

const setUserSearchFields = (flds, typeId) => {
    if (userSearchFieldCache === null) {
        userSearchFieldCache = {};
    }
    userSearchFieldCache[typeId] = flds;
    setPreferences(
        "userSearchFields",
        undefined,
        [JSON.stringify(userSearchFieldCache)],
        undefined,
        _.noop
    );
};

const addTagToReservations = (reservationIds, tag, callback) => {
    dispatcher.send("addTagToReservations", { reservationIds, tag }, (result) => {
        try {
            callback(result.parameters);
        } catch (error) {
            console.error("Error in addTagToReservations", error);
        }
    });
};

const removeTagFromReservations = (reservationIds, tag, callback) => {
    dispatcher.send("removeTagFromReservations", { reservationIds, tag }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in removeTagFromReservations", error);
        }
    });
};

const createTag = (tag, callback) => {
    dispatcher.send("createTag", { tag }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in createTag", error);
        }
    });
};

const deleteTag = (tag, callback) => {
    dispatcher.send("deleteTag", { tag }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in deleteTag", error);
        }
    });
};

const findTags = (searchText, callback) => {
    dispatcher.send("findTags", { searchText }, (result) => {
        try {
            callback({
                count: result.parameters[0],
                tags: result.parameters[1],
                isCurrentUserTag: result.parameters[2],
            });
        } catch (error) {
            console.error("Error in findTags", error);
        }
    });
};

const findMyTags = (searchText, callback) => {
    dispatcher.send("findTags", { searchText, myTags: true }, (result) => {
        try {
            callback({
                count: result.parameters[0],
                tags: result.parameters[1],
                isCurrentUserTag: result.parameters[2],
            });
        } catch (error) {
            console.error("Error in findMyTags", error);
        }
    });
};

const okToDeleteReservationsCancelled = (reservationIds, callback) => {
    if (reservationIds.length === 0) {
        dispatcher.send("okToDeleteReservationsCancelled", { reservationIds }, (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in okToDeleteReservationsCancelled", error);
            }
        });
    } else {
        let fullResult = [];
        const calls = _.splitArray(reservationIds, STATUS_BATCH_SIZE).map((batch) => (done) => {
            dispatcher.send(
                "okToDeleteReservationsCancelled",
                { reservationIds: batch },
                (result) => {
                    try {
                        fullResult = fullResult.concat(result.parameters[0]);
                    } catch (error) {
                        console.error("Error in okToDeleteReservationsCancelled", error);
                    } finally {
                        done();
                    }
                },
                true
            );
        });
        _.runSync(calls, () => {
            callback(fullResult);
        });
    }
};

const deleteReservationsCancelled = (reservationIds, callback) => {
    let fullResult = [];
    const calls = _.splitArray(reservationIds, STATUS_BATCH_SIZE).map((batch) => (done) => {
        dispatcher.send(
            "deleteReservationsCancelled",
            { reservationIds: batch },
            (result) => {
                try {
                    fullResult = fullResult.concat(result.parameters[0]);
                } catch (error) {
                    console.error("Error in deleteReservationsCancelled", error);
                } finally {
                    done();
                }
            },
            true
        );
    });
    _.runSync(calls, () => {
        callback(fullResult);
    });
};

const getReservationConflicts = (query, callback) => {
    // typeid
    // objectIds
    // start
    // end
    // includeUnintentionalDoubleReservations = true
    // includeIntentionalDoubleReservations = false
    // filterObjectIds
    // filterReservationModes
    // includeCollisionsOutsideOfFilters = false
    dispatcher.send(
        "getReservationConflicts",
        query,
        (result) => {
            // Returns five arrays
            // doubleReservationObjects
            // firstReservations
            // firstObjects
            // secondReservations
            // secondObjects
            try {
                callback(result.parameters);
            } catch (error) {
                console.error("Error in getReservationConflicts", error);
            }
        },
        true
    );
};

const getExamSettings = function (environment, platform, callback, errorCallback) {
    dispatcher.send(
        "getExamSettings",
        { environment, platform },
        (response) => {
            try {
                return callback(response.parameters ? response.parameters[0] : response);
            } catch (error) {
                console.error("Error in getExamSettings", error);
                return;
            }
        },
        false,
        errorCallback
    );
};

const getServerEnv = (callback) => {
    dispatcher.send("getServerEnv", {}, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getServerEnv", error);
        }
    });
};

const loginWithSSOToken = (token, callback) => {
    dispatcher.send("loginWithSSOToken", { token }, (result) => {
        try {
            console.log(result);
            callback(result[0]);
        } catch (error) {
            console.error("Error in loginWithSSOToken", error);
        }
    });
};

const logoutUser = (callback) => {
    dispatcher.send("logoutUser", {}, (result) => {
        try {
            console.log(result);
            callback(result);
        } catch (error) {
            console.error("Error in logoutUser", error);
        }
    });
};

const addReservationsToReservationGroup = function (groupId, reservationIds, callback) {
    dispatcher.send("addReservationsToReservationGroup", { groupId, reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in addReservationsToReservationGroup", error);
        }
    });
};

const removeReservationsFromReservationGroup = function (groupId, reservationIds, callback) {
    dispatcher.send(
        "removeReservationsFromReservationGroup",
        { groupId, reservationIds },
        (result) => {
            try {
                callback(result.parameters[0]);
            } catch (error) {
                console.error("Error in removeReservationsFromReservationGroup", error);
            }
        }
    );
};

const createReservationGroup = function (name, extid, callback = _.noop) {
    dispatcher.send("createReservationGroup", { extid, name }, (result) => {
        callback(result);
    });
};

const updateReservationGroup = function (id, extid, name, callback = _.noop) {
    dispatcher.send("updateReservationGroup", { id, extid, name }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in updateReservationGroup", error);
        }
    });
};

const deleteReservationGroup = function (groupIds, callback = _.noop) {
    let groupId = _.isArray(groupIds) ? groupIds[0] : groupIds;
    dispatcher.send("deleteReservationGroup", { groupId }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in deleteReservationGroup", error);
        }
    });
};

const getReservationGroup = function (groupId, callback = _.noop) {
    dispatcher.send("getReservationGroup", { groupId }, (result) => {
        try {
            if (result.parameters[0] === false) {
                callback(false);
            }
            callback({
                id: result.parameters[1],
                extId: result.parameters[2],
                name: result.parameters[3],
            });
        } catch (error) {
            console.error("Error in getReservationGroup", error);
            callback(false);
        }
    });
};

const createReservationGroupWithReservations = function (
    name,
    reservationIds,
    callback = _.noop,
    errorCallback = _.noop
) {
    createReservationGroup(name, null, (result) => {
        try {
            if (result.parameters[1]) {
                if (!reservationIds || reservationIds.length === 0) {
                    callback(result.parameters[1]);
                } else {
                    addReservationsToReservationGroup(
                        result.parameters[1],
                        reservationIds,
                        (addResult) => {
                            if (addResult.details) {
                                deleteReservationGroup(result.parameters[1], _.noop);
                                errorCallback(addResult.details);
                            } else {
                                callback(result.parameters[1]);
                            }
                        }
                    );
                }
            } else {
                errorCallback(result.parameters[0].details);
            }
        } catch (error) {
            console.error("Error in createReservationGroupWithReservations", error);
        }
    });
};

const getPaddingRules = function (ruleIds, callback = _.noop) {
    dispatcher.send("getPaddingRuleNames", { ruleIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in getPaddingRules", error);
        }
    });
};

const lockReservations = function (reservationIds, reason, callback = _.noop) {
    dispatcher.send("addReservationsLock", { reservationIds, reason }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in lockReservations", error);
        }
    });
};

const unlockReservations = function (reservationIds, callback = _.noop) {
    dispatcher.send("removeReservationsLock", { reservationIds }, (result) => {
        try {
            callback(result.parameters[0]);
        } catch (error) {
            console.error("Error in unlockReservations", error);
        }
    });
};

const setReservationCapacity = function (
    reservationIds,
    allowUnderCapacity,
    capacity,
    ordering,
    callback = _.noop
) {
    dispatcher.send(
        "setReservationCapacity",
        { reservationIds, allowUnderCapacity, capacity, ordering },
        (result) => {
            callback(result.parameters[0]);
        }
    );
};

module.exports = {
    getLimitation,
    getDatabases,
    getAuthServers,
    getAllAuthServers,
    getSingleSignOnServers,
    getServers,
    getVersionInfo,
    getLanguages,
    getTemplateGroups,
    findReservations,
    findReservationsList,
    findAllReservationsList,
    findReservationsCancelledList,
    okToCancelReservations,
    okToChangeViews,
    okToPublishView,
    findOrders,
    getOrders,
    exportOrders,
    importOrders,
    getReservations,
    getReservationsByExtid,
    getReservationsCancelled,
    getReservationsHistory,
    exportReservations,
    importReservations,
    cancelReservation,
    restoreReservations,
    restoreReservationsFromHistory,
    getModifiableReservationFields,
    setModifiableReservationFields,
    findEntries,
    getAllEntryPropertyDefs,
    findTypes,
    findAliasTypes,
    findFields,
    getAllFields,
    getFields,
    getFieldsByExtid,
    getFieldDefs,
    getAllFieldDefs,
    findCategories,
    setPrimaryField,
    getPrimaryFields,
    getObjects,
    getObjectsByExtid,
    getObjectNames,
    getObjectNamesByExtid,
    getObjectsLimited,
    getAssociatedTypes,
    getAssociatedObjects,
    getSuggestedObjectsSimple,
    getSuggestedObjectsAdvanced,
    getSuggestedObjectsAdvanced2,
    deleteFromSuggestedObjects,
    findObjects,
    exportObjects,
    exportObjectsBatch,
    importObjects,
    importObjectsBatch,
    createEditableObject,
    deleteObjects,
    getTypeDefsExtended,
    getTypes,
    getTypesByExtid,
    getRelatedTypes,
    getCreateTypes,
    getCreateTypesKinds,
    findNextCreateTypes,
    getTypeTree,
    updateMcFluffy,
    addToMcFluffy,
    addToMcFluffyMulti,
    addToMcFluffyServer,
    setToMcFluffy,
    setReservationToMcFluffy,
    setOrderRowToMcFluffy,
    removeFromMcFluffy,
    replaceInMcFluffy,
    reserveMcFluffy,
    getUserLocale,
    getWebLoginCreateUserUrl,
    getCurrentUserPrefs,
    setCurrentUserPrefs,
    getObjectTemplate,
    findUsers,
    getUsers,
    exportUsers,
    getOrganizations,
    changePassword,
    updatePassword,
    getCurrentUserObjects,
    deleteObjectRelation,
    deleteFromLatest,
    findHPeriods,
    getHPeriodDefs,
    setPreferences,
    setDefaultPreferences,
    getPreferences,
    getPreferencesMulti,
    getOtherUserPrefs,
    getStartupData,
    getSupportInfo,
    supportsEmail,
    getEmailReservationPrefs,
    sendEmailReservation,
    findOrderRowsList,
    getOrderRows,
    getAuthenticatedUserInfo,
    getSystemBackgroundText,
    getSpecialTypes,
    getSpecialFields,
    getFieldDefsForType,
    getPrimaryFieldDefsForType,
    getFieldDefsForReservations,
    findReservationFields,
    getFieldsForReservationLists,
    findFieldsForTypes,
    getPeriods,
    getModifiableObjectFields,
    moveReservationsInTime,
    copyReservationsInTime,
    replaceObjectsOnReservations,
    dayNumberFromString,
    reservationClusterChangeTime,
    getValidReservationStatus,
    getReservationStatus,
    setReservationStatus,
    createReservationLayer,
    deleteReservationLayer,
    addToReservationLayer,
    deleteFromReservationLayer,
    persistReservationLayer,
    checkPersistReservationLayer,
    getReservationLayers,
    getReservationLayerDefs,
    hasFeatures,
    hasExternalPermissions,
    getReservationCollisions,
    getReservationsOnLayer,
    renameReservationLayer,
    groupReservations,
    ungroupReservations,
    addReservationsToGroups,
    removeReservationsFromGroups,
    getGroupedEntries,
    findSumSettings,
    getSumSetting,
    setSumSetting,
    deleteSumSetting,
    getColorDefs,
    getColorTypes,
    getTypesOnReservations,
    getObjectsOnReservations,
    getReservableTypesForType,
    getReservationsWithObjects,
    getReservationsWithTime,
    findPeriods,
    okToSetReservationOrganizations,
    setReservationOrganizations,
    getTimezones,
    getTimezonesList,
    getCurrentDateTime,
    okToModifyObjects,
    okToModifyOrders,
    prefsLoginUser,
    prefsGetUser,
    prefsGetForms,
    prefsGetFormSubmissions,
    prefsGetFormSubmission,
    prefsGetElements,
    getMemberTypesForReservations,
    getMemberObjectsForReservations,
    getMemberExceptionObjectsForReservations,
    saveExceptionObjectsForReservations,
    canHaveMemberExceptionObjectsForReservations,
    supportsReservationExceptions,
    findObjectFieldsForReservationList,
    getObjectCategories,
    moveReservationsFromWaitingList,
    moveReservationsToWaitingList,
    showEnableCustomWeekNames,
    getCustomWeekNames,
    targetExternalAppsOnStaging,
    targetExternalAppsEnvironment,
    getUserSearchFields,
    setUserSearchFields,
    addTagToReservations,
    removeTagFromReservations,
    createTag,
    deleteTag,
    findTags,
    findMyTags,
    okToDeleteReservationsCancelled,
    deleteReservationsCancelled,
    getReservationConflicts,
    getExamSettings,
    getServerEnv,
    loginWithSSOToken,
    logoutUser,
    addReservationsToReservationGroup,
    removeReservationsFromReservationGroup,
    createReservationGroup,
    createReservationGroupWithReservations,
    updateReservationGroup,
    deleteReservationGroup,
    getReservationGroup,
    getPaddingRules,
    clearAmCaches,
    lockReservations,
    unlockReservations,
    setReservationCapacity,
    MASS_BATCH_SIZE,
    STATUS_BATCH_SIZE,

    sendMessage() {
        dispatcher.send.apply(dispatcher, arguments);
    },

    setMessageDispatcher(newDispatcher, newLogger) {
        dispatcher = newDispatcher;
        logger = newLogger;
    },

    getMessageDispatcher() {
        return dispatcher;
    },
};
