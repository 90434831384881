const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
const ReactDOM = require("react-dom");
const API = require("../lib/TimeEditAPI");
const TemplateGroupList = require("./TemplateGroupList");
const Log = require("../lib/Log");
const Lang = require("../lib/Language");
const _ = require("underscore");
const Intro = require("intro.js");
const ContextMenu = require("../lib/ContextMenu");
const Mousetrap = require("@timeedit/mousetrap");
const McFluffy = require("../models/McFluffy");
const Macros = require("../models/Macros");
const TemplateKind = require("../models/TemplateKind");
const SelectionListFieldEditor = require("./SelectionListFieldEditor");
const ReservationConstants = require("../lib/ReservationConstants");
const Viewer = require("../lib/Viewer");
import { isValidGroupEntry } from "../lib/GroupUtils";

const AUTO_OBJECT_TYPE_ERROR = "AUTO_OBJECT_TYPE_MISMATCH";

const Indicator = ({ title, className, isActive, isBadged, label, onClick = _.noop }) => (
    <button className={`${className} ${isActive ? "active" : ""}`} title={title} onClick={onClick}>
        {label}
        {isBadged ? <div className="badge" /> : null}
    </button>
);

const typeIs = (kind, validKinds) => _.any(validKinds, (kd) => kd === kind);

const isNotAbstract = (kind) =>
    kind !== McFluffy.RESERVATION_KIND.ABSTRACT && kind !== McFluffy.RESERVATION_KIND.VIRTUAL;

const SelectionList = createReactClass({
    displayName: "SelectionList",

    contextTypes: {
        update: PropTypes.func,
        fireEvent: PropTypes.func,
        registerMacro: PropTypes.func,
        deregisterMacro: PropTypes.func,
        useNewReservationGroups: PropTypes.bool,
        presentModal: PropTypes.func,
        envIsNotProduction: PropTypes.func,
    },

    getInitialState() {
        return {
            types: [],
            createTypes: {},
            isDragSource: false,
            isDropTarget: false,
            activeDropzone: null,
            templateGroups: [],
            hasError: false,
            displayFieldList: false,
            editableObjects: {},
        };
    },

    componentDidMount() {
        this._isMounted = true;

        if (this.props.data.fluffy) {
            this.updateTypes(this.props.data.fluffy, this.state.types);
            const isObject = (item) => item.object && item.object.id !== 0;
            const objects = this.props.data.fluffy.objectItems.filter(isObject);
            const currentObjectIds = objects.map((item) => item.object.id);
            this.loadEditableObjects(currentObjectIds);
        }
        this.loadTemplateGroups(this.props, true);
        this.registerMacros();

        API.findTypes((result) => {
            if (this._isMounted) {
                this.setState({ types: result });
            }
        });

        this.registerMenu();
        if (this.props.data.isEditMode()) {
            this.registerKeyboardShortcuts();
        }
    },

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.templateKind &&
            !TemplateKind.equals(this.props.templateKind, prevProps.templateKind)
        ) {
            this.loadTemplateGroups(this.props, false);
        }

        if (this._isMounted && this.props.data.fluffy !== prevProps.data.fluffy) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: false });
        }

        if (this.props.data.fluffy !== prevProps.data.fluffy || prevState.types.length === 0) {
            this.updateTypes(this.props.data.fluffy, this.state.types);
        }

        if (
            this.props.selectedFluffyItem &&
            !_.isEqual(this.props.selectedFluffyItem, prevProps.selectedFluffyItem)
        ) {
            this.getOrLoadCreateTypes(this.props.selectedFluffyItem.type.id);
        }

        const isObject = (item) => item.object && item.object.id !== 0;
        const objects = this.props.data.fluffy.objectItems.filter(isObject);
        const prevObjects = prevProps.data.fluffy.objectItems.filter(isObject);
        if (objects.length > 0 && prevObjects.length === 0) {
            this.showSelectionIntro();
        }
        const currentObjectIds = objects.map((item) => item.object.id);
        const prevObjectIds = prevObjects.map((item) => item.object.id);
        if (!_.isEqual(currentObjectIds, prevObjectIds)) {
            this.loadEditableObjects(currentObjectIds);
        }
        this.registerMenu();

        if (this.props.data.mode !== prevProps.data.mode) {
            if (this.props.data.isEditMode()) {
                this.registerKeyboardShortcuts();
            } else {
                this.deregisterKeyboardShortcuts();
            }
        }
        if (this.props.data.isGroupMode === false && prevProps.data.isGroupMode === true) {
            if (prevProps.data.isEditMode()) {
                this.handleDisableEditMode();
            }
        }
    },

    componentWillUnmount() {
        this.deregisterMenu();
        this.deregisterKeyboardShortcuts();
        this.deregisterMacros();
        this._isMounted = false;
    },

    registerMacros() {
        this.context.registerMacro("selectionList", {
            events: [Macros.Event.PRIMARY_FIELD_CHANGED],
            actions: [
                {
                    key: Macros.Action.REFRESH,
                    action: (type) => {
                        this.updateObjectLabels(type);
                    },
                },
            ],
        });
    },

    deregisterMacros() {
        this.context.deregisterMacro("selectionList");
    },

    updateTypes(fluffy, existingTypes) {
        const newTypes = fluffy.objectItems
            .map((item) => item.type.id)
            .filter((id) => _.every(existingTypes, (type) => type.id !== id));
        if (newTypes.length === 0) {
            return;
        }

        API.getTypes(newTypes, true, (result) => {
            if (this._isMounted) {
                this.setState({ types: existingTypes.concat(result) });
            }
        });
    },

    updateObjectLabels(type) {
        const objects = this.props.data.fluffy.getObjects().filter((item) => item.typeId === type);
        if (objects.length === 0) {
            return;
        }

        API.getObjectNames(
            objects.map((item) => ({ id: item.id, type })),
            false,
            (updatedObjects) => {
                const updatedLabels = this.props.data.fluffy.labels.map((label) => {
                    const updatedObject = _.find(
                        updatedObjects,
                        (object) => object.id === label.id
                    );
                    if (!updatedObject) {
                        return label;
                    }

                    return updatedObject;
                });

                const updatedFluffy = McFluffy.create(
                    this.props.data.fluffy.toJson(),
                    updatedLabels
                );
                this.context.update(this.props.data, this.props.data.setFluffy(updatedFluffy));
            }
        );
    },

    loadCreateTypes(typeId) {
        this._loadingCreateTypes = true;
        API.getCreateTypes([typeId], (createTypes) => {
            if (!this._isMounted) {
                this._loadingCreateTypes = false;
                return;
            }
            const newCreateTypes = _.clone(this.state.createTypes);
            newCreateTypes[typeId] = createTypes;
            this.setState({ createTypes: newCreateTypes }, () => {
                this._loadingCreateTypes = false;
            });
        });
    },

    loadEditableObjects(objectIds) {
        if (!objectIds || objectIds.length === 0) {
            return;
        }
        API.okToModifyObjects(objectIds, (result) => {
            const editableObjects = {};
            result.forEach((canModify, index) => {
                editableObjects[objectIds[index]] = canModify;
            });
            this.setState({ editableObjects });
        });
    },

    canEditObject(objectId) {
        return this.state.editableObjects[objectId] === true;
    },

    getOrLoadCreateTypes(typeId) {
        let createTypes = this.state.createTypes[typeId];
        if (createTypes === undefined && !this._loadingCreateTypes) {
            this.loadCreateTypes(typeId);
            createTypes = [];
        }
        return createTypes || [];
    },

    showSelectionIntro() {
        API.getPreferences("viewedHelp", (helpData) => {
            const viewedHelp = JSON.parse(helpData) || {};
            if (viewedHelp.selectionList) {
                return;
            }
            const intro = Intro.introJs();
            const done = function () {
                API.getPreferences("viewedHelp", (aboutData) => {
                    const aboutSettings = JSON.parse(aboutData) || {};
                    aboutSettings.selectionList = true;
                    API.setPreferences("viewedHelp", [JSON.stringify(aboutSettings)], _.noop);
                });
            };
            intro.onexit(done);
            intro.oncomplete(done);
            intro.setOptions({
                nextLabel: `${Lang.get("nc_intro_next")}&rarr;`,
                prevLabel: `&larr;${Lang.get("nc_intro_prev")}`,
                skipLabel: Lang.get("nc_intro_skip"),
                doneLabel: Lang.get("nc_intro_done"),
                steps: [
                    {
                        intro: Lang.get("nc_tutorial_selection_object_selected"),
                        element: ReactDOM.findDOMNode(this),
                        position: "top",
                    },
                    {
                        intro: Lang.get("nc_tutorial_selection_add_more_of_same_type"),
                        element: this.refs.firstType,
                        position: "top",
                    },
                    {
                        intro: Lang.get("nc_tutorial_selection_replace_or_remove_object"),
                        element: this.refs.firstObject,
                        position: "top",
                    },
                ],
            });
            intro.start();
        });
    },

    registerKeyboardShortcuts() {
        this._previousSaveBinding = Mousetrap.unbindWithHelp("mod+s", true);
        this._previousCancelBinding = Mousetrap.unbindWithHelp("esc", true);

        Mousetrap.bindWithHelp(
            "mod+s",
            (event) => {
                this.handleFinishEditMode(event, true);
                return false;
            },
            undefined,
            Lang.get("dialog_save")
        );
        Mousetrap.bindWithHelp("esc", this.handleDisableEditMode);
    },

    deregisterKeyboardShortcuts() {
        if (!this._previousSaveBinding) {
            return;
        }
        Mousetrap.bindWithHelp(
            "mod+s",
            this._previousSaveBinding[0],
            undefined,
            Lang.get("dialog_save")
        );
        Mousetrap.bindWithHelp("esc", this._previousCancelBinding[0]);
    },

    registerMenu() {
        this.deregisterMenu();

        if (this.state.isDropTarget) {
            return;
        }
        const listenerIds = [];
        const types = _.uniq(
            this.props.data.fluffy.objectItems.map((item) => parseInt(item.type.id, 10))
        );
        types.forEach(function (type, index) {
            const items = this.props.data.fluffy.objectItems.filter(
                (item) => item.type.id === type && item.object.id !== 0
            );
            let menuItems;
            let typeRef = this.refs[`firstRow${index}`];
            if (!typeRef) {
                return;
            }

            if (items.length > 0) {
                typeRef = typeRef.querySelector("th");
            }
            menuItems = this.getMenuItemsForType(type, items);
            if (process.env.NODE_ENV === "development" || this.props.flags?.examFlowV3) {
                menuItems.push({
                    label: Lang.tmp("Set size"),
                    action: () => {
                        this.props.setSize(prompt("Size", ""));
                    },
                });
            }
            listenerIds.push(ContextMenu.addListener(typeRef, menuItems, ContextMenu.RIGHT_CLICK));

            items.forEach(function (item, itemIndex) {
                menuItems = this.getMenuItemsForObject(item);
                let ref = this.refs[`${index}.${itemIndex}`];
                if (itemIndex === 0) {
                    ref = this.refs[`firstRow${index}`];
                }
                ref = ref.querySelector("td");
                listenerIds.push(ContextMenu.addListener(ref, menuItems, ContextMenu.RIGHT_CLICK));
            }, this);
        }, this);

        if (listenerIds.length === 0) {
            return;
        }
        this._listenerIds = listenerIds;
    },

    deregisterMenu() {
        if (this._listenerIds) {
            this._listenerIds.forEach((listenerId) => {
                ContextMenu.removeListener(listenerId);
            });
        }
    },

    getMenuItemsForType(typeId, objects) {
        const self = this;
        const items = [];

        const createTypes = this.getOrLoadCreateTypes(typeId);
        if (createTypes.length > 0 && this.props.onNewObject) {
            createTypes.forEach((createType) => {
                const label = Lang.get("cal_selected_new_of_type", createType.name);
                items.push({
                    label,
                    action() {
                        self.props.onNewObject(createType.id, label);
                        // eslint-disable-next-line no-undef
                        //mixpanel.track("Create new object", { Type: label });
                    },
                });
            });

            items.push({
                isSeparator: true,
            });
        }

        items.push({
            label: this.props.data.isObstacleTextType(typeId)
                ? Lang.get("nc_selection_list_hide_on_obstacles")
                : Lang.get("nc_selection_list_show_on_obstacles"),
            action: () => self.toggleObstacleTextType(typeId),
        });

        if (objects.length > 0) {
            const object = objects[0];
            const showDoubleOption =
                typeIs(object.typePhysical, [
                    McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE,
                    McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE,
                ]) && isNotAbstract(object.physical);

            if (
                showDoubleOption &&
                _.any(
                    objects,
                    (obj) =>
                        obj.physical === McFluffy.RESERVATION_KIND.PHYSICAL ||
                        obj.physical === McFluffy.RESERVATION_KIND.OPTIONAL
                )
            ) {
                items.push({
                    label: Lang.get("nc_selection_list_type_allow_double_reservation_all"),
                    action() {
                        self.allowDoubleBookingForType(typeId, self.props.data.fluffy);
                    },
                });
            }
        }

        return items;
    },

    getMenuItemsForObject(object) {
        const self = this;
        const items = [];

        if (
            ((object.physical === McFluffy.RESERVATION_KIND.ABSTRACT ||
                object.physical === McFluffy.RESERVATION_KIND.OPTIONAL ||
                object.physical === McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE) &&
                (object.typePhysical === McFluffy.RESERVATION_KIND.PHYSICAL ||
                    object.typePhysical === McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE)) ||
            object.ignoreAbstractException
        ) {
            const label = object.ignoreAbstractException
                ? Lang.get("cal_selected_remove_tmp_physical")
                : Lang.get("cal_selected_make_tmp_physical");
            items.push({
                label,
                action() {
                    self.toggleAbstractException(object);
                },
            });
            items.push({ isSeparator: true });
        }

        if (object.object.id !== 0 && this.props.onObjectInfo) {
            const label = Lang.get("nc_selection_list_show_object_information");
            items.push({
                label,
                action() {
                    self.props.onObjectInfo(object.object.id, false, true, label);
                },
            });
        }

        const showDoubleOption =
            typeIs(object.typePhysical, [
                McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE,
                McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE,
            ]) && isNotAbstract(object.physical);

        if (showDoubleOption) {
            items.push({
                label:
                    object.physical === McFluffy.RESERVATION_KIND.PHYSICAL ||
                    object.physical === McFluffy.RESERVATION_KIND.OPTIONAL
                        ? Lang.get("cal_selected_allow_double_res")
                        : Lang.get("cal_selected_allow_not_double_res"),
                action() {
                    self.toggleDoubleBooking(object);
                },
            });
        }

        let createSeparatorAdded = false;
        if (
            object.object.id !== 0 &&
            this.props.onObjectInfo &&
            this.canEditObject(object.object.id)
        ) {
            if (items.length > 0) {
                items.push({ isSeparator: true });
                createSeparatorAdded = true;
            }
            const label = Lang.get("cal_selected_get_object_info");
            items.push({
                label,
                action() {
                    self.props.onObjectInfo(
                        object.object.id,
                        false,
                        false,
                        `${label} ${object.objectText}`
                    );
                },
            });
        }

        const createTypes = this.getOrLoadCreateTypes(object.type.id);
        if (createTypes.length > 0 && this.props.onNewObject) {
            if (items.length > 0 && !createSeparatorAdded) {
                items.push({ isSeparator: true });
            }

            items.push({
                label: Lang.get("cal_selected_copy_object"),
                action() {
                    self.props.onObjectInfo(
                        object.object.id,
                        true,
                        false,
                        Lang.get("cal_selected_copy_object")
                    );
                },
            });
        }

        if (object.object.id !== 0 && this.props.onObjectInfo && Viewer.isActive(self.props.user)) {
            items.push({ isSeparator: true });
            const allObjectsOfType = this.props.data.fluffy.objectItems.filter(
                (item) => item.type.id === object.type.id && item.object.id !== 0
            );
            const objects = allObjectsOfType
                // eslint-disable-next-line no-shadow
                .map((object) => `&o=${object.object.id}.${object.type.id}`)
                .join("");
            items.push({
                label: Lang.get("nc_open_in_te_viewer"),
                action: () => Viewer.open(objects, self.props.user),
            });
        }

        return items;
    },

    toggleObstacleTextType(typeId) {
        // eslint-disable-next-line no-undef
        /*mixpanel.track(
            "Show/Hide object name on obstacle",
            {}
        );*/ /*Detects when, in the selection list, is selected from the type contextual menu to show objects on obstacles*/
        if (this.props.data.isObstacleTextType(typeId)) {
            return this.context.update(
                this.props.data,
                this.props.data.removeObstacleTextType(typeId)
            );
        }

        return this.context.update(this.props.data, this.props.data.addObstacleTextType(typeId));
    },

    toggleDoubleBooking(object) {
        this.props.data.fluffy.setObjectDoubleBooking(object, !object.double, (fluffy) => {
            this.context.update(this.props.data, this.props.data.setFluffy(fluffy, false));
        });
    },

    allowDoubleBookingForType(typeId, fluffy) {
        fluffy.setObjectDoubleBookingForType(typeId, true, (newFluffy) => {
            this.context.update(this.props.data, this.props.data.setFluffy(newFluffy, false));
        });
    },

    toggleAbstractException(object) {
        this.props.data.fluffy.setObjectAbstractException(
            object,
            !object.ignoreAbstractException,
            (fluffy) => {
                this.context.update(this.props.data, this.props.data.setFluffy(fluffy, false));
            }
        );
    },

    onObjectClick(object, event) {
        if (event && _.isModKey(event)) {
            return this.props.onRemove(object);
        }
        return this.props.onChange(object);
    },

    onLock(object) {
        return this.props.onLock(object);
    },

    onUnlock(object) {
        return this.props.onUnlock(object);
    },

    isLocked(object) {
        return this.props.lockedObjects.some((obj) => obj.object.id === object.object.id);
    },

    onDragStart(index, event) {
        const data = _.isObject(index) ? index : this.getObjectAtIndex(index);
        if (!data || !data.object) {
            return;
        }
        const type =
            data.object.id === 0 ? "application/x-timeedit-type" : "application/x-timeedit-object";
        _.setDragData(event, type, JSON.stringify(data));
        this.setState({ isDragSource: true });
    },

    onDrop(replace, evt) {
        if (!_.isEventDragDataOfType(evt, "application/x-timeedit-object")) {
            return;
        }

        let data = _.getDragData(evt, "application/x-timeedit-object");
        data = JSON.parse(data);
        evt.stopPropagation();

        this.setState({ isDropTarget: false });
        this.props.onObjectAdd(data, replace);
    },

    handleDragOver(evt) {
        if (
            !this.state.isDragSource &&
            _.isEventDragDataOfType(evt, "application/x-timeedit-object")
        ) {
            this.setState({ isDropTarget: true });
            evt.preventDefault();
        }
    },

    handleDragLeave(evt) {
        // We perform these calculations because onDragLeave fires without actually leaving the area, causing flickering.
        const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
        if (
            evt.clientX >= rect.left &&
            evt.clientX <= rect.right &&
            evt.clientY >= rect.top &&
            evt.clientY <= rect.bottom
        ) {
            return; // We are still inside the dropzone
        }
        this.setState({ isDropTarget: false });
    },

    handleDragEnd() {
        this.setState({
            isDragSource: false,
            isDropTarget: false,
        });
    },

    setActiveDropzone(id) {
        if (this.state.activeDropzone === id) {
            return;
        }
        this.setState({ activeDropzone: id });
    },

    // When invoked, we already know that there's no object
    getAutoLabel(typeItem, isLineSelected) {
        if (typeItem.auto && isLineSelected) {
            return <span className="optional">{Lang.get("nc_auto_object_will_be_used")}</span>;
        }
        return null;
    },

    getName(obj) {
        if (this.state.types.length === 0) {
            return "";
        }
        if (!obj) {
            return "";
        }
        if (!obj.type) {
            return obj.objectText;
        }

        const type = _.find(this.state.types, (tp) => tp.id === obj.type.id);

        if (type && !obj.selected) {
            return type.name;
        }
        return obj.objectText;
    },

    UNDEFINED: 0,
    PHYSICAL: 1,
    PHYSICAL_DOUBLE: 2,
    OPTIONAL: 3,
    OPTIONAL_DOUBLE: 4,
    ABSTRACT: 5,
    ABSTRACT_VIRTUAL: 6,

    getObjectPrefix(obj) {
        const result = [];
        if (obj.physical === McFluffy.RESERVATION_KIND.OPTIONAL) {
            result.push("°");
        }
        if (
            obj.physical === McFluffy.RESERVATION_KIND.ABSTRACT ||
            obj.physical === McFluffy.RESERVATION_KIND.ABSTRACT_VIRTUAL
        ) {
            result.push("*");
        }
        if (
            obj.physical === McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE ||
            obj.physical === McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE
        ) {
            result.push("+");
        }
        return result.join("");
    },

    getObjectSuffix(obj, classes) {
        const allClasses = `${classes} extra`;
        if (!this.props.user.showExtraInfo || !obj) {
            return <td className={allClasses} style={{ overflow: "visible" }} />;
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.ABSTRACT) {
            return (
                <td
                    className={allClasses}
                    style={{ overflow: "visible" }}
                    title={Lang.get("cal_reservation_status_abstract")}
                >
                    {Lang.get("cal_reservation_status_abstract_short")}
                </td>
            );
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.PHYSICAL) {
            return (
                <td
                    className={allClasses}
                    style={{ overflow: "visible" }}
                    title={Lang.get("cal_reservation_status_physical")}
                >
                    {Lang.get("cal_reservation_status_physical_short")}
                </td>
            );
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.PHYSICAL_DOUBLE) {
            const longText = `${Lang.get("cal_reservation_status_physical")}*`;
            return (
                <td className={allClasses} style={{ overflow: "visible" }} title={longText}>
                    {Lang.get("cal_reservation_status_physical_short")}*
                </td>
            );
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.ABSTRACT_VIRTUAL) {
            return (
                <td
                    className={allClasses}
                    style={{ overflow: "visible" }}
                    title={Lang.get("cal_reservation_status_virtual")}
                >
                    {Lang.get("cal_reservation_status_virtual_short")}
                </td>
            );
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.OPTIONAL) {
            return (
                <td
                    className={allClasses}
                    style={{ overflow: "visible" }}
                    title={Lang.get("cal_reservation_status_optional_short")}
                >
                    {Lang.get("cal_reservation_status_optional_short")}
                </td>
            );
        }
        if (obj.physical === McFluffy.RESERVATION_KIND.OPTIONAL_DOUBLE) {
            const longText = `${Lang.get("cal_reservation_status_optional_short")}*`;
            return (
                <td className={allClasses} style={{ overflow: "visible" }} title={longText}>
                    {Lang.get("cal_reservation_status_optional_short")}*
                </td>
            );
        }
        return null;
    },

    getObjectAtIndex(index) {
        return this.props.data.fluffy.objectItems[index];
    },

    getCellLabels() {
        return this.props.data.fluffy.objectItems.map(function (obj) {
            return this.getColumns().map(function (column) {
                let label;
                if (column.name === Lang.get("cal_selected_title")) {
                    label = this.getName(obj);
                    if (obj.min > 0) {
                        label = `${label} x ${obj.min}`;
                    }
                    return label;
                }
                if (column.name === "") {
                    const physicsTypes = [
                        "",
                        Lang.get("cal_reservation_status_abstract_short"),
                        Lang.get("cal_reservation_status_physical_short"),
                    ];
                    return physicsTypes[obj.physical];
                }
                if (!obj || (obj.object && obj.object.id === 0)) {
                    return "";
                }

                const type = _.find(this.state.types, (tp) => tp.id === obj.tp.id);
                if (type) {
                    return type.name;
                }
                return "";
            }, this);
        }, this);
    },

    getTypeName(id) {
        const type = _.find(this.state.types, (tp) => tp.id === id);
        if (type && this.props.user.showExtraInfo && process.env.NODE_ENV === "development") {
            return `${type.name} (${type.id})`;
        }
        return type ? type.name : "";
    },

    getClassName(index) {
        const obj = this.getObjectAtIndex(index);
        if (obj.optional === true) {
            return "optional";
        }
        return null;
    },

    handleDisableEditMode() {
        this.setState({ hasError: false });
        let previousFluffy = this.props.data.editedFluffy;
        const newSelection = this.props.data.disableEditMode();
        this.props.setSelectionGroup([]);
        if (previousFluffy) {
            previousFluffy = previousFluffy
                .immutableSet({
                    beginTime: undefined,
                    endTime: undefined,
                })
                .clearFields();
            const firstItem = _.find(previousFluffy.objectItems, (item) => item.object.id !== 0);
            if (firstItem) {
                previousFluffy.replaceObject(
                    firstItem,
                    {
                        ...firstItem.object,
                        typeId: firstItem.type.id,
                        name: firstItem.objectText,
                    },
                    (finalFluffy) => {
                        const finalSelection = newSelection.setFluffy(finalFluffy, false);
                        this.context.update(this.props.data, finalSelection);
                    },
                    false,
                    false
                );
            } else {
                const finalSelection = newSelection.setFluffy(previousFluffy, false);
                this.context.update(this.props.data, finalSelection);
            }
        } else {
            this.context.update(this.props.data, newSelection);
        }
    },

    handleDisableWaitingListMode() {
        this.context.update(this.props.data, this.props.data.disableWaitingListMode());
    },

    handleFinishEditMode(event, fromKeyboardShortcut = false) {
        if (fromKeyboardShortcut) {
            this.finishEditMode(this.props.data, false, false);
        } else {
            this.finishEditMode(this.props.data, _.isModKey(event), false);
        }
    },

    // TODO Set fluffy size from menu, invoking this.props.setSize

    handleAddToGroup(event) {
        this.props.data.finishEditMode(
            false,
            _.isModKey(event), // Allow availability overlap
            (reservationIds) => {
                this.setState({ hasError: false });

                const groupIds = this.props.data.getGroupIds();
                const entry = _.clone(this.props.data.editedEntry);
                entry.reservationids = reservationIds;
                const isOnlyZeroGroup = (ids) => ids.length === 1 && ids[0] === 0;
                if (
                    (groupIds.length === 0 || isOnlyZeroGroup(groupIds)) &&
                    this.props.currentSelectionGroup.length > 0
                ) {
                    const ids = _.flatten(
                        _.map(
                            this.props.currentSelectionGroup.concat(entry),
                            (etr) => etr.reservationids
                        )
                    );
                    API.groupReservations(ids, (result) => {
                        const newSelection = this.props.data
                            .setGroups(result[0])
                            .immutableSet({ reservations: ids });
                        this.context.update(this.props.data, newSelection);
                    });
                } else if (groupIds.length > 0 && !isOnlyZeroGroup(groupIds)) {
                    if (this.context.useNewReservationGroups) {
                        API.addReservationsToReservationGroup(
                            groupIds[0],
                            reservationIds,
                            (result) => {
                                if (result === false) {
                                    Log.warning(Lang.get("nc_could_not_add_to_reservation_group"));
                                    return;
                                }

                                const newSelection = this.props.data.immutableSet({
                                    reservations:
                                        this.props.data.reservations.concat(reservationIds),
                                });
                                this.context.update(this.props.data, newSelection);
                            }
                        );
                    } else {
                        API.addReservationsToGroups(
                            reservationIds,
                            groupIds,
                            undefined,
                            (result) => {
                                if (result === false) {
                                    Log.warning(Lang.get("nc_could_not_add_to_reservation_group"));
                                    return;
                                }

                                const newSelection = this.props.data.immutableSet({
                                    reservations:
                                        this.props.data.reservations.concat(reservationIds),
                                });
                                this.context.update(this.props.data, newSelection);
                            }
                        );
                    }
                } else {
                    this.context.update(
                        this.props.data,
                        this.props.data.immutableSet({ reservations: reservationIds })
                    );
                    this.props.setSelectionGroup([entry]);
                }

                this.context.fireEvent(
                    "selectionList",
                    Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                    reservationIds
                );
            },
            () => {
                this.setState({ hasError: true });
            }
        );
    },

    // TODO Replace entry times with times from … fluffy?
    getFirstFreeObject(typeId, callback) {
        let searcher = this.props.getObjectSearch();
        const finish = (search) => {
            const finalSearch = search.immutableSet({
                //beginTime: entry.startTimes[0].getMts(),
                //endTime: entry.endTimes[0].getMts(),
                reserveMode: true,
            });
            finalSearch.search(0, (objects) => callback(objects[0]));
        };
        if (typeId !== searcher.type) {
            console.log(
                "Type mismatch in getFirstFreeObject in SelectionList",
                typeId,
                searcher.type
            );
            callback(AUTO_OBJECT_TYPE_ERROR);
        } else {
            finish(searcher);
        }
    },

    confirmAuto(autoObject) {
        API.getPreferences(`dismissedModalDialogs.confirm_auto_object`, (value) => {
            if (value || value === null) {
                const yesButton = {
                    title: Lang.get("dialog_ok"),
                    cb: _.noop,
                    remember: true,
                    value: false,
                };
                const buttons = [yesButton];
                this.context.presentModal(
                    <div>{<p>{Lang.get("nc_auto_object_added", autoObject.name)}</p>}</div>,
                    "confirm_auto_object",
                    Lang.get("nc_auto_object_headline"),
                    buttons,
                    _.noop
                );
            } else {
                _.noop();
            }
        });
    },

    doFinishEditMode(
        selection,
        allowAvailabilityOverlap = false,
        closeRequest = false,
        autoObject = null
    ) {
        const isNewReservation = selection.reservations.length === 0;
        // If we had no reservation ID before finishing, set the isNewManualReservation flag in the reservation event to true
        selection.finishEditMode(
            closeRequest,
            allowAvailabilityOverlap,
            (savedReservationIds) => {
                this.props.setReservation(savedReservationIds, (updatedSelection) => {
                    this.setState({ hasError: false });
                    if (autoObject) {
                        this.confirmAuto(autoObject);
                    }
                    const newSelection = updatedSelection.disableEditMode().clearBeginEndTimes();
                    const firstItem = _.find(
                        newSelection.fluffy.objectItems,
                        (item) => item.object.id !== 0
                    );
                    if (firstItem) {
                        newSelection.fluffy.replaceObject(
                            firstItem,
                            {
                                ...firstItem.object,
                                typeId: firstItem.type.id,
                                name: firstItem.objectText,
                            },
                            (finalFluffy) => {
                                const finalSelection = newSelection.immutableSet({
                                    fluffy: finalFluffy,
                                });
                                this.context.update(this.props.data, finalSelection);
                                this.context.fireEvent(
                                    "selectionList",
                                    Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                                    savedReservationIds,
                                    isNewReservation
                                );
                            },
                            false,
                            false
                        );
                    } else {
                        this.context.update(this.props.data, newSelection);
                        this.context.fireEvent(
                            "selectionList",
                            Macros.Event.RESERVATION_MADE_OR_MODIFIED,
                            savedReservationIds,
                            isNewReservation
                        );
                    }
                });
            },
            (error) => {
                if (error.code === ReservationConstants.ERROR_AVAILABILITY_OVERLAP_POSSIBLE) {
                    // eslint-disable-next-line no-alert
                    if (window.confirm(Lang.get("nc_no_available_time_ignore_availability"))) {
                        this.finishEditMode(selection, true, closeRequest);
                    } else {
                        this.setState({ hasError: true });
                    }
                } else {
                    Log.warning(error.message, { code: error.code });
                    this.setState({ hasError: true });
                }
            }
        );
    },

    finishEditMode(selection, allowAvailabilityOverlap = false, closeRequest = false) {
        // Apply auto object, same way as in endEntryCreate in Calendar.jsx
        const fluffyItem = this.props.selectedFluffyItem;
        if (fluffyItem) {
            const fluffyItemType = fluffyItem.type.id;
            const typeObject = selection.fluffy.getFirstObjectOfType(fluffyItemType);
            if (!typeObject && fluffyItem.auto) {
                this.getFirstFreeObject(fluffyItemType, (autoObject) => {
                    if (autoObject === AUTO_OBJECT_TYPE_ERROR) {
                        // Do not log a user-visible error if Core got into a state where types didn't match.
                        this.doFinishEditMode(selection, allowAvailabilityOverlap, closeRequest);
                    } else if (autoObject) {
                        selection.fluffy.addObject(
                            autoObject,
                            (newFluffy) => {
                                const newSelection = selection.immutableSet({ fluffy: newFluffy });
                                this.doFinishEditMode(
                                    newSelection,
                                    allowAvailabilityOverlap,
                                    closeRequest,
                                    autoObject
                                );
                            },
                            false
                        );
                    } else {
                        Log.error(Lang.get("nc_err_res_no_auto_object_available"));
                        // What more? Progress as normal
                        this.doFinishEditMode(selection, allowAvailabilityOverlap, closeRequest);
                    }
                });
            } else {
                this.doFinishEditMode(selection, allowAvailabilityOverlap, closeRequest);
            }
        } else {
            this.doFinishEditMode(selection, allowAvailabilityOverlap, closeRequest);
        }
    },

    handleAcceptRequest(event) {
        this.finishEditMode(this.props.data, _.isModKey(event), true);
    },

    handleDenyRequest() {
        const reservationIds = this.props.data.editedEntry.reservationids;
        API.setReservationToMcFluffy(
            reservationIds,
            this.props.data.fluffy.toJson(),
            true,
            null,
            false,
            (result) => {
                const fluffy = McFluffy.create(result.parameters[0]);
                const selection = this.props.data.setFluffy(fluffy);
                this.finishEditMode(selection, false, true);
            }
        );
    },

    getColumns() {
        return [
            {
                name: Lang.get("cal_selected_title"),
                sortable: false,
            },
            {
                name: Lang.get("cal_selected_title_type"),
                sortable: false,
            },
            {
                name: "",
                sortable: false,
            },
        ];
    },

    removeObject(item, typeItem, event) {
        this.props.onRemove(item, typeItem);
        event.stopPropagation();
    },

    loadTemplateGroups(props, avoidNoGroup) {
        if (!props.templateKind) {
            if (this._isMounted) {
                this.setState({
                    templateGroups: [],
                });
            }
            return;
        }
        API.getTemplateGroups(props.templateKind.number, (result) => {
            if (!this._isMounted) {
                return;
            }
            const response = result.parameters[0];
            let activeGroup = _.find(response, (group) => group.id !== 0); // Pre-select a group, and don"t pre-select "All" if there are other options.
            activeGroup = activeGroup ? activeGroup.id : 0;
            const value = props.data.fluffy.templateGroupId;
            if (_.isNullish(value) || (avoidNoGroup && value === 0)) {
                this.props.onTemplateSelect(activeGroup);
            }
            this.props.onTemplatesLoaded(response);
            this.setState({
                templateGroups: response,
            });
        });
    },

    isRequestAcceptable() {
        const objects = this.props.data.fluffy.objectItems;
        return _.every(objects, (object) => !object.virtual);
    },

    clearFieldValues() {
        const newFluffy = this.props.data.fluffy.clearFields();
        const newSelection = this.props.data.setFluffy(newFluffy);
        this.context.update(this.props.data, newSelection);
    },

    clearTimeValues() {
        const newFluffy = this.props.data.fluffy.clearTimeValues();
        const newSelection = this.props.data.setFluffy(newFluffy);
        this.context.update(this.props.data, newSelection);
    },

    _renderListOrFields() {
        const buttons = this._renderButtons();
        const actions = this._renderActions(this.props.onSelectionClear);
        const HEIGHT_MOD = 50;
        const BUTTON_HEIGHT_MOD = 28;
        let height = this.props.height - HEIGHT_MOD;
        if (buttons && actions) {
            height = height - BUTTON_HEIGHT_MOD;
        }
        if (this.props.bottomButton) {
            height = height - BUTTON_HEIGHT_MOD;
        }
        return (
            <>
                {buttons}
                {actions}
                {this.state.displayFieldList &&
                this.props.fieldsInSelection &&
                !this.props.user.fieldsNextToSelection ? (
                    <SelectionListFieldEditor
                        disableClear={this.props.data.isEditMode()}
                        height={this.props.height}
                        fields={this.props.data.fluffy.fieldItems.map((fI) => fI.field)}
                        fluffy={this.props.data.fluffy}
                        clearFieldValues={this.clearFieldValues}
                    />
                ) : (
                    this._renderContent(height)
                )}
                {this.props.bottomButton}
            </>
        );
    },

    render() {
        const infoIndicators = this._renderIndicators();
        return (
            <div
                key="list"
                style={{ width: this.props.width }}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDragEnd={this.handleDragEnd}
            >
                <div style={{ display: "flex", flexDirection: "row", overflowY: "hidden" }}>
                    {infoIndicators}
                    <div className="fieldInformation" style={{ flexGrow: "1" }}>
                        {this._renderListOrFields()}
                    </div>
                </div>
            </div>
        );
    },

    displaySelectionList() {
        this.setState({ displayFieldList: false });
    },

    displayFieldList() {
        this.setState({ displayFieldList: true });
    },

    _renderIndicators() {
        if (this.props.user && !this.props.user.fieldsInSelection) {
            return null;
        }
        if (this.props.user && this.props.user.fieldsNextToSelection) {
            return null;
        }
        const result = [];
        if (this.props.data.fluffy.hasFields()) {
            result.push(
                <Indicator
                    key={"objectInfo"}
                    className={"objectInfo"}
                    isActive={!this.state.displayFieldList}
                    onClick={this.displaySelectionList}
                    title={Lang.get("cal_res_side_reservation_objects")}
                    label={""}
                />
            );
            result.push(
                <Indicator
                    key={"fieldInfo"}
                    className={"fieldInfo"}
                    isActive={this.state.displayFieldList}
                    isBadged={this.props.data.fluffy.hasFieldValues()}
                    title={Lang.get("cal_reservation_list_column_field")}
                    onClick={this.displayFieldList}
                    label={""}
                />
            );
        }
        if (result.length === 0) {
            return null;
        }
        return <div className="listTopLine vertical">{result}</div>;
    },

    _renderButtons() {
        if (this.props.data.isEditMode() && this.props.data.isEditRequest()) {
            let save = (
                <button
                    title={Lang.get("cal_res_below_confirm_reservation")}
                    onClick={this.handleFinishEditMode}
                    className="editGroupButton save"
                >
                    {Lang.get("cal_res_below_confirm_reservation")}
                </button>
            );
            if (this.isRequestAcceptable()) {
                save = (
                    <button
                        title={Lang.get("cal_res_below_accept_reservation")}
                        onClick={this.handleAcceptRequest}
                        className="editGroupButton save"
                    >
                        {Lang.get("cal_res_below_accept_reservation")}
                    </button>
                );
            }

            return (
                <div id="selectionListTop" className="listTopLine request">
                    <button
                        title={Lang.get("cal_res_below_reject_reservation")}
                        onClick={this.handleDenyRequest}
                        className="editGroupButton dangerous"
                    >
                        {Lang.get("cal_res_below_reject_reservation")}
                    </button>
                    <button
                        title={Lang.get("cal_res_below_cancel")}
                        onClick={this.handleDisableEditMode}
                        className="editGroupButton save"
                    >
                        {Lang.get("cal_res_below_cancel")}
                    </button>
                    {save}
                </div>
            );
        }
        const isAddToGroupMode =
            this.props.data.isGroupMode &&
            this.props.data.editedEntry &&
            this.props.data.editedEntry.reservationids.length === 0;

        if (this.props.data.isEditMode() && !this.props.data.isEditRequest()) {
            const confirmClasses = {
                editGroupButton: true,
                save: true,
                disabled: this.state.hasError,
            };

            if (!isAddToGroupMode) {
                return (
                    <div id="selectionListTop" className="listTopLine request">
                        <button
                            title={Lang.get("cal_res_below_cancel")}
                            onClick={this.handleDisableEditMode}
                            className="editGroupButton save"
                        >
                            {Lang.get("cal_res_below_cancel")}
                        </button>
                        <button
                            title={Lang.get("cal_res_below_confirm_reservation")}
                            onClick={this.handleFinishEditMode}
                            className={_.classSet(confirmClasses)}
                        >
                            {Lang.get("cal_res_below_confirm_reservation")}
                        </button>
                    </div>
                );
            }

            return null;
        }

        if (this.props.data.isWaitingListMode()) {
            return (
                <div id="selectionListTop" className="listTopLine request">
                    <button
                        title={Lang.get("cal_res_below_cancel")}
                        onClick={this.handleDisableWaitingListMode}
                        className="editGroupButton save"
                    >
                        {Lang.get("cal_res_below_cancel")}
                    </button>
                </div>
            );
        }
        return null;
    },

    _renderActions(onClear) {
        let helpButton = null;
        const hasHelp = _.some(
            this.state.templateGroups,
            (group) => group.id > 0 && group.description
        );
        if (hasHelp) {
            const classNames = {
                help: true,
                active: this.props.isHelpVisible,
            };
            helpButton = (
                <button
                    title={Lang.get("menu_help")}
                    className={_.classSet(classNames)}
                    onClick={this.props.onHelpToggle}
                />
            );
        }

        const isAddToGroupMode =
            this.props.data.isGroupMode &&
            this.props.data.editedEntry &&
            this.props.data.editedEntry.reservationids.length === 0;

        if (this.props.data.isEditMode() && !this.props.data.isEditRequest()) {
            return (
                <div id="selectionListTop" className="listTopLine">
                    <button
                        title={Lang.get("cal_res_below_clear")}
                        onClick={this.props.onSelectionClear}
                        className={"trashIcon"}
                    />
                    <TemplateGroupList
                        value={this.props.data.fluffy.templateGroupId || 0}
                        onObjectSelect={this.props.onTemplateSelect}
                        templateGroups={this.state.templateGroups}
                    />
                    {helpButton}
                    <button
                        className="back"
                        onClick={this.props.onPreviousClick}
                        disabled={!this.props.hasSnapshots(true)}
                    />
                    <button
                        className="forward"
                        onClick={this.props.onNextClick}
                        disabled={!this.props.hasSnapshots(false)}
                    />
                    {isValidGroupEntry(
                        this.context.useNewReservationGroups,
                        this.props.data.editedEntry,
                        this.props.data.fluffy,
                        this.props.data.editedFluffy
                    ) && isAddToGroupMode ? (
                        <button
                            title={Lang.get("nc_add_to_reservation_group")}
                            onClick={this.handleAddToGroup}
                            className="editGroupButton save"
                        >
                            {Lang.get("nc_add_button_title")}
                            <div className="addToGroupIcon" />
                        </button>
                    ) : null}
                </div>
            );
        }

        return (
            <div id="selectionListTop" className="listTopLine">
                <button
                    title={Lang.get("cal_res_below_clear")}
                    onClick={onClear}
                    className={"trashIcon"}
                />
                <TemplateGroupList
                    value={this.props.data.fluffy.templateGroupId || 0}
                    onObjectSelect={this.props.onTemplateSelect}
                    templateGroups={this.state.templateGroups}
                />
                {helpButton}
                <button
                    className="back"
                    onClick={this.props.onPreviousClick}
                    disabled={!this.props.hasSnapshots(true)}
                />
                <button
                    className="forward"
                    onClick={this.props.onNextClick}
                    disabled={!this.props.hasSnapshots(false)}
                />
                {isAddToGroupMode ? (
                    <button
                        title={Lang.get("nc_add_to_reservation_group")}
                        disabled={
                            !isValidGroupEntry(
                                this.context.useNewReservationGroups,
                                this.props.data.editedEntry,
                                this.props.data.fluffy,
                                this.props.data.editedFluffy
                            )
                        }
                        onClick={this.handleAddToGroup}
                        className="editGroupButton save"
                    >
                        {Lang.get("nc_add_button_title")}
                        <div className="addToGroupIcon" />
                    </button>
                ) : null}
            </div>
        );
    },

    _renderContent(height) {
        if (!this.state.isDropTarget) {
            return this._renderSelection(height);
        }

        if (
            !this.props.selectedFluffyItem ||
            !this.props.selectedFluffyItem.object ||
            this.props.selectedFluffyItem.object.id === 0
        ) {
            return (
                <div className="dropzone" style={{ height: `${height}px` }}>
                    <div
                        onDrop={this.onDrop.bind(this, true)}
                        onDragOver={this.setActiveDropzone.bind(this, 1)}
                        onDragLeave={this.setActiveDropzone.bind(this, null)}
                        className={this.state.activeDropzone === 1 ? "active" : ""}
                    >
                        {Lang.get("cal_res_below_add")}
                    </div>
                </div>
            );
        }

        return (
            <div className="dropzone" style={{ height: `${height}px` }}>
                <div
                    onDrop={this.onDrop.bind(this, true)}
                    onDragOver={this.setActiveDropzone.bind(this, 0)}
                    onDragLeave={this.setActiveDropzone.bind(this, null)}
                    className={this.state.activeDropzone === 0 ? "active" : ""}
                >
                    {`${Lang.get("cal_res_below_replace")}: ${
                        this.props.selectedFluffyItem.objectText
                    }`}
                </div>
                <div
                    onDrop={this.onDrop.bind(this, false)}
                    onDragOver={this.setActiveDropzone.bind(this, 1)}
                    onDragLeave={this.setActiveDropzone.bind(this, null)}
                    className={this.state.activeDropzone === 1 ? "active" : ""}
                >
                    {Lang.get("cal_res_below_add")}
                </div>
            </div>
        );
    },

    _renderSelection(height) {
        const types = _.uniq(
            this.props.data.fluffy.objectItems.map((item) => parseInt(item.type.id, 10))
        );
        const itemCount = this.props.data.fluffy.objectItems.filter(
            (item) => item.object && item.object.id !== 0
        ).length;
        const EMPTY_COLSPAN = 4;
        let headerCells = [
            <th key="titleType" colSpan={itemCount === 0 ? EMPTY_COLSPAN : 1}>
                {Lang.get("cal_selected_title_type")}
            </th>,
        ];
        if (itemCount > 0) {
            headerCells = headerCells.concat(
                <th key="title" colSpan="3">
                    {Lang.get("cal_selected_title")}
                </th>
            );
        }

        // The height somehow keeps the flexing div in check when the SelectionPane resizes.
        // Without it, the listTopLine will be squeezed up a bit if the selectionList contains enough lines to scroll.
        // Why does this help, and should it?
        return (
            <div
                className={"selectBox"}
                id="selectionList"
                style={{ overflowY: "scroll", height: `${height}px` }}
            >
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr className="columnTitles columnHeader">{headerCells}</tr>
                    </thead>
                    <tbody>
                        {types
                            .map((type, index) => this._renderType(type, index === 0, index))
                            .reduce((rows, typeRows) => rows.concat(typeRows), [])}
                    </tbody>
                </table>
            </div>
        );
    },

    _renderType(type, isFirst, index) {
        const STANDARD_HEIGHT = 20;
        const tableRowHeight = STANDARD_HEIGHT;
        let typeItem = _.find(this.props.data.fluffy.objectItems, (item) => item.type.id === type);
        const items = this.props.data.fluffy.objectItems.filter(
            (item) => item.type.id === type && item.selected
        );
        const isActiveType =
            this.props.selectedFluffyItem && this.props.selectedFluffyItem.type.id === type;
        const isObjectSelected =
            this.props.selectedFluffyItem &&
            this.props.selectedFluffyItem.object &&
            this.props.selectedFluffyItem.object.id !== 0;

        const obj = _.clone(typeItem.object);
        obj.id = 0;
        typeItem = _.clone(typeItem);
        typeItem.object = obj;
        typeItem.objectText = "";

        const style = {
            height: tableRowHeight,
            lineHeight: `${tableRowHeight}px`,
        };

        const getRowClasses = (iF) => ({
            selected: isActiveType,
            type: iF,
        });
        const getItemClasses = (item, isType = false) => ({
            object: !isType,
            type: isType,
            optional: isType && item.optional,
            selected:
                (isActiveType &&
                    isObjectSelected &&
                    !isType &&
                    this.props.selectedFluffyItem.object.id === item.object.id) ||
                (isActiveType && !isObjectSelected && isType),
            virtual: !isType && item.virtual,
        });

        const firstItemClasses = items[0] ? getItemClasses(items[0]) : {};
        let lockButton = null;
        if (items[0] && items[0].object) {
            if (this.isLocked(items[0])) {
                lockButton = (
                    <span className="lockButton" onClick={this.onUnlock.bind(this, items[0])}>
                        {" "}
                        &#xf023;
                    </span>
                );
            } else if (firstItemClasses.selected) {
                lockButton = (
                    <span className="lockButton" onClick={this.onLock.bind(this, items[0])}>
                        {" "}
                        &#xf09c;
                    </span>
                );
            }
        }
        const rows = [
            <tr
                ref={`firstRow${index}`}
                key={`firstRow${index}`}
                className={_.classSet(getRowClasses(true))}
            >
                <th
                    className={_.classSet(getItemClasses(typeItem, true))}
                    key={type}
                    ref={isFirst ? "firstType" : undefined}
                    style={style}
                    onClick={this.props.onChange.bind(null, typeItem, _.noop)}
                    draggable="true"
                    onDragStart={this.onDragStart.bind(this, typeItem)}
                >
                    {this.getTypeName(type)}
                    {typeItem.min > 0 ? ` x ${typeItem.min}` : ""}
                </th>
                <td
                    className={_.classSet(firstItemClasses)}
                    style={style}
                    ref={isFirst ? "firstObject" : undefined}
                    onClick={this.onObjectClick.bind(this, items[0] || typeItem)}
                    draggable="true"
                    onDragStart={this.onDragStart.bind(this, items[0])}
                >
                    {items[0]
                        ? this.getObjectPrefix(items[0]) + this.getName(items[0])
                        : this.getAutoLabel(typeItem, isActiveType)}
                </td>
                {this.getObjectSuffix(items[0], _.classSet(firstItemClasses))}
                <td className={items[0] ? "action" : ""} style={style}>
                    {items[0] && firstItemClasses.selected ? (
                        <span onClick={this.removeObject.bind(this, items[0], typeItem)}>
                            &#xf068;
                        </span>
                    ) : null}
                    {lockButton}
                </td>
            </tr>,
        ];

        if (items.length === 0) {
            return rows;
        }

        return rows.concat(
            _.range(1, items.length).map((i) => {
                const itemClasses = getItemClasses(items[i]);
                lockButton = null;
                if (items[i] && items[i].object) {
                    if (this.isLocked(items[i])) {
                        lockButton = (
                            <span
                                className="lockButton"
                                onClick={this.onUnlock.bind(this, items[i])}
                            >
                                {" "}
                                &#xf023;
                            </span>
                        );
                    } else if (itemClasses.selected) {
                        lockButton = (
                            <span className="lockButton" onClick={this.onLock.bind(this, items[i])}>
                                {" "}
                                &#xf09c;
                            </span>
                        );
                    }
                }
                return (
                    <tr
                        ref={`${index}.${i}`}
                        key={`${index}.${i}`}
                        className={_.classSet(getRowClasses(false))}
                    >
                        <th style={style}>&nbsp;</th>
                        <td
                            className={_.classSet(itemClasses)}
                            style={style}
                            onClick={this.onObjectClick.bind(this, items[i] || typeItem)}
                            draggable="true"
                            onDragStart={this.onDragStart.bind(this, items[i])}
                        >
                            {this.getObjectPrefix(items[i]) + this.getName(items[i])}
                        </td>
                        {this.getObjectSuffix(items[i], _.classSet(itemClasses))}
                        <td className="action" style={style}>
                            {itemClasses.selected ? (
                                <span onClick={this.removeObject.bind(this, items[i], typeItem)}>
                                    &#xf068;
                                </span>
                            ) : null}
                            {lockButton}
                        </td>
                    </tr>
                );
            })
        );
    },

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.data !== nextProps.data ||
            this.props.onSelectionClear !== nextProps.onSelectionClear ||
            this.props.onTemplateSelect !== nextProps.onTemplateSelect ||
            this.props.hasSnapshots !== nextProps.hasSnapshots ||
            this.props.width !== nextProps.width ||
            this.props.height !== nextProps.height ||
            this.props.selectedFluffyItem !== nextProps.selectedFluffyItem ||
            this.props.onNewObject !== nextProps.onNewObject ||
            this.props.onObjectInfo !== nextProps.onObjectInfo ||
            this.props.onRemove !== nextProps.onRemove ||
            this.props.onChange !== nextProps.onChange ||
            this.props.isHelpVisible !== nextProps.isHelpVisible ||
            !_.isEqual(this.props.lockedObjects, nextProps.lockedObjects) ||
            this.props.onObjectAdd !== nextProps.onObjectAdd ||
            !_.isEqual(this.state.createTypes, nextState.createTypes) ||
            this.state.isDropTarget !== nextState.isDropTarget ||
            this.state.isDragSource !== nextState.isDragSource ||
            !_.isEqual(this.state.types, nextState.types) ||
            this.state.activeDropzone !== nextState.activeDropzone ||
            this.state.hasError !== nextState.hasError ||
            this.state.displayFieldList !== nextState.displayFieldList ||
            !_.isEqual(this.state.templateGroups, nextState.templateGroups) ||
            !_.isEqual(this.props.user, nextProps.user) ||
            !_.isEqual(this.props.bottomButton, nextProps.bottomButton) ||
            !_.isEqual(this.state.editableObjects, nextState.editableObjects)
        );
    },
});

module.exports = SelectionList;
