const API = require("../lib/TimeEditAPI");
const Macros = require("../models/Macros");
const _ = require("underscore");

const PrimaryFieldManager = function (registerMacro, fireEvent) {
    this.fields = {};
    this.fireEvent = fireEvent;
    // This macro is deregistered by App.jsx when unmounting
    registerMacro(`primaryFieldManager`, {
        events: [Macros.Event.PRIMARY_FIELD_CHANGED],
        actions: [
            {
                key: Macros.Action.REFRESH,
                action: (typeId) => {
                    this.getPrimaryField(typeId);
                },
            },
        ],
    });
};

// Invoked by ObjectSelect when columns change
PrimaryFieldManager.prototype.setPrimaryField = function (
    typeId,
    fieldId,
    fromFindObjects = false
) {
    if (this.fields[typeId] === fieldId) {
        return;
    }
    this.fields[typeId] = fieldId;
    API.setPrimaryField(typeId, fieldId, () => {
        this.fireEvent(
            "primaryFieldManager",
            Macros.Event.PRIMARY_FIELD_CHANGED,
            typeId,
            fromFindObjects
        );
    });
};

PrimaryFieldManager.prototype.getPrimaryField = function (typeId) {
    API.getPrimaryFields(typeId, (result) => {
        const resultId = result[0] ? result[0].id : null;
        this.fields[typeId] = resultId;
    });
};

module.exports = PrimaryFieldManager;
