const _ = require("underscore");
const Model = require("./Model");
const API = require("../lib/TimeEditAPI");

const Menu = function (items) {
    Model.call(this, "Menu");
    this.activeMenu = "views";
    this.items = items || [];
    this.isOpen = true;
};

Menu.POSITION = {
    LEFT: "left",
    RIGHT: "right",
};

Menu.prototype = Object.create(Model.prototype);

Menu.prototype.getCurrentItem = function () {
    return _.find(this.items, (element) => element.id === this.activeMenu);
};

Menu.prototype.setMenuItems = function (items) {
    return this.immutableSet({
        items,
    });
};

Menu.prototype.updateFromPreferences = function (callback) {
    API.getPreferences("activeMenu", (activeMenu) => {
        if (_.isNullish(activeMenu)) {
            // eslint-disable-next-line no-param-reassign
            activeMenu = "views";
        }
        API.getPreferences("menuOpen", (isOpen) => {
            if (_.isNullish(isOpen)) {
                // eslint-disable-next-line no-param-reassign
                isOpen = true;
            }
            callback(
                this.immutableSet({
                    activeMenu: activeMenu || this.items[0].id,
                    isOpen,
                })
            );
        });
    });
};

Menu.prototype.close = function () {
    API.setPreferences("menuOpen", [false], _.noop);
    return this.immutableSet({
        isOpen: false,
    });
};

Menu.prototype.open = function (id) {
    API.setPreferences("activeMenu", [id], _.noop);
    API.setPreferences("menuOpen", [true], _.noop);
    return this.immutableSet({
        activeMenu: id,
        isOpen: true,
    });
};

module.exports = Menu;
