const React = require("react");
const ErrorComponent = require("./ErrorComponent");
const Language = require("../lib/Language");

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            info: null,
        };
    }

    componentDidCatch(error, info) {
        this.setState({ error, info });
    }

    render() {
        const { error, info } = this.state;
        if (this.state.error) {
            const text = Language.get("nc_error_crash_no_need_to_worry_just_reload");
            return (
                <ErrorComponent
                    componentStack={info ? info.componentStack : ""}
                    error={error}
                    text={text}
                />
            );
        }
        return this.props.children;
    }
}

module.exports = ErrorBoundary;
