const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
const Lang = require("../lib/Language");
const Log = require("../lib/Log");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

const LayerList = createReactClass({
    displayName: "LayerList",

    contextTypes: {
        update: PropTypes.func,
        user: PropTypes.object,
    },

    getInitialState() {
        return {
            isDeletable: true,
        };
    },

    componentDidMount() {},

    componentDidUpdate() {},

    componentWillUnmount() {},

    changeLayer(id) {
        this.props.setActiveLayer(id);
    },

    onLayersChanged() {
        this.props.reloadLayers();
    },

    newLayer() {
        const name = this.props.getLayerName();
        if (!name) {
            return;
        }
        API.createReservationLayer(name, "", (result, status) => {
            // eslint-disable-next-line no-console
            console.log(result);
            if (!result) {
                if (status) {
                    Log.error(status.details);
                } else {
                    Log.error(Lang.get("nc_error_could_not_create_draft"));
                }
            } else {
                this.props.onLayerCreated(result.id, false);
            }
        });
    },

    deleteLayer() {
        this.props.onRemoveLayer();
    },

    renameLayer() {
        const layer = _.find(this.props.layers, (lr) => lr.id === this.props.layer);
        const name = this.props.getLayerName(layer.name);
        API.renameReservationLayer(layer.id, name, layer.description, (result) => {
            // eslint-disable-next-line no-console
            console.log(result);
            this.props.reloadLayers();
        });
    },

    render() {
        const layers = [].concat(this.props.layers);
        layers.sort((a, b) => {
            // Standard view should be on top
            if (a.id === 1 || b.id === 1) {
                return a.id - b.id;
            }

            // Public views above private views
            /*if (a.orgState !== ViewVisibility.PRIVATE && b.orgState === ViewVisibility.PRIVATE) {
                return -1;
            } else if (
                a.orgState === ViewVisibility.PRIVATE &&
                b.orgState !== ViewVisibility.PRIVATE
            ) {
                return 1;
            }*/

            // Otherwise sort by name
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        const baseClasses = {
            rowItem: true,
            selected: this.props.layer === 0,
        };

        return (
            <div className="viewPane">
                <h2>{Lang.get("nc_drafts_title")}</h2>
                <ul className="selectBox viewList">
                    <li key={"base"} className={_.classSet(baseClasses)}>
                        <a onClick={this.changeLayer.bind(this, 0)}>
                            {Lang.get("nc_draft_list_no_draft")}
                        </a>
                    </li>
                    <li key="sep" className="separator" />
                    {layers.map(function (el) {
                        const classes = {
                            rowItem: true,
                            selected: el.id === this.props.layer,
                        };

                        const li = (
                            <li key={el.id} className={_.classSet(classes)}>
                                <a onClick={this.changeLayer.bind(this, el.id)}>{el.name}</a>
                            </li>
                        );

                        return li;
                    }, this)}
                </ul>

                <div className="btnGroup vertical">
                    <button
                        onClick={this.renameLayer}
                        disabled={this.props.layer === 0}
                        className="save"
                    >
                        {Lang.get("cal_header_popup_period_change_name")}
                    </button>
                    <button onClick={this.newLayer} className="save">
                        {Lang.get("nc_new_draft")}
                    </button>
                </div>
                <div className="btnGroup vertical">
                    <button
                        key={this.props.layer}
                        className="remove"
                        onClick={this.deleteLayer}
                        disabled={this.props.layer === 0}
                    >
                        {Lang.get("cal_header_popup_multi_object_remove")}
                    </button>
                </div>
            </div>
        );
    },
});

module.exports = LayerList;
