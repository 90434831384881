const PropTypes = require("prop-types");
const React = require("react");
const ListCalendar = require("./ListCalendar");
const ReservationList = require("./ReservationList");
const Macros = require("../models/Macros");

class ReservationListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
        Macros.Event.RESERVATION_RESTORED,
    ];

    onSelection = (selection) => {
        if (!selection.cancelled) {
            this.context.fireEvent(
                `reservationList${this.props.id}`,
                Macros.Event.SELECT_RESERVATION,
                selection
            );
            this.props.onInfoOpen([selection.id], false);
        }
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"reservationList"}
                refreshEvents={this.getRefreshEvents()}
                onSelection={this.onSelection}
                listRef={(node) => {
                    this._wrapper = node;
                }}
            >
                <ReservationList />
            </ListCalendar>
        );
    }
}

module.exports = ReservationListCalendar;
