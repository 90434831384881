const View = require("../models/View");
const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
const Lang = require("../lib/Language");
const Log = require("../lib/Log");
const API = require("../lib/TimeEditAPI");
const ConfirmButton = require("./ConfirmButton");
const _ = require("underscore");

const ViewVisibility = {
    PRIVATE: 0,
    PUBLIC: 1,
    ORGANIZATIONS: 2,
};

const ViewList = createReactClass({
    displayName: "ViewList",

    contextTypes: {
        update: PropTypes.func,
        user: PropTypes.object,
    },

    getInitialState() {
        return {
            isDeletable: false,
            isModifiable: false,
        };
    },

    componentDidMount() {
        this._isMounted = true;
        this.updateViews();
        this.updateModifiable(this.props.view ? this.props.view.id : null);
    },

    componentDidUpdate(prevProps) {
        if (prevProps.view && this.props.view && prevProps.view.id === this.props.view.id) {
            return;
        }

        this.updateModifiable(this.props.view ? this.props.view.id : null);
    },

    componentWillUnmount() {
        this._isMounted = false;
    },

    updateViews() {
        this.props.data.updateViews((viewList) => this.context.update(this.props.data, viewList));
    },

    changeView(id, event) {
        if (event && _.isModKey(event)) {
            // eslint-disable-next-line no-alert
            const view = _.find(
                this.props.data.views,
                (item) => {
                    return item.id === id;
                },
                this
            );
            if (confirm(Lang.get("cal_res_side_view_do_you_want_to_delete_the_view", view.name))) {
                this.deleteView(id);
            }
            return;
        }
        this.props.onViewChange(id);
    },

    getViewName(callback, defaultName = "") {
        // eslint-disable-next-line no-alert
        const name = window.prompt(Lang.get("cal_res_side_view_set_name"), defaultName);
        if (name) {
            callback(name);
        }
    },

    updateModifiable(id) {
        API.okToPublishView(id, (res) => {
            API.okToChangeViews(id, (res2) => {
                if (this._isMounted && res2.parameters[0].length > 0) {
                    this.setState({
                        canMakePublic: res.parameters[0],
                        canMakePrivate: res.parameters[1],
                        isDeletable: res2.parameters[0][0].delete,
                        isModifiable: res2.parameters[0][0].modify,
                    });
                }
            });
        });
    },

    saveView(saveNew, makePublic = null) {
        const callback = (view) => {
            const newView = this.props.view.section.toJSON();
            let orgState = saveNew ? null : this.props.view.orgState;
            if (makePublic === true) {
                orgState = ViewVisibility.PUBLIC;
            }
            if (makePublic === false) {
                orgState = ViewVisibility.PRIVATE;
            }
            // Make new views private by not copying organizations.
            View.save(
                view.id,
                view.name,
                newView,
                saveNew ? [] : this.props.view.organizations,
                orgState,
                (result) => {
                    if (result[0].details) {
                        Log.warning(result[0].details);
                        return;
                    }
                    if (saveNew || (makePublic !== null && makePublic !== undefined)) {
                        this.updateViews();
                        this.changeView(result[0].reference);
                    }
                    Log.info(Lang.get("nc_cal_res_side_view_saved", view.name));
                }
            );
        };

        if (saveNew) {
            this.getViewName((name) => {
                callback({ id: 0, name });
            }, this.props.view.name);
            return;
        }

        const view = _.find(this.props.data.views, (item) => item.id === this.props.view.id);
        callback(view);
    },

    renameView() {
        const view = _.find(this.props.data.views, (item) => item.id === this.props.view.id);
        this.getViewName((name) => {
            View.save(
                view.id,
                name,
                this.props.view.section.toJSON(),
                this.props.view.organizations,
                this.props.view.orgState,
                (result) => {
                    if (result[0].details) {
                        Log.warning(result[0].details);
                        return;
                    }
                    this.updateViews();
                    Log.info(Lang.get("nc_cal_res_side_view_saved", name));
                }
            );
        }, view.name);
    },

    deleteView(id = this.props.view.id) {
        const view = _.find(
            this.props.data.views,
            (item) => {
                return item.id === id;
            },
            this
        );
        const self = this;
        View.delete(view.id, () => {
            Log.info(Lang.get("nc_cal_res_side_view_deleted", view.name));
            self.updateViews();
            self.changeView(1);
        });
    },

    isStandardViewSelected() {
        return this.props.view && this.props.view.id === 1;
    },

    render() {
        const views = [].concat(this.props.data.views);
        views.sort((a, b) => {
            // Standard view should be on top
            if (a.id === 1 || b.id === 1) {
                return a.id - b.id;
            }

            // Public views above private views
            if (a.orgState !== ViewVisibility.PRIVATE && b.orgState === ViewVisibility.PRIVATE) {
                return -1;
            } else if (
                a.orgState === ViewVisibility.PRIVATE &&
                b.orgState !== ViewVisibility.PRIVATE
            ) {
                return 1;
            }

            // Otherwise sort by name
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });

        const makePublicButton =
            this.props.view &&
            this.props.view.orgState === ViewVisibility.PRIVATE &&
            this.state.canMakePublic ? (
                <button onClick={this.saveView.bind(this, false, true)} className="save">
                    {Lang.get("layer_make_public")}
                </button>
            ) : null;
        const makePrivateButton =
            this.props.view &&
            this.props.view.orgState === ViewVisibility.PUBLIC &&
            this.state.canMakePrivate &&
            !this.isStandardViewSelected() ? (
                <button onClick={this.saveView.bind(this, false, false)} className="save">
                    {Lang.get("layer_make_private")}
                </button>
            ) : null;

        const isUnsupported = this.props.view ? this.props.view.isUnsupported : false;
        return (
            <div className="viewPane">
                <h2>{Lang.get("permission_views")}</h2>
                <ul className="selectBox viewList">
                    {views.map(function (el, index, array) {
                        const classes = {
                            rowItem: true,
                            selected: el.id === this.props.view ? this.props.view.id : null,
                        };

                        const li = (
                            <li key={el.id} className={_.classSet(classes)}>
                                <a onClick={this.changeView.bind(this, el.id)}>{el.name}</a>
                            </li>
                        );

                        if (
                            el.orgState === ViewVisibility.PRIVATE &&
                            array[index - 1] &&
                            array[index - 1].orgState !== ViewVisibility.PRIVATE
                        ) {
                            return [<li key="sep" className="separator" />, li];
                        }

                        return li;
                    }, this)}
                </ul>

                <div className="btnGroup vertical" key={this.props.data.selected}>
                    {makePublicButton}
                    {makePrivateButton}
                    <button
                        onClick={this.renameView}
                        disabled={!this.state.isModifiable || isUnsupported}
                        className="save"
                    >
                        {Lang.get("cal_header_popup_period_change_name")}
                    </button>
                    <button
                        onClick={this.saveView.bind(this, false)}
                        disabled={!this.state.isModifiable || isUnsupported}
                        className="save"
                    >
                        {Lang.get("cal_res_side_view_update_title")}
                    </button>
                    <button onClick={this.saveView.bind(this, true)} className="save">
                        {Lang.get("nc_cal_res_side_view_save_as_title")}
                    </button>
                </div>
                <div className="btnGroup vertical">
                    <ConfirmButton
                        key={this.props.view ? this.props.view.id : 0}
                        className="remove"
                        onClick={() => this.deleteView()}
                        disabled={!this.state.isDeletable}
                        confirmMessage={Lang.get(
                            "cal_res_side_view_do_you_want_to_delete_the_view",
                            this.props.view ? this.props.view.name : "No view"
                        )}
                    >
                        {Lang.get("cal_header_popup_multi_object_remove")}
                    </ConfirmButton>
                </div>
            </div>
        );
    },
});

module.exports = ViewList;
