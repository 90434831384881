const PropTypes = require("prop-types");
const React = require("react");
const Accordion = require("./Accordion");
const OrderEditor = require("./OrderEditor");
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const Log = require("../lib/Log");
const Macros = require("../models/Macros");

import {
    MillenniumDate,
    MillenniumTime,
    MillenniumDateTime,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import {
    renderLine,
    presentObjects,
    getUserTime,
    getOrgName,
    getPeriodName,
    getOrderStatus,
    orderRowStatusToString,
    ORDER_ID_OFFSET,
} from "../lib/OrderUtils";
const _ = require("underscore");

class OrderInfo extends React.Component {
    static propTypes = {
        orderIds: PropTypes.array.isRequired,
    };

    static contextTypes = {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
    };

    state = {
        orderRows: [],
        orders: [],
        types: [],
        periods: [],
        organizations: [],
        users: [],
        accordionSections: [0],
        editableOrders: [],
    };

    componentDidMount() {
        API.findTypes((types) => {
            this.setState({ types });
        });
        API.findPeriods((periods) => {
            this.setState({ periods });
        });
        API.getOrganizations({}, (result) => {
            this.setState({ organizations: result.parameters[0] });
        });
        if (this.props.orderIds.length > 0) {
            this.updateOrders(this.props.orderIds);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.orderIds, this.props.orderIds)) {
            this.updateOrders(this.props.orderIds);
        }
    }

    componentWillUnmount() {}

    getUserInfo = (users, callback = _.noop) => {
        API.getUsers(users, (foundUsers) => callback(foundUsers));
    };

    updateOrders(orderRowIds) {
        const orderIds = orderRowIds.map(
            (orderRowId) => orderRowId - (orderRowId % ORDER_ID_OFFSET)
        );
        API.getOrderRows({ orderRowIds, createObjectsWithNames: true }, (result) => {
            API.exportOrders(orderIds, (exportResult) => {
                const userIds = _.flatten(
                    exportResult.map((ordr) => [ordr.created_by.id, ordr.modified_by.id])
                );
                API.okToModifyOrders(orderIds, (editResult) => {
                    this.getUserInfo(_.uniq(userIds), (users) => {
                        this.setState({
                            orderRows: result.parameters[0],
                            orders: exportResult,
                            users,
                            editableOrders: editResult,
                        });
                    });
                });
            });
        });
    }

    onAccordionChange(newSections) {
        this.setState({ accordionSections: newSections });
    }

    _renderOrder(order, index) {
        const types = this.state.types;
        const periods = this.state.periods;
        const organizations = this.state.organizations;
        const users = this.state.users;
        return (
            <div key={index}>
                <table>
                    <tbody>
                        {renderLine("cal_order_side_id", order.id, "id")}
                        {renderLine("cal_order_side_extid", order.extid, "extid")}
                        {renderLine(
                            "cal_order_side_created",
                            getUserTime(order.created, order.created_by.id, users),
                            "createdBy"
                        )}
                        {renderLine(
                            "cal_order_side_modified",
                            getUserTime(order.modified, order.modified_by.id, users),
                            "modifiedBy"
                        )}
                        {renderLine(
                            "cal_order_side_description",
                            order.description || "",
                            "description"
                        )}
                        {renderLine(
                            "cal_order_side_organizations",
                            (order.orgs || [])
                                .map((org) => getOrgName(org.id, organizations))
                                .join(", "),
                            "organizations"
                        )}
                        {renderLine(
                            "cal_order_side_period",
                            order.period ? getPeriodName(order.period.id, periods) : "-",
                            "period"
                        )}
                        {renderLine(
                            "cal_order_side_start",
                            SimpleDateFormat.format(
                                MillenniumDateTime.fromDate(new MillenniumDate(order.begin)),
                                Language.getDateFormat("date_f_yyyy_mm_dd")
                            ),
                            "begin"
                        )}
                        {renderLine(
                            "cal_order_side_end",
                            SimpleDateFormat.format(
                                MillenniumDateTime.fromDate(new MillenniumDate(order.end)),
                                Language.getDateFormat("date_f_yyyy_mm_dd_end")
                            ),
                            "end"
                        )}
                        {renderLine(
                            "cal_order_side_status",
                            getOrderStatus(order.status.status),
                            "status"
                        )}
                        {renderLine("cal_order_side_title", order.title || "", "title")}
                        {renderLine(
                            "cal_order_side_mandatory_object",
                            "",
                            "requiredObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.required_object], types)}
                        {renderLine(
                            "cal_order_side_customer_object",
                            "",
                            "customerObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.customer_object], types)}
                        {renderLine(
                            "cal_order_side_owner_object",
                            "",
                            "ownerObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.owner_object], types)}
                    </tbody>
                </table>
            </div>
        );
    }

    _renderOrderRow(orderRow, types, periods, index) {
        return (
            <div key={index}>
                <table>
                    <tbody>
                        {renderLine(
                            "cal_order_side_ordered_count",
                            orderRow.order_count || 0,
                            "orderedCount"
                        )}
                        {renderLine(
                            "cal_order_side_ordered_time",
                            orderRow.order_length
                                ? SimpleDateFormat.format(
                                      new MillenniumTime(orderRow.order_length),
                                      Language.getDateFormat("date_f_hh_mm")
                                  )
                                : 0,
                            "orderedTime"
                        )}
                        {renderLine(
                            "cal_order_side_delivered_count",
                            orderRow.delivered_count,
                            "deliveredCount"
                        )}
                        {renderLine(
                            "cal_order_side_delivered_time",
                            orderRow.delivered_length
                                ? SimpleDateFormat.format(
                                      new MillenniumTime(orderRow.delivered_length),
                                      Language.getDateFormat("date_f_hh_mm")
                                  )
                                : 0,
                            "deliveredTime"
                        )}
                        {renderLine(
                            "cal_order_side_expander_order_row_info",
                            orderRow.description,
                            "info"
                        )}
                        {renderLine("cal_order_side_row_description", orderRow.comment, "comment")}
                        {renderLine(
                            "cal_order_side_row_status",
                            orderRowStatusToString(orderRow.status.status),
                            "status"
                        )}
                        {renderLine(
                            "order_status_completed",
                            orderRow.completed
                                ? Language.get("order_status_completed")
                                : Language.get("order_status_not_completed"),
                            "completed"
                        )}
                        {renderLine(
                            "cal_order_side_order_objects",
                            "",
                            "orderObjects",
                            "tableSubheader"
                        )}
                        {presentObjects(orderRow.objects, types)}
                        {renderLine(
                            "cal_order_side_extra_objects",
                            "",
                            "extraObjects",
                            "tableSubheader"
                        )}
                        {presentObjects(orderRow.extra_objects, types)}
                    </tbody>
                </table>
            </div>
        );
    }

    enableEdit() {
        this.setState({ isEditing: true });
    }

    isEditable() {
        return _.every(
            this.state.editableOrders,
            (editPermissions) => editPermissions.modifyFields === true
        );
    }

    onOrderSave(orders) {
        this.setState({ isEditing: false, orders });
        // eslint-disable-next-line no-unused-vars
        API.importOrders(orders, (result) => {
            if (result[0].details) {
                Log.error(result[0].details);
            }
            this.updateOrders(this.props.orderIds);
            // If people ever start editing orders more
            // wouldn't it make sense to switch to a separate event
            // which just updated orders and order lists?
            this.context.fireEvent("menu", Macros.Event.RESERVATION_MADE_OR_MODIFIED, []);
        });
    }

    onOrderCancel() {
        this.setState({ isEditing: false });
    }

    render() {
        if (!this.props.orderIds || this.props.orderIds.length === 0) {
            return null;
        }
        if (this.state.isEditing) {
            return (
                <OrderEditor
                    onAccordionChange={this.onAccordionChange.bind(this)}
                    expandedSections={this.state.accordionSections}
                    orders={this.state.orders}
                    orderRows={this.state.orderRows}
                    types={this.state.types}
                    periods={this.state.periods}
                    organizations={this.state.organizations}
                    users={this.state.users}
                    onClose={this.props.onClose}
                    onSave={this.onOrderSave.bind(this)}
                    onCancel={this.onOrderCancel.bind(this)}
                />
            );
        }
        const types = this.state.types;
        const periods = this.state.periods;
        const rowSections = [];
        const sections = this.state.orders.map((order) => {
            order.rows.forEach((or, index) => {
                rowSections.push({
                    title: `${Language.get("cal_order_side_expander_order_row")} ${or.id}`,
                    content: this._renderOrderRow(or, types, periods, index),
                });
            });
            return {
                title: `${Language.get("cal_order_side_expander_order")} ${
                    order.title || order.id
                }`,
                content: this._renderOrder(order),
            };
        });
        return (
            <div className="reservationPane">
                <div style={{ display: "block" }}>
                    <div className="dismiss" onClick={this.props.onClose} />
                    <h2>{Language.get("cal_res_side_tab_order_info")}</h2>
                </div>
                <Accordion
                    onContentChange={this.onAccordionChange.bind(this)}
                    sections={sections.concat(rowSections)}
                    initialSection={this.state.accordionSections}
                />
                <div className="btnGroup single">
                    <button
                        className="ok"
                        onClick={() => this.enableEdit()}
                        disabled={!this.isEditable()}
                    >
                        {Language.get("nc_cal_func_res_edit")}
                    </button>
                </div>
            </div>
        );
    }
}

module.exports = OrderInfo;
