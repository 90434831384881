const PropTypes = require("prop-types");
const React = require("react");
const ListCalendar = require("../ListCalendar");
const _ = require("underscore");
const ExamWrapper = require("./ExamWrapper");

class ExamComponentCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    getRefreshEvents = () => [];

    render() {
        let style = { width: this.props.width, height: "100%" };
        if (this.props.style) {
            style = this.props.style;
        }
        const classes = _.extend(
            {
                reservationList: true,
            },
            this.props.classes
        );
        return (
            <ListCalendar
                {...this.props}
                className={`${this.props.className} prefsComponent examComponent`}
                type={"examComponent"}
                refreshEvents={this.getRefreshEvents()}
                privateSelected={false}
                listRef={(node) => {
                    this._wrapper = node;
                }}
                fireEvent={this.context.fireEvent}
            >
                <ExamWrapper classes={classes} style={style} />
            </ListCalendar>
        );
    }
}

module.exports = ExamComponentCalendar;
