import React from "react";

const MemberObjectCreatorFormPreview = (groupRequests) => {
    const rows = (groupRequests) => {
        return groupRequests.map((groupRequest, index) => (
            <tr key={groupRequests.connectTo?.extId || index}>
                <td>{groupRequest.connectTo?.object.name}</td>
                <td>{groupRequest.connectTo?.type.name}</td>
                <td>{groupRequest?.numberOfObjects}</td>
                <td>{groupRequest?.type.name}</td>
            </tr>
        ));
    };

    return (
        <table data-cy="preview-table">
            <thead>
                <tr data-cy="preview-table-header">
                    <th>Primary Object</th>
                    <th>Primary Objects Type</th>
                    <th>Number of Objects</th>
                    <th>Created Objects Type</th>
                </tr>
            </thead>
            <tbody>{rows(groupRequests)}</tbody>
        </table>
    );
};

export default MemberObjectCreatorFormPreview;
