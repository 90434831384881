const sendHttpRequest = function (url, opts, params, cb) {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || "POST", url, true);

    xhr.onreadystatechange = function () {
        // eslint-disable-next-line no-magic-numbers
        if (xhr.readyState === 4 && cb) {
            cb(xhr.responseText);
        }
    };

    xhr.setRequestHeader("Content-type", "application/json");
    const body = JSON.stringify(params);

    if (opts.beforeSend) {
        opts.beforeSend(xhr);
    }

    xhr.send(body);
};

const Glitch = {};
Glitch.create = function (opts) {
    const TIMEOUT = 500;
    if (
        opts === null ||
        opts === undefined ||
        (typeof opts !== "object" && typeof opts !== "string")
    ) {
        throw new Error("Options must either be a string or an object.");
    }

    if (typeof opts === "string") {
        // eslint-disable-next-line no-param-reassign
        opts = { url: opts };
    }

    let cache = {};
    let timeout = null;

    return function (msg, fileUrl, lineNumber, colNumber, error) {
        let key = `${fileUrl}:${lineNumber}:${msg}`;
        if (colNumber !== undefined) {
            key = `${fileUrl}:${lineNumber}:${colNumber}:${msg}`;
        }

        let stack;
        if (error && error.stack) {
            stack = error.stack;
            if (typeof stack === "string") {
                // Stack is not a standard, so don't assume too much about it.
                stack = stack.split("\n");
            }
        }

        if (!cache.hasOwnProperty(key)) {
            cache[key] = {
                handlerName: opts.name || "Glitch",
                message: msg,
                stack,
                file: fileUrl,
                line: lineNumber,
                count: 0,
            };
            if (colNumber !== undefined) {
                cache[key].column = colNumber;
            }
        }
        cache[key].count = cache[key].count + 1;
        if (timeout !== null) {
            return;
        }

        timeout = setTimeout(() => {
            const errors = Object.keys(cache).map((k) => cache[k]);
            sendHttpRequest(opts.url, opts, errors);
            clearTimeout(timeout);
            timeout = null;
            cache = {};
        }, opts.batchTime || TIMEOUT);
    };
};

module.exports = Glitch;
