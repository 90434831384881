const React = require("react");
const Mousetrap = require("@timeedit/mousetrap");
const Language = require("../lib/Language");
const _ = require("underscore");

class ShortcutOverlay extends React.Component {
    componentDidMount() {
        this._previousMouseBindings = Mousetrap.unbindWithHelp("esc", true)[0];
        Mousetrap.bindWithHelp("esc", this.props.onClose);
    }

    componentWillUnmount() {
        Mousetrap.bindWithHelp("esc", this._previousMouseBindings);
    }

    render() {
        const shortcuts = _.sortBy(Mousetrap.getShortcuts(), (shortcut) => {
            if (shortcut.hidden) {
                return 1;
            }
            return 0;
        });
        const shortcutRows = Object.keys(shortcuts).map((key) => (
            <tr key={key}>
                <th>{Mousetrap.presentShortcut(shortcuts[key].keys)}</th>
                <td>{shortcuts[key].description}</td>
            </tr>
        ));

        const WIDTH_MODIFIER = 400;
        const HEIGHT_MODIFIER = 200;
        const TOP_MODIFIER = 3;
        const LEFT_MODIFIER = 2;
        const SCROLL_MODIFIER = 70;

        const styles = {
            width: document.body.clientWidth - WIDTH_MODIFIER,
            height: document.body.clientHeight - HEIGHT_MODIFIER,
            top: 0,
            left: 0,
        };
        styles.top = (document.body.clientHeight - styles.height) / TOP_MODIFIER;
        styles.left = (document.body.clientWidth - styles.width) / LEFT_MODIFIER;

        const scrollStyle = {
            top: 0,
            left: 0,
            width: "100%",
            height: styles.height - SCROLL_MODIFIER,
        };

        const NUM_COLS = 2; // Note that rendered HTML also needs to change if more columns are wanted.

        return (
            <div className="modal" style={styles}>
                <div className="close" onClick={this.props.onClose}>
                    X
                </div>
                <h1>{Language.get("nc_keyboard_shortcuts")}</h1>
                <div className="verticalScrollArea" style={scrollStyle}>
                    <div className="objectDefinitionPane" style={styles}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="column">
                                        <table>
                                            <tbody>
                                                {shortcutRows.filter(
                                                    (row, index) =>
                                                        index < shortcutRows.length / NUM_COLS
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="column">
                                        <table>
                                            <tbody>
                                                {shortcutRows.filter(
                                                    (row, index) =>
                                                        index >= shortcutRows.length / NUM_COLS
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <a
                                            target="_blank"
                                            href="https://support.timeedit.net/hc/sv/articles/360001379953"
                                        >
                                            {Language.get("nc_about_more_info")}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = ShortcutOverlay;
