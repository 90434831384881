const PropTypes = require("prop-types");
const React = require("react");
let guid = 0;
const k = function () {};
const addClass = require("./add-class");
const ComboboxOption = require("./option");
const _ = require("underscore");

function getLabel(component) {
    return component.props.label || component.props.children;
}

class Combobox extends React.Component {
    static propTypes = {
        onFocus: PropTypes.func,

        /**
         * Called when the combobox receives user input, this is your chance to
         * filter the data and rerender the options.
         *
         * Signature:
         *
         * ```js
         * function(userInput){}
         * ```
         */
        onInput: PropTypes.func,

        /**
         * Called when the combobox receives a selection. You probably want to reset
         * the options to the full list at this point.
         *
         * Signature:
         *
         * ```js
         * function(selectedValue){}
         * ```
         */
        onSelect: PropTypes.func,

        /**
         * Shown when the combobox is empty.
         */
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        autocomplete: "both",
        onFocus: k,
        onInput: k,
        onSelect: k,
        value: null,
        showListOnFocus: false,
    };

    componentDidlMount() {
        this.setState({ menu: this.makeMenu(this.props.children) });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ menu: this.makeMenu(this.props.children) }, () => {
                if (
                    this.props.children.length &&
                    (this.isOpen || document.activeElement === this.input)
                ) {
                    if (!this.state.menu.children.length) {
                        return;
                    }
                    this.setState(
                        {
                            isOpen: true,
                        },
                        () => {
                            this.list.scrollTop = 0;
                        }
                    );
                } else {
                    this.hideList();
                }
            });
        }
    }

    /**
     * We don't create the <ComboboxOption> components, the user supplies them,
     * so before rendering we attach handlers to facilitate communication from
     * the ComboboxOption to the Combobox.
     */
    makeMenu = (children) => {
        let activedescendant;
        let isEmpty = true;

        // Should this instead use React.addons.cloneWithProps or React.cloneElement?
        const _children = React.Children.map(children, (child, index) => {
            // console.log(child.type, ComboboxOption.type)
            if (child.type !== ComboboxOption || !child.props.isfocusable) {
                // allow random elements to live in this list
                return child;
            }
            isEmpty = false;
            const props = child.props;
            const newProps = {};
            if (this.state.value === child.props.value) {
                // need an ID for WAI-ARIA
                newProps.id = props.id || `ic-tokeninput-selected-${++guid}`;
                newProps.isselected = "true";
                activedescendant = props.id;
            }
            newProps.onBlur = this.handleOptionBlur;
            newProps.onClick = this.selectOption.bind(this, child);
            newProps.onFocus = this.handleOptionFocus;
            newProps.onKeyDown = this.handleOptionKeyDown.bind(this, child);
            newProps.onMouseEnter = this.handleOptionMouseEnter.bind(this, index);

            return React.cloneElement(child, newProps);
        });

        return {
            children: _children,
            activedescendant,
            isEmpty,
        };
    };

    getClassName = () => {
        let className = addClass(this.props.className, "ic-tokeninput");
        if (this.state.isOpen) {
            className = addClass(className, "ic-tokeninput-is-open");
        }
        return className;
    };

    /**
     * When the user begins typing again we need to clear out any state that has
     * to do with an existing or potential selection.
     */
    clearSelectedState = (cb) => {
        this.setState(
            {
                focusedIndex: null,
                inputValue: null,
                value: null,
                matchedAutocompleteOption: null,
                activedescendant: null,
            },
            cb
        );
    };

    handleInputChange = () => {
        const value = this.input.value;
        this.clearSelectedState(() => {
            this.props.onInput(value);
        });
    };

    handleInputFocus = () => {
        this.props.onFocus();
        this.maybeShowList();
    };

    handleInputClick = () => {
        this.maybeShowList();
    };

    maybeShowList = () => {
        if (this.props.showListOnFocus) {
            this.showList();
        }
    };

    handleInputBlur = () => {
        const focusedAnOption = this.state.focusedIndex != null;
        if (focusedAnOption) {
            return;
        }
        this.maybeSelectAutocompletedOption();
        this.hideList();
    };

    handleOptionBlur = () => {
        // don't want to hide the list if we focused another option
        this.blurTimer = setTimeout(this.hideList, 0);
    };

    handleOptionFocus = () => {
        // see `handleOptionBlur`
        clearTimeout(this.blurTimer);
    };

    handleInputKeyUp = (event) => {
        if (
            this.state.menu.isEmpty ||
            // autocompleting while backspacing feels super weird, so let's not
            // eslint-disable-next-line no-magic-numbers
            event.keyCode === 8 /*backspace*/ ||
            !this.props.autocomplete.match(/both|inline/)
        ) {
            return;
        }
    };

    handleButtonClick = () => {
        // eslint-disable-next-line no-unused-expressions
        this.state.isOpen ? this.hideList() : this.showList();
        this.focusInput();
    };

    showList = () => {
        if (!this.state.menu.children.length) {
            return;
        }
        this.setState({ isOpen: true });
    };

    hideList = () => {
        this.setState({
            isOpen: false,
            focusedIndex: null,
        });
    };

    hideOnEscape = (event) => {
        this.hideList();
        this.focusInput();
        event.preventDefault();
    };

    focusInput = () => {
        this.input.focus();
    };

    selectInput = () => {
        this.input.select();
    };

    inputKeydownMap = {
        8: "removeLastToken", // delete
        13: "selectOnEnter", // enter
        188: "selectOnEnter", // comma
        27: "hideOnEscape", // escape
        38: "focusPrevious", // up arrow
        40: "focusNext", // down arrow
    };

    optionKeydownMap = {
        13: "selectOption",
        27: "hideOnEscape",
        38: "focusPrevious",
        40: "focusNext",
    };

    handleKeydown = (event) => {
        const handlerName = this.inputKeydownMap[event.keyCode];
        if (!handlerName) {
            return;
        }
        this.setState({ usingKeyboard: true });
        // eslint-disable-next-line no-useless-call
        this[handlerName].call(this, event);
    };

    handleOptionKeyDown = (child, event) => {
        const handlerName = this.optionKeydownMap[event.keyCode];
        if (!handlerName) {
            // if the user starts typing again while focused on an option, move focus
            // to the inpute, select so it wipes out any existing value
            this.selectInput();
            return;
        }
        event.preventDefault();
        this.setState({ usingKeyboard: true });
        // eslint-disable-next-line no-useless-call
        this[handlerName].call(this, child);
    };

    handleOptionMouseEnter = (index) => {
        if (this.state.usingKeyboard) {
            this.setState({ usingKeyboard: false });
        } else {
            this.focusOptionAtIndex(index);
        }
    };

    selectOnEnter = (event) => {
        event.preventDefault();
        this.maybeSelectAutocompletedOption();
    };

    maybeSelectAutocompletedOption = () => {
        if (!this.state.matchedAutocompleteOption) {
            this.selectText();
        } else {
            this.selectOption(this.state.matchedAutocompleteOption, { focus: false });
        }
    };

    selectOption = (child, options = {}) => {
        this.setState(
            {
                matchedAutocompleteOption: null,
            },
            () => {
                this.props.onSelect(child.props.value, child);
                this.hideList();
                this.clearSelectedState(); // added
                if (options.focus !== false) {
                    this.selectInput();
                }
            }
        );
        this.input.value = ""; // added
    };

    selectText = () => {
        const value = this.input.value;
        if (!value) {
            return;
        }
        this.props.onSelect(value);
        this.clearSelectedState();
        this.input.value = ""; // added
    };

    focusNext = (event) => {
        if (event.preventDefault) {
            event.preventDefault();
        }
        if (this.state.menu.isEmpty) {
            return;
        }
        const index = this.nextFocusableIndex(this.state.focusedIndex);
        this.focusOptionAtIndex(index);
    };

    removeLastToken = () => {
        if (this.props.onRemoveLast && !this.input.value) {
            this.props.onRemoveLast();
        }
        return true;
    };

    focusPrevious = (event) => {
        if (event.preventDefault) {
            event.preventDefault();
        }
        if (this.state.menu.isEmpty) {
            return;
        }
        const index = this.previousFocusableIndex(this.state.focusedIndex);
        this.focusOptionAtIndex(index);
    };

    focusSelectedOption = () => {
        let selectedIndex;
        React.Children.forEach(this.props.children, (child, index) => {
            if (child.props.value === this.state.value) {
                selectedIndex = index;
            }
        });
        this.showList();
        this.setState(
            {
                focusedIndex: selectedIndex,
            },
            this.focusOption
        );
    };

    findInitialInputValue = () => {
        let inputValue;
        React.Children.forEach(this.props.children, (child) => {
            if (child.props.value === this.props.value) {
                inputValue = getLabel(child);
            }
        });
        return inputValue;
    };

    clampIndex = (index) => {
        if (index < 0) {
            return this.props.children.length - 1;
        } else if (index >= this.props.children.length) {
            return 0;
        }
        return index;
    };

    scanForFocusableIndex = (index, increment) => {
        if (index === null || index === undefined) {
            // eslint-disable-next-line no-param-reassign
            index = increment > 0 ? this.clampIndex(-1) : 0;
        }
        let newIndex = index;
        // eslint-disable-next-line no-constant-condition
        while (true) {
            newIndex = this.clampIndex(newIndex + increment);
            if (newIndex === index || this.props.children[newIndex].props.isffsocusable) {
                return newIndex;
            }
        }
    };

    nextFocusableIndex = (index) => this.scanForFocusableIndex(index, 1);

    previousFocusableIndex = (index) => this.scanForFocusableIndex(index, -1);

    focusOptionAtIndex = (index) => {
        if (!this.state.isOpen && this.state.value) {
            return this.focusSelectedOption();
        }
        this.showList();
        const length = this.props.children.length;
        if (index === -1) {
            // eslint-disable-next-line no-param-reassign
            index = length - 1;
        } else if (index === length) {
            // eslint-disable-next-line no-param-reassign
            index = 0;
        }
        this.setState(
            {
                focusedIndex: index,
            },
            this.focusOption
        );
        return null;
    };

    focusOption = () => {
        const index = this.state.focusedIndex;
        this.list.childNodes[index].focus();
    };

    state = {
        value: this.props.value,
        // the value displayed in the input
        inputValue: this.findInitialInputValue(),
        isOpen: false,
        focusedIndex: null,
        matchedAutocompleteOption: null,
        // this prevents crazy jumpiness since we focus options on mouseenter
        usingKeyboard: false,
        activedescendant: null,
        listId: `ic-tokeninput-list-${++guid}`,
        menu: {
            children: [],
            activedescendant: null,
            isEmpty: true,
        },
    };

    render() {
        const ariaLabel =
            this.props["aria-label"] ||
            "Start typing to search. " +
                "Press the down arrow to navigate results. If you don't find an " +
                "acceptable option, you can input an alternative. Once you find or " +
                "input the tag you want, press Enter or Comma to add it.";

        return (
            <div className={this.getClassName()}>
                {this.props.value}
                {this.state.inputValue}
                <input
                    ref={(e) => (this.input = e)}
                    autoComplete="off"
                    spellCheck="false"
                    aria-label={ariaLabel}
                    aria-expanded={`${this.state.isOpen}`}
                    aria-haspopup="true"
                    aria-activedescendant={this.state.menu.activedescendant}
                    aria-autocomplete="list"
                    aria-owns={this.state.listId}
                    id={this.props.id}
                    disabled={this.props.isDisabled}
                    className="ic-tokeninput-input"
                    onFocus={this.handleInputFocus}
                    onClick={this.handleInputClick}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputBlur}
                    onKeyDown={this.handleKeydown}
                    onKeyUp={this.handleInputKeyUp}
                    placeholder={this.props.placeholder}
                    role="combobox"
                />
                <span
                    aria-hidden="true"
                    className="ic-tokeninput-button"
                    onClick={this.handleButtonClick}
                >
                    ▾
                </span>
                <div
                    id={this.state.listId}
                    ref={(e) => (this.list = e)}
                    className="ic-tokeninput-list"
                    role="listbox"
                >
                    {this.state.menu.children}
                </div>
            </div>
        );
    }
}

module.exports = Combobox;
