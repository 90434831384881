const DataStore = require("../lib/DataStore");

let currentModelId = 0;
const nextModelId = function () {
    return currentModelId++;
};

const Model = function (typeName) {
    this._typeName = typeName || "Model";
    this._modelId = nextModelId();
    this._transient = [];
};

Model.prototype.immutableSet = function (props) {
    return DataStore.update(this, this, props);
};

Model.prototype.freeze = function () {
    return DataStore.deepFreeze(this);
};

Model.prototype.mutableCopy = function () {
    const copy = DataStore.deepClone(this);
    copy._modelId = nextModelId();
    return copy;
};

Model.prototype.getId = function () {
    return this._modelId;
};

Model.prototype.toJSON = function () {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this) {
        if (
            this.hasOwnProperty(prop) &&
            prop !== "_transient" &&
            this._transient.indexOf(prop) === -1
        ) {
            result[prop] = this[prop];
        }
    }
    return result;
};

Model.prototype.toString = function () {
    return `[object ${this._typeName} ${this._modelId}]`;
};

module.exports = Model;
