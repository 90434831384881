const React = require("react");
const Language = require("../lib/Language");

class UnsupportedCalendar extends React.Component {
    render() {
        const getOldSymbol = () => {
            if (!this._oldSymbol) {
                const picks = ["📠", "👴", "👵", "🚧"];
                const pick = Math.round(Math.random() * (picks.length - 1));
                this._oldSymbol = picks[pick];
            }
            return this._oldSymbol;
        };
        const tip = Language.get("nc_datechooser_button_tip").split("{0}");
        const closeText = this.props.onClose ? (
            <p>{Language.get("nc_datechooser_click_to_close")}</p>
        ) : null;
        return (
            <div className="calendar" style={this.props.size} onClick={this.props.onClose}>
                <div className="oldComponent" style={{ overflow: "auto", cursor: "pointer" }}>
                    <div
                        style={{
                            width: "600px",
                            maxWidth: "75%",
                            whiteSpace: "normal",
                        }}
                    >
                        <h1>{Language.get("nc_old_calendar_headline")}</h1>
                        <p>{Language.get("nc_datechooser_not_supported")}</p>
                        <p>
                            {tip[0]}
                            <button className="calendarButton" />
                            {tip[1]}
                        </p>
                        {closeText}
                        <h1>{getOldSymbol()}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = UnsupportedCalendar;
