const React = require("react");
const ObjectSelect = require("./ObjectSelect");
// const OBMC = require("../lib/ReservationConstants").OBJECT_MASS_CHANGE;
const BooleanInput = require("./BooleanInput");
const Language = require("../lib/Language");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

const USE_RELATED_PREFS_KEY = "assignObjectsUseRelated";

class MassAssignObjectsDialog extends React.Component {
    state = {
        labelReplaceText: "",
        labelReplaceWithText: "",
        labelReplaceTypeText: "",
        selectedObjects: [],
        allTypes: [],
        types: [],
        typeTree: {},
        dropDownType: 0,
        destinationDropDownType: 0,
        allowDoubleBooking: false,
        addIfMissingTypeOnly: false,
        destinationType: TimeEdit.rootType,
        destinationTypes: [],
        removeAllOfType: false,
        useRelatedObjects: false,
    };

    componentDidMount() {
        if (this.props.changeData) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState(this.props.changeData);
        }

        API.getTypesOnReservations(this.props.reservationIds, (types) => {
            API.getTypeTree((tree) => {
                API.getReservableTypesForType(null, (allTypes) => {
                    API.getPreferences(USE_RELATED_PREFS_KEY, (savedUseRelatedValue) => {
                        this.setState(
                            {
                                allTypes,
                                typeTree: tree,
                                types,
                                useRelatedObjects: savedUseRelatedValue
                                    ? savedUseRelatedValue
                                    : false,
                            },
                            () => {
                                this.props.onSelectionChanged(this.state);
                                if (this.props.startTypeId && !this.state.selectedType) {
                                    this.changeType(
                                        this.props.startTypeId,
                                        this.loadSelectedObjects.bind(this)
                                    );
                                } else {
                                    this.loadSelectedObjects();
                                }
                            }
                        );
                    });
                });
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedType !== this.state.selectedType) {
            this.loadSelectedObjects();
        }
    }

    loadSelectedObjects() {
        if (!this.props.reservationIds || !this.state.selectedType) {
            return;
        }
        API.getObjectsOnReservations(
            this.props.reservationIds,
            this.state.selectedType.id,
            (objects, occurances) => {
                const fullObjects = objects.map((obj, index) => ({
                    id: obj.id,
                    type: this.state.selectedType,
                    occurances: occurances[index],
                }));
                this.setState({ selectedObjects: fullObjects });
            }
        );
    }

    changeType = (event, callback) => {
        const typeId = typeof event === "number" ? event : parseInt(event.target.value, 10);
        let selectedType = _.find(this.state.allTypes, (type) => type.id === typeId);
        if (!selectedType) {
            selectedType = this.state.allTypes[0];
            if (!selectedType) {
                return;
            }
        }
        const labelReplaceTypeText = this.getTypeName(selectedType.id);
        this.setState(
            {
                toReplace: null,
                labelReplaceText: "",
                labelReplaceWithText: "",
                selectedType,
                destinationTypes: this.state.allTypes,
                destinationType: selectedType,
                dropDownType: selectedType.id,
                destinationDropDownType: selectedType.id,
                labelReplaceTypeText,
                selectedObjects: [],
            },
            () => {
                this.clearSelection();
                if (callback) {
                    callback();
                }
            }
        );
    };

    onUseRelatedObjectsChanged = (useRelatedObjects) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Use related objects", {
            Checked: useRelatedObjects,
            "Change type": Object.keys(OBMC)[this.props.changeType],
        });*/
        API.setPreferences(USE_RELATED_PREFS_KEY, [useRelatedObjects], _.noop);
        this.updateStateAndNotify({ useRelatedObjects });
    };

    handleObjectSearchChange = (os) => {
        this.updateStateAndNotify({ objectSearch: os });
    };

    updateStateAndNotify = (newState) => {
        this.setState(newState, () => this.props.onSelectionChanged(this.state));
    };

    renderTypeTreeOptions = (typeTree, selectableTypeIds = []) => {
        const treeToTypeList = (tree, depth) => {
            if (!tree) {
                return [];
            }
            const subNodes = [];
            // eslint-disable-next-line no-param-reassign
            tree.depth = depth;
            subNodes.push(tree);
            if (tree.subtypes) {
                // eslint-disable-next-line no-param-reassign
                depth++;
                for (let i = 0; i < tree.subtypes.length; i++) {
                    subNodes.push(_.flatten(treeToTypeList(tree.subtypes[i], depth)));
                }
            }
            return _.flatten(subNodes);
        };
        return treeToTypeList(typeTree, 0).map((type) => {
            const indentation = [];
            for (let i = 0; i < type.depth; i++) {
                indentation.push("\u00a0\u00a0");
            }
            if (!type.id) {
                return null;
            }
            let disabled = false;
            if (selectableTypeIds.length > 0) {
                disabled = selectableTypeIds.indexOf(type.id) === -1;
            }
            if (disabled) {
                return null;
            }
            return (
                <option disabled={disabled} key={type.id} value={type.id}>
                    {indentation.join("")}
                    {type.name}
                </option>
            );
        });
    };

    getTypeName = (typeId) => {
        const type = _.find(this.state.types, (tp) => tp.id === typeId);
        return type ? type.name : String(typeId);
    };

    clearSelection = () => {
        if (this._clearSelection) {
            this._clearSelection();
        }
    };

    registerClearSelection = (clearFunction) => {
        this._clearSelection = clearFunction;
    };

    render = () => (
        <div className="massRemovePanel">
            <div className="selectedObjectList objectSelectList">
                <select
                    className="replacePanelSelectList"
                    value={this.state.dropDownType}
                    onChange={this.changeType}
                >
                    {this.renderTypeTreeOptions(
                        this.state.typeTree,
                        this.state.allTypes.map((tp) => tp.id)
                    )}
                </select>

                <ObjectSelect
                    width={"100%"}
                    selectedType={this.state.selectedType}
                    onObjectSearcherChange={this.handleObjectSearchChange}
                    selectedFluffyItem={null}
                    onClick={null}
                    onObjectInfo={null}
                    addReloadFunction={null}
                    ref="objectSelection"
                    onRowHover={null}
                    hoverButton={null}
                    modalKey={"allowSecondModal"}
                    reservationIds={this.props.reservationIds}
                    isStatic={false}
                    staticObjects={null}
                    occuringObjects={null}
                    disabled={this.state.removeAllOfType}
                    highlightSelection={false}
                    allowMultiSelection={false}
                    setClearSelection={this.registerClearSelection}
                    user={this.props.user}
                />
            </div>
            <span key="typeBox">
                <BooleanInput
                    defaultValue={this.state.useRelatedObjects}
                    onUpdate={this.onUseRelatedObjectsChanged}
                />{" "}
                {Language.get("nc_auto_object_use_related_objects")}
            </span>
        </div>
    );
}

module.exports = MassAssignObjectsDialog;
