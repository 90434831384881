import { MillenniumDateTime } from "@timeedit/millennium-time";
const _ = require("underscore");

const AvailabilityEntry = function (
    startTime,
    endTime,
    objects = [],
    clusterId = 0,
    periodIds = []
) {
    this.startTimes = [startTime];
    this.endTimes = [endTime];
    this.objects = objects;
    this.clusterId = clusterId;
    this.periodIds = periodIds;
    this.isAvailability = true;
};

AvailabilityEntry.prototype.getLength = function () {
    return this.endTimes[0].getMts() - this.startTimes[0].getMts() || 0;
};

AvailabilityEntry.prototype.hasTime = function () {
    return this.getLength() > 0;
};

AvailabilityEntry.prototype.hasSize = function () {
    return false;
};

AvailabilityEntry.prototype.equals = function (slot) {
    return (
        this.clusterId === slot.clusterId &&
        _.isEqual(this.periodIds, slot.periodIds) &&
        _.every(this.startTimes, (time, i) => time.equals(slot.startTimes[i])) &&
        _.every(this.endTimes, (time, i) => time.equals(slot.endTimes[i])) &&
        _.isEqual(this.objects, slot.objects)
    );
};

AvailabilityEntry.prototype.isLocked = function () {
    return false;
};

AvailabilityEntry.create = function (data) {
    return new AvailabilityEntry(
        new MillenniumDateTime(data.begin || 0),
        new MillenniumDateTime(data.end || 0),
        data.objects,
        data.clusterId,
        data.periodIds
    );
};

module.exports = AvailabilityEntry;
