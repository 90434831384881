const React = require("react");
const createReactClass = require("create-react-class");
const Language = require("../lib/Language");
const NumberInput = require("./NumberInput");
const MultiSelect = require("./MultiSelect");
const ListSettingsStore = require("./ListSettingsStore");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const RC = require("../lib/ReservationConstants");

// eslint-disable-next-line no-unused-vars
const track = (type, value) => {
    // eslint-disable-next-line no-undef
    /*mixpanel.track("OL settings change", {
        Type: type,
        Value: value,
    });*/
};

const getStatus = () => [
    {
        label: `${Language.get("order_status_row_partial")} (<)`,
        trackingId: "Partial",
        id: 1,
        isDefault: true,
    },
    {
        label: `${Language.get("order_status_row_fulfilled")} (=)`,
        trackingId: "Fulfilled",
        id: 2,
        isDefault: true,
    },
    {
        label: `${Language.get("order_status_row_exceeded")} (>)`,
        trackingId: "Exceeded",
        id: 3,
        isDefault: true,
    },
    { label: `${Language.get("order_status_row_unlimited")} (o)`, trackingId: "Unlimited", id: 4 },
    { label: `${Language.get("order_status_passive")} (-)`, trackingId: "Passive", id: 5 },
    { label: Language.get("order_status_not_completed"), trackingId: "Not completed", id: 102 },
    { label: Language.get("order_status_completed"), trackingId: "Completed", id: 101 },
    {
        label: `${Language.get("dynamic_reserv_list_delivered")} = 0`,
        trackingId: "Delivered = 0",
        id: 111,
    },
    {
        label: `${Language.get("dynamic_reserv_list_delivered")} <> 0`,
        trackingId: "Delivered <> 0",
        id: 112,
    },
];

const getSearch = () => ({
    0: { id: 0, label: Language.get("cal_order_side_order_objects"), trackingId: "Order objects" },
    1: {
        id: 1,
        label: Language.get("dynamic_reserv_list_mandatory_object"),
        trackingId: "Mandatory object",
    },
    2: {
        id: 2,
        label: Language.get("dynamic_reserv_list_customer_object"),
        trackingId: "Customer object",
    },
    3: {
        id: 3,
        label: Language.get("dynamic_reserv_list_owner_object"),
        trackingId: "Owner object",
    },
    4: {
        id: 4,
        label: Language.get("cal_reservation_list_column_order_additional_objects"),
        trackingId: "Additional objects",
    },
});

const getIncludeMembers = () => ({
    0: { id: 0, label: Language.get("dynamic_reserv_list_exclude"), trackingId: "Exclude" }, //"MEMBER_PARENT_GROUPS", // Föräldergrupper
    1: { id: 1, label: Language.get("dynamic_reserv_list_include"), trackingId: "Include" }, //"MEMBER_SUBGROUPS", // Föräldergrupper + undergrupper
    2: { id: 2, label: Language.get("dynamic_reserv_list_obstacles"), trackingId: "Obstacles" }, //"MEMBER_OBSTACLES", // Föräldergrupper + undergrupper + förhinder
    3: {
        id: 3,
        label: Language.get("dynamic_reserv_list_members_only"),
        trackingId: "Members only",
    }, //"MEMBER_NO_GROUPS", // Inga grupper
    4: {
        id: 4,
        label: Language.get("dynamic_reserv_list_obstacles_only"),
        trackingId: "Obstacles only",
    }, //"MEMBER_OBSTACLES_NO_SUBGROUPS", // Föräldergrupper + förhinder
});

const OrderListSettings = createReactClass({
    displayName: "OrderListSettings",

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.defaultSettings, this.props.defaultSettings)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(this.getInitialState(this.props));
        }
        if (!_.isEqual(this.state, prevState)) {
            const settings = _.clone(this.state);
            this.props.onChange(settings);
        }
    },

    getInitialState() {
        return {
            // Search options
            orderStatus: this.props.orderStatus,
            objectSearch: this.props.objectSearch,
            permission: this.props.permission,
            useFactor: this.props.factor > 1,
            factor: this.props.factor,
            includeMembers: this.props.includeMembers,
            objectCategories: this.props.objectCategories || [],
            descriptionField: this.props.descriptionField || null,
            commentField: this.props.commentField || null,
        };
    },

    onDescriptionFieldChange(event) {
        track("Description field", event.target.value);
        this.setState({ descriptionField: parseInt(event.target.value, 10) || null });
    },

    onCommentFieldChange(event) {
        track("Comment field", event.target.value);
        this.setState({ commentField: parseInt(event.target.value, 10) || null });
    },

    onCompleteChange(event) {
        track("Complete", event.target.value);
        this.setState({ complete: event.target.value === "true" });
    },

    onObjectSearchChange(values) {
        track(
            "Object search",
            values.map((value) => _.find(getSearch(), (st) => st.id === value).trackingId)
        );
        this.setState({ objectSearch: values });
    },

    onStatusChange(values) {
        track(
            "Reservation status",
            values.map((value) => _.find(getStatus(), (st) => st.id === value).trackingId)
        );
        this.setState({ orderStatus: values });
    },

    onPermissionChange(event) {
        track("Permission", parseInt(event.target.value, 10) === 0 ? "Read" : "Modify");
        this.setState({ permission: parseInt(event.target.value, 10) });
    },

    saveFactor(newFactor) {
        API.setPreferences("orderListFactor", [`${newFactor}`], _.noop);
    },

    onFactorToggle() {
        const newState = { useFactor: !this.state.useFactor };
        track("Use factor", newState.useFactor);
        if (!newState.useFactor) {
            newState.factor = 1;
        } else {
            newState.factor = 36;
        }
        if (newState.factor !== this.state.factor) {
            this.saveFactor(newState.factor);
        }
        this.setState(newState);
    },

    onFactorChange(newFactor) {
        track("Factor", newFactor);
        if (newFactor !== this.state.factor) {
            this.saveFactor(newFactor);
            this.setState({ factor: newFactor });
        }
    },

    onIncludeMembersChange(event) {
        const value = parseInt(event.target.value, 10);
        track("Include members", _.find(getIncludeMembers(), (st) => st.id === value).trackingId);
        this.setState({ includeMembers: value });
    },

    onObjectCheckboxChange(fieldId, isReservationFields, event) {
        let value = event.target.value;
        if (value === "1") {
            value = ["1"];
        } else if (value === "0") {
            value = ["0"];
        } else {
            value = [];
        }
        this.onCategoriesChange(fieldId, isReservationFields, value);
    },

    onCategoriesChange(fieldId, isReservationFields, values) {
        const newValues = {};
        newValues[String(fieldId)] = values;
        if (isReservationFields) {
            const finalValues = _.omit(
                _.extend({}, this.state.reservationCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            track("Reservation categories", finalValues);
            this.setState({
                reservationCategories: finalValues,
            });
        } else {
            const finalValues = _.omit(
                _.extend({}, this.state.objectCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            track("Object categories", finalValues);
            this.setState({
                objectCategories: finalValues,
            });
        }
    },

    isCategorySelected(fieldId, option, isReservationFields) {
        if (isReservationFields) {
            return (
                this.state.reservationCategories[String(fieldId)] &&
                this.state.reservationCategories[String(fieldId)].indexOf(option) > -1
            );
        }
        return (
            this.state.objectCategories[String(fieldId)] &&
            this.state.objectCategories[String(fieldId)].indexOf(option) > -1
        );
    },

    getCheckboxValue(checkboxId) {
        const values = this.state.objectCategories[String(checkboxId)];
        if (values) {
            return values[0];
        }
        return "";
    },

    _renderCategories(title, categories, isReservationFields = false) {
        if (!categories || categories.length === 0) {
            return null;
        }
        const options = categories.map((category) => {
            if (!category.categories) {
                return [];
            }
            return category.categories.map((opt) => ({
                value: opt,
                label: opt,
                selected: this.isCategorySelected(category.id, opt, isReservationFields),
            }));
        });
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {options.map((cats, index) => (
                    <div className="categoryList" key={index}>
                        <label>{categories[index].name}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={cats}
                            onValueChanged={this.onCategoriesChange.bind(
                                this,
                                categories[index].id,
                                isReservationFields
                            )}
                        />
                    </div>
                ))}
            </div>
        );
    },

    _renderFieldChooser(title, fields, selectedValue, onChange) {
        return (
            <div className="settingsLine">
                <label>{title}</label>
                <select onChange={onChange} value={selectedValue || ""}>
                    <option value={""}>-</option>
                    {fields.map((field) => (
                        <option key={field.id} value={field.id}>
                            {field.name} ({field.extid})
                        </option>
                    ))}
                </select>
            </div>
        );
    },

    _renderCheckboxes(title, checkboxes, isReservationFields = false) {
        if (!checkboxes || checkboxes.length === 0) {
            return null;
        }
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {checkboxes.map((checkbox, index) => {
                    const selectedValue = this.getCheckboxValue(checkbox.id);
                    return (
                        <div className="categoryList" key={index}>
                            <label>{checkbox.name} </label>
                            <select
                                onChange={this.onObjectCheckboxChange.bind(
                                    this,
                                    checkboxes[index].id,
                                    isReservationFields
                                )}
                                value={selectedValue}
                            >
                                <option value={""}>-</option>
                                <option value={1}>{Language.get("dynamic_field_yes")}</option>
                                <option value={0}>{Language.get("dynamic_field_no")}</option>
                            </select>
                        </div>
                    );
                })}
            </div>
        );
    },

    render() {
        const selectOptions = getStatus().map((option) => ({
            selected: this.state.orderStatus.indexOf(option.id) !== -1,
            value: option.id,
            label: option.label,
        }));

        const SPLICE_START = 3;
        selectOptions.splice(SPLICE_START, 0, {
            value: MultiSelect.DIVIDER,
            label: MultiSelect.DIVIDER,
        });

        selectOptions.splice(SPLICE_START + SPLICE_START, 0, {
            value: MultiSelect.DIVIDER,
            label: MultiSelect.DIVIDER,
        });

        selectOptions.splice(SPLICE_START * SPLICE_START, 0, {
            value: MultiSelect.DIVIDER,
            label: MultiSelect.DIVIDER,
        });

        const search = getSearch();
        const searchOptions = Object.keys(search).map((option, index) => ({
            selected: this.state.objectSearch.indexOf(index) !== -1,
            value: index,
            label: search[index].label,
        }));

        const includeMembers = getIncludeMembers();
        const memberOptions = Object.keys(includeMembers).map((option, index) => ({
            selected: this.state.includeMembers === index,
            value: index,
            label: includeMembers[index].label,
        }));

        return (
            <div>
                <h3 className="header">{Language.get("nc_list_search_settings_title")}</h3>

                <ListSettingsStore
                    searchSettings={this.state}
                    // eslint-disable-next-line no-shadow
                    onChange={(search) => this.setState(search)}
                    getSavedSettings={this.props.getSavedSettings}
                    saveSettings={this.props.saveSettings}
                    removeSettings={this.props.removeSettings}
                    saveDefaultSettings={this.props.saveDefaultSettings}
                />

                <div className="settingsContainer">
                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_members")}</label>
                        <select
                            onChange={this.onIncludeMembersChange}
                            value={this.state.includeMembers}
                        >
                            {memberOptions.map((o) => (
                                <option key={o.value} value={o.value}>
                                    {o.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_permission")}</label>
                        <select onChange={this.onPermissionChange} value={this.state.permission}>
                            <option value={RC.PERMISSION.READ} key={RC.PERMISSION.READ}>
                                {Language.get("dynamic_reserv_list_read")}
                            </option>
                            <option value={RC.PERMISSION.MODIFY} key={RC.PERMISSION.MODIFY}>
                                {Language.get("dynamic_reserv_list_modify")}
                            </option>
                        </select>
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_status_menu")}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={selectOptions}
                            onValueChanged={this.onStatusChange}
                        />
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_object_search")}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={searchOptions}
                            onValueChanged={this.onObjectSearchChange}
                        />
                    </div>

                    <hr />

                    {this._renderCategories(
                        Language.get("nc_lists_object_categories"),
                        this.props.availableObjectCategories,
                        false
                    )}

                    {this._renderCheckboxes(
                        Language.get("nc_lists_object_checkboxes"),
                        this.props.availableObjectCheckboxes,
                        false
                    )}

                    <hr />

                    {this._renderFieldChooser(
                        Language.get("nc_order_list_map_description_to"),
                        this.props.reservationFields,
                        this.state.descriptionField,
                        this.onDescriptionFieldChange
                    )}

                    {this._renderFieldChooser(
                        Language.get("nc_order_list_map_comment_to"),
                        this.props.reservationFields,
                        this.state.commentField,
                        this.onCommentFieldChange
                    )}

                    <div className="settingsLine">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={this.state.useFactor}
                                            onChange={this.onFactorToggle}
                                        />
                                    </td>
                                    <td>{Language.get("nc_order_list_use_minutes_per_week")}</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td>
                                        {Language.get("cal_res_side_view_num_weeks")}
                                        <NumberInput
                                            classNames={"smallInput"}
                                            disabled={!this.state.useFactor}
                                            defaultValue={this.state.factor}
                                            onUpdate={this.onFactorChange}
                                            decimals={1}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    },
});

OrderListSettings.getDefaultOrderStatus = function () {
    return getStatus()
        .filter((status) => status.isDefault === true)
        .map((status) => status.id);
};

module.exports = OrderListSettings;
