const React = require("react");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

class LoadIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "LoadIndicator";
        this.state = {
            loadsInProgress: [],
            slowLoads: [],
        };
    }

    componentDidMount() {
        const self = this;
        const dispatcher = API.getMessageDispatcher();
        const loadStarted = (data) => {
            this.setState({ loadsInProgress: this.state.loadsInProgress.concat([data.id]) });
        };
        const loadFinished = (data) => {
            self.setState({
                loadsInProgress: _.filter(this.state.loadsInProgress, (id) => id !== data.id),
                slowLoads: _.filter(this.state.slowLoads, (id) => id !== data.id),
            });
        };
        const slowLoadDetected = (data) => {
            this.setState({ slowLoads: this.state.slowLoads.concat([data.id]) });
        };
        dispatcher.on("requestSent", loadStarted);
        dispatcher.on("slowerResponse", slowLoadDetected);
        dispatcher.on("complete", loadFinished);
        dispatcher.on("timeout", loadFinished);
        dispatcher.on("abort", loadFinished);
        dispatcher.on("error", loadFinished);
    }

    render() {
        if (this.state.loadsInProgress.length === 0) {
            return null;
        }
        if (this.state.slowLoads.length === 0) {
            return null;
        }
        return <div style={this.props.style} className="loadIndicator" />;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.loadsInProgress.length !== nextState.loadsInProgress.length ||
            this.state.slowLoads.length !== nextState.slowLoads.length ||
            !_.isEqual(this.props.style, nextProps.style)
        );
    }
}

module.exports = LoadIndicator;
