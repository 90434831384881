const React = require("react");
const TokenInput = require("./react-tokeninput/main");
const ComboboxOption = require("./react-tokeninput/option");
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const Log = require("../lib/Log");

const DEBOUNCE_FIND_TAGS_TIME = 200;

const contentElements = (fromValues = [], currentInput) => {
    const result = [];
    if (currentInput && currentInput !== "") {
        result.push(
            <ComboboxOption
                key={currentInput}
                value={currentInput}
                label={currentInput}
                isfocusable="true"
                isselected="false"
            >
                {currentInput}
            </ComboboxOption>
        );
    }
    return result.concat(
        fromValues
            .filter((value) => value !== currentInput)
            .map((value) => (
                <ComboboxOption
                    key={value}
                    value={value}
                    label={value}
                    isfocusable="true"
                    isselected="false"
                >
                    {value}
                </ComboboxOption>
            ))
    );
};

const selectedTokens = (fromTags = []) =>
    fromTags.map((tag) => ({
        name: tag,
        id: tag,
    }));

class TagInput extends React.Component {
    state = {
        tags: [],
        selectedTags: [],
        isLoading: false,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ selectedTags: this.props.tags || [] });
        this.loadMyTags();
    }

    componentDidUpdate(prevProps) {
        if (
            !_.isEqual(prevProps.tags, this.props.tags) ||
            !_.isEqual(prevProps.reservationIds, this.props.reservationIds)
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ selectedTags: this.props.tags || [] });
            this.loadMyTags();
        }
    }

    loadMyTags() {
        API.findMyTags("", (result) => {
            this.setState({
                tags: result.tags.filter((tag) => this.state.selectedTags.indexOf(tag) === -1),
            });
        });
    }

    onSelect(value) {
        if (this.state.tags.indexOf(value) === -1) {
            API.createTag(value, (createResult) => {
                // eslint-disable-next-line no-console
                console.log(createResult);
                if (createResult.details) {
                    Log.error(createResult.details);
                    return;
                }
                API.addTagToReservations(this.props.reservationIds, value, (result) => {
                    // eslint-disable-next-line no-console
                    console.log(result);
                    if (result[0].details) {
                        Log.error(result[0].details);
                    }
                });
            });
        } else {
            API.addTagToReservations(this.props.reservationIds, value, (result) => {
                // eslint-disable-next-line no-console
                console.log(result);
                if (result[0].details) {
                    Log.error(result[0].details);
                }
            });
        }
        this.setState({
            selectedTags: [...this.state.selectedTags, value],
            tags: this.state.tags.filter((tag) => tag !== value),
        });
    }

    onRemove(value) {
        if (!value) {
            return;
        }
        this.setState({
            selectedTags: this.state.selectedTags.filter((tag) => tag !== value.id),
            tags: [...this.state.tags, value.id],
        });
        API.removeTagFromReservations(this.props.reservationIds, value.id, (result) => {
            // eslint-disable-next-line no-console
            console.log(result);
            if (result[0].details) {
                Log.info(result[0].details);
            }
        });
    }

    debounceFindTags = _.debounce((value) => {
        API.findTags(value, (result) => {
            this.setState({
                tags: result.tags.filter((tag) => this.state.selectedTags.indexOf(tag) === -1),
            });
        });
    }, DEBOUNCE_FIND_TAGS_TIME);

    _currentInput = "";

    onInput(value) {
        this._currentInput = value;
        this.debounceFindTags(value);
    }

    render() {
        return (
            <TokenInput
                showListOnFocus={true}
                onInput={this.onInput.bind(this)}
                onSelect={this.onSelect.bind(this)}
                onRemove={this.onRemove.bind(this)}
                selected={selectedTokens(this.state.selectedTags)}
                menuContent={contentElements(this.state.tags, this._currentInput)}
                placeholder={Language.get("nc_tags_type_to_search")}
            />
        );
    }
}

module.exports = TagInput;
