const React = require("react");
const ObjectList = require("./ObjectList");
const ObjectSettingsConstants = require("../lib/ObjectSettingsConstants");
const _ = require("underscore");

class ObjectListWithDropdown extends React.Component {
    render() {
        let items = this.props.items || [];
        let dropdown = null;
        let selection = null;
        const onOptionSelected = this.props.onOptionSelected || _.noop;
        const onItemsChanged = this.props.onItemsChanged || _.noop;
        const dropdownOptions = this.props.dropdownOptions;
        const onSelection = this.props.onSelection || _.noop;
        const preserveOrder = this.props.preserveOrder || false;

        const onItemClick = function (index, rowData, event, object) {
            if (object) {
                onSelection(object);
            } else {
                selection = parseInt(index.target ? index.target.value : index, 10);
                onSelection(items[selection]);
            }
        };
        const onMinusClick = function () {
            const value = selection;
            items = items.filter((item) => {
                if (item instanceof Array) {
                    return value !== item.map((subItem) => subItem.id).join(",");
                }
                return item.id !== parseInt(value, 10);
            });
            onItemsChanged(items);
        };
        if (dropdownOptions && dropdownOptions.options instanceof Array) {
            const onSelect = (event) => onOptionSelected(event, items);
            const options = dropdownOptions.options.map((item) => {
                if (item.options) {
                    return (
                        <optgroup key={item.name} label={item.name}>
                            {item.options.map((subOption) => (
                                <option key={subOption.id} value={`${item.id}:${subOption.id}`}>
                                    {subOption.name || subOption.extid}
                                </option>
                            ))}
                        </optgroup>
                    );
                }
                return (
                    <option key={item.id} value={item.id}>
                        {item.name || item.extid}
                    </option>
                );
            }, this);
            if (dropdownOptions.headlineValue) {
                options.splice(
                    0,
                    0,
                    <option key={-1} value={"DUMMY"}>
                        {dropdownOptions.headlineValue}
                    </option>
                );
            }
            dropdown = (
                <select
                    disabled={!this.props.editable}
                    className="optionDropdown"
                    onChange={onSelect}
                >
                    {options}
                </select>
            );
        }
        items = items || [];
        const options = items.map((item) => {
            // Use pure ids, not TimeEdit JSON objects.
            if (item instanceof Array) {
                // Related objects is an array of arrays.
                // eslint-disable-next-line no-param-reassign
                item = item[0];
            }
            return item.id;
        });
        let editButton = null;
        if (this.props.editable && !this.props.hideEditButton) {
            editButton = <div className="minusIcon" onClick={onMinusClick} />;
            if (this.props.propertyName !== null) {
                editButton = <div className="editIcon" onClick={this.props.editFunction} />;
            }
        }

        let searchObject = this.props.searchObject;
        const objectToNameAndId = (object) => {
            const name =
                object.fields && object.fields[0].values
                    ? object.fields[0].values.join(", ")
                    : object.extid || object.id;
            const appendix = this.props.presentMembershipPeriod
                ? this.props.presentMembershipPeriod(object.id)
                : "";

            const label = appendix ? `${name} ${appendix}` : name;
            return _.extend({}, object, {
                label,
            });
        };

        const sort = function (objects) {
            if (!preserveOrder) {
                return [...objects];
            }
            let result = [];
            objects.forEach((object) => {
                result[options.indexOf(object.id)] = object;
            });
            return result;
        };

        const loadFunction = function (objects, callback) {
            if (searchObject) {
                if (searchObject.parentValue) {
                    // eslint-disable-next-line no-param-reassign
                    objects = this.props
                        .getAvailabilityObjects(searchObject.parentValue)
                        .map((object) => object.id);
                }
                searchObject = searchObject.immutableSet({
                    searchObjects: objects,
                    excludeObjects: [],
                    otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE,
                });

                searchObject.search(0, (result) => {
                    callback(sort(result.map(objectToNameAndId)));
                });
            } else {
                callback(sort(items.map(objectToNameAndId)));
            }
        }.bind(this);

        let title = "";
        if (searchObject && searchObject.defaultColumns && searchObject.defaultColumns.length > 0) {
            title = searchObject.defaultColumns[0].name;
        }

        const flexStyle = this.props.flex ? { display: "flex", flex: "1 1 auto" } : {};
        return (
            <div style={flexStyle}>
                {editButton}
                {dropdown}
                {this.props.extraRightSideMenuButton}
                <ObjectList
                    flex={this.props.flex}
                    columnTitle={title}
                    listSize={3}
                    cssClass={"definitionList"}
                    loadFunction={loadFunction}
                    searchObject={searchObject}
                    cellRenderers={this.props.cellRenderers || undefined}
                    updateSearchObject={this.props.updateSearchObject}
                    totalNumber={this.props.totalNumber}
                    objects={options}
                    hidePlusButton={this.props.hidePlusButton}
                    onChange={this.props.editable ? onItemClick : _.noop}
                    presentMembershipPeriod={this.props.presentMembershipPeriod}
                    hoverButton={this.props.hoverButton}
                    allowMultiSelection={this.props.allowMultiSelection || false}
                />
            </div>
        );
    }
}

module.exports = ObjectListWithDropdown;
