const PropTypes = require("prop-types");
const React = require("react");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const Language = require("../lib/Language");
const Log = require("../lib/Log");
const ArrayInput = require("./ArrayInput");
const MultiSelect = require("./MultiSelect");
const RangeInput = require("./RangeInput");
const _ = require("underscore");
const ReservationStatus = require("../lib/ReservationStatus");
const Viewer = require("../lib/Viewer");

const LISTABLE_STATUSES = [
    ReservationStatus.UNKNOWN,
    ReservationStatus.C_CONFIRMED,
    ReservationStatus.C_PRELIMINARY,
    ReservationStatus.C_PLANNED,
];

const EMAIL_STATUS = {
    NONE: 0,
    ACCEPTED_AND_REJECTED: 30,
    REJECTED_ONLY: 34,
};

const FSL = {
    OFF: 0,
    INLINE: 1,
    NEXT_TO: 2,
};

const DIVIDER = "__DIVIDER__";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "Account";
        this.state = {
            langs: [],
            dateFormats: [],
            reservationStatus: [],
            settings: {},
            passwords: {},
            timezones: [],
            displayTimezones: [],
            viewerPages: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        Viewer.findPages(this.context.user.language, (pages) =>
            this.setState({ viewerPages: pages })
        );
        API.getTimezones((timezones) => {
            if (this._isMounted) {
                this.setState({ timezones });
            }
        });
        API.getLanguages((langs) => {
            if (this._isMounted) {
                langs.unshift({ id: "", label: Language.get("cal_res_side_user_lang_system") });
                this.setState({ langs });
            }
        }, true);
        API.getLanguages((langs) => {
            if (this._isMounted) {
                langs.unshift({ id: "", label: Language.get("cal_res_side_user_lang_system") });
                this.setState({ dateFormats: langs });
            }
        }, false);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getString(key) {
        return Language.get(key);
    }

    getValue(field) {
        if (this.state.settings.hasOwnProperty(field)) {
            return this.state.settings[field];
        }

        return this.context.user[field];
    }

    setValue(item, event) {
        if (item.field === "showLists") {
            this.context.toggleSelection();
        }
        const obj = _.clone(this.state.settings);
        let value;
        if (item.type === "boolean") {
            value = event.target.checked;
        } else if (item.type === "array") {
            value = event;
        } else {
            value = event.target.value;
        }
        if (item.field === "fieldsInSelectionOptions") {
            if (value === FSL.OFF) {
                obj.fieldsInSelection = false;
                obj.fieldsNextToSelection = false;
            }
            if (value === FSL.INLINE) {
                obj.fieldsInSelection = true;
                obj.fieldsNextToSelection = false;
            }
            if (value === FSL.NEXT_TO) {
                obj.fieldsInSelection = true;
                obj.fieldsNextToSelection = true;
            }
        } else {
            obj[item.field] = value;
        }
        this.setState({ settings: obj });
    }

    hasChanges() {
        const user = this.context.user;
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in user) {
            if (
                user.hasOwnProperty(prop) &&
                this.state.settings[prop] !== undefined &&
                user[prop] !== this.state.settings[prop]
            ) {
                return true;
            }
        }
        return this.state.settings.newPassword !== null;
    }

    getFields() {
        const divider = { label: DIVIDER, type: DIVIDER };
        const renderEntry = (entry, list) => {
            const item = {
                value: entry.name,
                label: entry.name,
                disabled: true,
                selected: false,
            };
            return [item, ...list];
        };
        const renderViewerPages = (options) =>
            options
                .filter((option) => option.type === "ScheduleCreationController")
                .map((option) => ({
                    value: option.urlstart,
                    label: option.name,
                    selected: this.getValue("viewerPageCurrent") === option.urlstart,
                }));
        const emptyItem = {
            value: "",
            label: "",
            selected: false,
        };
        const renderAllPages = () => {
            const pages = _.flatten(
                this.state.viewerPages.map((entry) =>
                    renderEntry(entry, renderViewerPages(entry.pages))
                )
            );
            if (pages.length === 0) {
                return [];
            }
            return [emptyItem, ...pages];
        };
        let viewerPageCurrent = null;
        if (Viewer.isEnabled(this.context.env)) {
            try {
                const pages = renderAllPages();
                if (pages.length > 0) {
                    viewerPageCurrent = {
                        label: "nc_open_in_te_viewer",
                        field: "viewerPageCurrent",
                        type: "array",
                        values: pages,
                    };
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                viewerPageCurrent = null;
            }
        }
        const enableMultiSelect = {
            label: "nc_enable_multi_select",
            field: "enableMultiSelect",
            type: "boolean",
        };
        const timezones =
            this.state.timezones.length > 1
                ? {
                      label: "cal_res_side_user_time_zone",
                      field: "timezone",
                      type: "array",
                      values: this.state.timezones.map((zone) => ({
                          value: zone.shortName,
                          label:
                              zone.name !== zone.shortName
                                  ? `${zone.shortName} ${zone.name}`
                                  : zone.shortName,
                          selected: this.getValue("timezone") === zone.shortName,
                      })),
                  }
                : null;
        const displayTimezones =
            this.state.timezones.length > 1
                ? {
                      label: "nc_other_timezones",
                      field: "displayTimezones",
                      tooltip: "nc_other_timezones_help",
                      multiselect: true,
                      type: "array",
                      values: this.state.timezones.map((zone) => ({
                          value: zone.shortName,
                          label:
                              zone.name !== zone.shortName
                                  ? `${zone.shortName} ${zone.name}`
                                  : zone.shortName,
                          selected: _.contains(this.getValue("displayTimezones"), zone.shortName),
                          disabled: this.getValue("timezone") === zone.shortName,
                      })),
                  }
                : null;
        const useCustomWeekNames =
            this.context.showEnableCustomWeekNames === true
                ? {
                      label: "nc_use_custom_week_names",
                      field: "useCustomWeekNames",
                      type: "boolean",
                  }
                : null;
        const createGroupsForTracks =
            this.context.prefsComponentActive === true && this.context.useNewReservationGroups
                ? {
                      label: "nc_create_groups_for_reservation_tracks",
                      field: "createGroupsForReservationTracks",
                      type: "boolean",
                  }
                : null;
        const promptForGroupName = {
            label: "nc_prompt_for_group_name",
            field: "promptForGroupName",
            type: "boolean",
        };
        const fields = [
            { label: "cal_res_side_user_login", field: "login", disabled: true },
            {
                label: "cal_res_side_user_first_name",
                field: "firstName",
                disabled: this.context.usingSSO,
            },
            {
                label: "cal_res_side_user_last_name",
                field: "lastName",
                disabled: this.context.usingSSO,
            },
            {
                label: "cal_res_side_user_email_title",
                field: "email",
                disabled: this.context.usingSSO,
            },
            {
                label: "nc_cal_res_side_user_request_email",
                field: "emailStatus",
                type: "array",
                values: [
                    {
                        value: EMAIL_STATUS.ACCEPTED_AND_REJECTED,
                        label: Language.get("cal_res_side_user_email_accepted_and_rejected"),
                        selected:
                            this.getValue("emailStatus") === EMAIL_STATUS.ACCEPTED_AND_REJECTED,
                    },
                    {
                        value: EMAIL_STATUS.REJECTED_ONLY,
                        label: Language.get("cal_res_side_user_email_rejected_only"),
                        selected: this.getValue("emailStatus") === EMAIL_STATUS.REJECTED_ONLY,
                    },
                    {
                        value: EMAIL_STATUS.NONE,
                        label: Language.get("cal_res_side_user_email_none"),
                        selected: this.getValue("emailStatus") === EMAIL_STATUS.NONE,
                    },
                ],
            },
            {
                label: "cal_res_side_user_reservation_status_title",
                field: "reservationStatus",
                type: "array",
                values: LISTABLE_STATUSES.map((status) => ({
                    value: status.id,
                    label: status.getStatusName(),
                    selected: this.getValue("reservationStatus") === status.id,
                })),
            },
            divider,
            {
                label: "cal_res_side_user_language",
                field: "language",
                type: "array",
                values: this.state.langs.map((lang) => ({
                    value: lang.id,
                    label: lang.label,
                    selected: this.getValue("language") === lang.id,
                })),
            },
            {
                label: "cal_res_side_user_date_format",
                field: "dateFormat",
                type: "array",
                values: this.state.dateFormats.map((format) => ({
                    value: format.id,
                    label: format.label,
                    selected: this.getValue("dateFormat") === format.id,
                })),
            },
            timezones,
            displayTimezones,
            divider,
            {
                label: "cal_res_side_user_single_click_res",
                field: "allowSingleClickReservation",
                type: "boolean",
            },
            {
                label: "cal_res_side_user_jump_to_next_type",
                field: "jumpToNextType",
                type: "boolean",
            },
            { label: "nc_cal_res_side_user_mute", field: "mute", type: "boolean" },
            {
                label: "nc_show_confirm_entry_removal_warning",
                field: "showConfirmEntryRemovalWarning",
                type: "boolean",
            },
            { label: "menu_view_additional_info", field: "showExtraInfo", type: "boolean" },
            {
                label: "nc_tooltip_in_sidebar",
                field: "tooltipInSidebar",
                type: "boolean",
            },
            {
                label: "menu_view_lists",
                field: "showLists",
                type: "boolean",
            },
            enableMultiSelect,
            {
                label: "nc_reservation_text_size",
                field: "fontSize",
                type: "range",
            },
            {
                label: "nc_use_larger_text",
                field: "biggerFont",
                type: "boolean",
            },
            createGroupsForTracks,
            promptForGroupName,
            divider,
            useCustomWeekNames,
            divider,
            {
                label: "nc_fields_in_selection_list_title",
                field: "fieldsInSelectionOptions",
                type: "array",
                values: [
                    {
                        label: Language.get("nc_fields_in_selection_list_off"),
                        value: FSL.OFF,
                        selected:
                            this.getValue("fieldsInSelection") === false &&
                            this.getValue("fieldsNextToSelection") === false,
                    },
                    {
                        label: Language.get("nc_fields_in_selection_list"),
                        value: FSL.INLINE,
                        selected:
                            this.getValue("fieldsInSelection") === true &&
                            this.getValue("fieldsNextToSelection") === false,
                    },
                    {
                        label: Language.get("nc_fields_next_to_selection_list"),
                        value: FSL.NEXT_TO,
                        selected:
                            this.getValue("fieldsInSelection") === true &&
                            this.getValue("fieldsNextToSelection") === true,
                    },
                ],
            },
            divider,
            viewerPageCurrent,
            divider,
        ];
        return fields;
    }

    abortChanges(event) {
        this.setState({ settings: {} });
        event.preventDefault();
    }

    saveChanges(event) {
        const user = this.context.user;
        const obj = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in user) {
            if (user.hasOwnProperty(prop)) {
                obj[prop] = this.getValue(prop);
            }
        }

        const self = this;
        const reloadPage =
            user.language !== obj.language ||
            user.dateFormat !== obj.dateFormat ||
            user.timezone !== obj.timezone ||
            user.useCustomWeekNames !== obj.useCustomWeekNames ||
            !_.isEqual(user.timezones, obj.timezones);
        const updatedUser = user.immutableSet(obj);
        if (obj.biggerFont) {
            document.body.classList.add("bigger");
        } else {
            document.body.classList.remove("bigger");
        }
        updatedUser.save((success) => {
            console.log(success);
            if (!success) {
                Log.warning(Language.get("reset_password_could_not_send_message"));
                return;
            }
            Log.info(Language.get("nc_account_settings_updated."));
            self.context.update(user, updatedUser);
            self.setState({ settings: {} });
            if (reloadPage) {
                document.location.reload(true);
            }
        });
        event.preventDefault();
    }

    onPasswordChange(type, event) {
        const passwords = _.clone(this.state.passwords);
        passwords[type] = event.target.value;
        this.setState({ passwords });
    }

    updatePassword(event) {
        event.preventDefault();

        const currentPassword = this.state.passwords.current;
        const newPassword = this.state.passwords.new;
        if (!this.hasPasswordInput()) {
            return;
        }

        API.updatePassword(currentPassword, newPassword, (result) => {
            const res = result.parameters;
            if (res[0] === false) {
                res.shift();
                Log.warning(res.join("\n"));
            } else {
                Log.info(Language.get("nc_client_side_user_menu_password_changed"));
            }
            this.setState({ passwords: {} });
        });
    }

    hasPasswordInput() {
        return !this.context.usingSSO && this.state.passwords.current && this.state.passwords.new;
    }

    logout(event) {
        TimeEdit.logoutUser(this.context.ssoLogout);
        event.preventDefault();
    }

    getUserName() {
        const user = this.context.user;
        if (user.firstName || user.lastName) {
            return `${user.firstName} ${user.lastName}`;
        }
        return user.login;
    }

    render() {
        return (
            <div className="accountPane">
                <h2>{this.getUserName()}</h2>

                <form>
                    <ul>
                        {this.getFields().map(this.renderField.bind(this))}
                        <li className="btnGroup horizontal">
                            <button
                                className="left default cancel"
                                disabled={!this.hasChanges()}
                                onClick={this.abortChanges.bind(this)}
                            >
                                {this.getString("nc_cal_res_side_user_cancel")}
                            </button>
                            <button
                                className="save"
                                disabled={!this.hasChanges()}
                                onClick={this.saveChanges.bind(this)}
                            >
                                {this.getString("cal_res_side_user_save")}
                            </button>
                        </li>
                    </ul>

                    <h3>{this.getString("cal_res_side_user_password")}</h3>
                    {this.renderPasswordSettings()}
                </form>
                <div className="btnGroup horizontal">
                    <button
                        className="default logout fiftyPercentWide"
                        onClick={this.logout.bind(this)}
                    >
                        {Language.get("nc_menu_log_out")}
                    </button>
                </div>
            </div>
        );
    }

    renderPasswordSettings() {
        return (
            <ul>
                <li>
                    <label>
                        <span>{this.getString("nc_cal_res_side_user_current_password")}</span>
                        <input
                            type="password"
                            ref="currentPassword"
                            value={this.state.passwords.current}
                            disabled={this.context.usingSSO}
                            onChange={this.onPasswordChange.bind(this, "current")}
                        />
                    </label>
                </li>
                <li>
                    <label>
                        <span>{this.getString("nc_cal_res_side_user_new_password")}</span>
                        <input
                            type="password"
                            ref="newPassword"
                            value={this.state.passwords.new}
                            disabled={this.context.usingSSO}
                            onChange={this.onPasswordChange.bind(this, "new")}
                        />
                    </label>
                </li>
                <li className="btnGroup horizontal">
                    <button
                        className="save fiftyPercentDynamic"
                        disabled={!this.hasPasswordInput()}
                        onClick={this.updatePassword.bind(this)}
                    >
                        {this.getString("user_pane_change_password")}
                    </button>
                </li>
            </ul>
        );
    }

    renderField(item, index) {
        if (item === null) {
            return null;
        }
        const onChange = this.setValue.bind(this, item);
        const isDisabled = item.disabled === true;

        if (item.type === DIVIDER) {
            return <li key={index} />;
        }

        if (item.type === "boolean") {
            return (
                <li key={item.field}>
                    <label>
                        <input
                            type="checkbox"
                            checked={this.getValue(item.field)}
                            disabled={isDisabled}
                            onChange={onChange}
                        />
                        <span className={"settingsLabel"}>
                            {item.dontLocalize ? item.label : this.getString(item.label)}
                        </span>
                    </label>
                </li>
            );
        }

        let input = (
            <input
                type={item.type === "password" ? "password" : "text"}
                value={this.getValue(item.field)}
                onChange={onChange}
                disabled={isDisabled}
            />
        );
        if (item.type === "array") {
            if (item.multiselect === true) {
                input = (
                    <MultiSelect
                        className="multiSelect"
                        options={item.values}
                        onValueChanged={onChange}
                    />
                );
            } else {
                input = (
                    <ArrayInput
                        defaultValue={item.values}
                        disabled={isDisabled}
                        onUpdate={onChange}
                        defaultSize={item.defaultSize || 0}
                        limit={item.limit || 1}
                    />
                );
            }
        }

        if (item.type === "range") {
            input = (
                <RangeInput
                    defaultValue={this.getValue(item.field)}
                    onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        this.context.update(
                            this.context.user,
                            this.context.user.setFontSize(value)
                        );
                        onChange(event);
                    }}
                />
            );
        }

        if (item.type === "array" && item.multiselect === true) {
            return (
                <span key={item.field}>
                    <li key={`${item.field}:label`}>
                        <label
                            title={
                                item.tooltip
                                    ? Language.get(item.tooltip)
                                    : this.getString(item.label)
                            }
                        >
                            <span>
                                {item.dontLocalize ? item.label : this.getString(item.label)}
                            </span>{" "}
                        </label>
                    </li>
                    <li key={`${item.field}:input`}>{input}</li>
                </span>
            );
        }

        return (
            <li key={item.field}>
                <label
                    title={item.tooltip ? Language.get(item.tooltip) : this.getString(item.label)}
                >
                    <span>{item.dontLocalize ? item.label : this.getString(item.label)}</span>{" "}
                    {input}
                </label>
            </li>
        );
    }
}

Account.contextTypes = {
    update: PropTypes.func,
    user: PropTypes.object,
    showEnableCustomWeekNames: PropTypes.bool,
    toggleSelection: PropTypes.func,
    usingSSO: PropTypes.bool,
    ssoLogout: PropTypes.func,
    env: PropTypes.object,
    prefsComponentActive: PropTypes.bool,
    useNewReservationGroups: PropTypes.bool,
};

module.exports = Account;
