const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
import { MillenniumDateTime } from "@timeedit/millennium-time";
const Limits = require("./Limits");
const Settings = require("./Settings");

const DEFAULT_LIST_ID = 7;

const PrefsData = function (
    limits = Limits.getDefaultList(),
    selection = null,
    reservationIds = []
) {
    Model.call(this, "PrefsData");
    this.templateKind = TemplateKind.RESERVATION;
    this.privateSelected = false;
    this.selection = selection || null;
    this.limits = limits;
    this.typeFilter = [];
    this.isTimePeriod = false;
    this.listId = DEFAULT_LIST_ID;
    this.reservationIds = reservationIds;
};

PrefsData.prototype = Object.create(Model.prototype);

PrefsData.prototype.getSettings = function () {
    const settings = [];
    return new Settings(settings);
};

PrefsData.prototype.getMacroFunctions = function () {
    const macros = [];
    return macros;
};

PrefsData.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    return this.immutableSet({
        limits,
    });
};

PrefsData.prototype.setIsTimePeriod = function (isTimePeriod) {
    return this.immutableSet({ isTimePeriod });
};

PrefsData.prototype.updateTimeRange = function (newBeginTime, newEndTime) {
    let limits = new Limits(this.limits);
    limits = limits.setStartDayFromDate(new MillenniumDateTime(newBeginTime).getMillenniumDate());
    limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
    return this.immutableSet({
        limits,
    });
};

// What about the search settings object and such? How to even get hold of it from here?
PrefsData.prototype.toJSON = function () {
    return {
        limits: this.limits,
        privateSelectedList: this.privateSelected,
        templateKind: this.templateKind.mode,
        typeFilter: this.typeFilter,
        reservationIds: this.reservationIds,
        function: this.getMacroFunctions(),
        isList: false,
        isPrefsComponent: true,
        listId: this.listId,
    };
};

module.exports = PrefsData;
