const React = require("react");

const DEFAULT_VALUE = 10;
const DEFAULT_MIN_VALUE = 9;
const DEFAULT_MAX_VALUE = 15;

class RangeInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: props.defaultValue || DEFAULT_VALUE,
        };
    }

    onChange(event) {
        const value = parseInt(event.target.value, 10);
        this.setState({ value });
        this.props.onChange(event);
    }

    render() {
        return (
            <div>
                {this.props.minLabel || "a"}
                <input
                    type="range"
                    min={this.props.minValue || DEFAULT_MIN_VALUE}
                    max={this.props.maxValue || DEFAULT_MAX_VALUE}
                    value={this.state.value}
                    onChange={this.onChange.bind(this)}
                />
                {this.props.maxLabel || "A"}
                <div style={{ textAlign: "center" }}>{this.state.value}</div>
            </div>
        );
    }
}

module.exports = RangeInput;
