import ColorCell from "./ColorCell";

const isColorSelected = (props, colorId) => {
    if (!props.selectedColors && colorId === ColorCell.ALL_COLORS) {
        return true;
    }
    if (!props.selectedColors) {
        return false;
    }
    if (props.selectedColors.length === 0 && colorId === ColorCell.NO_COLOR) {
        return true;
    }
    return props.selectedColors.indexOf(colorId) !== -1;
};

const ColorPicker = (props) => (
    <div className="colorPicker">
        <ColorCell
            showNames={props.showNames}
            key={ColorCell.NO_COLOR}
            name={ColorCell.NO_COLOR}
            color={ColorCell.NO_COLOR}
            pattern={ColorCell.NO_COLOR}
            onColorSelected={props.onColorSelected}
            id={ColorCell.NO_COLOR}
            isselected={isColorSelected(props, ColorCell.NO_COLOR)}
        />
        {props.colors.map((color) => (
            <ColorCell
                showNames={props.showNames}
                key={color.id}
                name={color.name}
                color={color.baseColor}
                pattern={color.basePattern}
                onColorSelected={props.onColorSelected}
                id={color.id}
                isselected={isColorSelected(props, color.id)}
            />
        ))}
    </div>
);

ColorPicker.NO_COLOR = ColorCell.NO_COLOR;
ColorPicker.ALL_COLORS = ColorCell.ALL_COLORS;

module.exports = ColorPicker;
