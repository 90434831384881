const React = require("react");
const TopLeft = require("./TopLeft");
import TEPrefsPlugin from "@timeedit/te-prefs-lib";
const _ = require("underscore");
const TimeEdit = require("../../lib/TimeEdit");
const API = require("../../lib/TimeEditAPI");
import ErrorBoundary from "../ErrorBoundary";
import coreAPI from "./PrefsCoreAPI";
const PropTypes = require("prop-types");
// Resolved by webpack config
// eslint-disable-next-line import/no-unresolved
import "te-prefs-lib.css";

class Wrapper extends React.Component {
    static contextTypes = {
        targetExternalAppsEnvironment: PropTypes.object,
        user: PropTypes.object,
        usingSSO: PropTypes.bool,
        env: PropTypes.object,
    };
    state = {
        toolbar: null,
        env: this.getEnv(),
    };

    /**
     * Get envioronment to pass to TePrefsLib
     * @returns one of "production", "staging", "beta" (connect to staging servers), or "localhost"
     */
    getEnv() {
        if (process.env.NODE_ENV === "development") {
            return "amLocalhost";
        }

        return this.context.targetExternalAppsEnvironment?.preferences || "production";
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.toolbar !== nextState.toolbar || !_.isEqual(this.props.size, nextProps.size)
        );
    }
    componentDidMount() {
        if (
            this.context.targetExternalAppsEnvironment &&
            this.context.targetExternalAppsEnvironment.preferences
        ) {
            // eslint-disable-next-line no-console
            console.log(
                "Environment from server: ",
                this.context.targetExternalAppsEnvironment.preferences
            );
        }
        if (process.env.NODE_ENV === "development") {
            TimeEdit.prefsAPI = coreAPI(this.props, this.context.user, undefined);
        }
    }

    componentWillUnmount() {
        API.clearAmCaches();
    }

    setToolbar(toolbar) {
        this.setState({ toolbar });
    }

    render() {
        return (
            <div className={_.classSet(this.props.classes)} style={this.props.style}>
                <TopLeft topLeft={this.props.topLeft} contents={this.state.toolbar} />
                <ErrorBoundary>
                    <div style={{ flexGrow: "1", overflow: "auto" }}>
                        <TEPrefsPlugin
                            mixpanel={window.mixpanel}
                            usingSSO={this.context.usingSSO}
                            platformVersion={this.context.env.platformVersion}
                            region={this.context.env.serverRegion}
                            env={this.state.env}
                            coreAPI={coreAPI(
                                this.props,
                                this.context.user,
                                this.setToolbar.bind(this)
                            )}
                        />
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

module.exports = Wrapper;
