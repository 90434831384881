const React = require("react");
const ObjectSelect = require("./ObjectSelect");
const BooleanInput = require("./BooleanInput");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const Language = require("../lib/Language");
const OBMC = require("../lib/ReservationConstants").OBJECT_MASS_CHANGE;
const _ = require("underscore");

class MassChangeObjectsDialog extends React.Component {
    state = {
        labelReplaceText: "",
        labelReplaceWithText: "",
        labelReplaceTypeText: "",
        selectedObjects: [],
        allTypes: [],
        types: [],
        typeTree: {},
        dropDownType: 0,
        destinationDropDownType: 0,
        allowDoubleBooking: false,
        addIfMissingTypeOnly: false,
        destinationType: TimeEdit.rootType,
        destinationTypes: [],
        removeAllOfType: false,
    };

    componentDidMount() {
        if (this.props.changeData) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState(this.props.changeData);
        }

        API.getReservableTypesForType(null, (allTypes) => {
            API.getTypeTree((tree) => {
                API.getTypesOnReservations(this.props.reservationIds, (types) => {
                    this.setState(
                        {
                            allTypes,
                            typeTree: tree,
                            types,
                        },
                        () => {
                            if (this.props.startTypeId && !this.state.selectedType) {
                                this.changeType(
                                    this.props.startTypeId,
                                    this.loadSelectedObjects.bind(this)
                                );
                                //this.setState({ dropDownType: this.props.startTypeId });
                            } else {
                                this.loadSelectedObjects();
                            }
                        }
                    );
                });
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedType !== this.state.selectedType) {
            this.loadSelectedObjects();
        }
    }

    loadSelectedObjects() {
        if (!this.props.reservationIds || !this.state.selectedType) {
            return;
        }
        API.getObjectsOnReservations(
            this.props.reservationIds,
            this.state.selectedType.id,
            (objects, occurances) => {
                const fullObjects = objects.map((obj, index) => ({
                    id: obj.id,
                    type: this.state.selectedType,
                    occurances: occurances[index],
                }));
                this.setState({ selectedObjects: fullObjects });
            }
        );
    }

    changeType = (event, callback) => {
        const typeId = typeof event === "number" ? event : parseInt(event.target.value, 10);
        API.getReservableTypesForType(null, (reservableTypes) => {
            let selectedType = _.find(this.state.types, (type) => type.id === typeId);
            if (!selectedType) {
                selectedType = this.state.types[0];
                if (!selectedType) {
                    return;
                }
            }
            const labelReplaceTypeText = this.getTypeName(selectedType.id);
            this.setState(
                {
                    toReplace: null,
                    labelReplaceText: "",
                    labelReplaceWithText: "",
                    selectedType,
                    destinationTypes: reservableTypes,
                    destinationType: selectedType,
                    dropDownType: selectedType.id,
                    destinationDropDownType: selectedType.id,
                    labelReplaceTypeText,
                    selectedObjects: [],
                },
                () => {
                    this.clearSelection();
                    if (callback) {
                        callback();
                    }
                }
            );
        });
    };

    changeDestinationType = (event) => {
        const typeId = parseInt(event.target.value, 10);
        const selectedType = _.find(this.state.allTypes, (type) => type.id === typeId);
        if (this.props.changeType === OBMC.ADD) {
            this.setState({
                selectedType,
                destinationType: selectedType,
                destinationDropDownType: selectedType.id,
                selectedObjects: [],
            });
        } else {
            this.setState({
                destinationType: selectedType,
                destinationDropDownType: selectedType.id,
            });
        }
    };

    handleAddObject = (object) => {
        this.updateStateAndNotify({
            selectedObjects: [].concat(object),
            occurances: object.occurances || false,
        });
    };

    onAllowDoubleBookingChanged = (allowDoubleBooking) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Allow double booking", {
            Checked: allowDoubleBooking,
            "Change type": Object.keys(OBMC)[this.props.changeType],
        });*/
        this.updateStateAndNotify({ allowDoubleBooking });
    };

    onRemoveAllOfTypeChanged = (removeAllOfType) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Remove all of type", {
            Checked: removeAllOfType,
            Type: this.state.selectedType.name,
            "Change type": Object.keys(OBMC)[this.props.changeType],
        });*/
        this.updateStateAndNotify({
            removeAllOfType,
            toReplace: removeAllOfType ? this.state.selectedObjects.map((obj) => obj.id) : null,
        });
    };

    onAddIfMissingTypeOnlyChanged = (addIfMissingTypeOnly) => {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Add if type missing", {
            Checked: addIfMissingTypeOnly,
            "Change type": Object.keys(OBMC)[this.props.changeType],
        });*/
        this.updateStateAndNotify({ addIfMissingTypeOnly });
    };

    onObjectSearcherChange = (os) => {
        this.updateStateAndNotify({ objectSearch: os });
    };

    getObjectName = (object) => {
        if (object.name) {
            return object.name;
        }
        const firstValueField = _.find(
            object.fields,
            (field) => field.values !== undefined && field.values.length > 0
        );
        return firstValueField.values.join(", ");
    };

    handleToReplaceSelected = (object, isSingleClick, selectedIndexes) => {
        const obj = _.find(this.state.selectedObjects, (o) => o.id === object.id);
        const labelReplaceText = this.getObjectName(object);
        const selectedType = obj.type;
        const labelReplaceTypeText = this.getTypeName(selectedType.id);
        let toReplace = obj.id;
        if (selectedIndexes.length > 1) {
            toReplace = selectedIndexes.map((index) => this.state.selectedObjects[index].id);
        }
        this.updateStateAndNotify({
            toReplace,
            labelReplaceText,
            labelReplaceWithText: "",
            selectedType,
            occurances: obj.occurances || false,
            labelReplaceTypeText,
        });
    };

    handleReplacementSelected = (object) => {
        this.updateStateAndNotify({ replacement: object.id, labelReplaceWithText: object.name });
    };

    updateStateAndNotify = (newState) => {
        this.setState(newState, () => this.props.onSelectionChanged(this.state));
    };

    renderTypeOptions = (types) =>
        [
            <option key={0} value={0}>
                {Language.get("admin_choose_type")}
            </option>,
        ].concat(
            types.map((type) => (
                <option key={type.id} value={type.id}>
                    {type.name}
                </option>
            ))
        );

    renderTypeTreeOptions = (typeTree, selectableTypeIds = []) => {
        const treeToTypeList = (tree, depth) => {
            if (!tree) {
                return [];
            }
            const subNodes = [];
            // eslint-disable-next-line no-param-reassign
            tree.depth = depth;
            subNodes.push(tree);
            if (tree.subtypes) {
                // eslint-disable-next-line no-param-reassign
                depth++;
                for (let i = 0; i < tree.subtypes.length; i++) {
                    subNodes.push(_.flatten(treeToTypeList(tree.subtypes[i], depth)));
                }
            }
            return _.flatten(subNodes);
        };
        return [
            <option key={0} value={0}>
                {Language.get("admin_choose_type")}
            </option>,
        ].concat(
            treeToTypeList(typeTree, 0).map((type) => {
                const indentation = [];
                for (let i = 0; i < type.depth; i++) {
                    indentation.push("\u00a0\u00a0");
                }
                if (!type.id) {
                    return null;
                }
                let disabled = false;
                if (selectableTypeIds.length > 0) {
                    disabled = selectableTypeIds.indexOf(type.id) === -1;
                }
                if (disabled) {
                    return null;
                }
                return (
                    <option disabled={disabled} key={type.id} value={type.id}>
                        {indentation.join("")}
                        {type.name}
                    </option>
                );
            })
        );
    };

    getTypeName = (typeId) => {
        const type = _.find(this.state.types, (tp) => tp.id === typeId);
        return type ? type.name : String(typeId);
    };

    clearSelection = () => {
        if (this._clearSelection) {
            this._clearSelection();
        }
    };

    registerClearSelection = (clearFunction) => {
        this._clearSelection = clearFunction;
    };

    _renderReplacePanel = (checkbox) => {
        const labelReplace = Language.get(
            "nc_mass_change_headline_replace",
            this.state.labelReplaceText
        );
        const labelReplaceWith = Language.get(
            "nc_mass_change_headline_replace_with",
            this.state.labelReplaceWithText
        );
        const replaceText =
            this.state.labelReplaceText && this.state.labelReplaceWithText
                ? Language.get(
                      "nc_mass_change_x_will_be_replaced_with_y",
                      this.state.labelReplaceText,
                      this.state.labelReplaceWithText
                  )
                : "";
        return (
            <div className="massChangeDialog">
                <div className="massChangePanel">
                    <div className="selectedObjectList objectSelectList">
                        <span className="settingsLabel massChangeLabel">{labelReplace}</span>
                        <select
                            className="replacePanelSelectList"
                            value={this.state.dropDownType}
                            onChange={this.changeType}
                        >
                            {this.renderTypeOptions(this.state.types)}
                        </select>

                        <ObjectSelect
                            width={"100%"}
                            selectedType={this.state.selectedType}
                            onObjectSearcherChange={null}
                            selectedFluffyItem={null}
                            onClick={this.handleToReplaceSelected}
                            onObjectInfo={null}
                            addReloadFunction={null}
                            ref="objectSelection"
                            onRowHover={null}
                            hoverButton={null}
                            modalKey={"allowSecondModal"}
                            reservationIds={this.props.reservationIds}
                            isStatic={true}
                            staticObjects={this.state.selectedObjects.map((obj) => obj.id)}
                            occuringObjects={this.state.selectedObjects}
                            highlightSelection={true}
                            user={this.props.user}
                        />
                    </div>
                    {this._renderObjectSelectList(
                        this.handleReplacementSelected,
                        true,
                        true,
                        labelReplaceWith
                    )}
                </div>
                <div className="massChangeInfoText">{replaceText}</div>
                {checkbox}
            </div>
        );
    };

    _renderRemovePanel = () => (
        <div className="massRemovePanel">
            <div className="selectedObjectList objectSelectList">
                <select
                    className="replacePanelSelectList"
                    value={this.state.dropDownType}
                    onChange={this.changeType}
                >
                    {this.renderTypeOptions(this.state.types)}
                </select>

                <ObjectSelect
                    width={"100%"}
                    selectedType={this.state.selectedType}
                    onObjectSearcherChange={null}
                    selectedFluffyItem={null}
                    onClick={this.handleToReplaceSelected}
                    onObjectInfo={null}
                    addReloadFunction={null}
                    ref="objectSelection"
                    onRowHover={null}
                    hoverButton={null}
                    modalKey={"allowSecondModal"}
                    reservationIds={this.props.reservationIds}
                    isStatic={true}
                    staticObjects={this.state.selectedObjects.map((obj) => obj.id)}
                    occuringObjects={this.state.selectedObjects}
                    disabled={this.state.removeAllOfType}
                    highlightSelection={true}
                    allowMultiSelection={true}
                    setClearSelection={this.registerClearSelection}
                    user={this.props.user}
                />

                <span>
                    <BooleanInput
                        defaultValue={this.state.removeAllOfType}
                        onUpdate={this.onRemoveAllOfTypeChanged}
                    />{" "}
                    {Language.get(
                        "nc_remove_all_of_type",
                        this.state.selectedType ? this.state.selectedType.name : ""
                    )}
                </span>
            </div>
        </div>
    );

    render() {
        let doubleBookingBox = (
            <span key="dbBox">
                <BooleanInput
                    defaultValue={this.state.allowDoubleBooking}
                    onUpdate={this.onAllowDoubleBookingChanged}
                />{" "}
                {Language.get("cal_selected_allow_double_res")}
            </span>
        );
        if (this.props.changeType === OBMC.REMOVE) {
            return this._renderRemovePanel();
        }

        if (this.props.changeType === OBMC.REPLACE) {
            return this._renderReplacePanel(doubleBookingBox);
        }

        doubleBookingBox = [doubleBookingBox];
        doubleBookingBox.push(
            <span key="typeBox">
                <BooleanInput
                    defaultValue={this.state.addIfMissingTypeOnly}
                    onUpdate={this.onAddIfMissingTypeOnlyChanged}
                />{" "}
                {Language.get("nc_add_if_missing_type_only")}
            </span>
        );
        return (
            <div className="massAddPanel">
                {this._renderObjectSelectList(this.handleAddObject, true, true, null)}
                {doubleBookingBox}
            </div>
        );
    }

    _renderObjectSelectList = (
        onClick,
        renderTypeChooser = false,
        showSelection = false,
        title = null
    ) => {
        const typeChooser = renderTypeChooser ? (
            <select
                className="replacePanelSelectList"
                value={this.state.destinationDropDownType}
                onChange={this.changeDestinationType}
            >
                {this.renderTypeTreeOptions(
                    this.state.typeTree,
                    this.state.allTypes.map((tp) => tp.id)
                )}
            </select>
        ) : null;
        return (
            <div className="objectSelectList">
                {title ? <span className="settingsLabel massChangeLabel">{title}</span> : null}
                {typeChooser}
                <ObjectSelect
                    width={"100%"}
                    selectedType={this.state.destinationType}
                    objectSearch={this.state.objectSearch}
                    onObjectSearcherChange={this.onObjectSearcherChange}
                    selectedFluffyItem={null}
                    onClick={onClick}
                    onObjectInfo={null}
                    addReloadFunction={null}
                    ref="objectSelection"
                    onRowHover={null}
                    hoverButton={null}
                    modalKey={"allowSecondModal"}
                    highlightSelection={showSelection}
                    reservationIds={this.props.reservationIds}
                    user={this.props.user}
                />
            </div>
        );
    };
}

module.exports = MassChangeObjectsDialog;
