const Header = require("./Header");

const IndexHeader = function (visibleValues, firstVisibleValue, subheader) {
    Header.call(this, visibleValues, firstVisibleValue || 0, subheader, "IndexHeader");
};

IndexHeader.prototype = Object.create(Header.prototype);

// eslint-disable-next-line no-unused-vars
IndexHeader.prototype.indexOf = function (entry, onlyVisible) {
    return entry.overlappingEntryIndex;
};

IndexHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    return this.indexOf(entry, onlyVisible) + 1;
};

IndexHeader.prototype.getValues = function () {
    return [{}];
};

// eslint-disable-next-line no-unused-vars
IndexHeader.prototype.getLabel = function (obj, size) {
    return obj.label || "";
};

module.exports = IndexHeader;
