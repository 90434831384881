const React = require("react");
const ReactDOM = require("react-dom");
const ContextMenu = require("../lib/ContextMenu");
const _ = require("underscore");

const FONT_SIZE_ADJUSTMENT = 0.9;

class CalendarMenu extends React.Component {
    onClick = (event) => {
        ContextMenu.displayMenu(this.props.menuItems, event, _.noop);
    };

    onDragStart = (event) => {
        _.setDragData(event, "application/x-timeedit-calendar", this.props.calendarId);
        event.dataTransfer.effectAllowed = "move"; // eslint-disable-line
        if (event.dataTransfer.setDragImage) {
            event.dataTransfer.setDragImage(ReactDOM.findDOMNode(this).parentNode, 0, 0);
        }
    };

    getPrivateStyle = () => {
        const isWide = this.props.size.width > this.props.size.height;
        const isTall = this.props.size.height > this.props.size.width;

        const style = {
            // eslint-disable-next-line no-magic-numbers
            height: !isWide ? this.props.size.height / 2 : this.props.size.height,
            // eslint-disable-next-line no-magic-numbers
            width: !isTall ? this.props.size.width / 2 : this.props.size.width,
        };
        style.lineHeight = `${style.height}px`;
        style.fontSize = Math.min(style.height, style.width) * FONT_SIZE_ADJUSTMENT;

        return style;
    };

    render() {
        const classes = {
            topLeft: true,
            inactive: !this.props.isActive,
            showId: this.props.showId,
        };

        let privateIcon = null;
        if (this.props.isPrivate && !this.props.showId) {
            privateIcon = <div className="private" style={this.getPrivateStyle()} />;
        }

        const style = _.clone(this.props.size);
        style.fontSize =
            Math.min(this.props.size.height, this.props.size.width) * FONT_SIZE_ADJUSTMENT;
        style.lineHeight = `${this.props.size.height}px`;

        return (
            <div
                className={_.classSet(classes)}
                style={style}
                draggable={true}
                onClick={this.onClick}
                onContextMenu={this.onClick}
                onDragStart={this.onDragStart}
            >
                <span className="calendarId" data-cy="calendar-menu">
                    {this.props.calendarId + 1}
                </span>
                {privateIcon}
            </div>
        );
    }
}

module.exports = CalendarMenu;
