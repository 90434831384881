const PropTypes = require("prop-types");
const React = require("react");
const ListCalendar = require("./ListCalendar");
const OrderList = require("./OrderList");
const Macros = require("../models/Macros");

class OrderListCalendar extends React.Component {
    static contextTypes = {
        fireEvent: PropTypes.func,
    };

    getRefreshEvents = () => [
        Macros.Event.SELECT_RESERVATION,
        Macros.Event.RESERVATION_MADE_OR_MODIFIED,
        Macros.Event.RESERVATION_RESTORED,
    ];

    onSelection = (selection, descriptionField, commentField) => {
        this.context.fireEvent(
            `orderList${this.props.id}`,
            Macros.Event.SELECT_ORDER,
            selection,
            descriptionField,
            commentField
        );
    };

    render() {
        return (
            <ListCalendar
                {...this.props}
                type={"orderList"}
                refreshEvents={this.getRefreshEvents()}
                onSelection={this.onSelection}
            >
                <OrderList />
            </ListCalendar>
        );
    }
}

module.exports = OrderListCalendar;
