const Model = require("./Model");
const API = require("../lib/TimeEditAPI");
const Macros = require("../models/Macros");
const TemplateKind = require("./TemplateKind");
const Language = require("../lib/Language");
const _ = require("underscore");
const EmptyHeader = require("./EmptyHeader");
const Calendar = require("./Calendar");
const ReservationSearch = require("./ReservationSearch");
const OrderSearch = require("./OrderSearch");
const WaitingListSearch = require("./WaitingListSearch");
const CancellationSearch = require("./CancellationSearch");
const RequestSearch = require("./RequestSearch");
const PrefsData = require("./PrefsData");
const ExamData = require("./ExamData");
const MemberData = require("./MemberData");
const ConflictSearch = require("./ConflictSearch");
const Limits = require("./Limits");
const Selection = require("./Selection");
const DateHeader = require("./DateHeader");
const TimeHeader = require("./TimeHeader");
const ObjectHeader = require("./ObjectHeader");
const WeekdayHeader = require("./WeekdayHeader");
const WeekHeader = require("./WeekHeader");
const WeekPeriodHeader = require("./WeekPeriodHeader");
const WeekdayPeriodHeader = require("./WeekdayPeriodHeader");
const DatePeriodHeader = require("./DatePeriodHeader");
const TimePeriodHeader = require("./TimePeriodHeader");
const Section = require("./Section");
const SectionModel = require("./Section");

const View = function (id, name, topSection, macros, organizations, orgState) {
    Model.call(this, "View");
    this.id = id;
    this.name = name;
    this.section = topSection;
    this.macros = macros || [];
    this.organizations = organizations || [];
    this.orgState = orgState;
    this.isUnsupported = this.hasUnsupportedChild();
};

View.prototype = Object.create(Model.prototype);

View.prototype.hasUnsupportedChild = function () {
    const children = this.section.getAllChildren();
    return _.some(children, (child) => child.isUnsupported);
};

const removeTemporaryProperties = function (section) {
    if (!section) {
        return;
    }

    if (section.area) {
        // eslint-disable-next-line no-param-reassign
        delete section.area;
    }
    removeTemporaryProperties(section.first);
    removeTemporaryProperties(section.second);
};

const DEFAULT_VIEW = "DEFAULT_VIEW";

View.DEFAULT_VIEW = DEFAULT_VIEW;

View.parse = function (serverViewData) {
    const viewId = serverViewData.id;
    const name = serverViewData.name;
    const tables = serverViewData.tables;
    const orgState = serverViewData.org_state;
    const organizations = serverViewData.orgs
        ? serverViewData.orgs.map((organization) => ({
              id: organization.id,
              extid: organization.extid,
          }))
        : [];
    const currentTable = 1;
    const topSection = View.parseSection(tables, currentTable, name);
    let macros;
    if (topSection) {
        // What to do if we get no topSection?
        topSection.viewId = viewId;
        removeTemporaryProperties(topSection);
        macros = View.parseMacros(topSection);
    }
    return new View(viewId, name, topSection, macros, organizations, orgState);
};

View.parseHeaderList = function (headers, limits) {
    let prevHeader, returnHeader, header;
    for (let i = 0; i < headers.length; i++) {
        header = View.parseHeader(headers[i], limits);
        if (!returnHeader) {
            returnHeader = header;
        }
        if (prevHeader) {
            prevHeader.subheader = header;
        }
        prevHeader = header;
    }

    if (!returnHeader) {
        returnHeader = new EmptyHeader();
    }

    returnHeader.isActive = true;
    return returnHeader;
};

View.parseCalendar = function (data, activeCalendar, name) {
    const limits = new Limits(data.limits);
    let xHeader, yHeader;

    try {
        xHeader = View.parseHeaderList(data.headersX, limits);
        yHeader = View.parseHeaderList(data.headersY, limits);
    } catch (error) {
        throw new Error(
            `${Language.get("nc_invalid_header_in_calendar_view:", activeCalendar, name)} ${
                error.message
            }`
        );
    }
    let calendar = new Calendar(xHeader, yHeader, limits);
    if (data.isList) {
        calendar = new ReservationSearch(limits);
    } else if (data.isCancellationList) {
        calendar = new CancellationSearch(limits);
    } else if (data.isOrderList) {
        calendar = new OrderSearch(limits);
    } else if (data.isWaitingList) {
        calendar = new WaitingListSearch(limits);
    } else if (data.isRequestList) {
        calendar = new RequestSearch(limits);
    } else if (data.isDateSelector) {
        calendar.isDateSelector = true;
    } else if (data.isPrefsComponent) {
        calendar = new PrefsData(limits);
    } else if (data.isExamComponent) {
        calendar = new ExamData(limits);
    } else if (data.isGroupManager) {
        calendar = new MemberData(limits);
    } else if (data.isConflictList) {
        calendar = new ConflictSearch(limits);
    }

    // If server has marked the data as incomplete, we consider the resulting calendar to be unsupported
    calendar.isUnsupported = data.isIncomplete || false;

    const booleanProps = [
        "availabilityInForeground",
        "hideAbstractObstacles",
        "hideObstacles",
        "readOnly",
        "hideInfoReservations",
    ];

    booleanProps.forEach((prop) => {
        calendar[prop] = data[prop];
    });
    calendar.isPaddingActive = data.hidePadding !== undefined ? !data.hidePadding : true;
    calendar.privateSelected = data.privateSelectedList;

    calendar.templateKind = TemplateKind.getFromString(data.templateKind);
    if (TemplateKind.equals(TemplateKind.NULL, calendar.templateKind)) {
        calendar.templateKind = TemplateKind.RESERVATION;
    }
    if (!TemplateKind.equals(TemplateKind.RESERVATION, calendar.templateKind)) {
        calendar.privateSelected = true;
    }

    if (calendar.privateSelected === true) {
        calendar.selection = new Selection();
    }

    calendar.typeFilter = data.typeFilter || [];
    calendar.colorTypes = data.typeColor || [];
    calendar.periodId = data.periodId || 0;
    calendar.isTimePeriod = calendar.periodId !== 0;

    const macros = data.function || [];

    calendar.macroFunctions = macros;
    macros.forEach((macro) => {
        if (macro.name === "update") {
            calendar.updateMacro = true;
        } else if (macro.name === "setDay") {
            calendar.setDayMacro = true;
        } else if (macro.name === "scrollDay") {
            calendar.scrollDayMacro = true;
        } else if (macro.name === "setTime") {
            calendar.setTimeMacro = true;
        } else if (macro.name === "scrollTime") {
            calendar.scrollTimeMacro = true;
        } else if (macro.name === "now") {
            calendar.nowMacro = true;
        } else {
            throw new Error(`Unknown macro ${macro.name}`);
        }
    });

    if (data.orientation.selected) {
        calendar.selected = true;
    }

    return calendar;
};

View.parseHeader = function (data, limits) {
    let header = null;

    if (data.kind === "date") {
        header = DateHeader.parse(data, limits);
    } else if (data.kind === "time") {
        header = TimeHeader.parse(data, limits);
    } else if (data.kind === "object") {
        header = ObjectHeader.parse(data, limits);
    } else if (data.kind === "weekday") {
        header = WeekdayHeader.parse(data, limits);
    } else if (data.kind === "week") {
        header = WeekHeader.parse(data, limits);
    } else if (data.kind === "periodweek") {
        header = WeekPeriodHeader.parse(data, limits);
    } else if (data.kind === "periodweekday") {
        header = WeekdayPeriodHeader.parse(data, limits);
    } else if (data.kind === "periodtime") {
        header = TimePeriodHeader.parse(data, limits);
    } else if (data.kind === "perioddate") {
        header = DatePeriodHeader.parse(data, limits);
    }

    if (!header) {
        // Unimplemented header type
        throw new Error(`No header of type ${data.kind}.`);
    }

    header.limits = limits;
    header.showInfo = data.showInfo;
    header.hide = data.hideHeader;
    header.isScrollable = data.scrollable;
    header.hideGrid = data.hideGrid;
    header.size = data.size;
    header.isSideBySide = data.isSideBySide || false;

    return header;
};

const buildSection = function (areas, isVertical, currentCalendar, name) {
    let distanceProp = "y",
        sizeProp = "height";
    if (!isVertical) {
        distanceProp = "x";
        sizeProp = "width";
    }

    let first = [];
    let second = [];
    let divider;

    let currentArea, i, j, area, distance, firstSection, secondSection;
    const numAreas = areas.length;
    const PRECISION = 3;

    for (i = 0; i < numAreas; i++) {
        currentArea = areas[i];

        first = [];
        second = [];
        divider = parseFloat(
            (currentArea.orientation[distanceProp] + currentArea.orientation[sizeProp]).toFixed(
                PRECISION
            )
        );

        for (j = 0; j < numAreas; j++) {
            area = areas[j];
            distance = parseFloat(area.orientation[distanceProp].toFixed(PRECISION));
            if (parseFloat((distance + area.orientation[sizeProp]).toFixed(PRECISION)) <= divider) {
                first.push(area);
            } else if (distance >= divider) {
                second.push(area);
            } else {
                break;
            }
        }

        if (
            first.length !== 0 &&
            second.length !== 0 &&
            first.length + second.length === areas.length
        ) {
            firstSection = View.parseSection(first, currentCalendar, name);
            secondSection = View.parseSection(second, currentCalendar, name);
            if (firstSection !== null && secondSection !== null) {
                return View.createContainer(
                    firstSection,
                    secondSection,
                    currentArea,
                    sizeProp,
                    isVertical
                );
            }
        }
    }
    return null;
};

const MAX_DIMENSION = 100;

View.createContainer = function (firstSection, secondSection, currentArea, sizeProp, isVertical) {
    const weight =
        firstSection.area.orientation[sizeProp] /
        (firstSection.area.orientation[sizeProp] + secondSection.area.orientation[sizeProp]);
    // eslint-disable-next-line no-param-reassign
    firstSection.height = MAX_DIMENSION;
    // eslint-disable-next-line no-param-reassign
    firstSection.width = MAX_DIMENSION;
    // eslint-disable-next-line no-param-reassign
    secondSection.height = MAX_DIMENSION;
    // eslint-disable-next-line no-param-reassign
    secondSection.width = MAX_DIMENSION;
    // eslint-disable-next-line no-param-reassign
    firstSection[sizeProp] = Math.round(MAX_DIMENSION * weight);
    // eslint-disable-next-line no-param-reassign
    secondSection[sizeProp] = MAX_DIMENSION - firstSection[sizeProp];
    // eslint-disable-next-line no-param-reassign
    currentArea.orientation[sizeProp] =
        firstSection.area.orientation[sizeProp] + secondSection.area.orientation[sizeProp];
    const container = new Section(firstSection, secondSection, weight, isVertical);
    container.area = currentArea;
    return container;
};

View.parseSection = function (areas, currentCalendar, name) {
    let section;
    if (areas.length === 1) {
        const calendar = View.parseCalendar(areas[0], currentCalendar, name);
        section = new SectionModel(calendar, null, null, null);
        section.area = areas[0];
        return section;
    }

    section = buildSection(areas, false, currentCalendar, name);
    if (!section) {
        section = buildSection(areas, true, currentCalendar, name);
    }

    return section;
};

View.parseMacros = function (section) {
    const macros = [];
    const calendars = section.getAllChildren();
    calendars.forEach((calendar, index) => {
        if (calendar.scrollDayMacro || calendar.setDayMacro) {
            macros.push({
                action: Macros.Action.SET_DATE,
                target: `calendar${index}`,
            });
        }
        if (calendar.scrollTimeMacro || calendar.setTimeMacro) {
            macros.push({
                action: Macros.Action.SET_TIME,
                target: `calendar${index}`,
            });
        }
        macros.push({
            action: Macros.Action.REFRESH,
        });
        if (calendar instanceof Calendar) {
            macros.push({
                action: Macros.Action.SET_RESERVATION,
            });
        }
    });
    return macros;
};

View.find = function (id, callback) {
    if (!id) {
        API.sendMessage("findViews", {}, (response) => {
            const foundViews = response.parameters[0].map((view) => {
                // eslint-disable-next-line no-param-reassign
                view.orgState = view.org_state;
                // eslint-disable-next-line no-param-reassign
                delete view.org_state;
                return view;
            });
            callback(foundViews);
        });
    } else {
        API.sendMessage("exportViews", { viewIds: id === DEFAULT_VIEW ? [] : [id] }, (response) => {
            if (!Array.isArray(response.parameters[0]) || response.parameters[0].length === 0) {
                return callback(null);
            }

            try {
                const parsedView = View.parse(response.parameters[0][0]);
                return callback(parsedView);
            } catch (e) {
                return callback(e);
            }
        });
    }
};

View.save = function (id, name, tables, organizations = [], orgState, callback) {
    const serverOrganizations = organizations.map((organization) => ({
        class: "organizationid",
        extid: organization.extid,
        id: organization.id,
    }));
    let viewData;
    // Only send orgState if we're hitting the "make public" button, otherwise send nothing
    if (orgState !== null) {
        viewData = [
            [
                {
                    class: "viewcreate",
                    id,
                    name,
                    orgs: serverOrganizations,
                    tables,
                    org_state: orgState,
                },
            ],
        ];
    } else {
        viewData = [[{ class: "viewcreate", id, name, orgs: serverOrganizations, tables }]];
    }
    API.sendMessage("importViews", viewData, (response) => {
        callback(response.parameters[0]);
    });
};

View.delete = function (id, callback) {
    API.sendMessage("deleteViews", [[{ class: "viewid", id }]], callback);
};

module.exports = View;
