const PropTypes = require("prop-types");
const React = require("react");
const _ = require("underscore");
const Language = require("../lib/Language");

class GroupInput extends React.Component {
    static propTypes = {
        defaultValue: PropTypes.object,
        availableValues: PropTypes.array,
        onUpdate: PropTypes.func,
    };

    state = {
        selectedGroup: 0,
    };

    contextTypes = {
        user: PropTypes.object,
    };

    onGroupChange = (evt) => {
        this.setState({ selectedGroup: parseInt(evt.target.value, 10) });
    };

    getCurrentValues = () => {
        const values = this.props.defaultValue.values[this.state.selectedGroup];

        if (values.length > 0 && values[0].value !== undefined) {
            return values.map((val) => val.value);
        }

        return values;
    };

    onChange = (evt) => {
        let selected = this.props.availableValues.filter(
            (val, index) => evt.target.options.item(index).selected === true
        );
        if (selected.length > 0 && selected[0].value !== undefined) {
            selected = selected.map((val) => val.value);
        }
        const result = _.clone(this.props.defaultValue);
        result.values[this.state.selectedGroup] = selected;
        this.props.onUpdate(result);
    };

    addGroup = () => {
        const result = _.clone(this.props.defaultValue);
        const name = this.context.user.promptForGroupName
            ? // eslint-disable-next-line no-alert
              window.prompt(Language.get("nc_reservation_group_name"))
            : "";
        if (name === null) {
            return;
        }
        result.names = result.names.concat([name]);
        result.values = result.values.concat([[this.props.availableValues[0].value]]);
        this.props.onUpdate(result);
    };

    render() {
        return (
            <div className="container groups">
                <span className="topLine">
                    <select
                        multiple={false}
                        onChange={this.onGroupChange}
                        size={1}
                        value={this.state.selectedGroup}
                    >
                        {this.props.defaultValue.names.map((item, index) => (
                            <option key={index} value={index}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <span className="addButton" onClick={this.addGroup}>
                        +
                    </span>
                </span>
                <select
                    ref="group"
                    multiple={true}
                    onChange={this.onChange}
                    size={7}
                    value={this.getCurrentValues()}
                >
                    {this.props.availableValues.map((item, index) => (
                        <option key={index} value={item.value !== undefined ? item.value : item}>
                            {item.label !== undefined ? item.label : item}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

module.exports = GroupInput;
