import { TConflictingObjects } from "../types/TEntry";

const React = require("react");
const createReactClass = require("create-react-class");
const API = require("../lib/TimeEditAPI");

const TOOLTIP_LEFT_OFFSET = 265;
const TOOLTIP_RIGHT_OFFSET = -10;

const ReservationConflictInfoTooltip = createReactClass({
    displayName: "ReservationConflictInfoTooltip",

    getInitialState() {
        return {
            objectNamesMap: new Map(),
            objects: [],
            members: [],
            isLoaded: false,
        };
    },

    componentDidMount() {
        this._isMounted = true;
        this.processConflictingObjects(this.props.conflictingObjects);
    },

    componentWillUnmount() {
        this._isMounted = false;
    },

    processConflictingObjects(conflictingObjects: TConflictingObjects) {
        this.getNamesForConflictingObjects(conflictingObjects);

        const objects = conflictingObjects.map((obj) => obj.objectId);
        const members = conflictingObjects
            .flatMap((obj) => obj.members?.map((memberObj) => memberObj.objectId))
            .filter((m) => m);

        this.setState({ objects, members });
    },

    getNamesForConflictingObjects(conflictingObjects: TConflictingObjects) {
        const recursiveFlattenConflictingObjectIds = (
            conflictObjArray?: TConflictingObjects
        ): number[] => {
            const ids = conflictObjArray?.flatMap(({ objectId, members }) => {
                const nestedResult = recursiveFlattenConflictingObjectIds(members);
                return [objectId, ...nestedResult];
            });

            return ids ?? [];
        };

        const conflictingObjectIds = recursiveFlattenConflictingObjectIds(conflictingObjects);

        if (conflictingObjectIds?.length) {
            const objectNamesMap = new Map<string, string>();

            API.getObjectNames(conflictingObjectIds, true, (nameResult) => {
                nameResult.forEach((namedObject) => {
                    const name = namedObject.fields
                        .map((field) => (field.values ? field.values.join(", ") : ""))
                        .join(", ");
                    objectNamesMap.set(namedObject.id, name);
                });
                this.setState({ objectNamesMap, isLoaded: true });
            });
        }
    },

    render() {
        if (!this.props.conflictingObjects || !this.state.isLoaded) return null;

        let objects: React.ReactNode[], members: React.ReactNode[];

        objects = this.state.objects.map((objectId) => {
            const objectName = this.state.objectNamesMap.get(objectId);
            return (
                <tr key={objectId}>
                    <td className="wrapText" title={objectName} style={{ paddingLeft: "5px" }}>
                        {objectName}
                    </td>
                </tr>
            );
        });

        members = this.state.members.map((objectId) => {
            const objectName = this.state.objectNamesMap.get(objectId);
            return (
                <tr key={objectId}>
                    <td className="wrapText" title={objectName} style={{ paddingLeft: "5px" }}>
                        {objectName}
                    </td>
                </tr>
            );
        });

        const text = (
            <span>
                <table>
                    <tbody>
                        <tr>
                            <th>{objects.length} Object(s) in conflict:</th>
                        </tr>
                        {objects}
                        {members?.length ? (
                            <>
                                <tr>
                                    <th>{members.length} Member(s) in conflict:</th>
                                </tr>
                                {members}
                            </>
                        ) : null}
                    </tbody>
                </table>
            </span>
        );

        let tooltipStyle: React.CSSProperties | null = null;
        if (this.props.mousePosition) {
            const leftOffset = this.props.tooltipLeft ? TOOLTIP_LEFT_OFFSET : TOOLTIP_RIGHT_OFFSET;
            const top = this.props.mousePosition.y - this.props.mousePosition.offset.top;
            let left =
                this.props.mousePosition.x - leftOffset - this.props.mousePosition.offset.left;
            if (this.props.tooltipLeft) {
                if (left < 0) {
                    left -= left;
                }
            }
            tooltipStyle = { top, left };
        }
        if (!tooltipStyle) {
            return null;
        }
        return this.props.isVisible ? (
            <div ref="tooltip" className="entryTooltip" style={tooltipStyle}>
                {text}
            </div>
        ) : null;
    },
});

module.exports = ReservationConflictInfoTooltip;
