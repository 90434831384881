import React, { useEffect, useState } from "react";
import * as API from "../../lib/TimeEditAPI";
import NumberInput from "../NumberInput";
import _ from "underscore";
import Language from "../../lib/Language";
import MOCFormPreview from "./MOCFormPreview";

const CONNECTION = {
    RELATION: "relationship",
    MEMBERSHIP: "membership",
    NONE: "none",
};

const SUFFIX = {
    ALPHA: "alphabetical",
    NUM: "numerical",
    NONE: "none",
};

const TimeEditAPI: any = API;

type TCreateObjectsRequestPayload = [
    {
        connectTo: {
            extId: string;
            typeExtId: string;
            object: {
                extid: string;
                fields: [{}];
                id: number; //The Id of the primary object
                name: string;
            };
            type: {
                class: string;
                extid: string;
                id: number; // The Id for the type of the primary object
                name: string;
            };
        };
        numberOfObjects: number;
        type: {
            class: string;
            extid: string;
            id: number; //The Id for the type of the created object
            name: string;
        };
        typeExtId: string;
    }
];

const MOCSettings = (props: { groupRequests: TCreateObjectsRequestPayload, callback: any }) => {
    const [types, setTypes] = useState<any>([]);
    const [creationType, setCreationType] = useState<number | null>(null);
    const [creationTypeName, setCreationTypeName] = useState<string>(
        Language.get("cal_header_kind_object")
    );
    const [fields, setFields] = useState([]);
    const [primaryType, setPrimaryType] = useState<number | null>(null);
    const [secondaryType, setSecondaryType] = useState<number | null>(null);
    const [nameField, setNameField] = useState<number | null>(null);
    const [suffix, setSuffix] = useState(SUFFIX.ALPHA);
    const [firstSuffix, setFirstSuffix] = useState<string>("A");
    const [preFixedValue, setPreFixedValue] = useState<string>(""); // The first fixed value, applied before the value from the object
    const [fixedValue, setFixedValue] = useState<string>("");
    const [numberOfObjects, setNumberOfObjects] = useState<number>(2);
    const [primaryConnection, setPrimaryConnection] = useState<string>(CONNECTION.MEMBERSHIP);
    const [secondaryConnection, setSecondaryConnection] = useState<string>(CONNECTION.NONE);
    const [secondaryConnectionAs, setSecondaryConnectionAs] = useState<string>(CONNECTION.NONE);

    useEffect(() => {
        TimeEditAPI.findAliasTypes((types) => {
            setTypes(types);
            setCreationType(types[0].id);
            props.groupRequests.length < 1
                ? setCreationTypeName(types[0].name)
                : setCreationTypeName(props.groupRequests[0].type.name);
            if (props.groupRequests.length > 0) {
                updatePrimaryType(props.groupRequests[0].connectTo.type.id, types);
            } else {
                updatePrimaryType(types[0].id, types);
            }
        });
    }, []);

    const updateMemberList = (evt) => {
        const creationType = parseInt(evt.target.value, 10);
        setCreationType(creationType);
        let e = (document as any).getElementById("type");
        setCreationTypeName(e.options[e.selectedIndex].text);
    };

    const updatePrimaryType = (typeId, allTypes) => {
        setPrimaryType(typeId);
        let type: any = (allTypes || types).find(tp => tp.id === typeId);
        TimeEditAPI.getPrimaryFieldDefsForType(typeId, false, (newFields) => {
            setFields(newFields);
            setNameField(newFields[0].id);
        });
    };

    const dropDownOptions = (options) => {
        return options.map((option) => {
            return (
                <option key={option.id} value={option.id} id="type">
                    {option.name}
                </option>
            );
        });
    };

    const onSubmit = () => {
        if (props.groupRequests.length < 1) {
            const query = {
                type: primaryType,
                returnFields: nameField ? [nameField] : [],
            };
            getObjectTemplate(creationType, (template, fieldDefs) => {
                template.members = [];
                template.related = [];
                createMembers(query, numberOfObjects, template, fieldDefs);
            });
        }

        props.groupRequests.forEach((request) => {
            getObjectTemplate(request.type.id, (template, fieldDefs) => {
                template.members = [];
                template.related = [];
                createMembers(
                    request.connectTo.object,
                    request.numberOfObjects,
                    template,
                    fieldDefs
                );
            });
        });
    };

    const getObjectTemplate = (cT, callback) => {
        TimeEditAPI.getObjectTemplate([cT], (result) => {
            const newObject = result.parameters[0];
            TimeEditAPI.getFieldDefs(
                newObject.fields.map((fld) => fld.id),
                (fieldDefs) => {
                    callback(newObject, fieldDefs);
                }
            );
        });
    };

    const findAllObjects = (query, foundObjects, totalNumber, callback) => {
        if (foundObjects.length === totalNumber) {
            callback(foundObjects);
        } else {
            const newQuery = {...query, startRow: foundObjects.length };
            TimeEditAPI.findObjects(newQuery, (objects) => {
                findAllObjects(newQuery, [...foundObjects, ...objects], totalNumber, callback);
            });
        }
    };

    const processSecondaryObjects = (definition, allDefs, allMembers, template, fieldDefs, noObjects, callback) => {
        findSecondaryObjects(definition, secondaryType, secondaryConnection === CONNECTION.MEMBERSHIP, secondaryObjects => {
            allMembers = allMembers.concat(doCreateMembers(definition, secondaryObjects ? secondaryObjects : false, template, fieldDefs, noObjects));
            if (allDefs.length > 0) {
                processSecondaryObjects(allDefs.shift(), allDefs, allMembers, template, fieldDefs, noObjects, callback);
            } else {
                callback(allMembers);
            }
        });
    };

    const createMembers = (query, noObjects, template, fieldDefs) => {
        let allMembers: any = [];
        const postLoad = (allObjects) => {
            if (secondaryType) {
                TimeEditAPI.exportObjectsBatch(allObjects.map(o => o.id), definitions => {
                    // Find related or members of the secondary type
                    // Find the secondary object to use among those members or related
                    let numHandled = 0;
                    let allDefs = [...definitions];
                    processSecondaryObjects(allDefs.shift(), allDefs, allMembers, template, fieldDefs, noObjects, result => {
                        saveMembers(result);
                    });
                });
            } else {
                allObjects.forEach(object => {
                    allMembers = allMembers.concat(doCreateMembers(object, false, template, fieldDefs, noObjects));
                });
                saveMembers(allMembers);
            }
        }
        if (query.extid) { // If we get objects from AM, move on with creation, no need to load
            postLoad([query]);
        } else {
            TimeEditAPI.findObjects(query, (objects, totalNumber) => {
                if (objects.length === totalNumber) {
                    postLoad(objects);
                } else {
                    findAllObjects(query, objects, totalNumber, postLoad);
                }
            });
        }
    };

    const findSecondaryObjects = (parentObject, secondaryType, isMembership, callback) => {
        const potentialSecondaries = isMembership ? (parentObject.members || []).map(member => member.id) : _.flatten(parentObject.related || []).map(related => related.id);
        TimeEditAPI.exportObjectsBatch(potentialSecondaries, definitions => {
            callback(definitions.filter(def => _.some(def.types, type => type === secondaryType)));
        });
    };

    const doCreateMembers = (parentObject, secondaryObjects, template, fieldDefs, count) : any[] => {
        let members: any = [];
        for (let i = 0; i < count; i++) {
            const objectNames = defineName(parentObject, i);
            const newMember = createMember(
                template,
                fieldDefs,
                objectNames,
                parentObject,
                primaryConnection
            );
            if (secondaryType && secondaryObjects) {
                // If secondary connection
                secondaryObjects.forEach(secondaryObject => {
                    if (secondaryConnectionAs === CONNECTION.MEMBERSHIP) {
                        newMember.members.push({
                            class: "objectid",
                            id: secondaryObject.id,
                            name: secondaryObject.extid,
                        });
                    } else {
                        newMember.related.push([{
                            class: "objectid",
                            id: secondaryObject.id,
                            name: secondaryObject.extid,
                        }]);
                    }
                });
            }
            members.push(newMember);
        }
        return members;
    };

    const defineName = (object, i) => {
        let selectedField = object.fields.find(field => field.id === nameField) || object.fields[0];
        const longName = `${selectedField.values.join("").replace(/[ ,ö,ä,å,Ö,Ä,Å]/g, "")}`;

        const shortName = `${longName.substring(0, 5) + longName.substring(longName.length - 1)}`;

        const baseName = `${preFixedValue ? preFixedValue : ""}${shortName}${fixedValue ? fixedValue : ""}`;

        const longBaseName = `${preFixedValue ? preFixedValue : ""}${longName}${fixedValue ? fixedValue : ""}`;

        let sfx = i + 1;
        if (firstSuffix && firstSuffix !== "") {
            if (suffix === SUFFIX.ALPHA) {
                sfx = sfx + (firstSuffix.charCodeAt(0)-1);
                sfx = String.fromCharCode(sfx);
            } else if (suffix === SUFFIX.NUM) {
                sfx = sfx + (parseInt(firstSuffix, 10)-1);
                if (firstSuffix.indexOf("0") === 0 && sfx < 10) {
                    sfx =  `0${sfx}`;
                }
            }
        } else if (suffix === SUFFIX.ALPHA) {
            sfx = String.fromCharCode("A".charCodeAt(0) + i);
        }
        if (suffix ===SUFFIX.NONE) {
            sfx = "";
        }
        const fullName = `${baseName}${sfx}`;
        const longFullName = `${longBaseName}${sfx}`
        return {shortName: fullName, longName: longFullName};
    };

    const createMember = (template, fieldDefs, { shortName, longName}, parent, connection) => {
        let newObject = JSON.parse(JSON.stringify(template));
        newObject.fields.forEach((field) => {
            const def = fieldDefs.find((d) => {
                return d.id === field.id;
            });
            if (!def.mandatory) {
                return;
            }

            // Use the long name if it fits. Otherwise use the short name, and shorten it if needed
            if (longName.length <= def.length) {
                field.values.push(longName);
                return;
            }
            if (shortName.length > def.length) {
                field.values.push(`
                ${shortName.substring(0, def.length - 1) + shortName.substring(shortName.length - 1)}
                `);
                return;
            }
            field.values.push(shortName);
        });
        primaryMembersAndRelated(newObject, parent, connection);
        if (longName.length < 73) {
            newObject.extid = longName;
        } else {
            newObject.extid = shortName;
        }
        return newObject;
    };

    const primaryMembersAndRelated = (newObject, parent, connection) => {
        if (connection === CONNECTION.RELATION) {
            newObject.related.push([{ class: "objectid", id: parent.id, name: parent.name }]);
            return;
        }
        newObject.members.push({ class: "objectid", id: parent.id, name: parent.name });
    };

    const saveMembers = (members) => {
        TimeEditAPI.importObjectsBatch(members, false, "ignore", (results) => {
            for (let i = 0; i < members.length; i++) {
                members[i].status = results[i].details;
                if (results[i].message === undefined) {
                    members[i].id = results[i].reference;
                    members[i].status = Language.get("nc_successfully_created");
                } else if (results[i].result === -2014) {
                    members[i].status = `${results[i].details}. ${Language.get(
                        "nc_contact_admin_for_setup"
                    )}`;
                }
            }
            /*mixpanel.track("Object creator created objects", {
                numberOfObjects: members.length
            });*/
            props.callback(members);
        });
    };

    const uiButtons = () => {
        return (
            <div style={{textAlign: "right"}}>
                <button
                    onClick={onSubmit}
                    data-cy="create-objects-btn"
                    className="uiText teButtonGroupManagement"
                    disabled={secondaryConnection !== CONNECTION.NONE && secondaryConnectionAs === CONNECTION.NONE}
                >
                    {Language.get("nc_create_x", creationTypeName)}
                </button>
            </div>
        );
    };

    const wrapStyle = { padding: "1em", display: "flex" };
    const flexStyle = { display: "flex" };
    const boxStyle = { padding: "0 8px 8px 0" };
    const nameStyle = { minWidth: "150px" };

    return (
        <div className="mocModal" style={wrapStyle}>
            <div data-cy="object-creator-interface" style={{width: "50%"}}>
                <label>{Language.get("nc_define_objects")}</label>

                {props.groupRequests.length >= 1 ? (
                    <div data-cy="form-input" style={boxStyle}>

                        <div style={boxStyle}>
                            <div>{Language.get("nc_set_primary_connection_for_objects")}</div>
                            <label>
                                <input
                                    type="radio"
                                    value={CONNECTION.MEMBERSHIP}
                                    data-cy="membership-btn"
                                    checked={primaryConnection === CONNECTION.MEMBERSHIP}
                                    onChange={(evt) => {
                                        setPrimaryConnection(evt.target.value);
                                    }}
                                />
                                {Language.get("nc_object_select_settings_membership")}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value={CONNECTION.RELATION}
                                    data-cy="relationship-btn"
                                    checked={primaryConnection === CONNECTION.RELATION}
                                    onChange={(evt) => {
                                        setPrimaryConnection(evt.target.value);
                                    }}
                                />
                                {Language.get("nc_relation")}
                            </label>
                        </div>

                        <div style={boxStyle}>
                            <div>{Language.get("nc_connection_secondary_type")}</div>
                            <select
                                value={secondaryConnection}
                                onChange={(evt) => setSecondaryConnection(evt.target.value)}
                                data-cy="secondary-connection"
                            >
                                <option value={CONNECTION.NONE}></option>
                                <option value={CONNECTION.MEMBERSHIP}>
                                    {Language.get("nc_object_select_settings_membership")}
                                </option>
                                <option value={CONNECTION.RELATION}>
                                    {Language.get("nc_relation")}
                                </option>
                            </select>
                            <select
                                id="secondary"
                                onChange={(evt) => {
                                    setSecondaryType(parseInt(evt.target.value, 10));
                                    let e = (document as any).getElementById("secondary");
                                }}
                                disabled={secondaryConnection === CONNECTION.NONE}
                                data-cy="secondary-type"
                            >
                                {dropDownOptions(types)}
                            </select>
                            <select
                                value={secondaryConnectionAs}
                                onChange={(evt) => setSecondaryConnectionAs(evt.target.value)}
                                data-cy="secondary-connection"
                                disabled={secondaryConnection === CONNECTION.NONE}
                            >
                                <option value={CONNECTION.NONE}></option>
                                <option value={CONNECTION.MEMBERSHIP}>
                                    {Language.get("nc_object_select_settings_membership")}
                                </option>
                                <option value={CONNECTION.RELATION}>
                                    {Language.get("nc_relation")}
                                </option>
                            </select>
                        </div>

                        <div style={boxStyle}>{MOCFormPreview(props.groupRequests)}</div>

                        <div style={flexStyle}>{uiButtons()}</div>
                    </div>
                ) : (
                    <div data-cy="manual-input">
                        <div style={boxStyle}>
                            <div data-cy="number-of-objects">
                                {Language.get("nc_number_of_x", creationTypeName)}
                            </div>
                            <div style={flexStyle} data-cy="number-input">
                                <NumberInput
                                    defaultValue={numberOfObjects}
                                    onUpdate={setNumberOfObjects}
                                />
                            </div>
                        </div>

                        <div style={boxStyle}>
                            <div>{Language.get("nc_type")}</div>
                            <select
                                data-cy="select-object-type"
                                onChange={updateMemberList}
                                id="type"
                            >
                                {dropDownOptions(types)}
                            </select>
                        </div>

                        <div style={boxStyle}>
                            <div>{Language.get("nc_connection_primary_type")}</div>
                            <select
                                value={primaryConnection}
                                onChange={(evt) => setPrimaryConnection(evt.target.value)}
                                data-cy="primary-connection"
                            >
                                <option value={CONNECTION.MEMBERSHIP}>
                                    {Language.get("nc_object_select_settings_membership")}
                                </option>
                                <option value={CONNECTION.RELATION}>
                                    {Language.get("nc_relation")}
                                </option>
                            </select>
                            <select
                                onChange={(evt) => updatePrimaryType(parseInt(evt.target.value, 10), undefined)}
                                data-cy="primary-type"
                                id="primary"
                            >
                                {dropDownOptions(types)}
                            </select>
                        </div>

                        <div style={boxStyle}>
                            <div>{Language.get("nc_connection_secondary_type")}</div>
                            <select
                                value={secondaryConnection}
                                onChange={(evt) => setSecondaryConnection(evt.target.value)}
                                data-cy="secondary-connection"
                            >
                                <option value={CONNECTION.NONE}></option>
                                <option value={CONNECTION.MEMBERSHIP}>
                                    {Language.get("nc_object_select_settings_membership")}
                                </option>
                                <option value={CONNECTION.RELATION}>
                                    {Language.get("nc_relation")}
                                </option>
                            </select>
                            <select
                                id="secondary"
                                onChange={(evt) => {
                                    setSecondaryType(parseInt(evt.target.value, 10));
                                    let e = (document as any).getElementById("secondary");
                                }}
                                disabled={secondaryConnection === CONNECTION.NONE}
                                data-cy="secondary-type"
                            >
                                {dropDownOptions(types)}
                            </select>
                            <div>{Language.get("nc_connection_secondary_type_as")}</div>
                            <select
                                value={secondaryConnectionAs}
                                onChange={(evt) => setSecondaryConnectionAs(evt.target.value)}
                                data-cy="secondary-connection-as"
                                disabled={secondaryConnection === CONNECTION.NONE}
                            >
                                <option value={CONNECTION.NONE}></option>
                                <option value={CONNECTION.MEMBERSHIP}>
                                    {Language.get("nc_object_select_settings_membership")}
                                </option>
                                <option value={CONNECTION.RELATION}>
                                    {Language.get("nc_relation")}
                                </option>
                            </select>
                        </div>

                        <div style={boxStyle}>
                            {uiButtons()}
                        </div>
                    </div>
                )}
            </div>

            <div>
                <div data-cy="object-creator-interface" style={{width: "50%"}}>
                    <label>{Language.get("nc_membership_naming")}</label>

                    <div style={boxStyle}>
                        <span>{Language.get("nc_fixed_value")}</span>
                        <div>
                            <input
                                style={nameStyle}
                                type="text"
                                onChange={(evt) => setPreFixedValue(evt.target.value)}
                            />
                        </div>
                    </div>

                    <div style={boxStyle}>
                        <span>{Language.get("nc_name_field")}</span>
                        <div>
                            <select
                                onChange={(evt) => setNameField(parseInt(evt.target.value, 10))}
                                data-cy="object-field-value"
                                style={nameStyle}
                            >
                                {dropDownOptions(fields)}
                            </select>
                        </div>
                    </div>

                    <div style={boxStyle}>
                        <span>{Language.get("nc_fixed_value")}</span>
                        <div>
                            <input
                                style={nameStyle}
                                type="text"
                                onChange={(evt) => setFixedValue(evt.target.value)}
                            />
                        </div>
                    </div>

                    <div style={boxStyle}>
                        <span>{Language.get("nc_suffix")}</span>
                        <div>
                            <select
                                name="Suffix"
                                onChange={(evt) => {
                                    const newSuffix = evt.target.value;
                                    if (newSuffix === SUFFIX.ALPHA) {
                                        setFirstSuffix("A");
                                    } else if (newSuffix === SUFFIX.NUM) {
                                        setFirstSuffix("01");
                                    } else {
                                        setFirstSuffix("");
                                    }
                                    setSuffix(newSuffix)
                                }}
                                data-cy="suffix"
                                style={nameStyle}
                            >
                                <option value={SUFFIX.ALPHA}>
                                    {Language.get("nc_suffix_alphabetical")}
                                </option>
                                <option value={SUFFIX.NUM}>
                                    {Language.get("nc_suffix_numerical")}
                                </option>
                                <option value={SUFFIX.NONE}>{Language.get("nc_suffix_none")}</option>
                            </select>
                        </div>
                    </div>
                    <div style={boxStyle}>
                        <span>{Language.get("nc_first_suffix")}</span>
                        <div>
                            <input
                                type="text"
                                name="Suffix"
                                onChange={(evt) => setFirstSuffix(evt.target.value)}
                                value={firstSuffix}
                                data-cy="first-suffix"
                                style={nameStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MOCSettings;
