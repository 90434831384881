const API = require("../lib/TimeEditAPI");
const McFluffy = require("./McFluffy");
const Model = require("./Model");
const Reservation = require("./Reservation");
const ReservationStatus = require("../lib/ReservationStatus");
const ReservationConstants = require("../lib/ReservationConstants");
const Log = require("../lib/Log");
const _ = require("underscore");
const { ClusterKind } = require("../lib/EntryConstants");

const Selection = function () {
    Model.call(this, "Selection");
    this.fluffy = null;
    this.mode = Selection.DEFAULT;
    this.editedEntry = null;
    this.timeLimit = null;
    this.availableObjects = true;
    this.selectNextItem = false;
    this.reservations = [];
    this.groups = [];
    this.isGroupMode = false;
    this.length = false; // Holds length of a selected reservation on the waiting list
    this.obstacleTextTypes = [];
    this.snapshots = [];
    this.currentSnapshot = -1;
    this.duration = false; // Holds duration from activity manager
};

Selection.DEFAULT = 0;
Selection.EDIT = 1;
Selection.WAITING_LIST = 2;

const SNAPSHOT_LIMIT = 20;

Selection.prototype = Object.create(Model.prototype);

Selection.prototype.createFluffy = function (templateId, layerId, templateKind, callback) {
    let baseFluffy = McFluffy.create();
    baseFluffy.layer = layerId || 0;
    if (templateKind !== null) {
        baseFluffy.templateKind = templateKind;
    }
    if (templateId !== null) {
        baseFluffy.templateGroupId = templateId;
    }

    baseFluffy = baseFluffy.toJson();
    API.updateMcFluffy(baseFluffy, (result) => {
        const newFluffy = McFluffy.create(result);
        callback(this.setFluffy(newFluffy));
    });
};

Selection.prototype.getCurrentReservationId = function () {
    return this.reservations.length > 0 ? this.reservations[0] : 0;
};

Selection.prototype.setLayer = function (layerId) {
    return this.immutableSet({
        fluffy: this.fluffy.setLayer(layerId),
    });
};

Selection.prototype.setGroups = function (groupIds) {
    return this.immutableSet({
        groups: groupIds,
    });
};

Selection.prototype.enableAddMode = function (groupIds, keepReservations = false) {
    return this.immutableSet({
        groups: groupIds,
        isGroupMode: true,
        reservations: keepReservations ? this.reservations : [],
    });
};

Selection.prototype.toggleAddMode = function (groupIds) {
    return this.immutableSet({
        groups: groupIds,
        isGroupMode: !this.isGroupMode,
    });
};

Selection.prototype.clearBeginEndTimes = function () {
    return this.immutableSet({
        fluffy: this.fluffy.immutableSet({
            beginTime: undefined,
            endTime: undefined,
        }),
    });
};

Selection.prototype.clearReservations = function () {
    return this.disableEditMode().immutableSet({
        reservations: [],
        fluffy: this.fluffy.immutableSet({
            reservation: undefined,
            beginTime: undefined,
            endTime: undefined,
        }),
    });
};

Selection.prototype.belongsToGroup = function () {
    return this.groups.length > 0;
};

Selection.prototype.getGroupIds = function () {
    return this.groups;
};

Selection.prototype.setFluffy = function (
    fluffy,
    clearReservations = this.mode === Selection.DEFAULT
) {
    const newTypes = fluffy.getTypeIds();
    const obstacleTextTypes = this.obstacleTextTypes.filter((textType) =>
        _.contains(newTypes, textType)
    );
    let snapshots = [].concat(this.snapshots);
    let currentSnapshot = snapshots.indexOf(fluffy);
    if (currentSnapshot === -1 && !_.isNullish(fluffy)) {
        if (
            snapshots.length === 0 ||
            !_.isEqual(fluffy.toJson(), snapshots[this.currentSnapshot].toJson())
        ) {
            currentSnapshot = this.currentSnapshot + 1;
            snapshots = _.head(snapshots, currentSnapshot);
            snapshots.push(fluffy);
        } else {
            currentSnapshot = this.currentSnapshot;
        }
    }
    if (snapshots.length > SNAPSHOT_LIMIT) {
        snapshots = _.last(snapshots, SNAPSHOT_LIMIT);
        currentSnapshot = SNAPSHOT_LIMIT - 1;
    }

    let groups = this.groups;
    let reservations = this.reservations;
    if (clearReservations && !this.isGroupMode) {
        groups = [];
        reservations = [];
    }

    return this.immutableSet({
        fluffy,
        snapshots,
        currentSnapshot,
        obstacleTextTypes,
        reservations,
        groups,
    });
};

Selection.prototype.setDuration = function (duration) {
    if (!duration) {
        return this;
    }
    return this.immutableSet({
        duration,
    });
};

Selection.prototype.setSelectNextItem = function (selectNextItem) {
    return this.immutableSet({
        selectNextItem,
    });
};

Selection.prototype.isEditRequest = function () {
    return this.editedEntry.status === ReservationStatus.C_REQUESTED.id;
};

Selection.prototype.isEditMode = function () {
    return this.mode === Selection.EDIT;
};

Selection.prototype.isWaitingListMode = function () {
    return this.mode === Selection.WAITING_LIST;
};

const getTimeLimit = function (entry) {
    const beginTimes = entry.startTimes.map((time) => time.getMts());
    const endTimes = entry.endTimes.map((time) => time.getMts());
    const sortedBeginTimes = [].concat(beginTimes);
    sortedBeginTimes.sort();
    const sortedEndTimes = [].concat(endTimes);
    sortedEndTimes.sort();
    return {
        begin: beginTimes,
        end: endTimes,
        first: sortedBeginTimes[0],
        last: sortedEndTimes[sortedEndTimes.length - 1],
    };
};

Selection.prototype.hasTimeLimit = function () {
    return this.timeLimit !== null;
};

Selection.prototype.setTimeLimit = function (begin, end) {
    let limit = { begin, end };
    if (!end) {
        limit = getTimeLimit(begin);
    }
    return this.immutableSet({
        timeLimit: limit,
    });
};

Selection.prototype.removeTimeLimit = function () {
    return this.immutableSet({
        timeLimit: null,
    });
};

Selection.prototype.setAvailableObjects = function (availableObjects) {
    return this.immutableSet({ availableObjects });
};

Selection.prototype.enableEditMode = function (editedEntry, isGroupMode = false) {
    if (!editedEntry) {
        return this;
    }
    const iGM = isGroupMode || this.isGroupMode;
    return this.immutableSet({
        mode: Selection.EDIT,
        editedEntry,
        editedFluffy: this.fluffy,
        timeLimit: getTimeLimit(editedEntry),
        availableObjects: true,
        isGroupMode: iGM,
        groups: iGM ? this.groups : editedEntry.groups, // If already in group mode, keep those groups
    });
};

Selection.prototype.disableEditMode = function () {
    return this.immutableSet({
        mode: Selection.DEFAULT,
        editedEntry: null,
        editedFluffy: null,
        timeLimit: null,
    });
};

Selection.prototype.disableWaitingListMode = function () {
    return this.immutableSet({
        mode: Selection.DEFAULT,
        length: false,
    });
};

Selection.prototype.finishEditMode = function (
    closeRequest,
    allowAvailabilityOverlap = false,
    successCallback = _.noop,
    errorCallback = _.noop
) {
    let saveFluffy = this.fluffy;
    if (closeRequest && this.isEditRequest()) {
        saveFluffy = McFluffy.create(saveFluffy.toJson());
        saveFluffy.status = [ReservationStatus.C_REJECTED.id];
    } else {
        // Always allow incomplete reservations in edit mode
        saveFluffy = saveFluffy.immutableSet({ allowIncomplete: true });
    }
    Reservation.save(
        this.editedEntry,
        saveFluffy,
        (result, reservationIds) => {
            if (result instanceof Error) {
                if (result.code !== ReservationConstants.ERROR_AVAILABILITY_OVERLAP_POSSIBLE) {
                    Log.warning(result.message);
                }
                errorCallback(result);
            } else {
                successCallback(reservationIds);
            }
        },
        true,
        allowAvailabilityOverlap, // Allow availability overlap
        false
    );
};

Selection.prototype.setFluffyFields = function (fields) {
    return this.setFluffy(this.fluffy.setFields(fields), false);
};

Selection.prototype.isObstacleTextType = function (typeId) {
    return _.contains(this.obstacleTextTypes, typeId);
};

Selection.prototype.addObstacleTextType = function (typeId) {
    return this.immutableSet({ obstacleTextTypes: this.obstacleTextTypes.concat(typeId) });
};

Selection.prototype.removeObstacleTextType = function (typeId) {
    return this.immutableSet({
        obstacleTextTypes: this.obstacleTextTypes.filter((id) => id !== typeId),
    });
};

Selection.prototype.setReservation = function (
    inIds,
    callback,
    modify = false,
    isWaitingList = false,
    length = false,
    isNewReservation = false,
    clusterKind = ClusterKind.NONE
) {
    const reservationIds = _.asArray(inIds);
    if (reservationIds.length === 0) {
        return callback(this, true);
    }
    let resultSetReservationToMcFluffy = false;
    let resultGetReservations = false;
    API.getTemplateGroups(this.fluffy.templateKind.number, (templateGroups) => {
        const templateGroupIds = isWaitingList
            ? [0]
            : templateGroups.parameters[0].map((group) => group.id);

        _.runAsync(
            [
                (done) => {
                    const inFluffy = this.fluffy.toJson();
                    if (inFluffy.reservation && !this.isEditMode()) {
                        delete inFluffy.reservation;
                    }
                    API.setReservationToMcFluffy(
                        reservationIds,
                        inFluffy,
                        modify,
                        templateGroupIds,
                        isNewReservation,
                        (result) => {
                            resultSetReservationToMcFluffy = result;
                            done();
                        }
                    );
                },
                (done) => {
                    API.getReservations(reservationIds, (reservations) => {
                        resultGetReservations = reservations;
                        done();
                    });
                },
            ],
            () => {
                if (
                    resultSetReservationToMcFluffy.parameters[0] === null ||
                    reservationIds.length === 0 ||
                    resultGetReservations.length === 0
                ) {
                    // We get no fluffy if we asked for a non-existant reservation ID
                    return callback(this, false);
                }
                if (modify && !resultSetReservationToMcFluffy.parameters[1]) {
                    return callback(this, false);
                }
                const fluffy = McFluffy.create(
                    resultSetReservationToMcFluffy.parameters[0],
                    resultGetReservations[0].objects
                );
                fluffy.rules = resultSetReservationToMcFluffy.parameters[2];
                let newSelection = this.setFluffy(fluffy, false);
                const mode = isWaitingList ? Selection.WAITING_LIST : Selection.DEFAULT;
                newSelection = newSelection.immutableSet({ mode, length, clusterKind });
                if (isNewReservation && !this.isGroupMode) {
                    newSelection = newSelection.setGroups([]);
                }
                return callback(
                    newSelection.immutableSet({ reservations: reservationIds }),
                    resultSetReservationToMcFluffy.parameters[1]
                );
            }
        );
    });
    return null;
};

Selection.prototype.hasSnapshots = function (hasPrevious) {
    if (hasPrevious === undefined) {
        return this.snapshots.length > 0;
    }

    if (hasPrevious === true) {
        return this.currentSnapshot > 0;
    }

    return this.currentSnapshot < this.snapshots.length - 1;
};

Selection.prototype.getNextSnapshot = function () {
    return this.snapshots[this.currentSnapshot + 1];
};

Selection.prototype.getPreviousSnapshot = function () {
    return this.snapshots[this.currentSnapshot - 1];
};

module.exports = Selection;
