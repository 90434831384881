const React = require("react");

class BooleanInput extends React.Component {
    state = {
        value: this.props.defaultValue,
        style: this.props.style || BooleanInput.DEFAULT,
    };

    componentDidUpdate() {
        if (this.props.defaultValue !== this.state.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: this.props.defaultValue });
        }
    }

    returnValue = (evt) => {
        const val = evt.target.checked;
        this.setState({ value: val });
        this.props.onUpdate(val);
    };

    render() {
        const box = (
            <input
                type="checkbox"
                checked={this.state.value}
                onChange={this.returnValue}
                disabled={this.props.disabled}
            />
        );
        if (this.state.style === BooleanInput.TOGGLE) {
            return (
                <label className="switch">
                    {box}
                    <span className="slider" />
                </label>
            );
        }
        return box;
    }
}

BooleanInput.defaultProps = {
    disabled: false,
};

BooleanInput.DEFAULT = "default";
BooleanInput.TOGGLE = "toggle";

module.exports = BooleanInput;
