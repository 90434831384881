const React = require("react");
import { Table } from "@timeedit/tecore-table";
const ContextMenu = require("../lib/ContextMenu");
const Log = require("../lib/Log");
const Language = require("../lib/Language");
const _ = require("underscore");
const TimeEdit = require("../lib/TimeEdit");
const API = require("../lib/TimeEditAPI");
const PropTypes = require("prop-types");
const getMembershipColumn = require("../lib/MembershipConstants");

class ObjectList extends React.Component {
    state = {
        objects: [],
        selectedColumns: {},
    };

    static contextTypes = {
        presentModal: PropTypes.func,
    };

    componentDidMount() {
        this._isMounted = true;
        this.load(this.props);

        API.getPreferences("multiObjectSelectColumns", [], (result) => {
            if (!result || result.length === 0) {
                return;
            }

            const newSettings = JSON.parse(result[0]);
            this.setState({ selectedColumns: newSettings }, () => {
                if (this.props.searchObject) {
                    const type = this.props.searchObject.type;
                    if (
                        this.state.selectedColumns[type] &&
                        !_.isEqual(
                            this.props.searchObject.defaultColumns,
                            this.state.selectedColumns[type]
                        )
                    ) {
                        const newSearch = this.props.searchObject.setDefaultColumns(
                            this.state.selectedColumns[type]
                        );
                        this.props.updateSearchObject(newSearch);
                    }
                }
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.load(this.props);
        }
        if (!this.props.searchObject) {
            return;
        }
        const type = this.props.searchObject.type;
        if (prevProps.searchObject.type !== type) {
            if (this.state.selectedColumns[type]) {
                const newSearch = this.props.searchObject.setDefaultColumns(
                    this.state.selectedColumns[type]
                );
                this.props.updateSearchObject(newSearch);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    load = (props) => {
        props.loadFunction(props.objects, (objects) => {
            if (this._isMounted) {
                this.setState({ objects: objects.filter((obj) => obj !== null) });
            }
        });
    };

    onTableColumnsChange = (columns) => {
        const searchObject = this.props.searchObject.setDefaultColumns(columns);
        this.props.updateSearchObject(searchObject);
        const allColumns = _.clone(this.state.selectedColumns);
        allColumns[searchObject.type] = searchObject.defaultColumns;
        API.setPreferences("multiObjectSelectColumns", [], [JSON.stringify(allColumns)], _.noop);
        this.setState({ selectedColumns: allColumns });
    };

    isRowLoaded = (index) => this.state.objects[index] !== undefined;

    // eslint-disable-next-line no-unused-vars
    loadMoreRows = ({ startIndex, stopIndex }) => {
        this.load(this.props);
    };

    getTableRow = ({ index }) => {
        const object = this.state.objects[index];
        const result = {};
        this.props.searchObject.defaultColumns.forEach((column) => {
            if (!object) {
                result[column.name] = "-";
            } else {
                const values = _.find(object.fields, (field) => field.id === column.id);
                if (!values || column.id === "_admin_color_") {
                    if (column.id === "type") {
                        result[column.name] = this.getTypeName(
                            object.typeId,
                            this.props.options,
                            TimeEdit.rootType
                        );
                    } else if (column.id === "_admin_color_") {
                        result[column.name] = object.color || "-";
                    } else if (
                        column.id === getMembershipColumn().id &&
                        this.props.presentMembershipPeriod
                    ) {
                        result[column.name] = this.props.presentMembershipPeriod(object.id);
                    } else if (column.id === "placeholder") {
                        let value = object.fields[0].values.join(", ");
                        if (!value) {
                            value = object.name || object.extid || object.id;
                        }
                        result[column.name] = value;
                    } else {
                        result[column.name] = "";
                    }
                } else {
                    result[column.name] = values.values ? values.values.join(", ") : "";
                }
            }
        });
        return result;
    };

    render() {
        let count = null;
        if (this.props.totalNumber) {
            count = `(${this.state.objects.length}/${this.props.totalNumber})`;
        }
        const classNames = ["selectBox"];
        if (this.props.cssClass) {
            classNames.push(this.props.cssClass);
        }
        if (this.props.searchObject) {
            const allColumns = (this.props.searchObject.columns || []).concat(
                getMembershipColumn()
            );
            const allColIds = allColumns.map((column) => column.id);
            const columns = (this.props.searchObject.defaultColumns || []).filter(
                (column) => allColIds.indexOf(column.id) !== -1
            );
            const flexStyle = this.props.flex ? { display: "flex", flex: "1 1 auto" } : {};
            return (
                <div style={flexStyle}>
                    {" "}
                    {count}
                    <div
                        className={classNames.join(" ")}
                        style={{
                            display: "flex",
                            flexGrow: "1",
                            flexDirection: "column",
                            height: this.props.flex ? undefined : "200px",
                            overflow: "hidden",
                        }}
                    >
                        <Table
                            language={Language}
                            log={Log}
                            columns={columns}
                            cellRenderers={this.props.cellRenderers || undefined}
                            allColumns={this.props.hidePlusButton ? undefined : allColumns}
                            columnWidths={[]}
                            isRowLoaded={this.isRowLoaded}
                            loadMoreRows={this.loadMoreRows}
                            presentModal={this.context.presentModal}
                            contextMenu={ContextMenu}
                            rowCount={this.state.objects.length}
                            onSelect={(index, rowData, event) =>
                                this.props.onChange(
                                    index,
                                    rowData,
                                    event,
                                    this.state.objects[index]
                                )
                            }
                            getTableRow={this.getTableRow}
                            onColumnChange={this.onTableColumnsChange}
                            maxColumns={10}
                            hoverButton={this.props.hoverButton}
                            highlight={
                                this.props.allowMultiSelection
                                    ? Table.HIGHLIGHT.MULTI
                                    : Table.HIGHLIGHT.SINGLE
                            }
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className={classNames.join(" ")}>
                <div className="columnTitles columnHeader">
                    <span>
                        {this.props.columnTitle} {count}
                    </span>
                </div>
                <select size={this.props.listSize} onChange={this.props.onChange}>
                    {this.state.objects.map((object) => {
                        const name = object.name || object.extid || object.id;
                        return (
                            <option key={object.id} value={object.id}>
                                {name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}

module.exports = ObjectList;
