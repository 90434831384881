const React = require("react");
const _ = require("underscore");

const SECONDS_PER_HOUR = 3600;
const SECONDS_PER_MINUTE = 60;

const MIN_HEIGHT = 15;
const MIN_WIDTH = 25;
const SMALL_WIDTH = 40;
const SMALL_HEIGHT = 25;

const getLengthString = (length) => {
    const lengthHours = Math.floor(length / SECONDS_PER_HOUR);
    const remainingSeconds = lengthHours > 0 ? length % (lengthHours * SECONDS_PER_HOUR) : length;
    const lengthMinutes = Math.floor(remainingSeconds / SECONDS_PER_MINUTE);
    // eslint-disable-next-line no-magic-numbers
    const lengthMinutesString = lengthMinutes < 10 ? `0${lengthMinutes}` : String(lengthMinutes);
    return `${lengthHours}:${lengthMinutesString}`;
};

const EntryLength = (props) => {
    if (props.containerHeight < MIN_HEIGHT || props.containerWidth < MIN_WIDTH) {
        return null;
    }

    const lengthString = getLengthString(props.length);

    let style = {
        display: "block",
        position: "absolute",
        width: "100%",
        textAlign: "center",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "14px",
    };
    if (props.containerWidth < SMALL_WIDTH || props.containerHeight < SMALL_HEIGHT) {
        style = _.extend(style, {
            fontSize: 11,
            lineHeight: "11px",
        });
    }
    style = _.extend(style, {
        // eslint-disable-next-line no-magic-numbers
        top: (props.containerHeight - style.fontSize) / 2,
    });
    return (
        <span style={style} key="len">
            {lengthString}
        </span>
    );
};

module.exports = EntryLength;
