const React = require("react");
const Language = require("../lib/Language");
const PropTypes = require("prop-types");
const Viewer = require("../lib/Viewer");
const _ = require("underscore");
const ArrayInput = require("./ArrayInput");
const API = require("../lib/TimeEditAPI");

class AdminPane extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = "AdminPane";
        this.TARGET = "_blank";
        this.state = {
            settings: {},
            viewerPages: [],
        };
    }

    componentDidMount() {
        Viewer.findPages(this.context.user.language, (pages) =>
            this.setState({ viewerPages: pages })
        );
        API.getPreferences("viewerPageCurrent", [], "all", (result) => {
            const value = !_.isEmpty(result) && result.length > 0 ? result[0] : "";
            const obj = _.clone(this.state.settings);
            obj.viewerPageCurrent = value;
            this.setState({ settings: obj });
        });
    }

    openAdminUI(event) {
        window.open("admin", this.TARGET);
        event.preventDefault();
    }

    renderAdminLinks() {
        if (this.context.user.isAdmin) {
            return [
                <p key="desc">{Language.get("nc_admin_link_description")}</p>,
                <form key="adminForm">
                    <ul key="link">
                        <li key="1" className="btnGroup">
                            <button className="default" onClick={this.openAdminUI.bind(this)}>
                                {Language.get("admin_main_menu_title")}{" "}
                                <div className="whiteArrow" />
                            </button>
                        </li>
                        <li key="2" />
                        <li key="3">{this.getFields().map(this.renderField.bind(this))}</li>
                    </ul>
                </form>,
            ];
        }
        return null;
    }

    getValue(field) {
        if (this.state.settings.hasOwnProperty(field)) {
            return this.state.settings[field];
        }
        return "";
    }

    getFields() {
        const renderEntry = (entry, list) => {
            const item = {
                value: entry.name,
                label: entry.name,
                disabled: true,
                selected: false,
            };
            return [item, ...list];
        };
        const renderViewerPages = (options) =>
            options
                .filter((option) => option.type === "ScheduleCreationController")
                .map((option) => ({
                    value: option.urlstart,
                    label: option.name,
                    selected: this.getValue("viewerPageCurrent") === option.urlstart,
                }));
        const emptyItem = {
            value: "",
            label: "",
            selected: false,
        };
        const renderAllPages = () => {
            const pages = _.flatten(
                this.state.viewerPages.map((entry) =>
                    renderEntry(entry, renderViewerPages(entry.pages))
                )
            );
            if (pages.length === 0) {
                return [];
            }
            return [emptyItem, ...pages];
        };
        try {
            const pageValues = renderAllPages();
            const viewerPageCurrent =
                Viewer.isEnabled(this.context.env) && pageValues.length > 0
                    ? {
                          label: Language.get("nc_open_in_te_viewer"),
                          field: "viewerPageCurrent",
                          type: "array",
                          values: pageValues,
                      }
                    : null;
            return viewerPageCurrent ? [viewerPageCurrent] : [];
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return [];
        }
    }

    setValue(item, event) {
        API.setDefaultPreferences(item.field, [event], _.noop);
        const obj = _.clone(this.state.settings);
        const value = event;
        obj[item.field] = value;
        this.setState({ settings: obj });
    }

    renderField(item) {
        const onChange = this.setValue.bind(this, item);
        const input = (
            <ArrayInput
                defaultValue={item.values}
                disabled={item.disabled}
                onUpdate={onChange}
                defaultSize={item.defaultSize || 0}
                limit={item.limit || 1}
            />
        );
        return (
            <label title={item.label} key={`Admin${item.field}`}>
                <span>{item.label}</span> {input}
            </label>
        );
    }

    render() {
        return (
            <div className="adminPane">
                <h2>{Language.get("user_permission_admin")}</h2>
                {this.renderAdminLinks()}
            </div>
        );
    }
}

AdminPane.contextTypes = {
    user: PropTypes.object,
    env: PropTypes.object,
};

module.exports = AdminPane;
