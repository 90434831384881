const React = require("react");
import { MillenniumTime } from "@timeedit/millennium-time";

const MAX_HOUR = 24;
const SHORTEST_TIME_ARRAY = 2;
const MAX_MINUTE = 59;

class TimeInput extends React.Component {
    state = {
        value: this.props.defaultValue.format("HH:mm"),
    };

    componentDidUpdate(prevProps) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            const value = this.props.defaultValue.format("HH:mm");
            if (value !== this.state.value) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ value });
            }
        }
    }

    onChange = (evt) => {
        this.setState({ value: evt.target.value });
    };

    returnValue = () => {
        const timeArray = this.state.value.split(":").map((str) => parseInt(str, 10));
        if (timeArray[0] > MAX_HOUR || timeArray[0] < 0 || timeArray.length < SHORTEST_TIME_ARRAY) {
            this.setState({ value: this.props.defaultValue.format("HH:mm") });
            //this.props.onUpdate(this.props.defaultValue);
            return;
        }
        if (timeArray[1]) {
            if (timeArray[1] > MAX_MINUTE || timeArray[1] < 0) {
                timeArray[1] = 0;
            }
        }
        const date = new MillenniumTime(timeArray[0], timeArray[1], 0);
        this.props.onUpdate(date);
    };

    render() {
        return (
            <input
                type="text"
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.returnValue}
            />
        );
    }
}

module.exports = TimeInput;
