const PropTypes = require("prop-types");
const React = require("react");
const _ = require("underscore");

class Accordion extends React.Component {
    static propTypes = {
        sections: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                content: PropTypes.element,
            })
        ).isRequired,
        onContentChange: PropTypes.func,
        initialSection: PropTypes.arrayOf(PropTypes.number),
    };

    state = {
        active: this.props.initialSection ? _.asArray(this.props.initialSection) : [0],
    };

    setActive = (id) => {
        if (this.isActive(id)) {
            this.setState({ active: this.state.active.filter((index) => index !== id) });
            return;
        }

        this.setState({ active: [id].concat(this.state.active) });
        return;
    };

    isActive = (index) => _.contains(this.state.active, index);

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.initialSection, prevProps.initialSection)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                active: this.props.initialSection ? _.asArray(this.props.initialSection) : [0],
            });
        }
        if (this.props.onContentChange && !_.isEqual(this.state.active, prevState.active)) {
            this.props.onContentChange(this.state.active);
        }
    }

    render() {
        const classes = {
            accordion: true,
        };

        return (
            <div className={_.classSet(classes)}>
                {this.props.sections.map(function (section, index) {
                    const activeClass = this.isActive(index) ? "active" : "inactive";
                    const marker = section.isMarked ? (
                        <span className={"membershipExceptionIndicatorDark"} />
                    ) : null;
                    return (
                        <div key={index} className={activeClass}>
                            <h3 onClick={this.setActive.bind(this, index)}>
                                {section.title} {marker}
                            </h3>
                            <div className="accordionSection">{section.content}</div>
                        </div>
                    );
                }, this)}
            </div>
        );
    }
}

module.exports = Accordion;
