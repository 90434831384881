const _ = require("underscore");

const isLegalGroup = (useNewReservationGroups, selectionGroup) => {
    if (selectionGroup.length === 0) {
        return false;
    }
    if (useNewReservationGroups) {
        return true;
    }
    // All clusters need to contain the same number of reservations
    const clusterDepth = selectionGroup[0].reservationids.length;
    if (_.every(selectionGroup, (group) => group.reservationids.length === clusterDepth)) {
        // All entries need to be on the same day
        if (clusterDepth === 1) {
            const firstStart = selectionGroup[0].startTimes[0].getMillenniumDate().getDayNumber();
            const firstEnd = selectionGroup[0].endTimes[0].getMillenniumDate().getDayNumber();
            if (firstStart !== firstEnd) {
                return false;
            }
            return _.every(selectionGroup, (entry) =>
                _.every(
                    entry.startTimes,
                    (startTime, index) =>
                        startTime.getMillenniumDate().getDayNumber() === firstStart &&
                        entry.endTimes[index].getMillenniumDate().getDayNumber() === firstStart
                )
            );
        }
        // All clusters need to be in same "time column"
        const dates = selectionGroup[0].startTimes.map((dateTime) =>
            dateTime.getMillenniumDate().getDayNumber()
        );
        return _.every(selectionGroup, (group) =>
            _.isEqual(
                dates,
                group.startTimes.map((dateTime) => dateTime.getMillenniumDate().getDayNumber())
            )
        );
    }
    return false;
};

const isValidGroupEntry = (useNewReservationGroups, editedEntry, fluffy, editedFluffy) => {
    if (useNewReservationGroups) {
        return true;
    }
    return (
        editedEntry &&
        editedEntry.startTimes.length > 0 &&
        editedEntry.endTimes.length > 0 &&
        !_.isEqual(fluffy, editedFluffy)
    );
};

module.exports = { isLegalGroup, isValidGroupEntry };
