const Language = require("./Language");
const API = require("../lib/TimeEditAPI");
const Intro = require("intro.js");
const _ = require("underscore");

const DEFAULT_TIMEOUT = 1000;

exports.show = (key, steps, opts = {}) => {
    const defaultOptions = {
        timeout: DEFAULT_TIMEOUT,
        shouldShowTutorial: () => true,
    };
    const options = _.extend({}, defaultOptions, opts);

    API.getPreferences("viewedHelp", (viewedHelpData) => {
        const viewedHelp = JSON.parse(viewedHelpData) || {};
        if (viewedHelp[key] || !options.shouldShowTutorial(viewedHelp)) {
            return;
        }

        const intro = Intro.introJs();
        const finished = () => {
            const updatedViewedHelp = _.extend({}, viewedHelp, { [key]: true });
            API.setPreferences("viewedHelp", [JSON.stringify(updatedViewedHelp)], _.noop);
        };
        intro.oncomplete(finished);
        intro.onexit(finished);
        intro.setOptions({
            nextLabel: `${Language.get("nc_intro_next")} &rarr;`,
            prevLabel: `&larr; ${Language.get("nc_intro_prev")}`,
            skipLabel: Language.get("nc_intro_skip"),
            doneLabel: Language.get("nc_intro_done"),
            steps,
        });

        setTimeout(() => {
            intro.start();
        }, options.timeout);
    });
};
