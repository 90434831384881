const React = require("react");
const createReactClass = require("create-react-class");
const Language = require("../lib/Language");
const MultiSelect = require("./MultiSelect");
const ListSettingsStore = require("./ListSettingsStore");
const RC = require("../lib/ReservationConstants");
const TemplateKind = require("../models/TemplateKind");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");

const getStatuses = () => [
    {
        value: RC.STATUS.CONFIRMED,
        trackingId: "Confirmed",
        label: Language.get("dynamic_reserv_list_confirmed"),
        useWithRequests: false,
    },
    {
        value: RC.STATUS.PRELIMINARY,
        trackingId: "Preliminary",
        label: Language.get("dynamic_reserv_list_preliminary"),
        useWithRequests: false,
    },
    {
        value: RC.STATUS.PLANNED,
        trackingId: "Planned",
        label: Language.get("dynamic_reserv_list_planned"),
        useWithRequests: false,
    },
    {
        value: RC.STATUS.REQUESTED,
        trackingId: "Requested",
        label: Language.get("dynamic_reserv_list_requested"),
        useWithRequests: true,
    },
    {
        value: RC.STATUS.REJECTED,
        trackingId: "Rejected",
        label: Language.get("dynamic_reserv_list_rejected"),
        useWithRequests: true,
    },
];

const ReservationListSettings = createReactClass({
    displayName: "ReservationListSettings",

    // eslint-disable-next-line no-unused-vars
    track(type, value) {
        // eslint-disable-next-line no-undef
        /*mixpanel.track(`${this.props.prefix} settings change`, {
            Type: type,
            Value: value,
        });*/
    },

    componentDidMount() {
        API.findTypes((types) => {
            this.setState({ types });
        });
    },

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.defaultSettings, this.props.defaultSettings)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(this.getInitialState(this.props));
        }
        if (!_.isEqual(this.state, prevState)) {
            const settings = _.clone(this.state);
            this.props.onChange(settings);
        }
    },

    getInitialState(props = this.props) {
        return {
            // Search options
            allReservations: props.allReservations,
            reservationStatus: props.reservationStatus || [RC.STATUS.CONFIRMED],
            includeMembers: props.includeMembers,
            templateKind: props.templateKind,
            complete: props.complete,
            incomplete: props.incomplete,
            permission: props.permission,
            useTemplateGroup: props.useTemplateGroup,
            objectCategories: props.objectCategories || [],
            reservationCategories: props.reservationCategories || [],
            ignoreExceptions: props.ignoreExceptions || false,
            reservationGroupsOnly: props.reservationGroupsOnly || false,
            placeholderType: props.placeholderType || null,
            types: [],
        };
    },

    onPlaceholderTypeChange(event) {
        const typeId = parseInt(event.target.value, 10);
        if (typeId === 0) {
            this.setState({ placeholderType: null });
            return;
        }
        const placeholderType = _.find(this.state.types, (tp) => tp.id === typeId);
        this.track("Placeholder type", placeholderType.name);
        this.setState({ placeholderType });
    },

    onTemplateKindChange(event) {
        const stateChange = { templateKind: TemplateKind.get(parseInt(event.target.value, 10)) };
        this.track("Template kind", stateChange.templateKind.name);
        this.setState(stateChange);
    },

    onCompleteChange(values) {
        if (values.length === 0) {
            return;
        }

        const complete = values.indexOf(RC.STATUS.COMPLETE) !== -1;
        const incomplete = values.indexOf(RC.STATUS.INCOMPLETE) !== -1;

        this.track("Complete", { Complete: complete, Incomplete: incomplete });

        this.setState({
            complete,
            incomplete,
        });
    },

    onUseTemplateGroupChange(event) {
        const useTemplateGroup = event.target.checked;
        this.track("Use template group", useTemplateGroup);
        this.setState({ useTemplateGroup });
    },

    onIgnoreExceptionsChange(event) {
        const ignoreExceptions = event.target.checked;
        this.track("Ignore exceptions", ignoreExceptions);
        this.setState({ ignoreExceptions });
    },

    onReservationGroupsOnlyChange(event) {
        const reservationGroupsOnly = event.target.checked;
        this.track("Reservation groups only", reservationGroupsOnly);
        this.setState({ reservationGroupsOnly });
    },

    onStatusChange(values) {
        if (values.length === 0) {
            return;
        }
        const statuses = getStatuses();
        this.track(
            "Reservation status",
            values.map((value) => _.find(statuses, (st) => st.value === value).trackingId)
        );
        this.setState({ reservationStatus: values });
    },

    onMembersChanged(event) {
        this.track("Include members", RC.toMemberLabel(parseInt(event.target.value, 10)));
        this.setState({ includeMembers: parseInt(event.target.value, 10) });
    },

    onAllReservationsChange(event) {
        this.track("All reservations", event.target.value === RC.ALL);
        this.setState({ allReservations: event.target.value === RC.ALL });
    },

    onPermissionChange(event) {
        this.track("Permission", parseInt(event.target.value, 10) === 0 ? "Read" : "Modify");
        this.setState({ permission: parseInt(event.target.value, 10) });
    },

    onObjectCheckboxChange(fieldId, isReservationFields, event) {
        let value = event.target.value;
        if (value === "1") {
            value = ["1"];
        } else if (value === "0") {
            value = ["0"];
        } else {
            value = [];
        }
        this.onCategoriesChange(fieldId, isReservationFields, value);
    },

    onCategoriesChange(fieldId, isReservationFields, values) {
        const newValues = {};
        newValues[String(fieldId)] = values;
        if (isReservationFields) {
            const finalValues = _.omit(
                _.extend({}, this.state.reservationCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            this.track("Reservation categories", finalValues);
            this.setState({
                reservationCategories: finalValues,
            });
        } else {
            const finalValues = _.omit(
                _.extend({}, this.state.objectCategories, newValues),
                (value) => _.isNullish(value) || (_.isArray(value) && value.length === 0)
            );
            this.track("Object categories", finalValues);
            this.setState({
                objectCategories: finalValues,
            });
        }
    },

    isCategorySelected(fieldId, option, isReservationFields) {
        if (isReservationFields) {
            return (
                this.state.reservationCategories[String(fieldId)] &&
                this.state.reservationCategories[String(fieldId)].indexOf(option) > -1
            );
        }
        return (
            this.state.objectCategories[String(fieldId)] &&
            this.state.objectCategories[String(fieldId)].indexOf(option) > -1
        );
    },

    _renderCategories(title, categories, isReservationFields = false) {
        if (!categories || categories.length === 0) {
            return null;
        }
        const options = categories.map((category) => {
            if (!category.categories) {
                return [];
            }
            return category.categories.map((opt) => ({
                value: opt,
                label: opt,
                selected: this.isCategorySelected(category.id, opt, isReservationFields),
            }));
        });
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {options.map((cats, index) => (
                    <div className="categoryList" key={index}>
                        <label>{categories[index].name}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={cats}
                            onValueChanged={this.onCategoriesChange.bind(
                                this,
                                categories[index].id,
                                isReservationFields
                            )}
                        />
                    </div>
                ))}
            </div>
        );
    },

    getCheckboxValue(checkboxId, isReservationFields) {
        const values = isReservationFields
            ? this.state.reservationCategories[String(checkboxId)]
            : this.state.objectCategories[String(checkboxId)];
        if (values) {
            return values[0];
        }
        return "";
    },

    _renderCheckboxes(title, checkboxes, isReservationFields = false) {
        if (!checkboxes || checkboxes.length === 0) {
            return null;
        }
        return (
            <div className="settingsLine">
                <label>{title}</label>
                {checkboxes.map((checkbox, index) => {
                    const selectedValue = this.getCheckboxValue(checkbox.id, isReservationFields);
                    return (
                        <div className="categoryList" key={index}>
                            <label>{checkbox.name} </label>
                            <select
                                onChange={this.onObjectCheckboxChange.bind(
                                    this,
                                    checkboxes[index].id,
                                    isReservationFields
                                )}
                                value={selectedValue}
                            >
                                <option value={""}>-</option>
                                <option value={1}>{Language.get("dynamic_field_yes")}</option>
                                <option value={0}>{Language.get("dynamic_field_no")}</option>
                            </select>
                        </div>
                    );
                })}
            </div>
        );
    },

    render() {
        let selectOptions = getStatuses().map((option) =>
            _.extend(option, {
                selected: this.state.reservationStatus.indexOf(option.value) !== -1,
            })
        );

        if (this.props.isRequestList === true) {
            selectOptions = selectOptions.filter((option) => option.useWithRequests === true);
        }

        const completeOptions = [
            {
                value: RC.STATUS.COMPLETE,
                label: Language.get("dynamic_reserv_list_complete"),
                selected: this.state.complete,
            },
            {
                value: RC.STATUS.INCOMPLETE,
                label: Language.get("dynamic_reserv_list_incomplete"),
                selected: this.state.incomplete,
            },
        ];

        let completeSelection = null;
        if (this.props.isRequestList !== true) {
            completeSelection = (
                <div className="settingsLine">
                    <label>{Language.get("dynamic_reserv_list_complete_menu")}</label>
                    <MultiSelect
                        className="multiSelect"
                        options={completeOptions}
                        onValueChanged={this.onCompleteChange}
                    />
                </div>
            );
        }
        let reservationType = null;
        if (this.props.isRequestList !== true) {
            reservationType = [
                <div className="settingsLine" key="line">
                    <label>{Language.get("dynamic_reserv_list_reservation_type")}</label>
                    <select
                        value={this.state.templateKind.number}
                        onChange={this.onTemplateKindChange}
                    >
                        <option value={TemplateKind.RESERVATION.number} key="bokning">
                            {Language.get("dynamic_reserv_list_reservation")}
                        </option>
                        <option value={TemplateKind.AVAILABILITY.number} key="tillgänglighet">
                            {Language.get("dynamic_reserv_list_availability")}
                        </option>
                        <option value={TemplateKind.INFO_RESERVATION.number} key="info">
                            {Language.get("dynamic_reserv_list_info")}
                        </option>
                    </select>
                </div>,
                <hr key="hr" />,
            ];
        }

        const placeholderType = [
            <div
                className="settingsLine"
                key="line"
                title={Language.get("nc_lists_placeholder_help")}
            >
                <label>{Language.get("nc_lists_placeholder_type")}</label>
                <select
                    value={this.state.placeholderType ? this.state.placeholderType.id : 0}
                    onChange={this.onPlaceholderTypeChange}
                >
                    <option key="noValue" value={0}>
                        -
                    </option>
                    {this.state.types.map((type) => (
                        <option key={type.id} value={type.id}>
                            {type.name}
                        </option>
                    ))}
                </select>
            </div>,
            <hr key="hr" />,
        ];

        let ignoreExceptions = null;
        if (this.props.hasReservationExceptions) {
            ignoreExceptions = (
                <div className="settingsLine">
                    <input
                        type="checkbox"
                        value={true}
                        checked={this.state.ignoreExceptions}
                        onChange={this.onIgnoreExceptionsChange}
                    />{" "}
                    {Language.get("nc_lists_ignore_exceptions")}
                </div>
            );
        }

        const reservationGroupsOnly = (
            <div className="settingsLine">
                <input
                    type="checkbox"
                    value={true}
                    checked={this.state.reservationGroupsOnly}
                    onChange={this.onReservationGroupsOnlyChange}
                />{" "}
                {Language.get("nc_lists_reservation_groups_only")}
            </div>
        );

        return (
            <div>
                <h3 className="header">{Language.get("nc_list_filter_title")}</h3>

                <ListSettingsStore
                    searchSettings={this.state}
                    onChange={(search) => this.setState(search)}
                    getSavedSettings={this.props.getSavedSettings}
                    saveSettings={this.props.saveSettings}
                    removeSettings={this.props.removeSettings}
                    saveDefaultSettings={this.props.saveDefaultSettings}
                />

                <div className="settingsContainer">
                    <div className="settingsLine">
                        <label>{Language.get("reservation_list_pref_name_mine")}</label>
                        <select
                            onChange={this.onAllReservationsChange}
                            value={this.state.allReservations ? RC.ALL : RC.MINE}
                        >
                            <option key="Alla" value={RC.ALL}>
                                {Language.get("dynamic_reserv_list_all")}
                            </option>
                            <option key="Mina" value={RC.MINE}>
                                {Language.get("dynamic_reserv_list_mine")}
                            </option>
                        </select>
                    </div>
                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_permission")}</label>
                        <select onChange={this.onPermissionChange} value={this.state.permission}>
                            <option value={RC.PERMISSION.READ} key={RC.PERMISSION.READ}>
                                {Language.get("dynamic_reserv_list_read")}
                            </option>
                            <option value={RC.PERMISSION.MODIFY} key={RC.PERMISSION.MODIFY}>
                                {Language.get("dynamic_reserv_list_modify")}
                            </option>
                        </select>
                    </div>
                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_members")}</label>
                        <select onChange={this.onMembersChanged} value={this.state.includeMembers}>
                            <option value={RC.MEMBERS.EXCLUDE} key="Exkludera">
                                {Language.get("dynamic_reserv_list_exclude")}
                            </option>
                            <option value={RC.MEMBERS.INCLUDE} key="Inkludera">
                                {Language.get("dynamic_reserv_list_include")}
                            </option>
                            <option value={RC.MEMBERS.OBSTACLES} key="Förhinder">
                                {Language.get("dynamic_reserv_list_obstacles")}
                            </option>
                            <option value={RC.MEMBERS.MEMBERS_ONLY} key="Endast medlemmar">
                                {Language.get("dynamic_reserv_list_members_only")}
                            </option>
                        </select>
                    </div>

                    <hr />

                    <div className="settingsLine">
                        <label>{Language.get("dynamic_reserv_list_status_menu")}</label>
                        <MultiSelect
                            className="multiSelect"
                            options={selectOptions}
                            onValueChanged={this.onStatusChange}
                        />
                    </div>
                    {completeSelection}
                    <hr />
                    {reservationType}
                    {placeholderType}
                    {this._renderCheckboxes(
                        Language.get("nc_lists_reservation_checkboxes"),
                        this.props.availableReservationCheckboxes,
                        true
                    )}
                    {this._renderCategories(
                        Language.get("nc_lists_reservation_categories"),
                        this.props.availableReservationCategories,
                        true
                    )}
                    {this._renderCheckboxes(
                        Language.get("nc_lists_object_checkboxes"),
                        this.props.availableObjectCheckboxes,
                        false
                    )}
                    {this._renderCategories(
                        Language.get("nc_lists_object_categories"),
                        this.props.availableObjectCategories,
                        false
                    )}

                    <hr />

                    <div className="settingsLine">
                        <input
                            type="checkbox"
                            value={true}
                            checked={this.state.useTemplateGroup}
                            onChange={this.onUseTemplateGroupChange}
                        />{" "}
                        {Language.get("nc_reserv_list_use_selected_template_group")}
                    </div>

                    {ignoreExceptions}
                    {reservationGroupsOnly}
                </div>
            </div>
        );
    },
});

module.exports = ReservationListSettings;
