import React, { useEffect, useState } from "react";
import Language from "../lib/Language";
const TimeEditAPI = require("../lib/TimeEditAPI");
const TimeEdit = require("../lib/TimeEdit");
import _ from "underscore";
const ObjectList = require("./ObjectListWithDropdown");
const ObjectSearch = require("../models/ObjectSearch");
const ObjectSettingsConstants = require("../lib/ObjectSettingsConstants");
import { MillenniumDateTime, SimpleDateFormat } from "@timeedit/millennium-time";

type TNameId = {
    name: string;
    id: number;
};

const ScratchpadList = (props) => {
    const [objects, setObjects] = useState([]);
    const [sourceObjects, setSourceObjects] = useState([]);
    const [types, setTypes] = useState<TNameId[]>([]);
    const [searchObject, setSearchObject] = useState(null);
    let sO =
        props.searchObject ||
        new ObjectSearch(
            { excludeObjects: [], otherObjectsMode: ObjectSettingsConstants.OTHER_OBJECTS.INCLUDE },
            props.showExtraInfo || false
        ).freeze();

    if (!props.searchObject && !searchObject) {
        sO.setType(props.selectedType || TimeEdit.rootType, (newSearchObject) => {
            sO = newSearchObject;
            setSearchObject(sO);
        });
    }

    useEffect(() => {
        TimeEditAPI.getObjectNames(
            (props.objects || []).map((object) => object.sourceObjectId),
            false,
            (namedSourceObjects) => {
                TimeEditAPI.exportObjects(
                    (props.objects || []).map((object) => object.objectId),
                    (namedObjects) => {
                        const typeIds = {};
                        props.objects.forEach((object) => {
                            if (object.typeId) {
                                typeIds[object.typeId] = true;
                            }
                        });
                        TimeEditAPI.getTypes(
                            Object.keys(typeIds).map((idString) => parseInt(idString)),
                            false,
                            (result) => {
                                setSourceObjects(namedSourceObjects);
                                setObjects(
                                    namedObjects.map((namedObject, index) => ({
                                        ...namedObject,
                                        typeId: props.objects[index].typeId,
                                        pinDate: props.objects[index].pinDate,
                                        sourceObjectId: props.objects[index].sourceObjectId,
                                    }))
                                );
                                setTypes(result);
                            }
                        );
                    }
                );
            }
        );
    }, [JSON.stringify(props.objects)]);

    const removeValue = (object) => {
        props.onObjectSelected(object);
    };

    const updateSearchObject = (sO) => {
        if (!props.searchObject) {
            setSearchObject(sO);
        }
        if (props.updateSearchObject) {
            props.updateSearchObject("pinboard", sO);
        }
    };

    if (!searchObject && !props.searchObject) {
        return null;
    }

    type FieldObject = { id: number; fields: { values: any[] }[] };

    const getObjectName = (objectId) => {
        const obj: FieldObject | undefined = sourceObjects.find(
            (o: { id: number }) => o.id === objectId
        );
        if (
            obj &&
            (obj as FieldObject).fields &&
            (obj as FieldObject).fields[0] &&
            (obj as FieldObject).fields[0].values
        ) {
            return (obj as FieldObject).fields[0].values.join(",");
        }
        return objectId;
    };

    const getTypeName = (typeId) => {
        const type = types.find((tp) => tp.id === typeId);
        if (type) {
            return type.name;
        }
        return typeId;
    };

    const tooltipCellRenderer = (cellData) => {
        const values = cellData.cellData;
        const valueString = Array.isArray(values) ? values.join(", ") : values;
        const object: any = objects[cellData.rowIndex];
        let tooltip = valueString;
        if (object && object.typeId && object.pinDate) {
            tooltip = `${getTypeName(object.typeId)} (pinned ${SimpleDateFormat.format(
                new MillenniumDateTime(object.pinDate),
                Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
            )} from ${getObjectName(object.sourceObjectId)})`;
        }
        return <span title={tooltip}>{valueString}</span>;
    };

    return (
        <div className="pinboard" style={props.flex ? { display: "flex", flex: "1 1 auto" } : {}}>
            <ObjectList
                flex={props.flex}
                items={objects}
                cellRenderers={{ ALL: tooltipCellRenderer }}
                totalNumber={null}
                editable={true}
                propertyName={"pinboard"}
                dropdownOptions={[]}
                label={Language.get("nc_pinboard")}
                onItemsChanged={_.noop}
                hideEditButton={true}
                onOptionSelected={_.noop}
                onSelection={removeValue.bind(this)}
                editFunction={_.noop}
                searchObject={props.searchObject || searchObject}
                updateSearchObject={updateSearchObject}
                getAvailabilityObjects={() => []}
                hasPeriodObjects={true}
                presentMembershipPeriod={_.noop}
                hoverButton={props.hoverButton}
                preserveOrder={true}
                allowMultiSelection={props.allowMultiSelection || false}
                hidePlusButton={props.hidePlusButton}
            />
        </div>
    );
};

export default ScratchpadList;
