import React from "react";
import MemberObjectCreator from "./MemberObjectCreator";
const ListCalendar = require("../ListCalendar");
import _ from "underscore";

const MemberObjectCreatorCalendar = (props) => {
    let style = { width: props.width, height: "100%" };
    if (props.style) {
        // eslint-disable-next-line no-unused-vars
        style = props.style;
    }
    const classes = _.extend(
        {
            reservationList: true,
        },
        props.classes
    );
    if (props.className) {
        classes[props.className] = true;
    }

    // eslint-disable-next-line no-unused-vars
    let _wrapper;

    return (
        <ListCalendar
            {...props}
            type={"memberComponent"}
            refreshEvents={_.noop}
            onSelection={_.noop}
            listRef={(node) => {
                _wrapper = node;
            }}
        >
            <MemberObjectCreator {...props} callback={props.data.callback} groupRequests={props.data.groupRequests} objects={props.data.objects} />
        </ListCalendar>
    );
};

export default MemberObjectCreatorCalendar;
