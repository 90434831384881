import React, { PropsWithChildren, useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { ITEToken } from "@timeedit/types/lib/types";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import { TCustomerSignatureContext } from "@timeedit/types/lib/types/launchDarkly.type";

type Props = {
    customerSignature: string;
    region: string;
    usingSSO: boolean;
    // Will only be present in v3
    token?: ITEToken;
};

export default function CoreLDInitializer(props: PropsWithChildren<Props>) {
    const ldClient = useLDClient();
    useEffect(() => {
        if (ldClient) {
            const platformVersion = props.usingSSO ? "3" : "2";
            // This field will only be true for platform v3 users with the root
            // scope for now
            const isRootUser =
                platformVersion === "2"
                    ? false
                    : props.token?.scopes.includes("TE_ROOT_USER") ?? false;

            const context: TCustomerSignatureContext = {
                kind: "customerSignature",
                key: props.customerSignature,
                region: props.region as ERegion,
                isRootUser,
                custom: { platformVersion },
            };
            ldClient.identify(context);
        }
    }, [props.customerSignature]);
    return <>{props.children}</>;
}
