const PropTypes = require("prop-types");
const React = require("react");
const _ = require("underscore");

/**
 * A generic tooltip library for React.js
 *
 * Based on Khan Academy's Tooltip component (https://github.com/Khan/react-components/blob/master/js/tooltip.jsx)
 **/

class Tooltip extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        height: PropTypes.number.isRequired,
        className: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
    };

    static defaultProps = {
        className: "",
        verticalPosition: "bottom",
        horizontalPosition: "left",
    };

    render() {
        /* A positioned div below the input to be the parent for our
            tooltip */
        const isTooltipLeftAligned = this.props.horizontalPosition === "left";

        const outerStyle = {
            position: "relative",
        };
        const containerStyle = {
            position: "absolute",
            height: this.props.height,
            width: "auto",
            overflow: "auto",
            margin: "auto",
            top: 0,
            bottom: 0,
            display: this.props.show ? "block" : "none",
        };
        if (isTooltipLeftAligned) {
            containerStyle.left = "100%";
        } else {
            const LEFT_PADDING = 15;
            containerStyle.left = `-${this.props.width + LEFT_PADDING}px`;
        }
        const tooltipStyle = { height: this.props.height };

        return (
            <div style={outerStyle}>
                <div ref="tooltipContainer" className="tooltipContainer" style={containerStyle}>
                    {/* The contents of the tooltip */}
                    <div ref="tooltipContent" className={this.props.className} style={tooltipStyle}>
                        {_.rest(this.props.children)}
                    </div>
                </div>
                {/* We wrap our input in a div so that we can put the tooltip in a
                    div above/below it */}
                <div>{_.first(this.props.children)}</div>
            </div>
        );
    }
}

// Sorry.  // Apology-Oriented-Programming
module.exports = Tooltip;
