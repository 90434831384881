const API = require("../lib/TimeEditAPI");
const PropTypes = require("prop-types");
const React = require("react");
const _ = require("underscore");
const Language = require("../lib/Language");
const FieldTableRow = require("./FieldTableRow");
const Mousetrap = require("@timeedit/mousetrap");
const Log = require("../lib/Log");
const OrganizationList = require("./ObjectListWithDropdown");

class ReservationFieldEditor extends React.Component {
    static propTypes = {
        reservationIds: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        reservationIds: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            editableFields: [],
            multipleValueFields: [],
            updatedFields: null,
            organizations: [],
            canEditOrganizations: this.props.organizationsEnabled || false,
            selectedOrganizations: this.props.organizations || [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.bindSave();
        if (this.props && this.props.reservationIds) {
            this.loadInformation(
                this.props.reservationIds,
                this.state.selectedOrganizations,
                this.props.organizationsEnabled
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.shouldLoadInformation(this.props, prevProps)) {
            this.loadInformation(
                this.props.reservationIds,
                this.state.selectedOrganizations,
                this.props.organizationsEnabled
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        Mousetrap.unbindWithHelp("mod+s");

        if (this._previousSaveBinding) {
            Mousetrap.bindWithHelp(
                "mod+s",
                this._previousSaveBinding,
                undefined,
                Language.get("dialog_save")
            );
            this._previousSaveBinding = null;
        }
    }

    shouldLoadInformation(props, prevProps) {
        return !_.isEqual(props, prevProps);
    }

    loadInformation(reservationIds, selectedOrganizations, organizationsEnabled) {
        API.getModifiableReservationFields(reservationIds, (editableFields, hasSingleValueList) => {
            const multipleValueFields = _.pluck(
                editableFields.filter((field, index) => !hasSingleValueList[index]),
                "id"
            );

            API.okToSetReservationOrganizations(reservationIds, (response) => {
                API.getOrganizations({}, (result) => {
                    if (this._isMounted) {
                        this.setState({
                            selectedOrganizations: selectedOrganizations.map((org) =>
                                this.getDefinition(org.id, result.parameters[0])
                            ),
                            organizations: result.parameters[0],
                            canEditOrganizations:
                                response === true ? true && organizationsEnabled : false,
                            editableFields,
                            multipleValueFields,
                        });
                    }
                });
            });
        });
    }

    isFieldModified = (field) =>
        _.some(this.state.updatedFields || [], (item) => item.id === field.id);

    updateField = (field, updateFn) => {
        let fieldItem = _.find(this.getFields(), (item) => item.id === field.id);
        const newValues = [].concat(fieldItem.values || []);
        updateFn(newValues);
        fieldItem = _.extend({}, fieldItem, { values: newValues });

        const updatedFields = (this.state.updatedFields || [])
            .filter((item) => item.id !== field.id)
            .concat(fieldItem);
        this.setState({ updatedFields });
    };

    addField = (field) => {
        this.updateField(field, (values) => {
            if (values.length === 0) {
                // From the user"s perspective, zero values are displayed as one empty value. Thus we need to go from 0 -> 2.
                values.push("");
            }
            values.push("");
        });
    };

    removeField = (field, index) => {
        this.updateField(field, (values) => values.splice(index, 1));
    };

    setFieldValue = (field, event, newValue, valueIndex) => {
        this.updateField(field, (values) => {
            // eslint-disable-next-line no-param-reassign
            values[valueIndex] = newValue;
        });
    };

    save = () => {
        const updatedFields = this.state.updatedFields;
        let hasNewOrgs = !_.isEqual(
            this.props.organizations.map((org) => org.id),
            this.state.selectedOrganizations.map((org) => org.id)
        );
        if (this.props.organizations === false) {
            hasNewOrgs = false;
        }
        if (this.state.canEditOrganizations === false) {
            hasNewOrgs = false;
        }
        if (!updatedFields && !hasNewOrgs) {
            this.props.onClose();
            return;
        }

        if (_.some(updatedFields, this.hasMultipleValues)) {
            // eslint-disable-next-line no-alert
            const confirmed = window.confirm(
                Language.get("nc_edit_reservation_fields_overwrite_warning")
            );
            if (!confirmed) {
                this.props.onClose();
                return;
            }
        }

        let errors = [];

        let apiCalls = [];
        if (updatedFields) {
            apiCalls = apiCalls.concat((done) => {
                API.setModifiableReservationFields(
                    this.props.reservationIds,
                    updatedFields,
                    (result) => {
                        const details = result
                            .filter((item) => item.details)
                            .map((item) => item.details);
                        errors = errors.concat(details);
                        done();
                    }
                );
            });
        }

        if (hasNewOrgs) {
            apiCalls = apiCalls.concat((done) => {
                API.setReservationOrganizations(
                    this.props.reservationIds,
                    this.state.selectedOrganizations.map((org) => org.id),
                    (result) => {
                        const details = result
                            .filter((item) => item.details)
                            .map((item) => item.details);
                        errors = errors.concat(details);
                        done();
                    }
                );
            });
        }

        _.runSync(apiCalls, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate();
            }
            if (errors.length > 0) {
                Log.warning(errors[0]);
            } else {
                this.props.onClose();
            }
        });
    };

    hasMultipleValues = (field) => _.contains(this.state.multipleValueFields, field.id);

    bindSave = () => {
        this._previousSaveBinding = Mousetrap.unbindWithHelp("mod+s", true)[0];
        Mousetrap.bindWithHelp(
            "mod+s",
            () => {
                this.save();
                return false;
            },
            undefined,
            Language.get("dialog_save")
        );
    };

    getFields = () => {
        if (!this.state.updatedFields) {
            return this.state.editableFields;
        }

        return this.state.editableFields.map((field) => {
            const updatedField = _.find(this.state.updatedFields, (item) => item.id === field.id);
            return updatedField || field;
        });
    };

    hasMultipleReservations = () => this.props.reservationIds.length > 1;

    getFieldDef = (fieldId) =>
        _.find(this.props.fieldDefs, (field) => field.id === fieldId) || null;

    getDefinition(id, definitions) {
        return _.find(definitions, (def) => def.id === id) || null;
    }

    onOrganizationsChanged = (selectedOrganizations) => {
        this.setState({ selectedOrganizations });
    };

    onOrganizationSelected = (event) => {
        const definition = this.getDefinition(
            parseInt(event.target.value, 10),
            this.state.organizations
        );
        if (definition !== null) {
            const selectedOrganizations = this.state.selectedOrganizations.concat(definition);
            this.setState({ selectedOrganizations });
        }
    };

    _renderOrganizationSection() {
        return (
            <div className="accordionSection">
                <h3>{Language.get("dynamic_object_info_orgs")}</h3>
                <OrganizationList
                    items={this.state.selectedOrganizations}
                    editable={this.state.canEditOrganizations && this.props.organizations !== false}
                    propertyName={null}
                    dropdownOptions={{
                        options: this.state.organizations,
                        headlineValue: Language.get("nc_option_select"),
                    }}
                    label={Language.get("dynamic_object_info_orgs")}
                    onItemsChanged={this.onOrganizationsChanged}
                    onOptionSelected={this.onOrganizationSelected}
                    editFunction={_.noop}
                    searchObject={null}
                    updateSearchObject={_.noop}
                    getAvailabilityObjects={_.noop}
                    totalNumber={this.state.organizations.length}
                    presentMembershipPeriod={""}
                />
            </div>
        );
    }

    render() {
        let title = Language.get("nc_cal_func_res_edit_fields");
        if (!this.hasMultipleReservations()) {
            title = `${title} (#${this.props.reservationIds[0]})`;
        } else {
            title = `${title} (${Language.get(
                "nc_mass_change_x_reservations",
                this.props.reservationIds.length
            )})`;
        }

        return (
            <div className="reservationInfo">
                <div className="accordion">
                    <div>
                        <h3>{title}</h3>
                        <div className="accordionSection">
                            <div>
                                <table>
                                    {this.getFields().map((field, index) => {
                                        const definition = this.getFieldDef(field.id);
                                        if (!definition) {
                                            return null;
                                        }

                                        const indeterminate =
                                            this.hasMultipleValues(field) &&
                                            !this.isFieldModified(field);
                                        return (
                                            <FieldTableRow
                                                key={field.id}
                                                isFirstField={index === 0}
                                                field={field}
                                                definition={definition}
                                                indeterminate={indeterminate}
                                                addField={this.addField.bind(this, field)}
                                                removeField={this.removeField.bind(this, field)}
                                                setFieldValue={this.setFieldValue.bind(this, field)}
                                            />
                                        );
                                    }, this)}
                                </table>
                            </div>
                        </div>
                        {this._renderOrganizationSection()}
                    </div>
                </div>

                <div className="btnGroup">
                    <button className="save" onClick={this.save}>
                        {Language.get("dialog_save")}
                    </button>
                    <button className="cancel" onClick={this.props.onClose}>
                        {Language.get("dialog_cancel")}
                    </button>
                </div>
            </div>
        );
    }
}

module.exports = ReservationFieldEditor;
