const Cookie = require("./Cookie");

const COOKIE_NAME = "_csrf";

const CSRF = {};

CSRF.hasToken = function () {
    return Cookie.has(COOKIE_NAME);
};

CSRF.getToken = function () {
    return Cookie.get(COOKIE_NAME);
};

const getRandomToken = (length) => {
    const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let text = "";
    for (let i = 0; i < length; i++) {
        text += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
    }

    return text;
};

CSRF.updateToken = function (isSecure = false) {
    const exp = new Date();
    // eslint-disable-next-line no-magic-numbers
    exp.setHours(exp.getHours() + 2);
    try {
        // eslint-disable-next-line no-magic-numbers
        const token = getRandomToken(42);
        Cookie.set(COOKIE_NAME, token, exp, "/", false, isSecure);
    } catch (error) {
        return error;
    }
    return null;
};

module.exports = CSRF;
