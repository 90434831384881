import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
const Language = require("../lib/Language");
const Header = require("./Header");
const _ = require("underscore");

const DEFAULT_VISIBLE_VALUES = 10;
const DEFAULT_FIRST_VALUE = 8;

const TimeHeader = function (
    visibleValues = DEFAULT_VISIBLE_VALUES,
    firstVisibleValue = DEFAULT_FIRST_VALUE,
    subheader
) {
    Header.call(this, visibleValues, firstVisibleValue, subheader, "TimeHeader");
    this.isContinuous = true;
    this.discreteStep = 3600;
};

TimeHeader.parse = function (data, limits) {
    const header = new TimeHeader(DEFAULT_VISIBLE_VALUES);
    header.limits = limits;
    header._firstVisibleValue = Math.round(data.firstValue / header.discreteStep);
    header.visibleValues = Math.ceil(data.visibleValues / header.discreteStep);

    return header;
};

TimeHeader.prototype = Object.create(Header.prototype);

TimeHeader.prototype.getIndexOfTime = function (time, onlyVisible) {
    const timeNumber = time.getTimeNumber();
    if (!onlyVisible) {
        return (
            (this.getValues().length * (timeNumber - this.getStartTime())) /
            (this.getEndTime() - this.getStartTime())
        );
    }

    return (
        (this.visibleValues * (timeNumber - this.getStartTime(true))) /
        (this.getEndTime(true) - this.getStartTime(true))
    );
};

TimeHeader.prototype.indexOf = function (entry, onlyVisible) {
    return this.getIndexOfTime(entry.startTimes[0].getMillenniumTime(), onlyVisible);
};

TimeHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    return this.getIndexOfTime(entry.endTimes[0].getMillenniumTime(), onlyVisible);
};

TimeHeader.prototype.valueAt = function (index, onlyVisible = true) {
    if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
        throw new Error(`Index out of bounds in TimeHeader.valueAt(${index})`);
    }

    return new MillenniumTime(
        this.getStartTime(onlyVisible) + Math.round(index * this.discreteStep)
    );
};

TimeHeader.prototype.getStartTime = function (onlyVisible) {
    if (onlyVisible) {
        return this.limits.startTime + this.discreteStep * this.firstVisibleValue;
    }
    return this.limits.startTime;
};

TimeHeader.prototype.getEndTime = function (onlyVisible) {
    if (onlyVisible) {
        return (
            this.limits.startTime +
            this.discreteStep * (this.firstVisibleValue + this.visibleValues)
        );
    }
    return this.limits.startTime + this.limits.timeCount;
};

TimeHeader.prototype.getValues = function () {
    let time = this.getStartTime();
    const values = [];

    while (time < this.getEndTime()) {
        values.push(time);
        time = time + this.discreteStep;
    }

    return values;
};

TimeHeader.prototype.hasInfo = function () {
    return false;
};

TimeHeader.prototype.getLabel = function (time, size) {
    const date = new MillenniumDateTime(MillenniumDate.today(), new MillenniumTime(time));
    if (size < Header.Label.L) {
        return SimpleDateFormat.format(date, Language.getDateFormat("date_f_hh"));
    }
    return SimpleDateFormat.format(date, Language.getDateFormat("date_f_hh_mm"));
};

TimeHeader.prototype.isCurrent = function (time, currentDateTime) {
    if (!currentDateTime) {
        return false;
    }
    const now = currentDateTime.getMillenniumTime().getTimeNumber();
    const current = new MillenniumTime(time).getTimeNumber();
    return Math.floor(now / this.discreteStep) === Math.floor(current / this.discreteStep);
};

TimeHeader.prototype.setLimits = function (limits) {
    const header = Header.prototype.setLimits.call(this, limits);

    let firstVisibleValue = this.firstVisibleValue;
    if (this.limits) {
        if (this.getStartTime() + firstVisibleValue * this.discreteStep < limits.startTime) {
            firstVisibleValue = 0;
        } else {
            firstVisibleValue =
                firstVisibleValue + (this.getStartTime() - limits.startTime) / this.discreteStep;
        }
    }

    let visibleValues = this.visibleValues;
    if (visibleValues + firstVisibleValue > limits.timeCount / this.discreteStep) {
        firstVisibleValue = limits.timeCount / this.discreteStep - visibleValues;
    }
    if (firstVisibleValue < 0) {
        visibleValues = visibleValues - Math.abs(firstVisibleValue);
        firstVisibleValue = 0;
    }

    return header.immutableSet({
        _firstVisibleValue: firstVisibleValue,
        visibleValues,
    });
};

// eslint-disable-next-line no-unused-vars
TimeHeader.prototype.getSettings = function (providers) {
    const settings = Header.prototype.getSettings.call(this);
    const self = this;

    // User MillenniumDate for setting and getting firstVisibleValue
    const firstVisibleValue = settings.find("firstVisibleValue");
    firstVisibleValue.label = Language.get("cal_reservation_list_column_start_time");
    firstVisibleValue.type = "array";
    firstVisibleValue.limit = 1;
    firstVisibleValue.get = function () {
        const start = this.limits.getStartTime().getTimeNumber();
        const end = this.limits.getEndTime().getTimeNumber();
        return _.range(start, end, this.discreteStep).map((timestamp, index) => ({
            value: index,
            label: new MillenniumTime(timestamp).format("HH:mm"),
            selected: index === this.firstVisibleValue,
        }));
    }.bind(this);
    firstVisibleValue.set = function (fVV) {
        return self.immutableSet({ firstVisibleValue: fVV });
    };

    const visibleValues = settings.find("visibleValues");
    visibleValues.label = Language.get("cal_reservation_list_column_end_time");
    visibleValues.type = "array";
    visibleValues.limit = 1;
    visibleValues.get = function () {
        const start = this.valueAt(0).getTimeNumber() + this.discreteStep;
        const end = this.limits.getEndTime().getTimeNumber();
        return _.range(start, end + this.discreteStep, this.discreteStep).map(
            (timestamp, index) => ({
                value: index + 1,
                label: new MillenniumTime(timestamp).format("HH:mm"),
                selected: index + 1 === this.visibleValues,
            })
        );
    }.bind(this);
    visibleValues.set = function (vVs) {
        return self.immutableSet({ visibleValues: vVs });
    };

    return settings;
};

TimeHeader.prototype.toJSON = function () {
    const json = Header.prototype.toJSON.call(this);
    return _.extend(json, {
        firstValue: this.firstVisibleValue * this.discreteStep,
        kind: "time",
        size: 0,
        timeProvider: true,
        visibleValues: this.visibleValues * this.discreteStep,
    });
};

module.exports = TimeHeader;
